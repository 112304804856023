import { ReceiverRole } from '../../../domain/enums/receiver-role'
import { SmartInstallmentStatus } from '../../../domain/enums/smart-installment-status'
import { SmartInstallmentStatusStats } from '../../components/smart-installment-status-stats'
import { CancelInstallmentModal } from './cancel-installment-modal'
import { CopyClipboardTag } from '../../components/copy-clipboard-tag'
import { useTranslation } from 'react-i18next'
import { InvoiceInstallmentsStats } from '../../components/invoice-installments-stats'

type Props = {
  id: string
  total: string
  status: SmartInstallmentStatus
  installment: number
  installments: number
  role: ReceiverRole
  onChange: () => void
  onClose: () => void
}

export const Header: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const { id, total, status, installment, installments, role, onChange } = props

  const canCancel =
    role === ReceiverRole.ROLE_SELLER &&
    [SmartInstallmentStatus.ON_SCHEDULE, SmartInstallmentStatus.OFF_SCHEDULE].includes(status)

  return (
    <div className="flex items-center justify-between">
      <div>
        <div className="">
          <div className="flex items-center gap-3 mb-2">
            <h3 className="text-3xl font-semibold">{total}</h3>
            <SmartInstallmentStatusStats status={status} />
            <InvoiceInstallmentsStats installment={installment} installments={installments} />
          </div>
          <div>
            <CopyClipboardTag
              value={id}
              successMessage={t('copyClipboard.idCopied')}
              tooltip={t('copyClipboard.idTooltip')}
            />
          </div>
        </div>
      </div>
      <div className="mt-4 space-x-2 h-full">
        {canCancel && <CancelInstallmentModal smartInstallmentId={id} onSuccess={onChange} />}
      </div>
    </div>
  )
}
