import { Button, Table, TableBody, TableCell, TableHead, TableHeader, TablePagination, TableRow } from '@hub-la/shadcn'
import isEmpty from 'lodash/isEmpty'
import times from 'lodash/times'
import { PlusCircle } from 'lucide-react'
import moment from 'moment'
import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import avatar from '../../../assets/placeholder-image.svg'
import { useListMyPartners } from '../../../hooks/settings/use-list-my-partners'
import { MyPartnersActions } from '../components/my-partners-actions'
import { PartnerStatusTag } from '../components/partner-status-tag'
import { MyPartnersTableLoading } from './my-partners-table-loading'

export const MyPartners = ({ offerId }) => {
  const { productId } = useParams<{ productId: string }>()
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(25)
  const history = useHistory()
  const {
    data = { items: [], lastPage: 0, page: 1, pageSize: 1, total: 0 },
    isLoading,
    refetch,
  } = useListMyPartners({
    id: productId,
    page,
    pageSize,
  })

  const headCells = [
    { key: 'partner', label: 'Parceiro', sortable: false, width: 110 },
    { key: 'status', label: 'Status', sortable: false, width: 60 },
    { key: 'commission', label: 'Comissão', sortable: false, width: 90 },
    { key: 'expiresAt', label: 'Data encerramento', sortable: false, width: 90 },
    { key: 'actions', label: '', sortable: false, width: 90 },
  ]

  const isRowsEmpty = isEmpty(data.items)
  const canRenderEmptyState = !isLoading && isRowsEmpty

  return (
    <div className="flex flex-col h-full w-full gap-3">
      <div className="flex items-baseline justify-between">
        <p className="text-sm">{data.total} parceiro(s)</p>

        <Button onClick={() => history.push(`/co-production/${offerId}`)}>
          <PlusCircle className="mr-2 h-4 w-4" />
          Convidar parceiro
        </Button>
      </div>

      <div className="flex flex-col gap-4">
        <div className="rounded-lg border bg-white overflow-hidden">
          <Table>
            <TableHeader>
              <TableRow>
                {headCells.map((cell) => (
                  <TableHead key={cell.key} style={{ width: cell.width }}>
                    {cell.label}
                  </TableHead>
                ))}
              </TableRow>
            </TableHeader>

            <TableBody>
              {isLoading
                ? times(pageSize, (i) => <MyPartnersTableLoading key={`skeleton-row-${i}`} />)
                : data.items.map((partner) => (
                    <TableRow key={partner.id}>
                      <TableCell className="font-medium">
                        <div className="flex items-center gap-2">
                          <img
                            src={partner.user.picture ?? avatar}
                            alt={partner.user.name}
                            className="w-10 h-10 rounded object-cover"
                          />

                          <span>{partner.user.name ?? partner.user.email}</span>
                        </div>
                      </TableCell>

                      <TableCell>
                        <PartnerStatusTag status={partner.status} />
                      </TableCell>

                      <TableCell>{partner.commission?.sell}%</TableCell>

                      <TableCell>{partner.expiresAt ? moment(partner.expiresAt).format('D MMM. YYYY') : '∞'}</TableCell>

                      <TableCell>
                        <div className="flex justify-end mr-3">
                          <MyPartnersActions receiverId={partner.id} offerId={offerId} onChange={refetch} />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </div>

        {canRenderEmptyState && (
          <div className="text-center py-4">
            <p>Nenhum resultado encontrado</p>
          </div>
        )}

        {!isRowsEmpty && (
          <div className="flex items-center">
            <TablePagination
              page={page}
              setPage={setPage}
              lastPage={data.lastPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          </div>
        )}
      </div>
    </div>
  )
}
