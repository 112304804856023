import { Button } from '@hub-la/shadcn'
import { FileDown, X } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BookAccount } from '../../../domain/dtos/book-accounts'
import { TransactionSource } from '../../../domain/dtos/get-moviments.dto'
import { TimeRangePicker } from '../../components/time-range-picker/time-range-picker'
import { TransactionSourceFilter, useTransactionSourcesFilter } from '../../hooks/use-transaction-sources-filter'

export type SelectedFilters = {
  accountType: BookAccount
  startDate: string | null
  endDate: string | null
  transactionSources: TransactionSource[]
}

type FiltersProps = {
  filters: SelectedFilters
  setFilters: (filters: SelectedFilters) => void
  onExportMovements: () => void
}

export const Filters: React.FC<FiltersProps> = ({ filters, setFilters, onExportMovements }) => {
  const { t } = useTranslation()

  const handleFilterChange = (filter: Partial<SelectedFilters>) => {
    const newSelectedFilters = { ...filters, ...filter }
    setFilters(newSelectedFilters)
  }

  const handleDateRange = (from: string, to: string) => {
    handleFilterChange({ startDate: from, endDate: to })
  }

  const { transactionSourceFilters, handleSourceSelect, handleSourceUnselect, isSourceSelected } =
    useTransactionSourcesFilter(filters.transactionSources, handleFilterChange, filters.accountType)

  return (
    <div className="flex flex-row justify-between items-center my-6 space-x-4 overflow-x-auto overflow-y-hidden">
      <div className="flex flex-row gap-2 items-center">
        <TimeRangePicker
          onChangeFromTo={handleDateRange}
          initialRange={{ from: filters.startDate ?? '', to: filters.endDate ?? '' }}
        />

        {transactionSourceFilters.map((filter: TransactionSourceFilter) => (
          <Button
            key={filter.id}
            data-testid={filter.id}
            variant={isSourceSelected(filter.id) ? 'secondary' : 'outline'}
            className="cursor-pointer"
            onClick={() => (isSourceSelected(filter.id) ? handleSourceUnselect(filter) : handleSourceSelect(filter))}
          >
            {t(`filters.sources.${filter.id}`)}
            {isSourceSelected(filter.id) && (
              <X
                className="w-3 h-3 ml-2"
                onClick={(e) => {
                  e.stopPropagation()
                  handleSourceUnselect(filter)
                }}
              />
            )}
          </Button>
        ))}
      </div>

      <Button variant="outline" onClick={onExportMovements} className="whitespace-nowrap">
        <FileDown className="mr-2 h-4 w-4" />
        {t('filters.exportSpreadsheet')}
      </Button>
    </div>
  )
}
