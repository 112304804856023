import {
  Badge,
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@hub-la/shadcn'
import isEmpty from 'lodash/isEmpty'
import times from 'lodash/times'
import { Edit, MoreVertical, Send, Trash2 } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { ExternalReference } from '../../../domain/dtos/external-reference'
import { IntegrationProvider } from '../../../domain/dtos/integration-provider'
import { Rule } from '../../../domain/dtos/rule'
import { GetEventName } from '../../../usecases/get-event-name'
import { CreateEditRuleType } from '../create-edit-rule-modal'
import { EmptyState } from '../empty-state'
import { RemainingElements } from '../remaining-elements'
import { RuleTableLoading } from './rule-table-loading'

type TableRuleProps = {
  provider: IntegrationProvider
  isFetching: boolean
  items: Rule[]
  schema: string[]
  onChangeRuleStatus: (rule: Rule) => (checked: boolean) => void
  onRuleDetail: (state: { isOpen: boolean; rule: Rule }) => void
  onRuleEdit: (state: { isOpen: boolean; rule: Rule | null; type: CreateEditRuleType }) => void
  onRuleDelete: (state: { isOpen: boolean; rule: Rule }) => void
  onRuleSendTest: (state: { isOpen: boolean; rule: Rule }) => void
}

export const TableRule = (props: TableRuleProps) => {
  const { provider, isFetching, items, schema } = props
  const { onChangeRuleStatus, onRuleDetail, onRuleEdit, onRuleDelete, onRuleSendTest } = props
  const { t } = useTranslation()

  const isRowsEmpty = isEmpty(items)
  const canRenderEmptyState = !isFetching && isRowsEmpty
  const isLoading = isFetching && isRowsEmpty

  const handleOpenedRuleDetail = (rule: Rule) => () => {
    onRuleDetail({ isOpen: true, rule })
  }

  const getHeadCells = () => {
    const properties = schema.map((property) => {
      if (property === 'lists') {
        return {
          key: property,
          label: t(`integrations.${provider}.rule.columns.lists`),
          sortable: false,
        }
      } else if (property === 'tags') {
        return {
          key: property,
          label: t(`integrations.${provider}.rule.columns.tags`),
          sortable: false,
        }
      } else {
        return {
          key: property,
          label: t(`rules.table.columns.${property}`),
          sortable: false,
        }
      }
    })

    return [
      ...properties,
      {
        key: 'status',
        label: t(`rules.table.columns.status`),
        sortable: false,
        width: 100,
      },
      {
        key: 'actions',
        label: t(`rules.table.columns.actions`),
        sortable: false,
        width: 80,
      },
    ]
  }

  const renderTableCell = (rule: Rule, property: string, index: number) => {
    if (property === 'products' || property === 'lists' || property === 'offers') {
      return (
        <TableCell key={index} className="cursor-pointer" onClick={handleOpenedRuleDetail(rule)}>
          <RemainingElements data={rule[property]}>
            {(item: ExternalReference) => <span>{item.name}</span>}
          </RemainingElements>
        </TableCell>
      )
    } else if (property === 'events') {
      return (
        <TableCell key={index} className="cursor-pointer" onClick={handleOpenedRuleDetail(rule)}>
          <RemainingElements data={rule[property]}>
            {(label: string) => {
              const eventName = new GetEventName().execute(label)

              if (!eventName) {
                return null
              }

              return <Badge variant="secondary">{t(eventName)}</Badge>
            }}
          </RemainingElements>
        </TableCell>
      )
    } else if (property === 'tags') {
      return (
        <TableCell key={index} className="cursor-pointer" onClick={handleOpenedRuleDetail(rule)}>
          {(rule[property] ?? []).length !== 0 ? (
            <RemainingElements data={rule[property]}>
              {(item: ExternalReference) => <Badge variant="outline">{item.name}</Badge>}
            </RemainingElements>
          ) : (
            '-'
          )}
        </TableCell>
      )
    } else {
      return (
        <TableCell key={index} className="cursor-pointer" onClick={handleOpenedRuleDetail(rule)}>
          <span>{rule[property]}</span>
        </TableCell>
      )
    }
  }

  return (
    <div className="rounded-lg border bg-white overflow-auto">
      <Table>
        <TableHeader>
          <TableRow>
            {getHeadCells().map((cell) => (
              <TableHead key={cell.key} className="bg-white">
                {cell.label}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>

        <TableBody>
          {isLoading
            ? times(25, (i) => <RuleTableLoading key={`skeleton-row-${i}`} schema={schema} />)
            : items.map((rule) => {
                return (
                  <TableRow key={rule.id} className="hover:bg-gray-100">
                    {schema.map((property, idx) => {
                      return renderTableCell(rule, property, idx)
                    })}

                    <TableCell>
                      <Switch checked={rule.enabled} onCheckedChange={(checked) => onChangeRuleStatus(rule)(checked)} />
                    </TableCell>

                    <TableCell className="p-0">
                      <Popover>
                        <PopoverTrigger asChild>
                          <Button variant="outline" size="icon" className="h-8 w-8">
                            <MoreVertical className="h-4 w-4" />
                          </Button>
                        </PopoverTrigger>

                        <PopoverContent className="w-56">
                          <div className="grid gap-4">
                            {provider === IntegrationProvider.WEBHOOK && (
                              <Button
                                variant="ghost"
                                className="w-full justify-start"
                                onClick={() => onRuleSendTest({ isOpen: true, rule })}
                              >
                                <Send className="mr-2 h-4 w-4" />
                                {t('buttons.sandbox')}
                              </Button>
                            )}

                            <Button
                              variant="ghost"
                              className="w-full justify-start"
                              onClick={() =>
                                onRuleEdit({
                                  isOpen: true,
                                  type: CreateEditRuleType.EDIT,
                                  rule,
                                })
                              }
                            >
                              <Edit className="mr-2 h-4 w-4" />
                              {t('rules.buttons.edit')}
                            </Button>

                            <Button
                              variant="ghost"
                              className="w-full justify-start"
                              onClick={() => onRuleDelete({ isOpen: true, rule })}
                            >
                              <Trash2 className="mr-2 h-4 w-4" />
                              {t('rules.buttons.delete')}
                            </Button>
                          </div>
                        </PopoverContent>
                      </Popover>
                    </TableCell>
                  </TableRow>
                )
              })}
        </TableBody>
      </Table>

      {canRenderEmptyState && <EmptyState />}
    </div>
  )
}
