import React from 'react'

type Props = {
  title: string
  subtitle: string
}

export const FormHeader: React.FC<Props> = ({ title, subtitle }) => {
  return (
    <div className="flex flex-col space-y-1">
      <h3 className="text-2xl font-semibold">{title}</h3>
      <p className="text-base text-gray-600">{subtitle}</p>
    </div>
  )
}
