import { container } from '@hub-la/fe-container'
import { DashboardHome, DashboardHomeProvider } from '@hub-la/fe-dashboard-home'

export const DashboardHomeInit = () => {
  return (
    <DashboardHomeProvider container={container}>
      <DashboardHome />
    </DashboardHomeProvider>
  )
}
