import {
  Button,
  Calendar,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@hub-la/shadcn'
import { CalendarIcon } from 'lucide-react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

interface DateRange {
  startDate?: Date
  endDate?: Date
}

interface SelectDateRangeCalendar {
  onChange: (value?: DateRange) => void
  onSelectChange: (value?: string) => void
  onClearDate: () => void
  selectValue: string
  value: DateRange
  placeholder?: string
}

const formatPlaceholder = (dateRange: DateRange, placeholder?: string) => {
  if (dateRange.startDate && dateRange.endDate) {
    const formattedStartDate = moment(dateRange.startDate).format('D MMM. YYYY')
    const formattedEndDate = moment(dateRange.endDate).format('D MMM. YYYY')
    return `${formattedStartDate} a ${formattedEndDate}`
  }

  if (dateRange.startDate) {
    const formattedStartDate = moment(dateRange.startDate).format('D MMM. YYYY')
    return `${formattedStartDate}`
  }

  return placeholder ?? 'Período'
}

export const SelectDateRangeCalendar = (props: SelectDateRangeCalendar) => {
  const { t } = useTranslation()

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline">
          <CalendarIcon className="mr-2 h-4 w-4" />
          <span>{formatPlaceholder(props.value, props.placeholder)}</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0 z-10002">
        <div className="p-3 flex justify-between">
          <div>
            <Label className="mb-2 block">Filtrar por data</Label>
            <Select onValueChange={(value) => props.onSelectChange(value)} value={props.selectValue}>
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Theme" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="nextDueAt">{t('filters.dateRangeBy.nextDueAt')}</SelectItem>
                <SelectItem value="createdAt">{t('filters.dateRangeBy.createdAt')}</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <Button size="sm" variant="link" onClick={props.onClearDate}>
            Limpar
          </Button>
        </div>
        <Calendar
          mode="range"
          selected={{ from: props.value.startDate, to: props.value.endDate }}
          defaultMonth={props.value.startDate}
          numberOfMonths={2}
          onSelect={(value) => props.onChange({ startDate: value?.from, endDate: value?.to })}
        />
      </PopoverContent>
    </Popover>
  )
}
