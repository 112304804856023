import { Progress } from '@hub-la/shadcn'

interface ProgressBarProps {
  progress?: number | null
  maxProgress?: number | null
  viewed?: boolean
}

export const ProgressBar = ({ viewed, progress = 0, maxProgress = 0 }: ProgressBarProps) => {
  const calcPorcentageProgress = (progress: number, total: number) => {
    if (total === 0 || progress === 0) return 0

    return (progress / total) * 100
  }

  const calcWidthBar = (): number => {
    if (progress === 0) {
      return viewed ? 100 : 0
    }

    if (!progress || !maxProgress) return 0

    if (progress === 0 || maxProgress === 0) return 0

    if (progress > maxProgress) return 100

    return calcPorcentageProgress(progress, maxProgress) ?? 0
  }

  const progressBar = calcWidthBar()

  return <Progress value={progressBar} className="h-2 w-full" />
}
