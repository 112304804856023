import { Button, Input } from '@hub-la/shadcn'
import { Eye, EyeOff } from 'lucide-react'
import React, { useState } from 'react'

interface PasswordFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  error?: boolean
  helperText?: React.ReactNode
}

const PasswordField: React.FC<PasswordFieldProps> = ({ label, error, helperText, ...props }) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <div className="space-y-2">
      {label && (
        <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
          {label}
        </label>
      )}
      <div className="relative">
        <Input
          type={showPassword ? 'text' : 'password'}
          className={`pr-10 ${error ? 'border-red-500' : ''}`}
          {...props}
        />
        <Button
          type="button"
          variant="ghost"
          size="sm"
          className="absolute right-0 top-0 h-full px-3 py-2 hover:bg-transparent"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? <EyeOff className="h-4 w-4 text-gray-500" /> : <Eye className="h-4 w-4 text-gray-500" />}
        </Button>
      </div>
      {error && helperText && <p className="text-sm text-red-500">{helperText}</p>}
    </div>
  )
}

export { PasswordField }
