export const pt = {
  installmentSelectItem: 'Em até {{installments}}x',
  errors: {
    general: 'Erro inesperado. Tente novamente.',
  },
  backendErrors: {
    'validator--invalid-field': 'Verifique os campos do formulário.',
  },
  fieldErrors: {
    required: 'Campo obrigatório',
    min: 'Valor mínimo é 10 reais',
    url: 'URL inválida',
    youtubeEmbedUrl: 'URL do YouTube inválida',
    positive: 'Valor deve ser um número positivo',
    differentFromUpsellUrl: 'Não utilize links de upsell de 1-clique!',
    minOldTotal: 'Valor mínimo é 10 reais',
    isLessPrice: 'O preço promocional deve ser menor que o preço',
    isNumber: 'O preço promocional deve ser um número',
    isGreaterFiveSeconds: 'O tempo do cronômetro deve ser maior que 5 segundos',
  },
  closeAlertModal: {
    title: 'Alterações não salvas',
    description: 'As alterações realizadas não foram salvas. Deseja sair mesmo assim?',
    stay: 'Ficar na página',
    leave: 'Sair',
  },
  closePromptAlert: 'As alterações realizadas não foram salvas. Deseja sair mesmo assim?',
  plans: {
    monthly: 'Mensal',
    quarterly: 'Trimestral',
    semiannually: 'Semestral',
    annually: 'Anual',
  },
  plansPerPeriod: {
    monthly: 'mês',
    quarterly: 'trimestre',
    semiannually: 'semestre',
    annually: 'ano',
  },
  paymentMethod: {
    credit_card: 'Cartão de crédito',
    bank_slip: 'Boleto',
    pix: 'PIX',
  },
  priceType: {
    oneTime: 'pagamento único',
    recurring: 'assinatura recorrente',
  },
  offer: {
    name: {
      label: 'Nome',
      placeholder: 'Digite o nome da oferta',
    },
    header: {
      back: 'Voltar',
      draft: 'Salvar rascunho',
      publish: 'Criar oferta',
      edit: 'Atualizar oferta',
    },
    tooltipErrors: {
      pricing: 'Preço',
      checkout: 'Checkout',
      afterPurchase: 'Pós-compra',
      name: 'Nome',
      cohortId: 'Turma',
    },
    title: {
      edit: 'Editar oferta',
      new: 'Nova oferta',
    },
    tabs: {
      pricing: 'Preço',
      checkout: 'Checkout',
      afterPurchase: 'Pós-compra',
      pricingEvent: 'Preço',
      checkoutEvent: 'Checkout',
      afterPurchaseEvent: 'Pós-compra',
    },
    cohort: {
      title: 'Turma',
      select: {
        label: 'Turma',
        placeholder: 'Selecione a(s) turma(s)',
      },
    },
    cohortEvent: {
      title: 'Ingresso',
      select: {
        label: 'Ingresso',
        placeholder: 'Selecione o ingresso',
      },
    },
    pricingTab: {
      pricing: {
        title: 'Preço',
        subscriptionType: {
          oneTime: 'Pagamento único',
          recurring: 'Assinatura recorrente',
        },
        currency: {
          label: 'Moeda',
          description: 'Compras em outras moedas serão convertidas de acordo com o câmbio do dia. <0>Saiba mais</0>',
        },
        price: {
          label: 'Preço',
        },
        promotion: {
          label: 'Preço promocional',
        },
        plan: {
          label: 'Plano',
        },
        installments: {
          label: 'Opções de parcelamento',
        },
        accessDays: {
          label: 'Tempo de acesso ao produto',
        },
        addPlan: 'Adicionar plano',
      },
      options: {
        title: 'Opções',
        membershipFee: {
          title: 'Cobrar taxa de adesão',
          description: 'Cobre uma taxa diferenciada de seus membros para o primeiro mês de acesso ao produto.',
          total: 'Taxa de adesão',
          installments: 'Opções de parcelamento',
        },
        smartInstallments: {
          title: 'Habilitar parcelamento inteligente',
          description:
            'Se o cartão do cliente falhar por falta de limite, tentamos concluir a compra cobrando uma parcela. O restante será parcelado automaticamente em mensalidades.',
          warning: 'Vendas com parcelamento inteligente serão marcadas com este ícone na fatura.',
          maxInstallments: 'Número máximo de parcelas',
          installmentsWithoutDebt: {
            label: 'Parcelas sem dívida',
            description:
              'O cliente não acumulará dívida. O acesso será suspenso temporariamente e liberado ao pagar uma parcela atrasada.',
          },
          installmentsWithDebt: {
            label: 'Parcelas com dívida',
            description:
              'O cliente acumulará dívidas se atrasar parcelas. O acesso será bloqueado até que todas as parcelas atrasadas sejam pagas.',
          },
        },
        affiliatesSwitch: 'Disponibilizar essa oferta para afiliados',
      },
    },
    checkoutTab: {
      customization: {
        title: 'Personalização',
        logo: {
          label: 'Logo do criador',
          description: 'JPEG, PNG, GIF ou SVG acima de 300x300 pixels',
        },
        bannerMobile: {
          label: 'Banner mobile',
          description: 'JPEG, PNG, GIF ou SVG acima de 1440x1024 pixels',
        },
        bannerDesktop: {
          label: 'Banner desktop',
          description: 'JPEG, PNG, GIF ou SVG acima de 1900x600 pixels',
        },
        preferences: {
          title: 'Preferências',
          planPreselected: 'Plano pré-selecionado',
          installmentPreselected: 'Parcelamento pré-selecionado',
          priceFormat: 'Formato de preço no checkout',
        },
      },
      paymentMethods: {
        title: 'Métodos de pagamento',
        disabledWarn: 'Não é possível desabilitar todos os métodos de pagamento da sua oferta.',
        priceTypeNotAllowed:
          'Não é possível utilizar o método de pagamento {{paymentMethod}} para ofertas de assinatura {{priceType}}.',
      },
      options: {
        title: 'Opções',
        orderBump: {
          title: 'Adicionar oferta de orderbump',
          description: 'Oferte um novo produto complementar durante o momento da compra do seu produto.',
          add: 'Adicionar order bump',
          edit: 'Salvar order bump',
          close: 'Fechar',
          cancel: 'Cancelar',
        },
        timer: {
          title: 'Exibir cronômetro de contagem regressiva',
          timerSeconds: 'Tempo do cronômetro',
          timerColor: 'Cor de fundo do cronômetro',
        },
        youtubeEmbed: {
          title: 'Exibir vídeo do YouTube dentro do checkout',
          description:
            'Adicionar um vídeo pode aumentar significativamente a conversão da sua oferta, além de mostrar mais credibilidade.',
          youtubeEmbedUrl: 'URL do vídeo do YouTube',
        },
        showCouponField: { title: 'Exibir campo de cupom de desconto' },
        billingAddress: { title: 'Coletar endereço de cobrança dos clientes' },
        creatorSupport: {
          title: 'Exibir botão de suporte no checkout',
          description: 'Adicione o link de direcionamento para acesso do cliente ao seu suporte.',
          creatorSupportUrl: 'Link de suporte',
        },
        termsUrl: {
          title: 'Exibir link de termos de uso personalizado',
          description: 'Adicione o link de direcionamento para acesso do cliente ao seus termos de uso personalizado.',
          termsUrl: 'Link de direcionamento',
        },
      },
    },
    afterPurchaseTab: {
      title: 'Depois da compra, direcionar o cliente para',
      userGroups: {
        label: 'Minhas compras',
      },
      membersArea: {
        label: 'Área de membros do produto',
      },
      externalUrl: {
        label: 'Página externa',
        example: 'https://upsell.com.br',
        tip: 'Copie e cole o link de compra de 1-clique na sua página externa para ofertar o upsell do produto desejado. Esse link se encontra na aba ”Links” dentro dos seus produtos na plataforma.',
      },
      upsell: {
        label: 'Upsell de 1-clique na Hubla',
        add: 'Adicionar upsell',
        upsellRedirectType: {
          description: 'Depois do Upsell de 1-clique, direcionar o cliente para:',
          label: 'Direcionar para',
        },
      },
    },
  },
  redirectType: {
    user_groups: 'Minhas compras',
    members_area: 'Área de membros',
    external_url: 'Página de obrigado externa',
    upsell: 'Upsell de 1-clique na Hubla',
  },
  upsellModal: {
    title: 'Configurar upsell',
    description:
      'Selecione um novo produto que será ofertado como um upsell de 1-clique logo após a compra do seu cliente.',
    productList: {
      title: 'Produto',
      empty: 'Nenhum produto disponível',
      emptyAvailable: 'Nenhum produto disponível',
    },
    offersList: {
      title: 'Oferta',
      empty: 'Nenhuma oferta selecionada',
      emptyAvailable: 'Nenhuma oferta disponível',
    },
    youtubeEmbed: {
      title: 'Personalizar oferta com um vídeo do YouTube',
      description:
        'Adicionar um vídeo pode aumentar significativamente a conversão da sua oferta, além de mostrar mais credibilidade.',
      youtubeEmbedUrl: 'URL do vídeo do YouTube',
    },
    actions: {
      cancel: 'Cancelar',
      close: 'Fechar',
      save: 'Adicionar upsell',
      edit: 'Salvar upsell',
    },
  },
  orderBumpModal: {
    title: 'Configurar order bump',
    description:
      'Selecione um novo produto que será ofertado como um order bump durante o fluxo de checkout do seu produto principal.',
    productsList: {
      title: 'Produto',
      empty: 'Nenhum produto disponível',
      emptyAvailable: 'Nenhum produto disponível',
    },
    offersList: {
      title: 'Oferta',
      empty: 'Nenhuma oferta selecionado',
      emptyAvailable: 'Nenhuma oferta disponível',
    },
    options: {
      customTag: {
        title: 'Adicionar uma tag personalizada',
        label: 'Tag personalizada',
      },
      oldTotal: {
        title: 'Mostrar como um preço promocional',
        label: 'De',
        example: 'por {{price}} / {{interval}}',
      },
      description: {
        title: 'Adicionar uma descrição da oferta',
        label: 'Descrição da oferta',
      },
    },
    actions: {
      cancel: 'Cancelar',
      save: 'Adicionar order bump',
    },
  },
  accessDays: {
    oneMonth: '1 mês',
    threeMonths: '3 meses',
    sixMonths: '6 meses',
    nineMonths: '9 meses',
    oneYear: '1 ano',
    twoYears: '2 anos',
    threeYears: '3 anos',
    indefinite: 'Indeterminado',
  },
  checkoutSettings: {
    view: {
      mobile: 'Mobile',
      desktop: 'Desktop',
    },
    timerText: 'Compre em até {{timer}} para não perder essa oferta',
  },
}
