import { Asset, AssetResponse } from '@hub-la/fe-asset'
import { Thumbnail } from '@hub-la/fe-thumbnail'
import { Edit } from 'lucide-react'
import { useState } from 'react'
import { DefaultEditThumbnail } from './default-edit-thumbnail'

type ClickableEditThumbnailProps = {
  asset: Asset | AssetResponse | null | undefined
  onClick?: () => void
}

const ClickableEditThumbnail = ({ asset, onClick }: ClickableEditThumbnailProps) => {
  const [isHoveringThumbnail, setIsHoveringThumbnail] = useState(false)

  return (
    <div
      className="mx-3 cursor-pointer relative"
      onClick={onClick}
      onMouseEnter={() => setIsHoveringThumbnail(!!asset)}
      onMouseLeave={() => setIsHoveringThumbnail(false)}
      data-testid="clickable-edit-thumbnail"
    >
      {isHoveringThumbnail && (
        <div
          className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm rounded-sm z-10"
          data-testid="thumbnail-overlay"
        >
          <Edit className="text-white" size={20} />
        </div>
      )}
      {asset ? (
        <Thumbnail width={38} height={64} asset={asset} hasProgressBar={false} />
      ) : (
        <DefaultEditThumbnail data-testid="default-edit-thumbnail" />
      )}
    </div>
  )
}

export { ClickableEditThumbnail }
