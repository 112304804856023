import { Button, cn } from '@hub-la/shadcn'
import { Circle, CircleAlert, CircleCheck, CircleX, RefreshCw, XCircle } from 'lucide-react'
import { Dispatch, SetStateAction } from 'react'
import { RefundStatus } from '../../domain/enums/refund-status'

interface StatusFilterProps {
  currentStatus: RefundStatus
  setCurrentStatus: Dispatch<SetStateAction<RefundStatus>>
}

export const StatusFilter = ({ currentStatus, setCurrentStatus }: StatusFilterProps) => {
  const handleStatusChange = (status: RefundStatus) => {
    setCurrentStatus(status)
  }

  const options = [
    { icon: Circle, value: RefundStatus.CREATED, label: 'Pendentes' },
    { icon: CircleCheck, value: RefundStatus.ACCEPTED, label: 'Aprovados' },
    { icon: XCircle, value: RefundStatus.REJECTED, label: 'Recusados' },
    { icon: RefreshCw, value: RefundStatus.PROCESSING, label: 'Processando' },
    { icon: CircleAlert, value: RefundStatus.FAILED, label: 'Falhas' },
    { icon: CircleX, value: RefundStatus.CANCELED, label: 'Cancelados' },
  ]

  return (
    <div className="flex flex-wrap gap-2">
      {options.map((option) => (
        <Button
          key={option.value}
          variant={currentStatus === option.value ? 'default' : 'outline'}
          size="default"
          onClick={() => handleStatusChange(option.value)}
          className={cn(
            'flex items-center',
            currentStatus === option.value ? 'bg-primary text-primary-foreground' : '',
          )}
        >
          <option.icon className="mr-2 h-4 w-4" />
          {option.label}
        </Button>
      ))}
    </div>
  )
}
