import { memo } from 'react'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { SubscriptionStatus } from '../../../domain/enums/subscription-status'
import { useGetOffers } from '../../hooks/use-get-offers'
import { CommaDelimitedArrayParam } from '../../utils/query-string-encoder'
import { Filters } from './filters'
import { SubscriptionsTable as Table } from './subscriptions-table'

export const SubscriptionsTable = memo(() => {
  const { data: offers = [], isFetching } = useGetOffers()
  const isFetchingOffers = isFetching && offers.length <= 0

  const [filters, setFilters] = useQueryParams({
    search: withDefault(StringParam, ''),
    dateRangeBy: withDefault(StringParam, 'createdAt'),
    startDate: withDefault(StringParam, ''),
    endDate: withDefault(StringParam, ''),
    statuses: withDefault(CommaDelimitedArrayParam, [
      SubscriptionStatus.ACTIVE,
      SubscriptionStatus.CANCELED,
      SubscriptionStatus.INACTIVE,
    ]),
    offerIds: withDefault(CommaDelimitedArrayParam, []),
    page: withDefault(NumberParam, 1),
    pageSize: withDefault(NumberParam, 25),
    planType: withDefault(CommaDelimitedArrayParam, []),
  })

  return (
    <div className="flex flex-col gap-2">
      <Filters filters={filters} setFilters={setFilters} offers={offers} isLoading={isFetchingOffers} />
      <Table filters={filters} offers={offers} offersLoading={isFetchingOffers} />
    </div>
  )
})
