import { useTranslation } from 'react-i18next'
import { Offer } from '../../../../domain/dtos/offer'
import { Period } from '../../../../domain/enums/period'
import { formatLocalNumber } from '../../../../domain/vos/format-local-number'
import { CardInfoBox } from '../../../components/card-info-box'
import { useGetNewSubscribers } from '../../../hooks/use-get-new-subscribers'
import { useRefetch } from '../../../hooks/use-refetch'
import { Loading } from './loading'

type NewSubscribersProps = {
  period: Period
  startDate: string
  endDate: string
  offerIds: string[]
  offers: Offer[]
  isLoading: boolean
}

export const NewSubscribers = (props: NewSubscribersProps) => {
  const { period, startDate, endDate, offers, offerIds, isLoading } = props
  const { t } = useTranslation()

  const { data, isFetching, refetch } = useGetNewSubscribers(
    { period, startDate, endDate, offers, offerIds },
    isLoading,
  )
  const isLoadingData = isFetching && data.current === 0

  useRefetch({ period, startDate, endDate, offers, offerIds }, refetch)

  if (isLoadingData || isLoading) {
    return <Loading />
  }

  const getVariation = () => {
    return {
      status: data.status,
      description: t('variation', { previous: formatLocalNumber(data.previous) }),
    }
  }

  return (
    <CardInfoBox
      title={t('newSubscribers.title')}
      description={t('newSubscribers.description')}
      current={formatLocalNumber(data.current)}
      variation={getVariation()}
    />
  )
}
