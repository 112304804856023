import { Card, CardContent } from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'
import { IntegrationProvider } from '../../domain/dtos/integration-provider'
import { ActiveCampaignCardActions } from './active-campaign/active-campaign-card-actions'
import { CademiCardActions } from './cademi/cademi-card-actions'
import { ENotasCardActions } from './enotas/enotas-card-actions'
import { GDigitalCardActions } from './g-digital/g-digital-card-actions'
import { GoogleTagManagerCardActions } from './google-tag-manager/google-tag-manager-card-actions'
import { HublaPixelCardActions } from './hubla-pixel/hubla-pixel-card-actions'
import { HubSpotCardActions } from './hubspot/hubspot-card-actions'
import { LeadLoversCardActions } from './lead-lovers/leadlovers-card-actions'
import { MemberkitCardActions } from './memberkit/memberkit-card-actions'
import { MetaPixelCardActions } from './meta-pixel/meta-pixel-card-actions'
import { OmieCardActions } from './omie/omie-card-actions'
import { RDStationCardActions } from './rdstation/rdstation-card-actions'
import { SellFluxCardActions } from './sell-flux/sell-flux-card-actions'
import { TheMembersCardActions } from './themembers/themembers-card-actions'
import { WebhookCardActions } from './webhook/webhook-card-actions'
import { ZapCertoCardActions } from './zapcerto/zapcerto-actions'
import { ZapierCardActions } from './zapier/zapier-card-actions'

type IntegrationCardProps = {
  provider: IntegrationProvider
  integrationId?: string
  loading: boolean
  refetch: () => void
  setTab: (name: string) => void
}

export const IntegrationCard = ({ provider, integrationId, loading, refetch, setTab }: IntegrationCardProps) => {
  const { t } = useTranslation()

  const actions = {
    [IntegrationProvider.ACTIVE_CAMPAIGN]: (
      <ActiveCampaignCardActions
        provider={provider}
        integrationId={integrationId}
        loading={loading}
        refetch={refetch}
        setTab={setTab}
      />
    ),
    [IntegrationProvider.WEBHOOK]: (
      <WebhookCardActions
        provider={provider}
        integrationId={integrationId}
        loading={loading}
        refetch={refetch}
        setTab={setTab}
      />
    ),
    [IntegrationProvider.GOOGLE_TAG_MANAGER]: <GoogleTagManagerCardActions provider={provider} />,
    [IntegrationProvider.META_PIXEL]: <MetaPixelCardActions provider={provider} />,
    [IntegrationProvider.E_NOTAS]: (
      <ENotasCardActions
        provider={provider}
        integrationId={integrationId}
        loading={loading}
        refetch={refetch}
        setTab={setTab}
      />
    ),
    [IntegrationProvider.ZAPIER]: <ZapierCardActions provider={provider} setTab={setTab} />,
    [IntegrationProvider.HUBLA_PIXEL]: (
      <HublaPixelCardActions
        provider={provider}
        integrationId={integrationId}
        loading={loading}
        refetch={refetch}
        setTab={setTab}
      />
    ),
    [IntegrationProvider.SELL_FLUX]: (
      <SellFluxCardActions
        provider={provider}
        integrationId={integrationId}
        loading={loading}
        refetch={refetch}
        setTab={setTab}
      />
    ),
    [IntegrationProvider.GDIGITAL]: (
      <GDigitalCardActions
        provider={provider}
        integrationId={integrationId}
        loading={loading}
        refetch={refetch}
        setTab={setTab}
      />
    ),
    [IntegrationProvider.ZAPCERTO]: <ZapCertoCardActions provider={provider} />,
    [IntegrationProvider.CADEMI]: (
      <CademiCardActions
        provider={provider}
        integrationId={integrationId}
        loading={loading}
        refetch={refetch}
        setTab={setTab}
      />
    ),
    [IntegrationProvider.MEMBERKIT]: (
      <MemberkitCardActions
        provider={provider}
        integrationId={integrationId}
        loading={loading}
        refetch={refetch}
        setTab={setTab}
      />
    ),
    [IntegrationProvider.LEAD_LOVERS]: (
      <LeadLoversCardActions
        provider={provider}
        integrationId={integrationId}
        loading={loading}
        refetch={refetch}
        setTab={setTab}
      />
    ),
    [IntegrationProvider.THE_MEMBERS]: (
      <TheMembersCardActions
        provider={provider}
        integrationId={integrationId}
        loading={loading}
        refetch={refetch}
        setTab={setTab}
      />
    ),
    [IntegrationProvider.HUBSPOT]: (
      <HubSpotCardActions
        provider={provider}
        integrationId={integrationId}
        loading={loading}
        refetch={refetch}
        setTab={setTab}
      />
    ),
    [IntegrationProvider.OMIE]: (
      <OmieCardActions
        provider={provider}
        integrationId={integrationId}
        loading={loading}
        refetch={refetch}
        setTab={setTab}
      />
    ),
    [IntegrationProvider.RDSTATION]: (
      <RDStationCardActions
        provider={provider}
        integrationId={integrationId}
        loading={loading}
        refetch={refetch}
        setTab={setTab}
      />
    ),
  }

  return (
    <Card className="bg-muted">
      <CardContent className="p-6">
        <div className="flex flex-col items-center justify-between gap-4 w-full">
          <div className="flex flex-col items-center justify-between gap-3 w-full">
            <img src={t(`integrations.${provider}.icon`)} width={48} height={48} alt={provider} className="w-12 h-12" />

            <div className="text-center">
              <h3 className="text-lg font-semibold mb-2">{t(`integrations.${provider}.title`)}</h3>

              <p className="text-sm text-muted-foreground">{t(`integrations.${provider}.description`)}</p>
            </div>
          </div>

          {actions[provider]}
        </div>
      </CardContent>
    </Card>
  )
}
