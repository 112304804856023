import { Button, Card, CardContent, CardHeader, CardTitle, Separator } from '@hub-la/shadcn'
import { Mail, MessageCircle, User } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Phone } from '../../../domain/vos/phone'

type Props = {
  client: {
    name: string
    picture?: string
    id: string
    email: string
    phone: string
  }
}

const InfoRow = ({ label, value }: { label: string; value: string; icon?: React.ElementType }) => (
  <div className="flex items-center justify-between space-x-2">
    <span className="text-sm text-muted-foreground">{label}:</span>
    <span className="text-sm text-right">{value}</span>
  </div>
)

export const EntitiesCards: React.FC<Props> = ({ client }) => {
  const { t } = useTranslation()

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader className="py-2 px-4">
          <CardTitle className="text-sm font-semibold flex justify-between items-center">
            <div className="flex gap-2 items-center">
              <div className="w-7 h-7 bg-secondary rounded-full p-1.5">
                <User className="h-4 w-4" />
              </div>
              {client.name}
            </div>
            <div className="flex space-x-2">
              <Button size="icon" variant="outline" onClick={() => window.open(`mailto:${client.email}`)}>
                <Mail className="w-4 h-4" />
              </Button>
              <Button size="icon" variant="outline" onClick={() => window.open(`https://wa.me/${client.phone}`)}>
                <MessageCircle className="w-4 h-4" />
              </Button>
            </div>
          </CardTitle>
        </CardHeader>
        <Separator />
        <CardContent className="p-4 space-y-4">
          <InfoRow label="ID" value={client.id} />
          <InfoRow label="Email" value={client.email} />
          <InfoRow label="Telefone" value={Phone.build(client.phone).getFormattedNumber()} />
        </CardContent>
      </Card>
    </div>
  )
}
