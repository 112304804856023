import { SectionResponse } from '@hub-la/fe-post'
import {
  Badge,
  Button,
  Card,
  CardContent,
  Input,
  Label,
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  Switch,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@hub-la/shadcn'
import { useFormik } from 'formik'
import { ArrowLeft } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useGetCohorts } from '../../hooks/use-get-cohorts'
import { useSaveSection } from '../../hooks/use-save-section'

export type Values = {
  name: string
  cohortIds: string[]
}

type Props = {
  open: boolean
  onClose: () => void
  section: SectionResponse
}

export const EditorSection: React.FC<Props> = ({ open, onClose, section }) => {
  const { productId } = useParams<{ productId: string }>()
  const { mutateAsync: saveSection, isLoading } = useSaveSection()
  const { data: cohorts = [] } = useGetCohorts(productId)
  const { t } = useTranslation()

  const { values, isValid, submitForm, handleChange, setFieldValue, resetForm, errors } = useFormik<Values>({
    initialValues: {
      name: section.name,
      cohortIds: section.cohortIds ?? [],
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(),
      cohortIds: Yup.array().min(1).required(),
    }),
    validateOnChange: true,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      await saveSection({
        id: section.id,
        payload: {
          name: values.name,
          cohortIds: values.cohortIds,
          productId,
        },
      }).then(() => {
        resetForm()
        onClose()
      })
    },
  })

  /** Reset form and states */
  const onCloseModal = () => {
    resetForm()
    onClose()
  }

  const updateCohortIds = (id: string, shouldAdd: boolean): void => {
    // It should have at least one cohort
    if (values.cohortIds.length <= 1 && !shouldAdd) return

    const newCohortIds = [...values.cohortIds]
    if (shouldAdd) {
      // Add the id if it's not already in the list
      if (!values.cohortIds.includes(id)) {
        setFieldValue('cohortIds', newCohortIds.concat([id]))
      }
    } else {
      // Remove the id if it exists in the list
      setFieldValue(
        'cohortIds',
        values.cohortIds.filter((cohortId) => cohortId !== id),
      )
    }
  }

  return (
    <Sheet open={open} onOpenChange={onCloseModal}>
      <SheetContent className="sm:max-w-lg">
        <SheetHeader className="flex justify-between items-center">
          <ArrowLeft className="text-[#161F00] cursor-pointer" onClick={onClose} />

          <SheetTitle>{t('saveSection.title')}</SheetTitle>
        </SheetHeader>

        <div className="flex flex-col gap-4 mt-6">
          <Input
            id="name"
            name="name"
            type="text"
            placeholder={t('saveSection.name')}
            value={values.name}
            onChange={handleChange}
          />

          <Card>
            <CardContent className="flex flex-col gap-4 pt-6">
              <h4 className="text-sm font-medium">{t('saveSection.cohorts')}</h4>

              {cohorts.map((cohort) => {
                const checked = values.cohortIds.includes(cohort.id)
                return (
                  <div key={cohort.id} className="flex items-center justify-between">
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <div className="flex items-center space-x-2">
                            <Switch
                              checked={checked}
                              onCheckedChange={(checked) => updateCohortIds(cohort.id, checked)}
                              id={`cohort-${cohort.id}`}
                            />

                            <Label htmlFor={`cohort-${cohort.id}`}>{cohort.name}</Label>
                          </div>
                        </TooltipTrigger>

                        {values.cohortIds.length <= 1 && checked && (
                          <TooltipContent>
                            <p>{t('saveSection.shouldHaveCohort')}</p>
                          </TooltipContent>
                        )}
                      </Tooltip>
                    </TooltipProvider>

                    {cohort.isDefault && <Badge variant="secondary">Padrão</Badge>}
                  </div>
                )
              })}

              {Boolean(errors?.cohortIds) && (
                <p className="text-sm text-red-500">{t('createSection.shouldHaveCohort')}</p>
              )}
            </CardContent>
          </Card>
        </div>

        <div className="flex flex-col gap-3 mt-6">
          <Button onClick={submitForm} disabled={!isValid || isLoading}>
            {t('saveSection.confirm')}
          </Button>

          <Button variant="outline" onClick={onCloseModal} disabled={isLoading}>
            {t('saveSection.cancel')}
          </Button>
        </div>
      </SheetContent>
    </Sheet>
  )
}
