export const pt = {
  freeMembers: {
    table: {
      Name: 'Nome',
      PhoneNumber: 'Telefone',
      CreatedAt: 'Entrou em',
      Action: 'Ações',
    },
    modal: {
      remove: {
        title: 'Remover o usuário da whitelist?',
        description: 'Essa ação é irreversível. Não será possível recuperar o usuário.',
        buttons: {
          cancel: 'Cancelar',
          remove: 'Remover',
        },
      },
    },
    generateLink: {
      title: 'Compartilhe o link de convite para sua Whitelist',
      description:
        'Este link só pode ser usado uma vez. Para dar acesso a mais pessoas você precisa gerar vários links.',
    },
    title: 'Membros gratuitos',
    resourceFull: 'Grupo cheio',
    generateInviteLink: 'Gerar link do convite',
    remove: 'Remover',
    copyLink: 'Copiar link',
  },
  share: {
    copied: 'Link copiado',
  },
  errors: {
    nameNotRegistered: '',
    general: 'Ocorreu um erro. Por favor, tente novamente mais tarde.',
  },
}
