import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@hub-la/shadcn'
import { X } from 'lucide-react'
import { useState } from 'react'
import { useRejectRefund } from '../../hooks/use-reject-refund'

type RejectRefundModalProps = {
  refundId: string
  onSuccess?: () => void
}

export const RejectRefundModal = ({ refundId, onSuccess }: RejectRefundModalProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { mutateAsync: rejectRefund, isLoading } = useRejectRefund()

  const handleVisibility = () => setIsOpen(!isOpen)

  const onSubmit = () => {
    rejectRefund(refundId).then(() => {
      handleVisibility()
      onSuccess?.()
    })
  }

  return (
    <Dialog open={isOpen} onOpenChange={handleVisibility}>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          className="text-destructive border-destructive hover:bg-destructive hover:text-destructive-foreground"
        >
          <X className="mr-2 h-4 w-4" />
          Recusar pedido
        </Button>
      </DialogTrigger>

      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="text-2xl font-semibold">Deseja recusar o pedido de reembolso?</DialogTitle>
        </DialogHeader>

        <DialogFooter className="sm:justify-end">
          <div className="flex flex-col-reverse sm:flex-row w-full sm:w-auto gap-2 sm:gap-4">
            <Button variant="outline" onClick={handleVisibility}>
              Cancelar
            </Button>

            <Button variant="destructive" onClick={onSubmit} loading={isLoading}>
              Recusar pedido
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
