import {
  Button,
  Checkbox,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@hub-la/shadcn'
import { useFormik } from 'formik'
import { Check, Download } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { couponStatus } from '../../domain/constants/coupon-status'
import { Offer } from '../../domain/dtos/offer'
import { CouponStatus } from '../../domain/enums/coupon-status'
import { useDownloadCouponsXlsx } from '../hooks/use-download-coupons-xlsx'

type Values = {
  offers: Offer[]
  statuses: CouponStatus[]
}

type ConfirmExportProps = {
  isOpen: boolean
  isLoading: boolean
  onClose: () => void
  onSuccess: () => void
  offers: Offer[]
}

export const ConfirmExportModal = ({ isOpen, onClose, offers, isLoading, onSuccess }: ConfirmExportProps) => {
  const { t } = useTranslation()

  const { values, isValid, submitForm, setFieldValue, resetForm } = useFormik<Values>({
    initialValues: {
      offers: [],
      statuses: [],
    },
    validationSchema: Yup.object().shape({
      offers: Yup.array().min(1),
    }),
    validateOnChange: true,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async () => {
      await exportXlsx().then(() => {
        resetForm()
        onSuccess?.()
      })
    },
  })

  const { mutateAsync: exportXlsx, isLoading: loading } = useDownloadCouponsXlsx({
    offerIds: values.offers.map((offer) => offer.id),
    offers: offers,
    statuses: values.statuses,
  })

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{t('exportXlsx.title')}</DialogTitle>
        </DialogHeader>

        <form onSubmit={submitForm} className="space-y-8">
          <div className="space-y-2">
            <label htmlFor="offers" className="text-sm font-medium text-gray-700">
              {t('exportXlsx.products')}
            </label>

            <Popover>
              <PopoverTrigger asChild disabled={isLoading}>
                <Button variant="outline" className="w-full justify-between">
                  {values.offers.length > 0 ? `${values.offers.length} selected` : 'Buscar ofertas'}
                </Button>
              </PopoverTrigger>

              <PopoverContent className="z-[72] w-[300px] p-0">
                <Command>
                  <CommandInput placeholder="Buscar ofertas" className="h-9" />

                  <CommandEmpty>Nenhum resultado encontrado.</CommandEmpty>

                  <CommandList>
                    <CommandGroup>
                      {offers.map((offer) => {
                        const isSelected = values.offers.includes(offer)

                        return (
                          <CommandItem
                            key={offer.id}
                            onSelect={() => {
                              setFieldValue(
                                'offers',
                                isSelected
                                  ? values.offers.filter((_offer) => _offer.id !== offer.id)
                                  : [...values.offers, offer],
                              )
                            }}
                          >
                            {isSelected && <Check className="mr-2 h-4 w-4" />}

                            <span>{offer.name}</span>
                          </CommandItem>
                        )
                      })}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-700">{t('exportXlsx.statuses')}</label>

            {couponStatus.map((status) => (
              <div key={status.id} className="flex items-center space-x-2">
                <Checkbox
                  id={`status-${status.id}`}
                  checked={values.statuses.includes(status.id)}
                  onCheckedChange={(checked) => {
                    if (checked) {
                      setFieldValue('statuses', [...values.statuses, status.id])
                    } else {
                      setFieldValue(
                        'statuses',
                        values.statuses.filter((s) => s !== status.id),
                      )
                    }
                  }}
                />

                <label
                  htmlFor={`status-${status.id}`}
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  {status.label}
                </label>
              </div>
            ))}
          </div>

          <div className="flex justify-end space-x-4">
            <Button type="button" variant="outline" onClick={onClose} disabled={loading}>
              {t('exportXlsx.cancel')}
            </Button>

            <Button type="submit" disabled={!isValid || loading}>
              <Download className="mr-2 h-4 w-4" />
              {t('exportXlsx.confirm')}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}
