import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'
import { IntegrationProvider } from '../../domain/dtos/integration-provider'
import { useDisableIntegrationById } from '../hooks/use-disable-integration-by-id'

type DisableIntegrationModalProps = {
  provider: IntegrationProvider
  integrationId: string
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

export const DisableIntegrationModal = ({
  provider,
  integrationId,
  isOpen,
  onClose,
  onConfirm,
}: DisableIntegrationModalProps) => {
  const { t } = useTranslation()
  const { mutateAsync: disableIntegration, isLoading } = useDisableIntegrationById()

  const handleDisableIntegration = async () => {
    await disableIntegration({ integrationId })
    onConfirm()
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{t(`integrations.${provider}.disable.title`)}</DialogTitle>
        </DialogHeader>

        <div className="py-4" data-testid="disable-integration-modal-content">
          <p className="text-sm text-gray-500">{t(`integrations.${provider}.disable.description`)}</p>
        </div>

        <DialogFooter>
          <Button variant="outline" onClick={onClose} disabled={isLoading}>
            {t('buttons.cancel')}
          </Button>

          <Button onClick={handleDisableIntegration} disabled={isLoading}>
            {isLoading ? t('common.loading') : t('buttons.confirm')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
