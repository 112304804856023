import { Search } from 'lucide-react'

export const EmptyState = () => {
  return (
    <div data-testid="empty-state" className="w-full h-full flex flex-col items-center justify-center py-30 px-8">
      <div className="p-2 rounded-md border border-gray-200 bg-gray-50">
        <Search className="text-gray-500" size={24} />
      </div>

      <h4 className="mt-6 text-center text-xl font-semibold">Nenhum módulo</h4>

      <p className="mt-1 text-center text-sm text-gray-600 max-w-[400px]">
        Ainda não há módulos adicionados. Adicione módulos e organize seus conteúdos para facilitar o consumo dos seus
        membros.
      </p>
    </div>
  )
}
