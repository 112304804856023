import { Button, Label, useIsMobile } from '@hub-la/shadcn'
import { X } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Member } from '../../../../domain/dtos/member'

interface MembersSelectedsActionsBarProps {
  selectedItems: Member[]
  setSelectedItems: (items: Member[]) => void
  setOpenChangeMembersCohortsModal: (open: boolean) => void
}

export const MembersSelectedsBarActions: React.FC<MembersSelectedsActionsBarProps> = ({
  selectedItems,
  setSelectedItems,
  setOpenChangeMembersCohortsModal,
}) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  if (selectedItems.length === 0) return null

  return (
    <div className="fixed bottom-6 left-1/2 transform -translate-x-1/2 bg-white border border-gray-200 rounded-lg shadow-lg p-4 z-10 w-full md:w-auto">
      <div className="flex flex-col md:flex-row items-center gap-4">
        <div className="flex justify-between w-full md:w-auto">
          <Label>{t('tabs.members.changeMembersCohortsBar.selecteds', { total: selectedItems.length })}</Label>
          {isMobile && <X className="w-4 h-4 cursor-pointer" onClick={() => setSelectedItems([])} />}
        </div>
        <div className="flex flex-col md:flex-row gap-2">
          <Button variant="outline" size="sm" onClick={() => setOpenChangeMembersCohortsModal(true)}>
            {t('tabs.members.changeMembersCohortsBar.changeCohorts', { count: selectedItems.length })}
          </Button>
        </div>
        {!isMobile && <X className="w-4 h-4 cursor-pointer ml-auto" onClick={() => setSelectedItems([])} />}
      </div>
    </div>
  )
}
