import { Image } from 'lucide-react'

const DefaultEditThumbnail = ({ ...props }) => {
  return (
    <div
      className="w-10 h-16 flex justify-center items-center border border-dashed border-gray-300 rounded-sm hover:bg-gray-100 transition-colors"
      {...props}
    >
      <div className="p-1.5 rounded-full bg-gray-100">
        <Image size={16} className="text-gray-500" />
      </div>
    </div>
  )
}

export { DefaultEditThumbnail }
