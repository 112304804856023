import { Button, Card, Skeleton } from '@hub-la/shadcn'
import { ArrowLeft } from 'lucide-react'
import { ContentRow } from './content-row'

export const Loading = () => {
  return (
    <div className="space-y-6">
      <div className="space-y-6">
        <div className="mb-4 self-start">
          <Button variant="ghost" size="sm">
            <ArrowLeft className="mr-2 h-4 w-4" /> Voltar
          </Button>
        </div>

        <div className="mb-6 space-y-5 sm:flex sm:justify-between">
          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <Skeleton className="h-11 w-[165px]" />
              <Skeleton className="h-6 w-12" />
            </div>

            <Skeleton className="h-4 w-[242px]" />
          </div>
        </div>
      </div>

      <div className="grid gap-6 md:grid-cols-12">
        <div className="md:col-span-7">
          <div className="mb-6">
            <Card className="h-full">
              <div className="flex flex-col justify-between gap-5 p-6">
                <div className="space-y-2">
                  <h3 className="text-lg font-bold">Cupom de desconto</h3>
                </div>

                <div className="space-y-2">
                  <Skeleton className="h-6 w-[120px]" />
                  <Skeleton className="h-6 w-[76px]" />
                  <Skeleton className="h-6 w-[80px]" />
                  <Skeleton className="h-6 w-[93px]" />
                  <Skeleton className="h-6 w-[120px]" />
                </div>
              </div>
            </Card>
          </div>
        </div>

        <div className="md:col-span-5">
          <div className="mb-6">
            <Card className="h-full bg-secondary">
              <div className="flex justify-between p-6">
                <div className="w-full space-y-5">
                  <div className="space-y-1">
                    <h4 className="text-2xl font-semibold">Detalhes</h4>
                    <Skeleton className="h-6 w-[10%]" />
                  </div>

                  <ContentRow title="ID do cupom" content={<Skeleton className="h-4 w-[70%]" />} />

                  <ContentRow title="Data de criação" content={<Skeleton className="h-6 w-[70%]" />} />

                  <ContentRow title="Expira em" content={<Skeleton className="h-6 w-[70%]" />} />

                  <ContentRow title="Método de Pagamento" content={<Skeleton className="h-6 w-[70%]" />} />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}
