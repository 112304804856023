import { container } from '@hub-la/fe-container'
import { TelegramVerificationProvider, VerificationStartPage } from '@hub-la/fe-telegram-verification'

export const TelegramVerificationStartInit = () => {
  return (
    <TelegramVerificationProvider container={container}>
      <VerificationStartPage />
    </TelegramVerificationProvider>
  )
}
