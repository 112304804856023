import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { GetIntegrationHistoriesInput } from '../../domain/dtos/get-integration-histories-input'
import { QueryKey } from '../../domain/dtos/query-keys'
import { GetHistories } from '../../usecases/get-histories'

export const useGetHistories = (input: GetIntegrationHistoriesInput) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.getIntegrationHistories, input],
    () => new GetHistories(container.get(HttpClient)).execute(input),
    {
      retry: 3,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      initialData: {
        items: [],
        page: 1,
        pageSize: 1,
        total: 0,
        lastPage: 1,
      },
    },
  )
}
