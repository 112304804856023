import { Button, Card, cn } from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

type IntegrationCardProps = {
  title: string
  description: string
  provider: string
  imageUrl: string
  disabled?: boolean
  vertical?: boolean
  loading?: boolean
}

export const IntegrationCard = ({
  title,
  description,
  provider,
  imageUrl,
  disabled,
  vertical,
  loading,
}: IntegrationCardProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  const handleConfigureIntegration = () => {
    return history.push({
      pathname: '/dashboard/integrations',
      search: `provider=${provider}`,
    })
  }

  return (
    <Card
      className={cn(
        'p-4 sm:p-6 w-full flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4 transition hover:-translate-y-0.5',
        vertical && 'flex-col items-center text-center',
        disabled ? 'bg-muted' : 'bg-card',
        !vertical && 'border border-border',
      )}
    >
      <div className={cn('flex flex-col sm:flex-row sm:items-center gap-3', vertical && 'flex-col items-center')}>
        <img src={imageUrl} width={48} height={48} alt={title} className="w-12 h-12" />

        <div className={cn('text-left', vertical && 'text-center')}>
          <h3 className="text-md font-semibold">{title}</h3>

          <p className="text-sm text-muted-foreground mt-2">{description}</p>
        </div>
      </div>

      <Button
        loading={loading}
        disabled={disabled}
        className={cn('w-full sm:w-auto', vertical && 'w-full')}
        onClick={handleConfigureIntegration}
      >
        {t('buttons.settings')}
      </Button>
    </Card>
  )
}
