import { AssetResponse } from '@hub-la/fe-asset'
import { isEmpty } from 'lodash'
import { memo, useEffect } from 'react'
import { CheckSectionMustBeExpanded } from '../../../usecases/check-section-must-be-expanded'
import { useRealtimeSections } from '../../hooks'
import { useGetSections } from '../../hooks/use-get-sections'
import { LoadingState } from './loading-state'
import { SidebarContent } from './sidebar-content'

type SidebarProps = {
  productId: string
  post: {
    id: string
    cover?: AssetResponse
    title: string
  }
  isBeta?: boolean
}

export const Sidebar = memo(({ productId, post }: SidebarProps) => {
  const { isFetching, data } = useGetSections({ productId })
  const dataSource = data ?? []
  const isLoading = isFetching && isEmpty(data)

  const { dispatch } = useRealtimeSections()

  useEffect(() => {
    dispatch({ type: 'add', sections: data ?? [] })
  }, [data, dispatch])

  const defaultSection = new CheckSectionMustBeExpanded().execute({ sections: dataSource, postId: post.id })

  return (
    <div className="w-full lg:flex-1 rounded-lg border bg-zinc-800/40" data-testid="sidebar">
      {isLoading || !data ? (
        <LoadingState />
      ) : (
        <SidebarContent defaultSection={defaultSection} postId={post.id} productId={productId} />
      )}
    </div>
  )
})
