import { Badge, Separator, Table, TableBody, TableCell, TableRow } from '@hub-la/shadcn'
import { Calendar, Package } from 'lucide-react'
import { useTranslation } from 'react-i18next'

export const SmartInstallmentInvoiceRelations = ({ products, subscription }) => {
  const { t } = useTranslation()

  const rows = [
    {
      icon: <Package className="h-5 w-5" />,
      content: (
        <div className="flex flex-wrap gap-2">
          {products.map((product) => (
            <div>
              <p className="text-base font-medium">Produto</p>
              <p className="text-base text-muted-foreground">
                {product.name} –– {product.id}
              </p>
            </div>
          ))}
        </div>
      ),
    },
    {
      icon: <Calendar className="h-5 w-5" />,
      content: subscription && (
        <div>
          <div className="flex gap-2">
            <p className="text-base font-medium">Assinatura</p>
            <Badge variant={'outline'}>{t(subscription.status)}</Badge>
          </div>
          <p className="text-base text-muted-foreground">Vence em {subscription.credits} dias</p>
        </div>
      ),
    },
  ]

  return (
    <div className="space-y-4">
      <h2 className="text-lg font-bold">Conexões</h2>
      <Separator />
      <div className="rounded-md border">
        <Table>
          <TableBody className="bg-white">
            {rows.map((row, index) => (
              <TableRow className="min-h-[56px] max-h-[56px]" key={index}>
                <TableCell className="w-20">
                  <div className="w-min bg-secondary rounded-full p-3"> {row.icon}</div>
                </TableCell>
                <TableCell>{row.content}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}
