import React from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { styled } from '@hub-la/design-system'
import CircularProgress from '@mui/material/CircularProgress'

export interface Props {
  url?: string
  height: number | string
  width: number | string
}

const StyledDocument = styled(Document)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  object-fit: contain;
`

export const Pdf = ({ url, height, width }: Props): JSX.Element => {
  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  return (
    <StyledDocument loading={<CircularProgress />} file={url} renderMode="canvas">
      <Page
        key={'page_1'}
        width={width}
        height={height}
        className="document-pdf-page"
        pageNumber={1}
        renderAnnotationLayer={false}
      />
    </StyledDocument>
  )
}
