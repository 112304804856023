import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'

type ConfirmDeleteModalProps = {
  isOpen: boolean
  isDeleting?: boolean
  collaboratorName?: string
  onClose: () => void
  onConfirm: () => void
}

export const ConfirmDeleteModal = ({
  isOpen,
  isDeleting = false,
  collaboratorName,
  onClose,
  onConfirm,
}: ConfirmDeleteModalProps) => {
  const { t } = useTranslation()

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-lg flex flex-col gap-2">
        <DialogHeader>
          <DialogTitle>{t('deleteCollaboratorModal.title', { collaboratorName })}</DialogTitle>
        </DialogHeader>

        <p className="text-sm text-gray-500">{t('deleteCollaboratorModal.description')}</p>

        <DialogFooter>
          <Button variant="outline" onClick={onClose} disabled={isDeleting}>
            {t('deleteCollaboratorModal.buttons.cancel')}
          </Button>

          <Button variant="default" onClick={onConfirm} loading={isDeleting}>
            {t('deleteCollaboratorModal.buttons.confirm')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
