import isEqual from 'lodash/isEqual'

export class GetDefaultSmartInstallmentsFilters {
  public execute() {
    return {
      statuses: [],
      page: 1,
      pageSize: '10',
      offerIds: [],
      search: '',
      dateRangeBy: 'createdAt',
      startDate: '',
      endDate: '',
    }
  }

  public countDifferentProperties(filters): number {
    const templateFilters = this.execute()
    return Object.keys(filters).reduce((count, key) => {
      // Exclude page, pageSize, period, dateRangeBy, startDate and endDate from the count
      if (['page', 'pageSize', 'dateRangeBy', 'startDate', 'endDate'].includes(key)) {
        return count
      }
      // For other properties, compare with the template
      return !isEqual(filters[key], templateFilters[key]) ? count + 1 : count
    }, 0)
  }

  public getDifferentPropertiesString(filters): string {
    const count = this.countDifferentProperties(filters)

    return count > 0 ? `(${count})` : ''
  }
}
