import {
  CreatePostRequest,
  PostResponse,
  PostStatus,
  SectionResponse,
  UpdatePostRequest,
  useSavePost,
} from '@hub-la/fe-post'
import {
  Alert,
  AlertDescription,
  Button,
  Calendar,
  Card,
  CardContent,
  cn,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  Input,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
  RadioGroup,
  RadioGroupItem,
} from '@hub-la/shadcn'
import { format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import { isEqual, isNaN } from 'lodash'
import { AlarmClock, CalendarIcon, Lightbulb } from 'lucide-react'
import moment, { Moment } from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CreateSectionRequest } from '../../../domain/dtos'
import { useSaveSection } from '../../hooks/use-save-section'

type Props = {
  open: boolean
  onClose: () => void
  section?: SectionResponse
  post?: PostResponse
  productId: string
}

const DEFAULT_VALUES = [2, 4, 7]
const CUSTOM_VALUE = -1

export const EditorReleaseDays: React.FC<Props> = ({ open, onClose, section, post, productId }) => {
  const type = section ? 'section' : post ? 'post' : undefined
  const entity = type === 'section' ? section : post
  const defaultValue = entity?.releaseAfterDays
    ? DEFAULT_VALUES.find((it) => it === entity.releaseAfterDays) ?? -1
    : undefined
  const defaultCustomValue = DEFAULT_VALUES.includes(entity?.releaseAfterDays ?? -1)
    ? undefined
    : entity?.releaseAfterDays
  const defaultReleaseAtDate = entity?.releaseAt ? moment(entity.releaseAt) : null
  const [releaseDays, setReleaseDays] = useState<number | undefined>(defaultValue)
  const [customValue, setCustomValue] = useState<number | undefined>(defaultCustomValue)
  const [releaseAtDate, setReleaseAtDate] = useState<Moment | null>(defaultReleaseAtDate)
  const [releaseTime, setReleaseTime] = useState<string>(
    defaultReleaseAtDate ? format(defaultReleaseAtDate.toDate(), 'HH:mm') : '00:00',
  )

  const { t } = useTranslation()
  const saveSection = useSaveSection()
  const savePost = useSavePost()

  const releaseAfterDays = releaseDays === -1 ? customValue : releaseDays
  const isDisabled =
    (isEqual(entity?.releaseAfterDays, releaseAfterDays) ?? !releaseAfterDays) &&
    (isEqual(entity?.releaseAt ?? null, releaseAtDate) ?? !releaseAtDate)

  const handleSave = () => {
    let finalReleaseDate: Date | null = null
    if (releaseAtDate) {
      const [hours, minutes] = releaseTime.split(':').map(Number)
      finalReleaseDate = releaseAtDate.toDate()
      finalReleaseDate.setHours(hours, minutes)
    }

    if (section) {
      const payload: CreateSectionRequest = {
        name: section.name,
        releaseAfterDays: !finalReleaseDate ? releaseAfterDays : null,
        releaseAt: finalReleaseDate,
        productId,
      }
      saveSection.mutateAsync({ payload, id: section.id }).then((res: SectionResponse | undefined) => {
        if (res) {
          onClose()
        }
      })
    } else if (post) {
      const payload: CreatePostRequest | UpdatePostRequest = {
        title: post.title,
        status: post.status as PostStatus,
        releaseAfterDays: !finalReleaseDate ? releaseAfterDays : null,
        releaseAt: finalReleaseDate,
        productId,
      }
      savePost.mutateAsync({ payload, id: post.id }).then((res: PostResponse | undefined) => {
        if (res) {
          onClose()
        }
      })
    }
  }

  const handleRemove = () => {
    if (section) {
      const payload: CreateSectionRequest = {
        name: section.name,
        releaseAfterDays: null,
        releaseAt: null,
        productId,
      }
      saveSection.mutateAsync({ payload, id: section.id }).then((res: SectionResponse | undefined) => {
        if (res) {
          setReleaseAtDate(null)
          onClose()
        }
      })
    } else if (post) {
      const payload: CreatePostRequest | UpdatePostRequest = {
        title: post.title,
        status: post.status as PostStatus,
        releaseAfterDays: null,
        releaseAt: null,
        productId,
      }
      savePost.mutateAsync({ payload, id: post.id }).then((res: PostResponse | undefined) => {
        if (res) {
          setReleaseAtDate(null)
          onClose()
        }
      })
    }
  }

  const hasReleaseSettings = !!entity?.releaseAfterDays || !!entity?.releaseAt

  const handleClose = () => {
    setReleaseDays(defaultValue)
    setCustomValue(defaultCustomValue)
    onClose()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    const sanitizedValue = inputValue.replace(/\D/g, '')
    const integerValue = Math.min(Math.max(parseInt(sanitizedValue, 10), 1), 99)
    setCustomValue(isNaN(integerValue) ? undefined : integerValue)
  }

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-lg">
        <DialogHeader>
          <DialogTitle>{t('editReleaseDays.title')}</DialogTitle>

          <DialogDescription>
            <span className="font-normal">
              {t(section ? 'editReleaseDays.subtitle' : 'editReleaseDays.subtitleForPost')}
            </span>
          </DialogDescription>
        </DialogHeader>

        <div className="grid gap-6 py-4">
          <Alert className=" bg-gray-100">
            <Lightbulb className="h-4 w-4" />

            <AlertDescription>{t('editReleaseDays.tooltip')}</AlertDescription>
          </Alert>

          <div className="grid grid-cols-2 gap-4">
            <Card
              className={`cursor-pointer ${!releaseAtDate ? 'border-primary' : ''}`}
              onClick={() => {
                setReleaseDays(defaultValue)
                setCustomValue(defaultCustomValue)
                setReleaseAtDate(null)
              }}
            >
              <CardContent className="flex flex-col justify-center p-4">
                <AlarmClock className=" mb-2 h-6 w-6" />

                <span className="text-sm">{t('editReleaseDays.daysAfterPurchase')}</span>
              </CardContent>
            </Card>

            <Card
              className={`cursor-pointer ${releaseAtDate ? 'border-primary' : ''}`}
              onClick={() => {
                setReleaseDays(undefined)
                setCustomValue(undefined)
                setReleaseAtDate(moment())
              }}
            >
              <CardContent className="flex flex-col justify-center p-4">
                <CalendarIcon className="mb-2 h-6 w-6" />

                <span className="text-sm">{t('editReleaseDays.onSpecificDate')}</span>
              </CardContent>
            </Card>
          </div>

          {releaseAtDate ? (
            <div className="grid gap-2">
              <Label htmlFor="releaseDate">{t('editReleaseDays.onSpecificDate')}</Label>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant={'outline'}
                    className={cn(
                      'w-full justify-start text-left font-normal',
                      !releaseAtDate && 'text-muted-foreground',
                    )}
                  >
                    <CalendarIcon className="mr-2 h-4 w-4" />

                    {releaseAtDate ? format(releaseAtDate.toDate(), 'PPP', { locale: ptBR }) : <span>Data</span>}
                  </Button>
                </PopoverTrigger>

                <PopoverContent className="w-auto p-0 z-10002">
                  <Calendar
                    mode="single"
                    selected={releaseAtDate.toDate()}
                    onDayClick={(date: Date | undefined) => setReleaseAtDate(moment(date) || null)}
                  />
                </PopoverContent>
              </Popover>

              <div>
                <Label htmlFor="releaseTime">Horário</Label>

                <input
                  type="time"
                  id="releaseTime"
                  value={releaseTime}
                  onChange={(e) => setReleaseTime(e.target.value)}
                  className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                />
              </div>
            </div>
          ) : (
            <div className="space-y-6">
              <RadioGroup value={releaseDays?.toString()} onValueChange={(value) => setReleaseDays(Number(value))}>
                {DEFAULT_VALUES.map((value) => (
                  <div className="flex items-center space-x-2" key={value}>
                    <RadioGroupItem value={value.toString()} id={`option-${value}`} />

                    <Label htmlFor={`option-${value}`}>{`${value} dias`}</Label>
                  </div>
                ))}
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value={CUSTOM_VALUE.toString()} id="option-custom" />

                  <Label htmlFor="option-custom">{t('editReleaseDays.customValue')}</Label>
                </div>
              </RadioGroup>
              {releaseDays === CUSTOM_VALUE && (
                <div className="grid gap-2">
                  <Label htmlFor="customValue">{t('editReleaseDays.customValueLabel')}</Label>

                  <Input id="customValue" value={customValue} onChange={handleChange} className="col-span-3" />

                  <Label className="text-xs"> {t('editReleaseDays.customValueDescription')} </Label>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="flex justify-end space-x-2">
          {hasReleaseSettings && (
            <Button variant="outline" onClick={handleRemove} disabled={saveSection.isLoading || savePost.isLoading}>
              {t('editReleaseDays.cancel')}
            </Button>
          )}

          <Button onClick={handleSave} disabled={isDisabled || saveSection.isLoading || savePost.isLoading}>
            {t('editReleaseDays.save')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
