import React from 'react'
import DefaultGroupImage from '../../assets/default-group-avatar.png'

interface ProductHeaderProps {
  group: {
    picture?: string | null
    name: string
    owner: string
  }
}

export const ProductHeader: React.FC<ProductHeaderProps> = ({ group }) => {
  return (
    <div className="flex flex-col space-y-6 px-6 pt-6">
      <div className="flex items-center space-x-4 pb-4">
        <div className="relative">
          <img
            src={group.picture ?? DefaultGroupImage}
            width={116}
            height={68}
            className="object-cover rounded"
            alt="Product image"
          />
        </div>
        <div>
          <h2 className="text-base font-bold">{group.name}</h2>
        </div>
      </div>
      <hr className="border-t border-gray-200" />
    </div>
  )
}
