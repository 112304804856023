import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableEmptyState,
  TableHead,
  TableHeader,
  TablePagination,
  TableRow,
} from '@hub-la/shadcn'
import isEmpty from 'lodash/isEmpty'
import times from 'lodash/times'
import { MessageSquare } from 'lucide-react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Subscriber } from '../../../../domain/dtos/waitlist'
import { ParseFirebaseDate } from '../../../../usecases/waitlist/parse-firebase-date'
import { TableLoading } from './table-loading'
import { WaitlistStatusStats } from './waitlist-status-stats'

export const WaitlistTable = ({ filters, setFilters, isLoading, data }) => {
  const { page, pageSize } = filters

  const { t } = useTranslation()

  const headCells = [
    { key: 'name', label: 'Nome' },
    { key: 'email', label: 'Email' },
    { key: 'phonenumber', label: 'Telefone' },
    { key: 'date', label: 'Data' },
    { key: 'status', label: 'Status' },
    { key: 'action', label: '' },
  ]

  const isRowsEmpty = isEmpty(data?.subscribers)
  const canRenderEmptyState = !isLoading && isRowsEmpty

  return (
    <div className="flex flex-col gap-4">
      <div className="rounded-lg border bg-white">
        <Table>
          <TableHeader>
            <TableRow>
              {headCells.map((cell) => (
                <TableHead key={cell.key}>{cell.label}</TableHead>
              ))}
            </TableRow>
          </TableHeader>

          <TableBody>
            {isLoading
              ? times(pageSize, (i) => <TableLoading key={`skeleton-row-${i}`} />)
              : (data?.subscribers ?? []).map((waitlist: Subscriber) => {
                  const createdAtDate = new ParseFirebaseDate(waitlist.createdAt).toDate()

                  return (
                    <TableRow key={waitlist.id}>
                      <TableCell>{waitlist.name}</TableCell>

                      <TableCell>{waitlist.email}</TableCell>

                      <TableCell>{waitlist.phoneNumber}</TableCell>

                      <TableCell className="text-muted-foreground font-light">
                        {moment(createdAtDate).format('D MMM. YYYY')}
                      </TableCell>

                      <TableCell>
                        <WaitlistStatusStats status={waitlist.member.status} />
                      </TableCell>

                      <TableCell>
                        <div className="flex justify-end mr-3">
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() =>
                              window.open(`https://wa.me/${waitlist.phoneNumber.replace('+', '')}`, '_blank')
                            }
                          >
                            <MessageSquare className="h-4 w-4" />
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  )
                })}
          </TableBody>
        </Table>

        {canRenderEmptyState && <TableEmptyState title={t('waitlist.empty')} subtitle={t('waitlist.emptySubtitle')} />}
      </div>

      <TablePagination
        page={page}
        setPage={(page) => setFilters({ ...filters, page })}
        lastPage={data?.lastPage ?? 1}
        pageSize={pageSize}
        setPageSize={(pageSize) => setFilters({ ...filters, pageSize })}
      />
    </div>
  )
}

export default WaitlistTable
