import { SnackbarProvider, theme, ThemeProvider } from '@hub-la/design-system'
import { AuthenticationProvider, AutoAuthPage } from '@hub-la/fe-auth'
import { container, ContainerProvider } from '@hub-la/fe-container'
import { RoleplayModeAlert, RoleplayPermissionProvider, RoleplayProvider } from '@hub-la/fe-roleplay'
import { Toaster } from '@hub-la/shadcn'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createBrowserHistory } from 'history'
import { Route, Router, Switch } from 'react-router-dom'
import { DashboardContainer } from './components/dashboard-container'
import { PrivateRoute } from './components/private-route'
import { UserContainer } from './components/user-container'
import { AffiliationProgramInit } from './pages/affiliation-program-init'
import { CoProductionInit } from './pages/co-production-init'
import { CohortInit } from './pages/cohort-init'
import { CreateCollaboratorInit } from './pages/create-collaborator'
import { CreatePostInit } from './pages/create-post-init'
import { DashboardAffiliatesInit } from './pages/dashboard-affiliates-init'
import { DashboardCouponsInit } from './pages/dashboard-coupons-init'
import { DashboardEventsInit } from './pages/dashboard-events-init'
import { DashboardFinancialInit } from './pages/dashboard-financial-init'
import { DashboardHomeInit } from './pages/dashboard-home-init'
import { DashboardIntegrationsInit } from './pages/dashboard-integrations-init'
import { DashboardInvoiceDetailInit } from './pages/dashboard-invoice-detail-init'
import { DashboardProductsInit } from './pages/dashboard-products-init'
import { DashboardRefundsInit } from './pages/dashboard-refunds-init'
import { DashboardSalesInit } from './pages/dashboard-sales-init'
import { DashboardSettingsInit } from './pages/dashboard-settings-init'
import { DashboardSubscriptionDetailInit } from './pages/dashboard-subscriptions-detail-init'
import { DashboardSubscriptionsInit } from './pages/dashboard-subscriptions-init'
import { EditCollaboratorInit } from './pages/edit-collaborator'
import { EditOrderInit } from './pages/edit-order-init'
import { EditProductInit } from './pages/edit-product-init'
import { ExportInit } from './pages/export-init'
import { FreeMembersInit } from './pages/free-members-init'
import { OfferInit } from './pages/offer-init'
import { PostDetailInit } from './pages/post-detail-init'
import { PrivacyPolicyInit } from './pages/privacy-policy'
import { RecoverPasswordInit } from './pages/recover-password-init'
import { RecoverPasswordTokenInit } from './pages/recover-password-token-init'
import { RoleplayInit } from './pages/roleplay-init'
import { SelectUserNavigationInit } from './pages/select-user-navigation'
import { SignInEmailInit } from './pages/signin-email-init'
import { SignInInit } from './pages/signin-init'
import { SignInSmsInit } from './pages/signin-sms-init'
import { SignInWhatsappInit } from './pages/signin-whatsapp-init'
import { SignUpInit } from './pages/signup-init'
import { TelegramVerificationDoneInit } from './pages/telegram-verification-done-init'
import { TelegramVerificationInitInit } from './pages/telegram-verification-init-init'
import { TelegramVerificationIntroInit } from './pages/telegram-verification-intro-init'
import { TelegramVerificationQRCodeInit } from './pages/telegram-verification-qrcode-init'
import { TelegramVerificationStartInit } from './pages/telegram-verification-start-init'
import { TermsOfUseInit } from './pages/terms-of-use-init'
import { TicketInit } from './pages/ticket-init'
import { UserGroupsInit } from './pages/user-groups'
import { UserInvoicesInit } from './pages/user-invoices'
import { UserInvoicesDetailsInit } from './pages/user-invoices-details'
import { UserInvoicesDownloadInit } from './pages/user-invoices-download'
import { WelcomeExperienceInit } from './pages/welcome-experience-init'
import { WhitelistInit } from './pages/whitelist-init'

const client = new QueryClient()

const history = createBrowserHistory()

export default function App() {
  return (
    <ContainerProvider container={container}>
      <ThemeProvider theme={theme.light}>
        <SnackbarProvider>
          <QueryClientProvider client={client}>
            <RoleplayPermissionProvider>
              <RoleplayProvider>
                <RoleplayModeAlert />
              </RoleplayProvider>
              <Router history={history}>
                <Switch>
                  <AuthenticationProvider>
                    <Route exact={true} path="/autoauth/:id" component={AutoAuthPage} />
                    {/* Routes for unauthenticated users */}
                    <Route exact={true} path="/signup" component={SignUpInit} />
                    <Route exact={true} path="/signin" component={SignInInit} />
                    <Route exact={true} path="/signin/sms" component={SignInSmsInit} />
                    <Route exact={true} path="/signin/whatsapp" component={SignInWhatsappInit} />
                    <Route exact={true} path="/signin/email" component={SignInEmailInit} />
                    <Route exact={true} path="/recover-password" component={RecoverPasswordInit} />
                    <Route exact={true} path="/recover-password/:token" component={RecoverPasswordTokenInit} />
                    <Route exact={true} path="/welcome-experience" component={WelcomeExperienceInit} />
                    <Route exact={true} path="/post/:postId" component={PostDetailInit} />
                    <Route exact={true} path="/terms-of-use" component={TermsOfUseInit} />
                    <Route exact={true} path="/privacy-policy" component={PrivacyPolicyInit} />

                    {/* Protected routes for authenticated users */}
                    <Route
                      path={[
                        '/user_groups',
                        '/user_invoices',
                        '/user_invoices/:invoiceId',
                        '/user_invoices/:invoiceId/pdf',
                        '/group_affiliate/:groupId',
                      ]}
                      exact
                    >
                      <Switch>
                        <UserContainer>
                          <PrivateRoute exact={true} path="/user_groups" component={UserGroupsInit} />
                          <PrivateRoute exact={true} path="/user_invoices" component={UserInvoicesInit} />
                          <PrivateRoute
                            exact={true}
                            path="/user_invoices/:invoiceId"
                            component={UserInvoicesDetailsInit}
                          />
                          <PrivateRoute
                            exact={true}
                            path="/user_invoices/:invoiceId/pdf"
                            component={UserInvoicesDownloadInit}
                          />
                          <PrivateRoute
                            exact={true}
                            path="/group_affiliate/:groupId"
                            component={AffiliationProgramInit}
                          />
                        </UserContainer>
                      </Switch>
                    </Route>

                    {/* Protected routes for authenticated users */}
                    <PrivateRoute exact={true} path="/" component={SelectUserNavigationInit} />
                    <PrivateRoute exact={true} path="/groups_order" component={EditOrderInit} />
                    <PrivateRoute exact={true} path="/edit/:productId/offers/:offerId" component={OfferInit} />
                    <PrivateRoute exact={true} path="/edit/:productId/cohorts/:cohortId" component={CohortInit} />
                    <PrivateRoute exact={true} path="/edit/:productId/tickets/:ticketId" component={TicketInit} />
                    <PrivateRoute
                      exact={true}
                      path="/verification-telegram/init"
                      component={TelegramVerificationInitInit}
                    />
                    <PrivateRoute
                      exact={true}
                      path="/verification-telegram/intro"
                      component={TelegramVerificationIntroInit}
                    />
                    <PrivateRoute
                      exact={true}
                      path="/verification-telegram/start"
                      component={TelegramVerificationStartInit}
                    />
                    <PrivateRoute
                      exact={true}
                      path="/verification-telegram/qrcode"
                      component={TelegramVerificationQRCodeInit}
                    />
                    <PrivateRoute
                      exact={true}
                      path="/verification-telegram/done"
                      component={TelegramVerificationDoneInit}
                    />
                    <PrivateRoute
                      exact={true}
                      path="/hub/create_post/:productId/:sectionId"
                      component={CreatePostInit}
                    />

                    {/* Routes without padding */}
                    <Route path={['/edit/:productId', '/edit/:productId/:menu']} exact>
                      <Switch>
                        <DashboardContainer disablePadding>
                          <PrivateRoute exact={true} path="/edit/:productId" component={EditProductInit} />
                          <PrivateRoute exact={true} path="/edit/:productId/:menu" component={EditProductInit} />
                        </DashboardContainer>
                      </Switch>
                    </Route>

                    {/* Routes without sidebar */}
                    <Route path={['/roleplay', '/whitelist/*', '/export/*', '/free_members/*']} exact>
                      <Switch>
                        <DashboardContainer hideSidebar disablePadding disableAppHeader>
                          <PrivateRoute exact={true} path="/roleplay" component={RoleplayInit} />
                          <PrivateRoute
                            exact={true}
                            path="/free_members/:groupResourceId/:productId/:groupId"
                            component={FreeMembersInit}
                          />
                          <PrivateRoute exact={true} path="/whitelist/:groupId" component={WhitelistInit} />
                          <PrivateRoute exact={true} path="/export/:exportId" component={ExportInit} />
                        </DashboardContainer>
                      </Switch>
                    </Route>

                    {/* Routes with sidebar */}
                    <Route exact path={['/dashboard', '/dashboard/*', '/co-production/*']}>
                      <Switch>
                        <DashboardContainer>
                          <PrivateRoute exact={true} path="/dashboard" component={DashboardHomeInit} />
                          <PrivateRoute exact={true} path="/dashboard/sales" component={DashboardSalesInit} />
                          <PrivateRoute
                            exact={true}
                            path="/dashboard/sales/:invoiceId"
                            component={DashboardInvoiceDetailInit}
                          />
                          <PrivateRoute exact={true} path="/dashboard/refunds" component={DashboardRefundsInit} />
                          <PrivateRoute exact={true} path="/dashboard/affiliates" component={DashboardAffiliatesInit} />
                          <PrivateRoute exact={true} path="/dashboard/coupons" component={DashboardCouponsInit} />
                          <PrivateRoute exact={true} path="/dashboard/financial" component={DashboardFinancialInit} />
                          <PrivateRoute
                            exact={true}
                            path="/dashboard/subscriptions"
                            component={DashboardSubscriptionsInit}
                          />
                          <PrivateRoute
                            exact={true}
                            path="/dashboard/subscriptions/:subscriptionId"
                            component={DashboardSubscriptionDetailInit}
                          />
                          <PrivateRoute exact={true} path="/dashboard/products" component={DashboardProductsInit} />
                          <PrivateRoute exact={true} path="/dashboard/events" component={DashboardEventsInit} />
                          <PrivateRoute
                            PrivateRoute
                            exact={true}
                            path="/dashboard/integrations"
                            component={DashboardIntegrationsInit}
                          />
                          <PrivateRoute exact={true} path="/dashboard/settings" component={DashboardSettingsInit} />
                          <PrivateRoute
                            exact={true}
                            path="/dashboard/collaborators/create"
                            component={CreateCollaboratorInit}
                          />
                          <PrivateRoute
                            exact={true}
                            path="/dashboard/collaborators/:id"
                            component={EditCollaboratorInit}
                          />
                          <PrivateRoute
                            exact={true}
                            path="/co-production/:groupId/:receiverId?"
                            component={CoProductionInit}
                          />
                        </DashboardContainer>
                      </Switch>
                    </Route>
                  </AuthenticationProvider>
                </Switch>
              </Router>
              <Toaster />
            </RoleplayPermissionProvider>
          </QueryClientProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </ContainerProvider>
  )
}
