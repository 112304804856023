import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@hub-la/shadcn'
import { Plus, RefreshCw, Trash2 } from 'lucide-react'
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IntervalType } from '../../../../../../domain/enums/interval-type.enum'
import { formatLocalNumber } from '../../../../../../domain/vos/format-local-number'
import { CurrencyField } from '../../../../../components/currency-field'
import { InstallmentsSelect } from '../../../../../components/installments-select'
import { PlansSelect } from '../../../../../components/plans-select'
import { PromotionField } from '../../../../../components/promotion-field'
import { useGetProduct } from '../../../../../hooks/use-get-product'
import { OfferSchemaValidationType } from '../../../../../validations/offer-validation'

const accessDays = [
  {
    value: '30',
    label: 'accessDays.oneMonth',
  },
  {
    value: '90',
    label: 'accessDays.threeMonths',
  },
  {
    value: '180',
    label: 'accessDays.sixMonths',
  },
  {
    value: '270',
    label: 'accessDays.nineMonths',
  },
  {
    value: '365',
    label: 'accessDays.oneYear',
  },
  {
    value: '730',
    label: 'accessDays.twoYears',
  },
  {
    value: '1095',
    label: 'accessDays.threeYears',
  },
  {
    value: 'lifetime',
    label: 'accessDays.indefinite',
  },
]

const OneTimeCard = () => {
  const { t } = useTranslation()

  return (
    <Card>
      <CardContent className="pt-6">
        <div className="grid w-full items-center gap-4">
          <Controller
            render={({ field: { name, onChange, value, ...props }, fieldState }) => (
              <CurrencyField
                label={t('offer.pricingTab.pricing.price.label')}
                error={Boolean(fieldState.error?.type)}
                helperText={fieldState.error?.type && t(`fieldErrors.${fieldState.error?.type}`)}
                onChange={onChange}
                value={value}
                name={name}
                {...props}
              />
            )}
            name="pricing.price"
          />
          <Controller
            render={({ field: { name, onChange, value, ...props }, fieldState }) => (
              <PromotionField
                label={t('offer.pricingTab.pricing.promotion.label')}
                error={Boolean(fieldState.error?.type)}
                helperText={fieldState.error?.type && t(`fieldErrors.${fieldState.error?.type}`)}
                onChange={onChange}
                value={value}
                name={name}
                {...props}
              />
            )}
            name="pricing.oldPrice"
          />
          <Controller
            render={({ field: { onChange, value, name }, fieldState }) => (
              <InstallmentsSelect
                label={t('offer.pricingTab.pricing.installments.label')}
                maxInstallment={12}
                error={Boolean(fieldState.error?.type)}
                helperText={fieldState.error?.type && t(`fieldErrors.${fieldState.error?.type}`)}
                onChange={onChange}
                value={value}
                name={name}
              />
            )}
            name="pricing.installments"
          />
          <Controller
            render={({ field, fieldState }) => (
              <div className="space-y-2">
                <Select {...field} value={field.value} onValueChange={field.onChange}>
                  <SelectTrigger className={`w-full ${fieldState.error ? 'border-red-500' : ''}`}>
                    <SelectValue placeholder={t('offer.pricingTab.pricing.accessDays.placeholder')} />
                  </SelectTrigger>
                  <SelectContent>
                    {accessDays.map(({ label, value }) => (
                      <SelectItem key={value} value={value}>
                        {t(label)}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {fieldState.error && (
                  <p className="text-sm text-red-500">{t(`fieldErrors.${fieldState.error.type}`)}</p>
                )}
              </div>
            )}
            name="pricing.accessDays"
          />
        </div>
      </CardContent>
    </Card>
  )
}

const maxIntstallmentsByInterval = {
  [IntervalType.MONTHLY]: 1,
  [IntervalType.QUARTERLY]: 3,
  [IntervalType.SEMIANNUALLY]: 6,
  [IntervalType.ANNUALLY]: 12,
}

const RecurringCard = ({
  productName,
  defaultInstallments,
  onDelete,
  showDelete,
  index,
}: {
  productName?: string
  // Index of FieldArray item
  index: number
  defaultInstallments: number
  onDelete: () => void
  showDelete: boolean
}) => {
  const { t } = useTranslation()
  const { watch, setValue } = useFormContext<OfferSchemaValidationType>()

  const plans = watch('pricing.plans') ?? []
  const plansSelected = plans.map((price) => price.interval as IntervalType)
  const planType = watch(`pricing.plans.${index}.interval`)
  const maxInstallment = maxIntstallmentsByInterval[planType]
  const planPrice = watch(`pricing.plans.${index}.price`)
  const formattedPrice = formatLocalNumber(planPrice / 100)

  const oldPrice = watch(`pricing.plans.${index}.oldPrice`)
  const formattedOldPrice = oldPrice ? formatLocalNumber(oldPrice / 100) : null

  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between space-y-0">
        <div className="flex items-center space-x-4">
          <div className="p-4 bg-gray-100 rounded-md">
            <RefreshCw className="h-4 w-4 text-gray-600" />
          </div>
          <div>
            <h4 className="text-sm font-medium">
              {productName} – Plano {t(`plans.${planType}`)}
            </h4>
            <p className="text-sm text-gray-500">
              {formattedOldPrice ? (
                <>
                  <span className="line-through">{formattedPrice}</span> {formattedOldPrice}
                </>
              ) : (
                formattedPrice
              )}
              {' / '}
              {t(`plansPerPeriod.${planType}`)}
            </p>
          </div>
        </div>
        {showDelete && (
          <Button variant="ghost" size="sm" onClick={() => onDelete()} type="button">
            <Trash2 className="h-4 w-4" />
          </Button>
        )}
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <Controller
            render={({ fieldState, field }) => (
              <PlansSelect
                label={t('offer.pricingTab.pricing.plan.label')}
                removePlans={plansSelected}
                error={Boolean(fieldState.error?.type)}
                helperText={fieldState.error?.type && t(`fieldErrors.${fieldState.error?.type}`)}
                {...field}
                onValueChange={(value) => {
                  field.onChange(value)
                  setValue(`pricing.plans.${index}.installments`, value)
                }}
              />
            )}
            name={`pricing.plans.${index}.interval`}
          />
          <Controller
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <CurrencyField
                label={t('offer.pricingTab.pricing.price.label')}
                {...field}
                error={Boolean(fieldState.error?.type)}
                helperText={fieldState.error?.type && t(`fieldErrors.${fieldState.error?.type}`)}
              />
            )}
            name={`pricing.plans.${index}.price`}
          />
          <Controller
            name={`pricing.plans.${index}.oldPrice`}
            render={({ field, fieldState }) => (
              <PromotionField
                label={t('offer.pricingTab.pricing.promotion.label')}
                {...field}
                error={Boolean(fieldState.error?.type)}
                helperText={fieldState.error?.type && t(`fieldErrors.${fieldState.error?.type}`)}
              />
            )}
          />
          <Controller
            render={({ field, fieldState }) => (
              <InstallmentsSelect
                label={t('offer.pricingTab.pricing.installments.label')}
                maxInstallment={maxInstallment}
                error={Boolean(fieldState.error?.type)}
                helperText={fieldState.error?.type && t(`fieldErrors.${fieldState.error?.type}`)}
                {...field}
              />
            )}
            name={`pricing.plans.${index}.installments`}
          />
        </div>
      </CardContent>
    </Card>
  )
}

const pricesPlansType = [
  { plan: IntervalType.MONTHLY, maxInstallments: 1 },
  { plan: IntervalType.QUARTERLY, maxInstallments: 3 },
  { plan: IntervalType.SEMIANNUALLY, maxInstallments: 6 },
  { plan: IntervalType.ANNUALLY, maxInstallments: 12 },
]

const getNextNewPrice = (prices) => {
  if (prices.length >= pricesPlansType.length) {
    throw new Error('No more prices available')
  }

  const availablePricesPlan = pricesPlansType.filter((price) => !prices.some((p) => p.interval === price.plan))

  return {
    installments: availablePricesPlan[0]?.maxInstallments,
    price: 1000,
    interval: availablePricesPlan[0]?.plan,
    oldPrice: null,
  }
}

const PriceConfiguration = () => {
  const { t } = useTranslation()
  const { control } = useFormContext<OfferSchemaValidationType>()
  const [pricingPriceType] = useWatch({
    name: ['pricing.priceType'],
    control,
  })
  const productId = useWatch({
    name: 'productId',
    control,
  })
  const {
    fields: plans,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'pricing.plans',
  })

  const isRecurringPrice = pricingPriceType === 'recurring'

  const { data: product } = useGetProduct(productId ?? '')

  if (isRecurringPrice) {
    return (
      <div className="flex flex-col gap-4">
        {plans.map((plan, index) => {
          return (
            <RecurringCard
              key={`${plan.interval}-${index}`}
              productName={product?.name}
              index={index}
              defaultInstallments={plan.installments ?? 1}
              onDelete={() => remove(index)}
              showDelete={plans.length > 1 && index !== 0}
            />
          )
        })}
        <div>
          <Button
            variant="outline"
            size="sm"
            disabled={plans.length >= pricesPlansType.length}
            onClick={() => append(getNextNewPrice(plans))}
            type="button"
          >
            <Plus className="mr-2 h-4 w-4" />
            {t('offer.pricingTab.pricing.addPlan')}
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-6">
      <OneTimeCard />
    </div>
  )
}

export { PriceConfiguration }
