import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@hub-la/shadcn'
import { VerificationStatus, useGetPayout } from '@hub-la/fe-get-user'
import { useRoleplay } from '@hub-la/fe-roleplay'
import React, { ComponentType, ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UpdatePayoutModal } from '../pages/update-payout-modal'
import { AccountVerificationProvider } from '../providers/account-verification-provider'

type ConfirmationModalProps = {
  open: boolean
  onSubmit: () => void
  onCancel: () => void
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = (props) => {
  const { open, onSubmit, onCancel } = props
  const { t } = useTranslation()
  return (
    <Dialog open={open} onOpenChange={(isOpen) => !isOpen && onCancel()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('confirmationModal.update.title')}</DialogTitle>
          <DialogDescription>{t('confirmationModal.update.message')}</DialogDescription>
        </DialogHeader>
        <DialogFooter className="sm:justify-end">
          <Button variant="outline" onClick={onCancel}>
            {t('confirmationModal.update.cancel')}
          </Button>
          <Button onClick={onSubmit}>{t('confirmationModal.update.confirm')}</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

type RoleplayModalProps = {
  open: boolean
  onCancel: () => void
}

const RoleplayModal: React.FC<RoleplayModalProps> = (props) => {
  const { open, onCancel } = props
  const { t } = useTranslation()
  return (
    <Dialog open={open} onOpenChange={(isOpen) => !isOpen && onCancel()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('roleplayModal.update.title')}</DialogTitle>
          <DialogDescription>{t('roleplayModal.update.message')}</DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button variant="outline" onClick={onCancel}>
            {t('roleplayModal.update.cancel')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

type WithApprovedPayoutProps = {
  onClick?: () => void
}

type Options = {
  tooltip?: string
}

export const withApprovedPayout = <P extends object>(
  WrappedComponent: ComponentType<P>,
  options?: Options,
): React.FC<P & WithApprovedPayoutProps> => {
  return (props: P & WithApprovedPayoutProps): ReactElement => {
    const [updatePayoutOpen, setUpdatePayoutOpen] = useState(false)
    const [confirmationOpen, setConfirmationOpen] = useState(false)
    const [roleplayOpen, setRoleplayOpen] = useState(false)

    const { isRoleplay } = useRoleplay()
    const { data: payout } = useGetPayout()
    const { t } = useTranslation()

    const isApproved = payout?.verificationStatus === VerificationStatus.APPROVED

    const openConfirmationModal = () => {
      if (isRoleplay && !isApproved) {
        setRoleplayOpen(true)
        return
      }

      if (isApproved) {
        props.onClick?.()
        return
      }
      setConfirmationOpen(true)
    }

    const closeConfirmationModal = () => {
      setConfirmationOpen(false)
    }

    const closeRoleplayModal = () => {
      setRoleplayOpen(false)
    }

    const openUpdatePayoutModal = () => {
      setConfirmationOpen(false)
      setUpdatePayoutOpen(true)
    }

    const closeUpdatePayoutModal = () => {
      setUpdatePayoutOpen(false)
      setConfirmationOpen(false)
    }

    const onFinish = () => {
      closeUpdatePayoutModal()
      props.onClick?.()
    }

    return (
      <AccountVerificationProvider>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div>
                <WrappedComponent {...props} onClick={openConfirmationModal} />
              </div>
            </TooltipTrigger>
            {options?.tooltip && !isApproved && <TooltipContent>{options.tooltip}</TooltipContent>}
          </Tooltip>
        </TooltipProvider>
        <ConfirmationModal open={confirmationOpen} onSubmit={openUpdatePayoutModal} onCancel={closeConfirmationModal} />
        <RoleplayModal open={roleplayOpen} onCancel={closeRoleplayModal} />
        <UpdatePayoutModal open={updatePayoutOpen} onClose={closeUpdatePayoutModal} onUpdate={onFinish} />
      </AccountVerificationProvider>
    )
  }
}
