import { memo } from 'react'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { SmartInstallmentStatus } from '../../../domain/enums/smart-installment-status'
import { useGetOffers } from '../../hooks/use-get-offers'
import { useGetSmartInstallmentsSummaries } from '../../hooks/use-get-smart-installments-summaries'
import { useRefetch } from '../../hooks/use-refetch'
import { CommaDelimitedArrayParam } from '../../utils/query-string-encoder'
import { Filters } from './filters'
import { SmartInstallmentsTable } from './smart-installments-table/smart-installments-table'
import { TotalPaid } from './total-paid/total-paid'
import { TotalScheduled } from './total-scheduled/total-scheduled'
import { TotalUnpaid } from './total-unpaid/total-unpaid'
import { Total } from './total/total'
import { useGetUser } from '@hub-la/fe-get-user'
import { useFeatureFlag } from '@hub-la/fe-core-feature-flag'

export const SmartInstallments = memo(() => {
  const { data: offers = [], isFetching } = useGetOffers()
  const isFetchingOffers = isFetching && offers.length <= 0

  const [filters, setFilters] = useQueryParams({
    search: withDefault(StringParam, ''),
    offerIds: withDefault(CommaDelimitedArrayParam, []),
    statuses: withDefault(CommaDelimitedArrayParam, []),
    page: withDefault(NumberParam, 1),
    pageSize: withDefault(NumberParam, 25),
    dateRangeBy: withDefault(StringParam, 'createdAt'),
    startDate: withDefault(StringParam, ''),
    endDate: withDefault(StringParam, ''),
  })

  const { isFlagEnabled } = useFeatureFlag()
  const { data: currentUser } = useGetUser()

  const showNewTable = isFlagEnabled('isValidUserToShowNewSmartInstallmentTable', {
    id: currentUser?.id,
  })

  const {
    data: summaries,
    isFetching: isFetchingSummaries,
    refetch,
  } = useGetSmartInstallmentsSummaries(
    {
      offers,
      search: filters.search,
      offerIds: filters.offerIds as string[],
      statuses: filters.statuses as SmartInstallmentStatus[],
      page: filters.page,
      pageSize: parseInt(filters.pageSize.toString(), 10),
    },
    isFetching,
  )

  useRefetch({ offers, ...filters, page: undefined, pageSize: undefined }, refetch)

  const total = (summaries?.totalPaid ?? 0) + (summaries?.totalScheduled ?? 0) + (summaries?.totalUnpaid ?? 0)
  const isCardLoading = (isFetchingSummaries && summaries.count <= 0) || isFetchingOffers

  return (
    <div className="flex flex-col gap-4">
      <Filters filters={filters} setFilters={setFilters} offers={offers} isLoading={isFetchingOffers} />

      <div className="grid grid-cols-1 md:grid-cols-4 gap-3">
        <div>
          <Total isLoading={isCardLoading} amount={total} />
        </div>

        <div>
          <TotalPaid isLoading={isCardLoading} amount={summaries?.totalPaid} />
        </div>

        <div>
          <TotalUnpaid isLoading={isCardLoading} amount={summaries?.totalUnpaid} />
        </div>

        <div>
          <TotalScheduled isLoading={isCardLoading} amount={summaries?.totalScheduled} />
        </div>
      </div>

      <SmartInstallmentsTable filters={filters} setFilters={setFilters} offers={offers} isLoading={isFetchingOffers} />
    </div>
  )
})
