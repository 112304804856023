import { Badge, Separator, Sheet, SheetContent, SheetHeader, SheetTitle } from '@hub-la/shadcn'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { EventDelivery } from '../../domain/dtos/event-delivery'
import { IntegrationEvent } from '../../domain/dtos/integration-event'
import { GetEventName } from '../../usecases/get-event-name'
import { JSONDetailItem } from './json-detail-item'
import { RuleDetailItem } from './rule-detail-item'

type HistoryDetailDrawerProps = {
  isOpen: boolean
  history: EventDelivery
  onClose: () => void
}

export const HistoryDetailDrawer = ({ isOpen, history, onClose }: HistoryDetailDrawerProps) => {
  const { t } = useTranslation()

  const renderEventCell = (event: IntegrationEvent) => {
    const eventName = new GetEventName().execute(event)

    if (!eventName) {
      return null
    }

    return <Badge variant="secondary">{t(eventName)}</Badge>
  }

  const renderStatusCodeCell = (status?: number | null) => {
    if (!status) {
      return <Badge variant="secondary">Em Processamento</Badge>
    } else if (status >= 0 && status < 300) {
      return <Badge>{status} - Processado</Badge>
    } else if (status >= 300) {
      return <Badge variant="destructive">{status} - Erro</Badge>
    } else {
      return <Badge variant="destructive">{status} - Erro</Badge>
    }
  }

  const hasProcessed = !!history.statusCode

  return (
    <Sheet open={isOpen} onOpenChange={onClose}>
      <SheetContent className="max-w-lg flex flex-col gap-4 overflow-y-auto">
        <SheetHeader>
          <SheetTitle>{t('histories.detailModal.title')}</SheetTitle>
        </SheetHeader>

        <Separator />

        <RuleDetailItem title={t('histories.table.columns.date')}>
          {moment(history.createdAt).format('DD/MM/YYYY HH:mm:ss')}
        </RuleDetailItem>

        <RuleDetailItem title={t('histories.table.columns.event')}>{renderEventCell(history.event)}</RuleDetailItem>

        <RuleDetailItem title={t('histories.table.columns.product')}>{history.productId}</RuleDetailItem>

        <RuleDetailItem title={t('histories.table.columns.offer')}>{history.offerId}</RuleDetailItem>

        <RuleDetailItem title={t('histories.table.columns.status')}>
          {renderStatusCodeCell(history.statusCode)}
        </RuleDetailItem>

        {hasProcessed && (
          <>
            {history.method && history.url && (
              <>
                <h3 className="text-lg font-semibold">{t('histories.detailModal.requestDetail')}</h3>
                <Separator />
              </>
            )}
            {history.method && history.url && (
              <JSONDetailItem title={t('histories.table.columns.url')}>
                {history.method} - {history.url}
              </JSONDetailItem>
            )}

            {history.headers && (
              <JSONDetailItem title={t('histories.table.columns.headers')}>{history.headers}</JSONDetailItem>
            )}

            {history.payload && (
              <JSONDetailItem title={t('histories.table.columns.payload')}>{history.payload}</JSONDetailItem>
            )}

            <h3 className="text-lg font-semibold">{t('histories.detailModal.responseDetail')}</h3>

            <Separator />

            <RuleDetailItem title={t('histories.table.columns.updatedAt')}>
              {moment(history.updatedAt).format('DD/MM/YYYY HH:mm:ss')}
            </RuleDetailItem>

            <RuleDetailItem title={t('histories.table.columns.responseStatus')}>
              {renderStatusCodeCell(history.statusCode)}
            </RuleDetailItem>

            <RuleDetailItem title={t('histories.table.columns.retry')}>{history.retryCount}</RuleDetailItem>

            <JSONDetailItem title="Resposta">{JSON.stringify(history.response)}</JSONDetailItem>
          </>
        )}
      </SheetContent>
    </Sheet>
  )
}
