import { Skeleton, TableCell, TableRow } from '@hub-la/shadcn'

export const RuleTableLoading = ({ schema }: { schema: string[] }) => {
  return (
    <TableRow>
      {schema.map((_, index) => (
        <TableCell key={index}>
          <Skeleton className="h-6 w-[70%]" />
        </TableCell>
      ))}

      <TableCell>
        <Skeleton className="h-6 w-[70%]" />
      </TableCell>

      <TableCell>
        <Skeleton className="h-6 w-[70%]" />
      </TableCell>
    </TableRow>
  )
}
