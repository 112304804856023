import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GetIntegrationHistoriesInput } from '../domain/dtos/get-integration-histories-input'
import { GetIntegrationHistoriesOutput } from '../domain/dtos/get-integration-histories-output'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class GetHistories {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: GetIntegrationHistoriesInput): Promise<GetIntegrationHistoriesOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_WEB_URL}/integrations/${input.integrationId}/events/?page=${input.page}&pageSize=${input.pageSize}`,
    })

    if (response.statusCode === HttpStatusCode.OK) {
      return response.data
    }

    throw new GeneralError()
  }
}
