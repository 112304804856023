import { PostResponse, SectionResponse } from '@hub-la/fe-post'
import { Badge } from '@hub-la/shadcn'
import { GripVertical } from 'lucide-react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { GetPostType } from '../../../usecases/get-post-type'
import { GetReleaseAtText } from '../../../usecases/get-release-at-text'
import { ClickableEditThumbnail } from '../clickable-edit-thumbnail/clickable-edit-thumbnail'
import { PostMenu } from './post-menu'

export type Props = {
  productId: string
  userId: string
  post: PostResponse
  sectionId: string
  sections: SectionResponse[]
  dragHandleProps: any
}

export const PostItem: React.FC<Props> = ({ dragHandleProps, sections, userId, productId, post, sectionId }: Props) => {
  const [isEditCoverOpen, setIsEditCoverOpen] = useState(false)

  const postEditLink = `/hub/create_post/${productId}/${sectionId}?postId=${post.id}`

  const hasReleaseAt = !!((post.releaseAfterDays && post.releaseAfterDays > 0) || post.releaseAt)

  const { icon: ReleaseIcon, text: releaseText } = new GetReleaseAtText().execute(post)

  return (
    <div className="flex items-center justify-between py-2 rounded hover:bg-gray-100" data-testid="post-item">
      <div className="flex items-center w-full">
        <div {...dragHandleProps}>
          <GripVertical className="w-4 h-4 mr-2 cursor-move" />
        </div>

        <ClickableEditThumbnail asset={post?.customCover ?? post?.cover} onClick={() => setIsEditCoverOpen(true)} />

        <Link className="flex flex-row cursor-pointer w-full gap-4" data-testid="post-edit" to={postEditLink}>
          <div className="flex flex-col">
            <p className="text-base text-gray-900 w-[90%] break-all w-full">{post.title}</p>
            <p className="text-sm text-gray-500" data-testid="post-subtitle">
              {new GetPostType().execute(post)}
            </p>
          </div>
          {hasReleaseAt && releaseText && (
            <Badge variant="secondary" className="bg-zinc-100 h-min">
              <ReleaseIcon className="mr-2 h-3 w-3" />
              {releaseText}
            </Badge>
          )}
        </Link>
      </div>

      <PostMenu
        isEditCoverOpen={isEditCoverOpen}
        setIsEditCoverOpen={setIsEditCoverOpen}
        sectionId={sectionId}
        post={post}
        sections={sections}
        productId={productId}
        userId={userId}
      />
    </div>
  )
}
