import { Input } from '@hub-la/shadcn'
import { Search } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { SelectDateRangeCalendar } from './select-date-range-calendar'
import { SelectOffers } from '../../components/select-offers'
import { ConfirmExportModal } from './confirm-export-modal'
import { SelectStatus } from './select-status'
import { FilterDrawerDetails } from './filter-drawer-details'

export const Filters = ({ setFilters, filters, offers, isLoading }) => {
  const { t } = useTranslation()

  return (
    <div className="flex justify-between">
      <div className="hidden md:flex gap-3">
        <div className="relative h-10 w-full">
          <Search className="absolute w-4 h-4 right-3 top-1/2 transform -translate-y-1/2 text-gray-500 z-10" />
          <Input
            type="text"
            placeholder="Buscar..."
            className="w-[250px]"
            value={filters.search}
            onChange={(e) => setFilters({ ...filters, search: e.target.value })}
          />
        </div>
        <SelectOffers
          offers={offers}
          value={filters.offerIds}
          onChange={(value) => setFilters((prevState) => ({ ...prevState, offerIds: value }))}
        />
        <SelectDateRangeCalendar
          value={filters}
          onChange={(value) => setFilters((prevState) => ({ ...prevState, ...value }))}
          onSelectChange={(value) => setFilters((prevState) => ({ ...prevState, dateRangeBy: value }))}
          selectValue={filters.dateRangeBy}
          onClearDate={() =>
            setFilters((prevState) => ({ ...prevState, dateRangeBy: 'createdAt', startDate: '', endDate: '' }))
          }
        />

        <SelectStatus
          value={filters.statuses}
          onChange={(value) => setFilters((prevState) => ({ ...prevState, statuses: value }))}
        />
      </div>
      <div className="flex w-full flex-col md:flex-row md:justify-end gap-3">
        <div>
          <FilterDrawerDetails filters={filters} setFilters={setFilters} offers={offers} />
        </div>
        <div>
          <ConfirmExportModal filters={filters} offers={offers} />
        </div>
      </div>
    </div>
  )
}
