import {
  Label,
  Separator,
  TableBody,
  TableCaption,
  TableHead,
  TableHeader,
  Table,
  TableCell,
  TableRow,
} from '@hub-la/shadcn'
import isEmpty from 'lodash/isEmpty'
import times from 'lodash/times'
import moment from 'moment'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { SmartInstallmentInvoice } from '../../../domain/dtos/smart-installment-invoice'
import { formatCurrency } from '../../../domain/vos/format-currency'
import { InvoiceInstallmentsStats } from '../../components/invoice-installments-stats'
import { InvoiceStatusStats } from '../../components/invoice-status-stats'
import { useCheckHaveChanged } from '../../hooks/use-check-have-changed'
import { useGetSmartInstallmentInvoices } from '../../hooks/use-get-smart-installment-invoices'
import { InvoiceTableLoading } from './invoice-table-loading'

export const InvoiceTable = ({ smartInstallmentId, status }) => {
  const { t } = useTranslation()
  const {
    data = { items: [], total: 0, page: 1, pageSize: 50, lastPage: 1 },
    isFetching,
    refetch,
    isLoading,
  } = useGetSmartInstallmentInvoices({
    smartInstallmentId: smartInstallmentId,
    page: 1,
    pageSize: 50,
  })

  const getHeadCells = () => {
    return [
      {
        key: 'total',
        label: 'Valor',
        tooltip: t('smartInstallmentsInvoiceTable.tooltip.total'),
        sortable: false,
        width: 90,
      },
      {
        key: 'status',
        label: 'Status',
        tooltip: t('smartInstallmentsInvoiceTable.tooltip.status'),
        sortable: false,
        width: 110,
      },
      {
        key: 'id',
        label: 'ID da fatura',
        tooltip: t('smartInstallmentsInvoiceTable.tooltip.id'),
        sortable: false,
        width: 110,
      },
      {
        key: 'dueDate',
        label: 'Data do vencimento',
        tooltip: t('smartInstallmentsInvoiceTable.tooltip.dueDate'),
        sortable: false,
        width: 90,
      },
    ]
  }

  const canRefetch = useCheckHaveChanged({ status })
  useEffect(() => {
    canRefetch && refetch()
  }, [canRefetch, refetch])

  const isRowsEmpty = isEmpty(data?.items)
  const canRenderEmptyState = !isFetching && isRowsEmpty

  return (
    <div className="space-y-4">
      <h2 className="text-lg font-bold">Dados da fatura</h2>
      <Separator />
      <div className="rounded-md overflow-scroll border bg-white h-[500px]">
        <Table className=" ">
          <TableHeader>
            <TableRow>
              {getHeadCells().map((column) => (
                <TableHead>{column.label}</TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody className="overfow-scroll max-h-[500px]">
            {(isFetching && isRowsEmpty) || isLoading
              ? times(3, (idx) => <InvoiceTableLoading key={idx} />)
              : (data?.items ?? []).map((installment: SmartInstallmentInvoice) => (
                  <TableRow key={installment.invoiceId}>
                    <TableCell>
                      <p>{formatCurrency(installment.amount.totalCents / 100)}</p>
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center gap-2">
                        <InvoiceStatusStats status={installment.status} />
                        <InvoiceInstallmentsStats
                          status={installment.status}
                          size="tiny"
                          installment={installment.installment}
                          installments={installment.installments}
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <p>{installment.invoiceId}</p>
                    </TableCell>
                    <TableCell>
                      <p>{moment(installment.dueDate || installment.scheduleEta).format('D MMM. YYYY')}</p>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
          {canRenderEmptyState && (
            <TableCaption>
              <div className="py-4 Label-center">
                <Label className="text-sm">{t('empty')}</Label>
                <Label className="text-sm">{t('emptySubtitle')}</Label>
              </div>
            </TableCaption>
          )}
        </Table>
      </div>
    </div>
  )
}
