import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@hub-la/shadcn'
import { Info } from 'lucide-react'

export const InfoTooltip = ({ title }: { title: string }) => {
  return (
    <TooltipProvider>
      <Tooltip delayDuration={100}>
        <TooltipTrigger asChild>
          <span className="cursor-pointer">
            <Info className="h-3 w-3 mr-2" />
          </span>
        </TooltipTrigger>
        <TooltipContent
          side="top"
          align="center"
          className="bg-gray-800 text-white p-2 lg:max-w-lg max-w-96 rounded-md shadow-lg"
        >
          <p className="text-sm">{title}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
