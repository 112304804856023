import { Circle, XCircle } from 'lucide-react'
import { useTranslation } from 'react-i18next'

type AffiliateStatusStatsProps = {
  status: 'pending' | 'enabled' | 'disabled'
}

const getStyles = (color: string) => {
  return { size: 16, className: `mr-1 text-${color}-700`, fill: color, style: { fillOpacity: 0.1 } }
}

export const AffiliateStatusStats = ({ status }: AffiliateStatusStatsProps) => {
  const { t } = useTranslation()

  const getIcon = () => {
    switch (status) {
      case 'enabled':
        return <Circle {...getStyles('green')} />
      case 'pending':
        return <Circle {...getStyles('gray')} />
      case 'disabled':
        return <XCircle {...getStyles('gray')} />
      default:
        return <Circle {...getStyles('gray')} />
    }
  }

  return (
    <div className="flex items-center gap-1">
      {getIcon()}
      <span>{t(`affiliates.status.${status}`)}</span>
    </div>
  )
}
