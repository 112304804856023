import moment from 'moment'
import QRCode from 'react-qr-code'

export type BigTicketProps = {
  name: string
  eventName: string
  eventLogo?: string
  userName: string
  location?: string
  bgColor?: string
  secondaryColor?: string
  textColor?: string
  startDate?: Date
  endDate?: Date
  code?: string
}

export const BigTicket: React.FC<BigTicketProps> = ({
  name,
  eventName,
  eventLogo,
  userName,
  bgColor = '#939393',
  secondaryColor = '#0000001A',
  textColor = '#fff',
  location,
  startDate,
  endDate,
  code,
}) => {
  const startDateFormatted = moment(startDate).format('DD [de] MMM. YYYY')
  const endDateFormatted = moment(endDate).format('DD [de] MMM. YYYY')
  const startHour = moment(startDate).format('HH:mm')
  const endHour = moment(endDate).format('HH:mm')

  return (
    <div className="relative max-w-[355px]" style={{ backgroundColor: bgColor }}>
      <svg className="absolute" width="0" height="0" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <clipPath id="ticket-mask" clipPathUnits="objectBoundingBox">
            <path d="M0 0H1V0.32395C1 0.32395 0.943 0.33438 0.943 0.39792C0.943 0.46146 1 0.47188 1 0.47188V1H0V0.47188C0 0.47188 0.0563 0.4563 0.0563 0.39792C0.0563 0.33954 0 0.32395 0 0.32395V0Z" />
          </clipPath>
        </defs>
      </svg>

      <div className="clip-path-ticket-mask rounded-md shadow-md">
        <div className="flex flex-col items-center px-14 py-6 space-y-6">
          <h3 className="text-center max-w-[213px] text-2xl font-bold" style={{ color: textColor }}>
            {eventName}
          </h3>

          <div className="flex flex-col items-center">
            {code && (
              <div className="bg-white p-2 rounded mb-2">
                <QRCode value={code} size={143} />
              </div>
            )}
            <p className="text-center font-bold" style={{ color: textColor }}>
              {userName}
            </p>
          </div>

          <div className="opacity-60 space-y-1">
            {startDate && (
              <p className="text-sm text-center font-bold" style={{ color: textColor }}>
                {startDateFormatted} {endDateFormatted === startDateFormatted ? '' : `– ${endDateFormatted}`}
              </p>
            )}

            {endDate && (
              <p className="text-sm text-center font-bold" style={{ color: textColor }}>
                {startHour} – {endHour} GMT-3
              </p>
            )}

            {location && (
              <p className="text-sm text-center font-bold" style={{ color: textColor }}>
                {location}
              </p>
            )}
          </div>
        </div>

        <div
          className="flex justify-between px-5 py-4 rounded-b-[20px] min-h-[65px]"
          style={{ backgroundColor: secondaryColor }}
        >
          <h3 className="text-2xl font-bold" style={{ color: textColor }}>
            {name}
          </h3>

          {eventLogo && (
            <img src={eventLogo} alt={`Logo do evento ${eventName}`} className="max-w-[82px] h-[34px] object-cover" />
          )}
        </div>
      </div>
    </div>
  )
}
