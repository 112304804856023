import { ExternalLink } from 'lucide-react'
import { CardUploader } from '../card-uploader/card-uploader'

export interface CustomCoverProps {
  title?: string
  description?: string
  showSnackbar?: boolean
}

export const CustomCover = ({ showSnackbar }: CustomCoverProps) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="w-full p-4 flex flex-col gap-4 rounded-lg bg-gray-100">
        <CardUploader fieldName="customCover" hasLoading={true} showSnackbar={showSnackbar} />
      </div>

      <div className="w-full p-4 flex flex-col gap-4 rounded-lg bg-gray-100 ">
        <a
          href="https://www.canva.com/design/DAFdSsJD-0k/m3P8PQOduQTzCUuF7ruF2Q/view?utm_content=DAFdSsJD-0k&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
          target="_blank"
          rel="noreferrer"
          className="flex justify-between items-center transition-colors"
        >
          <span>Use o nosso Template de capa no Canva</span>
          <ExternalLink className="h-4 w-4" />
        </a>
      </div>
    </div>
  )
}
