import { Card, CardContent } from '@hub-la/shadcn'
import moment from 'moment/moment'
import { useTranslation } from 'react-i18next'
import { SmartInstallmentAmountDetail } from '../../../domain/dtos/smart-installment-amount-detail'
import { ReceiverRole } from '../../../domain/enums/receiver-role'
import { formatCurrency } from '../../../domain/vos/format-currency'
import { ContentRow } from './content-row'

type DescriptionProps = {
  detail: SmartInstallmentAmountDetail
  role: ReceiverRole
}

export const Description = ({ detail }: DescriptionProps) => {
  const { t } = useTranslation()

  return (
    <div className="h-full w-full">
      <Card className="h-full">
        <CardContent className="p-6 flex flex-col gap-5 justify-between">
          <div className="flex flex-col gap-2">
            <h3 className="text-lg font-bold">Prévia da próxima parcela</h3>

            <p className="text-sm text-muted-foreground">
              Essa é uma prévia da próxima parcela a ser cobrada dia{' '}
              <span className="font-bold">{moment(detail.nextDueAt).format('D MMM. YYYY')}</span>
            </p>
          </div>

          <div className="w-full flex flex-col gap-2">
            {detail.products.map((product, i) => (
              <ContentRow
                key={i}
                showDivider
                fontWeight="lighter"
                title={t('description.productInstallment', { productName: product.productName })}
                content={<p className="font-light">{formatCurrency(product.priceCents / 100)}</p>}
              />
            ))}

            {detail.discountCents ? (
              <ContentRow
                showDivider
                fontWeight="lighter"
                title={t('description.discountCents', { couponCode: detail.couponCode })}
                content={<p className="font-light text-red-600">- {formatCurrency(detail.discountCents / 100)}</p>}
              />
            ) : null}

            {detail.installmentFeeCents ? (
              <ContentRow
                showDivider
                fontWeight="lighter"
                title={t('description.installmentFeeCents')}
                content={<p className="font-light text-gray-700">{formatCurrency(detail.installmentFeeCents / 100)}</p>}
              />
            ) : null}

            {detail.totalCents ? (
              <ContentRow
                title={t('description.totalCents')}
                fontWeight="bold"
                content={<p className="font-bold">{formatCurrency(detail.totalCents / 100)}</p>}
              />
            ) : null}
          </div>
        </CardContent>
      </Card>
    </div>
  )
}
