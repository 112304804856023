import { Button } from '@hub-la/shadcn'
import { ExternalLink } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { IntegrationProvider } from '../../../domain/dtos/integration-provider'
import { useGetAllProducts } from '../../hooks/use-get-all-products'
import { SelectProductModal } from '../select-product-modal/select-product-modal'

type MetaPixelCardActionsProps = {
  provider: IntegrationProvider
}

export const MetaPixelCardActions = ({ provider }: MetaPixelCardActionsProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  const [openedSelectProduct, setOpenedSelectProduct] = useState(false)
  const { data: products } = useGetAllProducts(false)

  return (
    <div className="w-full flex flex-col items-center justify-between gap-2">
      <Button className="w-full" onClick={() => setOpenedSelectProduct(true)}>
        {t(`buttons.activeIntegration`)}
      </Button>

      <a
        className="flex items-center gap-2 font-800 text-muted-foreground"
        href={t(`integrations.${provider}.about.link`)}
        target="_blank"
        rel="noreferrer"
      >
        {t('aboutTab.seeMore')}

        <ExternalLink fontSize="small" />
      </a>

      <SelectProductModal
        title={t(`integrations.${provider}.title`)}
        opened={openedSelectProduct}
        products={products}
        onClose={() => setOpenedSelectProduct(false)}
        onConfirm={(productId) => history.push({ pathname: `/edit/${productId}/settings?tab=trackers` })}
      />
    </div>
  )
}
