import { StatsVariant } from '@hub-la/design-system'
import { Badge, cn } from '@hub-la/shadcn'
import { InvoiceStatus } from '../../domain/enums/invoice-status'

type Props = {
  status: InvoiceStatus
  installment: number
  installments: number
}

export const SmartInstallmentBadge = ({ status, installment, installments }: Props) => {
  const getVariant = () => {
    switch (status) {
      case InvoiceStatus.DISPUTED:
      case InvoiceStatus.PAID:
        return StatsVariant.positive
      case InvoiceStatus.UNPAID:
      case InvoiceStatus.DRAFT:
        return StatsVariant.informative
      case InvoiceStatus.EXPIRED:
      case InvoiceStatus.CANCELED:
        return StatsVariant.negative

      case InvoiceStatus.RETURNED:
      case InvoiceStatus.CHARGEBACK:
        return StatsVariant.attention

      default:
        return StatsVariant.positive
    }
  }
  return (
    <Badge variant="outline" className={cn('font-medium rounded-full', getVariant())}>
      {installment}/{installments}
    </Badge>
  )
}
