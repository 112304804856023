import { Badge, Tabs, TabsContent, TabsList, TabsTrigger } from '@hub-la/shadcn'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useGetActiveMembers } from '../../hooks/members/use-get-active-members'
import { useGetDeactivatesMembers } from '../../hooks/members/use-get-deactivates-members'
import { useGetPendingInvites } from '../../hooks/members/use-get-pending-invites'
import { ActiveMembersTable } from './active-members-table/active-members-table'
import { InvitesTable } from './invites-table/invites-table'
import { OldMembersTable } from './old-members-table/old-members-table'

export const MembersPage: React.FC = () => {
  const { t } = useTranslation()
  const [actualTab, setActualTab] = React.useState('0')

  const { productId } = useParams<{ productId: string }>()

  const { data: desactivateMembers } = useGetDeactivatesMembers({
    productId,
    page: 1,
    pageSize: 25,
    search: '',
  })

  const { data: pendingInvites } = useGetPendingInvites({
    productId,
    page: 1,
    pageSize: 25,
    search: '',
  })

  const { data: activeMembers } = useGetActiveMembers({
    productId,
    page: 1,
    pageSize: 25,
    types: [],
    search: '',
  })

  return (
    <div className="flex flex-col space-y-4 py-6">
      <h1 className="text-xl font-semibold">Membros</h1>
      <Tabs value={actualTab} onValueChange={setActualTab} className="w-full space-y-4">
        <TabsList>
          <TabsTrigger value="0" className="inline-block">
            <span className="mr-1.5">{t('tabs.members.title')}</span>
            <Badge variant="secondary" className="px-1 bg-zinc-200 rounded-sm">
              {activeMembers?.total}
            </Badge>
          </TabsTrigger>
          <TabsTrigger value="1" className="inline-block">
            <span className="mr-1.5">{t('tabs.invites.title', { total: 12 })}</span>
            <Badge variant="secondary" className="px-1 bg-zinc-200 rounded-sm">
              {pendingInvites?.total ?? 0}
            </Badge>
          </TabsTrigger>
          <TabsTrigger value="2" className="inline-block">
            <span className="mr-1.5">{t('tabs.oldMembers.title')}</span>
            <Badge variant="secondary" className="px-1 bg-zinc-200 rounded-sm">
              {desactivateMembers?.total}
            </Badge>
          </TabsTrigger>
        </TabsList>
        <TabsContent value="0">
          <ActiveMembersTable />
        </TabsContent>
        <TabsContent value="1">
          <InvitesTable />
        </TabsContent>
        <TabsContent value="2">
          <OldMembersTable />
        </TabsContent>
      </Tabs>
    </div>
  )
}
