import { CheckCircle2, Circle, CircleDashed, CircleDot, CircleSlash } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { SubscriptionStatus } from '../../../domain/enums/subscription-status'

type SubscriptionStatusStatsProps = {
  status: SubscriptionStatus
}

const getStyles = (color: string, weight = 600) => {
  return { size: 16, className: `mr-1.5 text-${color}-${weight}` }
}

const getVariant = (status: SubscriptionStatus) => {
  switch (status) {
    case SubscriptionStatus.STATUS_ACTIVE:
      return <CircleDot {...getStyles('green')} />
    case SubscriptionStatus.STATUS_INACTIVE:
      return <CircleDashed {...getStyles('gray', 500)} />
    case SubscriptionStatus.STATUS_CANCELED:
      return <CircleSlash {...getStyles('red')} />
    case SubscriptionStatus.STATUS_INCOMPLETE:
      return <Circle {...getStyles('gray')} />
    default:
      return <CheckCircle2 {...getStyles('green')} />
  }
}

export const SubscriptionStatusStats = ({ status }: SubscriptionStatusStatsProps) => {
  const { t } = useTranslation()

  return (
    <div className="flex items-center ">
      {getVariant(status)}
      {t(`subscriptionStatus.${status}`)}
    </div>
  )
}
