import { Tabs, TabsContent, TabsList, TabsTrigger } from '@hub-la/shadcn'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IntegrationProvider } from '../../../domain/dtos/integration-provider'
import { useGetIntegrationByProvider } from '../../hooks/use-get-integration-by-provider'
import { IntegrationAboutTab } from '../integration-about-tab'
import { IntegrationHistoryTab } from '../integration-history-tab'
import { IntegrationSettingsTab } from '../integration-settings-tab'
import { ruleSchema, saveRuleSchema } from './omie.schema'

type OmieDetailProps = {
  provider: IntegrationProvider
}

export const OmieDetail = ({ provider }: OmieDetailProps) => {
  const { t } = useTranslation()
  const [tab, setTab] = useState('about')
  const {
    data: integration,
    refetch,
    isFetching,
    isLoading,
  } = useGetIntegrationByProvider({
    provider,
  })

  const loading = isLoading || isFetching
  const hasIntegration = !!integration

  return (
    <Tabs value={tab} onValueChange={setTab} className="flex flex-col gap-8">
      <TabsList className="h-full max-w-full w-min overflow-x-auto">
        <TabsTrigger value="about">{t('tabs.about')}</TabsTrigger>

        {hasIntegration && <TabsTrigger value="settings">{t('tabs.settings')}</TabsTrigger>}
        {hasIntegration && <TabsTrigger value="history">{t('tabs.history')}</TabsTrigger>}
      </TabsList>

      <TabsContent value="about">
        <IntegrationAboutTab
          provider={provider}
          integrationId={integration?.id}
          loading={loading}
          refetch={refetch}
          setTab={setTab}
        />
      </TabsContent>

      {hasIntegration && (
        <TabsContent value="settings">
          <IntegrationSettingsTab
            provider={provider}
            integrationId={integration.id}
            ruleSchema={ruleSchema}
            ruleValidationSchema={saveRuleSchema}
          />
        </TabsContent>
      )}

      {hasIntegration && (
        <TabsContent value="history">
          <IntegrationHistoryTab integrationId={integration.id} />
        </TabsContent>
      )}
    </Tabs>
  )
}
