import { memo } from 'react'
import { createEnumParam, DelimitedArrayParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { Period } from '../../../domain/enums/period'
import { GetDateRangeFromPeriod } from '../../../usecases/get-date-range-from-period'
import { useGetOffers } from '../../hooks/use-get-offers'
import { ActiveSubscriptions } from './active-subscriptions/active-subscriptions'
import { CanceledSubscriptions } from './canceled-subscriptions/canceled-subscriptions'
import { Filters } from './filters'
import { InactiveSubscriptions } from './inactive-subscriptions/inactive-subscriptions'
import { NewSubscribers } from './new-subscribers/new-subscribers'

export const Home = memo(() => {
  const { data: offers = [], isFetching } = useGetOffers()
  const isFetchingOffers = isFetching && offers.length <= 0
  const initialDateFilter = new GetDateRangeFromPeriod().execute(Period.MONTHLY)
  const PeriodEnumParam = createEnumParam([Period.HOURLY, Period.DAILY, Period.WEEKLY, Period.MONTHLY, Period.YEARLY])
  const [filters, setFilters] = useQueryParams({
    period: withDefault(PeriodEnumParam, Period.DAILY),
    startDate: withDefault(StringParam, initialDateFilter.from),
    endDate: withDefault(StringParam, initialDateFilter.to),
    offerIds: withDefault(DelimitedArrayParam, []),
  })
  const { period, startDate, endDate, offerIds } = filters

  return (
    <div className="flex flex-col space-y-6">
      <Filters filters={filters} setFilters={setFilters} offers={offers} />
      <div className="grid md:grid-cols-3 gap-6">
        <div className="col-span-3 md:col-span-2 row-span-3">
          <ActiveSubscriptions filters={filters} setFilters={setFilters} offers={offers} isLoading={isFetchingOffers} />
        </div>
        <div className="col-span-3 md:col-span-1">
          <NewSubscribers
            period={period as Period}
            startDate={startDate}
            endDate={endDate}
            offers={offers}
            offerIds={offerIds as string[]}
            isLoading={isFetchingOffers}
          />
        </div>
        <div className="col-span-3 md:col-span-1">
          <CanceledSubscriptions
            period={period as Period}
            startDate={startDate}
            endDate={endDate}
            offers={offers}
            offerIds={offerIds as string[]}
            isLoading={isFetchingOffers}
          />
        </div>
        <div className="col-span-3 md:col-span-1">
          <InactiveSubscriptions
            period={period as Period}
            startDate={startDate}
            endDate={endDate}
            offers={offers}
            offerIds={offerIds as string[]}
            isLoading={isFetchingOffers}
          />
        </div>
      </div>
    </div>
  )
})
