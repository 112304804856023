import { withAccountVerification, withApprovedPayout } from '@hub-la/fe-account-verification'
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Skeleton,
} from '@hub-la/shadcn'
import {
  CheckCircle2,
  ChevronDown,
  Circle,
  CircleDashed,
  HourglassIcon,
  Loader2,
  MoonIcon,
  XCircle,
} from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProductStatus } from '../../domain/dtos/product-status'
import { useChangeProductStatus } from '../hooks/use-change-product-status'

type Props = {
  productId?: string
  status?: ProductStatus
}

const DropdownMenuItemWithApprovedPayout = withApprovedPayout(
  withAccountVerification(DropdownMenuItem, {
    tooltip: 'Você precisa cadastrar uma conta bancária para ativar vendas',
  }),
  { tooltip: 'Você precisa ativar sua conta para ativar vendas' },
)

const ProductIcon = ({ status }: { status?: ProductStatus }) => {
  switch (status) {
    case ProductStatus.SELLING:
    case ProductStatus.PUBLISHED:
      return <Circle className="w-4 h-4 mr-1 fill-green-50 stroke-green-600" />
    case ProductStatus.WAITLISTED:
      return <HourglassIcon className="w-4 h-4" />
    case ProductStatus.DRAFT:
      return <CircleDashed className="w-4 h-4" />
    case ProductStatus.NOT_ENABLED:
    case ProductStatus.NOT_SELLING:
      return <MoonIcon className="w-4 h-4" />
    case ProductStatus.UNAVAILABLE:
      return <XCircle className="w-4 h-4" />
    default:
      return <Circle className="w-4 h-4" />
  }
}

const Element = {
  [ProductStatus.SELLING]: DropdownMenuItemWithApprovedPayout,
  [ProductStatus.WAITLISTED]: DropdownMenuItem,
  [ProductStatus.NOT_SELLING]: DropdownMenuItem,
}

export const ChangeProductStatus = ({ productId, status }: Props) => {
  const [action, setAction] = useState<ProductStatus>()
  const [isChanging, setIsChanging] = useState(false)

  const { t } = useTranslation()
  const { mutateAsync: changeStatus } = useChangeProductStatus()

  const onChangeStatus = async (action: ProductStatus) => {
    setIsChanging(true)
    changeStatus({ productId: productId ?? '', status: action })
      .then(() => {
        setAction(action)
      })
      .finally(() => {
        setIsChanging(false)
      })
  }

  const availableActions = [ProductStatus.SELLING, ProductStatus.WAITLISTED, ProductStatus.NOT_SELLING].filter(
    (it) => it !== status,
  )

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="outline"
            size="sm"
            className="w-full sm:w-min gap-2"
            disabled={status === ProductStatus.UNAVAILABLE || isChanging}
          >
            {isChanging ? <Loader2 className="h-4 w-4 animate-spin" /> : <ProductIcon status={status} />}

            {status ? t(`productStatus.${status}`) : <Skeleton className="w-16 h-4" />}

            <ChevronDown className="h-4 w-4 opacity-50" />
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent className="w-48">
          {availableActions.map((action) => {
            const Item = Element[action]
            return (
              <Item key={action} onClick={() => onChangeStatus(action)}>
                <div className="flex items-center gap-2">
                  <ProductIcon status={action} />

                  <span>{t(`productStatus.${action}`)}</span>
                </div>
              </Item>
            )
          })}
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog open={!!action} onOpenChange={() => setAction(undefined)}>
        <DialogContent>
          <DialogHeader>
            <div className="flex justify-between items-start w-full">
              <CheckCircle2 className="h-10 w-10 mb-2 text-green-600" />
            </div>

            <DialogTitle>{t(`changeStatusModal.${action}.title`)}</DialogTitle>

            <p>{t(`changeStatusModal.${action}.subtitle`)}</p>
          </DialogHeader>

          <DialogFooter>
            <Button onClick={() => setAction(undefined)}>{t(`changeStatusModal.close`)}</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  )
}
