import { CheckCircle2, Circle, CircleAlert, RefreshCw, XCircle } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { RefundStatus } from '../../domain/enums/refund-status'

type RefundStatusProps = {
  status: RefundStatus
  paidAt: string
  createdAt: string
}

const getStyles = (color: string) => {
  return { size: 16, className: `mr-1 text-${color}-700`, fill: color, style: { fillOpacity: 0.1 } }
}

export const RefundCurrentStatus = ({ status, paidAt, createdAt }: RefundStatusProps) => {
  const { t } = useTranslation()

  const dateDiff = (first: number, second: number) => {
    return Math.ceil((second - first) / (1000 * 60 * 60 * 24))
  }

  const getIcon = () => {
    switch (status) {
      case RefundStatus.CREATED:
      case RefundStatus.PENDING:
        return <Circle {...getStyles('gray')} />
      case RefundStatus.FAILED:
        return <CircleAlert {...getStyles('orange')} />
      case RefundStatus.REJECTED:
      case RefundStatus.CANCELED:
        return <XCircle {...getStyles('gray')} />
      case RefundStatus.PROCESSING:
        return <RefreshCw {...getStyles('gray')} />
      case RefundStatus.ACCEPTED:
        return <CheckCircle2 {...getStyles('green')} />
      default:
        return null
    }
  }

  return (
    <div className={`flex items-center`}>
      {getIcon()}
      {t(`status.${status}`, {
        count: dateDiff(new Date(paidAt).getTime(), new Date(createdAt).getTime()),
      })}
    </div>
  )
}
