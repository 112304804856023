import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Button, Card } from '@hub-la/shadcn'
import { ArrowRight, Search, Currency } from 'lucide-react'
import { useGetUser } from '@hub-la/fe-get-user'

type CardProps = {
  href: string
  icon: React.ReactNode
  text: string
}

const ActionButton: React.FC<CardProps> = ({ href, icon, text }) => {
  const history = useHistory()

  return (
    <Button variant="outline" className="p-4 cursor-pointer" onClick={() => history.push(href)}>
      <div className="flex items-center gap-2">
        {icon}
        <span className="text-sm">{text}</span>
        <ArrowRight className="h-4 w-4" />
      </div>
    </Button>
  )
}

export const Header: React.FC = () => {
  const { t } = useTranslation()
  const { data: currentUser } = useGetUser()

  return (
    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-2 sm:space-y-0 w-full">
      <h1 className="text-3xl font-bold">{currentUser?.name ? t('hello', { name: currentUser.name }) : t('title')}</h1>
      <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 w-full sm:w-auto">
        <ActionButton href="/user_invoices" icon={<Search className="h-5 w-5" />} text={t('userTransactions')} />
        <ActionButton
          href="/user_subscriptions"
          icon={<Currency className="h-5 w-5" />}
          text={t('userSubscriptions')}
        />
      </div>
    </div>
  )
}
