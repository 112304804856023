import { Separator } from '@hub-la/shadcn'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SmartInstallment } from '../../../domain/dtos/smart-installment'
import { CardBrand } from '../../../domain/enums/card-brand'
import amex from '../../assets/brands/amex.png'
import elo from '../../assets/brands/elo.png'
import mastercard from '../../assets/brands/mastercard.png'
import visa from '../../assets/brands/visa.png'

type Props = {
  smartInstallment: SmartInstallment
}

const creditCardIcons = {
  [CardBrand.CARD_BRAND_VISA]: <img className="w-[30px] object-contain" src={visa} aria-label={visa} />,
  [CardBrand.CARD_BRAND_MASTERCARD]: (
    <img className="w-[30px] object-contain" src={mastercard} aria-label={mastercard} />
  ),
  [CardBrand.CARD_BRAND_AMEX]: <img className="w-[30px] object-contain" src={amex} aria-label={amex} />,
  [CardBrand.CARD_BRAND_ELO]: <img className="w-[30px] object-contain" src={elo} aria-label={elo} />,
}

export const SmartInstallmentPayerDetails: React.FC<Props> = ({
  smartInstallment,
}: {
  smartInstallment: SmartInstallment
}) => {
  const { t } = useTranslation()

  return (
    <div className="w-full flex flex-col gap-4 md:gap-6 md:flex-row">
      <div className="space-y-1">
        <h4 className="text-base text-muted-foreground font-medium leading-none">Data de criação</h4>
        <p className="text-base">{moment(smartInstallment.createdAt).format('DD MMM. YYYY HH:mm')}</p>
      </div>
      <Separator className="w-px hidden md:block h-full" orientation="vertical" />
      <div className="space-y-1">
        <h4 className="text-base text-muted-foreground font-medium leading-none">Cliente</h4>
        <p className="text-base">{smartInstallment?.payer?.identity?.fullName}</p>
      </div>
      <Separator className="w-px hidden md:block h-full" orientation="vertical" />
      <div className="space-y-1">
        <h4 className="text-base text-muted-foreground font-medium leading-none">Método de pagamento</h4>
        <div className="flex flex-row items-center gap-1">
          {smartInstallment.cardDetails?.brand && creditCardIcons[smartInstallment.cardDetails.brand]}
          <p className="text-base">
            {t(`cardBrand.${smartInstallment.cardDetails.brand}`)} •••• {smartInstallment.cardDetails.last4}
          </p>
        </div>
      </div>
    </div>
  )
}
