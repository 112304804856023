import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  useIsMobile,
} from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from 'recharts'
import { Money } from '../../../../domain/vos/money'
import { InfoTooltip } from '../../../components/info-tooltip'
import { StatusBox } from '../../../components/status-box'
import { useGetRevenue } from '../../../hooks/use-get-revenue'
import { useRefetch } from '../../../hooks/use-refetch'
import { Loading } from './loading'

// Define the shape of your data
interface DatasetData {
  y: number | null
  // Add other properties if necessary
}

export const Revenue = ({ setFilters, filters, offers, isLoading }) => {
  const { period, startDate, endDate, offerIds } = filters
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const { data, isFetching, refetch } = useGetRevenue({ period, startDate, endDate, offers, offerIds }, isLoading)
  const isLoadingData = isFetching && data.current === 0

  useRefetch({ period, startDate, endDate, offers, offerIds }, refetch)

  if (isLoadingData || isLoading) {
    return <Loading />
  }

  const chartConfig = {
    currentRevenue: {
      label: 'Período atual',
      color: '#A6CF2C',
    },
    previousRevenue: {
      label: 'Período anterior',
      color: '#E7EED6',
    },
  }

  // Calculate the maximum value for both current and previous datasets
  const maxValue = Math.max(
    ...data.datasets[0].data.map((item: DatasetData) => item.y ?? 0),
    ...data.datasets[1].data.map((item: DatasetData) => item.y ?? 0),
  )

  // Calculate a suitable upper limit for the y-axis
  const yAxisUpperLimit = Math.ceil(maxValue * 1.1) // Add 10% padding

  return (
    <div className="h-full w-full">
      <Card className="h-full">
        <CardHeader className="space-y-0">
          <CardDescription>
            <div className="text-muted-foreground flex items-center gap-1">
              {t('revenue.title')}
              <InfoTooltip title={t('revenue.description')} />
            </div>
          </CardDescription>
          <CardTitle className="text-4xl font-semibold tabular-nums">
            {Money.build(data.current).getValue()}{' '}
            <div className="flex items-start mt-1 flex-wrap">
              <StatusBox
                indicator={data.indicator}
                status={data.status}
                description={`${data.percentage} ${t('variation', {
                  previous: Money.build(data.previous).getValue(),
                })}`}
              />
            </div>
          </CardTitle>
        </CardHeader>
        <CardContent className="p-0">
          <ChartContainer config={chartConfig} className="w-full max-h-80 px-6">
            <LineChart
              accessibilityLayer
              margin={{
                left: 14,
                right: 14,
                top: 20,
                bottom: 20,
              }}
              data={data.datasets[0].data}
            >
              <CartesianGrid horizontal={false} stroke="#C8C9CB" strokeOpacity={0.3} />
              <YAxis
                hide
                domain={[0, yAxisUpperLimit]} // Set the domain based on calculated upper limit
                allowDataOverflow={false} // Prevent data from overflowing the chart
              />
              <XAxis
                dataKey="date"
                stroke="#C8C9CB"
                strokeOpacity={0.3}
                tickMargin={10}
                tickFormatter={(value) => {
                  return new Date(value).toLocaleDateString('pt-BR', {
                    weekday: 'short',
                    day: 'numeric',
                    month: 'short',
                  })
                }}
              />
              <Line
                dataKey="y"
                type="monotoneX"
                stroke="#E7EED6"
                strokeWidth={2}
                dot={false}
                activeDot={{
                  r: 4,
                  strokeWidth: 2,
                  fill: 'white',
                  stroke: chartConfig.previousRevenue.color,
                }}
                name={chartConfig.previousRevenue.label}
                data={data.datasets[1].data}
              />
              <Line
                dataKey="y"
                type="monotoneX"
                stroke="#94BF0A"
                strokeWidth={2}
                dot={false}
                activeDot={{
                  r: 4,
                  strokeWidth: 2,
                  fill: '#94BF0A',
                  stroke: chartConfig.currentRevenue.color,
                }}
                name={chartConfig.currentRevenue.label}
              />
              <ChartTooltip
                content={
                  <ChartTooltipContent
                    className="w-56  bg-white rounded-lg p-2 shadow-lg"
                    labelFormatter={(value) => {
                      return new Date(value).toLocaleDateString('pt-BR', {
                        weekday: 'long',
                        day: 'numeric',
                        month: 'short',
                        year: '2-digit',
                      })
                    }}
                  />
                }
                cursor={false}
              />
            </LineChart>
          </ChartContainer>
        </CardContent>
      </Card>
    </div>
  )
}
