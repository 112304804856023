import {
  Badge,
  Button,
  cn,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Separator,
} from '@hub-la/shadcn'
import { CheckIcon, PlusCircle } from 'lucide-react'
import { subscriptionStatus } from '../../domain/constants/subscription-status'

interface SelectStatusProps {
  onChange: (ids: string[]) => void
  value: string[]
}

export const SelectStatus = (props: SelectStatusProps) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline">
          <PlusCircle className="h-4 w-4 mr-1" />
          <span className="text-sm">Status</span>
          {props.value.length > 0 && (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <Badge variant="secondary" className="rounded-sm px-1 font-normal lg:hidden">
                {props.value.length}
              </Badge>
              <div className="hidden space-x-1 lg:flex">
                {props.value.length > 2 ? (
                  <Badge variant="secondary" className="rounded-sm px-1 font-normal">
                    {props.value.length} selecionado(s)
                  </Badge>
                ) : (
                  subscriptionStatus
                    .filter((option) => props.value.includes(option.id))
                    .map((option) => (
                      <Badge variant="secondary" key={option.id} className="rounded-sm px-1 font-normal">
                        {option.label}
                      </Badge>
                    ))
                )}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0 z-10002" align="start">
        <Command>
          <CommandInput placeholder="Produto(s)" />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {subscriptionStatus.map((option) => {
                const isSelected = props.value.includes(option.id)
                return (
                  <CommandItem
                    key={option.id}
                    onSelect={() => {
                      if (isSelected) {
                        const newArr = props.value.filter((value) => value !== option.id)
                        props.onChange(newArr)
                      } else {
                        const newArr = [...props.value]
                        newArr.push(option.id)
                        props.onChange(newArr)
                      }
                    }}
                  >
                    <div
                      className={cn(
                        'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                        isSelected ? 'bg-primary text-primary-foreground' : 'opacity-50 [&_svg]:invisible',
                      )}
                    >
                      <CheckIcon className={cn('h-4 w-4')} />
                    </div>
                    <span>{option.label}</span>
                  </CommandItem>
                )
              })}
            </CommandGroup>
            {props.value.length > 0 && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem onSelect={() => props.onChange([])} className="justify-center text-center">
                    Limpar filtros
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
