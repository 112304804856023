import { useGetResources } from '../../../hooks/member-area/use-get-resources'
import { CreateGroup } from './components/create-group'
import { GroupsTable } from './components/groups-table'

type Props = {
  offerId?: string
}

export const Groups: React.FC<Props> = ({ offerId }) => {
  const { data = [], isLoading } = useGetResources(offerId)

  return (
    <div className="flex flex-col gap-3">
      <CreateGroup offerId={offerId} isLoading={isLoading} disabled={data?.length > 50} />
      <GroupsTable offerId={offerId} data={data} isLoading={isLoading} />
    </div>
  )
}
