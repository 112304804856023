import { SectionResponse } from '@hub-la/fe-post'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, Badge, Separator } from '@hub-la/shadcn'
import { GripVertical } from 'lucide-react'
import { memo, useRef, useState } from 'react'
import { GetReleaseAtText } from '../../../usecases/get-release-at-text'
import { ClickableEditThumbnail } from '../clickable-edit-thumbnail/clickable-edit-thumbnail'
import { CreatePostButton } from '../create-post-button/create-post-button'
import { PostAmountText } from '../post-amount-text/post-amount-text'
import { PostList } from '../post-list/post-list'
import { SectionNameEditor } from '../section-name-editor/section-name-editor'
import { SectionMenu } from './section-menu'

type Props = {
  section: SectionResponse
  userId: string
  productId: string
  sections: SectionResponse[]
  showEditThumbnail?: boolean
  dragHandleProps: any
}

export const SectionItem = memo(
  ({ dragHandleProps, userId, productId, sections, section, showEditThumbnail }: Props) => {
    const [isEditCoverOpen, setIsEditCoverOpen] = useState<boolean>(false)
    const [expanded, setExpanded] = useState<boolean>(false)
    const editorRef = useRef<HTMLInputElement>(null)

    const { icon: ReleaseIcon, text: releaseText } = new GetReleaseAtText().execute(section)

    return (
      <div className="mb-2 border border-gray-200 rounded-lg shadow-none bg-white relative">
        <div className="px-4 flex justify-between items-center">
          <div className=" flex items-center min-h-[80px]">
            <div {...dragHandleProps}>
              <GripVertical className="cursor-move mr-2 h-4 w-4" />
            </div>

            {showEditThumbnail && (
              <ClickableEditThumbnail asset={section?.cover} onClick={() => setIsEditCoverOpen(true)} />
            )}

            <div className="flex flex-col sm:flex-row gap-1 sm:items-center">
              <SectionNameEditor editorRef={editorRef} section={section} productId={productId} />

              {releaseText && (
                <Badge variant="secondary" className="bg-zinc-100 mr-auto flex items-center gap-1.5">
                  <ReleaseIcon className="h-3 w-3" />
                  {releaseText}
                </Badge>
              )}
            </div>
          </div>

          <div className="flex items-center">
            <CreatePostButton sectionId={section.id} productId={productId} />

            <SectionMenu
              section={section}
              userId={userId}
              productId={productId}
              isEditCoverOpen={isEditCoverOpen}
              setIsEditCoverOpen={setIsEditCoverOpen}
            />
          </div>
        </div>

        <Separator />

        <Accordion
          type="single"
          collapsible
          value={expanded ? section.id : ''}
          onValueChange={(value) => setExpanded(value === section.id)}
          className="px-4 py-2 w-full"
          data-testid="section-item"
        >
          <AccordionItem value={section.id} className="border-none">
            <AccordionTrigger className="hover:no-underline">
              <PostAmountText posts={section.posts} />
            </AccordionTrigger>

            <AccordionContent>
              <PostList
                posts={section.posts}
                sections={sections}
                productId={productId}
                sectionId={section.id}
                userId={userId}
              />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    )
  },
)
