import {
  Button,
  cn,
  Separator,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  useToast,
} from '@hub-la/shadcn'
import {
  ArrowLeft,
  ArrowRight,
  Banknote,
  CircleX,
  Clock,
  Copy,
  FileIcon,
  Flag,
  Info,
  InfoIcon,
  IterationCw,
  TvMinimal,
} from 'lucide-react'
import moment from 'moment'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { SmartInstallmentInvoiceEvent } from '../../../domain/dtos/smart-installment-invoice'
import { SmartInstallmentInvoiceEventType } from '../../../domain/enums/smart-installment-invoice-event-type.enum'

type Props = {
  events: SmartInstallmentInvoiceEvent[][]
}

export const SmartInstallmentTimeline: React.FC<Props> = ({ events }) => {
  const [showAllEvents, setShowAllEvents] = useState(false)

  const sectionSize = 4

  const showButton = events.length > sectionSize

  const sortEvents = events.sort((a, b) => new Date(a[0].date).getTime() - new Date(b[0].date).getTime())

  const handleToggleShowAllEvents = () => {
    setShowAllEvents((state) => !state)
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center w-full gap-2">
        <h2 className="text-lg font-bold">Histórico de eventos</h2>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <Info className="h-4 w-4" />
            </TooltipTrigger>
            <TooltipContent>
              <p>Históricos cronológico dos eventos que ocorreram com essa fatura.</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <Separator />
      <div className="space-y-2">
        {Array.from(sortEvents)
          .reverse()
          .slice(0, showAllEvents ? events.length : sectionSize)
          .map((event, index) => {
            const showConnector = index !== events.length - 1
            return event.length === 1 ? (
              <Item
                icon={<EventIcon type={event[0].type} />}
                title={event[0].title}
                link={event[0].link}
                description={event[0].description}
                time={event[0].date}
                showConnector={showConnector}
                installments={event[0].installments}
                installment={event[0].installment}
                dueDate={event[0].dueDate}
              />
            ) : (
              <ItemList items={event} showConnector={showConnector} />
            )
          })}
        {showButton && (
          <Button variant="outline" className="w-full" onClick={handleToggleShowAllEvents}>
            {showAllEvents ? 'Ver menos' : 'Ver mais'}
          </Button>
        )}
      </div>
    </div>
  )
}

const Item: React.FC<{
  icon: React.ReactNode
  title: string
  description?: string
  time: string
  link?: string
  installment?: number
  installments?: number
  dueDate?: string
  showConnector?: boolean
  controls?: JSX.Element
}> = ({ icon, installment, dueDate, installments, title, description, time, link, showConnector = true, controls }) => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const handleCopyClipboard = (content: string) => {
    navigator.clipboard.writeText(content).then(() => {
      toast({
        title: t('contentCopiedSuccessfully'),
        description: 'The content has been copied to your clipboard.',
      })
    })
  }

  return (
    <div>
      <div className="p-1">
        <div className="flex gap-4">
          <div className="flex flex-col items-center">
            <div className="rounded-full bg-gray-100 p-2">{icon}</div>
            {showConnector && <div className="w-px h-full bg-secondary my-3" />}
          </div>
          <div className="flex flex-col">
            <div className="flex gap-2 items-center">
              <p className="text-base">
                <Trans
                  i18nKey={title}
                  values={{
                    link,
                    installment,
                    installments,
                  }}
                  components={
                    link
                      ? [
                          // eslint-disable-next-line jsx-a11y/anchor-has-content
                          <a
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-primary hover:underline"
                          />,
                        ]
                      : undefined
                  }
                />
              </p>
              {link && (
                <button className="bg-transparent p-0" onClick={() => handleCopyClipboard(link)}>
                  <Copy className="h-4 w-4" />
                </button>
              )}
            </div>
            {description && (
              <p className="leading-tight text-base text-muted-foreground whitespace-pre-line">
                {t(description, { dueDate: moment(dueDate).format('DD [de] MMM[.] YYYY') })}
              </p>
            )}
            <p className="text-sm mt-0.5 text-muted-foreground">{moment(time).format('D MMM. YYYY HH:mm:ss')}</p>
            {controls}
          </div>
        </div>
      </div>
    </div>
  )
}

const ItemList: React.FC<{ items: SmartInstallmentInvoiceEvent[]; showConnector: boolean }> = ({
  items,
  showConnector,
}) => {
  const [index, setIndex] = useState<number>(0)

  return (
    <Item
      icon={<EventIcon type={items[index].type} />}
      title={items[index].title}
      link={items[index].link}
      description={items[index].description}
      time={items[index].date}
      showConnector={showConnector}
      controls={
        <div className="flex items-center mt-2">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setIndex((prev) => (prev === 0 ? items.length - 1 : prev - 1))}
          >
            <ArrowLeft className="h-4 w-4" />
          </Button>
          <span className="text-xs text-muted-foreground">
            {index + 1} / {items.length}
          </span>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setIndex((prev) => (prev === items.length - 1 ? 0 : prev + 1))}
          >
            <ArrowRight className="h-4 w-4" />
          </Button>
        </div>
      }
    />
  )
}

const EventIcon: React.FC<{ type: SmartInstallmentInvoiceEventType | undefined }> = ({ type }) => {
  const iconSize = 16
  const commonStyles = 'mt-1 w-4 h-4 p-1 rounded-full'

  switch (type) {
    case SmartInstallmentInvoiceEventType.CREATED:
      return <TvMinimal className={cn(commonStyles, 'text-emerald-700', 'bg-emerald-100')} />
    case SmartInstallmentInvoiceEventType.COMPLETED:
      return <TvMinimal className={cn(commonStyles, 'text-green-700', 'bg-green-100')} />
    case SmartInstallmentInvoiceEventType.REGULARIZED:
      return <TvMinimal className={cn(commonStyles, 'text-gray-700', 'bg-gray-100')} />
    case SmartInstallmentInvoiceEventType.CANCELED:
      return <TvMinimal className={cn(commonStyles, 'text-orange-700', 'bg-orange-100')} />
    case SmartInstallmentInvoiceEventType.OVERDUE:
      return <TvMinimal className={cn(commonStyles, 'text-red-700', 'bg-red-100')} />
    case SmartInstallmentInvoiceEventType.DISPUTED:
      return <Flag size={iconSize} className="mr-1 text-orange-600" fill-current />
    case SmartInstallmentInvoiceEventType.ERROR:
      return <CircleX size={iconSize} className="mr-1 text-red-700" fill-current />
    case SmartInstallmentInvoiceEventType.INSTALLMENT_OVERDUE:
      return <Clock className="text-red-700" fill-current />
    case SmartInstallmentInvoiceEventType.REFUNDED:
      return <IterationCw size={iconSize} className="text-gray-700" fill-current />
    case SmartInstallmentInvoiceEventType.PAID:
      return <Banknote size={iconSize} className="text-green-700" fill-current />
    case SmartInstallmentInvoiceEventType.PAYMENT_PAGE_CREATED:
      return <FileIcon size={iconSize} className="text-gray-700" fill-current />
    default:
      return <InfoIcon size={iconSize} className="text-gray-700" fill-current />
  }
}
