import { container } from '@hub-la/fe-container'
import { UserInvoicesDetailsPage, UserInvoicesProvider } from '@hub-la/fe-user-invoices'

export const UserInvoicesDetailsInit = () => {
  return (
    <UserInvoicesProvider container={container}>
      <UserInvoicesDetailsPage />
    </UserInvoicesProvider>
  )
}
