import { SectionResponse, stopEventPropagation } from '@hub-la/fe-post'
import { Input } from '@hub-la/shadcn'
import React, { useEffect, useRef } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { useSaveSection } from '../../hooks/use-save-section'

type Props = {
  section: SectionResponse
  productId: string
  editorRef: React.RefObject<HTMLInputElement>
}

export const SectionNameEditor: React.FC<Props> = ({ editorRef, section, productId }) => {
  const saveSection = useSaveSection()
  const sizer = useRef<HTMLSpanElement>(null)

  const handleName = useDebouncedCallback((name: string) => {
    saveSection.mutate({
      id: section.id,
      payload: {
        name,
        productId,
      },
    })
  }, 1000)

  const handleSize = (value: string) => {
    if (sizer.current && editorRef.current) {
      sizer.current.textContent = value
      const width = sizer.current.offsetWidth
      editorRef.current.style.width = `${width + 10}px` // Add some padding
    }
  }

  useEffect(() => {
    handleSize(section.name)
  }, [section.name])

  return (
    <>
      <Input
        ref={editorRef}
        defaultValue={section.name}
        onClick={stopEventPropagation}
        onChange={(event) => {
          const value = event.target.value
          handleSize(value)
          handleName(value)
        }}
        className="border-none focus-visible:outline-none focus-visible:ring-0 focus-visible:border-none focus-visible:bg-zinc-100 px-1 h-8 text-base"
      />
      <span
        ref={sizer}
        style={{
          position: 'absolute',
          visibility: 'hidden',
          whiteSpace: 'pre',
          font: 'inherit',
          letterSpacing: 'inherit',
          textTransform: 'inherit',
          padding: 'inherit',
        }}
      />
    </>
  )
}
