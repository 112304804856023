import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Input,
  Label,
} from '@hub-la/shadcn'
import { Download } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ExportAlreadyInProgressError } from '../../../domain/errors/export-already-in-progress'
import { useDownloadInvoicesXlsx } from '../../hooks/use-download-invoices-xlsx'

type Props = {
  filters: any // Update this type to match your filters structure
  offers: any[] // Update this type to match your offers structure
}

export const ConfirmExportModal: React.FC<Props> = ({ filters, offers }) => {
  const { t } = useTranslation()
  const [receiverEmail, setReceiverEmail] = React.useState('')
  const [showConfirmModal, setShowConfirmModal] = React.useState(false)

  const {
    mutateAsync: exportXlsx,
    isLoading: loading,
    isSuccess,
    isError,
    data,
    error,
    reset,
  } = useDownloadInvoicesXlsx({
    ...filters,
    receiverEmail,
  })

  function handleClose() {
    reset()
    setShowConfirmModal(false)
  }

  function getModalMessage() {
    if (isSuccess) return t('exportXlsx.successMessage', { email: data?.email })
    if (error instanceof ExportAlreadyInProgressError) return t('exportXlsx.conflictErrorMessage')
    return t('exportXlsx.generalErrorMessage')
  }

  async function handleSubmit() {
    await exportXlsx()
      .then(() => {
        handleClose()
      })
      .catch(console.error)
  }
  if (isSuccess || isError) {
    return (
      <Dialog open={showConfirmModal} onOpenChange={handleClose}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t('exportXlsx.title')}</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <p>{getModalMessage()}</p>
          </div>
          <div className="flex justify-end">
            <Button onClick={handleClose} variant="outline">
              {t('exportXlsx.getIt')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Dialog open={showConfirmModal} onOpenChange={() => setShowConfirmModal((prevState) => !prevState)}>
      <DialogTrigger asChild>
        <Button variant="outline" className="w-full">
          <Download className="h-4 w-4 mr-2" />
          {t('exportXlsx.confirm')}
        </Button>
      </DialogTrigger>
      <DialogContent className="w-full md:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{t('exportXlsx.title')}</DialogTitle>
        </DialogHeader>
        <div className="grid my-4">
          <div className="grid gap-2">
            {filters.search && (
              <div className="grid grid-cols-3 items-center gap-4">
                <Label>Busca</Label>
                <div className="col-span-2 h-auto p-2 border rounded-lg items-center text-sm bg-gray-50 text-muted-foreground">
                  {filters.search}
                </div>
              </div>
            )}
            {filters.offerIds?.length > 0 && (
              <div className="grid grid-cols-3 items-center gap-4">
                <Label>Produtos</Label>
                <div className="col-span-2 h-auto p-2 border rounded-lg items-center text-sm bg-gray-50 text-muted-foreground">
                  {filters.offerIds.map((id) => offers.find((o) => o.id === id)?.name).join(', ')}
                </div>
              </div>
            )}
            {filters.startDate && filters.endDate && (
              <div className="grid grid-cols-3 items-center gap-4">
                <Label>Data de criação</Label>
                <div className="col-span-2 h-auto p-2 border rounded-lg items-center text-sm bg-gray-50 text-muted-foreground">
                  {new Date(filters.startDate).toLocaleDateString()} - {new Date(filters.endDate).toLocaleDateString()}
                </div>
              </div>
            )}
            {filters.statuses?.length > 0 && (
              <div className="grid grid-cols-3 items-center gap-4">
                <Label>Status</Label>
                <div className="col-span-2 h-auto p-2 border rounded-lg items-center text-sm bg-gray-50 text-muted-foreground">
                  {filters.statuses.map((status) => t(`status.${status}`)).join(', ')}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="grid gap-2">
          <Label htmlFor="receiverEmail">{t('exportXlsx.email')}</Label>
          <Input
            id="receiverEmail"
            placeholder="example@email.com"
            type="email"
            value={receiverEmail}
            onChange={(e) => setReceiverEmail(e.target.value)}
          />
        </div>
        <div className="flex justify-end space-x-2">
          <DialogClose>
            <Button variant="outline" disabled={loading}>
              {t('exportXlsx.cancel')}
            </Button>
          </DialogClose>
          <Button onClick={handleSubmit} loading={loading} className="flex gap-2">
            <Download className="h-4 w-4" />
            {t('exportXlsx.confirm')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
