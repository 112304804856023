export const INTEGRATION_CATEGORIES_LIST = [
  {
    name: 'Automações',
    description: 'Ferramentas de automatizações para facilitar e potencializar a gestão do seu negócio.',
    integrations: [
      {
        title: 'Webhooks',
        description: 'Integração com plataformas externas',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fwebhook.png?alt=media',
        enabled: true,
        provider: 'webhook',
      },
      {
        title: 'Zapier',
        description: 'Ferramenta de automatização de fluxos de trabalho.',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fzapier.png?alt=media',
        enabled: true,
        provider: 'zapier',
      },
    ],
  },
  {
    name: 'CRM',
    description: 'Crie réguas de comunicações eficientes para os seus clientes.',
    integrations: [
      {
        title: 'ActiveCampaign',
        description: 'E-mail marketing, CRM e automação',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2FRectangle%202.png?alt=media',
        enabled: true,
        provider: 'active-campaign',
      },
      {
        title: 'SellFlux',
        description: 'Automação, marketing e CRM',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fsell-flux.png?alt=media',
        enabled: true,
        provider: 'sell-flux',
      },
      {
        title: 'LeadLovers',
        description: 'E-mail marketing, CRM e automação',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Flead_lovers.png?alt=media',
        enabled: true,
        provider: 'leadlovers',
      },
      {
        title: 'GDigital',
        description: 'Automação, marketing e CRM',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fg-digital.png?alt=media',
        enabled: true,
        provider: 'g-digital',
      },
      {
        title: 'ZapCerto',
        description: 'Disparos em Massa, Automações, Marketing Conversacional.',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fzapcerto.png?alt=media',
        enabled: true,
        provider: 'zapcerto',
      },
      {
        title: 'HubSpot',
        description: 'Marketing, CRM e automação',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fhubspot.png?alt=media',
        enabled: true,
        provider: 'hubspot',
      },
      {
        title: 'RDStation',
        description: 'E-mail marketing, CRM e automação',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Frdstation.png?alt=media',
        enabled: true,
        provider: 'rdstation',
      },
    ],
  },
  {
    name: 'Pixel de Rastreamento',
    description: 'Ganhe mais insights sobre os seus produtos',
    integrations: [
      {
        title: 'Meta Pixel',
        description: 'Ferramenta para mensurar, otimizar e criar suas campanhas de anúncios na Meta.',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fmeta_pixel.png?alt=media',
        enabled: true,
        provider: 'meta-pixel',
      },
      {
        title: 'Google Tag Manager',
        description: 'Ferramenta de gerenciamento de tags do Google.',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fgoogle_tag_manager.png?alt=media',
        enabled: true,
        provider: 'google-tag-manager',
      },
    ],
  },
  {
    name: 'Notas Fiscais',
    description: 'Evite trabalho manuais e tenha NFSe emitidas automaticamente',
    integrations: [
      {
        title: 'eNotas',
        description: 'Ferramenta de emissão automática de NFSe.',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fenotas.png?alt=media',
        enabled: true,
        provider: 'enotas',
      },
      {
        title: 'Omie',
        description: 'Ferramenta de emissão automática de NFSe.',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fomie.png?alt=media',
        enabled: true,
        provider: 'omie',
      },
    ],
  },
  {
    name: 'Área de membros',
    description: 'Venda pela Hubla e entregue seu conteúdo através de outra plataforma',
    integrations: [
      {
        title: 'MemberKit',
        description: 'Área de membros com turmas, área de conversas e gamificação.',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fmemberkit.jpg?alt=media',
        enabled: true,
        provider: 'memberkit',
      },
      {
        title: 'TheMembers',
        description: 'Área de membros com turmas, comunidade, gamificação e app.',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fthemembers.png?alt=media',
        enabled: true,
        provider: 'themembers',
      },
    ],
  },
]
