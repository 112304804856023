import { Label, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@hub-la/shadcn'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IntervalType } from '../../domain/enums/interval-type.enum'

const plans = Object.values(IntervalType)

interface PlansSelectProps extends React.ComponentPropsWithoutRef<typeof Select> {
  label: string
  removePlans?: IntervalType[]
  error?: boolean
  helperText?: string
}

export const PlansSelect: React.FC<PlansSelectProps> = ({ label, removePlans = [], error, helperText, ...props }) => {
  const { t } = useTranslation()

  return (
    <div className="space-y-1.5">
      <Label className="leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">{label}</Label>
      <Select {...props}>
        <SelectTrigger className={`w-full ${error ? 'border-red-500' : ''}`}>
          <SelectValue placeholder={t('selectPlan')} />
        </SelectTrigger>
        <SelectContent>
          {plans
            .filter((plan) => props.value === plan || !removePlans.includes(plan))
            .map((availablePlanType) => (
              <SelectItem key={availablePlanType} value={availablePlanType}>
                {t(`plans.${availablePlanType}`)}
              </SelectItem>
            ))}
        </SelectContent>
      </Select>
      {helperText && <p className={`text-sm ${error ? 'text-red-500' : 'text-gray-500'}`}>{helperText}</p>}
    </div>
  )
}
