import { ImageUploader } from '@hub-la/fe-asset'
import { Card, CardContent, Input, Separator } from '@hub-la/shadcn'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { PriceType } from '../../../../domain/enums/price-type.enum'
import { ColorPicker } from '../../../components/color-picker'
import { InstallmentsSelect } from '../../../components/installments-select'
import { OfferSection } from '../../../components/offer-section'
import { PlansSelect } from '../../../components/plans-select'
import { SwitchToggleChildren } from '../../../components/switch-toggle-children'
import { OfferSchemaValidationType } from '../../../validations/offer-validation'
import { OrderBumpSwitch } from './components/options/order-bump-switch'
import { PaymentMethodSelect } from './components/payment-method-select'

const CheckoutContent = () => {
  const { t } = useTranslation()

  const { watch } = useFormContext<OfferSchemaValidationType>()
  const priceType = watch('pricing.priceType')
  const { productId } = useParams<{ productId: string }>()

  return (
    <>
      <OfferSection title={t('offer.checkoutTab.customization.title')}>
        <Card>
          <CardContent className="space-y-4 pt-4">
            <div className="flex justify-between gap-4">
              <div>
                <h3 className="text-sm font-medium mb-1">{t('offer.checkoutTab.customization.logo.label')}</h3>
                <p className="text-sm text-gray-500">{t('offer.checkoutTab.customization.logo.description')}</p>
              </div>
              <Controller
                name="checkout.creatorLogoUrl"
                render={({ field }) => (
                  <ImageUploader
                    name={field.name}
                    path={`/checkout/${productId}`}
                    className="w-24 h-14"
                    value={field.value}
                    onChange={(file) => field.onChange(file?.URL)}
                  />
                )}
              />
            </div>

            <Separator />

            <div className="flex justify-between gap-4">
              <div>
                <h3 className="text-sm font-medium mb-1">{t('offer.checkoutTab.customization.bannerMobile.label')}</h3>
                <p className="text-sm text-gray-500">{t('offer.checkoutTab.customization.bannerMobile.description')}</p>
              </div>
              <Controller
                name="checkout.bannerMobileUrl"
                render={({ field }) => (
                  <ImageUploader
                    name={field.name}
                    path={`/checkout/${productId}`}
                    className="w-36 h-20"
                    value={field.value}
                    onChange={(file) => field.onChange(file?.URL)}
                  />
                )}
              />
            </div>

            <Separator />

            <div className="flex justify-between gap-4">
              <div>
                <h3 className="text-sm font-medium mb-1">{t('offer.checkoutTab.customization.bannerDesktop.label')}</h3>
                <p className="text-sm text-gray-500">
                  {t('offer.checkoutTab.customization.bannerDesktop.description')}
                </p>
              </div>
              <Controller
                name="checkout.bannerDesktopUrl"
                render={({ field }) => (
                  <ImageUploader
                    name={field.name}
                    path={`/checkout/${productId}`}
                    className="w-36 h-20"
                    value={field.value}
                    onChange={(file) => field.onChange(file?.URL)}
                  />
                )}
              />
            </div>

            <Separator />

            <div className="space-y-4">
              {priceType === PriceType.RECURRING && (
                <Controller
                  name="checkout.defaultPlan"
                  render={({ field, fieldState }) => (
                    <PlansSelect
                      label={t('offer.checkoutTab.customization.preferences.planPreselected')}
                      error={Boolean(fieldState.error?.type)}
                      helperText={fieldState.error?.type && t(`fieldErrors.${fieldState.error?.type}`)}
                      {...field}
                    />
                  )}
                />
              )}

              <Controller
                name="checkout.defaultInstallment"
                render={({ field, fieldState }) => (
                  <InstallmentsSelect
                    label={t('offer.checkoutTab.customization.preferences.installmentPreselected')}
                    error={Boolean(fieldState.error?.type)}
                    helperText={fieldState.error?.type && t(`fieldErrors.${fieldState.error?.type}`)}
                    {...field}
                  />
                )}
              />

              {/* TODO: Priceformat to be implemented in the future */}
              {/* <InstallmentsSelect label="Formato de preço" /> */}
            </div>
          </CardContent>
        </Card>
      </OfferSection>
      <OfferSection title={t('offer.checkoutTab.paymentMethods.title')}>
        <PaymentMethodSelect />
      </OfferSection>
      <OfferSection title={t('offer.checkoutTab.options.title')}>
        <div className="space-y-6">
          <OrderBumpSwitch />

          <SwitchToggleChildren name="checkout.isTimer" label={t('offer.checkoutTab.options.timer.title')}>
            <Controller
              name="checkout.timerSeconds"
              render={({ field, fieldState }) => (
                <div className="space-y-2">
                  <Input
                    type="time"
                    step="1"
                    placeholder={t('offer.checkoutTab.options.timer.timerSeconds')}
                    className={fieldState.error ? 'border-red-500' : ''}
                    {...field}
                  />
                  {fieldState.error && (
                    <p className="text-sm text-red-500">{t(`fieldErrors.${fieldState.error.type}`)}</p>
                  )}
                  <ColorPicker name="checkout.timerBgColor" />
                </div>
              )}
            />
          </SwitchToggleChildren>
          <SwitchToggleChildren
            name="checkout.isYoutubeEmbed"
            label={t('offer.checkoutTab.options.youtubeEmbed.title')}
          >
            <Controller
              name="checkout.youtubeEmbedUrl"
              render={({ field, fieldState }) => (
                <div className="space-y-2">
                  <Input
                    placeholder={t('offer.checkoutTab.options.youtubeEmbed.youtubeEmbedUrl')}
                    className={fieldState.error ? 'border-red-500' : ''}
                    {...field}
                  />
                  {fieldState.error && (
                    <p className="text-sm text-red-500">{t(`fieldErrors.${fieldState.error.type}`)}</p>
                  )}
                </div>
              )}
            />
          </SwitchToggleChildren>

          <SwitchToggleChildren
            name="checkout.showCouponField"
            label={t('offer.checkoutTab.options.showCouponField.title')}
          />

          <SwitchToggleChildren
            name="checkout.billingAddress"
            label={t('offer.checkoutTab.options.billingAddress.title')}
          />

          <SwitchToggleChildren
            name="checkout.isCreatorSupport"
            label={t('offer.checkoutTab.options.creatorSupport.title')}
          >
            <Controller
              name="checkout.creatorSupportUrl"
              render={({ field, fieldState }) => (
                <div className="space-y-2">
                  <Input
                    placeholder={t('offer.checkoutTab.options.creatorSupport.creatorSupportUrl')}
                    className={fieldState.error ? 'border-red-500' : ''}
                    {...field}
                  />
                  {fieldState.error && (
                    <p className="text-sm text-red-500">{t(`fieldErrors.${fieldState.error.type}`)}</p>
                  )}
                  <p className="text-xs text-gray-500 mb-2">
                    {t('offer.checkoutTab.options.creatorSupport.description')}
                  </p>
                </div>
              )}
            />
          </SwitchToggleChildren>

          <SwitchToggleChildren name="checkout.isTermsUrl" label={t('offer.checkoutTab.options.termsUrl.title')}>
            <Controller
              name="checkout.termsUrl"
              render={({ field, fieldState }) => (
                <div className="space-y-2">
                  <Input
                    placeholder={t('offer.checkoutTab.options.termsUrl.termsUrl')}
                    className={fieldState.error ? 'border-red-500' : ''}
                    {...field}
                  />
                  {fieldState.error && (
                    <p className="text-sm text-red-500">{t(`fieldErrors.${fieldState.error.type}`)}</p>
                  )}
                  <p className="text-xs text-gray-500 mb-2">{t('offer.checkoutTab.options.termsUrl.description')}</p>
                </div>
              )}
            />
          </SwitchToggleChildren>
        </div>
      </OfferSection>
    </>
  )
}

export { CheckoutContent }
