import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from 'lucide-react'
import { ComponentProps } from 'react'
import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '.'
import { cn } from '../utils'

type TablePaginationProps = {
  page: number
  setPage: (page: number) => void
  lastPage: number
  pageSize: number
  setPageSize: (pageSize: number) => void
} & ComponentProps<'div'>

// @TODO - This component should be using i18n, and not hardcoded strings
const TablePagination = ({
  page,
  setPage,
  lastPage,
  pageSize,
  setPageSize,
  className,
  ...props
}: TablePaginationProps) => {
  const pageSizes = [25, 50, 100, 200]
  const canGoToPreviousPage = page > 1
  const canGoToNextPage = page < lastPage

  const goToFirstPage = () => setPage(1)
  const goToPreviousPage = () => setPage(page - 1)
  const goToNextPage = () => setPage(page + 1)
  const goToLastPage = () => setPage(lastPage)

  const handlePageSize = (pageSize: string) => {
    setPageSize(Number(pageSize))
    setPage(1)
  }

  return (
    <div className={cn('flex items-center justify-end', className)} {...props}>
      <div className="flex items-center gap-6 lg:gap-8">
        <div className="hidden sm:flex items-center gap-2">
          <p className="text-sm font-medium">Linhas por página</p>

          <Select value={pageSize.toString()} onValueChange={handlePageSize}>
            <SelectTrigger className="h-8 w-16">
              <SelectValue placeholder={pageSize} />
            </SelectTrigger>

            <SelectContent side="top">
              {pageSizes.map((pageSize) => (
                <SelectItem key={pageSize} value={pageSize.toString()}>
                  {pageSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className="flex items-center justify-center text-sm font-medium">
          Página {page} de {lastPage}
        </div>

        <div className="flex items-center gap-2">
          <Button
            variant="outline"
            className="hidden h-8 w-8 p-0 sm:flex"
            onClick={goToFirstPage}
            disabled={!canGoToPreviousPage}
          >
            <span className="sr-only">Ir para a primeira página</span>
            <ChevronsLeft className="h-4 w-4" />
          </Button>

          <Button variant="outline" className="h-8 w-8 p-0" onClick={goToPreviousPage} disabled={!canGoToPreviousPage}>
            <span className="sr-only">Ir para a página anterior</span>
            <ChevronLeft className="h-4 w-4" />
          </Button>

          <Button variant="outline" className="h-8 w-8 p-0" onClick={goToNextPage} disabled={!canGoToNextPage}>
            <span className="sr-only">Ir para a próxima página</span>
            <ChevronRight className="h-4 w-4" />
          </Button>

          <Button
            variant="outline"
            className="hidden h-8 w-8 p-0 sm:flex"
            onClick={goToLastPage}
            disabled={!canGoToNextPage}
          >
            <span className="sr-only">Ir para a última página</span>
            <ChevronsRight className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </div>
  )
}
TablePagination.displayName = 'TablePagination'

export { TablePagination }
