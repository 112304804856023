import { PaletteMode, PaletteOptions } from '@mui/material'
import { Tokens } from './tokens.types'

export * from './palette.types'

export const makePalette = (mode: PaletteMode, tokens: Tokens): PaletteOptions => ({
  mode,
  primary: {
    main: tokens.primary,
    contrastText: tokens.onPrimary,
  },
  onPrimary: {
    main: tokens.onPrimary,
    contrastText: tokens.primary,
  },
  primaryContainer: {
    main: tokens.primaryContainer,
    contrastText: tokens.onPrimaryContainer,
  },
  onPrimaryContainer: {
    main: tokens.onPrimaryContainer,
    contrastText: tokens.primaryContainer,
  },
  success: {
    main: tokens.success,
  },
  onSuccess: {
    main: tokens.onSuccess,
  },
  successContainer: {
    main: tokens.successContainer,
  },
  onSuccessContainer: {
    main: tokens.onSuccessContainer,
  },
  background: {
    main: tokens.background,
  },
  onBackground: {
    main: tokens.onBackground,
  },
  error: {
    main: tokens.error,
    contrastText: tokens.onError,
  },
  onError: {
    main: tokens.onError,
    contrastText: tokens.error,
  },
  warning: {
    main: tokens.warning,
  },
  onWarning: {
    main: tokens.onWarning,
  },
  warningContainer: {
    main: tokens.warningContainer,
  },
  onWarningContainer: {
    main: tokens.onWarningContainer,
  },
  info: {
    main: tokens.info,
  },
  onInfo: {
    main: tokens.onInfo,
  },
  infoContainer: {
    main: tokens.infoContainer,
  },
  onInfoContainer: {
    main: tokens.onInfoContainer,
  },
  surface: {
    main: tokens.surface,
  },
  onSurface: {
    main: tokens.onSurface,
  },
  surfaceAt1: {
    main: tokens.surfaceAt1,
  },
  surfaceAt2: {
    main: tokens.surfaceAt2,
  },
  surfaceAt3: {
    main: tokens.surfaceAt3,
  },
  surfaceAt4: {
    main: tokens.surfaceAt4,
  },
  surfaceAt5: {
    main: tokens.surfaceAt5,
  },
  surfaceVariant: {
    main: tokens.surfaceVariant,
  },
  onSurfaceVariant: {
    main: tokens.onSurfaceVariant,
  },
  errorContainer: {
    main: tokens.errorContainer,
    contrastText: tokens.onErrorContainer,
  },
  onErrorContainer: {
    main: tokens.onErrorContainer,
    contrastText: tokens.errorContainer,
  },
  outline: {
    main: tokens.outline,
  },

  greenPrimary: {
    main: tokens.greenPrimary,
  },
  greenSecondary: {
    main: tokens.greenSecondary,
  },
  redPrimary: {
    main: tokens.redPrimary,
  },
  redSecondary: {
    main: tokens.redSecondary,
  },
  bluePrimary: {
    main: tokens.bluePrimary,
  },
  blueSecondary: {
    main: tokens.blueSecondary,
  },
  purplePrimary: {
    main: tokens.purplePrimary,
  },
  purpleSecondary: {
    main: tokens.purpleSecondary,
  },
  pinkPrimary: {
    main: tokens.pinkPrimary,
  },
  pinkSecondary: {
    main: tokens.pinkSecondary,
  },
  orangePrimary: {
    main: tokens.orangePrimary,
  },
  orangeSecondary: {
    main: tokens.orangeSecondary,
  },
  neutralPrimary: {
    main: tokens.neutralPrimary,
  },
  neutralSecondary: {
    main: tokens.neutralSecondary,
  },
  yellowPrimary: {
    main: tokens.yellowPrimary,
  },
  yellowSecondary: {
    main: tokens.yellowSecondary,
  },
})
