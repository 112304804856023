import { Button, Checkbox, Dialog, DialogContent } from '@hub-la/shadcn'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalContent } from './modal-content'

interface TermsOfUseProps {
  error?: boolean
  helperText?: string
  onBlur?: (event: React.FocusEvent<HTMLButtonElement>) => void
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

export const TermsOfUse: React.FC<TermsOfUseProps> = ({ error, helperText, onBlur, onChange }) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent>
          <ModalContent />
        </DialogContent>
      </Dialog>

      <div className="flex items-center" data-testid="terms-of-use">
        <Checkbox
          id="termsOfUse"
          onBlur={onBlur}
          onCheckedChange={(checked) => onChange({ target: { name: 'termsOfUse' } } as any, checked as boolean)}
        />
        <label htmlFor="termsOfUse" className="ml-2 text-sm">
          {t('Receiver.inviteForm.fields.terms_of_use.prefix')}{' '}
          <Button
            variant="link"
            className="p-0 h-auto text-sm font-normal underline"
            onClick={() => setIsOpen(true)}
            data-testid="terms-button"
          >
            {t('Receiver.inviteForm.fields.terms_of_use.link')}
          </Button>{' '}
          {t('Receiver.inviteForm.fields.terms_of_use.suffix')}
        </label>
      </div>
      {helperText && <p className={`mt-1 text-sm ${error ? 'text-red-500' : 'text-gray-500'}`}>{helperText}</p>}
    </div>
  )
}
