import { Uploader } from '@hub-la/fe-asset'
import {
  AboutAssetDtoTypeEnum,
  ProductSettingsWelcomeMessageResponseDto,
  UpdateProductSettingsWelcomeMessageRequestDto,
} from '@hub-la/sdk-bff-product'
import { Button, Card, CardContent } from '@hub-la/shadcn'
import { useFormik } from 'formik'
import { Paperclip, PlusCircle, Trash2 } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { Envs } from '../../../../envs'
import { Section } from '../../../components/section'
import { useGetSettings } from '../../../hooks/settings/use-get-settings'
import { useSaveSettings } from '../../../hooks/settings/use-save-settings'
import { Editor } from './editor'

type Values = UpdateProductSettingsWelcomeMessageRequestDto
const validationSchema = Yup.object().shape({})
const makeInitialValues = (data?: ProductSettingsWelcomeMessageResponseDto): Values => {
  return {
    message: data?.message ?? '',
    assets: data?.assets ?? [],
  }
}

export const WelcomeMessage = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const { productId } = useParams<{ productId: string }>()
  const { mutateAsync: saveSettings } = useSaveSettings()
  const { data, isLoading } = useGetSettings({ productId, type: 'welcome-message' })
  const { t } = useTranslation()

  const formik = useFormik<Values>({
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: makeInitialValues(data),
    onSubmit: async (values) => {
      await saveSettings({
        productId,
        type: 'welcome-message',
        data: values,
      })
    },
  })

  return (
    <Section
      title={t('settings.welcomeMessage.title')}
      subtitle={t('settings.welcomeMessage.subtitle')}
      formik={formik}
    >
      <div className="flex flex-col space-y-4">
        <Editor
          value={formik.values.message ?? ''}
          onEditorChange={(data) => formik.setFieldValue('message', data)}
          disabled={isLoading}
        />

        <div className="flex justify-between items-center">
          <span>{t('settings.welcomeMessage.files')}</span>

          <Uploader
            name="files"
            path={`${productId}/files`}
            setLoading={setLoading}
            accept={Envs.ATTACHMENT_FILE_MIMETYPE_ALLOWED}
            onChange={(file) => {
              formik.setFieldValue('assets', [
                ...(formik.values.assets ?? []),
                {
                  name: file?.name,
                  url: file?.URL,
                  type: AboutAssetDtoTypeEnum.WEB,
                },
              ])
            }}
          >
            <Button loading={loading || isLoading} variant="outline" size="sm">
              <PlusCircle className="w-4 h-4 mr-2" />
              Adicionar
            </Button>
          </Uploader>
        </div>

        {(formik.values.assets ?? [])?.map((asset, idx) => (
          <Card key={idx}>
            <CardContent className="flex justify-between items-center p-4">
              <div className="flex items-center">
                <Paperclip className="w-4 h-4 mr-2 text-muted-foreground" />

                <span className="text-sm">{asset.name}</span>
              </div>

              <Button
                variant="ghost"
                size="sm"
                onClick={() =>
                  formik.setFieldValue(
                    'assets',
                    formik.values.assets?.filter((_, index) => index !== idx),
                  )
                }
              >
                <Trash2 className="w-4 h-4" />
              </Button>
            </CardContent>
          </Card>
        ))}
      </div>
    </Section>
  )
}
