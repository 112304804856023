import React from 'react'
import { InfoTooltip } from '../../components/info-tooltip'

type ContentRowProps = {
  title: string
  content: React.ReactElement
  tooltip?: string
  fontWeight?: 'bold' | 'light'
  showDivider?: boolean
}

export const ContentRow = ({ title, content, tooltip, fontWeight, showDivider = false }: ContentRowProps) => {
  return (
    <div className="flex items-center justify-between space-x-2">
      <span className="text-base text-muted-foreground">
        {title}
        {tooltip && <InfoTooltip title={tooltip} />}
      </span>
      <span className="text-base text-right"> {content}</span>
    </div>
  )
}
