import { Discussion, DiscussionProvider } from '@hub-la/fe-discussion'
import { useGetRoleplayReference, useRoleplay } from '@hub-la/fe-roleplay'
import { Skeleton } from '@hub-la/shadcn'
import Parser from 'html-react-parser'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PostResponse, PostStatus } from '../../../domain/dtos'
import { IsPostContentHidden } from '../../../usecases/is-post-content-hidden'
import useCurrentUser from '../../hooks/use-current-user'
import { useGetMembersArea } from '../../hooks/use-get-members-area'
import { DateRange } from '../date-range'
import { EventCard } from '../event-card'
import { PostAttachmentView } from '../post-attachment-list/post-attachment-view'
import { PostDetailCover } from '../post-detail-cover'
import { Sidebar } from '../sidebar/sidebar'
import { TimeToReleaseCard } from '../time-to-release-card'

type PostContentProps = {
  post: PostResponse
  timeToRelease?: string
  canAccess?: boolean
  isFetching?: boolean
}

export const PostContent = ({ post, timeToRelease, canAccess, isFetching }: PostContentProps) => {
  const { t } = useTranslation()
  const { isRoleplay } = useRoleplay()
  const firebaseCurrentUser = useCurrentUser()
  const { data: roleplayUserData } = useGetRoleplayReference({ enabled: isRoleplay })

  // minutes to show the event button to buyers enter the event
  const minutesBeforeEventStart = 10

  const initialStarted = post.event
    ? moment(new Date()).isBetween(
        moment(post.event.startTime).subtract(minutesBeforeEventStart, 'minutes'),
        moment(post.event.endTime),
      )
    : false

  const [started, setStarted] = useState<boolean>(initialStarted)

  const today = moment()
  const dateDiff = today.diff(post?.updatedAt ?? post?.createdAt, 'days')
  const isDraft = post?.status === PostStatus.DRAFT
  const { data } = useGetMembersArea({ productId: post.product.id, enabled: canAccess })
  const showComments = canAccess && data?.postComment

  const isPostContentHidden = new IsPostContentHidden().execute(post)
  const releaseAt = new Date(post.releaseAt ?? 0)

  useEffect(() => {
    setStarted(initialStarted)
  }, [post])

  const currentUser = isRoleplay ? roleplayUserData : firebaseCurrentUser
  const currentUserId = isRoleplay ? roleplayUserData?.userId : firebaseCurrentUser?.id

  const canRenderEventCard = post.roleType !== 'not-member'

  if (isFetching) {
    return (
      <div className="pb-28 w-full px-0 md:px-6 flex flex-col lg:flex-row gap-6 justify-between">
        <main className="relative w-full flex lg:flex-[2.5] flex-col gap-4">
          <Skeleton className="w-full h-[245px] lg:h-[565px]" />
          <Skeleton className="w-32 h-10" />
          <Skeleton className="w-48 h-8" />
        </main>
        <aside className="lg:w-[340px]">
          <Sidebar
            productId={post.product.id}
            post={{
              id: post.id,
              cover: post.cover,
              title: post.title,
            }}
          />
        </aside>
      </div>
    )
  }

  return (
    <div id="post-detail" className="pb-28 w-full px-0 md:px-6 flex flex-col lg:flex-row gap-6 justify-between">
      <main className="relative w-full flex lg:flex-[2.5] flex-col gap-4">
        <PostDetailCover
          cover={post.cover}
          customCover={post.customCover?.url}
          sectionId={post.sectionId}
          postId={post.id}
          hideContent={isPostContentHidden}
          hideText={canAccess ? timeToRelease : t('postDetail.paywall.onlyMembers')}
          isLive={started}
        />
        <div className="space-y-4">
          {started && (
            <div className="p-4 flex items-center justify-center bg-gradient-to-r from-[#d8fd6c] via-[#dde2fe] to-[#79fbac] rounded-md">
              <span className="text-sm font-semibold text-gray-900">{t('postDetail.meeting.liveNow')}</span>
            </div>
          )}

          <div className="space-y-2">
            <h1 className="text-xl sm:text-2xl lg:text-3xl font-semibold text-white">{post.title}</h1>
            {post.subtitle && <p className="text-lg text-zinc-300">{post.subtitle}</p>}
          </div>

          {post.event && (
            <div className="space-y-4">
              <DateRange startTime={new Date(post.event?.startTime)} endTime={new Date(post.event?.endTime)} />
              {canRenderEventCard && (
                <EventCard
                  startTime={new Date(post.event?.startTime)}
                  endTime={new Date(post.event?.endTime)}
                  type={post.event?.urlType}
                  liveId={post.id}
                  started={started}
                  onComplete={() => setStarted(true)}
                />
              )}
            </div>
          )}

          {canAccess && releaseAt > new Date() && <TimeToReleaseCard timeToRelease={releaseAt} />}

          <div className="p-4 space-y-4 bg-zinc-800/40 rounded-lg prose prose-invert max-w-none">
            {post.content && <div className="prose prose-invert max-w-none">{Parser(post.content)}</div>}
            <p className="text-sm text-gray-400">
              {isDraft ? 'Atualizado' : 'Publicado'}
              {dateDiff > 7 ? ' em' : ''}{' '}
              {moment(post.updatedAt ?? post.createdAt)
                .locale('pt-br')
                .format('DD MMM. YYYY')}
            </p>
          </div>
          {post.attachments && post.attachments.length > 0 && (
            <div className="space-y-4">
              <h2 className="text-xl font-semibold text-white">{t('postDetail.assets.title')}</h2>
              <PostAttachmentView assets={post.attachments} />
            </div>
          )}

          {showComments && (
            <div className="mt-8">
              <DiscussionProvider>
                <Discussion
                  discussionOwnerId={post?.product?.owner?.id}
                  type="post"
                  metadata={{
                    postId: post.id,
                  }}
                  currentUser={{
                    id: currentUserId,
                    name: currentUser?.name,
                    picture: currentUser?.picture,
                  }}
                />
              </DiscussionProvider>
            </div>
          )}
        </div>
      </main>

      <aside className="lg:w-[340px]">
        <Sidebar
          productId={post.product.id}
          post={{
            id: post.id,
            cover: post.cover,
            title: post.title,
          }}
        />
      </aside>
    </div>
  )
}
