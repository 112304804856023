import { useAuth } from '@hub-la/fe-auth'
import { Redirect, Route } from 'react-router-dom'

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useAuth()
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated === null) {
          return null
        }
        if (isAuthenticated) {
          return <Component {...props} />
        }
        return <Redirect to="/signin" />
      }}
    />
  )
}
