import { Card, CardContent, CardDescription, CardHeader, CardTitle, useIsMobile } from '@hub-la/shadcn'
import React from 'react'
import { Envs } from '../../../envs'
import HorizontalLogo from '../../assets/horizontal-logo-dark.svg'

type AuthCardProps = {
  icon?: JSX.Element
  title: React.ReactNode
  description?: React.ReactNode
  children?: React.ReactNode
  showTerms?: boolean
}

export const AuthCard = ({ icon, title, description, children, showTerms = true }: AuthCardProps) => {
  const isMobile = useIsMobile()

  return (
    <div className="flex flex-col items-center animate-fadeIn">
      <Card className={`w-full ${isMobile ? 'max-w-full' : 'max-w-[462px]'} mb-8`}>
        <CardHeader className="items-center">
          {icon ? icon : <img src={HorizontalLogo} alt="Hubla Logo" className="mb-8" />}
          <CardTitle className="text-center">{title}</CardTitle>
          {description && <CardDescription className="text-center">{description}</CardDescription>}
        </CardHeader>
        <CardContent>{children}</CardContent>
      </Card>
      {showTerms && (
        <div className={`text-center text-white ${isMobile ? 'text-sm text-foreground mx-6' : 'text-surface'}`}>
          <p>
            Ao iniciar a sessão, você aceita e concorda com nossos
            <br />
            <a href={Envs.TERMS_OF_USE} className="text-lime-400 hover:text-lime-400 underline">
              Termos de uso
            </a>{' '}
            e{' '}
            <a href={Envs.PRIVACY_POLICY} className="text-lime-400 hover:text-lime-400 underline">
              Política de privacidade
            </a>
          </p>
        </div>
      )}
      {isMobile && showTerms && (
        <div className="mt-8 w-full max-w-[462px] text-center text-sm text-foreground mx-6">
          <p>
            Este site é protegido por reCAPTCHA e se aplicam os{' '}
            <a href="https://policies.google.com/terms" className="text-lime-400 hover:text-lime-400 underline">
              Termos de uso
            </a>{' '}
            e{' '}
            <a href="https://policies.google.com/privacy" className="text-lime-400 hover:text-lime-400 underline">
              Política de privacidade
            </a>{' '}
            do Google.
          </p>
        </div>
      )}
    </div>
  )
}
