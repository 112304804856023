import { CheckCircle2, CircleAlertIcon, CircleX, MinusCircle, Undo2 } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { InvoiceStatus } from '../../domain/enums/invoice-status'

type InvoiceStatusStatsProps = {
  size?: any
  status: InvoiceStatus
}

const getStyles = (color: string) => {
  return { size: 16, className: `mr-1.5 text-${color}-600` }
}

const getIcon = (status: InvoiceStatus) => {
  switch (status) {
    case InvoiceStatus.PAID:
      return <CheckCircle2 {...getStyles('green')} />
    case InvoiceStatus.EXPIRED:
      return <CircleX {...getStyles('red')} />
    case InvoiceStatus.UNPAID:
    case InvoiceStatus.DISPUTED:
      return <CircleAlertIcon {...getStyles('orange')} />
    case InvoiceStatus.RETURNED:
      return <Undo2 {...getStyles('gray')} />
    case InvoiceStatus.CHARGEBACK:
      return <MinusCircle {...getStyles('slate')} />
    default:
      return null
  }
}

export const InvoiceStatusStats = ({ status }: InvoiceStatusStatsProps) => {
  const { t } = useTranslation()

  return (
    <div className="flex items-center">
      {getIcon(status)}
      {t(`invoiceStatus.${status}`)}
    </div>
  )
}
