import { Skeleton } from '@hub-la/shadcn'

export const Loading = () => {
  return (
    <div className="w-full">
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div className="flex justify-between p-4">
          <div>
            <Skeleton className="animate-pulse bg-gray-300 h-6 w-4/5 mb-1 rounded"></Skeleton>
            <Skeleton className="animate-pulse bg-gray-300 h-11 w-[155px] mb-1 rounded"></Skeleton>
            <Skeleton className="animate-pulse bg-gray-300 h-6 w-[250px] rounded"></Skeleton>
          </div>
        </div>
      </div>
    </div>
  )
}
