import { Skeleton, useIsMobile } from '@hub-la/shadcn'

export const Loading = () => {
  const isMobile = useIsMobile()

  return (
    <div className="w-full h-auto md:h-[552px]">
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div className="p-4">
          <div className="flex justify-between mb-5">
            <div>
              <Skeleton className="animate-pulse bg-gray-300 h-6 w-4/5 mb-1 rounded"></Skeleton>
              <Skeleton className="animate-pulse bg-gray-300 h-11 w-[155px] mb-1 rounded"></Skeleton>
              <Skeleton className="animate-pulse bg-gray-300 h-6 w-[250px] rounded"></Skeleton>
            </div>
          </div>

          {!isMobile && (
            <div>
              <Skeleton className="animate-pulse bg-gray-300 h-[290px] w-full rounded"></Skeleton>
              <Skeleton className="flex items-center mt-5 ml-7 gap-3"></Skeleton>
              <Skeleton className="animate-pulse bg-gray-300 h-5 w-[50px] rounded"></Skeleton>
              <Skeleton className="animate-pulse bg-gray-300 h-5 w-[75px] rounded"></Skeleton>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
