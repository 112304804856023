import {
  cn,
  Sheet,
  SheetContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePagination,
  TableRow,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  useIsMobile,
} from '@hub-la/shadcn'
import isEmpty from 'lodash/isEmpty'
import times from 'lodash/times'
import { ChevronDown, ChevronUp, Info, Tag } from 'lucide-react'
import moment from 'moment'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Coupon } from '../../../domain/dtos/coupon'
import { OrderDirection } from '../../../domain/enums/order-direction'
import { CouponStatusStats } from '../../components/coupon-status-stats'
import { useGetCoupons } from '../../hooks/use-get-coupons'
import { useRefetch } from '../../hooks/use-refetch'
import { CouponDetail } from '../coupon-detail/coupon-detail'
import { EditMenu } from './edit-menu'
import { TableLoading } from './table-loading'

interface CouponsTableProps {
  filters: any
  setFilters: (filters: any) => void
  offers: any
  isLoading: boolean
}

export const CouponsTable = ({ filters, setFilters, offers, isLoading }: CouponsTableProps) => {
  const [openCouponDetail, setOpenCouponDetail] = useState<string>()
  const [orderDirection, setOrderDirection] = useState<OrderDirection>(OrderDirection.DESC)
  const [orderBy, setOrderBy] = useState<string>('createdAt')

  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const drawerPosition = isMobile ? 'bottom' : 'right'

  const { search, offerIds, page, pageSize, statuses } = filters
  const {
    data = { coupons: [] as Coupon[], count: 0, page, pageSize, lastPage: 1 },
    isFetching,
    refetch,
  } = useGetCoupons(
    {
      search,
      offers,
      offerIds,
      page,
      statuses,
      pageSize: parseInt(pageSize, 10),
      orderBy,
      orderDirection,
    },
    isLoading,
  )

  useRefetch({ offers, ...filters }, refetch)

  const handleRequestSort = useCallback(
    (property: string) => {
      const isAsc = orderBy === property && orderDirection === OrderDirection.ASC
      setOrderDirection(isAsc ? OrderDirection.DESC : OrderDirection.ASC)
      setOrderBy(property)
    },
    [orderDirection, orderBy],
  )

  const isRowsEmpty = isEmpty(data?.coupons)
  const canRenderEmptyState = !isFetching && !isLoading && isRowsEmpty

  return (
    <div className="flex flex-col gap-4">
      <div className="rounded-md border bg-white">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>
                <div className="flex items-center">
                  Código
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild className="cursor-pointer">
                        <Info className="h-3 w-3 ml-1" />
                      </TooltipTrigger>

                      <TooltipContent>
                        <p>Código utilizado para o cupom.</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </TableHead>

              <TableHead>
                <div className="flex items-center">
                  Status
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild className="cursor-pointer">
                        <Info className="h-3 w-3 ml-1" />
                      </TooltipTrigger>

                      <TooltipContent>
                        <p>Status atual do cupom.</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </TableHead>

              <TableHead>
                <div className="flex items-center">
                  Utilização
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild className="cursor-pointer">
                        <Info className="h-3 w-3 ml-1" />
                      </TooltipTrigger>

                      <TooltipContent>
                        <p>Quantidade de cupons utilizados.</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </TableHead>

              <TableHead className="cursor-pointer" onClick={() => handleRequestSort('discountPercent')}>
                <div className="flex items-center">
                  Desconto
                  {orderBy === 'discountPercent' &&
                    (orderDirection === OrderDirection.ASC ? (
                      <ChevronUp className="ml-2 h-4 w-4" />
                    ) : (
                      <ChevronDown className="ml-2 h-4 w-4" />
                    ))}
                </div>
              </TableHead>

              <TableHead className="cursor-pointer" onClick={() => handleRequestSort('createdAt')}>
                <div className="flex items-center">
                  Data da criação
                  {orderBy === 'createdAt' &&
                    (orderDirection === OrderDirection.ASC ? (
                      <ChevronUp className="ml-2 h-4 w-4" />
                    ) : (
                      <ChevronDown className="ml-2 h-4 w-4" />
                    ))}
                </div>
              </TableHead>

              <TableHead className="cursor-pointer" onClick={() => handleRequestSort('validUntil')}>
                <div className="flex items-center">
                  Expira em
                  {orderBy === 'validUntil' &&
                    (orderDirection === OrderDirection.ASC ? (
                      <ChevronUp className="ml-2 h-4 w-4" />
                    ) : (
                      <ChevronDown className="ml-2 h-4 w-4" />
                    ))}
                </div>
              </TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {(isFetching && isRowsEmpty) || isLoading
              ? times(pageSize, (i) => <TableLoading key={`skeleton-row-${i}`} />)
              : data?.coupons.map((coupon) => {
                  const {
                    validUntil,
                    state,
                    id,
                    code,
                    createdAt,
                    usagesCount,
                    discountPercent,
                    maxAllowedTotalUsages,
                  } = coupon
                  return (
                    <TableRow key={id} className="cursor-pointer" onClick={() => setOpenCouponDetail(id)}>
                      <TableCell className="py-2">
                        <div className="flex items-center">
                          <Tag className="w-4 h-4 mr-2 text-muted-foreground" />
                          {code}
                        </div>
                      </TableCell>

                      <TableCell className="py-2">
                        <CouponStatusStats status={state} />
                      </TableCell>

                      <TableCell className="py-2">
                        {usagesCount}/{maxAllowedTotalUsages ?? '∞'}
                      </TableCell>

                      <TableCell className="py-2">{discountPercent}%</TableCell>

                      <TableCell className="py-2">{moment(createdAt).format('D MMM. YYYY')}</TableCell>

                      <TableCell className="py-2">
                        <div className="flex justify-between items-center">
                          <span>{validUntil ? moment(validUntil).format('D MMM. YYYY') : '-'}</span>
                          <div onClick={(e) => e.stopPropagation()}>
                            <EditMenu id={id} code={code} state={state} offers={offers} />
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  )
                })}
          </TableBody>
        </Table>

        {canRenderEmptyState && (
          <div className="text-center py-4">
            <p>{t('empty')}</p>
          </div>
        )}
      </div>

      {!isRowsEmpty && (
        <TablePagination
          page={page}
          setPage={(page) => setFilters({ ...filters, page })}
          lastPage={Math.ceil(data.count / pageSize)}
          pageSize={pageSize}
          setPageSize={(pageSize) => setFilters({ ...filters, pageSize })}
        />
      )}

      <Sheet open={!!openCouponDetail} onOpenChange={() => setOpenCouponDetail(undefined)}>
        <SheetContent
          side={drawerPosition}
          className={cn('overflow-y-auto', drawerPosition === 'bottom' ? 'h-[calc(100%-4rem)]' : '')}
        >
          <div>
            {openCouponDetail && (
              <CouponDetail id={openCouponDetail} onClose={() => setOpenCouponDetail(undefined)} offers={offers} />
            )}
          </div>
        </SheetContent>
      </Sheet>
    </div>
  )
}
