import { Checkbox, Label, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@hub-la/shadcn'
import { Info } from 'lucide-react'
import React from 'react'
import { Controller, FieldPath, useFormContext } from 'react-hook-form'
import { OfferSchemaValidationType } from '../validations/offer-validation'
import { OrderBumpSchemaValidationType } from '../validations/order-bump-validation'
import { UpsellSchemaValidationType } from '../validations/upsell-validation'

type SwitchToggleChildrenProps = {
  name:
    | FieldPath<OfferSchemaValidationType>
    | FieldPath<OrderBumpSchemaValidationType>
    | FieldPath<UpsellSchemaValidationType>
  label: string
  children?: React.ReactNode
  tooltip?: string
  disabled?: boolean
}

export const SwitchToggleChildren: React.FC<SwitchToggleChildrenProps> = ({
  name,
  label,
  children,
  tooltip,
  disabled,
}) => {
  const { control, watch } = useFormContext<
    OfferSchemaValidationType | OrderBumpSchemaValidationType | UpsellSchemaValidationType
  >()
  const isToggled = watch(name)

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-2">
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="flex items-center gap-2">
              <Checkbox id={name} checked={value} onCheckedChange={onChange} disabled={disabled} />
              <Label htmlFor={name} className={`text-sm ${disabled ? 'text-muted-foreground' : ''}`}>
                {label}
              </Label>
            </div>
          )}
        />
        {tooltip && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Info className="h-4 w-4 text-gray-400 cursor-help" />
              </TooltipTrigger>
              <TooltipContent>
                <p>{tooltip}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
      {Boolean(isToggled && children) && <div className="ml-0 md:ml-6">{children}</div>}
    </div>
  )
}
