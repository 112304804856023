export const pt = {
  title: 'Integrações',
  description:
    'A Hubla oferece o básico para a sua empresa prosperar. Para todo o resto, utilize nossas integrações para te ajudar a alcançar o próximo nível.',
  tabs: {
    about: 'Sobre',
    settings: 'Configurações',
    history: 'Histórico',
    authentication: 'Autenticação',
  },
  integrations: {
    webhook: {
      title: 'Webhook',
      description: 'Integração com plataformas externas',
      icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fwebhook.png?alt=media',
      about: {
        description:
          'Um webhook é um mecanismo utilizado na programação web para permitir a comunicação em tempo real entre diferentes sistemas. Ele funciona como um "gatilho" que envia uma mensagem automática para uma URL específica sempre que ocorre um evento predefinido. Essa notificação instantânea possibilita a integração entre diferentes aplicativos e serviços, permitindo que informações sejam atualizadas automaticamente e de forma eficiente. Ao configurar um webhook, você está criando uma ponte entre sua página de produto e outros sistemas, possibilitando uma interação dinâmica e personalizada',
        features: [
          'Notificações em tempo real',
          'Integração de sistemas',
          'Atualização automática de dados',
          'Automatização de processos',
          'Personalização e interação dinâmica',
        ],
        permissions: [
          'Eventos e informações de checkout',
          'Eventos e informações de vendas',
          'Eventos e informações de assinaturas',
          'Eventos de novos membros',
        ],
        docs: [
          {
            title: 'Ler documentação',
            link: 'https://hubla.gitbook.io/docs/webhooks/introducao',
          },
        ],
      },
      rule: {
        title: {
          create: 'Criar Regra',
          edit: 'Editar Regra',
        },
        columns: {
          lists: 'Listas',
          tags: 'Tags',
        },
        description:
          'Para criar uma regra, basta informar a URL do seu webhook, escolha o produto e evento correspondentes.',
        explanation: 'O que são eventos e qual escolher?',
      },
      auth: {
        title: 'Configurar ferramenta',
        description:
          'Você pode receber webhooks da Hubla contendo informações de eventos específicos (compras, assinaturas, cancelamentos e etc.)',
        token: 'Hubla Webhook Token',
        disclaimer: 'Guarde com segurança o seu token de autenticação.',
        docs: 'Documentos & Guia',
        helpLink: 'Como configurar um webhook',
      },
    },
    zapier: {
      title: 'Zapier',
      description: 'Automação de fluxos de trabalho.',
      icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fzapier.png?alt=media',
      about: {
        description:
          'O Zapier é um produto que permite aos usuários finais integrar as principais ferramentas que usam no dia a dia para automatizar fluxos de trabalho.',
        link: 'https://zapier.com/',
        features: [
          'Construa fluxos de trabalho automatizados em minutos.',
          'Conecte com as principais ferramentas do mercado.',
        ],
        permissions: [],
        docs: [
          {
            title: 'Ler documentação',
            link: 'https://hubla.notion.site/Webhook-Events-a41d890d1ce746499563cb37ee9f60d8',
          },
        ],
      },
    },
    'google-tag-manager': {
      title: 'Google Tag Manager',
      description: 'Automatize suas de tags de acompanhamento.',
      icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fgoogle_tag_manager.png?alt=media',
      about: {
        description:
          'Atualize de forma simples e rápida códigos de acompanhamento e fragmentos de códigos relacionados (conhecidos como tags) no seu site ou app.',
        link: 'https://marketingplatform.google.com/intl/pt-BR_br/about/tag-manager/',
        features: ['Concentre suas tags em um só lugar', 'Crie modelos personalizados', 'Marcação no lado do servidor'],
        permissions: [],
      },
    },
    'meta-pixel': {
      title: 'Meta Pixel',
      description: 'Ferramenta para mensurar, otimizar e criar suas campanhas de anúncios na Meta.',
      icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fmeta_pixel.png?alt=media',
      about: {
        description:
          'O Pixel da Meta é um trecho de código no seu site que pode ajudar você a entender melhor a eficácia da sua publicidade e as ações realizadas pelas pessoas no seu site, como visitar uma página ou adicionar um item ao carrinho. Também será possível ver quando os clientes realizaram uma ação depois de verem seu anúncio no Facebook e no Instagram, o que é útil para o redirecionamento. Usar a API de Conversões junto com o pixel cria uma conexão mais confiável, que ajuda o sistema de veiculação a reduzir o custo.',
        link: 'https://pt-br.facebook.com/business/tools/meta-pixel',
        features: [
          'Melhore a perfomance das suas campanhas',
          'Expanda sua audiência com assertividade',
          'Gere mais vendas',
        ],
        permissions: [
          'Eventos e informações de checkout',
          'Eventos e informações de vendas',
          'Eventos e informações de assinaturas',
        ],
      },
    },
    'active-campaign': {
      title: 'ActiveCampaign',
      description: 'E-mail marketing, CRM e automação.',
      icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2FRectangle%202.png?alt=media',
      about: {
        description:
          'Organize os leads de seus produtos, integrando o ActiveCampaign com sua conta Hubla. Configure uma série de boas-vindas. Encontre seus contatos mais engajados. Obtenha informações de outras plataformas. Automatizar o marketing que ninguém tem tempo para fazer.',
        link: 'https://www.activecampaign.com/',
        features: [
          'Automatize o seu follow-up por e-mail',
          'Encontre seus contatos mais engajados',
          'Integre com outros apps',
          'Automação simples e fácil de configurar',
        ],
        permissions: ['Eventos e informações de checkout', 'Eventos e informações de assinaturas'],
        docs: [
          {
            title: 'Ler documentação',
            link: 'https://help.hub.la/hc/pt-br/hubla-active-campaign',
          },
        ],
      },
      create: {
        title: 'Integrar ActiveCampaign',
        description: 'Integre sua conta do ActiveCampaign com a Hubla e crie automações com nossos eventos.',
      },
      rule: {
        title: {
          create: 'Criar Regra',
          edit: 'Editar Regra',
        },
        columns: {
          lists: 'Listas',
          tags: 'Tags',
        },
        description:
          'Configure a integração para enviar automaticamente informações para o Active Campaign com base em eventos selecionados.',
        explanation: 'Onde encontrar as tags e listas no ActiveCampaign?',
      },
      disable: {
        title: 'Desativar ActiveCampaign',
        description: 'Integre sua conta do ActiveCampaign com a Hubla e crie automações com nossos eventos.',
      },
    },
    enotas: {
      title: 'eNotas',
      description: 'Emissão de notas fiscais.',
      icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fenotas.png?alt=media',
      about: {
        description:
          'Emissão automática de notas fiscais para produtores e co-produtores, oferecendo uma maneira eficiente e distribuída de gerar notas fiscais para suas transações. Ao ativar a integração com o eNotas na nossa plataforma, os produtores e co-produtores podem desfrutar de uma experiência totalmente automatizada na emissão de notas fiscais. A partir do momento em que ambas as partes possuem suas integrações ativas, o processo de emissão é desencadeado de forma automática e de maneira distribuída entre o produtor e seus co-produtores, garantindo uma divisão justa e transparente das responsabilidades.',
        link: 'https://enotas.com.br/',
        features: [
          'Escolha qual produto emitir suas notas fiscais.',
          'Encaminhe aos clientes, automaticamente.',
          'Visualize e gerencie suas notas fiscais.',
        ],
        permissions: [],
        docs: [
          {
            title: 'Ler documentação',
            link: 'https://hubla.gitbook.io/docs/integracoes/enotas',
          },
        ],
      },
      create: {
        title: 'Integrar eNotas',
        description: 'Integrar sua conta do eNotas com a Hubla emite suas NFs automáticamente.',
      },
      disable: {
        title: 'Desativar eNotas',
        description: 'Ao desativar a integração com o eNotas a Hubla não irá mais emitir ou cancelar suas NFs.',
      },
      rule: {
        title: {
          create: 'Criar Regra',
          edit: 'Editar Regra',
        },
        description: 'Para criar uma regra basta selecionar os seus produtos',
      },
    },
    'hubla-pixel': {
      title: 'Hubla Pixel',
      description: 'Análise e otimize suas campanhas de marketing.',
      icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fhubla-pixel.png?alt=media',
      about: {
        description:
          'A Integração Pixel Hubla oferece uma poderosa ferramenta para criadores de conteúdo que desejam aprimorar suas estratégias de marketing e entender melhor o comportamento dos visitantes em suas páginas de venda que estão fora da Hubla. Com esta funcionalidade, você pode rastrear informações valiosas sobre os usuários que acessam suas páginas e aqueles que interagem clicando no botão de compra.',
        features: [
          'Análise de campanhas de marketing',
          'Entenda seu funil de vendas',
          'Aprimore sua segmentação de públicos',
          'Otimização contínua',
        ],
        permissions: [],
      },
      rule: {
        title: {
          create: 'Criar Pixel',
          edit: 'Editar Pixel',
        },
        columns: {
          lists: 'Listas',
          tags: 'Tags',
        },
        description:
          'Para criar um pixel, basta fornecer um nome e especificar os produtos que serão associados a essa regra.',
      },
    },
    'sell-flux': {
      title: 'SellFlux',
      description: 'Automação, marketing e CRM.',
      icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fsell-flux.png?alt=media',
      about: {
        description: 'Otimize suas estratégias de conversão integrando com SellFlux',
        link: 'https://sellflux.com/',
        features: [
          'Automatize o seu follow-up por e-mail',
          'Encontre seus contatos mais engajados',
          'Integre com outros apps',
          'Automação simples e fácil de configurar',
        ],
        permissions: [
          'Eventos e informações de vendas',
          'Eventos e informações de assinaturas',
          'Eventos de novos membros',
        ],
      },
      rule: {
        title: {
          create: 'Criar Regra',
          edit: 'Editar Regra',
        },
        columns: {
          lists: 'Listas',
          tags: 'Tags',
        },
        description:
          'Para criar uma regra, basta fornecer um nome junto com a URL da integração e especificar os produtos que serão associados a essa regra',
      },
    },
    'g-digital': {
      title: 'GDigital',
      description: 'E-mail marketing, CRM e automação.',
      icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fg-digital.png?alt=media',
      about: {
        description:
          'Organize os leads de seus produtos, integrando o G Digital com sua conta Hubla. Configure uma série de boas-vindas. Encontre seus contatos mais engajados. Obtenha informações de outras plataformas. Automatizar o marketing que ninguém tem tempo para fazer.',
        link: 'https://www.gdigital.com.br/',
        features: [
          'Automatize o seu follow-up por e-mail',
          'Encontre seus contatos mais engajados',
          'Integre com outros apps',
          'Automação simples e fácil de configurar',
        ],
        permissions: ['Eventos e informações de checkout', 'Eventos e informações de assinaturas'],
      },
      create: {
        title: 'Integrar G Digital',
        description: 'Integre sua conta do G Digital com a Hubla e crie automações com nossos eventos.',
      },
      rule: {
        title: {
          create: 'Criar Regra',
          edit: 'Editar Regra',
        },
        columns: {
          lists: 'Listas',
          tags: 'Tags',
        },
        description:
          'Para criar uma regra basta selecionar seu produto, o evento e a tag a da lista criada no G Digital',
        explanation: 'Onde encontrar as tags e listas no G Digital?',
      },
      disable: {
        title: 'Desativar G Digital',
        description: 'Integre sua conta do G Digital com a Hubla e crie automações com nossos eventos.',
      },
    },
    zapcerto: {
      title: 'ZapCerto',
      description: 'Disparos em Massa, Automações, Marketing Conversacional.',
      icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fzapcerto.png?alt=media',
      about: {
        description:
          'Dispare milhares de mensagens para toda a sua base de audiência comunicando os eventos mais importantes do seu lançamento. Whatsapp Marketing, SMS com link, SMS Flash, Ligações como Torpedo de Voz, e URA integrada.',
        link: 'https://zapcerto.com.br/',
        features: [
          'Envie milhares de mensagens instantaneamente.',
          'Leve uma experiência de comunicação para a sua audiência.',
          'Ligue para toda a sua base de forma automática.',
          'Recuperação de Vendas em tempo real ao lançamento.',
          'Plataforma intuitiva.',
        ],
        permissions: [
          'Eventos e informações de vendas',
          'Eventos e informações de assinaturas',
          'Eventos de novos membros',
          'Eventos e informações de checkout',
        ],
        docs: [],
      },
    },
    cademi: {
      title: 'Cademí',
      description: '-.',
      icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fcademi.png?alt=media',
      about: {
        description: '-',
        link: 'https://cademi.com.br/',
        features: ['Automação simples e fácil de configurar'],
        permissions: ['Eventos e informações de checkout', 'Eventos e informações de assinaturas'],
      },
      create: {
        title: 'Integrar Cademí',
        description: 'Integre sua conta da Cademí com a Hubla e crie automações com nossos eventos.',
      },
      rule: {
        title: {
          create: 'Criar Regra',
          edit: 'Editar Regra',
        },
        columns: {
          lists: 'Listas',
          tags: 'Tags',
        },
        description: 'Para criar uma regra basta selecionar seu produto e evento.',
      },
      disable: {
        title: 'Desativar Cademí',
        description: 'Integre sua conta do Cademí com a Hubla e crie automações com nossos eventos.',
      },
    },
    memberkit: {
      title: 'MemberKit',
      description: 'Área de membros',
      icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fmemberkit.jpg?alt=media',
      about: {
        description: 'Área de membros com turmas, área de conversas e gamificação.',
        link: 'https://memberkit.com.br/',
        features: ['Cursos', 'Turmas', 'Área de interação entre membros', 'Gamificação.'],
        permissions: [
          'Eventos e informações de checkout',
          'Eventos e informações de assinaturas',
          'Eventos e informações de acesso de membros',
        ],
      },
      create: {
        title: 'Integrar MemberKit',
        description:
          'Integre sua conta da MemberKit com a Hubla e entregue conteúdo a partir de suas vendas feitas aqui.',
      },
      rule: {
        title: {
          create: 'Criar Regra',
          edit: 'Editar Regra',
        },
        columns: {
          lists: 'Turmas',
          tags: 'Nível de assinatura',
        },
        description: 'Para criar uma regra basta selecionar seu produto e evento.',
      },
      disable: {
        title: 'Desativar MemberKit',
        description: 'Remover regra de integração da MemberKit com a Hubla.',
      },
    },
    leadlovers: {
      title: 'LeadLovers',
      description: 'E-mail marketing, CRM e automação.',
      icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Flead_lovers.png?alt=media',
      about: {
        description:
          'O leadlovers é uma plataforma de automação de marketing digital criada para centralizar as principais tarefas de marketing das empresas.',
        link: 'https://leadlovers.app/',
        features: [
          'Automatize o seu follow-up por e-mail',
          'Encontre seus contatos mais engajados',
          'Integre com outros apps',
          'Automação simples e fácil de configurar',
        ],
        permissions: ['Eventos de novos membros'],
      },
      create: {
        title: 'Integrar LeadLovers',
        description: 'Integre sua conta do LeadLovers com a Hubla e crie automações com nossos eventos.',
      },
      rule: {
        title: {
          create: 'Criar Regra',
          edit: 'Editar Regra',
        },
        columns: {
          lists: 'Máquinas',
          tags: 'Tags',
        },
        description:
          'Para criar uma regra basta selecionar seu produto, o evento e a tag a da lista criada no LeadLovers',
        explanation: 'Onde encontrar as tags e listas no LeadLovers?',
      },
      disable: {
        title: 'Desativar LeadLovers',
        description: 'Integre sua conta do LeadLovers com a Hubla e crie automações com nossos eventos.',
      },
    },
    themembers: {
      title: 'TheMembers',
      description: 'Área de membros',
      icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fthemembers.png?alt=media',
      about: {
        description: 'Área de membros com turmas, comunidade, gamificação e app.',
        link: 'https://themembers.com.br/',
        features: ['Cursos.', 'Turmas.', 'Comunidade.', 'Área de interação entre membros.', 'Gamificação.'],
        permissions: [
          'Eventos e informações de checkout',
          'Eventos e informações de assinaturas',
          'Eventos e informações de acesso de membros',
        ],
      },
      create: {
        title: 'Integrar TheMembers',
        description:
          'Integre sua conta da TheMembers com a Hubla e entregue conteúdo a partir de suas vendas feitas aqui.',
      },
      rule: {
        title: {
          create: 'Criar Regra',
          edit: 'Editar Regra',
        },
        columns: {
          lists: 'Turmas',
        },
        description: 'Para criar uma regra basta selecionar seu produto e evento.',
      },
      disable: {
        title: 'Desativar TheMembers',
        description: 'Remover regra de integração da TheMembers com a Hubla.',
      },
    },
    hubspot: {
      title: 'HubSpot',
      description: 'E-mail marketing, CRM e automação.',
      icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fhubspot.png?alt=media',
      about: {
        description:
          'Organize os leads de seus produtos, integrando o HubSpot com sua conta Hubla. Encontre seus contatos mais engajados. Obtenha informações de outras plataformas. Automatizar o marketing que ninguém tem tempo para fazer.',
        link: 'https://www.hubspot.com/',
        features: ['Integre com outros apps', 'Automação simples e fácil de configurar'],
        permissions: ['Eventos e informações de checkout', 'Eventos e informações de assinaturas'],
      },
      create: {
        title: 'Integrar HubSpot',
        description:
          'Integre sua conta da HubSpot com a Hubla e entregue conteúdo a partir de suas vendas feitas aqui.',
      },
      rule: {
        title: {
          create: 'Criar Regra',
          edit: 'Editar Regra',
        },
        columns: {
          lists: 'Listas',
          tags: 'Tags',
        },
        description: 'Para criar uma regra basta selecionar seu produto e evento.',
      },
      disable: {
        title: 'Desativar HubSpot',
        description: 'Remover regra de integração da HubSpot com a Hubla.',
      },
    },
    omie: {
      title: 'Omie',
      description: 'Emissão de notas fiscais.',
      icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fomie.png?alt=media',
      about: {
        description:
          'Emissão automática de notas fiscais para produtores e co-produtores, oferecendo uma maneira eficiente e distribuída de gerar notas fiscais para suas transações. Ao ativar a integração com o eNotas na nossa plataforma, os produtores e co-produtores podem desfrutar de uma experiência totalmente automatizada na emissão de notas fiscais. A partir do momento em que ambas as partes possuem suas integrações ativas, o processo de emissão é desencadeado de forma automática e de maneira distribuída entre o produtor e seus co-produtores, garantindo uma divisão justa e transparente das responsabilidades.',
        link: 'https://omie.com.br/',
        features: [
          'Escolha qual produto emitir suas notas fiscais.',
          'Encaminhe aos clientes, automaticamente.',
          'Visualize e gerencie suas notas fiscais.',
        ],
        permissions: [],
        docs: [],
      },
      create: {
        title: 'Integrar Omie',
        description: 'Integrar sua conta do Omie com a Hubla emite suas NFs automáticamente.',
      },
      disable: {
        title: 'Desativar Omie',
        description: 'Ao desativar a integração com o Omie a Hubla não irá mais emitir ou cancelar suas NFs.',
      },
      rule: {
        title: {
          create: 'Criar Regra',
          edit: 'Editar Regra',
        },
        description: 'Para criar uma regra basta selecionar os seus produtos',
      },
    },
    rdstation: {
      title: 'RDStation',
      description: 'E-mail marketing, CRM e automação.',
      icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Frdstation.png?alt=media',
      about: {
        description:
          'Organize os leads de seus produtos, integrando o RDStation com sua conta Hubla. Encontre seus contatos mais engajados. Obtenha informações de outras plataformas. Automatizar o marketing que ninguém tem tempo para fazer.',
        link: 'https://www.rdstation.com/',
        features: [
          'Automatize o seu follow-up por e-mail',
          'Encontre seus contatos mais engajados',
          'Integre com outros apps',
          'Automação simples e fácil de configurar',
        ],
        permissions: ['Eventos e informações de checkout', 'Eventos e informações de assinaturas'],
      },
      rule: {
        title: {
          create: 'Criar Regra',
          edit: 'Editar Regra',
        },
        columns: {
          lists: 'Ações',
          tags: 'Tags',
        },
        description:
          'Configure a integração para enviar automaticamente informações para o RDStation com base em eventos selecionados.',
        explanation: 'Onde encontrar as tags e listas no RDStation?',
      },
      disable: {
        title: 'Desativar RDStation',
        description: 'Integre sua conta do RDStation com a Hubla e crie automações com nossos eventos.',
      },
    },
  },
  rules: {
    createModal: {
      title: {
        create: 'Criar Regra',
        edit: 'Editar Regra',
      },
      noOptions: 'Não há opções disponíveis',
      loading: 'Carregando...',
    },
    detailModal: {
      title: 'Detalhes da regra',
    },
    deleteModal: {
      title: 'Remover regra',
      description: 'Ao deletar, sua regra não estará mais ativa e será excluída permanentemente.',
    },
    table: {
      columns: {
        name: 'Nome',
        url: 'URL',
        products: 'Produtos',
        offers: 'Ofertas',
        events: 'Eventos',
        lists: 'Listas',
        tags: 'Tags',
        status: 'Status',
        actions: 'Ações',
        createdAt: 'Data de criação',
      },
      results: '{{ total }} resultados',
      pageSize: '{{ total }} por página',
      empty: {
        title: 'Crie uma regra para começar',
        subtitle: 'Ainda não foi criada nenhuma regra de envio de eventos,.',
      },
    },
    events: {
      newUser: 'Novo usuário',
      newSale: 'Nova venda',
      canceledSubscription: 'Assinatura cancelada',
      canceledSale: 'Venda cancelada',
      pendingSale: 'Venda pendente',
      inProtestSale: 'Venda em protesto',
      refundRequested: 'Solicitação reembolso',
      abandonedCheckout: 'Carrinho abandonado',
      expiredSale: 'Venda expirada',
      refundedSale: 'Venda reembolsada',
      refusedSale: 'Venda recusada',
      chargebackSale: 'Changeback',
      externalPageViewed: 'Visualização de página externa (Pixel)',
      externalPageClicked: 'Click em uma página externa (Pixel)',
      creatorPageViewed: 'Visualização da página de perfil',
      productPageViewed: 'Visualização da página do produto',
      checkoutPaymentViewed: 'Visualização da página de checkout',
      checkoutPurchaseSubmitted: 'Tentativa de compra',
      checkoutPurchaseFailed: 'Falha tentativa de compra',
      checkoutPurchaseSuccessful: 'Tentativa de compra realizada',
      checkoutPurchaseRejected: 'Tentativa de compra rejeitada',
      customerMemberAdded: 'Acesso concedido (v2)',
      customerMemberRemoved: 'Acesso removido (v2)',
      subscriptionCreated: 'Assinatura criada (v2)',
      subscriptionActivated: 'Assinatura ativa (v2)',
      subscriptionDeactivated: 'Assinatura desativada (v2)',
      subscriptionExpiring: 'Assinatura expirada (v2)',
      subscriptionRenewEnabled: 'Assinatura: Renovação ativada (v2)',
      subscriptionRenewDisabled: 'Assinatura: Renovação desativada (v2)',
      invoiceCreated: 'Fatura criada (v2)',
      invoiceStatusUpdated: 'Status da fatura atualizado (v2)',
      invoicePaymentSucceeded: 'Pagamento da fatura realizado (v2)',
      invoicePaymentFailed: 'Pagamento da fatura falhou (v2)',
      invoiceExpired: 'Fatura expirada (v2)',
      invoiceRefunded: 'Fatura reembolsada (v2)',
      refundRequestCreated: 'Solicitação de Reembolso criada',
      refundRequestAccepted: 'Solicitação de Reembolso aceita',
      refundRequestCanceled: 'Solicitação de Reembolso cancelada',
      refundRequestRejected: 'Solicitação de Reembolso rejeitada',
    },
    state: {
      active: 'Ativa',
    },
    buttons: {
      edit: 'Editar regra',
      delete: 'Deletar regra',
      create: 'Criar regra',
    },
  },
  histories: {
    table: {
      columns: {
        date: 'Data',
        event: 'Evento',
        product: 'Produto',
        offer: 'Oferta',
        email: 'Email',
        status: 'Status',
        actions: 'Ações',
        url: 'Endpoint',
        headers: 'Cabeçalho',
        payload: 'Corpo da requisição',
        updatedAt: 'Data de envio',
        retry: 'Número de retentativas',
        responseStatus: 'Status code de retorno',
      },
    },
    detailModal: {
      title: 'Detalhes do Evento',
      requestDetail: 'Detalhes da requisição',
      responseDetail: 'Detalhes da resposta',
    },
    sandboxModal: {
      title: 'Testar configuração',
      events: 'Eventos do teste',
      disclaimer: 'Enviaremos dados fictícios de teste simulando comportamentos dos eventos de sua configuração.',
    },
    retryModal: {
      title: 'Reprocessar eventos',
      description:
        'Ao reprocessar este evento ele irá ser enviado fora de sua ordem, uma vez que outros eventos já foram processados. Deseja continuar?',
    },
  },
  selectProductModal: {
    description: 'Selecione um produto para configurar...',
    cancelButton: 'Cancelar',
    confirmButton: 'Confirmar',
  },
  aboutTab: {
    sections: {
      about: 'Sobre',
      features: 'Funções',
      permissions: 'Permissões',
      docs: 'Documentos & Guias',
    },
    sendEvents: '{{provider}} enviará ou receberá as seguintes informações:',
    readDocs: 'Ler documentação',
    seeMore: 'Saiba mais',
  },
  empty: {
    title: 'Crie uma regra para começar',
    description: 'Ainda não foi criada nenhuma regra de envio de eventos,.',
  },
  errors: {
    account: {
      title: 'Integração com problemas',
      message: 'Houve um problema ao processar uma regra, favor verifique sua integração: {{message}}',
    },
    alreadyExists: 'Já existe uma integração {{provider}}.',
    general: 'Erro não identificado, entre em contato com o suporte.',
    notImplemented: 'Integração sem implementação.',
    noResult: 'Integracão não encontrada.',
    unknownIntegration: 'Houve um problema ao validar suas credenciais com a {{provider}}, verifique o campo acima.',
    unauthorizedIntegration: 'Conexão com a integracão não authorizada, verifique as credenciais.',
    invalidField: {
      url: 'Digite o campo API URL corretamente!',
      apiKey: 'Digite o campo API KEY corretamente!',
      secretToken: 'Digite o campo Token corretamente!',
    },
    validation: {
      url: {
        mustBeUrl: 'O campo deve ser uma URL.',
        required: 'Campo obrigatório.',
      },
      apiKey: {
        required: 'Campo obrigatório.',
      },
      secretToken: {
        required: 'Campo obrigatório.',
      },
    },
  },
  buttons: {
    integrate: 'Integrar',
    edit: 'Editar',
    delete: 'Deletar',
    disable: 'Desativar',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    settings: 'Configurar',
    authentication: 'Autenticação',
    retry: 'Reprocessar',
    sandbox: 'Testar configuração',
    addRule: 'Adicionar regra',
    syncItems: 'Atualizar resultados',
    sendEvents: 'Enviar eventos',
    activeIntegration: 'Ativar Integração',
    wait: 'Em breve',
  },
}
