import { Input } from '@hub-la/shadcn'
import React, { forwardRef } from 'react'

interface PhoneFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  helperText?: React.ReactNode
  error?: boolean
}

export const PhoneField = forwardRef<HTMLInputElement, PhoneFieldProps>(
  ({ label, helperText, error, ...props }, ref) => {
    return (
      <div className="w-full">
        {label && (
          <label htmlFor={props.id || props.name} className="block text-sm font-medium text-gray-700 mb-1">
            {label}
          </label>
        )}
        <Input
          {...props}
          ref={ref}
          name="phoneNumber"
          className={`${error ? 'border-red-500' : ''}`}
          data-testid="phoneNumber"
        />
        {helperText && <p className={`mt-1 text-sm ${error ? 'text-red-500' : 'text-gray-500'}`}>{helperText}</p>}
      </div>
    )
  },
)

PhoneField.displayName = 'PhoneField'
