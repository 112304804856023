import { Banknote, RefreshCwIcon } from 'lucide-react'
import { useCallback } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { paymentMethodsFieldOptions } from '../../../../../domain/constants/payment-methods-field-options'
import { PriceType } from '../../../../../domain/enums/price-type.enum'
import { OfferSection } from '../../../../components/offer-section'
import { OfferSchemaValidationType } from '../../../../validations/offer-validation'
import { Options } from './components/options'
import { PriceConfiguration } from './components/price-configuration'
import { PriceTypeSelectCard } from './components/price-type-select-card'

const PricingContent = () => {
  const { t } = useTranslation()

  const { setValue, clearErrors } = useFormContext<OfferSchemaValidationType>()

  const priceType = useWatch({
    name: 'pricing.priceType',
  })
  const isPublished = useWatch({
    name: 'isPublished',
  })
  const paymentMethods = useWatch({
    name: 'checkout.paymentMethods',
  })

  const isRecurringPrice = priceType === 'recurring'

  const handleSelectPriceType = useCallback(
    (priceType: PriceType) => {
      setValue('pricing.priceType', priceType)
      if (priceType === PriceType.ONE_TIME) {
        clearErrors('pricing.plans')
      } else {
        const paymentsAllowed = paymentMethodsFieldOptions
          .filter((paymentMethod) => paymentMethod.priceTypeAllowed.includes(priceType))
          .map((paymentMethod) => paymentMethod.name)
        if (paymentMethods) {
          // prevent clear value
          setValue(
            'checkout.paymentMethods',
            paymentMethods.filter((paymentMethod) => paymentsAllowed.includes(paymentMethod)),
          )
        }
        clearErrors('pricing.price')
        clearErrors('pricing.installments')
      }
    },
    [clearErrors, paymentMethods, setValue],
  )

  return (
    <>
      <OfferSection>
        <div className="flex flex-col md:flex-row gap-4 mb-6">
          {(!isPublished || (isPublished && !isRecurringPrice)) && (
            <PriceTypeSelectCard
              title={t('offer.pricingTab.pricing.subscriptionType.oneTime')}
              selected={!isRecurringPrice}
              onSelect={() => handleSelectPriceType(PriceType.ONE_TIME)}
              icon={<Banknote className="w-5 h-5 mb-2" />}
              disabled={isPublished}
            />
          )}
          {(!isPublished || (isPublished && isRecurringPrice)) && (
            <PriceTypeSelectCard
              title={t('offer.pricingTab.pricing.subscriptionType.recurring')}
              selected={isRecurringPrice}
              onSelect={() => handleSelectPriceType(PriceType.RECURRING)}
              icon={<RefreshCwIcon className="w-5 h-5 mb-2" />}
              disabled={isPublished}
            />
          )}
        </div>
        <div className="flex flex-col gap-4">
          {/* <CurrencySelect label={t('offer.pricingTab.pricing.currency.label')} /> */}
          <PriceConfiguration />
        </div>
      </OfferSection>
      <OfferSection title={t('offer.pricingTab.options.title')}>
        <Options />
      </OfferSection>
    </>
  )
}

export { PricingContent }
