import { CopyClipboard } from '@hub-la/fe-copy-clipboard'
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Input,
  Skeleton,
  useToast,
} from '@hub-la/shadcn'
import { ChevronLeft } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export const FreeMembersHeader = ({
  isLoading,
  product,
  resource,
  groupResource,
  onGenerateLink,
  isGeneratingLink,
}) => {
  const { t } = useTranslation()
  const [generatedLink, setGeneratedLink] = useState('')
  const { toast } = useToast()

  const handleGenerateLink = async () => {
    const link = await onGenerateLink()
    setGeneratedLink(link)
  }

  return (
    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6">
      <div className="flex items-center mb-4 sm:mb-0">
        {!isLoading && resource?.picture && product && (
          <Link to={`/edit/${product.id}/cohorts`} className="mr-4">
            <ChevronLeft className="h-6 w-6" />
          </Link>
        )}
        {isLoading ? (
          <div className="animate-pulse bg-gray-200 w-8 h-8 rounded-full mr-4" />
        ) : (
          resource?.picture && <img src={resource.picture} alt={resource.name} className="w-8 h-8 rounded-full mr-4" />
        )}
        <div>
          {isLoading ? (
            <div className="animate-pulse bg-gray-200 h-6 w-32 mb-1" />
          ) : (
            product && <h2 className="text-xl font-bold">{product.name}</h2>
          )}
          {isLoading ? (
            <div className="animate-pulse bg-gray-200 h-4 w-24" />
          ) : (
            product && <p className="text-sm text-gray-500">{product.name}</p>
          )}
        </div>
      </div>

      <Dialog>
        <DialogTrigger asChild>
          <Button
            onClick={handleGenerateLink}
            disabled={isLoading || isGeneratingLink || groupResource?.state === 'full'}
          >
            {groupResource?.state === 'full' ? t('freeMembers.resourceFull') : t('freeMembers.generateInviteLink')}
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t('freeMembers.generateLink.title')}</DialogTitle>
          </DialogHeader>
          <p>{t('freeMembers.generateLink.description')}</p>
          {isGeneratingLink ? (
            <Skeleton className="h-[40px] w-full mb-4" />
          ) : (
            <div className="flex flex-row space-x-1 mb-4 items-center">
              <Input value={generatedLink} readOnly />
              <Button
                variant="secondary"
                onClick={() => {
                  new CopyClipboard().execute(generatedLink)
                  toast({
                    title: t('share.copied'),
                    duration: 3000,
                  })
                }}
              >
                {t('freeMembers.copyLink')}
              </Button>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}
