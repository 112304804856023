import { AuthProvider, AuthRedirectProvider, SignUpPage as AuthSignUpPage } from '@hub-la/fe-auth'

export const SignUpInit = () => {
  return (
    <AuthProvider>
      <AuthRedirectProvider>
        <AuthSignUpPage />
      </AuthRedirectProvider>
    </AuthProvider>
  )
}
