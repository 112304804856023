import { useTranslation } from 'react-i18next'
import { Money } from '../../../../domain/vos/money'
import { InfoTooltip } from '../../../components/info-tooltip'
import { StatusBox } from '../../../components/status-box'
import { useGetActiveSubscriptions } from '../../../hooks/use-get-active-subscriptions'
import { useRefetch } from '../../../hooks/use-refetch'

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  useIsMobile,
} from '@hub-la/shadcn'
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from 'recharts'
import { Loading } from './loading'

export const ActiveSubscriptions = ({ setFilters, filters, offers, isLoading }) => {
  const { period, startDate, endDate, offerIds } = filters
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const { data, isFetching, refetch } = useGetActiveSubscriptions(
    { period, startDate, endDate, offers, offerIds },
    isLoading,
  )
  const isLoadingData = isFetching && data.current === 0

  useRefetch({ period, startDate, endDate, offers, offerIds }, refetch)

  if (isLoadingData || isLoading) {
    return <Loading />
  }

  const chartConfig = {
    currentRevenue: {
      label: 'Current Revenue',
      color: '#A6CF2C',
    },
    previousRevenue: {
      label: 'Previous Revenue',
      color: '#E7EED6',
    },
  }

  return (
    <div className="h-full w-full">
      <Card className="h-full">
        <CardHeader className="space-y-0">
          <CardDescription>
            <div className="text-muted-foreground flex items-center gap-2">
              {t('activeSubscriptions.title')}
              <InfoTooltip title={t('activeSubscriptions.description')} />
            </div>
          </CardDescription>
          <CardTitle className="text-4xl font-semibold tabular-nums">
            {data.current}
            <div className="flex items-start mt-1 flex-wrap">
              <StatusBox
                indicator={data.indicator}
                status={data.status}
                description={`${data.percentage} ${t('variation', {
                  previous: Money.build(data.previous).getValue(),
                })}`}
              />
            </div>
          </CardTitle>
        </CardHeader>
        <CardContent className="p-0">
          <div className="px-5">
            <ChartContainer config={chartConfig} className="w-full max-h-80">
              <LineChart
                accessibilityLayer
                margin={{
                  left: 14,
                  right: 14,
                  top: 20,
                  bottom: 20,
                }}
                data={data.datasets[0].data}
              >
                <CartesianGrid horizontal={false} stroke="#C8C9CB" strokeOpacity={0.3} />
                <YAxis hide domain={['dataMin-10', 'dataMax']} />
                <XAxis
                  dataKey="date"
                  stroke="#C8C9CB"
                  strokeOpacity={0.3}
                  tickMargin={6}
                  tickFormatter={(value) => {
                    return new Date(value).toLocaleDateString('pt-BR', {
                      weekday: 'short',
                      day: 'numeric',
                      month: 'short',
                    })
                  }}
                />
                <Line
                  dataKey="y"
                  type="monotoneX"
                  stroke="#94BF0A"
                  strokeWidth={2}
                  dot={false}
                  activeDot={{
                    r: 4,
                    strokeWidth: 2,
                    fill: '#94BF0A',
                    stroke: chartConfig.currentRevenue.color,
                  }}
                  name={chartConfig.currentRevenue.label}
                />
                <ChartTooltip
                  content={
                    <ChartTooltipContent
                      className="w-56  bg-white rounded-lg p-2 shadow-lg"
                      labelFormatter={(value) => {
                        return new Date(value).toLocaleDateString('pt-BR', {
                          weekday: 'long',
                          day: 'numeric',
                          month: 'short',
                          year: '2-digit',
                        })
                      }}
                    />
                  }
                  cursor={false}
                />
              </LineChart>
            </ChartContainer>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}
