import {
  Button,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  Separator,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@hub-la/shadcn'
import { ChevronDown, ChevronUp } from 'lucide-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { InvoiceAmountDetail } from '../../../domain/dtos/invoice-amount-detail'
import { ReceiverRole } from '../../../domain/enums/receiver-role'
import { formatCurrency } from '../../../domain/vos/format-currency'

type Props = {
  detail: InvoiceAmountDetail
  role: ReceiverRole
}

const ContentRow: React.FC<{
  title: string
  content: React.ReactNode
  fontWeight?: 'bold' | 'lighter' | 'normal'
}> = ({ title, content, fontWeight = 'normal' }) => (
  <TableRow className="border-b">
    <TableCell className={`font-${fontWeight} py-2 w-full`}>{title}</TableCell>
    <TableCell className={`font-${fontWeight} text-right py-2 min-w-48`}>{content}</TableCell>
  </TableRow>
)

const CollapsibleRow: React.FC<{
  title: string
  amount: number
  children: React.ReactNode
}> = ({ title, amount, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Collapsible open={isOpen} onOpenChange={setIsOpen}>
      <CollapsibleTrigger asChild>
        <TableRow
          className={`cursor-pointer w-full ${isOpen ? 'bg-zinc-200  hover:bg-zinc-200' : ' hover:bg-zinc-50'}`}
        >
          <TableCell className="font-semibold py-2 w-full">{title}</TableCell>
          <TableCell className="font-semibold text-right py-2 w-full">
            <div className="flex items-center justify-end">
              {formatCurrency(amount / 100)}
              {isOpen ? <ChevronUp className="w-4 h-4 ml-2" /> : <ChevronDown className="w-4 h-4 ml-2" />}
            </div>
          </TableCell>
        </TableRow>
      </CollapsibleTrigger>
      <CollapsibleContent>
        <TableBody>{children}</TableBody>
      </CollapsibleContent>
    </Collapsible>
  )
}

export const Description: React.FC<Props> = ({ detail, role }) => {
  const { t } = useTranslation()

  return (
    <div className="space-y-4">
      <h2 className="text-lg font-semibold">Detalhes do pagamento</h2>
      <Separator />
      <div className="">
        <Table>
          <TableBody className="w-full">
            <CollapsibleRow title={t('description.totalCents')} amount={detail.totalCents}>
              {detail.products.map((product, idx) => (
                <ContentRow
                  key={idx}
                  title={t('description.product', { productName: product.productName })}
                  content={<span>{formatCurrency(product.priceCents / 100)}</span>}
                />
              ))}
              {detail.discountCents ? (
                <ContentRow
                  title={t('description.discountCents', { couponCode: detail.couponCode })}
                  content={<span>- {formatCurrency(detail.discountCents / 100)}</span>}
                />
              ) : null}
              {detail.installmentFeeCents ? (
                <ContentRow
                  title={t('description.installmentFeeCents')}
                  content={<span>{formatCurrency(detail.installmentFeeCents / 100)}</span>}
                />
              ) : null}
              {detail.totalCents ? (
                <ContentRow
                  title={t('description.totalCents')}
                  content={<span>{formatCurrency(detail.totalCents / 100)}</span>}
                />
              ) : null}
            </CollapsibleRow>

            <CollapsibleRow title={t('description.platformAmountCents')} amount={detail.platformAmountCents || 0}>
              <ContentRow
                title="Taxa Hubla"
                content={
                  <Link
                    href="Saiba mais sobre nossas taxas"
                    to={'https://help.hub.la/hc/pt-br/quais-s%C3%A3o-as-taxas-da-hubla'}
                  >
                    <Button size="sm" variant="link">
                      Saiba mais sobre nossas taxas
                    </Button>
                  </Link>
                }
              />
            </CollapsibleRow>

            <CollapsibleRow title={t('description.subtotalCents')} amount={detail.subtotalCents}>
              <ContentRow
                title={t('description.totalCents')}
                content={<span>{formatCurrency(detail.totalCents / 100)}</span>}
              />
              {detail.platformAmountCents && (
                <ContentRow
                  title={t('description.platformAmountCents')}
                  content={<span>- {formatCurrency(detail.platformAmountCents / 100)}</span>}
                />
              )}
              {detail.installmentFeeCostCents ? (
                <ContentRow
                  title={t('description.installmentFeeCostCents')}
                  content={<span>- {formatCurrency(detail.installmentFeeCostCents / 100)}</span>}
                />
              ) : null}
              {detail.affiliates?.map((affiliate, idx) => (
                <ContentRow
                  key={idx}
                  title={t('description.affiliate', { name: affiliate.fullName })}
                  content={<span>- {formatCurrency(affiliate.netCents / 100)}</span>}
                />
              ))}
              {detail.subtotalCents ? (
                <ContentRow
                  title={t('description.subtotalCents')}
                  content={<span>{formatCurrency(detail.subtotalCents / 100)}</span>}
                />
              ) : null}
            </CollapsibleRow>

            <CollapsibleRow title={t('description.netCents', { role: t(`role.${role}`) })} amount={detail.netCents}>
              <ContentRow
                title={t('description.subtotalCents')}
                content={<span>{formatCurrency(detail.subtotalCents / 100)}</span>}
              />
              {detail.coProducers?.map((coProducer, idx) => (
                <ContentRow
                  key={idx}
                  title={t('description.coProducer', { name: coProducer.fullName })}
                  content={<span>- {formatCurrency(coProducer.netCents / 100)}</span>}
                />
              ))}
              {detail.netCents ? (
                <ContentRow
                  title={t('description.netCents')}
                  content={<span>{formatCurrency(detail.netCents / 100)}</span>}
                />
              ) : null}
            </CollapsibleRow>
          </TableBody>
        </Table>
      </div>
    </div>
  )
}
