import { Tabs, TabsContent, TabsList, TabsTrigger } from '@hub-la/shadcn'
import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Events } from './events/future-events'

type DashboardEventTabTypes = 'futures' | 'past'

export const DashboardEvents = memo(() => {
  const [tab, setTab] = useState<DashboardEventTabTypes>('futures')

  const history = useHistory()
  const { t } = useTranslation()

  /** @todo The #products is temporarily due to the lack of navigation on chatpay-firebase */
  /** @description This have to be set to properly go back when seeing a subscription detail */
  const onTabChange = (newTab: string) => {
    history.push(`${history.location.pathname}?tab=${newTab}#events`)
    setTab(newTab as DashboardEventTabTypes)
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const tabParam = urlParams.get('tab')
    if (tabParam) {
      setTab(tabParam as DashboardEventTabTypes)
    }
  }, [])

  return (
    <div className="space-y-4">
      <h3 className="text-2xl font-bold mb-4">Eventos</h3>

      <Tabs value={tab} onValueChange={onTabChange}>
        <TabsList>
          <TabsTrigger value="futures">{t('eventTabs.futures')}</TabsTrigger>
          <TabsTrigger value="past">{t('eventTabs.past')}</TabsTrigger>
        </TabsList>

        <div className="pt-4">
          <TabsContent value="futures">
            <Events timeScope="future" />
          </TabsContent>

          <TabsContent value="past">
            <Events timeScope="past" />
          </TabsContent>
        </div>
      </Tabs>
    </div>
  )
})
