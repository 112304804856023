import { cn } from '@hub-la/shadcn'
import React from 'react'
import { IFile } from '../../../domain/dtos'
import { Envs } from '../../../envs'
import { useUploadImage } from '../../hooks/use-upload-image'

type Props = {
  name: string
  path: string
  onChange: (file?: IFile) => void
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  onLoad?: (n: number) => void
  children?: React.ReactNode
  accept?: string
  className?: string
}

export const Uploader: React.FC<Props> = ({
  name,
  path,
  onChange,
  setLoading,
  onLoad,
  accept,
  children,
  className = '',
}) => {
  const { mutateAsync } = useUploadImage()

  const handleUpload = (file: File) => {
    setLoading(true)
    mutateAsync({ input: file, path, onLoad }).then((file) => {
      setLoading(false)
      onChange(file)
    })
  }

  return (
    <label htmlFor={name} className={cn('cursor-pointer', className)}>
      <input
        type="file"
        id={name}
        name={name}
        data-testid={name}
        accept={accept ?? Envs.ATTACHMENT_IMAGE_MIMETYPE_ALLOWED}
        className="sr-only"
        onChange={(e) => {
          if (e.target.files && e.target.files[0]) {
            handleUpload(e.target.files[0])
            e.target.value = ''
          }
        }}
      />

      {children}
    </label>
  )
}
