import { Stack, useUtility } from '@hub-la/design-system'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { alpha } from '@mui/system'

type Props = {
  fullSize?: boolean
}

const Lock = ({ fullSize = false }: Props) => {
  const { palette } = useUtility()

  return (
    <Stack
      position="absolute"
      justifyContent="center"
      alignItems="center"
      borderRadius={1}
      p={1}
      left={fullSize ? 0 : 8}
      top={fullSize ? 0 : 8}
      width="100%"
      height="100%"
      maxHeight={fullSize ? '100%' : 36}
      maxWidth={fullSize ? '100%' : 40}
      bgcolor={alpha(palette('background'), 0.5)}
      style={{ backdropFilter: 'blur(5px)' }}
    >
      <LockOutlinedIcon />
    </Stack>
  )
}

export default Lock
