import { Button, Card, CardContent, CardDescription, CardHeader, CardTitle, Skeleton } from '@hub-la/shadcn'
import { toDate } from '@hub-la/utils'
import { AlertTriangle, Download, Loader2 } from 'lucide-react'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ExportData, ExportStatus } from '../../domain/dtos/export-request'
import { useGetExportRequest } from '../hooks/use-get-export-request'

export const ExportPage: React.FC = () => {
  const { t } = useTranslation()
  const { exportId } = useParams<{ exportId: string }>()
  const { data: exportRequest, isLoading, error } = useGetExportRequest(exportId)

  const getDataText = (data?: ExportData) => {
    const dataTexts = {
      [ExportData.affiliates]: t('exportRequest.affiliates'),
      [ExportData.invoices]: t('exportRequest.invoices'),
      [ExportData.subscriptions]: t('exportRequest.subscriptions'),
      [ExportData.members]: t('exportRequest.members'),
      [ExportData.refunds]: t('exportRequest.refunds'),
      [ExportData.waitlist]: t('exportRequest.waitlist'),
      [ExportData.none]: '',
    }
    return data ? dataTexts[data] : ''
  }

  const getStatusText = (status?: ExportStatus) => {
    const statusTexts = {
      [ExportStatus.complete]: t('exportRequest.complete'),
      [ExportStatus.error]: t('exportRequest.error'),
      [ExportStatus.pending]: t('exportRequest.pending'),
    }
    return status ? statusTexts[status] : t('exportRequest.error')
  }

  const getStatusIcon = (status?: ExportStatus) => {
    if (isLoading) return <Loader2 className="mr-2 h-4 w-4 animate-spin" />
    if (status === ExportStatus.pending) return <Loader2 className="mr-2 h-4 w-4 animate-spin" />
    if (status === ExportStatus.complete) return <Download className="mr-2 h-4 w-4" />
    if (status === ExportStatus.error) return <AlertTriangle className="mr-2 h-4 w-4" />
    return null
  }

  if (error) {
    return <div className="text-red-500">{t('error.general')}</div>
  }

  return (
    <Card className="w-full max-w-md mx-auto">
      <CardHeader>
        <CardTitle className="text-2xl">
          {isLoading ? (
            <Skeleton className="h-8 w-3/4" />
          ) : (
            `${t('exportRequest.report')} ${getDataText(exportRequest?.data)}`
          )}
        </CardTitle>
        <CardDescription className="text-sm">
          {isLoading ? <Skeleton className="h-4 w-1/2" /> : `${exportRequest?.id}`}
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        <div>
          <h3 className="text-sm font-medium text-gray-500">{t('exportRequest.status')}</h3>
          {isLoading ? (
            <Skeleton className="h-10 w-full mt-1" />
          ) : (
            <Button
              variant={exportRequest?.status === ExportStatus.complete ? 'default' : 'outline'}
              disabled={exportRequest?.status !== ExportStatus.complete}
              onClick={() => window.open(exportRequest?.link)}
              className="mt-1 w-full"
            >
              {getStatusIcon(exportRequest?.status)}
              {getStatusText(exportRequest?.status)}
            </Button>
          )}
        </div>
        <div>
          <h3 className="text-sm font-medium text-gray-500">{t('exportRequest.type')}</h3>
          {isLoading ? <Skeleton className="h-6 w-1/4 mt-1" /> : <p className="mt-1">.{exportRequest?.type}</p>}
        </div>
        <div>
          <h3 className="text-sm font-medium text-gray-500">{t('exportRequest.createdAt')}</h3>
          {isLoading ? (
            <Skeleton className="h-6 w-1/2 mt-1" />
          ) : (
            <p className="mt-1">{moment(toDate(exportRequest?.createdAt) ?? '').format('D MMM. YYYY HH:mm')}</p>
          )}
        </div>
      </CardContent>
    </Card>
  )
}
