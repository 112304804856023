import { Button, Calendar, Popover, PopoverContent, PopoverTrigger } from '@hub-la/shadcn'
import { CalendarIcon } from 'lucide-react'
import moment from 'moment'
import { GetShortcuts } from '../../usecases/get-shortchuts'

interface DateRange {
  startDate?: Date
  endDate?: Date
}

interface SelectDateRangeCalendar {
  onChange: (value?: DateRange) => void
  value: DateRange
  placeholder?: string
}

const formatPlaceholder = (dateRange: DateRange, placeholder?: string) => {
  if (dateRange.startDate && dateRange.endDate) {
    const formattedStartDate = moment(dateRange.startDate).format('D MMM. YY')
    const formattedEndDate = moment(dateRange.endDate).format('D MMM. YY')
    return `${formattedStartDate} – ${formattedEndDate}`
  }

  if (dateRange.startDate) {
    const formattedStartDate = moment(dateRange.startDate).format('D MMM. YY')
    return `${formattedStartDate}`
  }

  return placeholder ?? 'Período'
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SelectDateRangeCalendar = (props: SelectDateRangeCalendar) => {
  const shortcuts = new GetShortcuts().execute()

  const handleShortcutClick = (shortcut) => {
    props.onChange({
      startDate: new Date(shortcut.startDate),
      endDate: new Date(shortcut.endDate),
    })
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline">
          <CalendarIcon className="mr-2 h-4 w-4" />
          <span>{formatPlaceholder(props.value, props.placeholder)}</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <div className="flex grid-cols-3">
          {/* Shortcuts */}
          <div className="p-2 col-span-1 flex flex-col border-r">
            {shortcuts.map((shortcut) => (
              <Button
                key={shortcut.id}
                className="justify-start"
                variant="ghost"
                size="sm"
                onClick={() => handleShortcutClick(shortcut)}
              >
                {shortcut.label}
              </Button>
            ))}
          </div>
          <div className="col-span-2">
            <Calendar
              mode="range"
              selected={{ from: props.value.startDate, to: props.value.endDate }}
              defaultMonth={props.value.startDate}
              numberOfMonths={2}
              onSelect={(value) => props.onChange({ startDate: value?.from, endDate: value?.to })}
            />
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}
