import { EventDataDto } from '@hub-la/sdk-bff-product'
import { Button, Calendar, cn, Input, Label, Popover, PopoverContent, PopoverTrigger } from '@hub-la/shadcn'
import { format } from 'date-fns'
import { useFormik } from 'formik'
import { CalendarIcon } from 'lucide-react'
import { useParams } from 'react-router-dom'
import { Section } from '../../../components/section'
import { useGetSettings } from '../../../hooks/settings/use-get-settings'
import { useSaveSettings } from '../../../hooks/settings/use-save-settings'
import { eventDateValidationSchema } from '../../../validations/event-date-validation'

type Values = Omit<EventDataDto, 'location'>

const EventDate = () => {
  const { productId } = useParams<{ productId: string }>()
  const { data, isLoading } = useGetSettings({ productId, type: 'info' })
  const { mutateAsync: saveSettings } = useSaveSettings()

  const formik = useFormik<Values>({
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: {
      startDate: data?.eventData?.startDate ?? '',
      endDate: data?.eventData?.endDate ?? '',
    },
    validationSchema: eventDateValidationSchema,
    onSubmit: async (values) => {
      await saveSettings({
        productId,
        type: 'info',
        data: {
          ...data,
          eventData: values,
        },
      })
    },
  })

  return (
    <Section formik={formik}>
      <div className="space-y-6">
        <div className="space-y-4">
          <div className="flex items-center space-x-2">
            <CalendarIcon className="text-gray-500" />

            <Label htmlFor="startDate">Início</Label>
          </div>

          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                className={cn(
                  'w-full justify-start text-left font-normal',
                  !formik.values.startDate && 'text-muted-foreground',
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {formik.values.startDate ? (
                  format(new Date(formik.values.startDate), 'PPP HH:mm')
                ) : (
                  <span>Selecione a data e hora</span>
                )}
              </Button>
            </PopoverTrigger>

            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={formik.values.startDate ? new Date(formik.values.startDate) : undefined}
                onSelect={(date) => formik.setFieldValue('startDate', date?.toISOString())}
                disabled={isLoading}
              />

              <div className="p-3 border-t">
                <Input
                  type="time"
                  value={formik.values.startDate ? format(new Date(formik.values.startDate), 'HH:mm') : ''}
                  onChange={(e) => {
                    const [hours, minutes] = e.target.value.split(':')
                    const newDate = new Date(formik.values.startDate)
                    newDate.setHours(parseInt(hours), parseInt(minutes))
                    formik.setFieldValue('startDate', newDate.toISOString())
                  }}
                  disabled={isLoading}
                />
              </div>
            </PopoverContent>
          </Popover>

          {formik.errors.startDate && <p className="text-red-500 text-sm">{formik.errors.startDate}</p>}
        </div>

        <div className="space-y-4">
          <div className="flex items-center space-x-2">
            <CalendarIcon className="text-gray-500" />

            <Label htmlFor="endDate">Fim</Label>
          </div>

          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                className={cn(
                  'w-full justify-start text-left font-normal',
                  !formik.values.endDate && 'text-muted-foreground',
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {formik.values.endDate ? (
                  format(new Date(formik.values.endDate), 'PPP HH:mm')
                ) : (
                  <span>Selecione a data e hora</span>
                )}
              </Button>
            </PopoverTrigger>

            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={formik.values.endDate ? new Date(formik.values.endDate) : undefined}
                onSelect={(date) => formik.setFieldValue('endDate', date?.toISOString())}
                disabled={isLoading}
              />
              <div className="p-3 border-t">
                <Input
                  type="time"
                  value={formik.values.endDate ? format(new Date(formik.values.endDate), 'HH:mm') : ''}
                  onChange={(e) => {
                    const [hours, minutes] = e.target.value.split(':')
                    const newDate = new Date(formik.values.endDate)
                    newDate.setHours(parseInt(hours), parseInt(minutes))
                    formik.setFieldValue('endDate', newDate.toISOString())
                  }}
                  disabled={isLoading}
                />
              </div>
            </PopoverContent>
          </Popover>

          {formik.errors.endDate && <p className="text-red-500 text-sm">{formik.errors.endDate}</p>}
        </div>
      </div>
    </Section>
  )
}

export { EventDate }
