import { Badge, Button, Separator, Sheet, SheetContent, Switch } from '@hub-la/shadcn'
import { ArrowLeft, Edit, Trash2 } from 'lucide-react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { IntegrationProvider } from '../../domain/dtos/integration-provider'
import { Rule } from '../../domain/dtos/rule'
import { GetEventName } from '../../usecases/get-event-name'
import { RuleDetailItem } from './rule-detail-item'

type RuleDetailDrawerProps = {
  isOpen: boolean
  rule: Rule
  schema: string[]
  isDeleting: boolean
  provider: IntegrationProvider
  onClose: () => void
  onEdit: () => void
  onDelete: () => void
  onSwitch: (checked: boolean) => void
}

export const RuleDetailDrawer = (props: RuleDetailDrawerProps) => {
  const { provider, isOpen, rule, schema, isDeleting, onSwitch, onClose, onEdit, onDelete } = props
  const { t } = useTranslation()

  const renderDetailItem = (rule: Rule, property: string, index: number) => {
    if (property === 'products' || property === 'offers') {
      return (
        <RuleDetailItem key={index} title={t(`rules.table.columns.${property}`)}>
          <span className="text-sm text-muted-foreground">
            {(rule[property] ?? []).map((list) => list.name).join(', ')}
          </span>
        </RuleDetailItem>
      )
    } else if (property === 'events') {
      return (
        <RuleDetailItem key={index} title={t(`rules.table.columns.${property}`)}>
          {(rule[property] ?? []).map((event) => {
            const eventName = new GetEventName().execute(event)

            if (!eventName) {
              return null
            }

            return <Badge key={event} variant="secondary" />
          })}
        </RuleDetailItem>
      )
    } else if (property === 'tags') {
      return (
        <RuleDetailItem key={index} title={t(`integrations.${provider}.rule.columns.tags`)}>
          {(rule[property] ?? []).length !== 0
            ? rule.tags.map((tag) => <Badge key={tag.name} variant="outline" />)
            : '-'}
        </RuleDetailItem>
      )
    } else if (property === 'lists') {
      return (
        <RuleDetailItem key={index} title={t(`integrations.${provider}.rule.columns.lists`)}>
          <span className="text-sm text-muted-foreground">
            {(rule[property] ?? []).map((list) => list.name).join(', ')}
          </span>
        </RuleDetailItem>
      )
    } else {
      return (
        <RuleDetailItem key={index} title={t(`rules.table.columns.${property}`)}>
          <span className="text-sm text-muted-foreground break-all">{rule[property]}</span>
        </RuleDetailItem>
      )
    }
  }

  return (
    <Sheet open={isOpen} onOpenChange={onClose}>
      <SheetContent className="p-6 max-w-lg">
        <div className="flex items-center gap-3 mb-6">
          <Button variant="ghost" size="icon" onClick={onClose}>
            <ArrowLeft className="h-4 w-4" />
          </Button>

          <h2 className="text-lg font-semibold">{t('rules.detailModal.title')}</h2>
        </div>

        <Separator className="my-6" />

        {schema.map((property, index) => {
          return renderDetailItem(rule, property, index)
        })}

        <RuleDetailItem title={t('rules.table.columns.status')}>
          <Switch id="rule-status" checked={rule.enabled} onCheckedChange={onSwitch} />
        </RuleDetailItem>

        <RuleDetailItem title={t('rules.table.columns.createdAt')}>
          <span className="text-sm text-muted-foreground">{moment(rule.createdAt).format('DD/MM/YYYY HH:mm:ss')}</span>
        </RuleDetailItem>

        {provider === IntegrationProvider.HUBLA_PIXEL && (
          <div className="mt-6">
            <pre className="text-sm bg-secondary p-4 rounded-md overflow-x-auto">
              <code>
                {`<!-- Hubla Pixel Code -->
<script type="text/javascript">
!(function(h,u,b,l,a){h.HublaAnalyticsObject=b;var hblq=(h[b]=h[b]||[]);hblq.load=function(i,r){hblq.integration=i;hblq.rule=r;var element=u.createElement("script");element.type="text/javascript";element.async=!0;element.src="https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fscripts%2Fpixel%2Fevents.js?alt=media&id="+i+"&rule="+r;var script=u.getElementsByTagName("script")[0];script.parentNode.insertBefore(element,script);};hblq.load(l,a);})(window,document,"hblq","<INTEGRATION_ID>","<RULE_ID>");
</script>
<!-- End Hubla Pixel Code -->`
                  .replace('<INTEGRATION_ID>', rule.integrationId)
                  .replace('<RULE_ID>', rule.id)}
              </code>
            </pre>
          </div>
        )}

        <Separator className="my-6" />

        <div className="flex space-x-2">
          <Button variant="outline" onClick={onEdit} className="flex-1">
            <Edit className="mr-2 h-4 w-4" />
            {t('rules.buttons.edit')}
          </Button>

          <Button variant="outline" onClick={onDelete} disabled={isDeleting} className="flex-1">
            <Trash2 className="mr-2 h-4 w-4" />
            {t('rules.buttons.delete')}
          </Button>
        </div>
      </SheetContent>
    </Sheet>
  )
}
