import { Circle, CircleDot, CirclePause, CircleSlash, CircleX } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { ReceiverStatus } from '../../../domain/enums/receiver-status'

type PartnershipStatusTagProps = {
  status: ReceiverStatus
}

const getStyles = (color: string) => {
  return { size: 16, className: `mr-1 text-${color}-700` }
}

export const PartnershipStatusTag = ({ status }: PartnershipStatusTagProps) => {
  const { t } = useTranslation()

  const getIcon = () => {
    switch (status) {
      case ReceiverStatus.INVITED:
        return <Circle size="16" className="mr-1 text-blue-700 fill-current" fillOpacity="0.2" />
      case ReceiverStatus.CANCELLATION_REQUESTED:
        return <CircleSlash {...getStyles('blue')} />
      case ReceiverStatus.REJECTED:
        return <CircleX {...getStyles('gray')} />
      case ReceiverStatus.ACCEPTED:
        return <Circle size="16" className="mr-1 text-green-600 fill-current" fillOpacity="0.2" />
      case ReceiverStatus.FINISHED:
        return <CircleDot {...getStyles('gray')} />
      case ReceiverStatus.CANCELED:
        return <CirclePause {...getStyles('gray')} />
      default:
        return <Circle {...getStyles('green')} />
    }
  }

  return (
    <div className="flex items-center gap-1">
      {getIcon()}
      {t(`partnerships.status.${status}`)}
    </div>
  )
}
