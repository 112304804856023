import { useContainer } from '@hub-la/fe-container'
import { Analytics, AnalyticsEvent } from '@hub-la/fe-core-analytics'
import { Button, Dialog, DialogContent, Input } from '@hub-la/shadcn'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Money } from '../../../domain/vos/money'
import { useSubmitWithdrawalRequest } from '../../hooks/use-submit-withdrawal-request'

const WITHDRAWAL_FEE_IN_CENTS = 300
const MIN_WITHDRAWAL_IN_CENTS = 301

export interface WithdrawalRequestModalProps {
  open: boolean
  availableBalanceInCents: number
  onClose: () => void
  onRequest: () => void
}

export const WithdrawalRequestModal: React.FC<WithdrawalRequestModalProps> = ({
  availableBalanceInCents,
  open,
  onClose,
  onRequest,
}) => {
  const { t } = useTranslation()
  const [requestedValue, setRequestedValue] = useState<number>(0)
  const defaultTextFieldHelperText = t('withdrawalRequestModal.availableBalance', {
    balance: Money.build(availableBalanceInCents / 100).getValue(),
  })
  const [balanceTextFieldHelperText, setBalanceTextFieldHelperText] = useState<string>(defaultTextFieldHelperText)
  const { mutateAsync, isLoading } = useSubmitWithdrawalRequest()
  const [error, setError] = useState<Error | undefined>()
  const container = useContainer()
  const analytics = container.get(Analytics)

  useEffect(() => {
    setBalanceTextFieldHelperText(defaultTextFieldHelperText)
  }, [defaultTextFieldHelperText])

  const handleClose = () => {
    onClose()
    setRequestedValue(0)
  }

  const handleSubmit = () => {
    if (availableBalanceInCents < requestedValue) return
    analytics.track(AnalyticsEvent.WALLET.REQUEST_WITHDRAWAL_CLICKED, { amount: requestedValue / 100 })
    const amountInCents = requestedValue - WITHDRAWAL_FEE_IN_CENTS
    mutateAsync({ amountInCents })
      .then(() => {
        handleClose()
      })
      .catch((error) => {
        setError(error)
        setBalanceTextFieldHelperText(t('withdrawalRequestModal.internalError'))
      })
      .finally(() => {
        onRequest()
      })
  }

  const handleValueChange = (value: string) => {
    const numericValue = Number(value.replace(/[^0-9]/g, ''))
    setRequestedValue(numericValue)
    if (numericValue > availableBalanceInCents) {
      setError(new Error(t('withdrawalRequestModal.valueExceeded')))
      setBalanceTextFieldHelperText(t('withdrawalRequestModal.valueExceeded'))
    } else {
      setError(undefined)
      setBalanceTextFieldHelperText(defaultTextFieldHelperText)
    }
  }

  const content = (
    <div className="w-full max-w-md">
      <div className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">{t('withdrawalRequestModal.title')}</h2>
        <p className="text-sm text-gray-500">{t('withdrawalRequestModal.description')}</p>
      </div>

      <div className="mb-6">
        <label htmlFor="withdrawal-amount" className="block text-sm font-medium text-gray-700 mb-1">
          {t('withdrawalRequestModal.fields.value')}
        </label>
        <Input
          id="withdrawal-amount"
          type="text"
          placeholder="0.00"
          value={Money.build(requestedValue / 100).getValue()}
          onChange={(e) => handleValueChange(e.target.value)}
          className={error ? 'border-red-500' : ''}
        />
        <p className={`mt-1 text-sm ${error ? 'text-red-500' : 'text-gray-500'}`}>{balanceTextFieldHelperText}</p>
      </div>

      <Button
        className="w-full"
        disabled={!!error || requestedValue < MIN_WITHDRAWAL_IN_CENTS}
        loading={isLoading}
        onClick={handleSubmit}
        data-testid="withdrawal-request-modal-submit-btn"
      >
        {availableBalanceInCents < requestedValue
          ? t('withdrawalRequestModal.valueExceeded')
          : t('withdrawalRequestModal.transfer', {
              value: `(${Money.build(Math.max(0, requestedValue / 100)).getValue()} - ${Money.build(
                WITHDRAWAL_FEE_IN_CENTS / 100,
              ).getValue()})`,
            })}
      </Button>
    </div>
  )

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent>{content}</DialogContent>
    </Dialog>
  )
}
