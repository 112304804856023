import { Input } from '@hub-la/shadcn'
import { Search } from '@mui/icons-material'
import { ConfirmExportModal } from '../../components/confirm-export-modal'
import { SelectOffers } from '../../components/select-offers'
import { SelectStatus } from '../../components/select-status'
import { FilterDrawerDetails } from './filter-drawer-details'
import { SelectDateRangeCalendar } from './select-date-range-calendar'

export const Filters = ({ setFilters, filters, offers, isLoading }) => {
  return (
    <div className="flex justify-between">
      <div className="hidden md:flex flex-wrap gap-2">
        <div className="relative h-10">
          <Search className="absolute w-4 h-4 right-3 top-1/2 transform -translate-y-1/2 text-gray-500 z-10" />
          <Input
            type="text"
            placeholder="Buscar..."
            className="w-[250px]"
            value={filters.search}
            onChange={(e) => setFilters({ ...filters, search: e.target.value })}
          />
        </div>
        <SelectOffers offers={offers} filters={filters} setFilters={setFilters} />
        <SelectDateRangeCalendar
          value={filters}
          onChange={(value) => setFilters((prevState) => ({ ...prevState, ...value }))}
          onSelectChange={(value) => setFilters((prevState) => ({ ...prevState, dateRangeBy: value }))}
          selectValue={filters.dateRangeBy}
          onClearDate={() =>
            setFilters((prevState) => ({ ...prevState, dateRangeBy: 'createdAt', startDate: '', endDate: '' }))
          }
        />
        <SelectStatus
          value={filters.statuses}
          onChange={(value) => setFilters((prevState) => ({ ...prevState, statuses: value }))}
        />
      </div>
      <div className="flex gap-2 ">
        <div className="md:hidden">
          <FilterDrawerDetails filters={filters} setFilters={setFilters} offers={offers} />
        </div>
        <div>
          <ConfirmExportModal filters={filters} offers={offers} />
        </div>
      </div>
    </div>
  )
}
