import { Button, Card, Skeleton } from '@hub-la/shadcn'
import { ArrowLeft } from 'lucide-react'
import { CardRow } from './card-row'

export const Loading = () => {
  return (
    <div className="flex flex-col space-y-6">
      <div className="flex flex-col space-y-6">
        <div className="self-start mb-4">
          <Button variant="ghost" size="sm">
            <ArrowLeft className="mr-2 h-4 w-4" />
            Voltar
          </Button>
        </div>

        <div className="flex flex-col md:flex-row justify-between space-y-5 md:space-y-0 md:space-x-5 mb-6">
          <div className="flex flex-col space-y-2">
            <div className="flex items-center space-x-2">
              <Skeleton className="h-9 w-56" />
              <Skeleton className="h-6 w-20" />
            </div>

            <Skeleton className="h-4 w-40" />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
        <div className="col-span-1 lg:col-span-5">
          <div className="mb-6">
            <Card className="h-full">
              <div className="flex flex-col justify-between space-y-5 p-6">
                <h3 className="text-2xl font-bold">Motivo</h3>

                <div className="flex flex-col w-full space-y-2">
                  <Skeleton className="h-6 w-full" />
                  <Skeleton className="h-6 w-11/12" />
                  <Skeleton className="h-6 w-20" />
                </div>
              </div>
            </Card>
          </div>
        </div>

        <div className="col-span-1 lg:col-span-7">
          <div className="mb-6">
            <Card className="h-full">
              <div className="flex flex-col space-y-5 p-6">
                <div className="flex flex-col space-y-1">
                  <h2 className="text-2xl font-bold">Detalhes</h2>
                  <Skeleton className="h-6 w-1/10" />
                </div>

                <CardRow title="ID da fatura" content={<Skeleton className="h-6 w-[70%]" />} />

                <CardRow title="Cliente" content={<Skeleton className="h-6 w-[70%]" />} />

                <CardRow title="Método de Pagamento" content={<Skeleton className="h-6 w-[70%]" />} />
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}
