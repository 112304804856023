import { CheckCircle2, CircleDashed, CircleX } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { CouponStatus } from '../../domain/enums/coupon-status'

type CouponStatusStatsProps = {
  status: CouponStatus
}

const getStyles = (color: string) => {
  return { size: 16, className: `mr-1 text-${color}-700`, fill: color, style: { fillOpacity: 0.1 } }
}

export const CouponStatusStats = ({ status }: CouponStatusStatsProps) => {
  const { t } = useTranslation()

  const getIcon = (status: CouponStatus) => {
    switch (status) {
      case CouponStatus.ACTIVE:
        return <CheckCircle2 {...getStyles('green')} />
      case CouponStatus.EXPIRED:
        return <CircleX {...getStyles('red')} />
      case CouponStatus.INACTIVE:
        return <CircleDashed {...getStyles('gray')} />
      default:
        return <CheckCircle2 {...getStyles('green')} />
    }
  }

  return (
    <div className="flex items-center">
      {getIcon(status)}
      {t(`status.${status}`)}
    </div>
  )
}
