import { FormikProvider, useFormik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { EditCollaboratorValues } from '../../../domain/dtos/edit-collaborator-values'
import { PermissionResource } from '../../../domain/dtos/permissions-resources-enum'
import { CollaboratorForm } from '../../components/collaborator-form'
import { useGetCollaborator } from '../../hooks/use-get-collaborator'
import { usePutCollaborator } from '../../hooks/use-put-collaborator'
import { createCollaboratorSchema } from '../../validations/create-collaborator.schema'

type Props = {
  userId?: string
}

export const EditCollaboratorPage: React.FC<Props> = ({ userId }) => {
  const { t } = useTranslation()
  const params = useParams<{ id: string }>()

  const { data } = useGetCollaborator(params.id ?? userId)
  const { mutateAsync, isLoading } = usePutCollaborator()

  const formik = useFormik<EditCollaboratorValues>({
    validationSchema: createCollaboratorSchema,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      name: data?.name ?? '',
      email: data?.email ?? '',
      permissions: data?.permissions ?? [],
    },
    onSubmit: async (values: EditCollaboratorValues) => {
      await mutateAsync({
        collaboratorUserId: params.id ?? userId,
        permissions: values.permissions,
      })
    },
  })

  return (
    <FormikProvider value={formik}>
      <h1 className="text-2xl font-bold mb-3">
        {t('editCollaborator.title')} {PermissionResource[PermissionResource.HOME_ABANDONED_CHECKOUT]}
      </h1>

      <p className="text-sm text-gray-600 mb-6">{t('editCollaborator.description')}</p>

      <CollaboratorForm isEdit loading={isLoading} />
    </FormikProvider>
  )
}
