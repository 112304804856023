import isEqual from 'lodash/isEqual'
import { Period } from '../domain/enums/period'
import { GetDateRangeFromPeriod } from './get-date-range-from-period'

export class GetDefaultInvoicesFilters {
  public execute() {
    const getDateFilter = new GetDateRangeFromPeriod().execute(Period.MONTHLY)
    return {
      statuses: ['paid'],
      page: 1,
      pageSize: '10',
      period: Period.DAILY,
      startDate: getDateFilter.from,
      endDate: getDateFilter.to,
      offerIds: [],
      types: [],
      methods: [],
      search: '',
      utmSource: '',
      utmMedium: '',
      utmCampaign: '',
      utmContent: '',
      utmTerm: '',
    }
  }

  public countDifferentProperties(filters): number {
    const templateFilters = this.execute()
    return Object.keys(filters).reduce((count, key) => {
      // Exclude page, pageSize, period, startDate and endDate from the count
      if (['page', 'pageSize', 'period', 'startDate', 'endDate'].includes(key)) {
        return count
      }
      // For other properties, compare with the template
      return !isEqual(filters[key], templateFilters[key]) ? count + 1 : count
    }, 0)
  }

  public getDifferentPropertiesString(filters): string {
    const count = this.countDifferentProperties(filters)

    return count > 0 ? `(${count})` : ''
  }
}
