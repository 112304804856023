import {
  AuthProvider,
  AuthRedirectProvider,
  RecoverPasswordToken as AuthRecoverPasswordTokenPage,
} from '@hub-la/fe-auth'

export const RecoverPasswordTokenInit = () => {
  return (
    <AuthProvider>
      <AuthRedirectProvider>
        <AuthRecoverPasswordTokenPage />
      </AuthRedirectProvider>
    </AuthProvider>
  )
}
