import { AccountVerificationProvider } from '@hub-la/fe-account-verification'
import { ContainerProvider } from '@hub-la/fe-container'
import { LicenseInfo } from '@mui/x-date-pickers-pro'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { I18nextProvider } from 'react-i18next'
import { QueryParamProvider } from 'use-query-params'
import { QueryParamAdapter } from './components/query-param-adapter'
import i18n from './i18n'
import { getQueryOptions } from './utils/query-string-options'

const queryClient = new QueryClient()

export const FinancialStatementProvider = ({ children, container }) => {
  LicenseInfo.setLicenseKey(
    '32e6e29ad03ec06c66d0db27f0ca6ee5Tz03Nzc0NyxFPTE3MzAzODI3NDgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
  )

  return (
    <QueryParamProvider adapter={QueryParamAdapter} options={getQueryOptions()}>
      <AccountVerificationProvider>
        <ContainerProvider container={container}>
          <I18nextProvider i18n={i18n}>
            <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
          </I18nextProvider>
        </ContainerProvider>
      </AccountVerificationProvider>
    </QueryParamProvider>
  )
}
