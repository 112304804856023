import { Button } from '@hub-la/shadcn'
import { ArrowLeft, Undo2 } from 'lucide-react'
import { RefundStatus } from '../../../domain/enums/refund-status'
import { Currency } from '../../../domain/vos/currency'
import { useAcceptRefund } from '../../hooks/use-accept-refund'
import { RejectRefundModal } from '../modals/reject-refund-modal'
import { RefundCurrentStatus } from '../refund-current-status'

type HeaderProps = {
  id: string
  status: RefundStatus
  name: string
  amount: number
  paidAt: string
  createdAt: string
  onClose: () => void
}

export const Header = ({ id, status, name, amount, paidAt, createdAt, onClose }: HeaderProps) => {
  const { mutateAsync: acceptRefund, isLoading } = useAcceptRefund()
  const canChangeStatus = [RefundStatus.CREATED, RefundStatus.PENDING].includes(status)
  const currency = new Currency()

  const onRefund = () => {
    acceptRefund(id).then(() => {
      onClose()
    })
  }

  return (
    <div className="flex flex-col space-y-6">
      <div className="self-start">
        <Button variant="ghost" size="sm" onClick={onClose}>
          <ArrowLeft className="mr-2 h-4 w-4" />
          Voltar
        </Button>
      </div>

      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-end space-y-5 sm:space-y-0 sm:space-x-5">
        <div className="flex flex-col space-y-2">
          <div className="flex flex-col sm:flex-row sm:items-center space-y-2 sm:space-y-0 sm:space-x-2">
            <h3 className="text-2xl font-medium">
              {name} - {currency.format(amount)}
            </h3>

            <div className="flex space-x-2">
              <RefundCurrentStatus status={status} paidAt={paidAt} createdAt={createdAt} />
            </div>
          </div>

          <span className="text-sm text-muted-foreground">{id}</span>
        </div>

        {canChangeStatus && (
          <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 w-full sm:w-auto">
            <Button variant="outline" onClick={onRefund} loading={isLoading}>
              <Undo2 className="mr-2 h-4 w-4" />
              Reembolsar
            </Button>

            <RejectRefundModal refundId={id} onSuccess={onClose} />
          </div>
        )}
      </div>
    </div>
  )
}
