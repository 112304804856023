import { TableCell, TableRow } from '@hub-la/shadcn'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BookAccount } from '../../../../domain/dtos/book-accounts'
import { TransactionSource } from '../../../../domain/dtos/get-moviments.dto'
import { BalanceMovement } from '../../../../domain/dtos/movements'
import { MovementsByDate } from '../../../../domain/dtos/movements-by-date'
import { Money } from '../../../../domain/vos/money'
import { MovementAmount } from './movement-amount'
import { MovementStatus } from './movement-status'

type GroupedMovementsProps = {
  data: MovementsByDate
  accountType: BookAccount
  idx: number
  onInvoiceClick?: (invoiceId: string) => void
  onWithdrawalClick?: (withdrawalId: string) => void
  headCells: HeadCell[]
}

type HeadCell = {
  key: string
  label: string
}

export const GroupedMovements: React.FC<GroupedMovementsProps> = ({
  data,
  idx,
  accountType,
  onInvoiceClick,
  onWithdrawalClick,
  headCells,
}) => {
  const { t } = useTranslation()
  const getMoneyFromCents = (b: number) => new Money(b / 100).getValue()

  const openTransactionModal = (source: TransactionSource, id: string) => {
    switch (source) {
      case TransactionSource.SALE:
      case TransactionSource.SALE_RELEASED:
      case TransactionSource.RENEWAL:
      case TransactionSource.RENEWAL_RELEASED:
      case TransactionSource.UPGRADE:
      case TransactionSource.UPGRADE_RELEASED:
      case TransactionSource.REFUND:
      case TransactionSource.CHARGEBACK_DISPUTE_OPENED:
      case TransactionSource.CHARGEBACK_ACCEPTED_AFTER_DISPUTE:
      case TransactionSource.CHARGEBACK_ACCEPTED_WITHOUT_DISPUTE:
      case TransactionSource.CHARGEBACK_REJECTED:
        return onInvoiceClick?.(id)
      case TransactionSource.WITHDRAWAL_PENDING:
      case TransactionSource.WITHDRAWAL_TAX_FEE:
      case TransactionSource.WITHDRAWAL_SUCCEEDED:
      case TransactionSource.WITHDRAWAL_FAILED_AFTER_SUCCEEDED:
      case TransactionSource.WITHDRAWAL_FAILED:
      case TransactionSource.WITHDRAWAL_CANCELED:
        return onWithdrawalClick?.(id)
      default:
        return
    }
  }

  const formatDate = (date: Date | string, pattern: string) => (date ? moment(date).format(pattern) : '')
  const isTransactionSourceRelatedToInvoice = (source: TransactionSource) =>
    [
      TransactionSource.SALE,
      TransactionSource.SALE_RELEASED,
      TransactionSource.RENEWAL,
      TransactionSource.RENEWAL_RELEASED,
      TransactionSource.UPGRADE,
      TransactionSource.UPGRADE_RELEASED,
      TransactionSource.REFUND,
      TransactionSource.CHARGEBACK_DISPUTE_OPENED,
      TransactionSource.CHARGEBACK_ACCEPTED_AFTER_DISPUTE,
      TransactionSource.CHARGEBACK_ACCEPTED_WITHOUT_DISPUTE,
      TransactionSource.CHARGEBACK_REJECTED,
    ].includes(source)

  const columnsComponentBuilder: Record<HeadCell['key'], (movement: BalanceMovement) => React.ReactNode> = {
    icon: (movement) => <MovementStatus amountInCents={movement.amountInCents} />,
    date: (movement) => (
      <span>
        {formatDate(movement.createdAt, 'D MMM. YYYY')} {String(movement.createdAtTime)}
      </span>
    ),
    invoiceId: (movement) => (
      <span className="text-xs text-muted-foreground">
        {isTransactionSourceRelatedToInvoice(movement.transactionSource) ? movement.externalId ?? '-' : '-'}
      </span>
    ),
    description: (movement) => <span>{t(`transactionSource.${accountType}.${movement.transactionSource}`)}</span>,
    releaseDate: (movement) => <span>{formatDate(movement.releaseDate, 'DD MMM. YYYY')}</span>,
    value: (movement) => <MovementAmount amount={movement.amountInCents} />,
    balance: (movement) => <span>{getMoneyFromCents(movement?.balance ?? 0)}</span>,
  }

  return (
    <React.Fragment>
      {data.movements.map((movement, movementIdx: number) => (
        <TableRow
          key={`movement-cell-${idx}${movementIdx}`}
          onClick={() => openTransactionModal(movement.transactionSource, movement.externalId)}
          className={
            [TransactionSource.INTERNAL_TRANSFER, TransactionSource.MIGRATED_BALANCE].includes(
              movement.transactionSource,
            )
              ? ''
              : 'cursor-pointer hover:bg-gray-100'
          }
        >
          {headCells.map((headCell, idx) => (
            <TableCell key={headCell.key}>{columnsComponentBuilder[headCell.key](movement)}</TableCell>
          ))}
        </TableRow>
      ))}
    </React.Fragment>
  )
}
