import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { PutRemoveFreeSubscriptionInput } from '../../../domain/dtos/put-remove-free-subscription-input'
import { QueryKey } from '../../../domain/enums/query-key'
import { PutRemoveFreeSubscription } from '../../../usecases/members/put-remove-free-subscription'

export const usePutRemoveFreeSubscription = ({ onSuccess }: { onSuccess: () => void }) => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.putRemoveFreeSubscription],
    (input: PutRemoveFreeSubscriptionInput) => new PutRemoveFreeSubscription(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QueryKey.getDeactivatesMembers] })
        queryClient.invalidateQueries({ queryKey: [QueryKey.getActiveMembers] })
        onSuccess()
      },
    },
  )
}
