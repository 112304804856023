import { useTranslation } from 'react-i18next'
import { Offer } from '../../../../domain/dtos/offer'
import { Status } from '../../../../domain/dtos/status'
import { formatCurrency } from '../../../../domain/vos/format-currency'
import { CardInfoBox } from '../../../components/card-info-box'
import { useGetAverageTicket } from '../../../hooks/use-get-average-ticket'
import { useRefetch } from '../../../hooks/use-refetch'
import { Loading } from './loading'

type AverageTicketProps = {
  startDate: string
  endDate: string
  offerIds: string[]
  offers: Offer[]
  isLoading: boolean
}

export const AverageTicket = (props: AverageTicketProps) => {
  const { startDate, endDate, offers, offerIds, isLoading } = props
  const { t } = useTranslation()

  const { data, isFetching, refetch } = useGetAverageTicket({ startDate, endDate, offers, offerIds }, isLoading)
  const isLoadingData = isFetching && data.current === 0

  useRefetch({ startDate, endDate, offers, offerIds }, refetch)

  if (isLoadingData || isLoading) {
    return <Loading />
  }

  const getVariation = () => {
    return data.status !== Status.neutral
      ? {
          indicator: data.indicator,
          status: data.status,
          description: `${data.percentage} ${t('variation', { previous: formatCurrency(data.previous) })}`,
        }
      : undefined
  }

  return (
    <CardInfoBox
      title={t('averageTicket.title')}
      description={t('averageTicket.description')}
      current={formatCurrency(data.current)}
      variation={getVariation()}
    />
  )
}
