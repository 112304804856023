import { Button } from '@hub-la/shadcn'
import { ChevronLeft } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { InvoiceStatus } from '../../../domain/enums/invoice-status'
import { ReceiverRole } from '../../../domain/enums/receiver-role'
import { SmartInstallmentStatus } from '../../../domain/enums/smart-installment-status'
import { CopyClipboardTag } from '../../components/copy-clipboard-tag'
import { InvoiceInstallmentsStats } from '../../components/invoice-installments-stats'
import { InvoiceStatusStats } from '../../components/invoice-status-stats'
import { RefundInvoiceModal } from './refund-invoice-modal'

type Props = {
  id: string
  total: string
  status: InvoiceStatus
  smartInstallmentStatus?: SmartInstallmentStatus
  installment: number
  installments: number
  role: ReceiverRole
  onChange: () => void
  onClose: () => void
}

export const Header: React.FC<Props> = (props) => {
  const { id, total, status, installment, installments, role, onChange, onClose } = props
  const [isCancelInvoiceOpen, setIsCancelInvoiceOpen] = useState<boolean>(false)

  const canRefund = role === ReceiverRole.ROLE_SELLER && status === InvoiceStatus.PAID
  const history = useHistory()

  const { t } = useTranslation()

  return (
    <div className="flex flex-col md:flex-row md:items-center justify-between">
      <div className="space-y-2">
        <Button className=" h-4 px-0" size="sm" variant="link" onClick={() => history.goBack()}>
          <ChevronLeft className=" w-4 h-4 mr-2" />
          Voltar
        </Button>
        <div className="flex items-center gap-3 mb-2">
          <h3 className="text-3xl font-semibold">{total}</h3>
          <InvoiceStatusStats status={status} />
          {props.smartInstallmentStatus && (
            <InvoiceInstallmentsStats installment={installment} installments={installments} />
          )}
        </div>
        <div>
          <CopyClipboardTag
            value={id}
            successMessage={t('copyClipboard.idCopied')}
            tooltip={t('copyClipboard.idTooltip')}
          />
        </div>
      </div>
      <div className="mt-4 space-x-2 h-full">{canRefund && <RefundInvoiceModal invoiceId={id} />}</div>
    </div>
  )
}
