import { Stack, Tab, TabList, TabPanel, Tabs } from '@hub-la/design-system'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IntegrationProvider } from '../../../domain/dtos/integration-provider'
import { useGetIntegrationByProvider } from '../../hooks/use-get-integration-by-provider'
import { IntegrationAboutTab } from '../integration-about-tab'
import { IntegrationHistoryTab } from '../integration-history-tab'
import { IntegrationSettingsTab } from '../integration-settings-tab'
import { ruleSchema, saveRuleSchema } from './rdstation.schema'

type RDStationDetailProps = {
  provider: IntegrationProvider
}

export const RDStationDetail = ({ provider }: RDStationDetailProps) => {
  const { t } = useTranslation()
  const [tab, setTab] = useState('about')
  const {
    data: integration,
    refetch,
    isFetching,
    isLoading,
  } = useGetIntegrationByProvider({
    provider,
  })

  const loading = isLoading || isFetching
  const hasIntegration = !!integration

  return (
    <Tabs value={tab}>
      <TabList onChange={(_, tab) => setTab(tab)}>
        <Tab label={t('tabs.about')} value="about" key="about" />
        {hasIntegration && <Tab label={t('tabs.settings')} value="settings" key="settings" />}
        {hasIntegration && <Tab label={t('tabs.history')} value="history" key="history" />}
      </TabList>
      <Stack pt={4}>
        <TabPanel value="about" key="about">
          <IntegrationAboutTab
            provider={provider}
            integrationId={integration?.id}
            loading={loading}
            refetch={refetch}
            setTab={setTab}
          />
        </TabPanel>
        {hasIntegration && (
          <>
            <TabPanel value="settings" key="settings">
              <IntegrationSettingsTab
                provider={provider}
                integrationId={integration.id}
                ruleSchema={ruleSchema}
                ruleValidationSchema={saveRuleSchema}
              />
            </TabPanel>
            <TabPanel value="history" key="history">
              <IntegrationHistoryTab integrationId={integration.id} />
            </TabPanel>
          </>
        )}
      </Stack>
    </Tabs>
  )
}
