export const pt = {
  title: 'Início',
  betterExperience:
    'Para ter uma melhor experiência de consumo dos gráficos e relatórios disponíveis, acesse pelo seu computador.',
  activeSubscriptions: {
    title: 'Assinaturas ativas',
    description:
      'Esta é a contagem total dos seus assinantes ativos e cancelados. Não inclui membros gratuitos ou produtores. Um assinante com múltiplas assinaturas referentes a vários produtos, são contados 1 vez por produto.',
  },
  contentCopiedSuccessfully: 'Copiado com sucesso',
  copyClipboard: {
    idCopied: 'ID copiado',
  },
  newSubscribers: {
    title: 'Novos assinantes',
    description: 'Total de novas assinaturas criadas durante o período selecionado.',
  },
  growth: {
    title: 'Crescimento de assinantes ativos',
    description:
      'Esta é a alteração líquida de assinantes ativos no período selecionado. Caso esse número for menor do que zero, você perdeu mais assinantes do que ganhou no período selecionado.',
  },
  canceledSubscriptions: {
    title: 'Assinaturas canceladas',
    description: 'Total de assinaturas canceladas proativamente pelos membros durante o período.',
  },
  inactiveSubscriptions: {
    title: 'Assinaturas inativadas',
    description:
      'Refere-se às assinaturas que se tornaram inativas (por cancelamento, mudança para plano gratuito, pausa ou inadimplência) dentro do intervalo da data selecionada.',
  },
  variation: 'vs. {{previous}} período anterior',
  selectPeriod: {
    hourly: 'Por hora',
    daily: 'Diário',
    weekly: 'Semanal',
    monthly: 'Mensal',
  },
  table: {
    pageSize: '{{total}} por página',
  },
  empty: 'Nenhum resultado encontrado',
  emptySubtitle: 'Ajuste seus critérios de busca ou redefina os filtros para obter mais resultados.',
  filters: {
    autocomplete: {
      noOptions: 'Não há opções disponíveis',
      loading: 'Carregando...',
      products: 'Produtos',
    },
    dateRangeBy: {
      createdAt: 'Data de criação',
      nextDueAt: 'Data de vencimento',
    },
  },
  tabs: {
    home: 'Visão geral',
    subscriptions: 'Assinaturas',
  },
  status: {
    active: 'Ativa',
    canceled: 'Cancelada',
    inactive: 'Inativa',
    incomplete: 'Incompleta',
    trial: 'Período de Teste',
  },
  exportXlsx: {
    title: 'Exportar assinaturas',
    cancel: 'Cancelar',
    confirm: 'Exportar',
    noOptions: 'Não há opções disponíveis',
    loading: 'Carregando...',
    offers: 'Produtos',
  },
  invoiceStatus: {
    pending: 'Em aberto',
    paid: 'Paga',
    expired: 'Não paga',
    refunded: 'Reembolsada',
    in_protest: 'Em protesto',
    chargeback: 'Chargeback',
  },
  planType: {
    monthly: 'Plano mensal',
    quarterly: 'Plano trimestral',
    semiannually: 'Plano semestral',
    annually: 'Plano anual',
  },
  paymentMethod: {
    credit_card: 'Cartão de crédito',
    pix: 'Pix',
  },
  actions: {
    title: '{{qnt}} assinaturas',
    deactivateBtn: 'Cancelar assinatura(s)',
    deactivateInactives: 'Você não pode cancelar assinaturas inativas.',
    deactivateCanceleds: 'Você não pode cancelar assinaturas canceladas.',
    deactivateIncompletes: 'Você não pode cancelar assinaturas que não foram pagas.',
    cannotDeactivate: 'Você não tem permissão para cancelar assinaturas.',
    addBtn: 'Estender vencimento',
    addInactives: 'Você não pode estender o vencimento de assinaturas inativas.',
    addIncompletes: 'Você não pode estender o vencimento de assinaturas que não foram pagas.',
    cannotAdd: 'Você não tem permissão para estender o vencimento de assinaturas.',
  },
  errors: {
    general: 'Não foi possível realizar a ação',
    required: 'Campo obrigatorio',
    clipboardFailed: 'Erro ao copiar. Tente novamente mais tarde',
  },
  success: 'Ação realizada com sucesso!',
}
