import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { PostTransformFreeMembersInput } from '../../../domain/dtos/post-transform-free-members-input'
import { StatusTransformFreeMemberOutput } from '../../../domain/dtos/post-transform-free-members-output'
import { QueryKey } from '../../../domain/enums/query-key'
import { PostTransformFreeMembers } from '../../../usecases/members/post-transform-free-members'

export const usePostTransformFreeMembers = ({
  onSuccess,
}: {
  onSuccess: (data: StatusTransformFreeMemberOutput[]) => void
}) => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.postTransformFreeMembers],
    (input: PostTransformFreeMembersInput) => new PostTransformFreeMembers(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess: (data, input) => {
        queryClient.setQueryData([QueryKey.getActiveMembers], (data: any) => {
          return {
            ...data,
            total: data.total + input?.selectedItems?.length,
            pageSize: data.pageSize + input?.selectedItems?.length,
            items: [...data.items, ...(input?.selectedItems ?? [])],
          }
        })
        queryClient.setQueryData([QueryKey.getDeactivatesMembers], (data: any) => {
          const filteredData = data.items.filter((r) => !input?.selectedItems?.some((t) => r.id === t.id))
          const changedElements = input?.selectedItems?.length ?? 0
          return {
            ...data,
            total: data.total - changedElements,
            pageSize: data.pageSize - changedElements,
            items: filteredData,
          }
        })
        onSuccess(data)
      },
    },
  )
}
