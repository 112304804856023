import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@hub-la/shadcn'
import { DialogClose } from '@radix-ui/react-dialog'
import { CircleX } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useCancelSmartInstallment } from '../../hooks/use-cancel-smart-installment'

type Props = {
  smartInstallmentId: string
  onSuccess: () => void
}

export const CancelInstallmentModal: React.FC<Props> = (props) => {
  const { smartInstallmentId, onSuccess } = props
  const {
    mutateAsync: cancelSmartInstallment,
    isLoading,
    error,
    isSuccess,
    reset,
    isError,
  } = useCancelSmartInstallment()
  const { t } = useTranslation()

  const [isCancelSmartInstallmentOpen, setIsCancelSmartInstalmentOpen] = React.useState(false)

  const onSubmit = () => {
    cancelSmartInstallment(smartInstallmentId)
      .then(() => onSuccess())
      .finally(() => {
        setIsCancelSmartInstalmentOpen(true)
      })
  }

  function getModalMessage() {
    if (isSuccess) return t('success')
    return t((error as Error)?.message)
  }

  if (isSuccess || isError) {
    return (
      <Dialog
        open={isCancelSmartInstallmentOpen}
        onOpenChange={() => setIsCancelSmartInstalmentOpen((prevState) => !prevState)}
      >
        <DialogContent className="z-10002">
          <DialogHeader>
            <DialogTitle>{t('exportXlsx.title')}</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <p>{getModalMessage()}</p>
          </div>
          <div className="flex justify-end">
            <DialogClose>
              <Button variant="outline">{t('exportXlsx.getIt')}</Button>
            </DialogClose>
          </div>
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Dialog
      open={isCancelSmartInstallmentOpen}
      onOpenChange={() => setIsCancelSmartInstalmentOpen((prevState) => !prevState)}
    >
      <DialogTrigger asChild>
        <Button variant="outline" className="w-full">
          <CircleX className="h-4 w-4 mr-2" />
          Cancelar parcelamento
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px] z-10002">
        <DialogHeader>
          <DialogTitle>Deseja cancelar o parcelamento?</DialogTitle>
          <DialogDescription>
            Ao cancelar o parcelamento inteligente, você também está cancelando automaticamente a assinatura do seu
            cliente. Com isso, ele perderá o acesso ao produto e suas futuras cobranças são canceladas.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="gap-2">
          <DialogClose>
            <Button variant="outline" className="w-full">
              Cancelar
            </Button>
          </DialogClose>
          <Button onClick={onSubmit} loading={isLoading} className="flex gap-2">
            <CircleX className="h-4 w-4" />
            Cancelar parcelamento
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
