import { cn, Sheet, SheetContent } from '@hub-la/shadcn'

import React, { memo } from 'react'

type ResponsiveDrawerProps = {
  onClose: () => void
  open: boolean
  children: React.ReactNode
}

export const ResponsiveDrawer = memo(({ onClose, open, children }: ResponsiveDrawerProps) => {
  const isMobile = window.innerWidth < 768

  return (
    <Sheet open={open} onOpenChange={onClose}>
      <SheetContent
        side={isMobile ? 'bottom' : 'right'}
        className={cn('p-0 overflow-y-auto', isMobile ? 'rounded-t-2xl' : 'h-full')}
      >
        {isMobile && <div className="w-10 h-1 bg-gray-200 rounded-full mx-auto mt-2 mb-4" />}
        <div className={`bg-white ${isMobile ? 'rounded-t-2xl' : ''} h-full`}>{children}</div>
      </SheetContent>
    </Sheet>
  )
})
