import { yupResolver } from '@hookform/resolvers/yup'
import { OfferDto, OfferDtoStatusEnum, ProductDto, ProductDtoStatusEnum } from '@hub-la/sdk-bff-product'
import {
  Badge,
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@hub-la/shadcn'
import React, { useEffect } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { formatLocalNumber } from '../../../domain/vos/format-local-number'
import PlaceholderLogo from '../../assets/placeholder-image.svg'
import { useGetOffers } from '../../hooks/use-list-offers'
import { useGetProducts } from '../../hooks/use-list-products'
import { upsellSchemaValidation, UpsellSchemaValidationType } from '../../validations/upsell-validation'
import { SwitchToggleChildren } from '../switch-toggle-children'

const OfferPreviewSkeleton: React.FC = () => (
  <div className="my-2 w-full max-w-[405px] border border-gray-200 rounded-lg p-4 mx-auto">
    <div className="flex gap-3">
      <div className="w-[84px] h-[56px] bg-gray-200 rounded" />
      <div className="space-y-1">
        <div className="w-[140px] h-5 bg-gray-200 rounded" />
        <div className="w-[85px] h-6 bg-gray-200 rounded" />
      </div>
    </div>
  </div>
)

interface UpsellModalProps {
  onCancel: () => void
  onSubmit: (values: UpsellSchemaValidationType & { offer: OfferDto | null; product: ProductDto | null }) => void
  open: boolean
  defaultValues?: any
  actionSave?: string
  actionCancel?: string
}

export const UpsellModal: React.FC<UpsellModalProps> = ({
  onCancel,
  onSubmit,
  open,
  defaultValues,
  actionSave,
  actionCancel,
}) => {
  const { t } = useTranslation()

  const form = useForm({
    defaultValues: {
      productId: '',
      offerId: '',
      isYoutubeEmbed: false,
      youtubeEmbedUrl: '',
    },
    resolver: yupResolver(upsellSchemaValidation),
    mode: 'onChange',
  })

  const {
    watch,
    resetField,
    control,
    setValue,
    formState: { isValid },
  } = form

  useEffect(() => {
    form.reset({
      productId: defaultValues?.productId ?? '',
      offerId: defaultValues?.offer?.id ?? '',
      isYoutubeEmbed: defaultValues?.isYoutubeEmbed ?? false,
      youtubeEmbedUrl: defaultValues?.youtubeEmbedUrl ?? '',
    })
  }, [
    defaultValues?.offer?.id,
    defaultValues?.productId,
    defaultValues?.isOldTotal,
    defaultValues?.oldTotal,
    defaultValues?.offer?.price,
  ])

  const selectedProductId = watch('productId')
  const selectedOfferId = watch('offerId')

  const { data: products, isLoading: isLoadingProducts } = useGetProducts({
    page: 1,
    pageSize: 999,
  })
  const availableProducts = (products?.items ?? []).filter(
    (product) => product.status === ProductDtoStatusEnum.IS_SELLING,
  )
  const { data: offers, isLoading: isLoadingOffers } = useGetOffers({
    page: 1,
    pageSize: 999,
    productId: selectedProductId ?? '',
  })
  const availableOffers = (offers?.items ?? [])?.filter((offer) => offer.status === OfferDtoStatusEnum.IS_SELLING)

  const selectedOffer = offers?.items?.find((offer) => offer.id === selectedOfferId)
  const selectedProduct = products?.items?.find((product) => product.id === selectedProductId)

  // clear offerId input when productId changes (product selection above)
  useEffect(() => {
    resetField('offerId')
  }, [resetField, selectedProductId])

  const handleCancel = () => {
    form.reset()
    onCancel()
  }

  const handleSubmit = (values: UpsellSchemaValidationType) => {
    form.reset()
    onSubmit({ ...values, offer: selectedOffer ?? null, product: selectedProduct ?? null })
  }

  return (
    <Dialog open={open} onOpenChange={onCancel}>
      <DialogContent className="sm:max-w-[600px]">
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <DialogHeader>
              <DialogTitle>{t('upsellModal.title')}</DialogTitle>
              <DialogDescription>{t('upsellModal.description')}</DialogDescription>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <Controller
                control={control}
                name="productId"
                render={({ field, fieldState }) => (
                  <Select onValueChange={field.onChange} value={field.value} disabled={isLoadingProducts}>
                    <SelectTrigger className={fieldState.error ? 'border-red-500' : ''}>
                      <SelectValue placeholder={t('upsellModal.productList.title')} />
                    </SelectTrigger>
                    <SelectContent className="z-10002">
                      {availableProducts?.map((product) => (
                        <SelectItem key={product.id} value={product.id}>
                          {product.name}
                        </SelectItem>
                      ))}

                      {availableProducts?.length === 0 && (
                        <SelectItem value="empty" disabled>
                          {t('upsellModal.offersList.emptyAvailable')}
                        </SelectItem>
                      )}
                    </SelectContent>
                  </Select>
                )}
              />
              <Controller
                control={control}
                name="offerId"
                render={({ field, fieldState }) => (
                  <Select
                    onValueChange={field.onChange}
                    value={field.value}
                    disabled={isLoadingOffers || !selectedProductId}
                  >
                    <SelectTrigger className={fieldState.error ? 'border-red-500' : ''}>
                      <SelectValue placeholder={t('upsellModal.offersList.title')} />
                    </SelectTrigger>
                    <SelectContent className="z-10002">
                      {availableOffers?.map((offer) => {
                        const lowerPricePlan = offer?.plans?.sort((a, b) => a.price - b.price)[0]
                        const planOrPrice = lowerPricePlan
                          ? `A partir de ${formatLocalNumber(lowerPricePlan?.price)}/${t(
                              'plansPerPeriod.' + lowerPricePlan?.interval,
                            )}`
                          : formatLocalNumber(offer?.price ?? 0)

                        return (
                          <SelectItem key={offer.id} value={offer.id ?? ''}>
                            {offer.name} - {planOrPrice}
                          </SelectItem>
                        )
                      })}

                      {availableOffers?.length === 0 && (
                        <SelectItem value="empty" disabled>
                          {t('upsellModal.offersList.emptyAvailable')}
                        </SelectItem>
                      )}
                    </SelectContent>
                  </Select>
                )}
              />
              {selectedProductId && selectedOfferId ? (
                <div className="my-2 w-full max-w-[405px] border border-gray-200 rounded-lg p-4 mx-auto">
                  <div className="flex gap-3">
                    <img
                      src={selectedProduct?.picture ?? PlaceholderLogo}
                      alt="Product"
                      className="w-[84px] h-[56px] object-cover rounded"
                    />
                    <div className="space-y-1">
                      <p className="text-sm">{selectedOffer?.name}</p>
                      <div className="font-bold">
                        {(selectedOffer?.plans ?? [])?.length > 0
                          ? selectedOffer?.plans?.map((plan) => (
                              <Badge key={plan.interval} variant="secondary" className="mr-1">
                                {`${formatLocalNumber(plan.price ?? 0)} / ${t(
                                  'plansPerPeriod.' + plan.interval.toLowerCase(),
                                )}`}
                              </Badge>
                            ))
                          : formatLocalNumber(selectedOffer?.price ?? 0)}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <OfferPreviewSkeleton />
              )}

              <SwitchToggleChildren name="isYoutubeEmbed" label={t('upsellModal.youtubeEmbed.title')}>
                <p className="text-sm text-gray-600">{t('upsellModal.youtubeEmbed.description')}</p>
                <Controller
                  name="youtubeEmbedUrl"
                  control={control}
                  render={({ field, fieldState }) => (
                    <div className="space-y-2">
                      <Input
                        placeholder={t('upsellModal.youtubeEmbed.youtubeEmbedUrl')}
                        className={fieldState.error ? 'border-red-500' : ''}
                        {...field}
                      />
                      {fieldState.error && (
                        <p className="text-sm text-red-500">{t(`fieldErrors.${fieldState.error.type}`)}</p>
                      )}
                    </div>
                  )}
                />
              </SwitchToggleChildren>
            </div>
            <DialogFooter>
              <Button variant="outline" onClick={handleCancel} type="button">
                {actionCancel ?? t('upsellModal.actions.cancel')}
              </Button>
              <Button disabled={!isValid} type="button" onClick={form.handleSubmit(handleSubmit)}>
                {actionSave ?? t('upsellModal.actions.save')}
              </Button>
            </DialogFooter>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  )
}
