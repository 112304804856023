import { CohortDto, CohortsByProductIdResponseDto } from '@hub-la/sdk-bff-product'
import {
  Button,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@hub-la/shadcn'
import { ArrowLeft, Info } from 'lucide-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useGetCohortsList } from '../../../../../hooks/cohorts/use-get-cohorts-list'
import { useBindOrUnbindResourceToProduct } from '../../../../../hooks/member-area/use-bind-or-unbind-resource-to-product'
import { useGetAssociatedCohorts } from '../../../../../hooks/member-area/use-get-associated-cohorts'
import { useGetResources } from '../../../../../hooks/member-area/use-get-resources'
import CohortsCard from '../cohorts-card'
import { useInitialState } from '../cohorts-card/get-initial-state'

interface BindExistentGroupDrawerInterface {
  open: boolean
  onClose: () => void
  offerId?: string
}

export const BindExistentGroupDrawer: React.FC<BindExistentGroupDrawerInterface> = ({ open, onClose, offerId }) => {
  const { t } = useTranslation()
  const [selectedOption, setSelectedOption] = useState<string>('')

  const { data, isLoading } = useGetResources(offerId, false)
  const { mutateAsync: bindResource, isLoading: isBindingResourceLoading } = useBindOrUnbindResourceToProduct({
    action: 'bind',
  })
  const { productId } = useParams<{ productId: string }>()
  const { data: cohortsData = [] as unknown as CohortsByProductIdResponseDto, isLoading: isLoadingGetCohorts } =
    useGetCohortsList({
      productId,
    })
  const { data: cohorts = [], isLoading: isCohortAssociationLoading } = useGetAssociatedCohorts({
    resourceExternalId: selectedOption,
    productId: offerId ?? '',
  })
  const [cohortSwitches, setCohortsSwitches] = useState<CohortDto[]>([])

  const handleOnSubmit = () => {
    const resource = data && data.find((resource) => resource.id === selectedOption)
    if (resource) {
      bindResource({ offerId, externalResourceId: resource.id, cohortIds: cohortSwitches.map((cohort) => cohort.id) })
        .then(() => onClose())
        .catch()
    }
  }

  const initialState = useInitialState({
    data: cohortsData?.items,
    cohorts: cohorts,
    isLoadingData: isLoadingGetCohorts || isCohortAssociationLoading,
  })

  return (
    <Sheet open={open} onOpenChange={onClose}>
      <SheetContent className="sm:max-w-lg flex flex-col gap-6 overflow-y-auto">
        <SheetHeader>
          <SheetTitle className="flex gap-2 items-center">
            <ArrowLeft className="h-6 w-6 text-green-900 cursor-pointer" onClick={onClose} />

            {t('member-area.groups.addGroupModal.cards.bindExistentGroup.title')}
          </SheetTitle>
        </SheetHeader>

        <p className="text-sm text-gray-600">
          {t('member-area.groups.addGroupModal.cards.bindExistentGroup.description')}
        </p>

        <div className="flex flex-col gap-4">
          <Select
            disabled={isLoading || !data}
            value={selectedOption}
            onValueChange={(value) => setSelectedOption(value)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Selecione um grupo existente" />
            </SelectTrigger>

            <SelectContent className="z-[61]">
              {data &&
                data.map((option) => (
                  <SelectItem key={option.id} value={option.id}>
                    {option.name}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>

          <CohortsCard
            data={initialState}
            isLoadingData={isLoadingGetCohorts || isCohortAssociationLoading}
            onChange={setCohortsSwitches}
          />

          <Button disabled={!selectedOption || isLoading || isBindingResourceLoading} onClick={handleOnSubmit}>
            {t('member-area.groups.addGroupModal.cards.bindExistentGroup.modal.confirm')}
          </Button>

          <div className="flex items-center gap-2">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Info className="h-4 w-4" />
                </TooltipTrigger>

                <TooltipContent>
                  <p>{t('member-area.groups.editResourceDrawer.toolTip')}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>

            <p className="text-xs text-gray-500">{t('member-area.groups.createGroupModal.disclaimer')}</p>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  )
}
