import { Button, Card, CardContent, CardHeader, CardTitle, Dialog, DialogContent, Separator } from '@hub-la/shadcn'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { CouponItem } from '../../../domain/dtos/coupon'
import { LifetimeInterval, RecurringInterval } from '../../../domain/enums/interval'
import { ContentRow } from './content-row'
import { EntitiesCards } from './entities-cards'

type DescriptionProps = {
  discount: number
  usagesCount: number
  maxAllowedTotalUsages: number
  appliableTo: CouponItem[]
  isRecurrent: boolean
}

export const Description = ({
  discount,
  usagesCount,
  maxAllowedTotalUsages,
  appliableTo,
  isRecurrent,
}: DescriptionProps) => {
  const [openProductsModal, setOpenProductsModal] = useState<boolean>(false)

  const { t } = useTranslation()

  const getOfferNames = (): JSX.Element => {
    if (!appliableTo || !appliableTo.find((it) => it.offerId))
      return <Trans i18nKey="description.productsContent.every" />

    if (appliableTo.length > 3)
      return (
        <Trans
          i18nKey="description.productsContent.many"
          values={{
            products: appliableTo
              .slice(0, 3)
              .map((it) => it.offerName)
              .join(', '),
            count: appliableTo.length - 3,
          }}
          components={[<Button onClick={() => setOpenProductsModal(true)} style={{ cursor: 'pointer' }} />]}
        />
      )

    return (
      <Trans
        i18nKey="description.productsContent.specific"
        values={{ products: appliableTo.map((it) => it.offerName).join(', ') }}
      />
    )
  }

  const getUniqueIntervals = () => {
    const uniqueIntervals = [] as (RecurringInterval | LifetimeInterval)[]

    appliableTo.forEach((item) => {
      item.intervals.forEach((interval) => {
        if (!uniqueIntervals.includes(interval)) {
          uniqueIntervals.push(interval)
        }
      })
    })

    const uniqueIntervalsNames = uniqueIntervals.map((interval) => t(`planType.${interval}`))

    return uniqueIntervals.length > 0 ? uniqueIntervalsNames.join(', ') : 'Todos os planos'
  }

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader className="p-6">
          <CardTitle className="text-lg font-semibold flex justify-between items-center">
            {t('description.title')}
          </CardTitle>
        </CardHeader>
        <Separator />
        <CardContent className="p-6 space-y-4">
          <ContentRow title={t('description.discount')} content={<span>{discount}%</span>} />
          <ContentRow
            title={t('description.usagesCount')}
            content={
              <span>
                {usagesCount}/{maxAllowedTotalUsages ?? '∞'}
              </span>
            }
          />
          <ContentRow title={t('description.products')} content={<span>{getOfferNames()}</span>} />

          <ContentRow title={t('description.plans')} content={<span>{getUniqueIntervals()}</span>} />

          <ContentRow title={t('description.isRecurrent')} content={<span>{isRecurrent ? 'Sim' : 'Não'}</span>} />
        </CardContent>
      </Card>
      <Dialog open={openProductsModal} onOpenChange={setOpenProductsModal}>
        <DialogContent>
          <EntitiesCards
            products={
              appliableTo
                ? appliableTo.map((it) => ({
                    name: it.offerName ?? '',
                    id: it.offerId ?? '',
                  }))
                : []
            }
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}
