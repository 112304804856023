export const pt = {
  editGroup: {
    mobilePageTitle: 'Produto',
  },
  navBar: {
    profile: 'Ver perfil',
    products: 'Meus Produtos',
    editProfile: 'Editar perfil',
    accountVerified: 'Conta verificada',
    verifyAccount: 'Verificar conta',
    bankAccount: 'Conta bancária',
    affiliates: 'Receitas de afiliados',
    tipsAndResources: 'Dicas e recursos',
    faq: 'Ajuda',
    ourBlog: 'Nosso blog',
    logout: 'Sair',
    changeMode: {
      creator: 'Usar Modo Criador',
      member: 'Usar Modo Membro',
    },
  },
  pdf: {
    pagination: 'Página {{pageNumber}} de {{numPages}}',
  },
  dashboard: {
    header: {
      title: 'Área de membros',
      description: 'Compartilhe conteúdo exclusivo para seus membros',
      createSection: 'Criar módulo',
      recomendationInfo:
        'Iremos recomendar que seus novos membros consumam seu conteúdo a partir da primeira seção da página.',
    },
    button: {
      addPost: 'Adicionar conteúdo',
      editList: 'Editar lista',
      title: 'Conteúdo',
    },
    popover: {
      videos: 'Enviar vídeos em massa',
      video: 'Video',
      files: 'Ebook',
      event: 'Lives & Eventos',
      post: 'Publicação',
    },
    labels: {
      draft: 'Rascunho',
    },
    menu: {
      publish: 'Publicar conteúdo',
      edit: 'Editar conteúdo',
      view: 'Abrir conteúdo',
      copyLink: 'Copiar link do conteúdo',
      linkCopied: 'Link copiado para a área de transferência',
      addSectionCover: 'Adicionar capa',
      editSectionCover: 'Editar capa',
      editCover: 'Editar capa',
      remove: 'Deletar',
      moveTo: 'Mover para',
      editSection: 'Editar módulo',
      duplicateSection: 'Duplicar',
      editReleaseDays: 'Agendar liberação',
    },
  },
  content: {
    advantages: {
      posts: 'Envie artigos, vídeos, e-books e gravações de lives',
      customize: 'Personalize a área de membros com o seu branding',
      events: 'Gestão de eventos com convites e notificações automatizadas',
    },
  },
  emptySection: {
    title: 'Ainda não há nenhum',
    subtitle:
      'Aqui você pode adicionar seus conteúdos de vídeo, live, ebook, publicações e ordenar do modo que desejar.',
  },
  mediaUpload: {
    progress: '{{progress}}% Carregados...',
    processing: 'Estamos processando o vídeo ...',
    progressWarning: 'Vídeo em carregamento',
    loadingThumbnail: 'Gerando thumbnail...',
    waitLabel: 'Aguarde...',
    success: 'Carregado!',
    removeAsset: 'Remover',
    cancelRequest: 'Cancelar',
    header: {
      create: 'Novo conteúdo',
      update: 'Editar conteúdo',
      save: 'Salvar',
      saved: 'Salvo!',
      published: 'Publicado',
      next: 'Publicar',
    },
    modal: {
      delete: {
        title: 'Remover aquivo',
        description: 'Você poderá adicionar um outro arquivo novamente.',
        btnNext: 'Remover',
        btnPrev: 'Manter',
      },
      cancel: {
        title: 'Cancelar carregamento',
        description: 'O arquivo será removido automaticamente mas você poderá adicionar outro novamente.',
        btnNext: 'Cancelar',
        btnPrev: 'Manter o carregamento',
      },
    },
    error: {
      default: 'Falha ao enviar o arquivo',
      image: 'Falha ao enviar a imagem',
      video: 'Falha ao enviar o vídeo',
      pdf: 'Falha ao enviar o pdf',
      media: 'Falha ao enviar mídia',
      size: 'Arquivo muito grande, selecione arquivos abaixo de 256 GB',
      tryAgain: 'Tente novamente',
      remove: 'Falha ao remover arquivo',
    },
  },
  mediaLoad: {
    error: {
      pdf: 'Falha ao carregar o pdf.',
    },
  },
  customCoverUpload: {
    post: {
      title: 'Capa do conteúdo',
      description:
        'Adicione uma imagem que mostre o que há no seu conteúdo. Uma boa capa se destaca e chama a atenção dos seus membros',
    },
    section: {
      error: 'Erro ao carregar a capa',
      title: 'Capa do módulo',
      description:
        'Adicione uma imagem de capa para representar seu módulo de conteúdo. Uma boa capa se destaca e chama a atenção dos seus membros.',
    },
    title: 'Editar capa',
    subtitle: 'Você pode escolher a capa que quiser para os membros verem seu conteúdo',
    done: 'Concluir',
    sizeTip: 'O tamanho ideal é de 319x498px (9:16)',
    bottomText: 'Envie uma imagem do seu dispositivo para definir a capa do seu conteúdo.',
    cover: 'Capa',
  },
  confirmationModal: {
    section: {
      title: 'Tem certeza que deseja excluir a seção e seus conteúdos?',
      subtitle:
        'Ao excluir esta seção, você apagará os conteúdos dela. Se você deseja mantê-los, recomendamos mover para outra seção antes de excluir esta seção.',
      confirm: 'Excluir seção e conteúdos',
      cancel: 'Cancelar',
    },
    post: {
      title: 'Tem certeza que deseja excluir o conteúdo?',
      subtitle: 'Ao excluir este conteúdo, você apagará todas as midias anexadas. Essa ação não pode ser desfeita.',
      confirm: 'Excluir conteúdo',
      cancel: 'Cancelar',
    },
  },
  duplicateModal: {
    title: 'Duplicar módulo',
    confirm: 'Duplicar',
    cancel: 'Cancelar',
    success: 'Módulo duplicado com sucesso',
    searchPlaceholder: 'Buscar por produto',
    noResults: 'Nenhum resultado',
    error: 'Erro ao duplicar módulo',
  },
  duplicatePostModal: {
    product: {
      title: 'Duplicar conteúdo',
      confirm: 'Duplicar',
      prompt: 'Escolha um produto:',
      noResults: 'Nenhum resultado',
    },
    section: {
      title: 'Duplicar módulo',
      confirm: 'Duplicar',
      prompt: 'Escolha um produto:',
      noResults: 'Nenhum resultado',
    },
    searchPlaceholder: 'Buscar por módulo',
    confirm: 'Duplicar',
    cancel: 'Cancelar',
    success: 'Post duplicado com sucesso',
  },
  editSection: {
    title: 'Editar seção',
    done: 'Salvar',
    editOrder: 'Organizar conteúdos',
    types: {
      text: 'Texto',
      application: 'Pdf',
      video: 'Video',
      image: 'Imagem',
    },
  },
  editReleaseDays: {
    title: 'Agendar liberação do conteúdo',
    subtitle: 'Escolha quanto o membro terá que esperar para ter acesso à esse módulo.',
    subtitleForPost:
      'Escolha quanto o membro terá que esperar para ter acesso à esse conteúdo. Caso o módulo também tenha um prazo de liberação, o do conteúdo prevalece.',
    cancel: 'Cancelar agendamento',
    save: 'Salvar agendamento',
    customValue: 'Outro prazo',
    customValueLabel: 'Dias após compra',
    customValueDescription: 'Max: 99 dias',
    daysAfterPurchase: 'Dias após a compra',
    onSpecificDate: 'Data específica',
    tooltip:
      'Diversos criadores diminuem seus pedidos de reembolso ao agendar a liberação de parte do seu conteúdo após 7 dias.',
  },
  enableModulesHighlightModal: {
    title: 'Deseja ativar o modo de exibição módulo em destaque?',
    description:
      'Ative esse modo para exibir todos os módulos em uma única linha. Você sempre pode alterar essa configuração na página de personalização da área de membros.',
    maybeLater: 'Talvez mais tarde',
    enable: 'Ativar módulos em destaque',
    mockupAlt: 'Exemplo de como ficará a página com os módulos em destaque.',
  },
  emptyState: {
    title: 'Seções',
    subtitle: 'Você pode começar adicionando uma seção para organizar melhor seus conteúdos.',
    button: 'Adicionar módulo',
  },
  assets: {
    title: 'Anexos',
    new: 'Adicionar anexos',
    download: 'Download',
    processing: 'O arquivo ainda está sendo processado',
    error: 'Houve um erro ao processar o arquivo',
    remove: 'Remover',
    canceled: 'cancelado',
    uploadError: 'Erro ao carregar o anexo',
  },
  button: {
    addFile: 'Adicionar mídia',
    save: 'Salvar',
  },
  createSection: {
    title: 'Novo módulo',
    name: 'Nome',
    cohorts: 'Acessos',
    shouldHaveCohort: 'Selecione pelo menos um acesso',
    options: 'Opções',
    disclaimer:
      'Diversos criadores diminuem seus pedidos de reembolso ao agendar a liberação de parte do seu conteúdo após 7 dias.',
    scheduleRelease: 'Agendar liberação',
    releaseType: 'Agendar para',
    releaseAfterDays: 'Dias após a compra',
    releaseAtDate: 'Data específica',
    customValue: 'Outro prazo',
    customValueLabel: 'Dias',
    confirm: 'Criar módulo',
    cancel: 'Cancelar',
  },
  saveSection: {
    title: 'Editar módulo',
    name: 'Nome',
    cohorts: 'Acessos',
    shouldHaveCohort: 'Selecione pelo menos um acesso',
    confirm: 'Salvar módulo',
    cancel: 'Cancelar',
  },
}
