import { useSaveAssetProgress } from '@hub-la/fe-asset'
import { useContainer } from '@hub-la/fe-container'
import { Analytics } from '@hub-la/fe-core-analytics'
import { Button, Skeleton } from '@hub-la/shadcn'
import { ArrowLeft, ArrowRight, Fullscreen } from 'lucide-react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Document, Page, pdfjs } from 'react-pdf'
import { Events } from '../../../domain/dtos'
import { useGetSectionProgress } from '../../hooks/use-get-section-progress'
import { useRealtimeSections } from '../../hooks/use-realtime-sections'

export interface Props {
  url?: string
  file?: File | null
  readonly?: boolean
  assetId: string
  progress?: number
  sectionId?: string
  postId?: string
}

export const MediaDocumentPDF = ({ url, assetId, progress, file, readonly, sectionId, postId }: Props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  const { t } = useTranslation()
  const container = useContainer()
  const analytics = container.get(Analytics)
  const { dispatch, setRealtimePostProgress } = useRealtimeSections()
  const { mutateAsync: getSectionProgress } = useGetSectionProgress()

  const [numPages, setNumPages] = useState<number>(1)
  const [pageNumber, setPageNumber] = useState<number>(progress ?? 1)
  const saveAssetProgress = useSaveAssetProgress()

  const setPdfProgress = useCallback(
    (currentPage: number, totalPage: number) => {
      setRealtimePostProgress(currentPage)

      dispatch({ type: 'update', sectionId, postId, currentTime: currentPage })
      getSectionProgress(sectionId).then((sectionProgress) =>
        dispatch({ type: 'update', sectionId, postId, currentTime: currentPage, sectionProgress }),
      )

      saveAssetProgress
        .mutateAsync({
          assetId,
          total: totalPage,
          currentProgress: currentPage,
        })
        .catch((error) => {
          console.error(error)
          analytics.track(Events.HUB.PROGRESS.SAVE_FAILED, {
            assetId,
            type: 'application',
            userProgress: progress ?? 0,
            current: currentPage,
            total: totalPage,
          })
        })
    },
    [analytics, assetId, progress, saveAssetProgress],
  )

  const changePage = useCallback(
    (offset) => {
      setPageNumber((prevPageNumber) => {
        const nextPage = (prevPageNumber || 1) + offset

        if (prevPageNumber === 1) {
          analytics.track(Events.HUB.PROGRESS.STARTED, {
            assetId,
            type: 'application',
            userProgress: progress ?? 0,
            current: pageNumber + 1,
            total: numPages,
          })
        } else if (numPages === nextPage) {
          analytics.track(Events.HUB.PROGRESS.ENDED, {
            assetId,
            type: 'application',
            userProgress: progress ?? 0,
            current: pageNumber + 1,
            total: numPages,
          })
        }
        return nextPage
      })
    },
    [numPages, analytics, assetId, progress, pageNumber],
  )

  const previousPage = useCallback(() => {
    changePage(-1)
    setPdfProgress(pageNumber - 1, numPages)
  }, [changePage, numPages, pageNumber, setPdfProgress])

  const nextPage = () => {
    changePage(1)
    setPdfProgress(pageNumber + 1, numPages)
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
    setPageNumber(progress ?? 1)

    if (!readonly) {
      setPdfProgress(pageNumber, numPages)
    }
  }

  const onFullscreenClick = () => {
    analytics.track(Events.HUB.POST_PDF_FULLSCREEN_CLICKED, { assetId })
    window.open(`${url}#toolbar=0&page=${pageNumber}`)
  }

  if (readonly) {
    return (
      <Document
        className="w-full h-full flex"
        file={url ?? file}
        error={t(`mediaLoad.error.pdf`)}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<Skeleton />}
      >
        <Page
          className="flex items-center justify-center"
          style={{ maxHeight: 'calc(100vh-4rem)' }}
          pageNumber={1}
          renderAnnotationLayer={false}
        />
      </Document>
    )
  }

  const isForwardDisabled = pageNumber >= numPages

  return (
    <div className="w-full flex flex-col gap-2">
      <Document
        className="w-full max-w-full h-[640px] max-h-full"
        file={url ?? file}
        error={t(`mediaLoad.error.pdf`)}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<Skeleton />}
      >
        <Page
          pageNumber={pageNumber}
          renderAnnotationLayer={false}
          className="w-full h-full flex items-center justify-center overflow-auto"
        />
      </Document>

      <div className="rounded-md flex gap-2 items-center bg-zinc-900">
        <Button
          size="icon"
          variant="ghost"
          disabled={pageNumber <= 1}
          onClick={pageNumber <= 1 ? undefined : previousPage}
          data-testid="arrow-back"
        >
          <ArrowLeft className="w-5 h-5" />
        </Button>

        <Button
          size="icon"
          variant="ghost"
          onClick={isForwardDisabled ? undefined : nextPage}
          data-testid="arrow-forward"
        >
          <ArrowRight className="w-5 h-5" />
        </Button>

        <p className="flex-1 font-normal size-sm">{t('pdf.pagination', { pageNumber, numPages })}</p>

        <Button size="icon" variant="ghost" onClick={onFullscreenClick} data-testid="fullscreen-button">
          <Fullscreen className="w-5 h-5" />
        </Button>
      </div>
    </div>
  )
}
