import { Badge, cn } from '@hub-la/shadcn'
import { Minus, TrendingDown, TrendingUp } from 'lucide-react'
import React from 'react'

type StatusBoxProps = {
  description: string
  status?: 'positive' | 'negative' | 'neutral'
  indicator?: 'up' | 'down'
}

const getStatusColor = (status: string) => {
  switch (status) {
    case 'positive':
      return 'bg-green-50 !hover:bg-green-50/80 text-green-700 dark:bg-green-900 dark:text-green-100'
    case 'negative':
      return 'bg-orange-50 !hover:bg-orange-50/80 text-orange-600 dark:bg-red-900 dark:text-red-100'
    default:
      return 'bg-gray-100 !focus:bg-gray-100/80 text-gray-800 dark:bg-gray-700 dark:text-gray-100'
  }
}

const getIcon = (status: string, indicator?: string) => {
  switch (status) {
    case 'positive':
      return indicator === 'down' ? <TrendingDown className="w-4 h-4 mr-1" /> : <TrendingUp className="w-4 h-4 mr-1" />
    case 'negative':
      return indicator === 'up' ? <TrendingUp className="w-4 h-4 mr-1" /> : <TrendingDown className="w-4 h-4 mr-1" />
    default:
      return <Minus className="w-4 h-4 mr-1" />
  }
}

export const StatusBox: React.FC<StatusBoxProps> = ({ indicator, status = 'neutral', description }) => {
  const [firstPart, ...rest] = description.split('vs.')
  const secondPart = rest.join('vs.')

  return (
    <div className="flex gap-1">
      <Badge
        variant="secondary"
        className={cn(
          'flex items-center px-1 py-0.5 font-semibold tracking-normal text-sm font-sm',
          getStatusColor(status),
        )}
      >
        <span className="flex items-center">
          {getIcon(status, indicator)}
          {firstPart.trim()}
        </span>
      </Badge>
      <span className="-tracking-normal flex text-sm font-normal items-center text-muted-foreground">
        {secondPart ? `vs. ${secondPart.trim()}` : ''}
      </span>
    </div>
  )
}
