import { Input } from '@hub-la/shadcn'
import { Search } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { SelectDateRangeCalendar } from '../../components/select-date-range-calendar'
import { SelectOffers } from '../../components/select-offers'
import { SelectPaymentMethod } from '../../components/select-payment-method'
import { SelectType } from '../../components/select-type'
import { SelectUtm } from '../../components/select-utm'
import { ConfirmExportModal } from './confirm-export-modal'
import { FilterDrawerDetails } from './filter-drawer-details'
import { SelectStatus } from './select-status'

export const Filters = ({ setFilters, filters, offers }) => {
  const { t } = useTranslation()

  return (
    <div className="flex justify-between">
      <div className="hidden md:flex flex-wrap gap-2">
        <div className="relative h-10">
          <Search className="absolute w-4 h-4 right-3 top-1/2 transform -translate-y-1/2 text-muted-foreground z-10" />
          <Input
            type="text"
            placeholder="Buscar..."
            className="w-[250px]"
            value={filters.search}
            onChange={(e) => setFilters({ ...filters, search: e.target.value })}
          />
        </div>
        <SelectOffers
          offers={offers}
          value={filters.offerIds}
          onChange={(value) => setFilters((prevState) => ({ ...prevState, offerIds: value }))}
        />
        <SelectDateRangeCalendar
          placeholder={t('')}
          value={filters}
          onChange={(value) => setFilters((prevState) => ({ ...prevState, ...value }))}
        />
        <SelectStatus
          value={filters.statuses}
          onChange={(value) => setFilters((prevState) => ({ ...prevState, statuses: value }))}
        />
        <SelectPaymentMethod filters={filters} setFilters={setFilters} />
        <SelectType filters={filters} setFilters={setFilters} />
        <SelectUtm filters={filters} setFilters={setFilters} />
      </div>
      <div className="flex gap-2 ">
        <div className="md:hidden">
          <FilterDrawerDetails filters={filters} setFilters={setFilters} offers={offers} />
        </div>
        <ConfirmExportModal filters={filters} offers={offers} />
      </div>
    </div>
  )
}
