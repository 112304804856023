import {
  Badge,
  Button,
  Calendar,
  cn,
  Input,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
  RadioGroup,
  RadioGroupItem,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  Switch,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@hub-la/shadcn'
import { format } from 'date-fns'
import { useFormik } from 'formik'
import { ArrowLeft, CalendarIcon, Lightbulb } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useGetCohorts } from '../../hooks/use-get-cohorts'
import { useSaveSection } from '../../hooks/use-save-section'

export type Values = {
  name: string
  releaseAfterDays?: number | null
  releaseAt?: Date | null
  cohortIds: string[]
}

type Props = {
  isOpen: boolean
  onClose: () => void
}

const DEFAULT_VALUES = [2, 4, 7]
const CUSTOM_VALUE = -1

export const CreateSectionModal: React.FC<Props> = (props) => {
  const { isOpen, onClose } = props
  const [scheduleRelease, setScheduleRelease] = useState<boolean>(false)
  const [releaseType, setReleaseType] = useState<'releaseAfterDays' | 'releaseAtDate'>('releaseAfterDays')
  const [customValue, setCustomValue] = useState<number>()
  const { productId } = useParams<{ productId: string }>()
  const { mutateAsync: createSection, isLoading } = useSaveSection()
  const { data: cohorts = [], isSuccess } = useGetCohorts(productId)
  const { t } = useTranslation()

  const { values, isValid, submitForm, handleChange, setFieldValue, resetForm, errors } = useFormik<Values>({
    initialValues: {
      name: '',
      releaseAfterDays: 7,
      cohortIds: [],
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(),
      cohortIds: Yup.array().min(1).required(),
    }),
    validateOnChange: true,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      await createSection({
        payload: {
          name: values.name,
          cohortIds: values.cohortIds,
          releaseAfterDays:
            scheduleRelease && releaseType === 'releaseAfterDays'
              ? values.releaseAfterDays === CUSTOM_VALUE
                ? customValue
                : values.releaseAfterDays
              : null,
          releaseAt: scheduleRelease && releaseType === 'releaseAtDate' ? values.releaseAt : null,
          productId,
        },
      }).then(() => {
        resetForm()
        onClose()
      })
    },
  })

  /** Reset form and states */
  const onCloseModal = () => {
    resetForm()
    onClose()
  }

  const handleCustomValue = (event) => {
    const inputValue = event.target.value
    // Replace any non-digit characters with an empty string
    const sanitizedValue = inputValue.replace(/\D/g, '')
    // Ensure the value is within the range of 1 to 99
    const integerValue = Math.min(Math.max(parseInt(sanitizedValue, 10), 1), 99)
    setCustomValue(isNaN(integerValue) ? undefined : integerValue)
  }

  const updateCohortIds = (id: string, shouldAdd: boolean): void => {
    // It should have at least one cohort
    if (values.cohortIds.length <= 1 && !shouldAdd) return

    const newCohortIds = [...values.cohortIds]
    if (shouldAdd) {
      // Add the id if it's not already in the list
      if (!values.cohortIds.includes(id)) {
        setFieldValue('cohortIds', newCohortIds.concat([id]))
      }
    } else {
      // Remove the id if it exists in the list
      setFieldValue(
        'cohortIds',
        values.cohortIds.filter((cohortId) => cohortId !== id),
      )
    }
  }

  useEffect(() => {
    const defaultCohort = cohorts.find((cohort) => cohort.isDefault)
    if (defaultCohort) {
      setFieldValue('cohortIds', [defaultCohort.id])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isOpen])

  return (
    <Sheet open={isOpen} onOpenChange={onCloseModal}>
      <SheetContent className="sm:max-w-lg flex flex-col gap-6 overflow-y-auto">
        <SheetHeader>
          <SheetTitle className="flex gap-2 items-center">
            <ArrowLeft className="text-[#161F00] cursor-pointer" onClick={onClose} />

            {t('createSection.title')}
          </SheetTitle>
        </SheetHeader>

        <div className="flex flex-col gap-4">
          <Input
            id="name"
            name="name"
            type="text"
            placeholder={t('createSection.name')}
            value={values.name}
            onChange={handleChange}
          />

          <h4 className="text-lg font-semibold">{t('createSection.cohorts')}</h4>

          {cohorts.map((cohort) => {
            const checked = values.cohortIds.includes(cohort.id)
            return (
              <div key={cohort.id} className="flex items-center justify-between">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div className="flex items-center space-x-2">
                        <Switch
                          checked={checked}
                          onCheckedChange={(checked) => updateCohortIds(cohort.id, checked)}
                          id={`cohort-${cohort.id}`}
                        />

                        <Label htmlFor={`cohort-${cohort.id}`}>{cohort.name}</Label>
                      </div>
                    </TooltipTrigger>

                    {values.cohortIds.length <= 1 && checked && (
                      <TooltipContent>
                        <p>{t('createSection.shouldHaveCohort')}</p>
                      </TooltipContent>
                    )}
                  </Tooltip>
                </TooltipProvider>

                {cohort.isDefault && <Badge variant="secondary">Padrão</Badge>}
              </div>
            )
          })}

          {Boolean(errors?.cohortIds) && <p className="text-sm text-red-500">{t('createSection.shouldHaveCohort')}</p>}

          <h4 className="text-lg font-semibold">{t('createSection.options')}</h4>

          <div className="flex items-center space-x-2">
            <Switch
              id="schedule-release"
              checked={scheduleRelease}
              onCheckedChange={setScheduleRelease}
              disabled={isLoading}
            />

            <Label htmlFor="schedule-release">{t('createSection.scheduleRelease')}</Label>
          </div>
        </div>

        {scheduleRelease && (
          <div className="flex flex-col gap-3 pt-3 pl-12">
            <div className="p-4 mb-3 flex items-center bg-blue-100 border-l-4 border-blue-500 text-blue-700">
              <Lightbulb className="h-5 w-5 mr-2" />

              <p className="text-sm">{t('createSection.disclaimer')}</p>
            </div>

            <Select
              onValueChange={(value) => setReleaseType(value as 'releaseAfterDays' | 'releaseAtDate')}
              value={releaseType}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder={t('createSection.releaseType')} />
              </SelectTrigger>

              <SelectContent className="z-[61]">
                <SelectItem value="releaseAfterDays">{t('createSection.releaseAfterDays')}</SelectItem>
                <SelectItem value="releaseAtDate">{t('createSection.releaseAtDate')}</SelectItem>
              </SelectContent>
            </Select>

            {releaseType === 'releaseAtDate' ? (
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant={'outline'}
                    className={cn(
                      'w-full justify-start text-left font-normal',
                      !values.releaseAt && 'text-muted-foreground',
                    )}
                  >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {values.releaseAt ? format(values.releaseAt as Date, 'PPP') : <span>Selecione uma data</span>}
                  </Button>
                </PopoverTrigger>

                <PopoverContent className="z-10002 w-auto p-0">
                  <Calendar
                    mode="single"
                    selected={values.releaseAt || undefined}
                    onSelect={(date: Date | undefined) => setFieldValue('releaseAt', date || null)}
                  />
                </PopoverContent>
              </Popover>
            ) : (
              <>
                <RadioGroup
                  value={String(values.releaseAfterDays)}
                  onValueChange={(value) => setFieldValue('releaseAfterDays', parseInt(value, 10))}
                >
                  {DEFAULT_VALUES.map((value) => (
                    <div key={value} className="flex items-center space-x-2">
                      <RadioGroupItem value={String(value)} id={`release-${value}`} />

                      <Label htmlFor={`release-${value}`}>{`${value} dias`}</Label>
                    </div>
                  ))}
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value={String(CUSTOM_VALUE)} id="release-custom" />

                    <Label htmlFor="release-custom">{t('createSection.customValue')}</Label>
                  </div>
                </RadioGroup>

                {values.releaseAfterDays === CUSTOM_VALUE && (
                  <Input
                    placeholder={t('createSection.customValueLabel')}
                    value={customValue}
                    onChange={handleCustomValue}
                  />
                )}
              </>
            )}
          </div>
        )}

        <div className="flex flex-col gap-3 pt-3">
          <Button onClick={submitForm} disabled={!isValid || isLoading}>
            {t('createSection.confirm')}
          </Button>

          <Button variant="outline" onClick={onCloseModal} disabled={isLoading}>
            {t('createSection.cancel')}
          </Button>
        </div>
      </SheetContent>
    </Sheet>
  )
}
