import {
  Button,
  cn,
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ScrollArea,
} from '@hub-la/shadcn'
import { CheckIcon, ChevronDown, ChevronRight, Package, Shapes } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Offer } from '../../domain/dtos/offer'

type Props = {
  offers: Offer[]
  filters: any
  setFilters: (filters: any) => void
}

export const AutocompleteOffers: React.FC<Props> = ({ offers, filters, setFilters }) => {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)

  const selectedOffers = offers.filter((offer) => filters.offerIds.includes(offer.id))

  const handleItemChange = (itemId: string, isSingleOffer = false) => {
    let newOfferIds: string[]
    if (isSingleOffer) {
      newOfferIds = filters.offerIds.includes(itemId)
        ? filters.offerIds.filter((id) => id !== itemId)
        : [...filters.offerIds, itemId]
    } else {
      const productOffers = groupedOffers[itemId].offers
      const allProductOfferIds = productOffers.map((o) => o.id)
      const allSelected = allProductOfferIds.every((id) => filters.offerIds.includes(id))

      if (allSelected) {
        newOfferIds = filters.offerIds.filter((id) => !allProductOfferIds.includes(id))
      } else {
        newOfferIds = [...new Set([...filters.offerIds, ...allProductOfferIds])]
      }
    }

    setFilters({ ...filters, offerIds: newOfferIds })
  }

  const countUniqueProducts = () => {
    const uniqueProductIds = new Set()
    offers.forEach((offer) => uniqueProductIds.add(offer.product?.id))
    return uniqueProductIds.size
  }

  const groupedOffers = offers.reduce((acc, offer) => {
    const productId = offer?.product?.id ?? ''
    if (!acc[productId]) {
      acc[productId] = {
        product: offer.product,
        offers: [],
      }
    }
    acc[productId].offers.push(offer)
    return acc
  }, {} as Record<string, { product: Offer['product']; offers: Offer[] }>)

  const isItemSelected = (itemId: string, isSingleOffer = false) => {
    if (isSingleOffer) {
      return filters.offerIds.includes(itemId)
    }
    const productOffers = groupedOffers[itemId].offers
    return productOffers.every((offer) => filters.offerIds.includes(offer.id))
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline" role="combobox" aria-expanded={open} className="w-full justify-between">
          {selectedOffers.length === 0
            ? t('Produtos')
            : selectedOffers.length === offers.length
            ? `Todos os produtos (${countUniqueProducts()})`
            : `${selectedOffers.length} produto${selectedOffers.length > 1 ? 's' : ''} selecionado${
                selectedOffers.length > 1 ? 's' : ''
              }`}
          <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[350px] p-0 z-10002" align="start">
        <Command className="border-none">
          <CommandInput placeholder="Buscar produtos..." className="border-b border-gray-200" />
          <div className="max-h-[300px] overflow-y-auto">
            <CommandList>
              <ScrollArea>
                {Object.entries(groupedOffers).map(([productId, { product, offers }]) => (
                  <CommandGroup key={productId}>
                    {offers.length === 1 ? (
                      <CommandItem onSelect={() => handleItemChange(offers[0].id, true)} className="px-2 py-1.5">
                        <div className="flex items-center w-full">
                          <div
                            className={cn(
                              'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                              isItemSelected(offers[0].id, true)
                                ? 'bg-primary text-primary-foreground'
                                : 'opacity-50 [&_svg]:invisible',
                            )}
                          >
                            <CheckIcon className={cn('h-4 w-4')} />
                          </div>
                          <Shapes className="mr-2 h-4 w-4 text-gray-500" />
                          <span className="flex-grow truncate font-medium text-black">{product?.name}</span>
                        </div>
                      </CommandItem>
                    ) : (
                      <>
                        <CommandItem onSelect={() => handleItemChange(productId)} className="px-2 py-1.5">
                          <div className="flex items-center w-full">
                            <div
                              className={cn(
                                'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                                isItemSelected(productId)
                                  ? 'bg-primary text-primary-foreground'
                                  : 'opacity-50 [&_svg]:invisible',
                              )}
                            >
                              <CheckIcon className={cn('h-4 w-4')} />
                            </div>
                            <Shapes className="mr-2 h-4 w-4 text-gray-500" />
                            <span className="inline-flex truncate font-medium text-black">{product?.name}</span>
                            <ChevronRight className="ml-1 h-4 w-4 text-gray-500" />
                            <span className="ml-1 text-sm text-gray-500">{offers.length} ofertas</span>
                          </div>
                        </CommandItem>
                        {offers.map((offer) => (
                          <CommandItem
                            key={offer.id}
                            onSelect={() => handleItemChange(offer.id, true)}
                            className="px-2 py-1.5 pl-8"
                          >
                            <div className="flex items-center w-full">
                              <div
                                className={cn(
                                  'mr-2 inline-flex h-4 items-center justify-center rounded-sm border border-primary',
                                  filters.offerIds.includes(offer.id)
                                    ? 'bg-primary text-primary-foreground'
                                    : 'opacity-50 [&_svg]:invisible',
                                )}
                              >
                                <CheckIcon className={cn('h-4 w-4')} />
                              </div>
                              <Package className="mr-2 h-4 w-4 text-gray-500" />
                              <span className="flex-grow truncate text-black">{offer.name}</span>
                            </div>
                          </CommandItem>
                        ))}
                      </>
                    )}
                  </CommandGroup>
                ))}
              </ScrollArea>
            </CommandList>
          </div>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
