import { Card, CardContent, CardHeader, CardTitle, Label, Separator } from '@hub-la/shadcn'
import { memo } from 'react'
import { useGetRefund } from '../../hooks/use-get-refund'
import { Details } from './details'
import { Header } from './header'
import { Loading } from './loading'
import { ProductImage } from './product-image'

type RefundDetailProps = {
  id: string
  onClose: () => void
}

export const RefundDetail = memo(({ id, onClose }: RefundDetailProps) => {
  const { data: refund } = useGetRefund(id)

  if (!refund) {
    return <Loading />
  }

  return (
    <div className="flex flex-col space-y-6">
      <Header
        status={refund.status}
        id={refund.id}
        onClose={onClose}
        paidAt={refund.transaction.paidAt}
        createdAt={refund.createdAt}
        amount={refund.amount}
        name={refund.to.email ?? refund.to.phoneNumber ?? ''}
      />
      <Separator />

      <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
        <div className="mb-auto flex flex-col gap-1 col-span-1 lg:col-span-6">
          <Label className="text-base font-medium">Motivo</Label>
          <Card>
            <CardContent className="pt-6">
              <div className="flex flex-col w-full gap-2">{refund.description}</div>
            </CardContent>
          </Card>
        </div>

        <div className="col-span-1 lg:col-span-6">
          <Details
            transactionId={refund.transaction.id}
            email={refund.to.email}
            phoneNumber={refund.to.phoneNumber}
            paymentMethod={refund.transaction.paymentMethod}
            createdAt={refund.createdAt}
            paidAt={refund.transaction.paidAt}
            isRenewing={refund.transaction.isRenewing}
          />

          <div className="mt-6">
            {refund.transaction.item && (
              <Card>
                <CardHeader>
                  <CardTitle className="text-2xl font-semibold">Produto</CardTitle>
                </CardHeader>
                <Separator />
                <CardContent className="pt-4 space-y-4">
                  <div className="rounded-lg">
                    <div className="flex flex-row gap-4">
                      <ProductImage url={refund.transaction.item.picture} />
                      <div className="flex flex-col gap-1">
                        <span className="text-base">{refund.transaction.item.name}</span>
                        <span className="text-sm text-muted-foreground">{refund.transaction.item.id}</span>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            )}
          </div>
        </div>
      </div>
    </div>
  )
})
