import { ArrowDownRight, ArrowUpLeft } from 'lucide-react'
import React from 'react'

type MovementStatusProps = {
  amountInCents: number
  // transactionType: string
}

const getStyles = (color: string) => {
  return { size: 16, className: `text-${color}-700 w-5 h-5` }
}

const getIcon = (amountInCents: number) => {
  return amountInCents >= 0 ? <ArrowDownRight {...getStyles('green')} /> : <ArrowUpLeft {...getStyles('red')} />
}

/**
 * Each transaction type has a different status symbol.
 * Red: to represent a bad movement, generally related to money going out the account
 * Orange: to represent a indefined movment, could become a bad or good movement
 * Green: to represent a good movement, generally related to money coming in
 */
export const MovementStatus: React.FC<MovementStatusProps> = ({ amountInCents }) => {
  return getIcon(amountInCents)
}
