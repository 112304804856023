import { useAuth } from '@hub-la/fe-auth'
import { useContainer } from '@hub-la/fe-container'
import { Analytics, AnalyticsEvent } from '@hub-la/fe-core-analytics'
import { Button } from '@hub-la/shadcn'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { UserVerificationTelegramStep } from '../../../domain/enums/user-verification-telegram-step'
import { makeTrackingEventData } from '../../../domain/mappers/make-tracking-event-data'
import VerificationIntroDeskImage from '../../assets/verification-intro-desk.svg'
import VerificationIntroMobileImage from '../../assets/verification-intro-mobile.svg'
import { LinkHelperFAQ } from '../../components/link-helper-faq'
import { NavigationButton } from '../../components/navigation-button'
import { NotGroupMember } from '../../components/not-group-member'
import { useGenerateTelegramCode } from '../../hooks/use-generate-telegram-code'
import { useGetTelegramCode } from '../../hooks/use-get-telegram-code'
import { useGetUserVerification } from '../../hooks/use-get-user-verification'
import { useUpdateVerificationTelegramStep } from '../../hooks/use-update-verification-telegram-step'

export const VerificationIntroPage = (): JSX.Element => {
  const container = useContainer()
  const analytics = container.get(Analytics)
  const { t } = useTranslation()
  const history = useHistory()

  const { currentUser } = useAuth()
  const { data: user } = useGetUserVerification(currentUser?.id)

  const updateStepAction = useUpdateVerificationTelegramStep()
  const generateAction = useGenerateTelegramCode()
  const { data: telegramCode } = useGetTelegramCode()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const step = user?.steps?.verificationTelegram
  const urlSearch = useLocation().search
  const groupId = new URLSearchParams(urlSearch).get('groupId')

  const trackingParams = {
    groupId: groupId,
    userId: user?.id,
    userEmail: user?.email ?? undefined,
    telegramId: user?.ims?.telegram ?? undefined,
    whatsappId: user?.ims?.whatsapp ?? undefined,
  }

  useEffect(() => {
    if (user) {
      if (step !== UserVerificationTelegramStep.intro) {
        dispatchUpdateStep(UserVerificationTelegramStep.intro)
      }
    }
  }, [])

  const dispatchUpdateStep = useCallback((step: UserVerificationTelegramStep, next?: string) => {
    updateStepAction.mutateAsync(step).then(() => {
      if (next) {
        setIsLoading(false)
        history.push({
          pathname: next,
          search: urlSearch,
        })
      }
    })
  }, [])

  const dispatchGenerateCode = useCallback(() => {
    generateAction.mutateAsync(groupId ?? undefined).then(() => {
      dispatchUpdateStep(UserVerificationTelegramStep.onboarding, '/verification-telegram/start')
    })
  }, [])

  const handleClickStart = () => {
    analytics.track(AnalyticsEvent.VERIFICATION_TELEGRAM.INTRO.START_CLICKED, makeTrackingEventData(trackingParams))
    setIsLoading(true)
    if (telegramCode) {
      dispatchUpdateStep(UserVerificationTelegramStep.onboarding, '/verification-telegram/start')
    } else {
      dispatchGenerateCode()
    }
  }

  const handleClickNav = () => {
    analytics.track(AnalyticsEvent.VERIFICATION_TELEGRAM.INTRO.NAV_BACK_CLICKED, makeTrackingEventData(trackingParams))
    history.push('/user_groups')
  }

  const handleClickFAQ = () => {
    analytics.track(AnalyticsEvent.VERIFICATION_TELEGRAM.INTRO.FAQ_CLICKED, makeTrackingEventData(trackingParams))
  }

  return (
    <div className="flex flex-col flex-1 h-screen" data-testid="verification-intro-container">
      <div className="flex flex-col md:flex-row flex-1">
        <div className="flex flex-col flex-1 w-full md:w-1/2 p-4 md:p-6 bg-[#F7F8F2] md:bg-white">
          <div className="flex flex-col items-start justify-center">
            <NavigationButton onClick={handleClickNav} />
          </div>
          <div className="flex flex-col flex-1 items-center justify-center">
            <div className="flex flex-col flex-1 space-y-6 justify-center w-full md:w-[400px]">
              <div className="flex items-center justify-center md:hidden">
                <img
                  height={200}
                  data-testid="img-verification-intro-mobile"
                  src={VerificationIntroMobileImage}
                  alt="verification intro mobile"
                />
              </div>
              <h1 className="text-left text-[#161F00] font-bold text-xl md:text-4xl">
                {t('verificationTelegram.intro.title')}
              </h1>
              <p className="text-left text-[#747968]">{t('verificationTelegram.intro.subtitle')}</p>
              <div className="hidden md:flex flex-col space-y-4 items-start justify-center">
                <Button
                  data-testid="button-start-desktop"
                  variant="default"
                  size="lg"
                  className="w-full font-bold text-base border-[#314000]"
                  disabled={isLoading}
                  onClick={handleClickStart}
                  loading={isLoading}
                >
                  {t('verificationTelegram.intro.button')}
                </Button>
                <LinkHelperFAQ onClick={handleClickFAQ} />
              </div>
            </div>
          </div>

          <div className="flex md:hidden flex-col space-y-4 items-center justify-center mt-6">
            <Button
              data-testid="button-start-mobile"
              variant="default"
              size="lg"
              className="w-full font-bold text-base border-[#314000]"
              disabled={isLoading}
              onClick={handleClickStart}
              loading={isLoading}
            >
              {t('verificationTelegram.intro.button')}
            </Button>
            <LinkHelperFAQ onClick={handleClickFAQ} />
          </div>
        </div>
        <div
          data-testid="img-verification-intro-desktop"
          className="hidden md:flex flex-1 w-full md:w-1/2 bg-[#F7F8F2] bg-no-repeat bg-center bg-[size:120%_auto]"
          style={{
            backgroundImage: `url(${VerificationIntroDeskImage})`,
          }}
        >
          &ensp;
        </div>
      </div>
      {groupId && <NotGroupMember groupId={groupId} />}
    </div>
  )
}
