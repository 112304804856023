import { VerificationStatus } from '@hub-la/fe-get-user'
import { AlertCircle, CheckCircle2, Circle, RotateCcw } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

type StatusTagProps = {
  status?: VerificationStatus
}

export const StatusTag: React.FC<StatusTagProps> = ({ status }) => {
  const { t } = useTranslation()

  const getIconAndColor = () => {
    const iconSize = 16

    switch (status) {
      case VerificationStatus.APPROVED:
        return {
          icon: (
            <CheckCircle2 size={iconSize} className="mr-1 text-green-700" fill="green" style={{ fillOpacity: 0.1 }} />
          ),
          textColor: 'text-green-700',
          bgColor: 'bg-green-100',
        }
      case VerificationStatus.PENDING:
        return {
          icon: <RotateCcw size={iconSize} className="mr-1 text-blue-700" fill="blue" style={{ fillOpacity: 0.1 }} />,
          textColor: 'text-blue-700',
          bgColor: 'bg-blue-100',
        }
      case VerificationStatus.REJECTED:
        return {
          icon: <AlertCircle size={iconSize} className="mr-1 text-red-700" fill="red" style={{ fillOpacity: 0.1 }} />,
          textColor: 'text-red-700',
          bgColor: 'bg-red-100',
        }
      default:
        return {
          icon: <Circle size={iconSize} className="mr-1 text-gray-700" fill="gray" style={{ fillOpacity: 0.1 }} />,
          textColor: 'text-gray-700',
          bgColor: 'bg-gray-100',
        }
    }
  }

  const { icon, textColor, bgColor } = getIconAndColor()

  return (
    <div className={`flex items-center px-2 py-1 rounded-full ${bgColor} ${textColor} text-sm`}>
      {icon}
      {t(`bankAccount.statusTag.${status ?? 'default'}`)}
    </div>
  )
}
