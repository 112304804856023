import { Avatar, AvatarFallback, AvatarImage, Badge, Button, Skeleton } from '@hub-la/shadcn'
import { Eye, EyeOff } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ProductType } from '../../../domain/enums/product-type'
import { Envs } from '../../../envs'
import { useGetProduct } from '../../hooks/use-get-product'
import { ChangeProductStatus } from '../change-product-status'
import { ProductActions } from '../product-actions/product-actions'

interface PageHeaderProps {
  productType: ProductType
}

export const PageHeader: React.FC<PageHeaderProps> = ({ productType }) => {
  const { t } = useTranslation()
  const { productId } = useParams<{ productId: string }>()
  const { data: product } = useGetProduct(productId)

  return (
    <header className="py-6 px-4 lg:px-24 bg-zinc-50 flex flex-col md:flex-row gap-4 md:gap-1 md:items-center md:justify-between">
      <div className="flex flex-col md:flex-row gap-4 items-center">
        <Avatar className="h-20 w-32 rounded-3xl shadow-md">
          <AvatarImage className="object-cover" src={product?.picture} alt={product?.name || ''} />
          <AvatarFallback>
            {product?.name ? product.name.charAt(0) : <Skeleton className="h-full w-full rounded-3xl bg-zinc-100" />}
          </AvatarFallback>
        </Avatar>

        <div className="flex flex-col justify-center items-center lg:items-start lg:justify-start">
          {!product?.name ? (
            <Skeleton className="h-7 w-48" />
          ) : (
            <h2 className="text-2xl font-semibold">{product.name}</h2>
          )}
          {product?.mainOfferId && (
            <p className="text-sm text-muted-foreground break-all">{`${Envs.SITE_URL}/g/${product.mainOfferId}`}</p>
          )}
        </div>
        {product?.isVisible === false && (
          <Badge variant="secondary">
            <EyeOff className="mr-1 h-3 w-3" />
            Hidden
          </Badge>
        )}
      </div>

      <div className="flex flex-col gap-2 sm:flex-row sm:items-center sm:gap-2 sm:gap-0">
        <ChangeProductStatus productId={product?.id} status={product?.status} />

        {product?.mainOfferId && (
          <Button
            variant="outline"
            size="sm"
            className="w-full sm:w-auto"
            onClick={() => window.open(`${Envs.SITE_URL}/g/${product.mainOfferId}`, '_blank')}
          >
            <Eye className="mr-2 h-4 w-4" />
            Ver área de membros
          </Button>
        )}

        {product && <ProductActions productId={product.id} isVisible={product.isVisible} />}
      </div>
    </header>
  )
}
