import { useContainer } from '@hub-la/fe-container'
import { useQuery } from '@tanstack/react-query'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useSnackbar } from '@hub-la/design-system'
import { useTranslation } from 'react-i18next'
import { ListEventTicketsUsecase } from '../../../usecases/tickets/list-event-tickets.usecase'
import { ListEventTicketsInputDto } from '../../../domain/dtos/tickets/list-event-tickets-input.dto'
import { QueryKey } from '../../../domain/enums/query-key'

export const useListEventTickets = (input: ListEventTicketsInputDto) => {
  const { addSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const container = useContainer()

  return useQuery(
    [QueryKey.listEventTickets],
    () => new ListEventTicketsUsecase(container.get(HttpClient)).execute(input),
    {
      enabled: !!input.productId,
      refetchOnWindowFocus: false,
      retry: 3,
      onError: (error: Error) => {
        addSnackbar({ message: t(error.message), variant: 'negative' })
      },
    },
  )
}
