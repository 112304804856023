import { Stack, Text, useUtility } from '@hub-la/design-system'
import { alpha } from '@mui/system'
import moment from 'moment'
import 'moment/locale/pt-br'

type EventDateProps = {
  date: Date
}

/**
 * @deprecated Use `@hub-la/fe-thumbnail` instead
 */
const EventDate = ({ date }: EventDateProps) => {
  const { palette } = useUtility()

  const day = date.getDate()
  const month = moment(date).locale('pt-br').format('MMM').toUpperCase()

  return (
    <Stack
      position="absolute"
      justifyContent="center"
      borderRadius={1}
      p={1}
      left={8}
      top={8}
      width="100%"
      height="100%"
      maxHeight={40}
      maxWidth={40}
      bgcolor={alpha(palette('background'), 0.5)}
      style={{ backdropFilter: 'blur(5px)' }}
      data-testid="event-date"
    >
      <Text variant="body1" fontWeight={700} color="onSurface" textAlign="center" width={32} lineHeight="16px">
        {day}
      </Text>
      <Text variant="body1" color="onSurfaceVariant" textAlign="center" width={32} lineHeight="10px" fontSize={10}>
        {month}
      </Text>
    </Stack>
  )
}

export default EventDate
