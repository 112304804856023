import { Separator } from '@hub-la/shadcn'
import { memo } from 'react'
import { Offer } from '../../../domain/dtos/offer'
import { PaymentMethod } from '../../../domain/enums/payment-method'
import { useGetCoupon } from '../../hooks/use-get-coupon'
import { Description } from './description'
import { Details } from './details'
import { Header } from './header'
import { Loading } from './loading'

type CouponDetailProps = {
  id: string
  onClose: () => void
  /** @todo This is temporary, since this is a modal and we shouldn't refetch offers */
  offers: Offer[]
}

export const CouponDetail = memo(({ id, onClose, offers }: CouponDetailProps) => {
  const { data: coupon } = useGetCoupon(id)

  if (!coupon) {
    return <Loading />
  }

  return (
    <div className="p-6 h-full w-full overflow-auto">
      <div className="space-y-4 mb-8">
        <Header code={coupon.code} status={coupon.state} id={coupon.id} onClose={onClose} offers={offers} />
        <Separator />
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
          <div className="col-span-1 lg:col-span-6 space-y-6">
            <Description
              discount={coupon.discountPercent}
              usagesCount={coupon.usagesCount}
              maxAllowedTotalUsages={coupon.maxAllowedTotalUsages}
              appliableTo={coupon.appliableTo}
              isRecurrent={coupon.isRecurrent}
            />
          </div>

          <div className="col-span-1 lg:col-span-6 space-y-6">
            <Details
              id={coupon.id}
              createdAt={coupon.createdAt}
              validUntil={coupon.validUntil}
              /** For now, every coupon is valid for credit card and pix */
              paymentMethods={[PaymentMethod.CREDIT_CARD, PaymentMethod.PIX, PaymentMethod.BOLETO]}
            />
          </div>
        </div>
      </div>
    </div>
  )
})
