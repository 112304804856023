import { Separator } from '@hub-la/shadcn'
import { FormikProvider, useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import { highlightTypes, Values } from '../../../../domain/dtos/member-area/settings/formik-values'
import { useGetHighlight } from '../../../hooks/member-area/settings/get-highlight'
import { useGetMembersArea } from '../../../hooks/member-area/settings/get-members-area'
import { useSaveMembersArea } from '../../../hooks/member-area/settings/save-members-area'
import { useUpdateHighlight } from '../../../hooks/member-area/settings/update-highlight'
import CustomHighlight from './components/custom-highlight'
import CustomLogo from './components/custom-logo'
import ToggleModulesHighlight from './components/toggle-module-highlight'
import TogglePdfWatermark from './components/toggle-pdf-watermark'
import TogglePostComment from './components/toggle-post-comment'
import ToggleThumbnail from './components/toggle-thumbnail'
import { validationSchema } from './validation-schema'

const CustomMemberArea = () => {
  const { productId } = useParams<{ productId: string }>()
  const updateHighlight = useUpdateHighlight()
  const saveMembersArea = useSaveMembersArea()
  const membersAreaSettings = useGetMembersArea({ productId })

  const { data, isLoading, refetch } = useGetHighlight({ productId })

  const handleUpdateThumbnailTitle = async (checked: boolean) => {
    saveMembersArea.mutateAsync({
      showThumbnailTitle: checked,
      productId,
    })
  }

  const handleUpdateWatermark = async (checked: boolean) => {
    saveMembersArea.mutateAsync({
      pdfWatermark: checked,
      productId,
    })
  }

  const handlePostComment = async (checked: boolean) => {
    saveMembersArea.mutateAsync({
      postComment: checked,
      productId,
    })
  }

  const handleUpdateHighlightModules = async (checked: boolean) => {
    saveMembersArea.mutateAsync({
      modulesHighlight: checked,
      productId,
    })
  }

  const handleUpdateShowLogo = async (checked: boolean) => {
    saveMembersArea.mutateAsync({
      showLogo: checked,
      productId,
    })
  }

  const handleUpdateHighlight = async (values: Values) => {
    await saveMembersArea.mutateAsync({ highlightType: values.highlightType, productId })
    await updateHighlight.mutateAsync({ ...values, productId })
    refetch()
  }

  const formik = useFormik<Omit<Values, 'productId'>>({
    initialValues: {
      highlightType: data?.type ?? highlightTypes.RECOMMENDATION,
      post: data?.post,
      videoAsset: data?.video?.desktop,
      desktopAsset: data?.image?.desktop,
      mobileAsset: data?.image?.mobile,
      logoAsset: membersAreaSettings?.data?.logoAsset,
      postComment: membersAreaSettings?.data?.postComment,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    validateOnChange: false,
    onSubmit: async (values) => {
      await handleUpdateHighlight(values)
    },
  })

  return (
    <FormikProvider value={formik}>
      <div className="flex flex-col flex-1 mb-8" data-testid="product-custom">
        <div className="flex flex-col gap-8">
          <CustomHighlight isLoading={isLoading || saveMembersArea.isLoading || updateHighlight.isLoading} />

          <Separator />

          <CustomLogo
            onChange={(checked) => handleUpdateShowLogo(checked)}
            defaultValue={membersAreaSettings?.data?.showLogo}
          />

          <Separator />

          <ToggleThumbnail
            onChange={(checked) => handleUpdateThumbnailTitle(checked)}
            defaultValue={membersAreaSettings?.data?.showThumbnailTitle}
          />

          <Separator />

          <ToggleModulesHighlight
            onChange={(checked) => handleUpdateHighlightModules(checked)}
            defaultValue={membersAreaSettings?.data?.modulesHighlight}
          />

          <Separator />

          <TogglePostComment
            onChange={(checked) => handlePostComment(checked)}
            defaultValue={membersAreaSettings?.data?.postComment}
          />

          <Separator />

          <TogglePdfWatermark
            onChange={(checked) => handleUpdateWatermark(checked)}
            defaultValue={membersAreaSettings?.data?.pdfWatermark}
          />
        </div>
      </div>
    </FormikProvider>
  )
}

export default CustomMemberArea
