import { container } from '@hub-la/fe-container'
import { TelegramVerificationProvider, VerificationDonePage } from '@hub-la/fe-telegram-verification'

export const TelegramVerificationDoneInit = () => {
  return (
    <TelegramVerificationProvider container={container}>
      <VerificationDonePage />
    </TelegramVerificationProvider>
  )
}
