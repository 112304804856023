import React from 'react'
import { useTranslation } from 'react-i18next'
import { Search } from 'lucide-react'

export const UserDoesNotHaveGroup: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col items-center justify-center w-full h-full py-30">
      <div className="p-2 rounded-md border border-secondary bg-secondary/10">
        <Search />
      </div>
      <h2 className="mt-6 text-2xl font-semibold">{t('empty')}</h2>
    </div>
  )
}
