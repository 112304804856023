import { PermissionResource, useRoleplay } from '@hub-la/fe-roleplay'
import {
  Checkbox,
  Label,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableEmptyState,
  TableHead,
  TableHeader,
  TablePagination,
  TableRow,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@hub-la/shadcn'
import times from 'lodash/times'
import { Info } from 'lucide-react'
import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Member } from '../../../../domain/dtos/member'
import { Phone } from '../../../../domain/vos/phone'
import { DrawerTransformFreeMembers } from '../../../components/drawer-transform-free-members'
import { useGetDeactivatesMembers } from '../../../hooks/members/use-get-deactivates-members'
import { useGetExportDeactivateMembers } from '../../../hooks/members/use-get-export-deactivate-members'
import { Actions } from './actions'

const SkeletonRow = () => (
  <TableRow>
    <TableCell>
      <Skeleton className="h-6 w-[85%]" />
    </TableCell>
    <TableCell>
      <Skeleton className="h-6 w-[85%]" />
    </TableCell>
    <TableCell>
      <Skeleton className="h-6 w-[85%]" />
    </TableCell>
    <TableCell>
      <Skeleton className="h-6 w-[85%]" />
    </TableCell>
    <TableCell>
      <Skeleton className="h-6 w-[85%]" />
    </TableCell>
    <TableCell>
      <Skeleton className="h-6 w-[85%]" />
    </TableCell>
  </TableRow>
)

export const OldMembersTable = () => {
  const { productId } = useParams<{ productId: string }>()
  const { t } = useTranslation()
  const { hasPermission } = useRoleplay()

  const [isCheckAll, setIsCheckAll] = useState<boolean>(false)
  const [selectedItems, setSelectedItems] = useState<Member[]>([])
  const [search, setSearch] = useState<string>('')
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(25)

  const { data: desactivateMembers, isLoading } = useGetDeactivatesMembers({
    productId,
    page,
    pageSize,
    search,
  })

  const handleOnChange = (checked: boolean, value: string): void => {
    if (!desactivateMembers) return

    setIsCheckAll(checked)

    if (value === 'all') {
      setSelectedItems(checked ? desactivateMembers?.items : [])
    } else {
      setSelectedItems((prevSelectedItems) => {
        const selectedMember = desactivateMembers?.items.find((member) => member.id === value)
        if (!selectedMember) return prevSelectedItems
        return checked
          ? [...prevSelectedItems, selectedMember]
          : prevSelectedItems.filter((member) => member.id !== selectedMember.id)
      })
    }
  }

  const canRenderEmptyState = !isLoading && (desactivateMembers?.items ?? []).length <= 0

  const { mutate: exportDeactivateMembers } = useGetExportDeactivateMembers()

  const handleExportDeactivateMembers = () => {
    exportDeactivateMembers({ productId, page: 1, pageSize: 5000, search })
  }

  return (
    <div className="flex flex-col space-y-4">
      <Actions onSearchChange={setSearch} onExportDeactivateMembers={handleExportDeactivateMembers} />
      <div className="border bg-white w-full rounded-lg">
        <Table>
          <TableHeader>
            <TableRow>
              {hasPermission(PermissionResource.PRODUCTS_MANAGE_MEMBERS) && (
                <TableHead>
                  <Checkbox
                    checked={isCheckAll}
                    onCheckedChange={(checked) => handleOnChange(checked as boolean, 'all')}
                  />
                </TableHead>
              )}
              <TableHead>
                <div className="flex items-center space-x-2">
                  <span>{t('tabs.oldMembers.table.member.label')}</span>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <Info className="h-3 w-3" />
                      </TooltipTrigger>
                      <TooltipContent>{t('tabs.oldMembers.table.member.tooltip')}</TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </TableHead>
              <TableHead>
                <span>{t('tabs.oldMembers.table.email.label')}</span>
              </TableHead>
              <TableHead>
                <span>{t('tabs.oldMembers.table.phoneNumber.label')}</span>
              </TableHead>
              <TableHead>
                <div className="flex items-center space-x-2">
                  <span>{t('tabs.oldMembers.table.sinceAt.label')}</span>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <Info className="h-3 w-3" />
                      </TooltipTrigger>
                      <TooltipContent>{t('tabs.oldMembers.table.sinceAt.tooltip')}</TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isLoading && times(3, (idx) => <SkeletonRow key={`skeleton-row-${idx}`} />)}
            {!isLoading &&
              (desactivateMembers?.items ?? []).map((member) => (
                <TableRow key={member.id}>
                  {hasPermission(PermissionResource.PRODUCTS_MANAGE_MEMBERS) && (
                    <TableCell className="py-2">
                      <Checkbox
                        checked={selectedItems.some((selectedMember) => selectedMember.id === member.id)}
                        onCheckedChange={(checked) => handleOnChange(checked as boolean, member.id)}
                      />
                    </TableCell>
                  )}
                  <TableCell className="py-2 max-w-[180]">
                    <div className="max-w-[150px]">
                      <Label className="text-sm font-medium truncate block">{member?.name}</Label>
                      <p className="text-xs text-muted-foreground truncate">{member?.id}</p>
                    </div>
                  </TableCell>
                  <TableCell className="py-2 max-w-[200]">
                    <span className="truncate block" title={member?.email ?? '–'}>
                      {member?.email ?? '–'}
                    </span>
                  </TableCell>
                  <TableCell className="py-2">
                    {member?.phoneNumber ? Phone.build(member?.phoneNumber).getFormattedNumber() : '–'}
                  </TableCell>
                  <TableCell className="py-2">
                    {member?.removalAt ? moment(member?.removalAt).format('D MMM. YYYY') : '-'}
                  </TableCell>
                </TableRow>
              ))}
            {canRenderEmptyState && (
              <TableRow>
                <TableCell colSpan={6} className="w-full">
                  <TableEmptyState title={t('emptyMembers')} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      <div className="flex justify-end px-2 mt-3 mb-3">
        <TablePagination
          page={page ?? 1}
          setPage={setPage}
          lastPage={desactivateMembers?.lastPage ?? 1}
          pageSize={pageSize ?? 25}
          setPageSize={setPageSize}
        />
      </div>

      {hasPermission(PermissionResource.PRODUCTS_MANAGE_MEMBERS) && (
        <DrawerTransformFreeMembers
          selectedItems={selectedItems}
          productId={productId}
          onCancel={() => {
            setIsCheckAll(false)
            setSelectedItems([])
          }}
          onSuccess={() => {
            setIsCheckAll(false)
            setSelectedItems([])
          }}
        />
      )}
    </div>
  )
}
