import { CopyClipboard } from '@hub-la/fe-copy-clipboard'
import { Button, toast } from '@hub-la/shadcn'
import { Copy } from 'lucide-react'
import { useTranslation } from 'react-i18next'

type CopyClipboardTagProps = {
  value: string
  successMessage: string
  tooltip: string
}

export const CopyClipboardTag = ({ value, successMessage, tooltip }: CopyClipboardTagProps) => {
  const { t } = useTranslation()

  const handleCopyClipboard = (content: string) =>
    new CopyClipboard().execute(content).then(() => {
      toast({
        title: t('contentCopiedSuccessfully'),
        description: successMessage,
      })
    })

  return (
    <Button
      variant="outline"
      className="h-6 text-sm text-muted-foreground"
      content={value}
      size="sm"
      onClick={(e) => {
        e.preventDefault()
        handleCopyClipboard(value)
      }}
    >
      {value}
      <Copy className="ml-3 h-4 w-4" />
    </Button>
  )
}
