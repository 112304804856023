type CardRowProps = {
  title: string
  content: React.ReactElement
}

export const CardRow = ({ title, content }: CardRowProps) => {
  return (
    <div className="flex justify-between items-start">
      <div className="w-48 pr-2">
        <p className="text-base text-muted-foreground">{title}</p>
      </div>
      <div className="pl-2 max-w-sm">
        <p className={`text-base`}>{content || '–'}</p>
      </div>
    </div>
  )
}
