import React, { useState } from 'react'
import { DesktopPreview } from './desktop-preview'
import { MobilePreview } from './mobile-preview'
import { SelectView, View } from './select-view'

interface PreviewCheckoutProps {
  group: {
    picture?: string | null
    name: string
    owner: string
  }
}

export const PreviewCheckout: React.FC<PreviewCheckoutProps> = ({ group }) => {
  const [view, setView] = useState<View>(View.Mobile)

  return (
    <div className="w-full flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <SelectView onChange={setView} value={view} />
      </div>
      <div className="flex justify-center">
        {view === View.Mobile && <MobilePreview group={group} />}
        {view === View.Desktop && <DesktopPreview group={group} />}
      </div>
    </div>
  )
}
