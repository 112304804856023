import { container } from '@hub-la/fe-container'
import { UserInvoicesDownloadPage, UserInvoicesProvider } from '@hub-la/fe-user-invoices'

export const UserInvoicesDownloadInit = () => {
  return (
    <UserInvoicesProvider container={container}>
      <UserInvoicesDownloadPage />
    </UserInvoicesProvider>
  )
}
