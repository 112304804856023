import { UpdateProductSettingsTrackersRequestDto } from '@hub-la/sdk-bff-product'
import { Card, CardContent, Input, Label, Switch, Textarea } from '@hub-la/shadcn'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { Section } from '../../../components/section'
import { useGetSettings } from '../../../hooks/settings/use-get-settings'
import { useSaveSettings } from '../../../hooks/settings/use-save-settings'

type Values = Pick<UpdateProductSettingsTrackersRequestDto, 'facebook'>
const validationSchema = Yup.object().shape({})
const makeInitialValues = ({ facebook }: Values): Values => {
  return {
    facebook: {
      id: facebook?.id ?? '',
      accessToken: facebook?.accessToken ?? '',
      domain: facebook?.domain ?? '',
    },
  }
}

export const Facebook = () => {
  const [verifyDomain, setVerifyDomain] = useState<boolean>(false)
  const { productId } = useParams<{ productId: string }>()
  const { mutateAsync: saveSettings } = useSaveSettings()
  const { data, isLoading } = useGetSettings({ productId, type: 'trackers' })
  const { t } = useTranslation()

  const formik = useFormik<Values>({
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: makeInitialValues({
      facebook: data?.facebook,
    }),
    onSubmit: async (values) => {
      await saveSettings({
        productId,
        type: 'trackers',
        data: {
          facebook: {
            id: values.facebook?.id ?? '',
            accessToken: values.facebook?.accessToken,
            domain: verifyDomain ? values.facebook?.domain : '',
          },
        },
      })
    },
  })

  const domainNotVerified = !!(!formik.touched.facebook && !data?.facebook?.verified && data?.facebook?.domain)

  useEffect(() => {
    if (data?.facebook?.domain) {
      setVerifyDomain(true)
    }
  }, [data?.facebook?.domain])

  return (
    <Section title={t('settings.trackers.title')} subtitle={t('settings.trackers.subtitle')} formik={formik}>
      <div className="flex flex-col space-y-4">
        <div className="space-y-2">
          <Label htmlFor="facebook.id">{t('settings.trackers.fields.facebook.id')}</Label>

          <Input
            id="facebook.id"
            name="facebook.id"
            value={formik.values.facebook?.id}
            onChange={formik.handleChange}
            disabled={isLoading}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="facebook.accessToken">{t('settings.trackers.fields.facebook.accessToken')}</Label>

          <Textarea
            id="facebook.accessToken"
            name="facebook.accessToken"
            value={formik.values.facebook?.accessToken}
            onChange={formik.handleChange}
            disabled={isLoading}
            rows={3}
          />
        </div>

        <div className="flex items-center space-x-2">
          <Switch id="verifyDomain" checked={verifyDomain} onCheckedChange={setVerifyDomain} disabled={isLoading} />

          <Label htmlFor="verifyDomain">{t('settings.trackers.fields.facebook.verified')}</Label>
        </div>

        <p className="text-sm text-muted-foreground">{t('settings.trackers.fields.facebook.verifiedSubtitle')}</p>

        {verifyDomain && (
          <div className="space-y-4">
            <p>{t('settings.trackers.fields.facebook.domain.step_1')}</p>

            <div className="space-y-2">
              <Label htmlFor="facebook.domain">{t('settings.trackers.fields.facebook.domain.label')}</Label>

              <Input
                id="facebook.domain"
                name="facebook.domain"
                value={formik.values.facebook?.domain}
                onChange={formik.handleChange}
                disabled={isLoading}
                className={domainNotVerified ? 'border-red-500' : ''}
              />

              {domainNotVerified && (
                <p className="text-sm text-red-500">{t('settings.trackers.fields.facebook.domain.notVerified')}</p>
              )}
            </div>

            <p className="text-sm text-muted-foreground">{t('settings.trackers.fields.facebook.domain.helper_1')}</p>

            <p>{t('settings.trackers.fields.facebook.domain.step_2')}</p>

            <p className="text-sm text-muted-foreground">{t('settings.trackers.fields.facebook.domain.helper_2')}</p>

            <Card>
              <CardContent className="p-4">
                <p className="text-xs">{t('settings.trackers.fields.facebook.domain.step_2_1')}</p>
              </CardContent>
            </Card>

            <Card>
              <CardContent className="p-4">
                <p className="text-xs">
                  {t('settings.trackers.fields.facebook.domain.step_2_2', {
                    domain: formik.values.facebook?.domain || 'pixel.seudominio.com.br',
                  })}
                </p>
              </CardContent>
            </Card>

            <Card>
              <CardContent className="p-4">
                <p className="text-xs">{t('settings.trackers.fields.facebook.domain.step_2_3')}</p>
              </CardContent>
            </Card>

            <p className="text-sm text-muted-foreground">{t('settings.trackers.fields.facebook.domain.helper_3')}</p>

            <p className="text-sm text-muted-foreground">{t('settings.trackers.fields.facebook.domain.helper_4')}</p>
          </div>
        )}
      </div>
    </Section>
  )
}
