import {
  Button,
  Card,
  CardContent,
  Input,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  Switch,
  useToast,
} from '@hub-la/shadcn'
import { useFormik } from 'formik'
import { Plus } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ShortLink } from '../../../../domain/dtos/short-link'
import { CreateEditShortLinkType } from '../../../../domain/enums/create-edit-shortlink-type'
import { ShortLinkType } from '../../../../domain/enums/shortlink-type'
import { Envs } from '../../../../envs'
import { GetUtmsAndCleanUrl } from '../../../../usecases/shortlinks/get-utms-and-clean-url'
import { useCreateShortLink } from '../../../hooks/shortlinks/use-create-short-link'
import { useUpdateShortLink } from '../../../hooks/shortlinks/use-update-short-link'
import { createShortLinkValidationSchema } from '../../../validations/create-short-link-validation'

export type CreateShortLinkValues = {
  offerId: string
  name: string
  type: ShortLinkType
  redirectUrl?: string
  slug: string | null
  utmSource?: string
  utmMedium?: string
  utmCampaign?: string
  utmContent?: string
  utmTerm?: string
  applyToSpecificUtms: boolean
  isSlugAvailable: boolean
}

type Props = {
  productId: string
  offers: { id: string; name: string }[]
  type: CreateEditShortLinkType
  shortLink: ShortLink | null
  isOpen: boolean
  isLoading: boolean
  onClose: () => void
  onSuccess: () => void
}

export const CreateEditShortLinkModal: React.FC<Props> = ({
  productId,
  offers,
  type,
  shortLink,
  isOpen,
  onClose,
  isLoading,
  onSuccess,
}) => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const { mutateAsync: createShortLink, isLoading: loadingCreate } = useCreateShortLink(productId)
  const { mutateAsync: updateShortLink, isLoading: loadingEdit } = useUpdateShortLink(productId, shortLink?.id)

  const formik = useFormik<CreateShortLinkValues>({
    initialValues: {
      offerId: shortLink?.offer.id ?? '',
      name: shortLink?.name ?? '',
      slug: shortLink?.slug || shortLink?.id || '',
      type: shortLink?.type ?? ShortLinkType.CHECKOUT,
      redirectUrl: shortLink?.redirectUrl,
      utmSource: shortLink?.utm?.source,
      utmMedium: shortLink?.utm?.medium,
      utmCampaign: shortLink?.utm?.campaign,
      utmContent: shortLink?.utm?.content,
      utmTerm: shortLink?.utm?.term,
      applyToSpecificUtms: !!shortLink?.utm ?? false,
      isSlugAvailable: true,
    },
    validationSchema: createShortLinkValidationSchema,
    validateOnChange: true,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        if (type === CreateEditShortLinkType.CREATE) {
          await createShortLink(values)
        } else {
          const isSlugSameId = !shortLink?.slug && values.slug?.toLowerCase() === shortLink?.id.toLowerCase()
          const request = isSlugSameId ? { ...values, slug: null } : values
          await updateShortLink(request)
        }
        formik.resetForm()
        onSuccess?.()
        toast({
          description:
            type === CreateEditShortLinkType.CREATE
              ? t('shortlinks.snackbar.createSuccess')
              : t('shortlinks.snackbar.editSuccess'),
        })
      } catch (error: any) {
        if (error?.message === 'shortlinks.errors.alreadyExists') {
          formik.setFieldError('slug', t('shortlinks.errors.alreadyExists'))
        }
        toast({
          variant: 'destructive',
          description: t(error?.message),
        })
      }
    },
  })

  const onSlugChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const isLetters = /^[A-Za-z0-9_-]*$/.test(value)
    if (isLetters) {
      formik.setFieldValue('slug', value.toLowerCase())
    }
  }

  const onRedirectUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const { url, params } = new GetUtmsAndCleanUrl().execute(value)
    const {
      utm_source: source,
      utm_medium: medium,
      utm_campaign: campaign,
      utm_content: content,
      utm_term: term,
    } = params

    if (source || medium || campaign || content || term) {
      formik.setFieldValue('applyToSpecificUtms', true)
      formik.setFieldValue('utmSource', source)
      formik.setFieldValue('utmMedium', medium)
      formik.setFieldValue('utmCampaign', campaign)
      formik.setFieldValue('utmContent', content)
      formik.setFieldValue('utmTerm', term)
    }

    formik.setFieldValue('redirectUrl', url)
  }

  return (
    <Sheet open={isOpen} onOpenChange={onClose}>
      <SheetContent className="sm:max-w-[450px] overflow-y-auto ">
        <SheetHeader className="mb-6">
          <SheetTitle className="flex items-center gap-3">
            <span>{shortLink?.name ?? 'Link'}</span>
          </SheetTitle>
        </SheetHeader>

        <div className="space-y-4">
          <div className="space-y-1">
            <Label htmlFor="name">Nome</Label>
            <Input
              id="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={(type === CreateEditShortLinkType.EDIT && shortLink && shortLink.isDefault) ?? false}
              className={formik.errors.name && formik.touched.name ? 'border-red-500' : ''}
            />
            {formik.errors.name && formik.touched.name && (
              <p className="text-red-500 text-sm">{t(formik.errors.name)}</p>
            )}
          </div>

          <div className="space-y-1">
            <Label htmlFor="offerId">Ofertas</Label>
            <Select
              onValueChange={(value) => formik.setFieldValue('offerId', value)}
              value={formik.values.offerId}
              disabled={(type === CreateEditShortLinkType.EDIT && shortLink && shortLink.isDefault) ?? false}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Selecione uma oferta" />
              </SelectTrigger>
              <SelectContent className="z-[6000]">
                {offers.map((offer) => (
                  <SelectItem key={offer.id} value={offer.id}>
                    {offer.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            {formik.errors.offerId && formik.touched.offerId && (
              <p className="text-red-500 text-sm">{t(formik.errors.offerId)}</p>
            )}
          </div>

          <div className="space-y-1">
            <Label htmlFor="type">Tipo</Label>
            <Select
              onValueChange={(value) => formik.setFieldValue('type', value)}
              value={formik.values.type}
              disabled={(type === CreateEditShortLinkType.EDIT && shortLink && shortLink.isDefault) ?? false}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Selecione o tipo" />
              </SelectTrigger>
              <SelectContent className="z-[6000]">
                {Object.keys(ShortLinkType).map((type) => (
                  <SelectItem key={type} value={ShortLinkType[type]}>
                    {t(`shortlinks.types.${type}`)}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            {formik.errors.type && formik.touched.type && (
              <p className="text-red-500 text-sm">{t(formik.errors.type)}</p>
            )}
          </div>

          {[ShortLinkType.PRODUCT_EXTERNAL, ShortLinkType.MEMBER_AREA_EXTERNAL].includes(formik.values.type) && (
            <div className="space-y-1">
              <Label htmlFor="redirectUrl">URL de destino</Label>
              <Input
                id="redirectUrl"
                value={formik.values.redirectUrl}
                onChange={onRedirectUrlChange}
                onBlur={formik.handleBlur}
                placeholder="https://example.com"
                className={formik.errors.redirectUrl && formik.touched.redirectUrl ? 'border-red-500' : ''}
              />
              {formik.errors.redirectUrl && formik.touched.redirectUrl ? (
                <p className="text-red-500 text-sm">{t(formik.errors.redirectUrl)}</p>
              ) : (
                <p className="text-xs text-gray-500">Link a ser encurtado. Ex: https://google.com.br</p>
              )}
            </div>
          )}

          <div className="space-y-1">
            <Label htmlFor="slug">URL encurtada</Label>
            <div className="flex items-center">
              <span className="bg-secondary px-3 py-2.5 text-sm font-medium text-muted-foreground rounded-l-md border border-r-0 border-gray-300">
                {(Envs.SITE_URL ?? '').replace('https://', '')}/r/
              </span>
              <Input
                id="slug"
                value={formik.values.slug || ''}
                onChange={onSlugChange}
                onBlur={formik.handleBlur}
                className={`rounded-l-none ${formik.errors.slug && formik.touched.slug ? 'border-red-500' : ''}`}
              />
            </div>
            {formik.errors.slug && formik.touched.slug && (
              <p className="text-red-500 text-sm">{t(formik.errors.slug)}</p>
            )}
          </div>

          <Card>
            <CardContent className="pt-6">
              <div className="flex items-center space-x-2">
                <Switch
                  id="applyToSpecificUtms"
                  checked={formik.values.applyToSpecificUtms}
                  onCheckedChange={(checked) => formik.setFieldValue('applyToSpecificUtms', checked)}
                />
                <Label htmlFor="applyToSpecificUtms">Parâmetros de campanha (UTM)</Label>
              </div>

              {formik.values.applyToSpecificUtms && (
                <div className="mt-4 space-y-3">
                  {['utmSource', 'utmMedium', 'utmCampaign', 'utmContent', 'utmTerm'].map((field) => (
                    <div key={field} className="space-y-1">
                      <Label htmlFor={field}>{t(`shortlinks.utmFields.${field}`)}</Label>
                      <Input
                        id={field}
                        value={formik.values[field]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={formik.errors[field] && formik.touched[field] ? 'border-red-500' : ''}
                      />
                      {formik.errors[field] && formik.touched[field] ? (
                        <p className="text-red-500 text-sm">{t(formik.errors[field])}</p>
                      ) : (
                        <p className="text-xs text-gray-500">{`utm_${field.toLowerCase().replace('utm', '')}`}</p>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </CardContent>
          </Card>

          <Button
            className="w-full"
            onClick={() => formik.submitForm()}
            disabled={!formik.isValid || !formik.dirty}
            loading={isLoading || loadingCreate || loadingEdit}
          >
            {type === CreateEditShortLinkType.CREATE ? <Plus className="mr-2 h-4 w-4" /> : null}
            {type === CreateEditShortLinkType.CREATE ? t('shortlinks.buttons.create') : t('shortlinks.buttons.edit')}
          </Button>
        </div>
      </SheetContent>
    </Sheet>
  )
}
