import { Card, CardContent, CardHeader, CardTitle, Separator } from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'
import { InvoicePayerSession } from '../../../domain/dtos/invoice'

type Props = {
  detail: InvoicePayerSession
}

const UtmInfoRow = ({ label, value }) => (
  <div className="flex justify-between items-start mb-4">
    <div className="w-48 pr-2">
      <p className="text-sm">{label}</p>
    </div>
    <div className="pl-2 max-w-sm">
      <p className={`text-sm text-muted-foreground text-right`}>{value || 'Não disponível'}</p>
    </div>
  </div>
)

export const UTMTracking: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const { utmSource, utmMedium, utmCampaign, utmTerm, utmContent } = props.detail

  return (
    <Card>
      <CardHeader className="py-2 px-4">
        <CardTitle className="text-sm font-semibold">Rastreamento de UTM</CardTitle>
      </CardHeader>
      <Separator />
      <CardContent className="p-4 space-y-4">
        <UtmInfoRow label="Origem" value={utmSource} />
        <UtmInfoRow label="Mídia" value={utmMedium} />
        <UtmInfoRow label="Campanha" value={utmCampaign} />
        <UtmInfoRow label="Conteúdo" value={utmContent} />
        <UtmInfoRow label="Termo" value={utmTerm} />
      </CardContent>
    </Card>
  )
}
