import { useTheme } from '@hub-la/shadcn'
import React from 'react'

type Props = {
  children: React.ReactNode
}

export const UserContainer: React.FC<Props> = ({ children }) => {
  useTheme('dark')

  return <div className="min-h-screen">{children}</div>
}
