import { TableCell, TableRow, Skeleton } from '@hub-la/shadcn'

export const TableLoading = () => {
  return (
    <TableRow>
      <TableCell className="p-2">
        <div className="flex gap-2">
          <Skeleton className="w-3/5 h-6" />
        </div>
      </TableCell>

      <TableCell className="p-2">
        <Skeleton className="w-11 h-6" />
      </TableCell>

      <TableCell className="p-2">
        <Skeleton className="w-6 h-6" />
      </TableCell>

      <TableCell className="p-2">
        <Skeleton className="w-[70px] h-6" />
      </TableCell>

      <TableCell className="p-2">
        <Skeleton className="w-[70%] h-6" />
      </TableCell>

      <TableCell className="p-2">
        <Skeleton className="w-[70%] h-6" />
      </TableCell>
    </TableRow>
  )
}
