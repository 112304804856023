import { useTranslation } from 'react-i18next'
import { IntegrationCard } from '../../components/integration-card/integration-card'
import { IntegrationCategory } from '../../components/integration-category/integration-category'
import { INTEGRATION_CATEGORIES_LIST } from '../../constants/integrations-list'

export const IntegrationsListPage = () => {
  const { t } = useTranslation()

  return (
    <div>
      <div className="mb-8 md:mb-0 p-2 md:p-12 pb-35 md:pb-36 w-full flex flex-col md:items-center gap-4 md:text-center rounded-md md:bg-secondary">
        <h2 className="text-3xl font-bold">{t('title')}</h2>

        <p className="max-w-lg text-sm">{t('description')}</p>
      </div>

      <div className="relative md:-mt-28 md:px-12 flex flex-col items-center">
        {INTEGRATION_CATEGORIES_LIST.map((category, idx) => (
          <IntegrationCategory key={idx} title={category.name} description={category.description}>
            {category.integrations.map((integration, idx) => (
              <IntegrationCard
                key={idx}
                title={integration.title}
                description={integration.description}
                provider={integration.provider}
                disabled={!integration.enabled}
                imageUrl={integration.icon}
              />
            ))}
          </IntegrationCategory>
        ))}
      </div>
    </div>
  )
}
