import { AuthProvider, AuthRedirectProvider, SignInEmailPage } from '@hub-la/fe-auth'

export const SignInEmailInit = () => {
  return (
    <AuthProvider>
      <AuthRedirectProvider>
        <SignInEmailPage />
      </AuthRedirectProvider>
    </AuthProvider>
  )
}
