import { useGetUser } from '@hub-la/fe-get-user'
import { RoleplayProvider, SelectAccountRoleplayPage } from '@hub-la/fe-roleplay'

export const RoleplayInit = () => {
  const { data: currentUser } = useGetUser()

  return (
    <RoleplayProvider>
      <SelectAccountRoleplayPage
        currentUser={{
          id: currentUser?.id ?? '',
          name: currentUser?.name ?? '',
          email: currentUser?.email ?? '',
          avatarUrl: currentUser?.picture ?? '',
        }}
      />
    </RoleplayProvider>
  )
}
