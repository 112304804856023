import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { toast } from '@hub-la/shadcn'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { QueryKey } from '../../domain/enums/query-key'
import { DeleteCoupon } from '../../usecases/delete-coupon'

export const useDeleteCoupon = () => {
  const { t } = useTranslation()
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation([QueryKey.deleteCoupon], (id: string) => new DeleteCoupon(container.get(HttpClient)).execute(id), {
    retry: false,
    onSuccess: (_, input) => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.getCoupon, input] })
      queryClient.invalidateQueries({ queryKey: [QueryKey.getCoupons] })
      toast({ title: 'Sucesso', description: t('success') })
    },
    onError: (error) => {
      toast({ title: 'Erro', description: (error as Error).message, variant: 'destructive' })
    },
  })
}
