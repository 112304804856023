import { Label, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@hub-la/shadcn'
import React from 'react'
import { useTranslation } from 'react-i18next'

const installments = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

interface InstallmentsSelectProps {
  label: string
  minInstallment?: number
  maxInstallment?: number
  name?: string
  value?: number
  onChange: (value: number) => void
  error?: boolean
  helperText?: string
}

export const InstallmentsSelect: React.FC<InstallmentsSelectProps> = ({
  label,
  minInstallment = 0,
  maxInstallment = 12,
  name,
  value,
  onChange,
  error,
  helperText,
}) => {
  const { t } = useTranslation()

  const installmentsAvailable = installments
    .slice(0, maxInstallment)
    .filter((installment) => (minInstallment ? installment >= minInstallment : true))

  const isDisabled = installmentsAvailable.length === 1

  const renderInstallmentText = (installments: number) => t('installmentSelectItem', { installments })

  return (
    <div className="flex flex-col space-y-1.5">
      <Label htmlFor={name} className="block">
        {label}
      </Label>
      <Select value={value?.toString()} onValueChange={(val) => onChange(Number(val))} disabled={isDisabled}>
        <SelectTrigger id={name} className={`w-full ${error ? 'border-red-500' : ''}`}>
          <SelectValue placeholder={renderInstallmentText(value || 1)} />
        </SelectTrigger>
        <SelectContent>
          {installmentsAvailable.map((installmentNumber) => (
            <SelectItem key={installmentNumber} value={installmentNumber.toString()} className="notranslate">
              {renderInstallmentText(installmentNumber)}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      {helperText && <p className={`mt-2 text-sm ${error ? 'text-red-600' : 'text-gray-500'}`}>{helperText}</p>}
    </div>
  )
}
