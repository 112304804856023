import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { GetIntegrationRulesInput } from '../../domain/dtos/get-integration-rules-input'
import { QueryKey } from '../../domain/dtos/query-keys'
import { GetIntegrationRules } from '../../usecases/get-integration-rules'

export const useGetIntegrationRules = (input: GetIntegrationRulesInput) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.getIntegrationRules],
    () => new GetIntegrationRules(container.get(HttpClient)).execute(input),
    {
      retry: 3,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      initialData: {
        items: [],
        page: 1,
        pageSize: 1,
        total: 0,
        lastPage: 1,
      },
    },
  )
}
