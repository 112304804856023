import { ImageUploader } from '@hub-la/fe-asset'
import { UpdateProductSettingsPageRequestDto } from '@hub-la/sdk-bff-product'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { Section } from '../../../components/section'
import { useSaveSettings } from '../../../hooks/settings/use-save-settings'

type Values = Pick<UpdateProductSettingsPageRequestDto, 'cover'>
const validationSchema = Yup.object().shape({})
const makeInitialValues = ({ cover }: Values): Values => {
  return {
    cover: cover ?? '',
  }
}

export const CoverSection = ({ cover }: Values) => {
  const { productId } = useParams<{ productId: string }>()
  const { mutateAsync: saveSettings } = useSaveSettings()
  const { t } = useTranslation()

  const formik = useFormik<Values>({
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: makeInitialValues({ cover }),
    onSubmit: async (values) => {
      await saveSettings({ productId, type: 'page', data: values })
    },
  })

  return (
    <Section
      title={t('settings.productPage.cover.title')}
      subtitle={t('settings.productPage.cover.subtitle')}
      formik={formik}
    >
      <div className="flex flex-col md:flex-row gap-4">
        <div className="w-full md:w-2/3 space-y-2">
          <h3 className="font-medium">{t('settings.productPage.cover.input.title')}</h3>

          <p className="text-sm text-gray-500">{t('settings.productPage.cover.input.subtitle')}</p>
        </div>

        <div className="w-full md:w-1/3">
          <ImageUploader
            name="cover"
            path={productId}
            className="w-36 h-20"
            value={formik.values.cover}
            onChange={(file) => formik.setFieldValue('cover', file?.URL ?? '')}
          />
        </div>
      </div>
    </Section>
  )
}
