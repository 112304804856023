import { Thumbnail } from '@hub-la/fe-thumbnail'
import Countdown from 'react-countdown'
import { useHistory } from 'react-router-dom'
import { GetPostPagination } from '../../../usecases/get-post-pagination'
import { PaginationHelper } from '../../../usecases/pagination-helper'
import { useRealtimeSections } from '../../hooks'

const COUNTDOWN_IN_SECONDS = 7

type UpNextCardProps = {
  postId: string
}

export const UpNextCard = ({ postId }: UpNextCardProps) => {
  const history = useHistory()
  const { sections } = useRealtimeSections()

  const pagination = new GetPostPagination().execute({ postId, sections })
  const { nextPost } = PaginationHelper.getPaginationData(sections, postId, pagination)

  if (!nextPost) return null

  const handleRedirectPost = () => {
    const postLink = `/post/${nextPost.id}`
    history.push(postLink)
  }

  return (
    <div
      data-testid="upnext-card"
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 max-w-80 w-full min-w-80"
    >
      <div
        onClick={handleRedirectPost}
        className="p-2 flex flex-row gap-3 items-center rounded cursor-pointer bg-surface"
      >
        <Thumbnail hasProgressBar={false} width={94} height={156} asset={nextPost.customCover ?? nextPost.cover} />

        <div className="flex flex-col">
          <h4 className="mb-4 text-neutral-primary text-lg font-bold">{nextPost?.title}</h4>

          <p className="text-neutral-primary text-sm">
            Próximo em{' '}
            <strong>
              <Countdown
                date={Date.now() + COUNTDOWN_IN_SECONDS * 1000}
                onComplete={handleRedirectPost}
                renderer={({ seconds }) => `${seconds}`}
              />
            </strong>
          </p>
        </div>
      </div>
    </div>
  )
}
