import { CheckCircle2, Circle, CircleDashed, CircleDot, CircleSlash } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { SubscriptionStatus } from '../../domain/enums/subscription-status'

type SubscriptionStatusStatsProps = {
  status: SubscriptionStatus
}

const getVariant = (status: SubscriptionStatus) => {
  const iconSize = 16

  switch (status) {
    case SubscriptionStatus.ACTIVE:
      return <CircleDot size={iconSize} className="mr-1.5 text-green-600" />
    case SubscriptionStatus.INACTIVE:
      return <CircleDashed size={iconSize} className="mr-1.5 text-gray-500" />
    case SubscriptionStatus.CANCELED:
      return <CircleSlash size={iconSize} className="mr-1.5 text-red-600" />
    case SubscriptionStatus.INCOMPLETE:
      return <Circle size={iconSize} className="mr-1.5 text-gray-600" />
    default:
      return <CheckCircle2 size={iconSize} className="mr-1.5 text-green-600 " />
  }
}

export const SubscriptionStatusStats = ({ status }: SubscriptionStatusStatsProps) => {
  const { t } = useTranslation()

  return (
    <div className="flex items-center ">
      {getVariant(status)}
      {t(`status.${status}`)}
    </div>
  )
}
