import { Card, CardContent, CardHeader, CardTitle, Separator } from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'
import { Account, BillingAddress, Identity } from '../../../domain/dtos/account'
import { CardDetail } from '../../../domain/dtos/card-detail'
import { InstallmentCostsPayer } from '../../../domain/enums/installment-costs-payer'
import { PaymentMethod } from '../../../domain/enums/payment-method'

type ChargeDetailProps = {
  payer: Account
  seller: Account
  detail: {
    createdAt: string
    paymentMethod: PaymentMethod
    installments: number
    installmentCostsPayer?: InstallmentCostsPayer
    cardDetails?: CardDetail
    billingAddress?: BillingAddress
    payerFullName?: string
    payerDocument?: string | null
  }
}

const PaymentInfoRow = ({ label, value }: { label: string; value: string | null | undefined }) => (
  <div className="flex justify-between items-start">
    <div className="w-48 pr-2">
      <p className="text-sm text-muted-foreground">{label}</p>
    </div>
    <div className="pl-2 max-w-sm">
      <p className={`text-sm`}>{value || '–'}</p>
    </div>
  </div>
)

const formatAddress = (address: BillingAddress | undefined) => {
  if (!address) return '–'
  return `${address.street}${address.number ? `, ${address.number}` : ''}
${address.complement ? `${address.complement}, ` : ''}${address.neighborhood || ''}
${address.city}, ${address.state}, ${address.postalCode}`
}

const formatPhoneNumber = (phone: string | null | undefined) => {
  if (!phone) return '–'
  // This is a basic formatter for Brazilian phone numbers
  // You might want to adjust it smd on your specific needs
  return phone.replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3')
}

const formatDocument = (document: string | null | undefined) => {
  if (!document) return '–'
  // Format CPF
  if (document.length === 11) {
    return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  }
  // Format CNPJ
  if (document.length === 14) {
    return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
  }
  // If it doesn't match CPF or CNPJ length, return as is
  return document
}

export const ChargeDetail = ({ payer, seller, detail }: ChargeDetailProps) => {
  const payerIdentity = payer.identity as Identity
  const { t } = useTranslation()

  const getPaymentMethodText = (paymentMethod: PaymentMethod, cardDetails?: CardDetail) => {
    switch (paymentMethod) {
      case PaymentMethod.PIX:
        return 'Pix'
      case PaymentMethod.BOLETO:
        return 'Boleto'
      case PaymentMethod.CREDIT_CARD:
        return cardDetails ? `${t(`cardBrand.${cardDetails.brand}`)} •••• ${cardDetails.last4}` : 'Cartão de crédito'
      default:
        return 'Método de pagamento desconhecido'
    }
  }

  const renderPaymentMethodInfo = () => {
    if (detail.paymentMethod === PaymentMethod.CREDIT_CARD) {
      return (
        <>
          <PaymentInfoRow
            label="Método de pagamento"
            value={detail.cardDetails ? `•••• ${detail.cardDetails.last4}` : undefined}
          />
          <PaymentInfoRow label="Titular do cartão" value={detail.cardDetails?.holder} />
          <PaymentInfoRow
            label="Validade"
            value={
              detail.cardDetails?.expMonth && detail.cardDetails?.expYear
                ? `${detail.cardDetails.expMonth} / ${detail.cardDetails.expYear}`
                : undefined
            }
          />
          <PaymentInfoRow label="BIN" value={detail.cardDetails?.bin} />
        </>
      )
    } else {
      return (
        <PaymentInfoRow
          label="Método de pagamento"
          value={getPaymentMethodText(detail.paymentMethod, detail.cardDetails)}
        />
      )
    }
  }

  return (
    <Card>
      <CardHeader className="p-4">
        <CardTitle className="text-sm font-semibold">Detalhes de cobrança</CardTitle>
      </CardHeader>
      <Separator />
      <CardContent className="p-4 space-y-4">
        {renderPaymentMethodInfo()}
        <PaymentInfoRow label="Número de parcelas" value={detail.installments.toString()} />
      </CardContent>
      <Separator />
      <CardContent className="p-4 space-y-4">
        {/* <div className="font-semibold">Comprador</div> */}
        <PaymentInfoRow label="Comprador" value={detail.payerFullName} />
        <PaymentInfoRow label="Documento" value={formatDocument(detail.payerDocument)} />
        <PaymentInfoRow label="E-mail" value={payerIdentity.email} />
        <PaymentInfoRow label="Telefone" value={formatPhoneNumber(payerIdentity.phone)} />
        <div className="flex items-start mb-2">
          <div className="w-48 pr-2">
            <p className="text-sm text-muted-foreground">Endereço</p>
          </div>
          <div className="pl-2 max-w-sm">
            <p className="text-sm text-right">{formatAddress(payerIdentity.billingAddress)}</p>
          </div>
        </div>
        <PaymentInfoRow
          label="País"
          value={
            payerIdentity.billingAddress
              ? new Intl.DisplayNames(['pt'], { type: 'region' }).of(payerIdentity.billingAddress.countryCode)
              : undefined
          }
        />
      </CardContent>
    </Card>
  )
}
