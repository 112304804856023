import { yupResolver } from '@hookform/resolvers/yup'
import { OfferDto, OfferDtoStatusEnum, ProductDto, ProductDtoStatusEnum } from '@hub-la/sdk-bff-product'
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@hub-la/shadcn'
import React, { useEffect } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { formatLocalNumber } from '../../../../../../domain/vos/format-local-number'
import PlaceholderLogo from '../../../../../assets/placeholder-image.svg'
import { useGetOffers } from '../../../../../hooks/use-list-offers'
import { useGetProducts } from '../../../../../hooks/use-list-products'
import {
  orderBumpSchemaValidation,
  OrderBumpSchemaValidationType,
} from '../../../../../validations/order-bump-validation'

const OfferPreviewSkeleton = () => (
  <div className="my-2 w-full max-w-[405px] border border-gray-200 rounded-lg p-4 mx-auto">
    <div className="flex gap-3">
      <div className="w-[84px] h-[56px] bg-gray-200 rounded" />
      <div className="space-y-1">
        <div className="w-[140px] h-5 bg-gray-200 rounded" />
        <div className="w-[85px] h-6 bg-gray-200 rounded" />
      </div>
    </div>
  </div>
)

interface OrderBumpModalProps {
  onCancel: () => void
  onSubmit: (values: OrderBumpSchemaValidationType & { offer: OfferDto | null; product: ProductDto | null }) => void
  open: boolean
  defaultValues?: any
  actionSave?: string
  actionCancel?: string
}

export const OrderBumpModal: React.FC<OrderBumpModalProps> = ({
  onCancel,
  onSubmit,
  open,
  defaultValues,
  actionSave,
  actionCancel,
}) => {
  const { t } = useTranslation()

  const form = useForm({
    defaultValues: {
      productId: '',
      offerId: '',
    },
    resolver: yupResolver(orderBumpSchemaValidation),
    mode: 'onChange',
  })

  const {
    watch,
    resetField,
    control,
    formState: { isValid },
  } = form

  useEffect(() => {
    form.reset({
      productId: defaultValues?.productId ?? '',
      offerId: defaultValues?.offer?.id ?? '',
    })
  }, [defaultValues?.offer?.id, defaultValues?.productId, defaultValues?.offer?.price])

  const selectedProductId = watch('productId')
  const selectedOfferId = watch('offerId')

  const { data: products, isLoading: isLoadingProducts } = useGetProducts({
    page: 1,
    pageSize: 999,
  })
  const availableProducts = (products?.items ?? []).filter(
    (product) => product.status === ProductDtoStatusEnum.IS_SELLING,
  )
  const { data: offers, isLoading: isLoadingOffers } = useGetOffers({
    page: 1,
    pageSize: 999,
    productId: selectedProductId ?? '',
  })
  const availableOffers = (offers?.items ?? [])?.filter(
    (offer) => offer?.priceType === 'one_time' && offer.status === OfferDtoStatusEnum.IS_SELLING,
  )

  const selectedProduct = products?.items?.find((product) => product.id === selectedProductId)
  const selectedOffer = offers?.items?.find((offer) => offer.id === selectedOfferId)
  const showPreview = selectedOfferId && selectedProductId

  // clear offerId input when productId changes (product selection above)
  useEffect(() => {
    resetField('offerId')
  }, [resetField, selectedProductId])

  const handleCancel = () => {
    form.reset()
    onCancel()
  }

  const handleSubmit = (values: OrderBumpSchemaValidationType) => {
    form.reset()
    onSubmit({ ...values, offer: selectedOffer ?? null, product: selectedProduct ?? null })
  }

  console.log({ availableOffers, availableProducts })

  return (
    <Dialog open={open} onOpenChange={onCancel}>
      <DialogContent className="sm:max-w-[425px]">
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <DialogHeader>
              <DialogTitle>{t('orderBumpModal.title')}</DialogTitle>
              <DialogDescription>{t('orderBumpModal.description')}</DialogDescription>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <Controller
                control={control}
                name="productId"
                render={({ field, fieldState }) => (
                  <Select onValueChange={field.onChange} defaultValue={field.value} disabled={isLoadingProducts}>
                    <SelectTrigger className={fieldState.error ? 'border-red-500' : ''}>
                      <SelectValue placeholder={t('orderBumpModal.productsList.title')} />
                    </SelectTrigger>
                    <SelectContent className="z-10002">
                      {availableProducts?.map((product) => (
                        <SelectItem key={product.id} value={product.id}>
                          {product.name}
                        </SelectItem>
                      ))}

                      {availableProducts?.length === 0 && (
                        <SelectItem value="empty" disabled>
                          {t('orderBumpModal.offersList.emptyAvailable')}
                        </SelectItem>
                      )}
                    </SelectContent>
                  </Select>
                )}
              />
              <Controller
                control={control}
                name="offerId"
                render={({ field, fieldState }) => (
                  <Select onValueChange={field.onChange} defaultValue={field.value} disabled={isLoadingOffers}>
                    <SelectTrigger className={fieldState.error ? 'border-red-500' : ''}>
                      <SelectValue placeholder={t('orderBumpModal.offersList.title')} />
                    </SelectTrigger>
                    <SelectContent className="z-10002">
                      {availableOffers?.map((offer) => (
                        <SelectItem key={offer.id} value={offer.id ?? ''}>
                          {offer.name} - {formatLocalNumber(offer?.price ?? 0)}
                        </SelectItem>
                      ))}

                      {availableOffers?.length === 0 && (
                        <SelectItem value="empty" disabled>
                          {t('orderBumpModal.offersList.emptyAvailable')}
                        </SelectItem>
                      )}
                    </SelectContent>
                  </Select>
                )}
              />
              {!showPreview && <OfferPreviewSkeleton />}
              {showPreview && (
                <div className="my-2 w-full max-w-[405px] border border-gray-200 rounded-lg p-4 mx-auto">
                  <div className="flex gap-3">
                    <img
                      src={selectedProduct?.picture ?? PlaceholderLogo}
                      alt="Product"
                      className="w-[84px] h-[56px] object-cover rounded"
                    />
                    <div className="space-y-1">
                      <p className="text-sm">{selectedOffer?.name}</p>
                      <p className="text-base font-bold">{formatLocalNumber(selectedOffer?.price ?? 0)}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <DialogFooter>
              <Button variant="outline" onClick={handleCancel} type="button">
                {actionCancel ?? t('orderBumpModal.actions.cancel')}
              </Button>
              <Button type="button" disabled={!isValid} onClick={form.handleSubmit(handleSubmit)}>
                {actionSave ?? t('orderBumpModal.actions.save')}
              </Button>
            </DialogFooter>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  )
}
