/* eslint-disable no-empty-pattern */
import { LoadingButton, loadingButtonClasses } from '@mui/lab'
import { buttonBaseClasses } from '@mui/material'
import { css, styled } from '@mui/material/styles'
import { alpha, spacing as muiSpacing } from '@mui/system'
import { Tokens } from '../../foundation/theme/tokens/tokens.types'
import { shouldNotForwardProps, spacing, withUtility } from '../../foundation/utils'
import { ButtonProps } from './button.types'

export const Button: React.FC<ButtonProps> = styled(LoadingButton, {
  shouldForwardProp: shouldNotForwardProps,
})<ButtonProps>`
  white-space: nowrap;
  font-size: 16px;
  font-weight: 700;

  ${withUtility<ButtonProps>(
    ({}, { iconOnly }) => css`
      padding-left: ${iconOnly ? spacing(2) : spacing(6)};
      padding-right: ${iconOnly ? spacing(2) : spacing(6)};
    `,
  )}

  ${withUtility<ButtonProps>(({}, { fullWidth }) => {
    if (!fullWidth) {
      return
    }

    return css`
      width: 100%;
    `
  })}

  ${withUtility<ButtonProps>(({}, { size, iconOnly }) => {
    if (!iconOnly) {
      return
    }

    switch (true) {
      case size === 'small':
        return css`
          min-width: 32px;
          max-width: 32px;
        `
      case size === 'medium':
        return css`
          min-width: 48px;
          max-width: 48px;
        `
      case size === 'large':
        return css`
          min-width: 56px;
          max-width: 56px;
        `
      default:
        break
    }

    return
  })}

  ${withUtility<ButtonProps>(({}, { size, iconOnly }) => {
    switch (true) {
      case size === 'small':
        return css`
          height: ${iconOnly ? '32px' : '40px'};
        `
      case size === 'medium':
        return css`
          height: 48px;
        `
      case size === 'large':
        return css`
          height: 56px;
        `
      default:
        return css``
    }
  })}

  ${withUtility<ButtonProps>(
    ({ palette, isLight }, { variant, loading, disabled, hierarchy, inverted: isInverted }) => {
      const theme = isLight ? 'light' : 'dark'
      const inverted = isInverted ? 'inverted' : 'default'

      if (variant === 'text' && isInverted) {
        return css`
          color: ${palette('onPrimary')};

          &:hover {
            background-color: ${alpha(palette('onPrimary'), 0.12)};
          }
        `
      }

      if (variant === 'outlined' && isInverted) {
        return css`
          border: 1px solid ${palette('onPrimary')};
          color: ${palette('onPrimary')};

          &:hover {
            border: 1px solid ${palette('onPrimary')};
            background-color: ${alpha(palette('onPrimary'), 0.12)};
          }
        `
      }

      /** ----------------------------------------------------------------------
       *
       * @Filled variant
       *
       * ---------------------------------------------------------------------- */
      if (variant === 'filled') {
        switch (true) {
          case loading || disabled: {
            const color = isInverted ? 'surface' : 'onSurface'

            return css`
              &.${buttonBaseClasses.disabled} {
                color: ${alpha(palette(color), 0.38)};
                border: 1px solid ${alpha(palette(color), 0.38)};
                background-color: ${alpha(palette(color), 0.12)};
              }

              &.${loadingButtonClasses.loading} {
                color: transparent;
              }
            `
          }
          case hierarchy === 'primary': {
            const properties: Record<string, Record<string, Record<string, keyof Tokens>>> = {
              light: {
                default: {
                  background: 'primaryContainer',
                  color: 'onPrimaryContainer',
                  border: 'onPrimaryContainer',
                  backgroundHover: 'primaryContainer',
                  overlayHover: 'onPrimaryContainer',
                },
                inverted: {
                  background: 'primary',
                  color: 'primaryContainer',
                  border: 'primaryContainer',
                  backgroundHover: 'primary',
                  overlayHover: 'primaryContainer',
                },
              },
              dark: {
                default: {
                  background: 'onPrimaryContainer',
                  color: 'onPrimary',
                  border: 'onPrimary',
                  backgroundHover: 'onPrimaryContainer',
                  overlayHover: 'onPrimary',
                },
                inverted: {
                  background: 'primaryContainer',
                  color: 'onPrimaryContainer',
                  border: 'onPrimaryContainer',
                  backgroundHover: 'primaryContainer',
                  overlayHover: 'onPrimaryContainer',
                },
              },
            }

            const { background, color, border, backgroundHover, overlayHover } = properties[theme][inverted]

            return css`
              background-color: ${palette(background)};
              color: ${palette(color)};
              border: 1px solid ${palette(border)};

              &:hover {
                background-color: ${palette(backgroundHover)};

                & .MuiTouchRipple-root {
                  background-color: ${palette(overlayHover)};
                  opacity: 0.08;
                }
              }
            `
          }
          case hierarchy === 'secondary': {
            const properties: Record<string, Record<string, Record<string, keyof Tokens>>> = {
              light: {
                default: {
                  background: 'onBackground',
                  color: 'onPrimary',
                  border: 'onPrimary',
                  backgroundHover: 'onBackground',
                  overlayHover: 'onPrimary',
                },
                inverted: {
                  background: 'onSurface',
                  color: 'surface',
                  border: 'onPrimary',
                  backgroundHover: 'onSurface',
                  overlayHover: 'surface',
                },
              },
              dark: {
                default: {
                  background: 'onSurface',
                  color: 'surface',
                  border: 'onPrimary',
                  backgroundHover: 'onSurface',
                  overlayHover: 'surface',
                },
                inverted: {
                  background: 'onPrimary',
                  color: 'onPrimaryContainer',
                  border: 'onPrimaryContainer',
                  backgroundHover: 'onPrimary',
                  overlayHover: 'onPrimaryContainer',
                },
              },
            }

            const { background, color, border, backgroundHover, overlayHover } = properties[theme][inverted]

            return css`
              background-color: ${palette(background)};
              color: ${palette(color)};
              border: 1px solid ${palette(border)};

              &:hover {
                background-color: ${palette(backgroundHover)};

                & .MuiTouchRipple-root {
                  background-color: ${palette(overlayHover)};
                  opacity: 0.08;
                }
              }
            `
          }
          case hierarchy === 'negative': {
            const properties: Record<string, Record<string, Record<string, keyof Tokens>>> = {
              light: {
                default: {
                  background: 'errorContainer',
                  color: 'onErrorContainer',
                  border: 'onErrorContainer',
                  backgroundHover: 'errorContainer',
                  overlayHover: 'onErrorContainer',
                },
                inverted: {
                  background: 'onErrorContainer',
                  color: 'errorContainer',
                  border: 'errorContainer',
                  backgroundHover: 'onErrorContainer',
                  overlayHover: 'errorContainer',
                },
              },
              dark: {
                default: {
                  background: 'onError',
                  color: 'error',
                  border: 'error',
                  backgroundHover: 'onError',
                  overlayHover: 'error',
                },
                inverted: {
                  background: 'onError',
                  color: 'error',
                  border: 'error',
                  backgroundHover: 'onError',
                  overlayHover: 'error',
                },
              },
            }

            const { background, color, border, backgroundHover, overlayHover } = properties[theme][inverted]

            return css`
              background-color: ${palette(background)};
              color: ${palette(color)};
              border: 1px solid ${palette(border)};

              &:hover {
                background-color: ${palette(backgroundHover)};

                & .MuiTouchRipple-root {
                  background-color: ${palette(overlayHover)};
                  opacity: 0.08;
                }
              }
            `
          }
          default:
            return css``
        }
      }

      /** ----------------------------------------------------------------------
       *
       * @Outlined variant
       *
       * ---------------------------------------------------------------------- */
      if (variant === 'outlined') {
        switch (true) {
          case hierarchy === 'primary': {
            const properties: Record<string, Record<string, keyof Tokens>> = {
              light: {
                color: 'onPrimaryContainer',
                border: 'onPrimaryContainer',
                backgroundHover: 'onPrimaryContainer',
              },
              dark: {
                color: 'primary',
                border: 'primary',
                backgroundHover: 'primary',
              },
            }

            const { color, border, backgroundHover } = properties[theme]

            return css`
              color: ${palette(color)};
              border: 1px solid ${palette(border)};

              &:hover {
                background-color: ${alpha(palette(backgroundHover), 0.12)};
                border: 1px solid ${palette(border)};

                & .MuiTouchRipple-root {
                  background-color: ${palette(backgroundHover)};
                  opacity: 0.08;
                }
              }
            `
          }
          case hierarchy === 'secondary': {
            const properties: Record<string, Record<string, keyof Tokens>> = {
              light: {
                color: 'onPrimaryContainer',
                border: 'onPrimaryContainer',
                backgroundHover: 'onPrimaryContainer',
              },
              dark: {
                color: 'onSurface',
                border: 'onSurfaceVariant',
                backgroundHover: 'onSurfaceVariant',
              },
            }

            const { color, border, backgroundHover } = properties[theme]

            return css`
              color: ${palette(color)};
              border: 1px solid ${palette(border)};

              &:hover {
                background-color: ${alpha(palette(backgroundHover), 0.12)};
                border: 1px solid ${palette(border)};

                & .MuiTouchRipple-root {
                  background-color: ${palette(backgroundHover)};
                  opacity: 0.08;
                }
              }
            `
          }
          case hierarchy === 'negative': {
            const properties: Record<string, Record<string, keyof Tokens>> = {
              light: {
                color: 'onErrorContainer',
                border: 'onErrorContainer',
                backgroundHover: 'onErrorContainer',
              },
              dark: {
                color: 'error',
                border: 'error',
                backgroundHover: 'error',
              },
            }

            const { color, border, backgroundHover } = properties[theme]

            return css`
              color: ${palette(color)};
              border: 1px solid ${palette(border)};

              &:hover {
                background-color: ${alpha(palette(backgroundHover), 0.12)};
                border: 1px solid ${palette(border)};

                & .MuiTouchRipple-root {
                  background-color: ${palette(backgroundHover)};
                  opacity: 0.08;
                }
              }
            `
          }
          default:
            return css``
        }
      }

      /** ----------------------------------------------------------------------
       *
       * @Text variant
       *
       * ---------------------------------------------------------------------- */
      if (variant === 'text') {
        switch (true) {
          case hierarchy === 'primary': {
            const properties: Record<string, Record<string, keyof Tokens>> = {
              light: {
                color: 'onPrimaryContainer',
                backgroundHover: 'onPrimaryContainer',
              },
              dark: {
                color: 'primary',
                backgroundHover: 'primary',
              },
            }

            const { color, backgroundHover } = properties[theme]

            return css`
              color: ${palette(color)};

              &:hover {
                background-color: ${alpha(palette(backgroundHover), 0.04)};
              }
            `
          }
          case hierarchy === 'secondary': {
            const properties: Record<string, Record<string, keyof Tokens>> = {
              light: {
                color: 'primary',
                backgroundHover: 'onPrimaryContainer',
              },
              dark: {
                color: 'onSurface',
                backgroundHover: 'onSurfaceVariant',
              },
            }

            const { color, backgroundHover } = properties[theme]

            return css`
              color: ${palette(color)};

              &:hover {
                background-color: ${alpha(palette(backgroundHover), 0.04)};
              }
            `
          }
          case hierarchy === 'negative': {
            const properties: Record<string, Record<string, keyof Tokens>> = {
              light: {
                color: 'error',
                backgroundHover: 'error',
              },
              dark: {
                color: 'error',
                backgroundHover: 'error',
              },
            }

            const { color, backgroundHover } = properties[theme]

            return css`
              color: ${palette(color)};

              &:hover {
                background-color: ${alpha(palette(backgroundHover), 0.04)};
              }
            `
          }
          default:
            return css``
        }
      }

      return css``
    },
  )};

  ${muiSpacing};
`

Button.defaultProps = {
  variant: 'filled',
  hierarchy: 'primary',
}
