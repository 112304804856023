import { FormikProvider, useFormik } from 'formik'
import { useContext } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Trans, useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { SignInWithoutPasswordError } from '../../../domain/errors/sign-in-without-password-error'
import { SignInWrongPasswordError } from '../../../domain/errors/sign-in-wrong-password-error'
import { SignInNotfoundError } from '../../../domain/errors/signin-not-found-error'
import { AuthRedirectContext } from '../../../providers/auth-redirect-provider'
import { useAuthSignInByEmail } from '../../hooks/use-auth-sign-in-by-email'
import { signInEmailSchema } from '../../validations/signin-email'
import { AuthCard } from '../auth-card/auth-card'
import { PasswordField } from '../password-field'

import { Alert, AlertDescription, Button, Input, Label } from '@hub-la/shadcn'
import { RefreshCcw } from 'lucide-react'

const SignInEmailWithPasswordCard = ({ defaultEmail = '', resetEmail }) => {
  const { t } = useTranslation()
  const { redirectAutoIfAuthenticated } = useContext(AuthRedirectContext)

  const { executeRecaptcha } = useGoogleReCaptcha()
  const { mutateAsync, isLoading, error, isError, reset, isSuccess } = useAuthSignInByEmail({
    onSuccess: () => {
      redirectAutoIfAuthenticated()
    },
    onError: (error) => {
      const isEmailError = [SignInNotfoundError].some((errorType) => error instanceof errorType)
      if (isEmailError) {
        form.setFieldError('email', error.message)
      }

      const isPasswordError = [SignInWrongPasswordError, SignInWithoutPasswordError].some(
        (errorType) => error instanceof errorType,
      )
      if (isPasswordError) {
        form.setFieldError('password', error.message)
      }
    },
  })

  const form = useFormik({
    initialValues: {
      email: defaultEmail,
      password: '',
    },
    onSubmit: async (values) => {
      const captcha = await executeRecaptcha?.('email_signin')

      await mutateAsync({ email: values.email, password: values.password, captcha })
    },
    validationSchema: signInEmailSchema,
  })

  const hideErrorsOnSnackbar = [SignInNotfoundError, SignInWrongPasswordError, SignInWithoutPasswordError].some(
    (errorType) => error instanceof errorType,
  )

  return (
    <AuthCard title="Faça seu login" description="Informe seus dados para continuar">
      <FormikProvider value={form}>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            form.handleSubmit()
          }}
          className="space-y-4 w-full"
        >
          <div className="space-y-2">
            <Label htmlFor="email">E-mail</Label>
            <div className="relative">
              <Input
                id="email"
                name="email"
                type="email"
                disabled={!!defaultEmail}
                onChange={form.handleChange}
                value={form.values.email}
              />
              <Button
                variant="ghost"
                size="sm"
                className="absolute right-2 top-1/2 transform -translate-y-1/2"
                onClick={() => resetEmail()}
                type="button"
              >
                <RefreshCcw className="h-4 w-4" />
              </Button>
            </div>
            {form.touched.email && form.errors.email && (
              <p className="text-sm text-red-500">
                <Trans
                  i18nKey={form.errors.email}
                  components={[
                    <RouterLink to="/signup">
                      <a href="/signup" />
                    </RouterLink>,
                  ]}
                />
              </p>
            )}
          </div>

          <PasswordField
            name="password"
            autoComplete="password"
            label="Senha"
            onChange={form.handleChange}
            value={form.values.password}
            error={form.touched.password && !!form.errors.password}
            helperText={
              form.touched.password &&
              !!form.errors.password && (
                <Trans
                  i18nKey={form.errors.password}
                  components={[
                    <RouterLink to="/recover-password">
                      <a href="/recover-password" />
                    </RouterLink>,
                  ]}
                />
              )
            }
          />

          <Button className="w-full" type="submit" disabled={isLoading || isSuccess}>
            {isLoading || isSuccess ? 'Carregando...' : 'Acessar minha conta'}
          </Button>

          <RouterLink to="/recover-password" className="block w-full">
            <Button type="button" variant="outline" className="w-full">
              Esqueci minha senha
            </Button>
          </RouterLink>
        </form>
      </FormikProvider>

      <div className="relative my-4">
        <hr />
        <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-background px-2 text-xs text-muted-foreground">
          {t('Ou')}
        </span>
      </div>
      <p className="text-center text-sm text-muted-foreground">
        Não possui uma conta?{' '}
        <RouterLink className="text-lime-600" to="/signup">
          Crie sua conta
        </RouterLink>
      </p>

      {isError && !hideErrorsOnSnackbar && (
        <Alert variant="destructive" className="mt-4">
          <AlertDescription>{t((error as Error)?.message)}</AlertDescription>
        </Alert>
      )}
    </AuthCard>
  )
}

export { SignInEmailWithPasswordCard }
