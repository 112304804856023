import { CopyClipboard } from '@hub-la/fe-copy-clipboard'
import { Button, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, useToast } from '@hub-la/shadcn'
import { Copy } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Envs } from '../../../../envs'

type CopyClipboardTagProps = {
  title: string
}

export const CopyClipboardTag: React.FC<CopyClipboardTagProps> = ({ title }) => {
  const { t } = useTranslation()
  const { toast } = useToast()
  const url = `${Envs.SITE_URL}/r/${title}`

  const handleCopyClipboard = async (content: string) => {
    await new CopyClipboard().execute(content)
    toast({
      description: t('shortlinks.snackbar.copyClipboard'),
      duration: 6000,
    })
  }

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button variant="outline" size="sm" className="py-1 gap-2" onClick={() => handleCopyClipboard(url)}>
            <Copy className="h-4 w-4" />
            <span className="max-w-[200px] truncate">{url.replace('https://', '')}</span>
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p>{t('shortlinks.buttons.copyUrl')}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
