import { formatDate } from '../../../domain/vos/format-date'
import { GetComparativeDateRange } from '../../../usecases/get-comparative-date-range'
import { SelectDateRangeCalendar } from '../../components/select-date-range-calendar'
import { SelectOffers } from '../../components/select-offers'

export const Filters = ({ setFilters, filters, offers }) => {
  const { startAt, endAt } = new GetComparativeDateRange().execute(filters.period, filters.startDate, filters.endDate)
  return (
    <div className="flex md:flex-row flex-col gap-2 my-4">
      <SelectOffers
        offers={offers}
        value={filters.offerIds}
        onChange={(ids) => setFilters((prevState) => ({ ...prevState, offerIds: ids }))}
      />
      <SelectDateRangeCalendar filters={filters} setFilters={setFilters} />
      <p className="flex text-sm text-muted-foreground items-center">
        em comparação com o período anterior
        {` (${formatDate(startAt, filters.period)} - ${formatDate(endAt, filters.period)})`}
      </p>
    </div>
  )
}
