import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react'
import { Section } from '../../domain/dtos/section'

type RealtimeStateProps = {
  sections: Section[]
}

type ActionUpdateProps = {
  sectionId?: string
  postId?: string
  currentTime?: number
  sectionProgress?: number
  viewed?: boolean
  type: 'update'
}

type ActionAddProps = {
  sections: Section[]
  type: 'add'
}

type ActionProps = ActionAddProps | ActionUpdateProps

export interface RealtimeSectionsContextProps {
  realtimePostProgress: number
  setRealtimePostProgress: Dispatch<SetStateAction<number>>
  sections: Section[]
  dispatch: React.Dispatch<ActionProps>
}

export const RealtimeSectionsContext = createContext<RealtimeSectionsContextProps>({} as RealtimeSectionsContextProps)

export const useRealtimeSections = () => {
  return useContext(RealtimeSectionsContext)
}

function reducer(state: RealtimeStateProps, action: ActionProps): RealtimeStateProps {
  switch (action.type) {
    case 'add':
      return { sections: action.sections }
    case 'update':
      return {
        sections: state.sections.map((section) => ({
          ...section,
          progress:
            section.id === action.sectionId && action?.sectionProgress !== undefined
              ? action.sectionProgress
              : section.progress,
          posts:
            section.id === action.sectionId
              ? section.posts.map((post) => {
                  if (post.id === action.postId) {
                    return {
                      ...post,
                      viewed: action?.viewed !== undefined ? action.viewed : post.viewed,
                      cover: {
                        ...post.cover!,
                        progress: {
                          ...post.cover?.progress,
                          currentProgress: action.currentTime
                            ? action.currentTime
                            : post.cover?.progress?.currentProgress,
                        },
                      },
                    }
                  }
                  return post
                })
              : section.posts,
        })),
      } as RealtimeStateProps
    default:
      throw new Error()
  }
}

const initialState: RealtimeStateProps = {
  sections: [],
}

export const CreateRealtimeSectionsProvider = (props: { children?: React.ReactElement }) => {
  const [realtimePostProgress, setRealtimePostProgress] = useState<number>(0)
  const [realtimeState, dispatch] = React.useReducer(reducer, initialState)

  const value: RealtimeSectionsContextProps = {
    realtimePostProgress,
    setRealtimePostProgress,
    sections: realtimeState.sections,
    dispatch,
  }

  return <RealtimeSectionsContext.Provider value={value}>{props.children}</RealtimeSectionsContext.Provider>
}
