/* tslint:disable */
/* eslint-disable */
/**
 * BFF Web Product
 * API reference used to call the backend from the web frontend
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { PlanDto } from './plan-dto';

/**
 * 
 * @export
 * @interface RecurringPlansDto
 */
export interface RecurringPlansDto {
    /**
     * Is charged in a recurring way, according to the selected plan.
     * @type {string}
     * @memberof RecurringPlansDto
     */
    'name': RecurringPlansDtoNameEnum;
    /**
     * Mutiple plans (montly, yearly, etc) the user might opt for when purchasing     the extra offer.
     * @type {Array<PlanDto>}
     * @memberof RecurringPlansDto
     */
    'items': Array<PlanDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum RecurringPlansDtoNameEnum {
    RECURRING = 'RECURRING'
}


