import { AccountVerificationModal, AccountVerificationProvider } from '@hub-la/fe-account-verification'
import { VerificationStatus, useGetIdentity, useGetPayout } from '@hub-la/fe-get-user'
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Separator,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@hub-la/shadcn'
import { Edit, HelpCircle } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Document } from '../../../domain/vos/document'
import { formatAddress } from '../../../domain/vos/format-address'
import { Phone } from '../../../domain/vos/phone'
import { BankAccount } from './bank-account'
import { StatusTag } from './status-tag'

const InfoRow = ({
  label,
  value,
  tooltip,
}: {
  label?: string
  value: string | React.ReactNode | null | undefined
  tooltip?: string
}) => (
  <div className="items-start mb-2">
    <div className="w-auto pr-2 flex items-center">
      {label && <p className="text-sm text-gray-500">{label}</p>}
      {tooltip && (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <HelpCircle className="w-4 h-4 ml-1 text-gray-400" />
            </TooltipTrigger>
            <TooltipContent>
              <p>{tooltip}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
    </div>
    <div>
      <p className="text-sm">{value || 'Não disponível'}</p>
    </div>
  </div>
)

export const AccountInfo = () => {
  const [open, setOpen] = useState(false)
  const { data, isError } = useGetIdentity()
  const { data: payout, refetch } = useGetPayout()

  const { t } = useTranslation()

  const canEditIdentity = [undefined, VerificationStatus.REJECTED, VerificationStatus.UNSPECIFIED].includes(
    data?.identity?.verification?.status,
  )

  const isIdentityRejected = data?.identity?.verification?.status === VerificationStatus.REJECTED

  return (
    <div className="max-w-2xl py-6">
      {/* Dados Empresariais */}
      <Card className="w-full mb-6">
        <CardHeader>
          <CardTitle>{t('Dados empresariais')}</CardTitle>
          <CardDescription>{t('Informações sobre sua empresa')}</CardDescription>
        </CardHeader>
        <Separator />
        <CardContent>
          <div className="grid grid-cols-1 gap-8 my-6">
            <div className="flex justify-between items-center">
              <StatusTag status={data?.identity?.verification?.status} />
              {canEditIdentity && (
                <Button variant={isIdentityRejected ? 'default' : 'outline'} size="sm" onClick={() => setOpen(true)}>
                  <Edit className="mr-2 h-4 w-4" />
                  Atualizar
                </Button>
              )}
            </div>
            {!isError && (
              <InfoRow
                label={t('Tipo de empresa')}
                value={
                  data?.identity?.document?.type
                    ? t(`bankAccount.documentTypeTag.${data?.identity?.document?.type}`)
                    : undefined
                }
              />
            )}
          </div>
        </CardContent>
      </Card>

      {/* Dados Pessoais */}
      <Card className="w-full mb-6">
        <CardHeader>
          <CardTitle>{t('Dados pessoais')}</CardTitle>
          <CardDescription>{t('Informações pessoais do responsável')}</CardDescription>
        </CardHeader>
        <Separator />
        <CardContent>
          <div className="grid grid-cols-1 gap-8 my-6">
            <div className="flex justify-between items-center">
              <StatusTag status={data?.identity?.verification?.status} />
              {canEditIdentity && (
                <Button variant={isIdentityRejected ? 'default' : 'outline'} size="sm" onClick={() => setOpen(true)}>
                  <Edit className="mr-2 h-4 w-4" />
                  Atualizar
                </Button>
              )}
            </div>
            {!isError && (
              <>
                <InfoRow label={t('bankAccount.fullName')} value={data?.identity?.fullName} />
                <InfoRow
                  label={
                    data?.identity?.document?.type
                      ? t(`bankAccount.documentTypeEnum.${data?.identity?.document?.type}`)
                      : undefined
                  }
                  value={
                    data?.identity?.document?.value
                      ? Document.build(data?.identity?.document?.value).getValue()
                      : undefined
                  }
                />
                <InfoRow label={t('bankAccount.email')} value={data?.identity?.email} />
                {data?.identity?.phone && (
                  <InfoRow
                    label={t('bankAccount.phoneNumber')}
                    value={Phone.build(data?.identity?.phone).getFormattedNumber()}
                  />
                )}
                {data?.identity?.billingAddress?.postalCode && (
                  <InfoRow label={t('Endereço')} value={formatAddress(data?.identity.billingAddress)} />
                )}
              </>
            )}
          </div>
        </CardContent>
      </Card>

      {/* Dados Bancários */}
      <BankAccount data={data} payout={payout} onFinish={refetch} />

      {/* Account Verification Modal */}
      <AccountVerificationProvider>
        <AccountVerificationModal open={open} onClose={() => setOpen(false)} />
      </AccountVerificationProvider>
    </div>
  )
}
