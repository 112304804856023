import {
  Alert,
  AlertDescription,
  AlertTitle,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@hub-la/shadcn'
import { useFormikContext } from 'formik'
import { AlertTriangle } from 'lucide-react'
import React from 'react'
import { Document } from '../../domain/vos/document'
import { UpdatePayoutValues } from '../pages/update-payout-modal'
import { BankAccountForm } from './bank-account-form'
import { PixKeyForm } from './pix-key-form'

type Props = {
  documentValue?: string
  fullName?: string
}

export const WithdrawalForm: React.FC<Props> = ({ fullName, documentValue }) => {
  const { values, setFieldValue } = useFormikContext<UpdatePayoutValues>()

  const handleChange = (value: string) => {
    setFieldValue('isPixKey', value === 'true')
  }

  return (
    <div className="flex flex-col space-y-4">
      <Select value={values.isPixKey.toString()} onValueChange={handleChange}>
        <SelectTrigger className="w-full">
          <SelectValue placeholder="Método de recebimento" />
        </SelectTrigger>
        <SelectContent className="z-[6000]">
          <SelectItem value="true">Chave Pix</SelectItem>
          <SelectItem value="false">Conta bancária</SelectItem>
        </SelectContent>
      </Select>

      <Alert variant="destructive" className="bg-amber-100 border-amber-300">
        <AlertTriangle className="h-4 w-4 text-amber-500 flex-shrink-0" />

        {values.isPixKey ? (
          <AlertTitle className="text-sm text-amber-700">
            Para saques na modalidade Pix, utilizaremos como chave o mesmo documento informado nos dados do titular da
            conta.
          </AlertTitle>
        ) : (
          <>
            <AlertTitle className="text-sm text-amber-700">
              A conta bancária que recebe repasses precisa estar associada ao mesmo ID fiscal (CPF ou CNPJ) da pessoa
              física ou jurídica registrada na sua conta Hubla.
            </AlertTitle>
            {fullName && <AlertDescription className="font-bold text-sm text-amber-700">{fullName}</AlertDescription>}
            {documentValue && (
              <AlertDescription className="font-bold text-sm text-amber-700">
                {new Document(documentValue).getValue()}
              </AlertDescription>
            )}
          </>
        )}
      </Alert>

      {values.isPixKey ? <PixKeyForm pixKey={new Document(documentValue ?? '').getValue()} /> : <BankAccountForm />}
    </div>
  )
}
