import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { GetPendencies } from '../../usecases/get-pendencies'
import { QueryKey } from '../../domain/enums/query-key'

export const useGetPendencies = () => {
  const container = useContainer()

  return useQuery([QueryKey.getPendencies], () => new GetPendencies(container.get(HttpClient)).execute(), {
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    initialData: {
      unpaid: [],
      overdue: [],
    },
  })
}
