import {
  Badge,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@hub-la/shadcn'
import { BarChart, Info } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { TableEmptyState } from '@hub-la/shadcn'
import isEmpty from 'lodash/isEmpty'
import times from 'lodash/times'
import { ShortLink } from '../../../../domain/dtos/short-link'
import { CreateEditShortLinkType } from '../../../../domain/enums/create-edit-shortlink-type'
import { NumberAbbreviation } from '../../../../domain/vos/number-abbreviation'
import { CopyClipboardTag } from '../components/copy-clipboard-tag'
import { UTMTags } from '../components/utm-tags'
import { MenuMoreOptions } from './menu-more-options'
import { TableLoading } from './table-loading'

type ShortLinksTableProps = {
  productId: string
  isLoading: boolean
  items: ShortLink[]
  total: number
  setShowCreateEditModal: (state: {
    isOpen: boolean
    shortLink: ShortLink | null
    type: CreateEditShortLinkType
  }) => void
  onUpdate: () => void
}

export const ShortLinksTable: React.FC<ShortLinksTableProps> = ({
  productId,
  isLoading,
  items,
  total,
  setShowCreateEditModal,
  onUpdate,
}) => {
  const { t } = useTranslation()
  const isRowsEmpty = isEmpty(items)
  const canRenderEmptyState = !isLoading && isRowsEmpty

  return (
    <div className="border bg-white rounded-md overflow-hidden">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="min-w-[200px]">{t('shortlinks.table.name')}</TableHead>
            <TableHead>
              <div className="flex items-center gap-2">
                {t('shortlinks.table.url')}
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <Info className="h-4 w-4" />
                    </TooltipTrigger>
                    <TooltipContent>{t('shortlinks.table.tooltips.url')}</TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            </TableHead>
            <TableHead>
              <div className="flex items-center gap-2">
                {t('shortlinks.table.utms')}
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <Info className="h-4 w-4" />
                    </TooltipTrigger>
                    <TooltipContent>{t('shortlinks.table.tooltips.utms')}</TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            </TableHead>
            <TableHead>
              <div className="flex items-center gap-2">
                {t('shortlinks.table.clicks')}
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <Info className="h-4 w-4" />
                    </TooltipTrigger>
                    <TooltipContent>{t('shortlinks.table.tooltips.clicks')}</TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            </TableHead>
            <TableHead className="w-[80px]"></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {(isLoading && isRowsEmpty) || isLoading ? (
            times(5, (idx) => <TableLoading key={`skeleton-row-${idx}`} />)
          ) : canRenderEmptyState ? (
            <TableRow>
              <TableCell colSpan={5} className="h-24 text-center">
                <TableEmptyState title={t('shortlinks.table.emptyStateTitle')} />
              </TableCell>
            </TableRow>
          ) : (
            items.map((shortlink: ShortLink) => (
              <TableRow key={shortlink.id} className="cursor-pointer">
                <TableCell
                  className="py-2"
                  onClick={() =>
                    setShowCreateEditModal({
                      isOpen: true,
                      shortLink: shortlink,
                      type: CreateEditShortLinkType.EDIT,
                    })
                  }
                >
                  <div className="flex items-center gap-2">
                    <span>{shortlink.name}</span>
                    {shortlink.isDefault && (
                      <Badge variant="secondary" className="text-xs px-2 py-0.5">
                        {t('shortlinks.table.isDefault')}
                      </Badge>
                    )}
                  </div>
                  <p className="text-sm text-gray-500">{t(`shortlinks.type.${shortlink.type}`)}</p>
                </TableCell>
                <TableCell className="p-0">
                  <CopyClipboardTag title={`${shortlink.slug ?? shortlink.id}`} />
                </TableCell>
                <TableCell
                  className="py-2"
                  onClick={() =>
                    setShowCreateEditModal({
                      isOpen: true,
                      shortLink: shortlink,
                      type: CreateEditShortLinkType.EDIT,
                    })
                  }
                >
                  <UTMTags utm={shortlink.utm} />
                </TableCell>
                <TableCell
                  className="py-2"
                  onClick={() =>
                    setShowCreateEditModal({
                      isOpen: true,
                      shortLink: shortlink,
                      type: CreateEditShortLinkType.EDIT,
                    })
                  }
                >
                  <Badge
                    variant="secondary"
                    className={`gap-1 ${shortlink.clickCount > 0 ? 'bg-green-100 text-green-800' : ''}`}
                  >
                    <BarChart className="h-4 w-4" />
                    {`${NumberAbbreviation.build(shortlink.clickCount).getValue()} cliques`}
                  </Badge>
                </TableCell>
                <TableCell className="p-0" onClick={(e) => e.stopPropagation()}>
                  <MenuMoreOptions
                    productId={productId}
                    shortLink={shortlink}
                    setShowCreateEditModal={setShowCreateEditModal}
                    onUpdate={onUpdate}
                  />
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </div>
  )
}
