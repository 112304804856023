import { useTranslation } from 'react-i18next'
import { IntegrationProvider } from '../../domain/dtos/integration-provider'
import { AboutDetail } from './about-detail/about-detail'
import { IntegrationCard } from './integration-card'
import { NotificationAlert } from './notification-alert'

type IntegrationAboutTabProps = {
  provider: IntegrationProvider
  integrationId?: string
  authError?: string
  loading: boolean
  refetch: () => void
  setTab: (name: string) => void
}

export const IntegrationAboutTab = (props: IntegrationAboutTabProps) => {
  const { provider, integrationId, authError, loading, refetch, setTab } = props
  const { t } = useTranslation()

  return (
    <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
      <div className="md:col-span-4 lg:col-span-3">
        <IntegrationCard
          provider={provider}
          loading={loading}
          refetch={refetch}
          integrationId={integrationId}
          setTab={setTab}
        />
      </div>

      <div className="md:col-span-8 lg:col-span-9">
        {authError && (
          <div className="mb-6">
            <NotificationAlert
              title={t(`errors.account.title`)}
              message={t(`errors.account.message`, { message: authError })}
              variant="destructive"
            />
          </div>
        )}

        <AboutDetail provider={provider} />
      </div>
    </div>
  )
}
