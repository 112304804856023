import { Box, useUtility } from '@hub-la/design-system'
import { AssetType } from '@hub-la/fe-asset'
import { Post } from '@hub-la/fe-post'

interface Props {
  post: Post | null | undefined
  progress?: number
}

export const ProgressBar = ({ post, progress }: Props) => {
  const { palette } = useUtility()

  const getDuration = (cover): number => {
    switch (cover.type) {
      case AssetType.application:
        return cover.metadata ? cover.metadata.pages ?? 0 : 0
      case AssetType.video:
        return cover.metadata ? cover.metadata.duration ?? 0 : 0
      default:
        return 0
    }
  }

  const calcPorcentageProgress = (progress: number, total: number) => {
    return (progress / total) * 100
  }

  const calcWidthBar = (): number => {
    const cover = post?.cover
    const viewed = post?.viewed

    if (cover) {
      const canCalculatePercentageProgress = [AssetType.application, AssetType.video].includes(cover.type)
      if (canCalculatePercentageProgress) {
        return calcPorcentageProgress(progress ?? (post.cover?.progress?.currentProgress || 0), getDuration(cover))
      }

      if (cover.type === AssetType.image) {
        return viewed ? 100 : 0
      }

      return 0
    }

    return viewed ? 100 : 0
  }

  const progressBar = calcWidthBar()

  return (
    <Box position="absolute" height="3px" left={0} right={0} bottom={1} paddingX={0.25}>
      <Box
        data-testid="publication-progress-content"
        position="relative"
        bgcolor={palette('outline')}
        height="3px"
        borderRadius="20px"
      >
        {progressBar !== 0 && (
          <Box
            data-testid="publication-progress-bar"
            position="absolute"
            height="3px"
            left={0}
            right={0}
            bottom={0}
            bgcolor={palette('errorContainer')}
            width={`${progressBar}%`}
            borderRadius="20px"
          >
            <div />
          </Box>
        )}
      </Box>
    </Box>
  )
}
