import { Checkbox, Label, RadioGroup, RadioGroupItem } from '@hub-la/shadcn'
import { Loader } from 'lucide-react'
import React, { useEffect } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CurrencyField } from '../../../../../components/currency-field'
import { InstallmentsSelect } from '../../../../../components/installments-select'
import { SwitchToggleChildren } from '../../../../../components/switch-toggle-children'
import { OfferSchemaValidationType } from '../../../../../validations/offer-validation'

export const Options: React.FC = () => {
  const { t } = useTranslation()

  const { setValue, getValues, control } = useFormContext<OfferSchemaValidationType>()

  const priceType = useWatch({
    name: 'pricing.priceType',
    control,
  })
  const allowedDebit = getValues('pricing.smartInstallment.allowedDebit')

  const isRecurringPrice = priceType === 'recurring'
  useEffect(() => {
    if (!isRecurringPrice) {
      setValue('pricing.isMembershipFee', false)
    }
  }, [isRecurringPrice, setValue])

  return (
    <div className="space-y-6 py-2">
      <SwitchToggleChildren
        name="pricing.isMembershipFee"
        disabled={!isRecurringPrice}
        label={t('offer.pricingTab.options.membershipFee.title')}
      >
        <div className="space-y-4">
          <Controller
            name="pricing.membershipFee.price"
            render={({ field, fieldState }) => (
              <CurrencyField
                label={t('offer.pricingTab.options.membershipFee.total')}
                error={Boolean(fieldState.error?.type)}
                helperText={fieldState.error?.type && t(`fieldErrors.${fieldState.error?.type}`)}
                {...field}
              />
            )}
          />

          <Controller
            name="pricing.membershipFee.installments"
            render={({ field, fieldState }) => (
              <InstallmentsSelect
                label={t('offer.pricingTab.options.membershipFee.installments')}
                {...field}
                error={Boolean(fieldState.error?.type)}
                helperText={fieldState.error?.type && t(`fieldErrors.${fieldState.error?.type}`)}
              />
            )}
          />
        </div>
        <p className="text-xs mt-1.5 text-gray-600">{t('offer.pricingTab.options.membershipFee.description')}</p>
      </SwitchToggleChildren>

      <SwitchToggleChildren
        name="pricing.isSmartInstallment"
        label={t('offer.pricingTab.options.smartInstallments.title')}
      >
        <div className="space-y-4">
          <p className="text-sm text-gray-600">{t('offer.pricingTab.options.smartInstallments.description')}</p>
          <div className="p-3 border border-gray-200 rounded-lg flex items-center space-x-4 bg-white">
            <div className="p-3 border border-gray-200 rounded-md bg-gray-100">
              <Loader className="w-6 h-6 text-gray-600" />
            </div>
            <p className="text-sm text-gray-600">{t('offer.pricingTab.options.smartInstallments.warning')}</p>
          </div>

          <Controller
            name="pricing.smartInstallment.installments"
            rules={{ required: true }}
            render={({ field }) => (
              <InstallmentsSelect
                minInstallment={2}
                label={t('offer.pricingTab.options.smartInstallments.maxInstallments')}
                {...field}
              />
            )}
          />

          <RadioGroup
            defaultValue={allowedDebit.toString()}
            onValueChange={(value) =>
              setValue('pricing.smartInstallment.allowedDebit', value === 'true', {
                shouldDirty: true,
                shouldTouch: true,
              })
            }
          >
            <div className="space-y-2">
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="true" id="allowed-debit-true" />
                <Label htmlFor="allowed-debit-true">
                  {t('offer.pricingTab.options.smartInstallments.installmentsWithDebt.label')}
                </Label>
              </div>
              <p className="text-sm text-gray-600 ml-6">
                {t('offer.pricingTab.options.smartInstallments.installmentsWithDebt.description')}
              </p>
            </div>
            <div className="space-y-2 mt-2">
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="false" id="allowed-debit-false" />
                <Label htmlFor="allowed-debit-false">
                  {t('offer.pricingTab.options.smartInstallments.installmentsWithoutDebt.label')}
                </Label>
              </div>
              <p className="text-sm text-gray-600 ml-6">
                {t('offer.pricingTab.options.smartInstallments.installmentsWithoutDebt.description')}
              </p>
            </div>
          </RadioGroup>
        </div>
      </SwitchToggleChildren>

      <Controller
        name="isAvailableForAffiliates"
        render={({ field }) => (
          <div className="flex items-center space-x-2">
            <Checkbox id="affiliates-switch" checked={field.value} onCheckedChange={field.onChange} />
            <Label htmlFor="affiliates-switch">{t('offer.pricingTab.options.affiliatesSwitch')}</Label>
          </div>
        )}
      />
    </div>
  )
}
