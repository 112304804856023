import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { createEnumParam, DelimitedArrayParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { Period } from '../../../domain/dtos/period'
import { GetDateRangeFromPeriod } from '../../../usecases/get-date-range-from-period'
import { useGetOffers } from '../../hooks/use-get-offers'
import { AbandonedCheckout } from './abandoned-checkout/abandoned-checkout'
import { AverageTicket } from './average-ticket/average-ticket'
import { BankSlipConversion } from './bank-slip-conversion/bank-slip-conversion'
import { CreditCardApprovalRate } from './credit-card-approval-rate/credit-card-approval-rate'
import { Filters } from './filters'
import { PixConversion } from './pix-conversion/pix-conversion'
import { Revenue } from './revenue/revenue'
import { TotalRefund } from './total-refund/total-refund'
import { TotalSale } from './total-sale/total-sale'

export const DashboardHome = memo(() => {
  const { t } = useTranslation()
  const { data: offers = [], isFetching } = useGetOffers()
  const isLoadingOffers = isFetching && offers.length <= 0

  const initialDateFilter = new GetDateRangeFromPeriod().execute(Period.MONTHLY)
  const PeriodEnumParam = createEnumParam([Period.HOURLY, Period.DAILY, Period.WEEKLY, Period.MONTHLY, Period.YEARLY])
  const [filters, setFilters] = useQueryParams({
    period: withDefault(PeriodEnumParam, Period.DAILY),
    startDate: withDefault(StringParam, initialDateFilter.from),
    endDate: withDefault(StringParam, initialDateFilter.to),
    offerIds: withDefault(DelimitedArrayParam, []),
  })
  const { startDate, endDate, offerIds } = filters

  return (
    <div>
      <h1 className="text-3xl font-semibold leading-tight mb-4">{t('title')}</h1>
      <Filters filters={filters} setFilters={setFilters} offers={offers} />
      <div className="flex flex-col">
        <div className="grid md:grid-cols-3 gap-6">
          <div className="col-span-3 md:col-span-2 row-span-3">
            <Revenue filters={filters} setFilters={setFilters} offers={offers} isLoading={isLoadingOffers} />
          </div>

          <div className="col-span-3 md:col-span-1">
            <TotalSale
              startDate={startDate}
              endDate={endDate}
              offers={offers}
              offerIds={offerIds as string[]}
              isLoading={isLoadingOffers}
            />
          </div>

          <div className="col-span-3 md:col-span-1">
            <AbandonedCheckout
              startDate={startDate}
              endDate={endDate}
              offers={offers}
              offerIds={offerIds as string[]}
              isLoading={isLoadingOffers}
            />
          </div>

          <div className="col-span-3 md:col-span-1">
            <TotalRefund
              startDate={startDate}
              endDate={endDate}
              offers={offers}
              offerIds={offerIds as string[]}
              isLoading={isLoadingOffers}
            />
          </div>

          <div className="col-span-3 md:col-span-1">
            <CreditCardApprovalRate
              startDate={startDate}
              endDate={endDate}
              offers={offers}
              offerIds={offerIds as string[]}
              isLoading={isLoadingOffers}
            />
          </div>

          <div className="col-span-3 md:col-span-1">
            <PixConversion
              startDate={startDate}
              endDate={endDate}
              offers={offers}
              offerIds={offerIds as string[]}
              isLoading={isLoadingOffers}
            />
          </div>

          <div className="col-span-3 md:col-span-1">
            <BankSlipConversion
              startDate={startDate}
              endDate={endDate}
              offers={offers}
              offerIds={offerIds as string[]}
              isLoading={isLoadingOffers}
            />
          </div>

          <div className="col-span-3 md:col-span-1">
            <AverageTicket
              startDate={startDate}
              endDate={endDate}
              offers={offers}
              offerIds={offerIds as string[]}
              isLoading={isLoadingOffers}
            />
          </div>

          {/* Blank spaces */}
          <div className=""></div>
          <div className=""></div>
        </div>
      </div>
    </div>
  )
})
