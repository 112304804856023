import {
  Badge,
  Button,
  cn,
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Separator,
} from '@hub-la/shadcn'
import { CheckIcon, ChevronRight, PlusCircle } from 'lucide-react'
import React, { useMemo, useState } from 'react'

type Offer = {
  id: string
  name: string
  product?: {
    id: string
    name: string
  }
}

type Props = {
  offers: Offer[]
  filters: {
    offerIds: string[]
  }
  setFilters: (filters: { offerIds: string[] }) => void
}

export const SelectOffers: React.FC<Props> = ({ offers, filters, setFilters }) => {
  const { offerIds } = filters
  const [searchTerm, setSearchTerm] = useState('')

  const groupedOffers = useMemo(() => {
    return offers.reduce((acc, offer) => {
      if (!offer.product) {
        if (!acc['uncategorized']) {
          acc['uncategorized'] = {
            product: { id: 'uncategorized', name: 'Uncategorized' },
            offers: [],
          }
        }
        acc['uncategorized'].offers.push(offer)
      } else {
        const productId = offer.product.id
        if (!acc[productId]) {
          acc[productId] = {
            product: offer.product,
            offers: [],
          }
        }
        acc[productId].offers.push(offer)
      }
      return acc
    }, {} as Record<string, { product: { id: string; name: string }; offers: Offer[] }>)
  }, [offers])

  const handleItemChange = (itemId: string, isSingleOffer = false) => {
    let newOfferIds: string[]
    if (isSingleOffer) {
      newOfferIds = offerIds.includes(itemId) ? offerIds.filter((id) => id !== itemId) : [...offerIds, itemId]
    } else {
      const productOffers = groupedOffers[itemId]?.offers || []
      const allProductOfferIds = productOffers.map((o) => o.id)
      const allSelected = allProductOfferIds.every((id) => offerIds.includes(id))

      if (allSelected) {
        newOfferIds = offerIds.filter((id) => !allProductOfferIds.includes(id))
      } else {
        newOfferIds = [...new Set([...offerIds, ...allProductOfferIds])]
      }
    }

    setFilters({ ...filters, offerIds: newOfferIds })
  }

  const handleClearFilters = () => {
    setFilters({ ...filters, offerIds: [] })
  }

  const isItemSelected = (itemId: string, isSingleOffer = false) => {
    if (isSingleOffer) {
      return offerIds.includes(itemId)
    }
    const productOffers = groupedOffers[itemId]?.offers || []
    return productOffers.every((offer) => offerIds.includes(offer.id))
  }

  const getSelectedProductsInfo = () => {
    return Object.values(groupedOffers).reduce((acc, { product, offers }) => {
      const selectedOffers = offers.filter((offer) => offerIds.includes(offer.id))
      if (selectedOffers.length > 0) {
        acc.push({
          productName: product.name,
          selectedOffersCount: selectedOffers.length,
          totalOffersCount: offers.length,
        })
      }
      return acc
    }, [] as Array<{ productName: string; selectedOffersCount: number; totalOffersCount: number }>)
  }

  const renderFilterBadges = () => {
    const selectedProductsInfo = getSelectedProductsInfo()
    if (selectedProductsInfo.length === 0) return null

    if (selectedProductsInfo.length === 1) {
      const { productName, selectedOffersCount, totalOffersCount } = selectedProductsInfo[0]
      return (
        <>
          <Separator orientation="vertical" className="mx-2 h-4" />
          <Badge variant="secondary" className="ml-1">
            {productName}
            {totalOffersCount > 1 && ` (${selectedOffersCount}/${totalOffersCount})`}
          </Badge>
        </>
      )
    }

    return (
      <>
        <Separator orientation="vertical" className="mx-2 h-4" />
        <Badge variant="secondary" className="ml-1">
          {selectedProductsInfo.length} selecionados
        </Badge>
      </>
    )
  }

  function trimWithEllipsis(input: string, maxLength: number): string {
    if (input.length <= maxLength) return input
    return input.slice(0, maxLength - 3) + '...'
  }

  const filteredGroupedOffers = useMemo(() => {
    if (!searchTerm) return groupedOffers
    return Object.entries(groupedOffers).reduce((acc, [productId, { product, offers }]) => {
      const filteredOffers = offers.filter((offer) => offer.name.toLowerCase().includes(searchTerm.toLowerCase()))
      if (product.name.toLowerCase().includes(searchTerm.toLowerCase()) || filteredOffers.length > 0) {
        acc[productId] = { product, offers: filteredOffers }
      }
      return acc
    }, {} as typeof groupedOffers)
  }, [groupedOffers, searchTerm])

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" className="flex items-center space-x-1">
          <PlusCircle className="h-4 w-4 mr-1" />
          <span className="text-sm">Produto(s)</span>
          {renderFilterBadges()}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[350px] p-0 z-10002" align="start">
        <Command className="border-none">
          <CommandInput placeholder="Produtos" value={searchTerm} onValueChange={setSearchTerm} />
          <div className="max-h-[300px] overflow-y-auto">
            <CommandList>
              {Object.entries(filteredGroupedOffers).map(([productId, { product, offers }]) => (
                <CommandGroup key={productId}>
                  {offers.length === 1 ? (
                    <CommandItem onSelect={() => handleItemChange(offers[0].id, true)} className="px-2 py-1.5">
                      <div className="flex items-center w-full">
                        <div
                          className={cn(
                            'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                            isItemSelected(offers[0].id, true)
                              ? 'bg-primary text-primary-foreground'
                              : 'opacity-50 [&_svg]:invisible',
                          )}
                        >
                          <CheckIcon className={cn('h-4 w-4')} />
                        </div>
                        <span className="inline-flex truncate font-medium text-black">{product.name}</span>
                      </div>
                    </CommandItem>
                  ) : (
                    <>
                      <CommandItem onSelect={() => handleItemChange(productId)} className="px-2 py-1.5">
                        <div className="flex items-center w-full">
                          <div
                            className={cn(
                              'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                              isItemSelected(productId)
                                ? 'bg-primary text-primary-foreground'
                                : 'opacity-50 [&_svg]:invisible',
                            )}
                          >
                            <CheckIcon className={cn('h-4 w-4')} />
                          </div>
                          <span className="inline-flex truncate font-medium text-black">{product.name}</span>
                          <ChevronRight className="ml-1 h-4 w-4 text-gray-500" />
                          <span className="ml-1 text-sm text-gray-500">{offers.length} ofertas</span>
                        </div>
                      </CommandItem>
                      {offers.map((offer) => (
                        <CommandItem
                          key={offer.id}
                          onSelect={() => handleItemChange(offer.id, true)}
                          className="px-2 py-1.5 pl-8"
                        >
                          <div className="flex items-center w-full">
                            <div
                              className={cn(
                                'mr-2 inline-flex h-4 items-center justify-center rounded-sm border border-primary',
                                offerIds.includes(offer.id)
                                  ? 'bg-primary text-primary-foreground'
                                  : 'opacity-50 [&_svg]:invisible',
                              )}
                            >
                              <CheckIcon className={cn('h-4 w-4')} />
                            </div>
                            <span className="truncate font-medium text-muted-foreground">
                              {trimWithEllipsis(product.name, 12)}
                            </span>
                            <ChevronRight className="mx-1 h-4 w-4 text-gray-500" />
                            <span className="flex-grow truncate text-black">{offer.name}</span>
                          </div>
                        </CommandItem>
                      ))}
                    </>
                  )}
                </CommandGroup>
              ))}
            </CommandList>
          </div>
          {offerIds.length > 0 && (
            <>
              <CommandSeparator />
              <CommandList>
                <CommandGroup>
                  <CommandItem onSelect={handleClearFilters} className="justify-center text-center">
                    Limpar filtros
                  </CommandItem>
                </CommandGroup>
              </CommandList>
            </>
          )}
        </Command>
      </PopoverContent>
    </Popover>
  )
}
function trimWithEllipsis(
  name: string,
  arg1: number,
): import('react-i18next').ReactI18NextChild | Iterable<import('react-i18next').ReactI18NextChild> {
  throw new Error('Function not implemented.')
}
