import { Tabs, TabsContent, TabsList, TabsTrigger } from '@hub-la/shadcn'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Content } from './content'
import { Groups } from './groups'
import CustomMemberArea from './settings'

type Props = {
  offerId?: string
}

export const MemberAreaPage: React.FC<Props> = ({ offerId }) => {
  const [tab, setTab] = useState<string>(new URLSearchParams(window.location.search).get('tab') ?? 'content')
  const { t } = useTranslation()
  const history = useHistory()

  const onTabChange = (tab: string) => {
    history.push(history.location.pathname + '?tab=' + tab)
    setTab(tab)
  }

  return (
    <div className="flex flex-col gap-4 py-6">
      <h1 className="text-xl font-semibold">Área de membros</h1>

      <Tabs value={tab} onValueChange={onTabChange}>
        <TabsList>
          <TabsTrigger value="content">{t('member-area.tabs.content')}</TabsTrigger>

          <TabsTrigger value="groups">{t('member-area.tabs.groups')}</TabsTrigger>

          <TabsTrigger value="settings">{t('member-area.tabs.settings')}</TabsTrigger>
        </TabsList>

        <TabsContent value="content">
          <Content />
        </TabsContent>

        <TabsContent value="groups">
          <Groups offerId={offerId} />
        </TabsContent>

        <TabsContent value="settings">
          <CustomMemberArea />
        </TabsContent>
      </Tabs>
    </div>
  )
}
