import { Button } from '@hub-la/shadcn'
import { ExternalLink } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { IntegrationProvider } from '../../../domain/dtos/integration-provider'
import { useCreateIntegration } from '../../hooks/use-create-integration'

type SellFluxCardProps = {
  provider: IntegrationProvider
  integrationId?: string
  loading: boolean
  refetch: () => void
  setTab: (name: string) => void
}

export const SellFluxCardActions = ({ provider, integrationId, loading, refetch, setTab }: SellFluxCardProps) => {
  const { t } = useTranslation()

  const { isLoading, mutateAsync: createIntegration } = useCreateIntegration()

  const handleCreateIntegration = async () => {
    await createIntegration({ provider })
    refetch()
  }

  return (
    <div className="w-full flex flex-col items-center justify-between gap-2">
      {!integrationId ? (
        <Button className="w-full" loading={loading || isLoading} onClick={async () => handleCreateIntegration()}>
          {t(`buttons.activeIntegration`)}
        </Button>
      ) : (
        <Button className="w-full" variant="outline" loading={loading || isLoading} onClick={() => setTab('settings')}>
          {t(`buttons.settings`)}
        </Button>
      )}

      <a
        className="flex items-center gap-2 font-800 text-muted-foreground"
        href={t(`integrations.${provider}.about.link`)}
        target="_blank"
        rel="noreferrer"
      >
        {t('aboutTab.seeMore')}

        <ExternalLink fontSize="small" />
      </a>
    </div>
  )
}
