/* tslint:disable */
/* eslint-disable */
/**
 * BFF Web Product
 * API reference used to call the backend from the web frontend
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UpsellDto
 */
export interface UpsellDto {
    /**
     * Allows the buyer to purchase in a streamlined way a separate product configured by the       seller on the initial purchase\'s thank you page.
     * @type {string}
     * @memberof UpsellDto
     */
    'name': UpsellDtoNameEnum;
    /**
     * Media to present to users as part of preseting the upsell offer. Currently only Youtube       URLs are supported, for example \"https://youtu.be/bQCDrgcZVw0\".
     * @type {string}
     * @memberof UpsellDto
     */
    'mediaURL': string;
}

/**
    * @export
    * @enum {string}
    */
export enum UpsellDtoNameEnum {
    UPSELL = 'UPSELL'
}


