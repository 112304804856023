import { CopyClipboard } from '@hub-la/fe-copy-clipboard'
import { PermissionResource, useRoleplay } from '@hub-la/fe-roleplay'
import {
  Badge,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  useToast,
} from '@hub-la/shadcn'
import times from 'lodash/times'
import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { TableEmptyState, TablePagination } from '@hub-la/shadcn'
import { MakeInviteUrl } from '../../../../usecases/members/make-invite-url'
import { GenericConfirmModal } from '../../../components/generic-confirm-modal'
import { useGetPendingInvites } from '../../../hooks/members/use-get-pending-invites'
import { usePutCancelInvite } from '../../../hooks/members/use-put-cancel-invite'
import { Actions } from './actions'
import { RowInviteActions } from './row-invite-actions'

const SkeletonRow = () => (
  <TableRow>
    <TableCell className="w-full">
      <Skeleton className="h-6 w-4/5" />
    </TableCell>
    <TableCell className="w-[130px]">
      <Skeleton className="h-6 w-full" />
    </TableCell>
    <TableCell className="w-[120px]">
      <Skeleton className="h-6 w-full" />
    </TableCell>
    <TableCell className="w-[120px]">
      <div className="flex justify-end gap-2">
        <Skeleton className="h-6 w-6" />
        <Skeleton className="h-6 w-6" />
      </div>
    </TableCell>
  </TableRow>
)

export const InvitesTable = () => {
  const { t } = useTranslation()
  const { productId } = useParams<{ productId: string }>()
  const { toast } = useToast()
  const { hasPermission } = useRoleplay()

  const [search, setSearch] = useState<string>('')
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(25)
  const [openCancelInviteModal, setOpenCancelInviteModal] = useState<{ isOpen: boolean; inviteId: string }>({
    isOpen: false,
    inviteId: '',
  })

  const { data: pendingInvites, isLoading } = useGetPendingInvites({
    productId,
    page,
    pageSize,
    search,
  })

  const { mutateAsync: cancelInvite, isLoading: cancelInviteLoading } = usePutCancelInvite({
    onSuccess: () => setOpenCancelInviteModal({ isOpen: false, inviteId: '' }),
  })

  const canRenderEmptyState = !isLoading && (pendingInvites?.items ?? []).length <= 0

  const handleCopyInviteLink = (inviteId: string) => {
    new CopyClipboard().execute(new MakeInviteUrl().execute(inviteId))
    toast({
      description: t('tabs.invites.successCopyLink'),
    })
  }

  return (
    <div className="flex flex-col gap-4">
      <Actions onSearchChange={setSearch} />
      <div className="border bg-white rounded-md">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead> {t('tabs.invites.table.email')}</TableHead>
              <TableHead>{t('tabs.invites.table.status')}</TableHead>
              <TableHead>{t('tabs.invites.table.createdAt')}</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isLoading && times(3, (idx) => <SkeletonRow key={`skeleton-row-${idx}`} />)}
            {!isLoading &&
              (pendingInvites?.items ?? []).map((invite) => (
                <TableRow key={invite?.id}>
                  <TableCell className="py-2 w-2/4">{invite?.receiverEmail}</TableCell>
                  <TableCell className="py-2">
                    <Badge variant="secondary">Pendente</Badge>
                  </TableCell>
                  <TableCell className="py-2">
                    {invite?.createdAt ? moment(invite?.createdAt).format('D MMM. YYYY') : '–'}
                  </TableCell>
                  <TableCell className="py-2">
                    <RowInviteActions
                      onCancelInvite={() => setOpenCancelInviteModal({ isOpen: true, inviteId: invite.id })}
                      onCopyInviteLink={() => handleCopyInviteLink(invite.id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            {canRenderEmptyState && (
              <TableRow>
                <TableCell colSpan={4} className="w-full">
                  <TableEmptyState title={t('emptyInvites')} subtitle={t('emptyInvitesSubtitle')} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      {hasPermission(PermissionResource.PRODUCTS_MANAGE_MEMBERS) && (
        <GenericConfirmModal
          title={t('tabs.invites.cancelInviteModal.title')}
          description={t('tabs.invites.cancelInviteModal.description')}
          onDelete={() => cancelInvite({ inviteId: openCancelInviteModal.inviteId })}
          onCancel={() => setOpenCancelInviteModal({ isOpen: false, inviteId: '' })}
          isOpen={openCancelInviteModal.isOpen}
          confirmText={t('tabs.invites.cancelInviteModal.confirmText')}
          isLoading={cancelInviteLoading}
        />
      )}

      <div className="flex justify-end px-2 mt-3 mb-3">
        <TablePagination
          page={page ?? 1}
          setPage={(page) => setPage(page)}
          lastPage={pendingInvites?.lastPage ?? 1}
          pageSize={pageSize ?? 25}
          setPageSize={(pageSize) => setPageSize(pageSize)}
        />
      </div>
    </div>
  )
}
