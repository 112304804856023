import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { CreateCollaboratorValues } from '../../domain/dtos/create-collaborator-values'
import { PermissionResource } from '../../domain/dtos/permissions-resources-enum'
import { ChevronDown, ChevronUp, Info } from 'lucide-react'
import { Switch, Button, Checkbox, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@hub-la/shadcn'

type SubPermissionProps = {
  name: string
  value: PermissionResource
}

export type PermissionFieldProps = {
  name: string
  value: PermissionResource
  description?: string
  subpermissions?: SubPermissionProps[]
}

export const PermissionField: React.FC<PermissionFieldProps> = ({ name, value, description, subpermissions }) => {
  const [isShowDropdown, setIsShowDropdown] = useState(true)
  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext<CreateCollaboratorValues>()

  const hasSubPermissions = subpermissions && subpermissions.length > 0
  const permissionIsEnabled = values.permissions.includes(value)

  const handleSwitchPermissionChange = (checked: boolean) => {
    const currentPermissions = values.permissions.slice()
    const permissionIndex = currentPermissions.indexOf(value)

    if (checked) {
      if (permissionIndex === -1) currentPermissions.push(value)
      if (hasSubPermissions) {
        subpermissions.forEach((sub) => {
          if (!currentPermissions.includes(sub.value)) {
            currentPermissions.push(sub.value)
          }
        })
      }
    } else {
      if (permissionIndex !== -1) currentPermissions.splice(permissionIndex, 1)
      if (hasSubPermissions) {
        subpermissions.forEach((sub) => {
          const subIndex = currentPermissions.indexOf(sub.value)
          if (subIndex !== -1) currentPermissions.splice(subIndex, 1)
        })
      }
    }

    setFieldValue('permissions', currentPermissions)
  }

  const handleCheckboxSubPermissionChange = (subValue: PermissionResource, checked: boolean) => {
    const permissions = values.permissions.slice()
    const permissionIndex = permissions.indexOf(subValue)

    if (checked && permissionIndex === -1) {
      permissions.push(subValue)
    } else if (!checked && permissionIndex !== -1) {
      permissions.splice(permissionIndex, 1)
    }

    setFieldValue('permissions', permissions)
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <Switch checked={permissionIsEnabled} onCheckedChange={handleSwitchPermissionChange} />
          <span className="text-sm font-medium">{name}</span>
          {description && (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Info className="h-4 w-4 text-gray-500" />
                </TooltipTrigger>
                <TooltipContent>
                  <p>{description}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>
        {hasSubPermissions && (
          <div className="flex items-center space-x-2">
            <span className={`text-sm ${!permissionIsEnabled ? 'text-gray-400' : ''}`}>
              {t('collaboratorForm.subpermissionsDetails')}
            </span>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => setIsShowDropdown(!isShowDropdown)}
              disabled={!permissionIsEnabled}
              className="p-1"
            >
              {permissionIsEnabled && isShowDropdown ? (
                <ChevronUp className="h-4 w-4" />
              ) : (
                <ChevronDown className="h-4 w-4" />
              )}
            </Button>
          </div>
        )}
      </div>
      {permissionIsEnabled && isShowDropdown && hasSubPermissions && (
        <div className="bg-gray-100 p-4 rounded-md space-y-2">
          {subpermissions.map((subpermission) => (
            <div key={subpermission.value} className="flex items-center space-x-2">
              <Checkbox
                id={subpermission.value}
                checked={values.permissions.includes(subpermission.value)}
                onCheckedChange={(checked) =>
                  handleCheckboxSubPermissionChange(subpermission.value, checked as boolean)
                }
              />
              <label
                htmlFor={subpermission.value}
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                {t(subpermission.name)}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
