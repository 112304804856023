import { OfferDto } from '@hub-la/sdk-bff-product'
import { Badge, Button } from '@hub-la/shadcn'
import { formatLocalNumber } from 'libs/frontend/modules/offer/src/lib/domain/vos/format-local-number'
import { Edit, Plus, Trash2 } from 'lucide-react'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PlaceholderLogo from '../../../../../assets/placeholder-image.svg'
import { SwitchToggleChildren } from '../../../../../components/switch-toggle-children'
import { OfferSchemaValidationType } from '../../../../../validations/offer-validation'
import { OrderBumpModal } from './orderbump-modal'

interface OrderBumpPreviewSelectedProps {
  orderBump: any
  onEdit: () => void
  onDelete: () => void
}

const OrderBumpPreviewSelected: React.FC<OrderBumpPreviewSelectedProps> = ({ orderBump, onEdit, onDelete }) => {
  return (
    <div className="flex items-center justify-between border border-gray-200 p-4 rounded-lg">
      <div className="flex items-center gap-3">
        <img
          src={orderBump?.product?.picture ?? PlaceholderLogo}
          alt="Product logo"
          className="w-[70px] h-[46px] object-cover rounded"
        />
        <span className="text-sm">{orderBump?.offer?.name}</span>
      </div>

      <div className="flex items-center gap-3">
        {orderBump?.offer?.price > 1 && (
          <Badge variant="secondary">
            {formatLocalNumber(orderBump?.offer?.price ? orderBump?.offer?.price / 100 : 0)}
          </Badge>
        )}
        <Button variant="ghost" size="icon" onClick={onEdit} type="button">
          <Edit className="h-4 w-4" />
        </Button>
        <Button variant="ghost" size="icon" onClick={onDelete} type="button">
          <Trash2 className="h-4 w-4" />
        </Button>
      </div>
    </div>
  )
}

export const OrderBumpSwitch: React.FC = () => {
  const { watch, setValue, resetField } = useFormContext<OfferSchemaValidationType>()
  const orderBump = watch('checkout.orderbump')
  const [orderbumpModal, setOrderBumpModal] = useState({
    isEdit: false,
    open: false,
  })
  const { t } = useTranslation()

  return (
    <>
      <OrderBumpModal
        open={orderbumpModal.open}
        onCancel={() => {
          if (!orderbumpModal.isEdit) {
            resetField('checkout.isOrderBump')
          }
          setOrderBumpModal({
            isEdit: false,
            open: false,
          })
        }}
        onSubmit={(values) => {
          setValue('checkout.orderbump.productId', values.productId, {
            shouldDirty: true,
          })
          setValue('checkout.orderbump.product', values.product, {
            shouldDirty: true,
          })
          setValue(
            'checkout.orderbump.offer',
            {
              ...values?.offer,
              price: values?.offer?.price ? values.offer.price * 100 : 0,
              plans: values?.offer?.plans?.map((plan) => ({ ...plan, price: plan.price * 100 })),
            } as OfferDto,
            {
              shouldDirty: true,
            },
          )
          setOrderBumpModal({
            isEdit: false,
            open: false,
          })
        }}
        defaultValues={orderBump}
        actionSave={
          orderbumpModal.isEdit
            ? t('offer.checkoutTab.options.orderBump.edit')
            : t('offer.checkoutTab.options.orderBump.add')
        }
        actionCancel={
          orderbumpModal.isEdit
            ? t('offer.checkoutTab.options.orderBump.cancel')
            : t('offer.checkoutTab.options.orderBump.close')
        }
      />
      <SwitchToggleChildren name="checkout.isOrderBump" label={t('offer.checkoutTab.options.orderBump.title')}>
        {!orderBump?.offer ? (
          <Button variant="outline" onClick={() => setOrderBumpModal({ isEdit: false, open: true })} type="button">
            <Plus className="mr-2 h-4 w-4" />
            {t('offer.checkoutTab.options.orderBump.add')}
          </Button>
        ) : (
          <OrderBumpPreviewSelected
            orderBump={orderBump}
            onEdit={() => setOrderBumpModal({ isEdit: true, open: true })}
            onDelete={() => setValue('checkout.orderbump.offer', null, { shouldDirty: true })}
          />
        )}
      </SwitchToggleChildren>
    </>
  )
}
