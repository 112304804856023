import { Badge } from '@hub-la/shadcn'

export const RemainingElements = ({ data = [], children }: { data: unknown[]; children }) => {
  const [firstElement] = data

  const length = data.length
  const amountRemainingElements = `+ ${length - 1}`

  return (
    <div className="flex flex-row items-center">
      {children(firstElement)}
      {length > 1 && (
        <Badge variant="secondary" className="ml-1 text-xs" data-testid="remaining-elements">
          {amountRemainingElements}
        </Badge>
      )}
    </div>
  )
}
