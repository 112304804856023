import { Badge, Input } from '@hub-la/shadcn'
import { useFormikContext } from 'formik'
import { X } from 'lucide-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AddFreeMemberForm } from '../../../domain/dtos/add-free-member-form'
import { FilterArrayByEmailValidation } from '../../../usecases/members/filter-array-by-email-validation'

const filterArrayByEmailValidation = new FilterArrayByEmailValidation()

type EmailsAutocompleteProps = {
  helperText?: string | null
  error?: boolean
}

const EmailsAutocomplete: React.FC<EmailsAutocompleteProps> = ({ error, helperText }) => {
  const { t } = useTranslation()
  const formik = useFormikContext<AddFreeMemberForm>()
  const [inputValue, setInputValue] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [emailHelperText, setEmailHelperText] = useState<string | null>(null)

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email.trim())
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    if (emailError) {
      setEmailError(false)
      setEmailHelperText(null)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === ',') {
      event.preventDefault()
      const emails = inputValue
        .replace(/[\n\s]+/g, ',')
        .split(',')
        .map((email) => email.trim())
        .filter((email) => email !== '')

      const invalidEmails = emails.filter((email) => !isValidEmail(email))
      if (invalidEmails.length > 0) {
        setEmailError(true)
        setEmailHelperText(t('addFreeMembersModal.inputs.emails.invalidEmail'))
        return
      }

      const validEmails = emails.filter(isValidEmail)
      if (validEmails.length > 0) {
        const newEmails = filterArrayByEmailValidation.execute(validEmails, formik.values.emails)
        formik.setFieldValue('emails', [...formik.values.emails, ...newEmails])
        setInputValue('')
      }
    }
  }

  const handleRemoveEmail = (emailToRemove: string) => {
    const updatedEmails = formik.values.emails.filter((email) => email !== emailToRemove)
    formik.setFieldValue('emails', updatedEmails)
  }

  return (
    <div className="space-y-2">
      <Input
        type="text"
        placeholder={t('addFreeMembersModal.inputs.emails.label')}
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        className="w-full p-2 border rounded-md"
      />
      <div className="flex flex-wrap gap-2 mt-2">
        {formik.values.emails.map((email, index) => (
          <Badge key={index} variant="secondary" className="flex items-center gap-1 px-2 py-1">
            {email}
            <button
              onClick={() => handleRemoveEmail(email)}
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
              aria-label={t('addFreeMembersModal.inputs.emails.removeEmail')}
            >
              <X size={14} />
            </button>
          </Badge>
        ))}
      </div>
      <p className={`text-sm ${emailError ? 'text-destructive' : 'text-muted-foreground'}`}>
        {emailError ? emailHelperText : t('addFreeMembersModal.inputs.emails.helperText')}
      </p>
    </div>
  )
}

export { EmailsAutocomplete }
