import { Button, Link, Stack, Text } from '@hub-la/design-system'
import { useGetUser } from '@hub-la/fe-get-user'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IntegrationProvider } from '../../../domain/dtos/integration-provider'
import { Envs } from '../../../envs'
import { DisableIntegrationModal } from '../disable-integration-modal'

type IntegrationCardProps = {
  provider: IntegrationProvider
  integrationId?: string
  loading: boolean
  refetch: () => void
  setTab: (name: string) => void
}

export const RDStationCardActions = (props: IntegrationCardProps) => {
  const { provider, integrationId, loading, refetch, setTab } = props
  const { t } = useTranslation()
  const { data: currentUser } = useGetUser()
  const [showDisableIntegration, setShowDisableIntegration] = useState(false)

  const connectRDStation = () => {
    window.location.href = `https://api.rd.services/auth/dialog?client_id=${Envs.RDSTATION_CLIENT_ID}&redirect_uri=${Envs.RDSTATION_REDIRECT_URI}&state=${provider}:${currentUser?.id}`
  }

  return (
    <Stack flexDirection="column" alignItems="center" justifyContent="space-between" gap={2} width="100%">
      {!integrationId ? (
        <Button
          variant="filled"
          size="medium"
          hierarchy="primary"
          fullWidth
          loading={loading}
          onClick={connectRDStation}
        >
          {t(`buttons.activeIntegration`)}
        </Button>
      ) : (
        <>
          <Button
            variant="outlined"
            size="medium"
            hierarchy="primary"
            fullWidth
            loading={loading}
            onClick={() => setTab('settings')}
          >
            {t(`buttons.settings`)}
          </Button>
          <Button
            variant="outlined"
            size="medium"
            hierarchy="primary"
            fullWidth
            loading={loading}
            onClick={() => setShowDisableIntegration(true)}
          >
            {t(`buttons.disable`)}
          </Button>
        </>
      )}

      {integrationId && (
        <DisableIntegrationModal
          integrationId={integrationId}
          provider={provider}
          isOpen={showDisableIntegration}
          onClose={() => setShowDisableIntegration(false)}
          onConfirm={() => {
            setShowDisableIntegration(false)
            refetch()
          }}
        />
      )}

      <Link fontWeight={800} href={t(`integrations.${provider}.about.link`)} target="_bank" mt={2}>
        <Stack flexDirection="row" alignItems="center">
          <Text variant="body1" mr={2}>
            {t('aboutTab.seeMore')}
          </Text>
          <OpenInNewIcon fontSize="small" />
        </Stack>
      </Link>
    </Stack>
  )
}
