import { Button, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@hub-la/shadcn'
import { Check, Copy } from 'lucide-react'
import { useState } from 'react'

interface CopyClipboardTagProps {
  tooltip: string
  content: string
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  maxWidth?: string
}

const CopyClipboardTag: React.FC<CopyClipboardTagProps> = ({
  tooltip,
  content,
  disabled = false,
  onClick,
  maxWidth = '200px',
}) => {
  const [copied, setCopied] = useState(false)
  const [open, setOpen] = useState(false)

  const handleCopy = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!disabled) {
      navigator.clipboard.writeText(content)
      setCopied(true)
      setOpen(true) // Force open the tooltip
      setTimeout(() => {
        setCopied(false)
        setOpen(false) // Close the tooltip after 2 seconds
      }, 2000)
      if (onClick) {
        onClick(event)
      }
    }
  }

  return (
    <TooltipProvider>
      <Tooltip open={open} onOpenChange={setOpen}>
        <TooltipTrigger asChild>
          <Button
            variant="secondary"
            disabled={disabled}
            onClick={handleCopy}
            className="w-auto px-3 h-8 hover:bg-slate-200 font-mono text-xs flex items-center"
            style={{ maxWidth }}
          >
            <span className="truncate mr-2">{content}</span>
            {copied ? (
              <Check className="h-4 w-4 text-green-600 flex-shrink-0" />
            ) : (
              <Copy className="h-3 w-3 flex-shrink-0" />
            )}
          </Button>
        </TooltipTrigger>

        <TooltipContent>
          <p>{copied ? tooltip : 'Copiar link'}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

export default CopyClipboardTag
