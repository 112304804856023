import { Tabs, TabsContent, TabsList, TabsTrigger } from '@hub-la/shadcn'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InvitesTable } from './invites-table/invites-table'
import { ParticipantsTable } from './participants-table/participants-table'

export const ParticipantsPage = () => {
  const [actualTab, setActualTab] = useState('0')

  const { t } = useTranslation()

  return (
    <Tabs value={actualTab} onValueChange={setActualTab} className="mb-8">
      <TabsList variant="underlined">
        <TabsTrigger value="0" variant="underlined">
          {t('tabs.participants.tabActive')}
        </TabsTrigger>
        <TabsTrigger value="1" variant="underlined">
          {t('tabs.participants.tabInvites', { total: 12 })}
        </TabsTrigger>
      </TabsList>

      <TabsContent value="0">
        <ParticipantsTable />
      </TabsContent>

      <TabsContent value="1">
        <InvitesTable />
      </TabsContent>
    </Tabs>
  )
}
