import { formatCurrency } from '@brazilian-utils/brazilian-utils'

export const InstallmentByStatus = ({ installments, amount }) => (
  <div className="space-y-1 text-sm">
    <p>{!!amount ? `R$ ${formatCurrency(amount / 100)}` : '-'}</p>
    <div>
      {!!installments ? (
        <>
          <p className="text-xs text-muted-foreground">{installments} parcelas</p>
        </>
      ) : (
        <p>-</p>
      )}
    </div>
  </div>
)
