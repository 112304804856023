import moment from 'moment-timezone'
import { Period } from '../domain/dtos/period'

export class GetShortcuts {
  public execute() {
    const format = 'YYYY-MM-DDTHH:mm:ssZ'
    const today = moment()

    return [
      {
        id: 'today',
        period: Period.HOURLY,
        label: 'Hoje',
        startDate: today.clone().startOf('day').format(format),
        endDate: today.clone().endOf('day').format(format),
      },
      {
        id: 'last-7-days',
        period: Period.DAILY,
        label: 'Últimos 7 dias',
        startDate: today.clone().subtract(6, 'days').startOf('day').format(format),
        endDate: today.clone().endOf('day').format(format),
      },
      {
        id: 'last-30-days',
        period: Period.DAILY,
        label: 'Últimos 30 dias',
        startDate: today.clone().subtract(29, 'days').startOf('day').format(format),
        endDate: today.clone().endOf('day').format(format),
      },
      {
        id: 'last-3-months',
        period: Period.DAILY,
        label: 'Últimos 3 meses',
        startDate: today.clone().subtract(2, 'months').startOf('day').format(format),
        endDate: today.clone().endOf('day').format(format),
      },
      {
        id: 'last-12-months',
        period: Period.MONTHLY,
        label: 'Últimos 12 meses',
        startDate: today.clone().subtract(11, 'months').startOf('day').format(format),
        endDate: today.clone().endOf('day').format(format),
      },
      {
        id: 'this-month',
        period: Period.DAILY,
        label: 'Esse mês',
        startDate: today.clone().startOf('month').format(format),
        endDate: today.clone().endOf('month').endOf('day').format(format),
      },
      {
        id: 'this-quarter',
        period: Period.MONTHLY,
        label: 'Esse trimestre',
        startDate: today.clone().startOf('quarter').format(format),
        endDate: today.clone().endOf('quarter').endOf('day').format(format),
      },
      {
        id: 'this-year',
        period: Period.MONTHLY,
        label: 'Esse ano',
        startDate: today.clone().startOf('year').format(format),
        endDate: today.clone().endOf('year').endOf('day').format(format),
      },
    ]
  }
}
