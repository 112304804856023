import {
  Badge,
  Button,
  Calendar,
  cn,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  Input,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ScrollArea,
  Separator,
  Switch,
  useIsMobile,
} from '@hub-la/shadcn'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { useFormik } from 'formik'
import { Check, ChevronDown } from 'lucide-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Offer } from '../../domain/dtos/offer'
import { LifetimeInterval, RecurringInterval } from '../../domain/enums/interval'
import { useCreateCoupon } from '../hooks/use-create-coupon'

export type Values = {
  code: string
  discountPercent?: number
  applyToSpecificOffers: boolean
  offers: Offer[]
  applyToSpecificIntervals: boolean
  intervals: (RecurringInterval | LifetimeInterval)[]
  maxAllowedTotalUsages?: number
  maxAllowedTotalUsagesPerUser?: number
  validUntil?: string
  isRecurrent: boolean
  useOutsideCheckout: boolean
}

type CreateCouponModalProps = {
  isOpen: boolean
  isLoading: boolean
  onClose: () => void
  onSuccess: () => void
  offers: Offer[]
}

const validationSchema = Yup.object().shape({
  code: Yup.string()
    .min(4, 'O código deve ter no mínimo 4 caracteres.')
    .max(15, 'O código deve ter no máximo 15 caracteres.')
    .matches(/^[A-Za-z0-9]*$/, 'O código deve conter apenas letras e números.')
    .required('Código do cupom é obrigatório'),
  discountPercent: Yup.number()
    .required('Porcentagem de desconto é obrigatória')
    .min(1, 'O desconto mínimo é 1%')
    .max(90, 'O desconto máximo é 90%'),
  maxAllowedTotalUsages: Yup.number().when('isLimitUsageEnabled', {
    is: true,
    then: Yup.number().required('Número máximo de usos é obrigatório quando o limite está ativado'),
  }),
  validUntil: Yup.date().when('isValidUntilEnabled', {
    is: true,
    then: Yup.date()
      .min(new Date(), 'A data de validade deve ser no futuro')
      .required('Data de validade é obrigatória quando ativada'),
  }),
})

export const CreateCouponModal: React.FC<CreateCouponModalProps> = ({
  isOpen,
  onClose,
  offers,
  isLoading,
  onSuccess,
}) => {
  const [isLimitUsageEnabled, setIsLimitUsageEnabled] = useState<boolean>(false)
  const [isValidUntilEnabled, setIsValidUntilEnabled] = useState<boolean>(false)

  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const { mutateAsync: createCoupon, isLoading: loading, error } = useCreateCoupon()

  const { values, isValid, submitForm, setFieldValue, resetForm, handleBlur, errors, touched, handleChange } =
    useFormik<Values>({
      initialValues: {
        code: '',
        validUntil: undefined,
        applyToSpecificOffers: false,
        offers: [],
        applyToSpecificIntervals: false,
        intervals: [],
        discountPercent: undefined,
        maxAllowedTotalUsages: undefined,
        maxAllowedTotalUsagesPerUser: undefined,
        isRecurrent: false,
        useOutsideCheckout: false,
      },
      validationSchema,
      validateOnChange: true,
      validateOnBlur: true,
      onSubmit: async (values) => {
        try {
          await createCoupon(values)
          resetForm()
          onSuccess?.()
        } catch (error) {
          console.error('Error creating coupon:', error)
        }
      },
    })

  const maxLength = 15
  const hasCouponError = !!(errors.code && touched.code)

  const getHelperText = () => {
    if (hasCouponError) return errors.code
    if (error) return t('createCoupon.alreadyExists')
    return t('createCoupon.codeHelperText', { current: values.code.length, maxLength })
  }

  const onCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const isValid = /^[A-Za-z0-9]*$/.test(value)
    if (isValid) {
      setFieldValue('code', value.toUpperCase())
    }
  }

  const onCloseModal = () => {
    resetForm()
    onClose()
    setIsLimitUsageEnabled(false)
    setIsValidUntilEnabled(false)
  }

  const DateFormatter: React.FC<{ date?: string; fallbackText?: string }> = ({
    date,
    fallbackText = 'Selecionar data',
  }) => {
    if (!date) return <span className="text-sm font-medium">{fallbackText}</span>
    return <span className="text-sm font-medium">{format(new Date(date), 'dd MMM yyyy', { locale: ptBR })}</span>
  }

  return (
    <Dialog open={isOpen} onOpenChange={onCloseModal}>
      <DialogContent className="h-4/5">
        <DialogHeader>
          <DialogTitle>{t('createCoupon.title')}</DialogTitle>
        </DialogHeader>
        <ScrollArea className="w-full h-full">
          <form onSubmit={submitForm} className="grid w-full px-2 items-center gap-4">
            <div className="flex flex-col space-y-1.5">
              <Label htmlFor="code">{t('createCoupon.code')}</Label>
              <Input
                id="code"
                name="code"
                value={values.code}
                onChange={onCodeChange}
                onBlur={handleBlur}
                maxLength={maxLength}
                aria-invalid={hasCouponError}
                aria-describedby="code-error"
                className={cn('mt-1', hasCouponError && 'border-red-500')}
              />
              <p id="code-error" className={cn('mt-1 text-sm', hasCouponError ? 'text-red-500' : 'text-gray-500')}>
                {getHelperText()}
              </p>
            </div>

            <div className="flex flex-col space-y-1.5">
              <Label htmlFor="discountPercent">{t('createCoupon.discountPercent')}</Label>
              <Input
                id="discountPercent"
                name="discountPercent"
                type="number"
                value={values.discountPercent}
                onChange={handleChange}
                onBlur={handleBlur}
                min={1}
                max={90}
                className="mt-1"
                aria-invalid={!!(errors.discountPercent && touched.discountPercent)}
                aria-describedby="discountPercent-error"
              />
              {errors.discountPercent && touched.discountPercent && (
                <p id="discountPercent-error" className="mt-1 text-sm text-red-500">
                  {errors.discountPercent}
                </p>
              )}
              <p className="mt-1 text-sm text-gray-500">{t('createCoupon.discountPercentHelperText')}</p>
            </div>

            <div className="flex flex-col bg-secondary rounded-lg p-4 space-y-4">
              <div className="flex justify-between items-center space-x-2">
                <Label htmlFor="applyToSpecificOffers">{t('createCoupon.applyToSpecificOffers')}</Label>
                <Switch
                  id="applyToSpecificOffers"
                  checked={values.applyToSpecificOffers}
                  onCheckedChange={(checked) => setFieldValue('applyToSpecificOffers', checked)}
                />
              </div>

              {values.applyToSpecificOffers && (
                <Popover>
                  <PopoverTrigger asChild>
                    <Button variant="outline" className="w-full justify-between">
                      <div className="flex items-center">
                        <span className="text-sm">Ofertas</span>
                        {values.offers.length > 0 && (
                          <>
                            <Separator orientation="vertical" className="mx-2 h-4" />
                            <Badge variant="secondary" className="rounded-sm px-1 font-normal lg:hidden">
                              {values.offers.length}
                            </Badge>
                            <div className="hidden space-x-1 lg:flex">
                              {values.offers.length > 2 ? (
                                <Badge variant="secondary" className="rounded-sm px-1 font-normal">
                                  {values.offers.length} selecionado(s)
                                </Badge>
                              ) : (
                                values.offers.map((offer) => (
                                  <Badge variant="secondary" key={offer.id} className="rounded-sm px-1 font-normal">
                                    {offer.name}
                                  </Badge>
                                ))
                              )}
                            </div>
                          </>
                        )}
                      </div>
                      <ChevronDown className="h-4 w-4 text-muted-foreground" />
                    </Button>
                  </PopoverTrigger>

                  <PopoverContent align="start" className="z-[72] w-[300px] p-0">
                    <Command>
                      <CommandInput placeholder="Buscar ofertas" className="h-9" />
                      <CommandEmpty>Nenhum resultado encontrado.</CommandEmpty>
                      <CommandList>
                        <CommandGroup>
                          {offers
                            .filter((it) => it.id !== 'all')
                            .map((offer) => (
                              <CommandItem
                                key={offer.id}
                                onSelect={() => {
                                  setFieldValue(
                                    'offers',
                                    values.offers.some((o) => o.id === offer.id)
                                      ? values.offers.filter((o) => o.id !== offer.id)
                                      : [...values.offers, offer],
                                  )
                                }}
                              >
                                <Check
                                  className={cn(
                                    'mr-2 h-4 w-4',
                                    values.offers.some((o) => o.id === offer.id) ? 'opacity-100' : 'opacity-0',
                                  )}
                                />
                                <span>{offer.name}</span>
                              </CommandItem>
                            ))}
                        </CommandGroup>
                      </CommandList>
                    </Command>
                  </PopoverContent>
                </Popover>
              )}

              <div className="flex justify-between items-center space-x-2">
                <Label htmlFor="applyToSpecificIntervals">{t('createCoupon.applyToSpecificIntervals')}</Label>
                <Switch
                  id="applyToSpecificIntervals"
                  checked={values.applyToSpecificIntervals}
                  onCheckedChange={(checked) => setFieldValue('applyToSpecificIntervals', checked)}
                />
              </div>

              {values.applyToSpecificIntervals && (
                <Popover>
                  <PopoverTrigger asChild>
                    <Button variant="outline" className="w-full justify-between">
                      <div className="flex items-center">
                        <span className="text-sm">{t('createCoupon.intervals')}</span>
                        {values.intervals.length > 0 && (
                          <>
                            <Separator orientation="vertical" className="mx-2 h-4" />
                            <Badge variant="secondary" className="rounded-sm px-1 font-normal lg:hidden">
                              {values.intervals.length}
                            </Badge>
                            <div className="hidden space-x-1 lg:flex">
                              {values.intervals.length > 2 ? (
                                <Badge variant="secondary" className="rounded-sm px-1 font-normal">
                                  {values.intervals.length} selecionado(s)
                                </Badge>
                              ) : (
                                values.intervals.map((interval) => (
                                  <Badge variant="secondary" key={interval} className="rounded-sm px-1 font-normal">
                                    {t(`planType.${interval}`)}
                                  </Badge>
                                ))
                              )}
                            </div>
                          </>
                        )}
                      </div>
                      <ChevronDown className="h-4 w-4 text-muted-foreground" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent align="start" className="z-[72] w-[300px] p-0">
                    <Command>
                      <CommandInput placeholder="Planos de pagamento" className="h-9" />
                      <CommandEmpty>{t('createCoupon.noIntervalsFound')}</CommandEmpty>
                      <CommandList>
                        <CommandGroup>
                          {Object.values({ ...RecurringInterval, ...LifetimeInterval }).map((interval) => (
                            <CommandItem
                              key={interval}
                              onSelect={() => {
                                setFieldValue(
                                  'intervals',
                                  values.intervals.includes(interval)
                                    ? values.intervals.filter((i) => i !== interval)
                                    : [...values.intervals, interval],
                                )
                              }}
                            >
                              <Check
                                className={cn(
                                  'mr-2 h-4 w-4',
                                  values.intervals.includes(interval) ? 'opacity-100' : 'opacity-0',
                                )}
                              />
                              <span>{t(`planType.${interval}`)}</span>
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </CommandList>
                    </Command>
                  </PopoverContent>
                </Popover>
              )}

              <div className="flex justify-between items-center space-x-2">
                <Label htmlFor="isLimitUsageEnabled">{t('createCoupon.isLimitUsageEnabled')}</Label>
                <Switch
                  id="isLimitUsageEnabled"
                  checked={isLimitUsageEnabled}
                  onCheckedChange={setIsLimitUsageEnabled}
                />
              </div>

              {isLimitUsageEnabled && (
                <div>
                  <Label htmlFor="maxAllowedTotalUsages">{t('createCoupon.maxAllowedTotalUsages')}</Label>
                  <Input
                    id="maxAllowedTotalUsages"
                    name="maxAllowedTotalUsages"
                    type="number"
                    value={values.maxAllowedTotalUsages}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="mt-1"
                    aria-invalid={!!(errors.maxAllowedTotalUsages && touched.maxAllowedTotalUsages)}
                    aria-describedby="maxAllowedTotalUsages-error"
                  />
                  {errors.maxAllowedTotalUsages && touched.maxAllowedTotalUsages && (
                    <p id="maxAllowedTotalUsages-error" className="mt-1 text-sm text-red-500">
                      {errors.maxAllowedTotalUsages}
                    </p>
                  )}
                </div>
              )}

              <div className="flex justify-between items-center space-x-2">
                <Label htmlFor="isValidUntilEnabled">{t('createCoupon.isValidUntilEnabled')}</Label>
                <Switch
                  id="isValidUntilEnabled"
                  checked={isValidUntilEnabled}
                  onCheckedChange={setIsValidUntilEnabled}
                />
              </div>

              {isValidUntilEnabled && (
                <Popover>
                  <PopoverTrigger asChild>
                    <Button variant="outline">
                      <DateFormatter date={values.validUntil} fallbackText="Selecionar data" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent align="start" className="z-[72] w-auto p-0">
                    <Calendar
                      mode="single"
                      selected={values.validUntil ? new Date(values.validUntil) : undefined}
                      onSelect={(date) => setFieldValue('validUntil', date ? date.toISOString() : undefined)}
                      locale={ptBR}
                    />
                  </PopoverContent>
                </Popover>
              )}

              <div className="flex justify-between items-center space-x-2">
                <Label htmlFor="isRecurrent">{t('createCoupon.isRecurrent')}</Label>
                <Switch
                  id="isRecurrent"
                  checked={values.isRecurrent}
                  onCheckedChange={(checked) => setFieldValue('isRecurrent', checked)}
                />
              </div>

              <div className="flex justify-between items-center space-x-2">
                <Label htmlFor="useOutsideCheckout">{t('createCoupon.useOutsideCheckout')}</Label>
                <Switch
                  id="useOutsideCheckout"
                  checked={values.useOutsideCheckout}
                  onCheckedChange={(checked) => setFieldValue('useOutsideCheckout', checked)}
                />
              </div>
            </div>

            <div className="flex justify-between items-center space-x-2">
              <Button
                type="button"
                className="w-full"
                variant="outline"
                onClick={onCloseModal}
                disabled={isLoading || loading}
              >
                {t('createCoupon.cancel')}
              </Button>
              <Button type="submit" className="w-full" disabled={!isValid || isLoading || loading}>
                {t('createCoupon.confirm')}
              </Button>
            </div>
          </form>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  )
}
