import { Button, useIsMobile } from '@hub-la/shadcn'
import { LoaderCircle } from 'lucide-react'
import { Fragment } from 'react'
import { CurrentUser } from '../../domain/dtos/current-user'
import { Comment } from '../components/comment/comment'
import { Count } from '../components/count/count'
import { InputComment } from '../components/input-comment/input-comment'
import { useGetComments } from '../hooks/use-get-comments'
import { usePostComment } from '../hooks/use-post-comment'
import { DiscussionProvider, useDiscussion } from '../providers/discussion'

type DiscussionProps = DiscussionPostProps

type DiscussionPostProps = {
  type: 'post'
  discussionOwnerId: string
  currentUser: CurrentUser
  metadata: {
    postId: string
  }
}

const DiscussionContent = ({ currentUser }: DiscussionProps) => {
  const isMobile = useIsMobile()
  const { metadata, orderDir } = useDiscussion()

  const {
    data,
    isLoading: isLoadingComments,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetComments({ postId: metadata.postId, pageSize: isMobile ? 5 : 10, orderDir })
  const { mutateAsync, isLoading } = usePostComment({ currentUser })

  const totalComments = data?.pages[0]?.total ?? 0

  return (
    <>
      <Count total={totalComments} isLoading={isLoadingComments} />

      <div className="w-full flex flex-col gap-6">
        <InputComment
          onComment={async (message) => {
            await mutateAsync({ message, postId: metadata.postId })
          }}
          isLoading={isLoading}
          avatarUrl={currentUser?.picture}
          commenterName={currentUser?.name}
        />

        {isLoadingComments && (
          <div className="w-full flex justify-center text-white">
            <LoaderCircle className="animate-spin" />
          </div>
        )}

        {(data?.pages ?? []).map((group, i) => (
          <Fragment key={i}>
            {group?.items?.map((comment) => (
              <Comment
                key={comment.id}
                commentId={comment.id}
                fullName={comment?.user?.name}
                avatarUrl={comment?.user?.picture}
                createdAt={comment.createdAt}
                comment={comment.message}
                isLiked={comment.hasUserLike}
                likes={comment.likesCount}
                repliesCount={comment.repliesCount}
                latestReplyAt={comment.latestReplyAt}
                isEdited={comment.wasEdited}
                userId={comment?.userId}
                hasOwnerReply={comment.hasOwnerReply}
                discussionOwner={comment?.discussionOwner}
              />
            ))}
          </Fragment>
        ))}

        {hasNextPage && (
          <Button variant="outline" onClick={() => fetchNextPage()} disabled={isFetchingNextPage} className="w-full">
            Ver mais comentários
          </Button>
        )}
      </div>
    </>
  )
}

export const Discussion = ({ discussionOwnerId, currentUser, type, metadata }: DiscussionProps) => {
  return (
    <DiscussionProvider discussionOwnerId={discussionOwnerId} currentUser={currentUser} type={type} metadata={metadata}>
      <DiscussionContent
        currentUser={currentUser}
        metadata={metadata}
        type={type}
        discussionOwnerId={discussionOwnerId}
      />
    </DiscussionProvider>
  )
}
