import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'

type ConfirmRetryEventHistoryModalProps = {
  isOpen: boolean
  isLoading: boolean
  onClose: () => void
  onConfirm: () => void
}

export const ConfirmRetryEventHistoryModal = (props: ConfirmRetryEventHistoryModalProps) => {
  const { isOpen, isLoading, onClose, onConfirm } = props
  const { t } = useTranslation()

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('histories.retryModal.title')}</DialogTitle>

          <DialogDescription>{t('histories.retryModal.description')}</DialogDescription>
        </DialogHeader>

        <DialogFooter className="sm:justify-end">
          <Button variant="outline" onClick={onClose} disabled={isLoading}>
            {t('buttons.cancel')}
          </Button>

          <Button onClick={onConfirm} loading={isLoading}>
            {t('buttons.confirm')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
