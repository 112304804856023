export const pt = {
  dropdown: {
    header: {
      showProfile: 'Ver perfil',
      editProfile: 'Editar perfil',
    },
    links: {
      products: 'Meus produtos',
      purchases: 'Minhas compras',
      affiliates: 'Afiliados',
      blog: 'Hubla blog',
      support: 'Suporte & ajuda',
      bankAccount: 'Conta bancária',
      collaborators: 'Gestão de colaboradores',
      selectAccount: 'Acessar outra conta',
      logout: 'Sair',
    },
  },
}
