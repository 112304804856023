import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@hub-la/shadcn'
import { Download } from 'lucide-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { FileExtension } from '../../../../domain/dtos/waitlist'
import { useExportWaitlist } from '../../../hooks/waitlist/use-export-waitlist'

export const ConfirmExportModal: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [fileExtension, setFileExtension] = useState<FileExtension>(FileExtension.XLSX)

  const { t } = useTranslation()
  const { productId } = useParams<{ productId: string }>()
  const { mutateAsync: exportWaitlist, isLoading } = useExportWaitlist()

  return (
    <Dialog open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
      <DialogTrigger className="w-full sm:w-32">
        <Button variant="outline" className="w-full flex items-center gap-2">
          <Download className="h-4 w-4" />
          Exportar
        </Button>
      </DialogTrigger>

      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>{t('waitlist.export.title')}</DialogTitle>
        </DialogHeader>

        <div className="flex flex-col gap-6">
          <Select
            value={fileExtension}
            onValueChange={(value) => setFileExtension(value as FileExtension)}
            disabled={isLoading}
          >
            <SelectTrigger>
              <SelectValue placeholder={t('waitlist.export.placeholder')} />
            </SelectTrigger>

            <SelectContent className="z-[72]">
              <SelectItem value={FileExtension.XLSX}>xlsx</SelectItem>
              <SelectItem value={FileExtension.CSV}>csv</SelectItem>
              <SelectItem value={FileExtension.JSON}>json</SelectItem>
            </SelectContent>
          </Select>

          <div className="flex justify-end gap-4">
            <DialogClose>
              <Button variant="outline" disabled={isLoading}>
                {t('waitlist.export.cancel')}
              </Button>
            </DialogClose>

            <Button
              loading={isLoading}
              onClick={() => exportWaitlist({ productId, fileExtension }).then(() => setIsOpen(false))}
              className="bg-[#151515] text-white hover:bg-[#151515]/90"
            >
              <Download className="mr-2 h-4 w-4" />

              {t('waitlist.export.confirm')}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
