import { Card, CardContent, Switch } from '@hub-la/shadcn'
import { useFormikContext } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Values } from '../../../../../domain/dtos/member-area/settings/formik-values'
import CustomLogoMockup from '../../../../assets/custom-logo-mockup.png'
import { useSaveMembersArea } from '../../../../hooks/member-area/settings/save-members-area'
import CardUploader from './card-uploader'

const CustomLogo = ({
  onChange,
  isLoading,
  defaultValue,
}: {
  onChange?: (checked: boolean) => void
  isLoading?: boolean
  defaultValue?: boolean
}) => {
  const { productId } = useParams<{ productId: string }>()

  const saveMembersArea = useSaveMembersArea()
  const [showThumbnailTitle, setShowThumbnailTitle] = useState<boolean | undefined>()

  const { t } = useTranslation()
  const { values } = useFormikContext<Values>()

  const handleToggle = useCallback(
    (checked: boolean) => {
      setShowThumbnailTitle(checked)
      if (onChange) {
        onChange(checked)
      }
    },
    [setShowThumbnailTitle, onChange],
  )

  useEffect(() => {
    if (!defaultValue) return
    setShowThumbnailTitle(defaultValue)
  }, [defaultValue])

  const handleUpdateLogoAsset = async () => {
    await saveMembersArea.mutateAsync({ logoAsset: values?.logoAsset ?? null, productId })
  }

  return (
    <div className="flex flex-col md:flex-row gap-4">
      <div className="w-2/3 flex flex-col gap-2 ">
        <h2 className="text-lg font-bold">{t('member-area.settings.customLogo.title')}</h2>

        <p className="text-sm">{t('member-area.settings.customLogo.description')}</p>
      </div>

      <Card className="w-full">
        <CardContent className="p-6 flex flex-col gap-4">
          <div className="flex items-center gap-2">
            <Switch
              id="show-logo"
              checked={showThumbnailTitle}
              onCheckedChange={handleToggle}
              disabled={isLoading}
              data-testid="switch-thumbnail"
            />

            <label
              htmlFor="show-logo"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              {t('member-area.settings.customLogo.options.showLogo.label')}
            </label>
          </div>

          <p className="text-sm text-muted-foreground">
            {t('member-area.settings.customLogo.options.showLogo.description')}
          </p>

          <CardUploader
            field="logoAsset"
            title={t('member-area.settings.customLogo.options.logoAsset.title')}
            subtitle={t('member-area.settings.customLogo.options.logoAsset.subtitle')}
            errorMessage={t('member-area.settings.customLogo.options.logoAsset.errorMessage')}
            onComplete={() => handleUpdateLogoAsset()}
            onDelete={() => handleUpdateLogoAsset()}
          />

          <div className="max-h-80 lg:pl-20 lg:pt-8 rounded-md bg-muted relative overflow-hidden">
            <img
              className="w-full rounded-md"
              src={CustomLogoMockup}
              alt="Imagem representando o módulo em destaque e modo conteúdo em destaque"
            />
          </div>
        </CardContent>
      </Card>
    </div>
  )
}

export default CustomLogo
