import { AuthProvider, AuthRedirectProvider, RecoverPasswordPage as AuthRecoverPasswordPage } from '@hub-la/fe-auth'

export const RecoverPasswordInit = () => {
  return (
    <AuthProvider>
      <AuthRedirectProvider>
        <AuthRecoverPasswordPage />
      </AuthRedirectProvider>
    </AuthProvider>
  )
}
