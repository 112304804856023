import { useContainer } from '@hub-la/fe-container'
import { Auth } from '@hub-la/fe-core-auth'
import { useGetUser } from '@hub-la/fe-get-user'
import { createContext, ReactNode, useCallback, useEffect, useState } from 'react'

export const AuthenticationContext = createContext<{
  isAuthenticated: null | boolean
  updateCurrentUser: () => void
  currentUser: null | any
}>({
  isAuthenticated: null,
  updateCurrentUser: () => {},
  currentUser: null,
})

type AuthenticationProviderProps = {
  children: ReactNode
}

const AuthenticationProvider = ({ children }: AuthenticationProviderProps) => {
  const container = useContainer()
  const auth = container.get(Auth)

  const { data: userData, isFetched, refetch } = useGetUser({ enabled: false })

  const [isAuthenticated, setIsAuthenticated] = useState<null | boolean>(null)
  const [currentUser, setCurrentUser] = useState<null | any>(null)

  const updateCurrentUser = useCallback(async () => {
    const currentUserLocalStorage = localStorage.getItem('currentUser')
    if (currentUserLocalStorage && currentUser) return currentUser
    if (currentUserLocalStorage) {
      setCurrentUser(JSON.parse(currentUserLocalStorage))
      return JSON.parse(currentUserLocalStorage)
    }
    const { data } = isFetched ? { data: userData } : await refetch()

    localStorage.setItem(
      'currentUser',
      JSON.stringify({
        ...data,
        loginPreferences: {
          navigationTarget: 'creator',
        },
      }),
    )
    setCurrentUser(data)

    return data
  }, [currentUser])

  const generateAuthenticateIframe = useCallback(async () => {
    // prevent multiple iframes
    const iframeNextElement = document.getElementById('iframe-next')
    const iframeCheckoutElement = document.getElementById('iframe-checkout')
    if (iframeNextElement || iframeCheckoutElement) return

    const iframeNext = document.createElement('iframe')
    iframeNext.id = 'iframe-next'
    iframeNext.src = '/authenticate?token='
    iframeNext.style.display = 'none'
    document.body.appendChild(iframeNext)
  }, [])

  useEffect(() => {
    const currentUserLocalStorage = localStorage.getItem('currentUser')

    if (currentUserLocalStorage) {
      setIsAuthenticated(true)
    }
  }, [])

  useEffect(() => {
    const unsubscribe = auth.listen(async (currentUser) => {
      if (!currentUser) {
        setIsAuthenticated(false)
        setCurrentUser(null)
        return
      }

      setIsAuthenticated(true)

      await updateCurrentUser()
      generateAuthenticateIframe()
    })

    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <AuthenticationContext.Provider value={{ isAuthenticated, updateCurrentUser, currentUser }}>
      {children}
    </AuthenticationContext.Provider>
  )
}

export { AuthenticationProvider }
