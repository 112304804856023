import { container } from '@hub-la/fe-container'
import { UserGroupsPage, UserGroupsProvider } from '@hub-la/fe-user-groups'

export const UserGroupsInit = () => {
  return (
    <UserGroupsProvider container={container}>
      <UserGroupsPage />
    </UserGroupsProvider>
  )
}
