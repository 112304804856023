import { AssetType } from '@hub-la/fe-asset'
import { useContainer } from '@hub-la/fe-container'
import { Analytics } from '@hub-la/fe-core-analytics'
import { Thumbnail } from '@hub-la/fe-thumbnail'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, cn, Progress } from '@hub-la/shadcn'
import { Lock, Play } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Events } from '../../../domain/dtos/events'
import { GetReleaseTime } from '../../../usecases/get-release-time'
import { useRealtimeSections } from '../../hooks'
import { Duration } from './duration'

type SidebarContentProps = {
  postId: string
  productId: string
  defaultSection: string
}

export const SidebarContent = ({ postId, productId, defaultSection }: SidebarContentProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const container = useContainer()
  const analytics = container.get(Analytics)
  const [hoveredPostId, setHoveredPostId] = useState<string | null>(null)

  const { sections, realtimePostProgress } = useRealtimeSections()
  const [openSections, setOpenSections] = useState<Set<string>>(new Set([defaultSection]))
  const [cachedSectionsThumbnail, setCachedSectionsThumbnail] = useState<Set<string>>(new Set([defaultSection]))

  const openSection = (element: string) => {
    setOpenSections((prevState) => new Set([...prevState, element]))
    setCachedSectionsThumbnail((prevState) => new Set([...prevState, element]))
  }

  const closeSection = (element: string) => {
    setOpenSections((prevState) => {
      const newState = new Set([...prevState])
      newState.delete(element)
      return newState
    })
  }

  useEffect(() => {
    setOpenSections(new Set([defaultSection]))
  }, [defaultSection])

  const handleClick = (sectionId: string, postId: string) => {
    const postLink = `/post/${postId}`
    analytics.track(Events.POST_DETAIL.SIDEBAR_CONTENT.POST_CLICKED, { productId, sectionId, postId })
    window.scrollTo(0, 0)
    history.push(postLink)
  }

  const handleChange = (panel: string) => () => {
    analytics.track(Events.POST_DETAIL.SIDEBAR_CONTENT.SECTION_CLICKED, { productId, sectionId: panel })
    openSections.has(panel) ? closeSection(panel) : openSection(panel)
  }

  const calcPercentageProgress = (viewed: boolean, progress: number, total?: number | null) => {
    if (viewed) return 100
    if (!total || total < 0) return 0
    if (total === 0 || progress === 0) return 0
    return (progress / total) * 100
  }

  return (
    <Accordion
      type="multiple"
      className="rounded-lg w-full"
      value={Array.from(openSections)}
      onValueChange={(value) => setOpenSections(new Set(value))}
    >
      {sections.map((section) => {
        const releaseAt = new Date(section.releaseAt ?? 0)
        const isSectionThumbnailCached = cachedSectionsThumbnail.has(section.id)

        return (
          <AccordionItem key={section.id} value={section.id} className="w-full">
            <AccordionTrigger
              onClick={() => handleChange(section.id)}
              className="hover:no-underline hover:bg-zinc-800 w-full px-3 py-2 gap-4"
            >
              <div className="font-semibold w-full flex gap-2">
                <span>{section.order}</span>

                <div className="w-full flex flex-col">
                  <div className="flex gap-2">
                    {releaseAt > new Date() && <Lock size={16} />}

                    <h4 className="text-left line-clamp-2">{section.name}</h4>
                  </div>

                  <div className="w-full flex flex-wrap items-center justify-between">
                    {section?.posts?.length > 0 && (
                      <span className="text-xs font-normal text-zinc-500  flex items-center">
                        {section?.posts?.length} {t('postDetail.sidebar.sectinProgress.counter')}
                      </span>
                    )}

                    {section?.posts?.length > 0 && (
                      <Progress value={section?.progress} indicatorColor="bg-red-600" className="h-1 w-24 mr-3" />
                    )}
                  </div>
                </div>
              </div>

              {releaseAt > new Date() && <p className="text-xs">{new GetReleaseTime().execute(releaseAt)}</p>}
            </AccordionTrigger>

            <AccordionContent className="p-0">
              {section.posts.map((post, i) => {
                const releaseAt = new Date(post.releaseAt ?? 0)
                const maxProgress = post.cover?.metadata?.duration ?? (post.cover?.metadata?.pages || 0)
                const progress = postId === post.id ? realtimePostProgress : post.cover?.progress?.currentProgress || 0
                const viewed =
                  (!post.cover?.url && post?.viewed) || (post.cover?.type === AssetType.image && post?.viewed) || false

                return (
                  <div
                    key={post.id}
                    onClick={() => handleClick(section.id, post.id)}
                    className="cursor-pointer"
                    onMouseEnter={() => setHoveredPostId(post.id)} // Hover detected on parent
                    onMouseLeave={() => setHoveredPostId(null)} // Reset hover on leave
                  >
                    <div
                      className={cn(
                        'px-3 py-2 flex gap-4 items-center border-t',
                        post.id !== postId ? '' : 'bg-zinc-800',
                      )}
                    >
                      <span className="text-base">{i + 1}</span>

                      <div className="relative">
                        <Thumbnail
                          width={60}
                          height={94}
                          resizeWidth={420}
                          lockContent={releaseAt > new Date()}
                          asset={post?.customCover ?? post?.cover}
                        />

                        {/* Play button animation */}
                        <div
                          className={cn(
                            'absolute inset-0 flex items-center justify-center rounded-lg bg-black bg-opacity-50 transition-all ease-in-out duration-500 transform',
                            hoveredPostId === post.id
                              ? 'opacity-100 scale-105' // When parent is hovered
                              : 'opacity-0 scale-100', // Default state
                          )}
                        >
                          <Play className="text-primary fill-primary" size={28} />
                        </div>
                      </div>

                      <div className="w-full flex flex-col gap-1.5">
                        <h5 className="text-left text-sm font-medium">{post.title}</h5>

                        {releaseAt > new Date() ? (
                          <div className="flex items-center gap-1 mt-2">
                            <Lock size={12} />
                            <p className="text-xs">{new GetReleaseTime().execute(releaseAt)}</p>
                          </div>
                        ) : (
                          <Duration post={post} />
                        )}

                        {maxProgress ? (
                          <Progress
                            value={calcPercentageProgress(viewed, progress, maxProgress)}
                            className="mt-1 w-full h-1"
                            indicatorColor="bg-red-600"
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                )
              })}
            </AccordionContent>
          </AccordionItem>
        )
      })}
    </Accordion>
  )
}
