import { Tabs, TabsContent, TabsList, TabsTrigger } from '@hub-la/shadcn'
import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Invoices } from '../invoices-table/invoices'
import { SmartInstallments } from '../smart-installments-table/smart-installments'

export const DashboardSales = memo(() => {
  const [tab, setTab] = useState('invoices')
  const { t } = useTranslation()
  const history = useHistory()

  const onTabChange = (tab: string) => {
    history.push(history.location.pathname + '?tab=' + tab + '#sales')
    setTab(tab)
  }
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const tab = urlParams.get('tab')
    if (tab) {
      setTab(tab)
    }
  }, [])

  return (
    <div>
      <h1 className="text-3xl font-semibold leading-tight mb-4">Vendas</h1>
      <Tabs value={tab} onValueChange={onTabChange}>
        <TabsList variant="underlined">
          <TabsTrigger variant="underlined" value="invoices" key="invoices">
            {t('tabs.invoices')}
          </TabsTrigger>
          <TabsTrigger variant="underlined" value="smartInstallments" key="smartInstallments">
            {t('tabs.smartInstallments')}
          </TabsTrigger>
        </TabsList>
        <div className="pt-2">
          <TabsContent value="invoices" key="invoices">
            <Invoices />
          </TabsContent>
          <TabsContent value="smartInstallments" key="smartInstallments">
            <SmartInstallments />
          </TabsContent>
        </div>
      </Tabs>
    </div>
  )
})
