import { theme, ThemeProvider } from '@hub-la/design-system'
import { ContainerProvider } from '@hub-la/fe-container'
import { I18nextProvider } from 'react-i18next'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import i18n from './i18n'

const queryClient = new QueryClient()

export const CohortProvider = ({ children, container }) => {
  return (
    <ContainerProvider container={container}>
      <ThemeProvider theme={theme.light}>
        <I18nextProvider i18n={i18n}>
          <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
        </I18nextProvider>
      </ThemeProvider>
    </ContainerProvider>
  )
}
