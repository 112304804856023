/* eslint-disable react/jsx-no-useless-fragment */
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@hub-la/shadcn'
import { times } from 'lodash'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { FreeMembersHeader } from '../components/free-members-header'
import { FreeMembersTableRow } from '../components/free-members-table-row'
import { useGenerateWhitelistLink } from '../hooks/use-generate-whitelist-link'
import { useGetFreeMembers } from '../hooks/use-get-free-members'
import { useGetGroupResourceDetails } from '../hooks/use-get-group-resource-details'
import { useGetProductDetails } from '../hooks/use-get-product-details'
import { useGetResourceDetails } from '../hooks/use-get-resource-details'
import { useRemoveFreeMember } from '../hooks/use-remove-free-member'

export const FreeMembersPage = () => {
  const { t } = useTranslation()
  const [documentIdToDelete, setDocumentIdToDelete] = useState()
  const [confirmPopup, setConfirmPopup] = useState(false)

  const { groupResourceId, productId, groupId } = useParams<{
    groupResourceId: string
    productId: string
    groupId: string
  }>()

  const { data: freeMembers, isLoading: isLoadingMembers, error } = useGetFreeMembers({ groupId, groupResourceId })
  const { data: product } = useGetProductDetails(productId)
  const { data: groupResource } = useGetGroupResourceDetails(groupResourceId)
  const { data: resource } = useGetResourceDetails(groupResource?.resourceType)
  const { mutateAsync: removeFreeMember, isLoading: isRemoving } = useRemoveFreeMember()
  const { mutateAsync: generateLink, isLoading: isGeneratingLink } = useGenerateWhitelistLink()

  const handleConfirmRemoveClick = useCallback(async () => {
    if (documentIdToDelete) {
      await removeFreeMember({ groupId, id: documentIdToDelete })
      setDocumentIdToDelete(undefined)
      setConfirmPopup(false)
    }
  }, [groupId, documentIdToDelete, removeFreeMember])

  const handleGenerateLink = useCallback(async () => {
    if (groupResource && resource) {
      return await generateLink({
        groupId,
        groupResourceId: groupResource.id,
        resourceType: resource.type,
      })
    }
    return
  }, [groupResource, resource, generateLink, groupId])

  const tableHeaderItems = [
    { key: 'name', label: t('freeMembers.table.Name'), align: 'left', width: 195 },
    { key: 'phoneNumber', label: t('freeMembers.table.PhoneNumber'), align: 'left' },
    { key: 'createdAt', label: t('freeMembers.table.CreatedAt'), align: 'left' },
    { key: 'action', label: t('freeMembers.table.Action'), align: 'right' },
  ]

  return (
    <div className="container mx-auto p-4">
      <>
        <FreeMembersHeader
          isLoading={isLoadingMembers}
          product={product}
          resource={resource}
          groupResource={groupResource}
          onGenerateLink={handleGenerateLink}
          isGeneratingLink={isGeneratingLink}
        />

        <div className="my-4">
          <h2 className="text-2xl font-bold">{t('freeMembers.title')}</h2>
        </div>

        <Table>
          <TableHeader>
            <TableRow>
              {tableHeaderItems.map((item) => (
                <TableHead key={item.key} className={`text-${item.align}`} style={{ width: item.width }}>
                  {item.label}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>

          <TableBody>
            {isLoadingMembers
              ? times(5, (idx) => (
                  <TableRow key={idx}>
                    <TableCell colSpan={4} className="h-16 animate-pulse bg-gray-100" />
                  </TableRow>
                ))
              : freeMembers?.map((member) => (
                  <FreeMembersTableRow
                    key={member.id}
                    member={member}
                    setDocumentIdToDelete={setDocumentIdToDelete}
                    setConfirmPopup={setConfirmPopup}
                  />
                ))}
          </TableBody>
        </Table>

        {error && <div className="text-red-500 mt-4">{t((error as Error).message)}</div>}

        <Dialog open={confirmPopup} onOpenChange={setConfirmPopup}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>{t('freeMembers.modal.remove.title')}</DialogTitle>
            </DialogHeader>
            <p>{t('freeMembers.modal.remove.description')}</p>
            <div className="flex justify-end space-x-2 mt-4">
              <Button variant="outline" onClick={() => setConfirmPopup(false)}>
                {t('freeMembers.modal.remove.buttons.cancel')}
              </Button>
              <Button variant="destructive" onClick={handleConfirmRemoveClick} disabled={isRemoving}>
                {t('freeMembers.modal.remove.buttons.remove')}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </>
    </div>
  )
}
