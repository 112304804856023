import { useEffect } from 'react'

/**
 * Custom hook to apply a theme to the document's root element.
 *
 * @param theme - The theme to apply. Can be 'dark', 'light', or 'system'.
 *
 * This hook adds the specified theme as a class to the document's root element
 * and removes it when the component is unmounted or the theme changes.
 *
 * @example
 * ```typescript
 * useTheme('dark');
 * ```
 */
export const useTheme = (theme: 'dark' | 'light' | 'system'): void => {
  useEffect(() => {
    document.documentElement.classList.add(theme)
    return () => document.documentElement.classList.remove(theme)
  }, [theme])

  return
}
