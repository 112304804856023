import { Button, Input, Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '@hub-la/shadcn'
import { Search, SlidersHorizontal } from 'lucide-react'
import { SelectDateRangeCalendar } from './select-date-range-calendar'
import { SelectOffers } from '../../components/select-offers'
import { SelectStatus } from '../../components/select-status'
import { Offer } from '../../../domain/dtos/offer'
import { GetDefaultFilters } from '../../../usecases/get-default-filters'

type Props = {
  offers: Offer[]
  filters
  setFilters
}

export const FilterDrawerDetails = ({ offers, filters, setFilters }: Props) => {
  const clearFilters = () => {
    setFilters(new GetDefaultFilters().execute())
  }

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="outline" className="w-full">
          <SlidersHorizontal className="h-4 w-4 mr-2" />
          Filtros
        </Button>
      </SheetTrigger>
      <SheetContent side="right" className="h-full max-w-2xl flex-col overflow-y-auto">
        <SheetHeader>
          <SheetTitle>Filtros</SheetTitle>
        </SheetHeader>
        <div className="grid grid-cols-1 gap-4">
          <Button variant="ghost" onClick={clearFilters} className="py-4">
            Limpar filtros
          </Button>
          <div className="relative h-10 w-full">
            <Search className="absolute w-4 h-4 right-3 top-1/2 transform -translate-y-1/2 text-gray-500 z-10" />
            <Input
              type="text"
              placeholder="Buscar..."
              className="w-full"
              value={filters.search}
              onChange={(e) => setFilters({ ...filters, search: e.target.value })}
            />
          </div>
          <SelectOffers offers={offers} filters={filters} setFilters={setFilters} />
          <SelectDateRangeCalendar
            value={filters}
            onChange={(value) => setFilters((prevState) => ({ ...prevState, ...value }))}
            onSelectChange={(value) => setFilters((prevState) => ({ ...prevState, dateRangeBy: value }))}
            selectValue={filters.dateRangeBy}
            onClearDate={() =>
              setFilters((prevState) => ({ ...prevState, dateRangeBy: 'createdAt', startDate: '', endDate: '' }))
            }
          />
          <SelectStatus
            value={filters.statuses}
            onChange={(value) => setFilters((prevState) => ({ ...prevState, statuses: value }))}
          />
        </div>
      </SheetContent>
    </Sheet>
  )
}
