import { default as MuiTabContext } from '@mui/lab/TabContext'
import { default as MuiTabList } from '@mui/lab/TabList'
import { default as MuiTabPanel } from '@mui/lab/TabPanel'
import { Box, Tab as MuiTab, tabsClasses } from '@mui/material'
import { styled } from '@mui/material/styles'
import { spacing as muiSpacing } from '@mui/system'
import { shouldNotForwardProps, spacing } from '../../foundation/utils'
import { TabListProps, TabsProps } from './tabs.types'

export const Tab = styled(MuiTab)`
  text-transform: none;
  padding: 0;
  min-width: 0;
  min-height: 0;
  font-size: 14px;
`

const TabContext: React.FC<TabsProps> = styled(MuiTabContext, { shouldForwardProp: shouldNotForwardProps })`
  ${muiSpacing};
`

const StyledTabList = styled(MuiTabList, { shouldForwardProp: shouldNotForwardProps })`
  min-height: 32px;
  .${tabsClasses.flexContainer} {
    gap: ${spacing(4)};
  }
  .${tabsClasses.scroller} {
    overflow: auto !important;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`

export const TabPanel = styled(MuiTabPanel)`
  padding: 0;
`

export const Tabs = (props: TabsProps) => <TabContext {...props}>{props.children}</TabContext>

export const TabList = ({ hasDivider = true, centered, onChange, children }: TabListProps) => (
  <Box sx={{ borderBottom: hasDivider ? 1 : 0, borderColor: 'divider' }}>
    <StyledTabList centered={centered} onChange={onChange}>
      {children}
    </StyledTabList>
  </Box>
)
