export const getSiteHost = (): string => {
  if (location.port === '3002') {
    return 'http://localhost:3000'
  }
  return process.env['NX_SITE_URL'] as string
}

export const getSiteUrl = (path: string = '') => {
  const siteHost = getSiteHost()
  return (path || '').substring(0, 1) === '/' ? `${siteHost}${path}` : `${siteHost}/${path}`
}

export const getGroupUrl = (path?: string) => {
  return getSiteUrl('/g/') + path
}
