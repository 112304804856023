import { SpacingProps } from '@mui/system'
import { ReactNode } from 'react'

export enum StatsVariant {
  positive = 'positive',
  negative = 'negative',
  neutral = 'neutral',
  neutralVariant = 'neutralVariant',
  attention = 'attention',
  informative = 'informative',
}

export enum StatsSize {
  large = 'large',
  small = 'small',
  tiny = 'tiny',
}

export enum StatsIcon {
  up = 'up',
  down = 'down',
}

export interface StatsProps extends SpacingProps {
  variant: keyof typeof StatsVariant
  size: keyof typeof StatsSize
  icon?: keyof typeof StatsIcon
  children: ReactNode
}
