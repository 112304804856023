import { Button, Input, Sheet, SheetContent } from '@hub-la/shadcn'
import { useFormik } from 'formik'
import { ArrowLeft } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { IntegrationProvider } from '../../domain/dtos/integration-provider'
import { useCreateIntegration } from '../hooks/use-create-integration'

export type Values = {
  url: string
  apiKey: string
  secretToken: string
}

type ConnectAccountModalProps = {
  isOpen: boolean
  provider: IntegrationProvider
  schema?: string[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validation?: any
  onClose: () => void
  onSubmit: () => void
}

export const ConnectAccountModal = (props: ConnectAccountModalProps) => {
  const { schema, isOpen, provider, validation, onClose, onSubmit } = props
  const { t } = useTranslation()

  const needUrl = schema?.find((value) => value === 'url')
  const needApiKey = schema?.find((value) => value === 'apiKey')
  const needSecretToken = schema?.find((value) => value === 'secretToken')

  const { isLoading, mutateAsync: createIntegration, isError, error } = useCreateIntegration()

  const formik = useFormik<Values>({
    validationSchema: validation,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      url: '',
      apiKey: '',
      secretToken: '',
    },
    onSubmit: async (values: Values) => {
      await createIntegration({
        provider,
        auth: {
          url: values?.url,
          apiKey: values?.apiKey,
          secretToken: values?.secretToken,
        },
      })
      onSubmit()
      formik.resetForm()
    },
  })

  return (
    <Sheet open={isOpen} onOpenChange={onClose}>
      <SheetContent className="p-6 max-w-lg">
        <div className="flex items-center gap-3 mb-6">
          <Button variant="ghost" size="icon" onClick={onClose}>
            <ArrowLeft className="h-4 w-4" />
          </Button>

          <h2 className="text-lg font-semibold">{t(`integrations.${provider}.create.title`)}</h2>
        </div>

        <div className="flex flex-col gap-4">
          <p className="text-sm text-muted-foreground">{t(`integrations.${provider}.create.description`)}</p>

          {!!needUrl && (
            <div data-testid="input-apiurl">
              <Input
                placeholder="API URL"
                id="url"
                autoFocus
                onChange={formik.handleChange}
                value={formik.values.url}
              />

              {(isError || formik.errors.url) && (
                <p className="text-sm text-destructive mt-1">
                  {isError ? t(error.message) : t(formik.errors.url || '')}
                </p>
              )}
            </div>
          )}

          {!!needApiKey && (
            <div data-testid="input-apikey">
              <Input placeholder="API Key" id="apiKey" onChange={formik.handleChange} value={formik.values.apiKey} />

              {(isError || formik.errors.apiKey) && (
                <p className="text-sm text-destructive mt-1">
                  {isError ? t(error.message) : t(formik.errors.apiKey || '')}
                </p>
              )}
            </div>
          )}

          {!!needSecretToken && (
            <div data-testid="input-secrettoken">
              <Input
                placeholder="Token"
                id="secretToken"
                onChange={formik.handleChange}
                value={formik.values.secretToken}
              />

              {(isError || formik.errors.secretToken) && (
                <p className="text-sm text-destructive mt-1">
                  {isError ? t(error.message) : t(formik.errors.secretToken || '')}
                </p>
              )}
            </div>
          )}
        </div>

        <Button onClick={formik.submitForm} loading={isLoading} className="w-full mt-6">
          {t('buttons.confirm')}
        </Button>
      </SheetContent>
    </Sheet>
  )
}
