import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { GetGroupResourcesUseCase } from '../../usecases/get-group-resources.usecase'

export const useGetGroupResources = (groupId?: string) => {
  const container = useContainer()

  return useQuery(
    [`group-resources-${groupId}`],
    () => new GetGroupResourcesUseCase(container.get(HttpClient)).execute(groupId),
    {
      refetchOnWindowFocus: false,
      initialData: [],
      retry: false,
    },
  )
}
