import { Badge } from '@hub-la/shadcn'
import { InvoiceStatus } from '../../domain/enums/invoice-status'

type InvoiceStatusStatsProps = {
  status?: InvoiceStatus
  installment: number
  installments: number
  size?: 'large' | 'small' | 'tiny'
}

export const InvoiceInstallmentsStats = ({ installment, installments }: InvoiceStatusStatsProps) => {
  return (
    <Badge variant="outline">
      {installment}/{installments}
    </Badge>
  )
}
