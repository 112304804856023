import { container } from '@hub-la/fe-container'
import { CreatePost, CreatePostProvider } from '@hub-la/fe-create-post'
import { useGetUser } from '@hub-la/fe-get-user'
import { useTheme } from '@hub-la/shadcn'
import { useParams } from 'react-router-dom'

export const CreatePostInit = () => {
  useTheme('light')

  const { data: user } = useGetUser()
  const params = useParams<{ productId: string; sectionId: string }>()

  return (
    <CreatePostProvider container={container}>
      <CreatePost userId={user?.id ?? ''} productId={params.productId} sectionId={params.sectionId} />
    </CreatePostProvider>
  )
}
