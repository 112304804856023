import { Button, Card, CardContent, Skeleton } from '@hub-la/shadcn'
import { toDate } from '@hub-la/utils'
import { GripVertical } from 'lucide-react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable, DroppableProps } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { Product, SetOrderRequest } from '../../domain/dtos/product'
import defaultImage from '../assets/default-group-avatar.png'
import { useGetAvailableProducts } from '../hooks/use-get-available-products'
import { useSetOrder } from '../hooks/use-set-order'

// This component is a workaround to avoid the following error
// on the lib itself with react 18 strict mode:
// react-beautiful-dnd: Unable to find draggable with id
// https://github.com/atlassian/react-beautiful-dnd/issues/2407
const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false)
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true))
    return () => {
      cancelAnimationFrame(animation)
      setEnabled(false)
    }
  }, [])
  if (!enabled) {
    return null
  }
  return <Droppable {...props}>{children}</Droppable>
}

export const EditOrderPage: React.FC = () => {
  const { t } = useTranslation()
  const { data: availableProducts, isLoading, error } = useGetAvailableProducts()
  const { mutateAsync: setOrder, isLoading: isSaving } = useSetOrder()
  const [products, setProducts] = useState<Product[]>([])

  useEffect(() => {
    if (availableProducts) {
      setProducts(availableProducts.sort((a, b) => a.order - b.order))
    }
  }, [availableProducts])

  const onDragEnd = (result) => {
    if (!result.destination) return

    const newProducts = Array.from(products)
    const [reorderedItem] = newProducts.splice(result.source.index, 1)
    newProducts.splice(result.destination.index, 0, reorderedItem)

    const updatedProducts = newProducts.map((product, index) => ({
      ...product,
      order: index + 1,
    }))

    setProducts(updatedProducts)
  }

  const handleSave = async () => {
    const request: SetOrderRequest = products.map((product) => ({
      id: product.id,
      order: product.order,
    }))
    await setOrder(request)
  }

  if (error) {
    return <div className="text-red-500">{t('groupsOrder.error')}</div>
  }

  return (
    <div className="flex flex-col gap-4 p-4 max-w-2xl m-auto">
      <div className="flex flex-col text-center space-y-1">
        <h2 className="text-2xl font-bold text-center">{t('groupsOrder.title')}</h2>
        <p className="text-center text-gray-600">{t('groupsOrder.subtitle')}</p>
      </div>

      {isLoading ? (
        <div className="space-y-4">
          {[...Array(5)].map((_, index) => (
            <Card key={index}>
              <CardContent className="flex items-center p-4">
                <Skeleton className="h-12 w-12 rounded-full mr-4" />
                <div className="flex-grow">
                  <Skeleton className="h-4 w-3/4 mb-2" />
                  <Skeleton className="h-3 w-1/2" />
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      ) : products.length === 0 ? (
        <p className="text-center">{t('groupsOrder.noGroups')}</p>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <StrictModeDroppable droppableId="products">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-4">
                {products.map((product, index) => (
                  <Draggable key={product.id} draggableId={product.id} index={index}>
                    {(provided) => (
                      <Card ref={provided.innerRef} {...provided.draggableProps} className="bg-white">
                        <CardContent className="flex items-center p-4">
                          <div {...provided.dragHandleProps} className="mr-4">
                            <GripVertical className="text-gray-400" />
                          </div>
                          <img
                            src={product.picture ?? defaultImage}
                            alt={product.name}
                            className="w-12 h-12 rounded-full mr-4 object-cover"
                          />
                          <div className="flex-grow">
                            <h3 className="font-semibold">{product.name}</h3>
                            <p className="text-sm text-gray-500">
                              {t('Created')}: {moment(toDate(product?.createdAt) ?? '').format('D MMM. YYYY HH:mm')}
                            </p>
                          </div>
                        </CardContent>
                      </Card>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      )}

      {products.length > 0 && (
        <Button variant="default" onClick={handleSave} loading={isSaving}>
          {t('groupsOrder.save')}
        </Button>
      )}
    </div>
  )
}
