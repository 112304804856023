import { Button } from '@hub-la/shadcn'
import { ExternalLink } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { IntegrationProvider } from '../../../domain/dtos/integration-provider'

type ZapCertoCardActionsProps = {
  provider: IntegrationProvider
}

export const ZapCertoCardActions = ({ provider }: ZapCertoCardActionsProps) => {
  const { t } = useTranslation()

  return (
    <div className="w-full flex flex-col items-center justify-between gap-2">
      <Button className="w-full" onClick={() => (window.location.href = 'https://zp-c.com/hubla')}>
        Teste gratuitamente
      </Button>

      <a
        className="flex items-center gap-2 font-800 text-muted-foreground"
        href={t(`integrations.${provider}.about.link`)}
        target="_blank"
        rel="noreferrer"
      >
        {t('aboutTab.seeMore')}

        <ExternalLink fontSize="small" />
      </a>
    </div>
  )
}
