import { CircleSlash, CircleOff, CircleDashed, CircleCheck } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { SmartInstallmentStatus } from '../../domain/enums/smart-installment-status'

type SmartInstallmentStatusStatsProps = {
  status: SmartInstallmentStatus
  size?: any
}

const getIcon = (status: SmartInstallmentStatus) => {
  const iconSize = 20 // Super small size for icons

  switch (status) {
    case SmartInstallmentStatus.COMPLETED:
      return <CircleCheck size={iconSize} className="mr-1 text-green-700" fill="green" style={{ fillOpacity: 0.1 }} />
    case SmartInstallmentStatus.ON_SCHEDULE:
      return <CircleDashed size={iconSize} className="mr-1 text-blue-700" fill="blue" style={{ fillOpacity: 0.1 }} />
    case SmartInstallmentStatus.OFF_SCHEDULE:
      return <CircleSlash size={iconSize} className="mr-1 text-red-700" fill="red" style={{ fillOpacity: 0.1 }} />
    default:
      return <CircleOff size={iconSize} className="mr-1 text-gray-700" fill-current style={{ fillOpacity: 0.1 }} />
  }
}

export const SmartInstallmentStatusStats = ({ status }: SmartInstallmentStatusStatsProps) => {
  const { t } = useTranslation()

  return (
    <div className="flex items-center gap-2">
      {getIcon(status)}
      {t(`smartInstallmentStatus.${status}`)}
    </div>
  )
}
