import { useContainer } from '@hub-la/fe-container'
import { Analytics, AnalyticsEvent } from '@hub-la/fe-core-analytics'
import { Button, Dialog, DialogContent, Input } from '@hub-la/shadcn'
import { DateRange, DateRangePicker, LocalizationProvider, SingleInputDateRangeField } from '@mui/x-date-pickers-pro'
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment'
import { Moment } from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BookAccount } from '../../../domain/dtos/book-accounts'
import { ExportMovementsFilterDto } from '../../../domain/dtos/export-movements-request.dto'
import { ExportAlreadyInProgressError } from '../../../domain/errors/export-already-in-progress'
import { GetDateRange } from '../../../usecases/get-date-range'
import { useExportMovements } from '../../hooks/use-export-movements'

export interface ExportMovementsDialogProps {
  open: boolean
  accountType: BookAccount
  onClose: () => void
}

enum ExportMovementsModalSteps {
  filterByDate = 'filterByDate',
  result = 'result',
}

export const ExportMovementsModal: React.FC<ExportMovementsDialogProps> = ({ open, accountType, onClose }) => {
  const { t } = useTranslation()
  const container = useContainer()
  const analytics = container.get(Analytics)
  const initialDateRange = new GetDateRange().execute(7)
  const [exportFilter, setExportFilter] = useState<ExportMovementsFilterDto>({
    startDate: initialDateRange.startDate,
    endDate: initialDateRange.endDate,
    accountType: accountType,
    receiverEmail: '',
  })
  const [step, setStep] = useState<ExportMovementsModalSteps>(ExportMovementsModalSteps.filterByDate)
  const [modalMessage, setModalMessage] = useState<string>('')
  const { mutateAsync, isLoading } = useExportMovements()

  const handleClose = () => {
    setStep(ExportMovementsModalSteps.filterByDate)
    setModalMessage('')
    onClose()
  }

  const handleExportMovements = () => {
    analytics.track(AnalyticsEvent.WALLET.EXPORT_CLICKED, { accountType })
    mutateAsync(exportFilter)
      .then((output) => {
        setModalMessage(t('exportMovementsModal.successMessage', { email: output.email }))
        setStep(ExportMovementsModalSteps.result)
      })
      .catch((error) => {
        if (error instanceof ExportAlreadyInProgressError) {
          setModalMessage(t('exportMovementsModal.conflictErrorMessage'))
        } else {
          setModalMessage(t('exportMovementsModal.generalErrorMessage'))
        }
        setStep(ExportMovementsModalSteps.result)
      })
  }

  const handleDateChange = (value: DateRange<Moment>) => {
    const startDate = value[0]?.startOf('day').toDate()
    const endDate = value[1]?.endOf('day').toDate()
    if (startDate && endDate) {
      setExportFilter((prev) => ({ ...prev, startDate, endDate }))
    }
  }

  const content = (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">{t('exportMovementsModal.title')}</h3>
      {step === ExportMovementsModalSteps.filterByDate ? (
        <>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt-BR">
            <DateRangePicker
              slots={{ field: SingleInputDateRangeField }}
              slotProps={{
                textField: {
                  label: t('exportMovementsModal.fields.filterByDate.label'),
                  className: 'w-full',
                },
              }}
              onChange={handleDateChange}
              calendars={1}
              format="D MMM. YYYY"
              currentMonthCalendarPosition={1}
              showDaysOutsideCurrentMonth={false}
              disableAutoMonthSwitching
            />
          </LocalizationProvider>
          <Input
            placeholder="exemplo@email.com"
            value={exportFilter.receiverEmail}
            onChange={(e) => setExportFilter((prev) => ({ ...prev, receiverEmail: e.target.value }))}
          />
          <div className="flex justify-end space-x-2">
            <Button variant="outline" onClick={handleClose} disabled={isLoading}>
              {t('exportMovementsModal.buttons.cancel')}
            </Button>
            <Button onClick={handleExportMovements} loading={isLoading}>
              {t('exportMovementsModal.buttons.export')}
            </Button>
          </div>
        </>
      ) : (
        <>
          <p>{modalMessage}</p>
          <div className="flex justify-end">
            <Button onClick={handleClose} loading={isLoading}>
              {t('exportMovementsModal.buttons.getIt')}
            </Button>
          </div>
        </>
      )}
    </div>
  )

  return (
    <Dialog open={open} onOpenChange={(open) => !open && handleClose()}>
      <DialogContent>{content}</DialogContent>
    </Dialog>
  )
}
