import { FormikProvider, useFormik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CreateCollaboratorValues } from '../../../domain/dtos/create-collaborator-values'
import { PermissionResource } from '../../../domain/dtos/permissions-resources-enum'
import { CollaboratorForm } from '../../components/collaborator-form'
import { useCreateCollaborator } from '../../hooks/use-create-collaborator'
import { createCollaboratorSchema } from '../../validations/create-collaborator.schema'

export const CreateCollaboratorPage: React.FC = () => {
  const { t } = useTranslation()
  const { mutateAsync, isLoading, error } = useCreateCollaborator()

  const formik = useFormik<CreateCollaboratorValues>({
    validationSchema: createCollaboratorSchema,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      email: '',
      permissions: Object.values(PermissionResource),
    },
    onSubmit: async (values: CreateCollaboratorValues) => {
      await mutateAsync(values)
    },
  })

  return (
    <FormikProvider value={formik}>
      <h1 className="text-2xl font-bold mb-3">{t('collaboratorsList.button')}</h1>

      <p className="text-sm text-gray-600 mb-6">{t('collaboratorsList.description')}</p>

      <CollaboratorForm error={error} loading={isLoading} />
    </FormikProvider>
  )
}
