import { useGetUser } from '@hub-la/fe-get-user'
import { useParams } from 'react-router-dom'
import { CreateSectionButton } from '../../components/create-section-button/create-section-button'
import { SectionList } from '../../components/section-list/section-list'
import { SectionListSkeleton } from '../../components/section-list/section-list-sekeleton'
import { useListSections } from '../../hooks/use-list-sections'

export const ListPosts = () => {
  const { productId } = useParams<{ productId: string }>()
  const { data: currentUser } = useGetUser()
  const { isFetching, data } = useListSections({ productId })

  const isLoading = isFetching && data.pageSize === 90

  return (
    <div className="w-full flex flex-col flex-1" data-testid="post-dashboard">
      <div className="w-min self-end">
        <CreateSectionButton />
      </div>

      {isLoading && <SectionListSkeleton />}
      {!isLoading && <SectionList productId={productId} userId={currentUser?.id ?? ''} sections={data.items} />}
    </div>
  )
}
