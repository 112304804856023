import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { GetTotalRefundInput } from '../../domain/dtos/get-total-refund-input'
import { Indicator } from '../../domain/dtos/indicator'
import { QueryKey } from '../../domain/dtos/query-keys'
import { Status } from '../../domain/dtos/status'
import { GetTotalRefund } from '../../usecases/get-total-refund'

export const useGetTotalRefund = (input: GetTotalRefundInput, isLoading: boolean) => {
  const container = useContainer()

  const offerIds = input.offers.filter(({ id }) => id !== 'all').map(({ id }) => id)
  const enabled = !isLoading && offerIds.length > 0
  const initialData = {
    current: 0,
    previous: 0,
    percentage: '',
    status: Status.neutral,
    indicator: Indicator.down,
  }

  return useQuery(
    [QueryKey.getTotalRefund],
    ({ signal }) => {
      if (!enabled) {
        return Promise.resolve(initialData)
      }
      return new GetTotalRefund(container.get(HttpClient)).execute(input, signal)
    },
    {
      retry: 3,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      initialData,
      enabled,
    },
  )
}
