import { PermissionResource, useRoleplay } from '@hub-la/fe-roleplay'
import { Button, Input } from '@hub-la/shadcn'
import debounce from 'lodash/debounce'
import { Download, Search, UserPlus } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { SelectSubscriptionType } from '../../../components/select-subscription-type'

type ActionsProps = {
  onSearchChange: (value: string) => void
  onSubscriptionTypeChange: (value: string[]) => void
  onAddMember: () => void
  onExportActiveMembers: () => void
}

export const Actions = ({
  onSearchChange,
  onSubscriptionTypeChange,
  onAddMember,
  onExportActiveMembers,
}: ActionsProps) => {
  const { t } = useTranslation()
  const { hasPermission } = useRoleplay()

  const handleSearchChange = debounce((value) => onSearchChange(value), 500)

  return (
    <div className="flex flex-col md:flex-row justify-between items-center mb-6 mt-2 gap-4">
      <div className="w-full md:w-auto flex flex-col md:flex-row items-center gap-4">
        <div className="w-full md:w-80 relative">
          <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-500" />

          <Input
            id="search"
            className="pl-8"
            placeholder={t('tabs.participants.actions.search.placeholder')}
            onChange={(e) => handleSearchChange(e.target.value)}
          />
        </div>

        <SelectSubscriptionType onChange={onSubscriptionTypeChange} />
      </div>

      <div className="w-full flex flex-col sm:flex-row gap-2">
        {hasPermission(PermissionResource.PRODUCTS_MANAGE_MEMBERS) && (
          <Button variant="outline" className="w-full" onClick={onAddMember}>
            <UserPlus className="mr-2 h-4 w-4" />
            {t('tabs.participants.actions.addMember')}
          </Button>
        )}

        <Button variant="outline" className="w-full" onClick={onExportActiveMembers}>
          <Download className="mr-2 h-4 w-4" />
          {t('tabs.participants.actions.export')}
        </Button>
      </div>
    </div>
  )
}
