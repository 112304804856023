import { useContainer } from '@hub-la/fe-container'
import { ListPosts, ListPostsProvider } from '@hub-la/fe-list-posts'
import { Card, CardContent, cn } from '@hub-la/shadcn'
import { ExternalLink } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { MemberAreaType, useGetMemberAreaType } from '../../../hooks/member-area/use-get-member-area-type'
import { ExternalMembersAreaForm } from './external-members-area-form'
import { Loading } from './loading'

export const Content = () => {
  const { productId } = useParams<{ productId: string }>()
  const { memberAreaType, isLoading, setMemberAreaType, externalMemberArea } = useGetMemberAreaType({ productId })
  const { t } = useTranslation()
  const container = useContainer()

  if (isLoading && memberAreaType === MemberAreaType.None) {
    return <Loading />
  }

  if (memberAreaType === MemberAreaType.Internal) {
    return (
      <ListPostsProvider container={container}>
        <ListPosts />
      </ListPostsProvider>
    )
  }

  return (
    <div className="max-w-2xl my-4 mx-auto flex flex-col gap-6">
      <div className="flex flex-col gap-2 items-center">
        <h4 className="text-xl font-semibold">{t('member-area.content.title')}</h4>

        <p>{t('member-area.content.subtitle')}</p>
      </div>

      <div className="flex gap-4">
        <Card
          className="w-full border cursor-pointer transition-colors hover:bg-gray-100"
          onClick={() => setMemberAreaType(MemberAreaType.Internal)}
        >
          <CardContent className="px-4 py-10 flex flex-col gap-4 items-center">
            <ExternalLink className="h-5 w-5" />

            <p>{t('member-area.content.internal')}</p>
          </CardContent>
        </Card>

        <Card
          className={cn(
            'w-full border cursor-pointer transition-colors hover:bg-gray-100',
            memberAreaType === MemberAreaType.External ? 'bg-gray-100 border-black border-2' : '',
          )}
          onClick={() => setMemberAreaType(MemberAreaType.External)}
        >
          <CardContent className="px-4 py-10 flex flex-col gap-4 items-center">
            <ExternalLink className="h-5 w-5" />

            <p>{t('member-area.content.external')}</p>
          </CardContent>
        </Card>
      </div>

      {memberAreaType === MemberAreaType.External && (
        <ExternalMembersAreaForm
          initialData={
            externalMemberArea
              ? {
                  hasAccessInstructionMessage: externalMemberArea?.hasAccessInstructionMessage,
                  accessInstructionMessage: externalMemberArea?.accessInstructionMessage,
                  accessURL: externalMemberArea?.accessURL,
                  id: externalMemberArea?.id,
                }
              : undefined
          }
        />
      )}
    </div>
  )
}
