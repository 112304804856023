import { Skeleton } from '@hub-la/shadcn'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MovementDto } from '../../../domain/dtos/get-moviments.dto'
import { ContentRow } from '../content-row/content-row'

interface MovementsProps {
  movements: MovementDto[] | undefined
  isLoading: boolean
}

export const Movements: React.FC<MovementsProps> = ({ movements, isLoading }) => {
  const { t } = useTranslation()

  if (isLoading) {
    return (
      <div className="flex items-center justify-between mb-3 mt-2" data-testid="loading-movements">
        <Skeleton className="h-4 w-10" />
        <Skeleton className="h-4 w-15" />
      </div>
    )
  }

  if (movements?.length === 0) {
    return (
      <div className="flex items-center justify-between mb-3 mt-2">
        <p className="text-sm text-gray-600">{t('invoiceDetails.movementsIsEmpty')}</p>
      </div>
    )
  }

  return (
    <div className="space-y-2">
      {movements?.map((movement) => (
        <ContentRow
          key={movement.id}
          label={t(`transactionSource.available.${movement.transactionSource}`)}
          value={moment(movement.createdAt).format('DD/MM/YY')}
        />
      ))}
    </div>
  )
}
