import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { ShortLink } from '../../../domain/dtos/short-link'
import { CreateEditShortLinkType } from '../../../domain/enums/create-edit-shortlink-type'
import { useGetShortLinks } from '../../hooks/shortlinks/use-get-short-links'
import { useGetOffers } from '../../hooks/shortlinks/use-offers'
import { CardSectionShortLinks } from './components/card-section-shortlinks'
import { CreateEditShortLinkModal } from './components/create-edit-shortlink-modal'

export const ShortLinksPage: React.FC = () => {
  const { productId } = useParams<{ productId: string }>()
  const { data: offers } = useGetOffers({ productId })

  const [showCreateEditShortLinkModal, setShowCreateEditShortLinkModal] = useState<{
    isOpen: boolean
    shortLink: ShortLink | null
    type: CreateEditShortLinkType
  }>({
    isOpen: false,
    shortLink: null,
    type: CreateEditShortLinkType.CREATE,
  })

  const { data, isLoading, refetch } = useGetShortLinks({
    productId,
  })

  return (
    <div className="flex flex-col gap-4 mb-4">
      <CardSectionShortLinks
        productId={productId}
        isLoading={isLoading}
        total={data?.total ?? 0}
        items={data?.items ?? []}
        onUpdate={refetch}
        setShowCreateEditModal={setShowCreateEditShortLinkModal}
      />

      <CreateEditShortLinkModal
        productId={productId}
        offers={offers ?? []}
        shortLink={showCreateEditShortLinkModal.shortLink}
        type={showCreateEditShortLinkModal.type}
        isOpen={showCreateEditShortLinkModal.isOpen}
        isLoading={isLoading}
        onClose={() => {
          setShowCreateEditShortLinkModal({
            ...showCreateEditShortLinkModal,
            isOpen: false,
            shortLink: null,
          })
        }}
        onSuccess={() => {
          setShowCreateEditShortLinkModal({
            ...showCreateEditShortLinkModal,
            isOpen: false,
            shortLink: null,
          })
          refetch()
        }}
      />
    </div>
  )
}
