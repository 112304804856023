import { Button, Input, useIsMobile } from '@hub-la/shadcn'
import { Download, Search, Tag } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GetOfferOutput } from '../../domain/dtos/get-offer-output'
import { ConfirmExportModal } from './confirm-export-modal'
import { CreateCouponModal } from './create-coupon-modal'
import { SelectOffers } from './select-offers'
import { SelectStatus } from './select-status'

interface FiltersProps {
  filters: any
  setFilters: (filters: any) => void
  offers: GetOfferOutput
  isLoading: boolean
}

export const Filters = ({ setFilters, filters, offers, isLoading }: FiltersProps) => {
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)

  const { t } = useTranslation()
  const isMobile = useIsMobile()

  return (
    <div className="flex justify-between">
      <div className="hidden md:flex gap-3">
        <div className="relative h-10 w-full">
          <Search className="absolute w-4 h-4 right-3 top-1/2 transform -translate-y-1/2 text-gray-500 z-10" />
          <Input
            type="text"
            placeholder="Buscar..."
            className="w-[250px]"
            value={filters.search}
            onChange={(e) => setFilters({ ...filters, search: e.target.value })}
          />
        </div>

        <SelectOffers
          offers={offers}
          value={filters.offerIds}
          onChange={(ids) => setFilters((prevState) => ({ ...prevState, offerIds: ids }))}
        />

        <SelectStatus
          value={filters.statuses}
          onChange={(value) => setFilters((prevState) => ({ ...prevState, statuses: value }))}
        />
      </div>

      <div className="flex w-full flex-col md:flex-row md:justify-end gap-3">
        <Button variant="outline" className={`${isMobile ? 'w-full' : ''}`} onClick={() => setShowCreateModal(true)}>
          <Tag className="mr-2 h-4 w-4" />
          {t('filters.create')}
        </Button>

        <Button variant="outline" className={`${isMobile ? 'w-full' : ''}`} onClick={() => setShowConfirmModal(true)}>
          <Download className="mr-2 h-4 w-4" />
          {t('filters.export')}
        </Button>
        <CreateCouponModal
          isOpen={showCreateModal}
          isLoading={isLoading}
          onClose={() => setShowCreateModal(false)}
          onSuccess={() => setShowCreateModal(false)}
          offers={offers}
        />
        <ConfirmExportModal
          isOpen={showConfirmModal}
          isLoading={isLoading}
          onClose={() => setShowConfirmModal(false)}
          onSuccess={() => setShowConfirmModal(false)}
          offers={offers}
        />
      </div>
    </div>
  )
}
