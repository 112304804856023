import { Card, CardDescription, CardHeader, CardTitle } from '@hub-la/shadcn'
import { ReactNode } from 'react'
import { Indicator } from '../../domain/dtos/indicator'
import { Status } from '../../domain/dtos/status'
import { InfoTooltip } from './info-tooltip'
import { StatusBox } from './status-box'

type CardInfoBoxProps = {
  title: string
  description?: string
  current: string
  previous?: string
  variation?: {
    indicator?: Indicator
    status?: Status
    description: string
  }
  children?: ReactNode
}

export const CardInfoBox = ({ title, description, current, previous, variation, children }: CardInfoBoxProps) => {
  return (
    <div className="w-full h-full">
      <Card className="min-h-full">
        <CardHeader className="space-y-0">
          <CardDescription>
            <div className="text-muted-foreground flex items-center gap-1">
              {title}
              {description && <InfoTooltip title={description} />}
            </div>
          </CardDescription>
          <CardTitle className="text-4xl font-semibold tabular-nums">
            {current}{' '}
            <span className="font-sans text-sm font-normal tracking-normal text-muted-foreground">
              {previous && <p className="text-sm text-gray-500">{previous}</p>}
            </span>
            {variation && (
              <div className="flex items-start mt-1 flex-wrap">
                <StatusBox
                  indicator={variation.indicator}
                  status={variation.status}
                  description={variation.description}
                />
              </div>
            )}
          </CardTitle>
          {children}
        </CardHeader>
      </Card>
    </div>
  )
}
