import {
  Pix,
  Table,
  TableBody,
  TableCell,
  TableEmptyState,
  TableHead,
  TableHeader,
  TablePagination,
  TableRow,
  useToast,
} from '@hub-la/shadcn'
import isEmpty from 'lodash/isEmpty'
import times from 'lodash/times'
import { Barcode, CreditCard } from 'lucide-react'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Invoice } from '../../../../domain/dtos/invoice'
import { Offer } from '../../../../domain/dtos/offer'
import { OrderDirection } from '../../../../domain/enums/order-direction'
import { PaymentMethod } from '../../../../domain/enums/payment-method'
import { formatCurrency } from '../../../../domain/vos/format-currency'
import { InvoiceStatusBadge } from '../../../components/invoice-status-badge'
import { SmartInstallmentBadge } from '../../../components/smart-installment-badge'
import { useCheckHaveChanged } from '../../../hooks/use-check-have-changed'
import { useGetInvoices } from '../../../hooks/use-get-invoices'
import { InvoicesTableLoading } from './invoices-table-loading'

type Props = {
  offers: Offer[]
  filters: {
    offerIds: string[]
  }
}

export const InvoicesTable = ({ offers, filters }: Props) => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(25)

  const history = useHistory()
  const { toast } = useToast()
  const { t } = useTranslation()
  const {
    data = { items: [], total: 0, page, pageSize: pageSize, lastPage: 1 },
    isFetching,
    refetch,
    error,
  } = useGetInvoices({
    offerIds: filters.offerIds,
    offers,
    page: page,
    pageSize: pageSize,
    orderDirection: OrderDirection.DESC,
    orderBy: 'createdAt',
  })

  useEffect(() => {
    if (error) {
      toast({ description: t((error as Error)?.message) ?? '', variant: 'destructive' })
    }
  }, [error, toast, t])

  const isRowsEmpty = isEmpty(data?.items)
  const canRenderEmptyState = !isFetching && isRowsEmpty

  const getIcon = (paymentMethod: PaymentMethod) => {
    switch (paymentMethod) {
      case PaymentMethod.BOLETO:
        return <Barcode className="w-5 h-5" />
      case PaymentMethod.PIX:
        return <Pix className="w-5 h-5" />
      case PaymentMethod.CREDIT_CARD:
        return <CreditCard className="w-5 h-5" />
      default:
        return null
    }
  }

  const canRefetch = useCheckHaveChanged({ page, pageSize, offerIds: filters.offerIds })
  useEffect(() => {
    canRefetch && refetch()
  }, [canRefetch, refetch])

  return (
    <div className="h-full w-full flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        <div className="rounded-lg border overflow-hidden">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>{t('invoices.table.product')}</TableHead>
                <TableHead>{t('invoices.table.status')}</TableHead>
                <TableHead>{t('invoices.table.total')}</TableHead>
                <TableHead>{t('invoices.table.type')}</TableHead>
                <TableHead>{t('invoices.table.createdAt')}</TableHead>
              </TableRow>
            </TableHeader>

            <TableBody>
              {isFetching
                ? times(pageSize, (i) => <InvoicesTableLoading key={`skeleton-row-${i}`} />)
                : (data?.items ?? []).map((invoice: Invoice) => {
                    return (
                      <TableRow
                        key={invoice.id}
                        onClick={() => history.push(`/user_invoices/${invoice.id}`)}
                        className="cursor-pointer"
                      >
                        <TableCell className="text-gray-400 font-light">
                          {invoice.items[0]?.productName ?? ''}
                        </TableCell>

                        <TableCell>
                          <div className="flex gap-1">
                            <InvoiceStatusBadge status={invoice.status} />

                            {invoice.smartInstallmentRef && (
                              <SmartInstallmentBadge
                                status={invoice.status}
                                installment={invoice.installment}
                                installments={invoice.installments}
                              />
                            )}
                          </div>
                        </TableCell>

                        <TableCell>
                          <div className="flex items-center gap-2">
                            {getIcon(invoice.paymentMethod)}

                            <span>{formatCurrency(invoice.totalCents / 100)}</span>
                          </div>
                        </TableCell>

                        <TableCell className="text-gray-400 font-light">
                          {t(`invoices.invoiceType.${invoice.type}`)}
                        </TableCell>

                        <TableCell className="text-gray-400 font-light">
                          {moment(invoice.createdAt).format('D MMM. YYYY')}
                        </TableCell>
                      </TableRow>
                    )
                  })}
            </TableBody>
          </Table>

          {canRenderEmptyState && <TableEmptyState title={t('invoices.table.empty')} />}
        </div>

        {!isRowsEmpty && (
          <TablePagination
            lastPage={data.lastPage}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        )}
      </div>
    </div>
  )
}
