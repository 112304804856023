import { useTranslation } from 'react-i18next'
import { Offer } from '../../../../domain/dtos/offer'
import { PaymentMethod } from '../../../../domain/dtos/payment-method'
import { Status } from '../../../../domain/dtos/status'
import { formatPercentage } from '../../../../domain/vos/format-percentage'
import { CardInfoBox } from '../../../components/card-info-box'
import { useGetConversionRate } from '../../../hooks/use-get-conversion-rate'
import { useRefetch } from '../../../hooks/use-refetch'
import { Loading } from './loading'

type CreditCardApprovalRateProps = {
  startDate: string
  endDate: string
  offerIds: string[]
  offers: Offer[]
  isLoading: boolean
}

export const CreditCardApprovalRate = (props: CreditCardApprovalRateProps) => {
  const { startDate, endDate, offers, offerIds, isLoading } = props
  const { t } = useTranslation()

  const { data, isFetching, refetch } = useGetConversionRate(
    { startDate, endDate, offers, offerIds, paymentMethod: PaymentMethod.CREDIT_CARD },
    isLoading,
  )
  const isLoadingData = isFetching && data.current === 0

  useRefetch({ startDate, endDate, offers, offerIds }, refetch)

  if (isLoadingData || isLoading) {
    return <Loading />
  }

  const getVariation = () => {
    return data.status !== Status.neutral
      ? {
          indicator: data.indicator,
          status: data.status,
          description: `${data.percentage} ${t('variation', { previous: formatPercentage(data.previous) })}`,
        }
      : undefined
  }

  return (
    <CardInfoBox
      title={t('creditCardApprovalRate.title')}
      description={t('creditCardApprovalRate.description')}
      current={formatPercentage(data.current)}
      variation={getVariation()}
    />
  )
}
