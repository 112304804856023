import { Button, Card, CardContent, CardHeader, CardTitle, Input } from '@hub-la/shadcn'
import { ExternalLink, RefreshCw } from 'lucide-react'
import { useTranslation } from 'react-i18next'

export const WebhookAuthenticationTab = ({ secretToken }) => {
  const { t } = useTranslation()

  return (
    <div className="grid grid-cols-1 sm:grid-cols-12 gap-6">
      <div className="sm:col-span-5">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col justify-between w-full">
            <h2 className="text-2xl font-semibold mb-2">{t('integrations.webhook.auth.title')}</h2>

            <p className="text-sm text-muted-foreground mb-2">{t('integrations.webhook.auth.description')}</p>
          </div>
        </div>
      </div>

      <div className="sm:col-span-7">
        <div className="flex flex-col gap-4">
          <Card>
            <CardHeader>
              <CardTitle>{t('integrations.webhook.auth.token')}</CardTitle>
            </CardHeader>

            <CardContent>
              <p className="text-sm text-muted-foreground mb-4">{t('integrations.webhook.auth.disclaimer')}</p>

              <div className="flex flex-row gap-2">
                <Input placeholder="Nome" className="flex-grow" disabled value={secretToken} />

                <Button variant="outline" size="icon">
                  <RefreshCw className="h-4 w-4" />
                </Button>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>{t('integrations.webhook.auth.docs')}</CardTitle>
            </CardHeader>

            <CardContent>
              <a
                className="flex items-center gap-2"
                href="https://hubla.notion.site/Webhook-Events-a41d890d1ce746499563cb37ee9f60d8"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>{t('integrations.webhook.auth.helpLink')}</span>

                <ExternalLink className="h-4 w-4" />
              </a>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  )
}
