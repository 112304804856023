import { Badge } from '@hub-la/shadcn'
import { SmartInstallmentStatus } from '../../domain/enums/smart-installment-status'

type InvoiceStatusStatsProps = {
  status?: SmartInstallmentStatus
  installment: number
  installments: number
  size?: any
}

export const SmartInstallmentChip = ({ installment, installments }: InvoiceStatusStatsProps) => {
  return (
    <Badge variant="outline">
      {installment}/{installments}
    </Badge>
  )
}
