import { alpha } from '@mui/system'
import merge from 'deepmerge'
import { CHART_TOKENS } from './chart-tokens'
import { Tokens } from './tokens.types'

export const TOKENS_LIGHT: Tokens = merge(CHART_TOKENS, {
  primary: '#587300',
  onPrimary: '#fff',

  primaryContainer: '#D7FF60',
  onPrimaryContainer: '#314000',

  success: '#54651B',
  onSuccess: '#fff',

  successContainer: '#CCE47C',
  onSuccessContainer: '#2B350A',

  error: '#F08A71',
  onError: '#fff',

  errorContainer: '#F1D0C5',
  onErrorContainer: '#80230D',

  warning: '#F7CC4A',
  onWarning: '#2E1501',

  warningContainer: '#FDE398',
  onWarningContainer: '#8E4E0B',

  info: '#6B90FF',
  onInfo: '#fff',

  infoContainer: '#C7D4F3',
  onInfoContainer: '#0C44B4',

  background: '#fff',
  onBackground: '#1A1C18',

  surface: '#fff',
  onSurface: '#1A1C18',

  surfaceAt1: alpha('#587300', 0.05),

  surfaceAt2: alpha('#587300', 0.08),

  surfaceAt3: alpha('#587300', 0.11),

  surfaceAt4: alpha('#587300', 0.12),

  surfaceAt5: alpha('#587300', 0.14),

  surfaceVariant: '#E0E4D8',
  onSurfaceVariant: '#444840',

  outline: '#74796F',
})

export const TOKENS_DARK: Tokens = merge(CHART_TOKENS, {
  primary: '#A8D90B',
  onPrimary: '#314000',

  primaryContainer: '#587300',
  onPrimaryContainer: '#D7FF60',

  success: '#BBD076',
  onSuccess: '#2B350A',

  successContainer: '#3F4C12',
  onSuccessContainer: '#CCE47C',

  error: '#F08A71',
  onError: '#5A1909',

  errorContainer: '#80230D',
  onErrorContainer: '#F1D0C5',

  warning: '#F7CC4A',
  onWarning: '#4B2603',

  warningContainer: '#8E4E0B',
  onWarningContainer: '#FDE398',

  info: '#B2C9F7',
  onInfo: '#002E8A',

  infoContainer: '#0C44B4',
  onInfoContainer: '#C7D4F3',

  background: '#121212',
  onBackground: '#fff',

  surface: '#1A1A1A',
  onSurface: '#fff',

  surfaceAt1: alpha('#A8D90B', 0.05),

  surfaceAt2: alpha('#A8D90B', 0.08),

  surfaceAt3: alpha('#A8D90B', 0.11),

  surfaceAt4: alpha('#A8D90B', 0.12),

  surfaceAt5: alpha('#A8D90B', 0.14),

  surfaceVariant: '#444840',
  onSurfaceVariant: '#C4C8BC',

  outline: '#8E9388',
})
