import { isEmpty } from '@firebase/util'
import React from 'react'
import { useListMemberGroups } from '../../../hooks/use-list-member-groups'
import { Loading } from '../loading'
import { Group } from './group'
import { UserDoesNotHaveGroup } from './user-does-not-have-group'

export const GroupsList: React.FC = () => {
  const { data = [], isFetching } = useListMemberGroups()
  const isDataEmpty = isEmpty(data)
  const canRenderEmptyState = !isFetching && isDataEmpty

  if (isFetching && isDataEmpty) {
    return <Loading />
  }

  return (
    <div>
      {canRenderEmptyState && <UserDoesNotHaveGroup />}
      {!canRenderEmptyState && (
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
          {data.map((data) => (
            <Group
              key={data.id}
              id={data.id}
              name={data.name}
              picture={data.pictureUrl}
              type={data?.type}
              eventData={data?.eventData}
            />
          ))}
        </div>
      )}
    </div>
  )
}
