import { Skeleton, TableCell, TableRow } from '@hub-la/shadcn'

export const HistoryTableLoading = () => {
  return (
    <TableRow>
      {[...Array(6)].map((_, index) => (
        <TableCell key={index} className="p-2">
          <Skeleton className="w-[70%] h-6" />
        </TableCell>
      ))}
    </TableRow>
  )
}
