import { container } from '@hub-la/fe-container'
import { TelegramVerificationProvider, VerificationIntroPage } from '@hub-la/fe-telegram-verification'

export const TelegramVerificationIntroInit = () => {
  return (
    <TelegramVerificationProvider container={container}>
      <VerificationIntroPage />
    </TelegramVerificationProvider>
  )
}
