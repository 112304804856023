type RuleDetailItemProps = {
  title: string
  children: React.ReactNode
}

export const RuleDetailItem = ({ title, children }: RuleDetailItemProps) => {
  return (
    <div className="flex flex-nowrap items-start justify-between">
      <div className="flex flex-wrap items-start justify-start min-w-[20%]">
        <span className="text-sm font-bold">{title}</span>
      </div>

      <div className="flex flex-wrap items-start justify-end">{children}</div>
    </div>
  )
}
