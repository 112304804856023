import { Button, Card, useToast } from '@hub-la/shadcn'
import { useQueryClient } from '@tanstack/react-query'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Invite } from '../../../../domain/dtos/get-pending-invites-output'
import { QueryKey } from '../../../../domain/enums/query-key'
import { useGetPendingInvites } from '../../../hooks/use-get-pending-invites'
import { usePostAcceptInvite } from '../../../hooks/use-post-accept-invite'
import { usePostRejectInvite } from '../../../hooks/use-post-reject-invite'

const InviteCard: React.FC<{
  picture: string
  productName: string
  days: number | null
  needRequestUserInfo: boolean
  onAccept: () => void
  onReject: () => void
  isLoading?: boolean
}> = ({ picture, productName, days, onAccept, onReject, isLoading }) => {
  const { t } = useTranslation()

  return (
    <Card className="p-4 space-y-3 sm:space-y-0 sm:flex sm:justify-between sm:items-center">
      <div className="flex items-center space-x-3">
        <img src={picture} width={90} height={59} alt={`${productName} logo`} className="rounded object-cover" />
        <div>
          <h3 className="font-medium mb-1">{productName}</h3>
          <p className="text-sm text-muted-foreground">
            {!days
              ? t('userGroups.pendingInvites.inviteLifetime')
              : t('userGroups.pendingInvites.inviteDaysTime', { days })}
          </p>
        </div>
      </div>
      <div className="flex space-x-3 sm:items-center">
        <Button variant="outline" onClick={onReject} disabled={isLoading}>
          {t('userGroups.pendingInvites.cancelInvite')}
        </Button>
        <Button variant="default" onClick={onAccept} disabled={isLoading}>
          {t('userGroups.pendingInvites.acceptInvite')}
        </Button>
      </div>
    </Card>
  )
}

export const PendingInvites: React.FC = () => {
  const { t } = useTranslation()
  const { data } = useGetPendingInvites()
  const history = useHistory()
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const {
    mutate: acceptInvite,
    isSuccess,
    isError: isAcceptError,
    error,
    variables: acceptVariables,
    isLoading: isAcceptLoading,
  } = usePostAcceptInvite({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.listMemberGroups])
    },
  })

  const { mutate: rejectInvite, isLoading: isRejectLoading, variables: rejectInviteId } = usePostRejectInvite()

  const handleAccept = (needRequestUserInfo: boolean, invite: Invite) => {
    if (needRequestUserInfo) {
      history.push(`/invites/${invite?.id}/accept`)
      return
    }
    // TODO: Implement accept logic when backend endpoint is ready
  }

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: t('userGroups.pendingInvites.successAccept'),
        duration: 3000,
      })
    }
    if (isAcceptError) {
      toast({
        title: error?.message ?? '',
        variant: 'destructive',
        duration: 3000,
      })
    }
  }, [isSuccess, isAcceptError, error])

  if ((data?.items ?? []).length === 0) {
    return null
  }

  const isLoading = isAcceptLoading || isRejectLoading

  return (
    <div className="space-y-4 mb-4">
      {data?.items.map((invite) => (
        <InviteCard
          key={invite.id}
          productName={invite?.details?.product?.name}
          picture={invite?.details?.product?.picture}
          days={invite?.details?.days}
          needRequestUserInfo={data?.needRequestUserInfo}
          isLoading={(acceptVariables?.inviteId === invite.id || rejectInviteId === invite.id) && isLoading}
          onAccept={() => handleAccept(data?.needRequestUserInfo, invite)}
          onReject={() => rejectInvite(invite.id)}
        />
      ))}
    </div>
  )
}
