import {
  Badge,
  Button,
  Card,
  cn,
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Input,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@hub-la/shadcn'
import { CalendarIcon, CheckIcon, ChevronDownIcon, SlidersHorizontal } from 'lucide-react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { invoiceStatus } from '../../../domain/constants/invoice-status'
import { invoiceType } from '../../../domain/constants/invoice-type'
import { paymentMethods } from '../../../domain/constants/payment-method'
import { Offer } from '../../../domain/dtos/offer'
import { GetDefaultInvoicesFilters } from '../../../usecases/get-default-invoices-filters'
import { GetShortcuts } from '../../../usecases/get-shortchuts'
import { AutocompleteOffers } from '../../components/autocomplete-offers'

type Props = {
  offers: Offer[]
  filters
  setFilters
}

export const FilterDrawerDetails = ({ offers, filters, setFilters }: Props) => {
  const { t } = useTranslation()
  const dateList = new GetShortcuts().execute()

  const dateText = () => {
    const { startDate, endDate } = filters
    const formattedStartDate = moment(startDate).format('D MMM. YYYY')
    const formattedEndDate = moment(endDate).format('D MMM. YYYY')
    const text = `${formattedStartDate} a ${formattedEndDate}`

    const shortcut = dateList.find((it) => it.startDate === startDate && it.endDate === endDate)

    return shortcut ? shortcut.label : text
  }

  const clearFilters = () => {
    setFilters(new GetDefaultInvoicesFilters().execute())
  }

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="outline" className="w-full">
          <SlidersHorizontal className="h-4 w-4 mr-2" />
          Filtros
        </Button>
      </SheetTrigger>
      <SheetContent side="right" className="h-full max-w-2xl overflow-y-auto">
        <SheetHeader>
          <SheetTitle>Filtros</SheetTitle>
          <Button variant="ghost" onClick={clearFilters}>
            Limpar filtros
          </Button>
        </SheetHeader>

        <div className="grid gap-4 py-4">
          <Input
            type="text"
            placeholder="ID da fatura, nome ou e-mail"
            value={filters.search}
            onChange={(e) => setFilters({ ...filters, search: e.target.value })}
            className="w-full"
          />

          <AutocompleteOffers offers={offers} filters={filters} setFilters={setFilters} />

          <Select
            onValueChange={(value) => {
              const selectedDate = dateList.find(({ id }) => id === value)
              if (selectedDate) {
                setFilters({
                  ...filters,
                  period: selectedDate.period,
                  startDate: selectedDate.startDate,
                  endDate: selectedDate.endDate,
                })
              }
            }}
            value={
              dateList.find(
                (it) =>
                  it.period === filters.period && it.startDate === filters.startDate && it.endDate === filters.endDate,
              )?.id
            }
          >
            <SelectTrigger className="flex justify-between items-center w-full px-4 py-2">
              <div className="flex items-center">
                <CalendarIcon className="mr-2 h-4 w-4" />
                <SelectValue>{dateText()}</SelectValue>
              </div>
            </SelectTrigger>

            <SelectContent className="w-full p-0 z-10002" align="start">
              {dateList.map(({ id, label, period, startDate, endDate }) => (
                <SelectItem key={id} value={id}>
                  {label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          <Popover>
            <PopoverTrigger asChild>
              <Button size="default" variant="outline" className="flex justify-between items-center w-full px-4 py-2">
                <div className="flex items-center justify-start">
                  {filters.statuses.length > 0 ? (
                    filters.statuses.length === 1 ? (
                      <>
                        {/* If exactly 1 status is selected, show the label of the selected status */}
                        <span className="text-left">Status</span>
                        <span className="mx-2 opacity-50">|</span>
                        <Badge variant="outline" className="mr-1">
                          {invoiceStatus.find((status) => status.id === filters.statuses[0])?.label}
                        </Badge>
                      </>
                    ) : (
                      <>
                        {/* If more than 1 status is selected, show the count of selected statuses */}
                        <span className="text-left">Status</span>
                        <span className="mx-2 opacity-50">|</span>
                        {filters.statuses.length > 2 ? (
                          <Badge variant="outline" className="mr-1">
                            {filters.statuses.length} selecionados
                          </Badge>
                        ) : (
                          filters.statuses.map((statusId) => (
                            <Badge key={statusId} variant="outline" className="mr-1">
                              {invoiceStatus.find((status) => status.id === statusId)?.label}
                            </Badge>
                          ))
                        )}
                      </>
                    )
                  ) : (
                    <span className="text-left">{t('Status')}</span>
                  )}
                </div>

                <ChevronDownIcon className="ml-auto h-4 w-4 opacity-50" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[300px] p-0 z-10002" align="start">
              <Command>
                <CommandInput placeholder="Status" />
                <CommandList>
                  <CommandGroup>
                    {invoiceStatus.map(({ id, label }) => {
                      const checked = filters.statuses.includes(id)
                      return (
                        <CommandItem
                          key={id}
                          onSelect={() => {
                            const newStatuses = filters.statuses.includes(id)
                              ? filters.statuses.filter((status) => status !== id)
                              : [...filters.statuses, id]
                            setFilters({ ...filters, statuses: newStatuses })
                          }}
                        >
                          <div
                            className={cn(
                              'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                              checked ? 'bg-primary text-primary-foreground' : 'opacity-50 [&_svg]:invisible',
                            )}
                          >
                            <CheckIcon className={cn('h-4 w-4')} />
                          </div>
                          {t(label)}
                        </CommandItem>
                      )
                    })}
                  </CommandGroup>
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>

          <Popover>
            <PopoverTrigger asChild>
              <Button size="default" variant="outline" className="flex justify-between items-center w-full px-4 py-2">
                <div className="flex items-center justify-start">
                  {filters.types.length > 0 ? (
                    filters.types.length === 1 ? (
                      <>
                        <span className="text-left">Tipo</span>
                        <span className="mx-2 opacity-50">|</span>
                        <Badge variant="outline" className="mr-1">
                          {invoiceType.find((type) => type.id === filters.types[0])?.label}
                        </Badge>
                      </>
                    ) : (
                      <>
                        <span className="text-left">Tipo</span>
                        <span className="mx-2 opacity-50">|</span>
                        {filters.types.length > 2 ? (
                          <Badge variant="outline" className="mr-1">
                            {filters.types.length} selecionados
                          </Badge>
                        ) : (
                          filters.types.map((typeId) => (
                            <Badge key={typeId} variant="outline" className="mr-1">
                              {invoiceType.find((type) => type.id === typeId)?.label}
                            </Badge>
                          ))
                        )}
                      </>
                    )
                  ) : (
                    <span className="text-left">Tipo de Fatura</span>
                  )}
                </div>
                <ChevronDownIcon className="ml-auto h-4 w-4 opacity-50" />
              </Button>
            </PopoverTrigger>

            <PopoverContent className="w-[300px] p-0 z-10002" align="start">
              <Command>
                <CommandInput placeholder="Tipo de Fatura" />
                <CommandList>
                  <CommandGroup>
                    {invoiceType.map(({ id, label }) => {
                      const checked = filters.types.includes(id)
                      return (
                        <CommandItem
                          key={id}
                          onSelect={() => {
                            const newTypes = filters.types.includes(id)
                              ? filters.types.filter((type) => type !== id)
                              : [...filters.types, id]
                            setFilters({ ...filters, types: newTypes })
                          }}
                        >
                          <div
                            className={cn(
                              'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                              checked ? 'bg-primary text-primary-foreground' : 'opacity-50 [&_svg]:invisible',
                            )}
                          >
                            <CheckIcon className={cn('h-4 w-4')} />
                          </div>
                          {label}
                        </CommandItem>
                      )
                    })}
                  </CommandGroup>
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>

          <Popover>
            <PopoverTrigger asChild>
              <Button size="default" variant="outline" className="flex justify-between items-center w-full px-4 py-2">
                <div className="flex items-center justify-start">
                  {filters.methods.length > 0 ? (
                    filters.methods.length === 1 ? (
                      <>
                        <span className="text-left">Método</span>
                        <span className="mx-2 opacity-50">|</span>
                        <Badge variant="outline" className="mr-1">
                          {paymentMethods.find((method) => method.id === filters.methods[0])?.label}
                        </Badge>
                      </>
                    ) : (
                      <>
                        <span className="text-left">Método</span>
                        <span className="mx-2 opacity-50">|</span>
                        {filters.methods.length > 2 ? (
                          <Badge variant="outline" className="mr-1">
                            {filters.methods.length} selecionados
                          </Badge>
                        ) : (
                          filters.methods.map((methodId) => (
                            <Badge key={methodId} variant="outline" className="mr-1">
                              {paymentMethods.find((method) => method.id === methodId)?.label}
                            </Badge>
                          ))
                        )}
                      </>
                    )
                  ) : (
                    <span className="text-left">Método de Pagamento</span>
                  )}
                </div>
                <ChevronDownIcon className="ml-auto h-4 w-4 opacity-50" />
              </Button>
            </PopoverTrigger>

            <PopoverContent className="w-[300px] p-0 z-10002" align="start">
              <Command>
                <CommandInput placeholder="Método de Pagamento" />
                <CommandList>
                  <CommandGroup>
                    {paymentMethods.map(({ id, label }) => {
                      const checked = filters.methods.includes(id)
                      return (
                        <CommandItem
                          key={id}
                          onSelect={() => {
                            const newMethods = filters.methods.includes(id)
                              ? filters.methods.filter((method) => method !== id)
                              : [...filters.methods, id]
                            setFilters({ ...filters, methods: newMethods })
                          }}
                        >
                          <div
                            className={cn(
                              'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                              checked ? 'bg-primary text-primary-foreground' : 'opacity-50 [&_svg]:invisible',
                            )}
                          >
                            <CheckIcon className={cn('h-4 w-4')} />
                          </div>
                          {label}
                        </CommandItem>
                      )
                    })}
                  </CommandGroup>
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>

          <Card className="p-4 space-y-4 mt-4">
            <span>UTM Tracking</span>
            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="source">Source</Label>
              <Input
                value={filters.utmSource}
                id="source"
                onChange={(e) => setFilters({ ...filters, utmSource: e.target.value })}
                placeholder="utm_source"
              />
            </div>
            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="medium">Medium</Label>
              <Input
                value={filters.utmMedium}
                id="medium"
                onChange={(e) => setFilters({ ...filters, utmMedium: e.target.value })}
                placeholder="utm_medium"
              />
            </div>
            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="campaign">Campaign</Label>
              <Input
                value={filters.utmCampaign}
                id="campaign"
                onChange={(e) => setFilters({ ...filters, utmCampaign: e.target.value })}
                placeholder="utm_campaign"
              />
            </div>

            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="content">Content</Label>
              <Input
                value={filters.utmContent}
                id="content"
                onChange={(e) => setFilters({ ...filters, utmContent: e.target.value })}
                placeholder="utm_content"
              />
            </div>

            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="term">Term</Label>
              <Input
                value={filters.utmTerm}
                id="term"
                onChange={(e) => setFilters({ ...filters, utmTerm: e.target.value })}
                placeholder="utm_term"
              />
            </div>
          </Card>
        </div>
      </SheetContent>
    </Sheet>
  )
}
