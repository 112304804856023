import { Button, TablePagination } from '@hub-la/shadcn'
import { RefreshCw } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EventDelivery } from '../../domain/dtos/event-delivery'
import { IntegrationEvent } from '../../domain/dtos/integration-event'
import { useGetHistories } from '../hooks/use-get-histories'
import { useRetryEventHistory } from '../hooks/use-retry-event-history'
import { ConfirmRetryEventHistoryModal } from './confirm-retry-event-history-modal'
import { HistoryDetailDrawer } from './history-detail-drawer'
import { TableHistory } from './table-history/table-history'

const history: EventDelivery = {
  id: '',
  integrationId: '',
  ruleId: '',
  event: IntegrationEvent.NEW_USER,
  retryCount: 0,
  updatedAt: new Date().toISOString(),
  createdAt: new Date().toISOString(),
}

export const IntegrationHistoryTab = ({ integrationId }) => {
  const { t } = useTranslation()
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(50)
  const [openedHistory, setOpenedHistory] = useState<{ isOpen: boolean; history: EventDelivery }>({
    isOpen: false,
    history,
  })
  const [confirmRetryEvent, setConfirmRetryEvent] = useState<{ isOpen: boolean; history: EventDelivery }>({
    isOpen: false,
    history,
  })

  const {
    data: histories = { items: [], total: 0, page, pageSize, lastPage: 1 },
    isFetching: isFetchingItems,
    isLoading: isLoadingItems,
    refetch,
  } = useGetHistories({ integrationId, page, pageSize })

  const { mutateAsync: retryEventHistory, isLoading: isLoadingRetry } = useRetryEventHistory()

  const handleOnRetryEventHistory = (history: EventDelivery) => async () => {
    await retryEventHistory({
      integrationId,
      historyId: history.id,
    })

    setConfirmRetryEvent({
      isOpen: false,
      history,
    })

    refetch()
  }

  const isFetching = isFetchingItems || isLoadingItems

  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center justify-between">
        <p className="text-sm text-gray-500">{histories.total} evento(s)</p>
        <Button variant="outline" size="sm" onClick={() => refetch()} className="flex items-center gap-2">
          <RefreshCw className="h-4 w-4" />

          {t('buttons.syncItems')}
        </Button>
      </div>

      <div className="flex flex-col gap-4">
        <TableHistory
          items={histories.items}
          isFetching={isFetching}
          onHistoryDetail={setOpenedHistory}
          onRetryEventHistory={setConfirmRetryEvent}
        />

        <TablePagination
          page={page}
          setPage={(newPage) => {
            setPage(newPage)
            refetch()
          }}
          lastPage={histories.lastPage}
          pageSize={pageSize}
          setPageSize={(newPageSize) => {
            setPageSize(newPageSize)
            refetch()
          }}
        />
      </div>

      <HistoryDetailDrawer
        isOpen={openedHistory.isOpen}
        history={openedHistory.history}
        onClose={() =>
          setOpenedHistory({
            isOpen: false,
            history,
          })
        }
      />

      <ConfirmRetryEventHistoryModal
        isOpen={confirmRetryEvent.isOpen}
        isLoading={isLoadingRetry}
        onClose={() =>
          setConfirmRetryEvent({
            isOpen: false,
            history,
          })
        }
        onConfirm={handleOnRetryEventHistory(confirmRetryEvent.history)}
      />
    </div>
  )
}
