import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PlanType } from '../../../domain/enums/plan-type'
import { ReceiverRole } from '../../../domain/enums/receiver-role'
import { SubscriptionStatus } from '../../../domain/enums/subscription-status'
import { SubscriptionStatusStats } from '../../components/subscription-status-stats'
import { AddDailyCreditsModal } from './add-daily-credits-modal'
import { DeactivateSubscriptionModal } from './deactivate-subscription-modal'

import { Button } from '@hub-la/shadcn'
import { Calendar, ChevronLeft, X } from 'lucide-react'
import { useHistory } from 'react-router-dom'
import { CopyClipboardTag } from '../../components/copy-clipboard-tag'

type Props = {
  id: string
  name: string
  onClose: () => void
  onChange: () => void
  planType: PlanType
  status: SubscriptionStatus
  credits: number
  role: ReceiverRole
}

export const Header: React.FC<Props> = (props) => {
  const { id, name, planType, status, onClose, onChange, credits, role } = props
  const [isDeactivateSubscriptionOpen, setIsDeactivateSubscriptionOpen] = useState<boolean>(false)
  const [isAddDailyCreditsOpen, setIsAddDailyCreditsOpen] = useState<boolean>(false)
  const canDeactivate =
    ![SubscriptionStatus.INACTIVE, SubscriptionStatus.CANCELED, SubscriptionStatus.INCOMPLETE].includes(status) &&
    role === ReceiverRole.ROLE_SELLER
  const canAddDailyCredits =
    ![SubscriptionStatus.INACTIVE, SubscriptionStatus.INCOMPLETE].includes(status) && role === ReceiverRole.ROLE_SELLER

  const { t } = useTranslation()
  const history = useHistory()

  return (
    <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center space-y-4 lg:space-y-0">
      <div className="space-y-2">
        <Button className=" h-4 px-0" size="sm" variant="link" onClick={() => history.goBack()}>
          <ChevronLeft className=" w-4 h-4 mr-2" />
          Voltar
        </Button>
        <div className="flex items-center space-x-2">
          <h1 className="text-2xl font-semibold">{`${name} - ${t(`planType.${planType}`)}`}</h1>
          <SubscriptionStatusStats status={status} />
        </div>
        <div>
          <CopyClipboardTag
            value={id}
            successMessage={t('copyClipboard.idCopied')}
            tooltip={t('copyClipboard.idTooltip')}
          />
        </div>
      </div>
      <div className="flex flex-col lg:w-auto w-full lg:flex-row space-y-4 lg:space-y-0 lg:space-x-2">
        {canAddDailyCredits && (
          <Button className="lg:w-auto w-full" variant="outline" onClick={() => setIsAddDailyCreditsOpen(true)}>
            <Calendar className="mr-2 h-4 w-4" />
            Estender vencimento
          </Button>
        )}
        {canDeactivate && (
          <Button className="lg:w-auto w-full" variant="outline" onClick={() => setIsDeactivateSubscriptionOpen(true)}>
            <X className="mr-2 h-4 w-4" />
            Cancelar assinatura
          </Button>
        )}
      </div>

      <DeactivateSubscriptionModal
        id={id}
        open={isDeactivateSubscriptionOpen}
        onClose={() => setIsDeactivateSubscriptionOpen(false)}
        onSuccess={onChange}
      />
      <AddDailyCreditsModal
        id={id}
        open={isAddDailyCreditsOpen}
        onClose={() => setIsAddDailyCreditsOpen(false)}
        onSuccess={onChange}
        planType={planType}
        remainingCredits={credits}
      />
    </div>
  )
}
