import { useTranslation } from 'react-i18next'

export const EmptyState = () => {
  const { t } = useTranslation()

  return (
    <div className="text-center py-8">
      <h3 className="text-lg font-semibold">{t('empty.title')}</h3>

      <p className="text-sm text-gray-500">{t('empty.description')}</p>
    </div>
  )
}
