import { Button, useIsMobile } from '@hub-la/shadcn'
import { LucideEye, Undo } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRoleplay } from '../hooks/use-roleplay'

export const RoleplayModeAlert: React.FC = () => {
  const { t } = useTranslation()
  const { signOut: signOutRoleplay, isRoleplay, signOutLoading } = useRoleplay()
  const isMobile = useIsMobile()

  if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
    return null
  }

  const onClickHandleSelectAccount = async (): Promise<void> => {
    if (isRoleplay) {
      await signOutRoleplay()
    }
    window.location.href = '/roleplay'
  }

  if (!isRoleplay) {
    return null
  }

  return (
    <div className="w-full flex flex-row px-6 bg-zinc-700 border-b fixed items-center justify-between h-12 py-2 z-10001">
      <div className="flex items-center space-x-2">
        <LucideEye className="text-secondary" size={16} />
        {isMobile ? (
          <span className="text-secondary text-sm">Colaborador</span>
        ) : (
          <span className="text-secondary text-sm">{t('roleplayModeAlert.title')}</span>
        )}
      </div>
      <Button
        size="sm"
        variant="ghost"
        onClick={onClickHandleSelectAccount}
        loading={signOutLoading}
        className="flex items-center space-x-1 text-secondary"
      >
        <Undo size={16} />
        <span>{t('roleplayModeAlert.button')}</span>
      </Button>
    </div>
  )
}
