import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Input,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Textarea,
  toast,
} from '@hub-la/shadcn'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { ArrowLeft, CalendarIcon, Loader2, MapPinIcon, PlusIcon } from 'lucide-react'
import { useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { UF } from '../../../domain/constants/brazilian-states'
import { Countries } from '../../../domain/constants/countries'
import { CountryCode } from '../../../domain/constants/country-code'
import { ProductCategory } from '../../../domain/enums/product-category'
import { createEventProductMapper } from '../../../domain/factories/create-event-product-mapper'
import {
  createEventProductSchema,
  CreateEventProductSchemaType,
} from '../../../domain/factories/create-event-product-schema'
import { useCreateProduct } from '../../hooks/use-create-product'
import { useGetAddress } from '../../hooks/use-get-address'
import { ResponsiveDrawer } from '../responsive-drawer'
import { DateWithTimePicker } from './date-with-time-picker'

export const CreateEventProduct = () => {
  const [open, setOpen] = useState<boolean>(false)

  const history = useHistory()
  const { t } = useTranslation()
  const { mutateAsync, isLoading } = useCreateProduct({
    onSuccess: (id) => {
      setOpen(false)
      history.push(`/edit/${id}/tickets`)
    },
    onError: (error) => {
      toast({
        title: 'Erro',
        description: t(error.message),
        variant: 'destructive',
      })
    },
  })

  const form = useForm<CreateEventProductSchemaType>({
    defaultValues: {
      name: '',
      category: undefined,
      description: '',
      eventData: {
        startDate: undefined,
        endDate: undefined,
        isLocationNotDefined: false,
        location: {
          street: '',
          number: '',
          complement: '',
          neighborhood: '',
          city: '',
          state: '',
          country: '',
          zipCode: '',
        },
      },
    },
    mode: 'onChange',
    resolver: yupResolver(createEventProductSchema),
  })

  const countryValue = form.watch('eventData.location.country')
  const cepValue = form.watch('eventData.location.zipCode')
  const isLocalNotDefined = form.watch('eventData.isLocationNotDefined')
  const isBrazil = countryValue === CountryCode.BR

  // update address fields when cep is filled
  useGetAddress(countryValue ?? '', cepValue, (data) => {
    form.setValue('eventData.location.street', data.street ?? '')
    form.clearErrors('eventData.location.street')
    form.setValue('eventData.location.city', data.city ?? '')
    form.clearErrors('eventData.location.city')
    form.setValue('eventData.location.neighborhood', data.neighborhood ?? '')
    form.clearErrors('eventData.location.neighborhood')
  })

  const onSubmit = (formValues: CreateEventProductSchemaType) => {
    mutateAsync(createEventProductMapper(formValues))
  }

  const onClose = () => {
    setOpen(false)
    form.reset()
  }

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        <PlusIcon className="mr-2 h-4 w-4" /> Novo evento
      </Button>

      <ResponsiveDrawer open={open} onClose={onClose}>
        <div className="w-full md:w-[450px] p-6 space-y-6">
          <div className="flex items-center gap-3 mb-6">
            <ArrowLeft className="text-[#161F00] cursor-pointer hidden md:block" onClick={onClose} />

            <h4 className="text-2xl font-bold">Novo produto</h4>
          </div>

          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
              <div className="space-y-4">
                <Controller
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <div>
                      <Label htmlFor="name">Nome do evento</Label>

                      <Input {...field} id="name" maxLength={70} className={fieldState.error ? 'border-red-500' : ''} />
                      {fieldState.error && (
                        <p className="text-red-500 text-sm mt-1">{t(`fieldErrors.${fieldState.error.type}`)}</p>
                      )}
                    </div>
                  )}
                  name="name"
                />

                <Controller
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <div>
                      <Label htmlFor="category">Categoria</Label>

                      <Select onValueChange={field.onChange} defaultValue={field.value}>
                        <SelectTrigger className={fieldState.error ? 'border-red-500' : ''}>
                          <SelectValue placeholder="Selecione uma categoria" />
                        </SelectTrigger>

                        <SelectContent className="z-[61]">
                          {Object.values(ProductCategory).map((category) => (
                            <SelectItem key={category} value={category}>
                              {t(`products.category.${category}`)}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>

                      {fieldState.error && (
                        <p className="text-red-500 text-sm mt-1">{t(`fieldErrors.${fieldState.error.type}`)}</p>
                      )}
                    </div>
                  )}
                  name="category"
                />

                <Controller
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <div>
                      <Label htmlFor="description">Descrição</Label>

                      <Textarea
                        {...field}
                        id="description"
                        maxLength={350}
                        className={fieldState.error ? 'border-red-500' : ''}
                      />
                      {fieldState.error && (
                        <p className="text-red-500 text-sm mt-1">{t(`fieldErrors.${fieldState.error.type}`)}</p>
                      )}
                    </div>
                  )}
                  name="description"
                />

                <Card>
                  <CardContent className="space-y-4">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <div className="space-y-3">
                        <div className="flex items-center gap-2">
                          <CalendarIcon className="text-gray-500" />
                          <span>Início</span>
                        </div>

                        <DateWithTimePicker name="eventData.startDate" />
                      </div>

                      <div className="space-y-3">
                        <div className="flex items-center gap-2">
                          <CalendarIcon className="text-gray-500" />
                          <span>Fim</span>
                        </div>

                        <DateWithTimePicker name="eventData.endDate" />
                      </div>
                    </LocalizationProvider>
                  </CardContent>
                </Card>

                <Card>
                  <CardContent className="space-y-4">
                    <div className="flex items-center gap-2">
                      <MapPinIcon />
                      <span>Local</span>
                    </div>

                    <Controller
                      control={form.control}
                      render={({ field }) => (
                        <div className="flex items-center space-x-2">
                          <Checkbox checked={field.value} onCheckedChange={field.onChange} />

                          <Label htmlFor="local-not-defined">Local ainda não definido</Label>
                        </div>
                      )}
                      name="eventData.isLocationNotDefined"
                    />

                    {!isLocalNotDefined && (
                      <>
                        <Controller
                          control={form.control}
                          name="eventData.location.country"
                          render={({ field, fieldState }) => (
                            <div>
                              <Label htmlFor="country">País</Label>
                              <Select onValueChange={field.onChange} defaultValue={field.value}>
                                <SelectTrigger id="country" className={fieldState.error ? 'border-red-500' : ''}>
                                  <SelectValue placeholder="Selecione um país" />
                                </SelectTrigger>

                                <SelectContent>
                                  {Object.keys(Countries).map((countryCode) => (
                                    <SelectItem key={countryCode} value={countryCode}>
                                      {Countries[countryCode].name}
                                    </SelectItem>
                                  ))}
                                </SelectContent>
                              </Select>
                              {fieldState.error && (
                                <p className="text-red-500 text-sm mt-1">{t(`fieldErrors.${fieldState.error.type}`)}</p>
                              )}
                            </div>
                          )}
                        />

                        <div className="flex gap-2">
                          <Controller
                            control={form.control}
                            name="eventData.location.state"
                            render={({ field, fieldState }) => (
                              <div className="flex-1">
                                <Label htmlFor="state">Estado</Label>
                                {isBrazil ? (
                                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                                    <SelectTrigger id="state" className={fieldState.error ? 'border-red-500' : ''}>
                                      <SelectValue placeholder="Selecione um estado" />
                                    </SelectTrigger>

                                    <SelectContent>
                                      {UF.map((uf) => (
                                        <SelectItem key={uf.name} value={uf.name}>
                                          {uf.name}
                                        </SelectItem>
                                      ))}
                                    </SelectContent>
                                  </Select>
                                ) : (
                                  <Input id="state" {...field} className={fieldState.error ? 'border-red-500' : ''} />
                                )}
                                {fieldState.error && (
                                  <p className="text-red-500 text-sm mt-1">
                                    {t(`fieldErrors.${fieldState.error.type}`)}
                                  </p>
                                )}
                              </div>
                            )}
                          />

                          <Controller
                            control={form.control}
                            name="eventData.location.city"
                            render={({ field, fieldState }) => (
                              <div className="flex-1">
                                <Label htmlFor="city">Cidade</Label>

                                <Input id="city" {...field} className={fieldState.error ? 'border-red-500' : ''} />

                                {fieldState.error && (
                                  <p className="text-red-500 text-sm mt-1">
                                    {t(`fieldErrors.${fieldState.error.type}`)}
                                  </p>
                                )}
                              </div>
                            )}
                          />
                        </div>

                        <Controller
                          control={form.control}
                          name="eventData.location.zipCode"
                          render={({ field, fieldState }) => (
                            <div>
                              <Label htmlFor="zipCode">CEP</Label>

                              <Input
                                id="zipCode"
                                {...field}
                                className={fieldState.error ? 'border-red-500' : ''}
                                maxLength={9}
                                onChange={(e) => {
                                  const value = e.target.value.replace(/\D/g, '')
                                  const formattedValue = value.replace(/(\d{5})(\d{3})/, '$1-$2')
                                  field.onChange(formattedValue)
                                }}
                              />

                              {fieldState.error && (
                                <p className="text-red-500 text-sm mt-1">{t(`fieldErrors.${fieldState.error.type}`)}</p>
                              )}
                            </div>
                          )}
                        />

                        <Controller
                          control={form.control}
                          name="eventData.location.street"
                          render={({ field, fieldState }) => (
                            <div>
                              <Label htmlFor="street">Rua</Label>

                              <Input id="street" {...field} className={fieldState.error ? 'border-red-500' : ''} />

                              {fieldState.error && (
                                <p className="text-red-500 text-sm mt-1">{t(`fieldErrors.${fieldState.error.type}`)}</p>
                              )}
                            </div>
                          )}
                        />

                        <Controller
                          control={form.control}
                          name="eventData.location.number"
                          render={({ field, fieldState }) => (
                            <div>
                              <Label htmlFor="number">Número</Label>

                              <Input id="number" {...field} className={fieldState.error ? 'border-red-500' : ''} />

                              {fieldState.error && (
                                <p className="text-red-500 text-sm mt-1">{t(`fieldErrors.${fieldState.error.type}`)}</p>
                              )}
                            </div>
                          )}
                        />

                        <Controller
                          control={form.control}
                          name="eventData.location.complement"
                          render={({ field, fieldState }) => (
                            <div>
                              <Label htmlFor="complement">Complemento</Label>

                              <Input id="complement" {...field} className={fieldState.error ? 'border-red-500' : ''} />

                              {fieldState.error && (
                                <p className="text-red-500 text-sm mt-1">{t(`fieldErrors.${fieldState.error.type}`)}</p>
                              )}
                            </div>
                          )}
                        />

                        <Controller
                          control={form.control}
                          name="eventData.location.neighborhood"
                          render={({ field, fieldState }) => (
                            <div>
                              <Label htmlFor="neighborhood">Bairro</Label>

                              <Input
                                id="neighborhood"
                                {...field}
                                className={fieldState.error ? 'border-red-500' : ''}
                              />

                              {fieldState.error && (
                                <p className="text-red-500 text-sm mt-1">{t(`fieldErrors.${fieldState.error.type}`)}</p>
                              )}
                            </div>
                          )}
                        />
                      </>
                    )}
                  </CardContent>
                </Card>
              </div>

              <div className="mt-6 space-y-3">
                <Button className="w-full" disabled={isLoading} type="submit">
                  {isLoading ? (
                    <>
                      <span className="mr-2">Criando...</span>
                      <Loader2 className="h-4 w-4 animate-spin" />
                    </>
                  ) : (
                    'Criar produto'
                  )}
                </Button>

                <Button variant="outline" className="w-full" onClick={onClose} disabled={isLoading}>
                  Cancelar
                </Button>
              </div>
            </form>
          </FormProvider>
        </div>
      </ResponsiveDrawer>
    </>
  )
}
