import { Circle, CircleDashed, HourglassIcon, MoonIcon, XCircle } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { ProductStatus } from '../../domain/enums/product-status'

type ProductStatusTagProps = {
  status: ProductStatus
}

const getStyles = (color: string) => {
  return { size: 16, className: `mr-1 text-${color}-700`, fill: color, style: { fillOpacity: 0.1 } }
}

export const ProductStatusTag = ({ status }: ProductStatusTagProps) => {
  const { t } = useTranslation()

  const getIcon = () => {
    switch (status) {
      case ProductStatus.SELLING:
      case ProductStatus.PUBLISHED:
        return <Circle {...getStyles('green')} />
      case ProductStatus.WAITLISTED:
        return <HourglassIcon {...getStyles('gray')} />
      case ProductStatus.DRAFT:
        return <CircleDashed {...getStyles('gray')} />
      case ProductStatus.NOT_ENABLED:
      case ProductStatus.NOT_SELLING:
        return <MoonIcon {...getStyles('gray')} />
      case ProductStatus.UNAVAILABLE:
        return <XCircle {...getStyles('gray')} />
      default:
        return <Circle {...getStyles('gray')} />
    }
  }

  return (
    <div className="flex items-center gap-1">
      {getIcon()}
      <span>{t(`products.status.${status ?? ProductStatus.SELLING}`)}</span>
    </div>
  )
}
