import { CopyClipboard } from '@hub-la/fe-copy-clipboard'
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  Input,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  toast,
} from '@hub-la/shadcn'
import { Copy } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Offer } from '../../domain/dtos/offer'
import { LifetimeInterval } from '../../domain/enums/interval'
import { GetAvailableOptions } from '../../usecases/get-available-options'
import { GetCouponLinks } from '../../usecases/get-coupon-links'
import { useGetCoupon } from '../hooks/use-get-coupon'

type ShareCouponModalProps = {
  id: string
  couponCode: string
  open: boolean
  onClose: () => void
  offers?: Offer[]
}

export const ShareCouponModal = ({ id, open, onClose, couponCode, offers = [] }: ShareCouponModalProps) => {
  const [offer, setOffer] = useState<Offer>()

  const { t } = useTranslation()

  /** @description The `(open ? id : '')` will prevent fetching the coupon if the modal is not open */
  const { data: coupon, isFetching } = useGetCoupon(open ? id : '')

  const options = new GetAvailableOptions().execute(offers, coupon?.appliableTo ?? [])
  const items = new GetCouponLinks().execute(couponCode, coupon?.appliableTo, offer) ?? []
  const shouldRenderTable = options && !isFetching && items.length > 0
  const isAvailableForAll = items.some((it) => it.interval === 'ALL' || it.interval === LifetimeInterval.LIFETIME)

  const handleCopy = (text: string) => {
    new CopyClipboard().execute(text)
    toast({
      title: t('share.copied'),
    })
  }

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Compartilhar link de desconto</DialogTitle>
          <DialogDescription>{t('share.subtitle')}</DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid gap-2">
            <Label htmlFor="product">Selecione um produto</Label>

            <Select
              value={offer?.id}
              onValueChange={(value) => setOffer(options.find((o) => o.id === value))}
              disabled={isFetching}
            >
              <SelectTrigger id="product">
                <SelectValue placeholder="Selecione um produto" />
              </SelectTrigger>

              <SelectContent className="z-10002 w-80 h-60">
                {options.map((option) => (
                  <SelectItem key={option.id} value={option.id}>
                    {option.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          {shouldRenderTable && (
            <div className="border rounded-md">
              <div className="flex items-center p-4 border-b">
                {!isAvailableForAll && <span className="w-40">Plano</span>}

                <span>Link do checkout com cupom aplicado</span>
              </div>

              {items.map((item, index) => (
                <div key={index} className="flex items-center p-4 border-b last:border-b-0">
                  {!isAvailableForAll && <span className="w-40">{t(`planType.${item.interval}`)}</span>}

                  <div className="flex-grow">
                    <div className="flex">
                      <Input value={item.link} readOnly className="flex-grow" />

                      <Button variant="ghost" size="icon" onClick={() => handleCopy(item.link)}>
                        <Copy className="h-4 w-4" />
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}
