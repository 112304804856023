import { Button, Dialog, DialogContent, DialogHeader, DialogTitle } from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'
import { SetModulesHighlightVisualized } from '../../../usecases/set-modules-highlight-visualized'
import moduleHighlightMockup from '../../assets/images/module-highlight-mockup.png'
import { usePutMembersArea } from '../../hooks/put-members-area'

type ModalEnableModulesHighlightProps = {
  productId: string
  open: boolean
  onClose: () => void
}

const ModalEnableModulesHighlight: React.FC<ModalEnableModulesHighlightProps> = ({ productId, open, onClose }) => {
  const { t } = useTranslation()
  const { mutateAsync } = usePutMembersArea()

  const handleEnableModulesHighlight = () => {
    mutateAsync({
      productId,
      modulesHighlight: true,
    })
    new SetModulesHighlightVisualized().execute(productId)

    onClose()
  }

  const handleMaybeLater = () => {
    new SetModulesHighlightVisualized().execute(productId)

    onClose()
  }

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-lg">
        <DialogHeader>
          <DialogTitle className="text-2xl mb-3">{t('enableModulesHighlightModal.title')}</DialogTitle>

          <p className="text-sm text-gray-500 mb-6">{t('enableModulesHighlightModal.description')}</p>
        </DialogHeader>

        <div className="mt-6 bg-gray-100 rounded-md overflow-hidden relative">
          <img
            className="w-full rounded-md sm:rounded-none sm:pl-15 sm:pt-8"
            src={moduleHighlightMockup}
            alt={t('enableModulesHighlightModal.mockupAlt')}
          />
        </div>

        <div className="flex justify-end space-x-3 mt-8">
          <Button variant="outline" onClick={handleMaybeLater}>
            {t('enableModulesHighlightModal.maybeLater')}
          </Button>

          <Button onClick={handleEnableModulesHighlight}>{t('enableModulesHighlightModal.enable')}</Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export { ModalEnableModulesHighlight }
