import { Button, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@hub-la/shadcn'
import { CheckSquare, Copy, MoreHorizontal, Square, Trash2 } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Offer } from '../../../domain/dtos/offer'
import { CouponStatus } from '../../../domain/enums/coupon-status'
import { DeleteCouponModal } from '../../components/delete-coupon-modal'
import { ShareCouponModal } from '../../components/share-coupon-modal'
import { useChangeCouponStatus } from '../../hooks/use-change-coupon-status'

type EditMenuProps = {
  id: string
  code: string
  state: CouponStatus
  offers: Offer[]
}

export const EditMenu = ({ id, code, state, offers }: EditMenuProps) => {
  const [isDeleteCouponOpen, setIsDeleteCouponOpen] = useState<boolean>(false)
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false)

  const { mutateAsync: changeCouponStatus } = useChangeCouponStatus()
  const { t } = useTranslation()

  const canChangeStatus = [CouponStatus.ACTIVE, CouponStatus.INACTIVE].includes(state)
  const isActive = state === CouponStatus.ACTIVE

  const onShareClick = () => {
    setIsShareOpen(true)
  }

  const onChangeStatusClick = () => {
    changeCouponStatus({
      id,
      currentStatus: state,
    })
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon">
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent align="end">
          {isActive && (
            <DropdownMenuItem onClick={onShareClick}>
              <Copy className="mr-2 h-4 w-4" />
              <span>{t('actions.share')}</span>
            </DropdownMenuItem>
          )}

          {canChangeStatus && (
            <DropdownMenuItem onClick={onChangeStatusClick}>
              {isActive ? <Square className="mr-2 h-4 w-4" /> : <CheckSquare className="mr-2 h-4 w-4" />}
              <span>{isActive ? t('actions.deactivate') : t('actions.activate')}</span>
            </DropdownMenuItem>
          )}

          <DropdownMenuItem onClick={() => setIsDeleteCouponOpen(true)}>
            <Trash2 className="mr-2 h-4 w-4" />
            <span>{t('actions.delete')}</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <ShareCouponModal
        id={id}
        open={isShareOpen}
        couponCode={code}
        offers={offers}
        onClose={() => setIsShareOpen(false)}
      />

      <DeleteCouponModal
        couponId={id}
        couponCode={code}
        open={isDeleteCouponOpen}
        onClose={() => setIsDeleteCouponOpen(false)}
      />
    </>
  )
}
