import { useFormik } from 'formik'
import React from 'react'
import { useAddDailyCreditsBatch } from '../../hooks/use-add-daily-credits-batch'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { AddDailyCreditsInput } from '../../../domain/dtos/add-daily-credits-input'

import {
  Badge,
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Input,
  Label,
  useToast,
} from '@hub-la/shadcn'

type Props = {
  subscriptionIds: string[]
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

export type Values = Omit<AddDailyCreditsInput, 'id'>

export const AddDailyCreditsBatchModal: React.FC<Props> = (props) => {
  const { subscriptionIds, open, onClose, onSuccess } = props
  const { mutateAsync: extendSubscription, isLoading } = useAddDailyCreditsBatch()
  const { t } = useTranslation()
  const { toast } = useToast()

  const formik = useFormik<Values>({
    validationSchema: Yup.object().shape({
      credits: Yup.number().required('errors.required'),
    }),
    validateOnMount: false,
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: {
      credits: 0,
    },
    onSubmit: async (values: Values) => {
      await extendSubscription(
        { subscriptionIds, credits: values.credits },
        {
          onSuccess: () => {
            onSuccess()
            onClose()
            toast({
              description: t('success'),
            })
          },
          onError: (error) => {
            toast({
              description: t((error as Error).message ?? ''),
              variant: 'destructive',
            })
          },
        },
      )
    },
  })

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Estender vencimento</DialogTitle>
          <DialogDescription>
            Ao estender a data de vencimento de assinaturas ativas, os dias adicionais serão incorporados à data
            original de vencimento. Para assinaturas canceladas, a nova data de vencimento será atualizada, mantendo o
            status como cancelado. As assinaturas inativas não serão afetadas por esta ação.
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-4">
          <div>
            {subscriptionIds.length > 1 && <Badge>{`${subscriptionIds.length} assinantes selecionados`}</Badge>}
          </div>
          <div className="grid gap-2">
            <Label htmlFor="credits">Dia(s)</Label>
            <Input
              id="credits"
              type="number"
              value={formik.values.credits}
              onChange={(e) => formik.setFieldValue('credits', parseInt(e.target.value, 10))}
              onBlur={formik.handleBlur}
            />
            {formik.touched.credits && formik.errors.credits && (
              <p className="text-sm text-destructive">{t(formik.errors.credits)}</p>
            )}
          </div>
        </div>
        <DialogFooter className="gap-2">
          <Button variant="outline" onClick={onClose}>
            Cancelar
          </Button>
          <Button loading={isLoading} onClick={() => formik.handleSubmit()} disabled={isLoading}>
            Estender vencimento
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
