import { PermissionResource, useRoleplay } from '@hub-la/fe-roleplay'
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Label,
} from '@hub-la/shadcn'
import { Download, FileDown, Loader2 } from 'lucide-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDownloadCsv } from '../../../hooks/use-download-csv'

type Props = {
  filters: {
    startDate: any
    endDate: any
    offerIds: string[]
  } // Update this type to match your filters structure
  offers: any[] // Update this type to match your offers structure
}
export const ConfirmExportModal: React.FC<Props> = ({ filters, offers }) => {
  const { t } = useTranslation()

  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const { hasPermission } = useRoleplay()

  const { mutateAsync: exportCsv, isLoading: loading } = useDownloadCsv({
    offerIds: filters.offerIds,
    offers: offers,
    startDate: filters.startDate,
    endDate: filters.endDate,
  })

  async function handleSubmit() {
    await exportCsv()
      .then(() => {
        setShowConfirmModal(false)
      })
      .catch(console.error)
  }

  return (
    <Dialog open={showConfirmModal} onOpenChange={() => setShowConfirmModal((prevState) => !prevState)}>
      <DialogTrigger asChild>
        {hasPermission(PermissionResource.HOME_ABANDONED_CHECKOUT) && (
          <Button variant="outline" className="w-full">
            <FileDown className="mr-2 h-4 w-4" />
            Exportar
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{t('abandonedCheckout.modal.title')}</DialogTitle>
        </DialogHeader>
        <div className="grid my-4">
          <div className="grid gap-2">
            {filters.offerIds?.length > 0 && (
              <div className="grid grid-cols-3 items-center gap-4">
                <Label>Produtos</Label>
                <div className="col-span-2 h-9 p-2 border rounded-lg items-center text-sm bg-gray-50 text-muted-foreground">
                  {filters.offerIds.map((id) => offers.find((o) => o.id === id)?.name).join(', ')}
                </div>
              </div>
            )}
          </div>
          {filters.startDate && filters.endDate && (
            <div className="grid grid-cols-3 items-center gap-4">
              <Label>Data de criação</Label>
              <div className="col-span-2 h-9 p-2 border rounded-lg items-center text-sm bg-gray-50 text-muted-foreground">
                {new Date(filters.startDate).toLocaleDateString()} - {new Date(filters.endDate).toLocaleDateString()}
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-end space-x-2">
          <DialogClose>
            <Button variant="outline" disabled={loading}>
              {t('abandonedCheckout.modal.cancel')}
            </Button>
          </DialogClose>
          <Button onClick={handleSubmit} className="flex gap-2" disabled={loading}>
            {loading ? <Loader2 className="h-4 w-4 animate-spin" /> : <Download className="h-4 w-4" />}
            {t('abandonedCheckout.modal.confirm')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
