import { Avatar, AvatarFallback, AvatarImage, Badge, Card, Label, Separator } from '@hub-la/shadcn'
import { ChevronRight, RefreshCcw, Shapes } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { SubscriptionStatusStats } from './subscription-status-stats'

export const InvoiceRelations = ({ products, subscription, smartInstallment, offers }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [hoveredItem, setHoveredItem] = useState(null)

  const renderCard = (item, type) => (
    <Card
      key={item.id}
      className={`lg:col-span-1 flex justify-between border-none shadow-none items-center p-2 cursor-pointer
        hover:bg-zinc-100 bg-transparent transition-all duration-200`}
      onClick={() =>
        type === 'product'
          ? history.push(`/edit/${item.id}`)
          : type === 'subscription'
          ? history.push(`/dashboard/subscriptions/${item.id}`)
          : null
      }
      onMouseEnter={() => setHoveredItem(item.id)}
      onMouseLeave={() => setHoveredItem(null)}
    >
      <div className="flex items-center space-x-4">
        <Avatar className="h-10 w-10">
          <AvatarImage className="object-cover" src={item.picture} alt={item.name} />
          <AvatarFallback>
            {type === 'subscription' ? (
              <RefreshCcw className="text-zinc-800 w-5 h-5" />
            ) : (
              <Shapes className="text-zinc-800 w-5 h-5" />
            )}
          </AvatarFallback>
        </Avatar>
        <div>
          <Label>{type === 'product' ? 'Produto' : type === 'offer' ? 'Oferta' : 'Assinatura'}</Label>
          <p className="text-sm text-gray-500">{item.name}</p>
          {type === 'subscription' && (
            <div className="flex flex-row items-center gap-2">
              <p className="text-sm text-gray-500">{t(`planType.${item.plan}`)}</p>
              <Badge className="bg-white" variant="outline">
                <SubscriptionStatusStats status={item.status} />
              </Badge>
            </div>
          )}
          <p className="text-sm text-gray-500">{item.id}</p>
        </div>
      </div>
      {hoveredItem === item.id && <ChevronRight className="h-4 w-4 ml-2" />}
    </Card>
  )

  return (
    <div className="space-y-4">
      <h2 className="text-lg font-semibold">Conexões</h2>
      <Separator />
      <div className="grid grid-cols-2">
        {products.map((product) => renderCard(product, 'product'))}
        {/* {offers.map((offer) => renderCard(offer, 'offer'))} */}
        {subscription && renderCard(subscription, 'subscription')}
      </div>
    </div>
  )
}
