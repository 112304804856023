import { Button, Calendar, Popover, PopoverContent, PopoverTrigger } from '@hub-la/shadcn'
import { CalendarIcon } from 'lucide-react'
import moment from 'moment'
import { GetShortcuts } from '../../usecases/get-shortchuts'

export const SelectDateRangeCalendar = ({ filters, setFilters }) => {
  const shortcuts = new GetShortcuts().execute()

  const Placeholder = () => {
    const { startDate, endDate } = filters
    const formattedStartDate = moment(startDate).format('D MMM. YYYY')
    const formattedEndDate = moment(endDate).format('D MMM. YYYY')
    const text = `${formattedStartDate} a ${formattedEndDate}`

    const shortcut = shortcuts.find((it) => it.startDate === startDate && it.endDate === endDate)

    return shortcut ? shortcut.label : text
  }

  const handleShortcutClick = (shortcut) => {
    setFilters({ ...filters, startDate: shortcut.startDate, endDate: shortcut.endDate })
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline">
          <CalendarIcon className="mr-2 h-4 w-4" />
          <span>{Placeholder()}</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <div className="flex grid-cols-3">
          <div className="col-span-2">
            <Calendar
              mode="range"
              selected={{ from: filters.startDate, to: filters.endDate }}
              defaultMonth={filters.startDate}
              numberOfMonths={2}
              onSelect={(selected) => {
                setFilters({ ...filters, startDate: selected?.from, endDate: selected?.to })
              }}
            />
          </div>
          <div className="p-2 col-span-1 flex flex-col bg-gray-50">
            {shortcuts.map((shortcut) => (
              <Button key={shortcut.label} variant="ghost" size="sm" onClick={() => handleShortcutClick(shortcut)}>
                {shortcut.label}
              </Button>
            ))}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}
