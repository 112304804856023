import { Tabs, TabsContent, TabsList, TabsTrigger } from '@hub-la/shadcn'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IntegrationProvider } from '../../../domain/dtos/integration-provider'
import { IntegrationAboutTab } from '../integration-about-tab'

type MetaPixelDetailProps = {
  provider: IntegrationProvider
}

export const MetaPixelDetail = ({ provider }: MetaPixelDetailProps) => {
  const { t } = useTranslation()
  const [tab, setTab] = useState('about')

  return (
    <Tabs value={tab}>
      <TabsList className="h-full max-w-full w-min overflow-x-auto">
        <TabsTrigger value="about">{t('tabs.about')}</TabsTrigger>
      </TabsList>

      <TabsContent value="about">
        <IntegrationAboutTab
          provider={provider}
          loading={false}
          refetch={() => {
            //
          }}
          setTab={setTab}
        />
      </TabsContent>
    </Tabs>
  )
}
