import { useAuth } from '@hub-la/fe-auth'
import { useContainer } from '@hub-la/fe-container'
import { Analytics, AnalyticsEvent } from '@hub-la/fe-core-analytics'
import { Button, useIsMobile } from '@hub-la/shadcn'
import { Send } from 'lucide-react'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { UserVerificationTelegramStep } from '../../../domain/enums/user-verification-telegram-step'
import { makeTrackingEventData } from '../../../domain/mappers/make-tracking-event-data'
import VerificationDoneDesk from '../../assets/verification-done-desk.svg'
import VerificationDoneMobile from '../../assets/verification-done-mobile.svg'
import ConfirmAccount from '../../components/confirm-account'
import { NavigationButton } from '../../components/navigation-button'
import { NotGroupMember } from '../../components/not-group-member'
import { useGetGroupResources } from '../../hooks/use-get-group-resources'
import { useGetTelegramCode } from '../../hooks/use-get-telegram-code'
import { useGetUserVerification } from '../../hooks/use-get-user-verification'
import { useUpdateVerificationTelegramStep } from '../../hooks/use-update-verification-telegram-step'

export const VerificationDonePage = (): JSX.Element => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const history = useHistory()
  const container = useContainer()
  const analytics = container.get(Analytics)

  const { currentUser } = useAuth()
  const { data: user } = useGetUserVerification(currentUser?.id)
  const { data: telegramCode, isFetching: isFetchingTelegramCode } = useGetTelegramCode()

  const updateStepAction = useUpdateVerificationTelegramStep()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const step = user?.steps?.verificationTelegram
  const urlSearch = useLocation().search
  const groupId = new URLSearchParams(urlSearch).get('groupId')
  const groupResources = useGetGroupResources(groupId ?? undefined)

  const trackingParams = {
    groupId: groupId,
    userId: user?.id,
    userEmail: user?.email ?? undefined,
    telegramId: user?.ims?.telegram ?? undefined,
    whatsappId: user?.ims?.whatsapp ?? undefined,
  }

  useEffect(() => {
    if (user && !isFetchingTelegramCode) {
      if (!step && !user.ims?.telegram && !telegramCode) {
        history.push({
          pathname: '/verification-telegram/intro',
          search: urlSearch,
        })
      }

      if (
        (step === UserVerificationTelegramStep.onboarding && !telegramCode) ||
        step === UserVerificationTelegramStep.intro
      ) {
        history.push({
          pathname: '/verification-telegram/start',
          search: urlSearch,
        })
      }

      if (
        (step === UserVerificationTelegramStep.onboarding || step === UserVerificationTelegramStep.pending) &&
        telegramCode
      ) {
        history.push({
          pathname: '/verification-telegram/start',
          search: urlSearch,
        })
      }

      if (step === UserVerificationTelegramStep.done && user.ims?.telegram && telegramCode === null) {
        history.push('/user_groups')
      }
    }
  }, [step, user?.ims?.telegram, user?.telegramCode, isFetchingTelegramCode])

  const dispatchUpdateStep = useCallback((step: UserVerificationTelegramStep, next: string) => {
    updateStepAction.mutateAsync(step).finally(() => {
      window.location.href = next
    })
  }, [])

  const handleClickDone = () => {
    analytics.track(AnalyticsEvent.VERIFICATION_TELEGRAM.DONE.COMPLETED_CLICKED, makeTrackingEventData(trackingParams))
    setIsLoading(true)
    dispatchUpdateStep(UserVerificationTelegramStep.done, '/user_groups')
  }

  const handleClickGoGroup = (groupId?: string, link?: string) => {
    analytics.track(AnalyticsEvent.VERIFICATION_TELEGRAM.DONE.COMPLETED_CLICKED, makeTrackingEventData(trackingParams))
    setIsLoading(true)
    dispatchUpdateStep(UserVerificationTelegramStep.done, groupId ? `${link}` : '/user_groups')
  }

  const handleClickNav = () => {
    analytics.track(AnalyticsEvent.VERIFICATION_TELEGRAM.DONE.NAV_CLOSE_CLICKED, makeTrackingEventData(trackingParams))
    setIsLoading(true)
    dispatchUpdateStep(UserVerificationTelegramStep.done, '/user_groups')
  }

  return (
    <div className="flex flex-col flex-1 h-screen" data-testid="verification-done-container">
      <div className="flex flex-col md:flex-row flex-1">
        <div className={`flex flex-col flex-1 w-full md:w-1/2 p-2 md:p-3 ${isMobile ? 'bg-[#D7FE61]' : 'bg-white'}`}>
          <div className="flex flex-col items-start justify-center">
            <NavigationButton onClick={handleClickNav} isClose />
          </div>
          <div className="flex flex-col flex-1 items-center justify-center">
            <div className="flex flex-col flex-1 space-y-3 justify-center w-full md:w-[400px]">
              {isMobile && (
                <div className="flex items-center justify-center">
                  <img
                    className="h-[200px]"
                    data-testid="img-verification-done-mobile"
                    src={VerificationDoneMobile}
                    alt="verification done mobile"
                  />
                </div>
              )}
              <h1 className={`text-left text-[#161F00] font-bold ${isMobile ? 'text-xl' : 'text-4xl'}`}>
                {`${t('verificationTelegram.done.title')} ${user?.firstName}`}
              </h1>
              <p className={`text-left ${isMobile ? 'text-[#161F00]' : 'text-[#747968]'}`}>
                {t('verificationTelegram.done.subtitle')}
              </p>
              {user && (
                <ConfirmAccount
                  picture={user.picture}
                  name={user.ims?.telegramName ?? user.firstName}
                  username={user.ims?.telegramUsername}
                />
              )}
              {!isMobile && (
                <div className="flex flex-col space-y-2 items-start justify-center">
                  {groupId &&
                    groupResources.data &&
                    groupResources.data.map((resource, index) => (
                      <Button
                        key={index}
                        variant="default"
                        size="lg"
                        className="w-full font-bold text-base text-[#314000] border-[#314000]"
                        loading={isLoading}
                        onClick={() => handleClickGoGroup(groupId, resource.link as string)}
                      >
                        {t('verificationTelegram.done.buttonGoGroup')}
                        <Send className="ml-2 h-4 w-4" />
                      </Button>
                    ))}
                  <Button
                    data-testid="button-completed-desktop"
                    variant="outline"
                    size="lg"
                    className="w-full font-bold text-base text-[#314000] border-[#314000]"
                    loading={isLoading}
                    onClick={handleClickDone}
                  >
                    {t('verificationTelegram.done.buttonCompleted')}
                  </Button>
                </div>
              )}
            </div>
          </div>

          {isMobile && (
            <div className="flex flex-col space-y-2 items-center justify-center">
              {groupId &&
                groupResources.data &&
                groupResources.data.map((resource, index) => (
                  <Button
                    key={index}
                    variant="default"
                    size="lg"
                    className="w-full font-bold text-base text-white bg-[#587300] border-[#314000]"
                    loading={isLoading}
                    onClick={() => handleClickGoGroup(groupId, resource.link as string)}
                  >
                    {t('verificationTelegram.done.buttonGoGroup')}
                    <Send className="ml-2 h-4 w-4" />
                  </Button>
                ))}
              <Button
                data-testid="button-completed-mobile"
                variant="ghost"
                size="lg"
                className="w-full font-bold text-base text-[#314000]"
                loading={isLoading}
                onClick={handleClickDone}
              >
                {t('verificationTelegram.done.buttonCompleted')}
              </Button>
            </div>
          )}
        </div>
        {!isMobile && (
          <div
            data-testid="img-verification-done-desktop"
            className="flex flex-1 w-full md:w-1/2 bg-[#D7FE61] bg-no-repeat bg-center bg-[size:110%_auto]"
            style={{
              backgroundImage: `url(${VerificationDoneDesk})`,
            }}
          >
            &ensp;
          </div>
        )}
      </div>
      {groupId && <NotGroupMember groupId={groupId} />}
    </div>
  )
}

export default VerificationDonePage
