import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Label,
  toast,
} from '@hub-la/shadcn'
import { Download } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDownloadXlsx } from '../hooks/use-download-xlsx'

type Props = {
  filters: any
  offers: any[]
}

export const ConfirmExportModal: React.FC<Props> = ({ filters, offers }) => {
  const { t } = useTranslation()
  const [showConfirmModal, setShowConfirmModal] = React.useState(false)

  const {
    mutateAsync: exportXlsx,
    isLoading: loading,
    reset,
  } = useDownloadXlsx({
    ...filters,
    offers,
  })

  const handleClose = () => {
    reset()
    setShowConfirmModal(false)
  }

  async function handleSubmit() {
    try {
      await exportXlsx()
      handleClose()
      toast({
        description: t('success'),
      })
    } catch (error) {
      toast({
        description: t((error as Error).message ?? ''),
        variant: 'destructive',
      })
    }
  }

  return (
    <Dialog open={showConfirmModal} onOpenChange={() => setShowConfirmModal((prevState) => !prevState)}>
      <DialogTrigger asChild>
        <Button variant="outline" className="w-full">
          <Download className="h-4 w-4 mr-2" />
          {t('exportXlsx.confirm')}
        </Button>
      </DialogTrigger>
      <DialogContent className="w-full md:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{t('exportXlsx.title')}</DialogTitle>
        </DialogHeader>
        <div className="grid my-4">
          <div className="grid gap-2">
            {filters.search && (
              <div className="grid grid-cols-3 items-center gap-4">
                <Label>Busca</Label>
                <div className="col-span-2 h-auto p-2 border rounded-lg items-center text-sm bg-gray-50 text-muted-foreground">
                  {filters.search}
                </div>
              </div>
            )}
            {filters.offerIds?.length > 0 && (
              <div className="grid grid-cols-3 items-center gap-4">
                <Label>Produtos</Label>
                <div className="col-span-2 h-auto p-2 border rounded-lg items-center text-sm bg-gray-50 text-muted-foreground">
                  {filters.offerIds.map((id) => offers.find((o) => o.id === id)?.name).join(', ')}
                </div>
              </div>
            )}
            {filters.startDate && filters.endDate && (
              <div className="grid grid-cols-3 items-center gap-4">
                <Label>Data de criação</Label>
                <div className="col-span-2 h-auto p-2 border rounded-lg items-center text-sm bg-gray-50 text-muted-foreground">
                  {new Date(filters.startDate).toLocaleDateString()} - {new Date(filters.endDate).toLocaleDateString()}
                </div>
              </div>
            )}
            {filters.statuses?.length > 0 && (
              <div className="grid grid-cols-3 items-center gap-4">
                <Label>Status</Label>
                <div className="col-span-2 h-auto p-2 border rounded-lg items-center text-sm bg-gray-50 text-muted-foreground">
                  {filters.statuses.map((status) => t(`status.${status}`)).join(', ')}
                </div>
              </div>
            )}
          </div>
        </div>
        <DialogFooter className="gap-2">
          <DialogClose>
            <Button variant="outline" disabled={loading} className="w-full">
              {t('exportXlsx.cancel')}
            </Button>
          </DialogClose>
          <Button onClick={handleSubmit} loading={loading} className="flex gap-2">
            <Download className="h-4 w-4" />
            {t('exportXlsx.confirm')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
