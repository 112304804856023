/* tslint:disable */
/* eslint-disable */
/**
 * BFF Web Product
 * API reference used to call the backend from the web frontend
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CreateOfferCheckoutSettingsDto
 */
export interface CreateOfferCheckoutSettingsDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'paymentMethods': Array<CreateOfferCheckoutSettingsDtoPaymentMethodsEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'showCouponField': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'showBillingAddress': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'defaultPlan'?: CreateOfferCheckoutSettingsDtoDefaultPlanEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'defaultInstallment'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'timerBgColor'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'timerSeconds'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'logoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'bannerDesktopUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'bannerMobileUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'supportUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'termsUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'youtubeEmbedUrl'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateOfferCheckoutSettingsDtoPaymentMethodsEnum {
    CREDIT_CARD = 'credit_card',
    BANK_SLIP = 'bank_slip',
    PIX = 'pix'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateOfferCheckoutSettingsDtoDefaultPlanEnum {
    MONTHLY = 'monthly',
    QUARTERLY = 'quarterly',
    SEMIANNUALLY = 'semiannually',
    ANNUALLY = 'annually'
}


