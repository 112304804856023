import {
  Badge,
  Button,
  cn,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from '@hub-la/shadcn'
import { useFormik } from 'formik'
import { ArrowLeft, Check, ChevronsUpDown } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { ExternalReference } from '../../domain/dtos/external-reference'
import { IntegrationEventOption } from '../../domain/dtos/integration-event'
import { IntegrationProvider } from '../../domain/dtos/integration-provider'
import { Offer } from '../../domain/dtos/offer'
import { Product } from '../../domain/dtos/product'
import { Rule } from '../../domain/dtos/rule'
import { useCreateRule } from '../hooks/use-create-rule'
import { useEditRule } from '../hooks/use-edit-rule'
import { useGetAllProducts } from '../hooks/use-get-all-products'
import { useGetIntegrationEvents } from '../hooks/use-get-integration-events'
import { useGetLists } from '../hooks/use-get-lists'
import { useGetOffers } from '../hooks/use-get-offers'
import { useGetTags } from '../hooks/use-get-tags'

type Values = {
  name: string
  url: string
  products: Product[]
  offers: Offer[]
  lists: ExternalReference[]
  tags: ExternalReference[]
  events: IntegrationEventOption[]
}

export enum CreateEditRuleType {
  CREATE = 'create',
  EDIT = 'edit',
}

type Props = {
  isOpen: boolean
  integrationId: string
  provider: IntegrationProvider
  type: CreateEditRuleType
  rule: Rule | null
  schema?: string[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validation?: any
  onClose: () => void
  refetch: () => void
}

export const CreateEditRuleModal = (props: Props) => {
  const { integrationId, provider, isOpen, onClose, type, rule, schema, validation, refetch } = props
  const { t } = useTranslation()
  const events = useGetIntegrationEvents(provider)

  const { data: products = [] } = useGetAllProducts(true)
  const { data: offers = [] } = useGetOffers()

  const needName = schema?.find((value) => value === 'name')
  const needUrl = schema?.find((value) => value === 'url')
  const needProducts = schema?.find((value) => value === 'products')
  const needOffers = schema?.find((value) => value === 'offers')
  const needEvents = schema?.find((value) => value === 'events')
  const needLists = schema?.find((value) => value === 'lists')
  const needTags = schema?.find((value) => value === 'tags')

  const { data: lists = [] } = useGetLists({ integrationId }, !!needLists)
  const { data: tags = [] } = useGetTags({ integrationId }, !!needTags)

  const { mutateAsync: createRule, isLoading: isCreating } = useCreateRule()
  const { mutateAsync: editRule, isLoading: isEditing } = useEditRule()

  const { values, isValid, submitForm, setFieldValue, resetForm, handleChange } = useFormik<Values>({
    initialValues: {
      name: rule?.name ?? '',
      url: rule?.url ?? '',
      products: rule?.products ?? [],
      offers: rule?.offers ?? [],
      events: events.filter((event) => rule?.events.includes(event.code)) ?? [],
      lists: rule?.lists ?? [],
      tags: rule?.tags ?? [],
    },
    validationSchema: validation,
    validateOnChange: true,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (params) => {
      if (CreateEditRuleType.CREATE === type) {
        createRule({
          integrationId,
          provider,
          rule: {
            name: params.name,
            url: params.url,
            products: params.products,
            offers: params.offers,
            events: params.events.map((event) => event.code),
            lists: params.lists,
            tags: params.tags,
          },
        }).then(() => {
          resetForm()
          onClose()

          refetch()
        })
      } else {
        editRule({
          integrationId,
          provider,
          rule: {
            id: rule?.id as string,
            name: params.name,
            url: params.url,
            products: params.products,
            offers: params.offers,
            events: params.events.map((event) => event.code),
            lists: params.lists,
            tags: params.tags,
            enabled: rule?.enabled as boolean,
          },
        }).then(() => {
          resetForm()
          onClose()
          refetch()
        })
      }
    },
  })

  const isLoading = isCreating || isEditing

  return (
    <Sheet open={isOpen} onOpenChange={onClose}>
      <SheetContent className="p-6 max-w-lg flex flex-col gap-6">
        <SheetHeader>
          <SheetTitle className="flex items-center gap-3">
            <ArrowLeft className="h-6 w-6 cursor-pointer text-gray-600" onClick={onClose} />

            <span>
              {type === CreateEditRuleType.CREATE
                ? t(`integrations.${provider}.rule.title.create`)
                : t(`integrations.${provider}.rule.title.edit`)}
            </span>
          </SheetTitle>
        </SheetHeader>

        <div className="mt-6 flex flex-col gap-6">
          {type === CreateEditRuleType.CREATE && (
            <p className="text-sm text-gray-500">{t(`integrations.${provider}.rule.description`)}</p>
          )}

          {!!needName && (
            <div data-testid="input-apikey">
              <Input
                placeholder={t('rules.table.columns.name')}
                id="name"
                autoFocus
                onChange={handleChange}
                value={values.name}
              />
            </div>
          )}

          {!!needUrl && (
            <div data-testid="input-apiurl">
              <Input placeholder={t('rules.table.columns.url')} id="url" onChange={handleChange} value={values.url} />
            </div>
          )}

          {needProducts && (
            <Popover>
              <PopoverTrigger asChild>
                <Button variant="outline" role="combobox" className="gap-2">
                  {t('rules.table.columns.products')}

                  <div className="flex gap-1 flex-1">
                    {values.products.map((product) => (
                      <Badge variant="secondary" className="ml-2">
                        {product.name}
                      </Badge>
                    ))}
                  </div>

                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </PopoverTrigger>

              <PopoverContent className="z-[61]">
                <Command>
                  <CommandInput placeholder={t('rules.table.columns.products')} />

                  <CommandList>
                    <CommandEmpty>{t('rules.createModal.noOptions')}</CommandEmpty>

                    <CommandGroup>
                      {products.map((product) => (
                        <CommandItem
                          key={product.id}
                          onSelect={() => {
                            setFieldValue(
                              'products',
                              values.products.some((p) => p.id === product.id)
                                ? values.products.filter((p) => p.id !== product.id)
                                : [...values.products, product],
                            )
                          }}
                        >
                          <Check
                            className={`mr-2 h-4 w-4 ${
                              values.products.some((p) => p.id === product.id) ? 'opacity-100' : 'opacity-0'
                            }`}
                          />

                          {product.name}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
          )}

          {needOffers && (
            <Popover>
              <PopoverTrigger asChild>
                <Button variant="outline" role="combobox" className="gap-2">
                  {t('rules.table.columns.offers')}

                  <div className="flex gap-1 flex-1">
                    {values.offers.map((offer) => (
                      <Badge variant="secondary" className="ml-2">
                        {offer.name}
                      </Badge>
                    ))}
                  </div>

                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </PopoverTrigger>

              <PopoverContent className="z-[61]">
                <Command>
                  <CommandInput placeholder={t('rules.table.columns.offers')} />

                  <CommandList>
                    <CommandEmpty>{t('rules.createModal.noOptions')}</CommandEmpty>

                    <CommandGroup>
                      {offers
                        .filter((offer) => offer.id === 'all' || values.products.some((p) => p.id === offer.productId))
                        .map((offer) => (
                          <CommandItem
                            key={offer.id}
                            onSelect={() => {
                              setFieldValue(
                                'offers',
                                values.offers.some((o) => o.id === offer.id)
                                  ? values.offers.filter((o) => o.id !== offer.id)
                                  : [...values.offers, offer],
                              )
                            }}
                          >
                            <Check
                              className={`mr-2 h-4 w-4 ${
                                values.offers.some((o) => o.id === offer.id) ? 'opacity-100' : 'opacity-0'
                              }`}
                            />

                            {offer.name}
                          </CommandItem>
                        ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
          )}

          {needEvents && (
            <Popover>
              <PopoverTrigger asChild>
                <Button variant="outline" role="combobox" className="gap-2">
                  {t('rules.table.columns.events')}

                  <div className="flex gap-1 flex-1">
                    {values.events.map((event) => (
                      <Badge variant="secondary">{t(event.name)}</Badge>
                    ))}
                  </div>

                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </PopoverTrigger>

              <PopoverContent className="z-[61]">
                <Command>
                  <CommandInput placeholder={t('rules.table.columns.events')} />

                  <CommandList>
                    <CommandEmpty>{t('rules.createModal.noOptions')}</CommandEmpty>

                    {Object.entries(
                      events.reduce((acc, event) => {
                        const group = event.group
                        if (!acc[group]) acc[group] = []
                        acc[group].push(event)
                        return acc
                      }, {} as Record<string, IntegrationEventOption[]>),
                    ).map(([group, groupEvents]) => (
                      <CommandGroup key={group} heading={group}>
                        {groupEvents.map((event) => (
                          <CommandItem
                            key={event.code}
                            onSelect={() => {
                              setFieldValue(
                                'events',
                                values.events.some((e) => e.code === event.code)
                                  ? values.events.filter((e) => e.code !== event.code)
                                  : [...values.events, event],
                              )
                            }}
                          >
                            <Check
                              className={`mr-2 h-4 w-4 ${
                                values.events.some((e) => e.code === event.code) ? 'opacity-100' : 'opacity-0'
                              }`}
                            />

                            <div className="flex flex-col">
                              <span>{t(event.name)}</span>

                              <span className="text-xs text-gray-500">{event.code}</span>
                            </div>
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    ))}
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
          )}

          {needLists && (
            <Popover>
              <PopoverTrigger asChild>
                <Button variant="outline" role="combobox" className="gap-2">
                  {t(`integrations.${provider}.rule.columns.lists`)}

                  <div className="flex gap-1 flex-1">
                    {values.lists.map((list) => (
                      <Badge variant="secondary" className="ml-2">
                        {list.name}
                      </Badge>
                    ))}
                  </div>

                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </PopoverTrigger>

              <PopoverContent className="z-[61]">
                <Command>
                  <CommandInput placeholder={t(`integrations.${provider}.rule.columns.lists`)} />

                  <CommandList>
                    <CommandEmpty>{t('rules.createModal.noOptions')}</CommandEmpty>

                    <CommandGroup>
                      {lists.map((list) => (
                        <CommandItem
                          key={list.id}
                          onSelect={() => {
                            setFieldValue(
                              'lists',
                              values.lists.some((l) => l.id === list.id)
                                ? values.lists.filter((l) => l.id !== list.id)
                                : [...values.lists, list],
                            )
                          }}
                        >
                          <Check
                            className={`mr-2 h-4 w-4 ${
                              values.lists.some((l) => l.id === list.id) ? 'opacity-100' : 'opacity-0'
                            }`}
                          />

                          {list.name}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
          )}

          {needTags && (
            <Popover>
              <PopoverTrigger asChild>
                <Button variant="outline" role="combobox" className="gap-2">
                  {t(`integrations.${provider}.rule.columns.tags`)}

                  <div className="flex gap-1 flex-1">
                    {values.tags.map((tag) => (
                      <Badge variant="secondary" className="ml-2">
                        {tag.name}
                      </Badge>
                    ))}
                  </div>

                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </PopoverTrigger>

              <PopoverContent className="z-[61]">
                <Command>
                  <CommandInput placeholder={t(`integrations.${provider}.rule.columns.tags`)} />

                  <CommandList>
                    <CommandEmpty>{t('rules.createModal.noOptions')}</CommandEmpty>

                    <CommandGroup>
                      {tags.map((tag) => (
                        <CommandItem
                          key={tag.id}
                          onSelect={() => {
                            setFieldValue(
                              'tags',
                              values.tags.some((t) => t.id === tag.id)
                                ? values.tags.filter((t) => t.id !== tag.id)
                                : [...values.tags, tag],
                            )
                          }}
                        >
                          <Check
                            className={`mr-2 h-4 w-4 ${
                              values.tags.some((t) => t.id === tag.id) ? 'opacity-100' : 'opacity-0'
                            }`}
                          />

                          {tag.name}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
          )}
        </div>

        <Button
          disabled={!isValid}
          loading={isLoading}
          onClick={submitForm}
          className={cn('w-full', isLoading ? 'opacity-50 cursor-not-allowed' : '')}
        >
          {type === CreateEditRuleType.CREATE ? t('rules.buttons.create') : t('rules.buttons.edit')}
        </Button>
      </SheetContent>
    </Sheet>
  )
}
