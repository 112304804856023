import { container } from '@hub-la/fe-container'
import { TelegramVerificationProvider, VerificationQRCodePage } from '@hub-la/fe-telegram-verification'

export const TelegramVerificationQRCodeInit = () => {
  return (
    <TelegramVerificationProvider container={container}>
      <VerificationQRCodePage />
    </TelegramVerificationProvider>
  )
}
