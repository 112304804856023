import { Analytics, AnalyticsEvent } from '@hub-la/fe-core-analytics'
import { useRoleplay } from '@hub-la/fe-roleplay'
import { useSignOut } from '@hub-la/fe-sign-out'
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@hub-la/shadcn'
import { Info, LogOut, Package, Pen, ShoppingCart, Undo, User, Users } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getAppHost, getSiteUrl, isAtSite, routes } from '../../utils/routes'
import avatar from '../assets/default-user-avatar.png'
import { useContainer } from '../hooks/use-container'

export const AccountDropdown: React.FC<any> = ({ push, id, firstName, picture, username, bankAccount }) => {
  const { t } = useTranslation()
  const { execute: signOut } = useSignOut()
  const { signOut: signOutRoleplay, isRoleplay } = useRoleplay()
  const container = useContainer()
  const analytics = container.get(Analytics)
  const events = AnalyticsEvent.MAIN_MENU
  const userPicture = picture || String(avatar)

  const onClickMenuLink = (route: string, event: string): void => {
    analytics.track(event)

    if (route.startsWith(window.location.origin)) {
      push(route.replace(window.location.origin, ''))
    } else {
      window.location.href = route
    }
  }

  const onClickSignOut = async (): Promise<void> => {
    if (isRoleplay) {
      await signOutRoleplay()
    }
    signOut().then(() => {
      analytics.track(events.LOGOUT_CLICKED)
      isAtSite() && window.location.replace(`${getAppHost()}/logout`)
    })
  }

  const onClickHandleSelectAccount = async (): Promise<void> => {
    if (isRoleplay) {
      await signOutRoleplay()
    }
    push(routes.ROLEPLAY)
  }

  const getUsername = () => {
    return (username && username.length > 1 ? username : id) ?? ''
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="relative h-8 w-8 rounded-full">
          <Avatar className="h-8 w-8">
            <AvatarImage src={userPicture} alt={firstName} className="object-cover" />
            <AvatarFallback>{firstName ? firstName?.charAt(0) : 'U'}</AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-64" align="end" forceMount>
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p className="text-base font-medium leading-none">{firstName}</p>
            <p className="text-xs leading-none text-muted-foreground">{getUsername()}</p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup className="space-y-1">
          <a href={getSiteUrl(`/${username}`)}>
            <DropdownMenuItem onClick={() => onClickMenuLink(getSiteUrl(`/${username}`), events.EDIT_PROFILE_CLICKED)}>
              <User className="mr-2 h-4 w-4" />
              <span>{t('dropdown.header.showProfile')}</span>
            </DropdownMenuItem>
          </a>
          <DropdownMenuItem onClick={() => onClickMenuLink(routes.SETTINGS, events.EDIT_PROFILE_CLICKED)}>
            <Pen className="mr-2 h-4 w-4" />
            <span>{t('dropdown.header.editProfile')}</span>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={() => onClickMenuLink(routes.PRODUCTS, events.PRODUCTS_CLICKED)}>
            <Package className="mr-2 h-4 w-4" />
            <span>{t('dropdown.links.products')}</span>
          </DropdownMenuItem>
          {!isRoleplay && (
            <DropdownMenuItem onClick={() => onClickMenuLink(routes.PURCHASES, events.PURCHASES_CLICKED)}>
              <ShoppingCart className="mr-2 h-4 w-4" />
              <span>{t('dropdown.links.purchases')}</span>
            </DropdownMenuItem>
          )}
          <DropdownMenuItem
            onClick={() => {
              analytics.track(events.SUPPORT_CLICKED)
              window.open(routes.SUPPORT)
            }}
          >
            <Info className="mr-2 h-4 w-4" />
            <span>{t('dropdown.links.support')}</span>
          </DropdownMenuItem>
          {!isRoleplay && (
            <DropdownMenuItem onClick={() => onClickMenuLink(routes.COLLABORATORS, events.COLLABORATORS_CLICKED)}>
              <Users className="mr-2 h-4 w-4" />
              <span>{t('dropdown.links.collaborators')}</span>
            </DropdownMenuItem>
          )}
        </DropdownMenuGroup>
        <DropdownMenuSeparator className="my-1" />
        <DropdownMenuItem onClick={onClickHandleSelectAccount}>
          <Undo className="mr-2 h-4 w-4" />
          <span>{t('dropdown.links.selectAccount')}</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={onClickSignOut}>
          <LogOut className="mr-2 h-4 w-4 text-red-700" />
          <span>{t('dropdown.links.logout')}</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
