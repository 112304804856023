import { FormikProvider, useFormik } from 'formik'
import React from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { AuthCard } from '../../components/auth-card/auth-card'
import { AuthLayout } from '../../components/auth-layout'
import { CountdownButton } from '../../components/countdown-button'
import { MetaTags } from '../../components/meta-tags'
import { usePostRecoverPassword } from '../../hooks/use-post-recover-password'
import { recoverPasswordSchema } from '../../validations/recover-password'

import { Alert, AlertDescription, Button, Input, Label, Separator } from '@hub-la/shadcn'

const RecoverPasswordSendedCard = ({ email, nextRetryDate }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { mutate, isLoading, isError, reset, data } = usePostRecoverPassword()

  const handleResendEmail = async () => {
    const captcha = await executeRecaptcha?.('recover_password')
    mutate({ email, captcha })
  }

  const nextRetryDateResend = new Date(data?.nextRetryDate ?? nextRetryDate)

  return (
    <AuthCard
      showTerms={false}
      title="Verifique seu e-mail"
      description={
        <>
          Obrigado! Se o e-mail <strong>{email}</strong> estiver vinculado a uma conta existente na Hubla, você receberá
          um e-mail com todas as instruções para redefinir sua senha
        </>
      }
    >
      <div className="space-y-4 w-full">
        <CountdownButton onClick={handleResendEmail} isLoading={isLoading} endDate={nextRetryDateResend}>
          Reenviar e-mail
        </CountdownButton>
        <RouterLink to="/signin" className="block w-full">
          <Button variant="outline" className="w-full">
            Voltar para o login
          </Button>
        </RouterLink>
      </div>

      <div className="my-4 flex items-center w-full">
        <Separator className="flex-grow" />
        <span className="px-4 text-sm text-gray-500">Ou</span>
        <Separator className="flex-grow" />
      </div>

      <p className="text-sm text-gray-500 text-center">
        Não possui uma conta?{' '}
        <RouterLink className="text-lime-600" to="/signup">
          Crie sua conta
        </RouterLink>
      </p>

      {isError && (
        <Alert variant="destructive" className="mt-4">
          <AlertDescription>
            Erro ao reenviar a redefinição de senha. Tente novamente, caso o erro persista entre em contato com o nosso
            suporte.
          </AlertDescription>
        </Alert>
      )}
    </AuthCard>
  )
}

const RecoverPasswordCard = () => {
  const { t } = useTranslation()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { mutate, isSuccess, isLoading, isError, reset, data } = usePostRecoverPassword()

  const form = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async (values) => {
      const captcha = await executeRecaptcha?.('recover_password')
      mutate({
        email: values.email,
        captcha,
      })
    },
    validationSchema: recoverPasswordSchema,
  })

  if (isSuccess) {
    return <RecoverPasswordSendedCard email={form.values.email} nextRetryDate={data?.nextRetryDate} />
  }

  return (
    <AuthCard
      showTerms={false}
      title="Redefina sua senha"
      description="Informe o e-mail associado a sua conta para receber o link para redefinir sua senha"
    >
      <FormikProvider value={form}>
        <form onSubmit={form.handleSubmit} className="space-y-4 w-full">
          <div className="space-y-2">
            <Label htmlFor="email">E-mail</Label>
            <Input id="email" name="email" type="email" onChange={form.handleChange} value={form.values.email} />
            {form.touched.email && form.errors.email && <p className="text-sm text-red-500">{t(form.errors.email)}</p>}
          </div>

          <Button className="w-full" type="submit" disabled={!form.dirty || isLoading}>
            {isLoading ? 'Carregando...' : 'Continuar'}
          </Button>

          <RouterLink to="/signin" className="block w-full">
            <Button variant="outline" className="w-full" type="button">
              Voltar para o login
            </Button>
          </RouterLink>
        </form>
      </FormikProvider>

      <div className="relative my-4">
        <hr />
        <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-background px-2 text-xs text-muted-foreground">
          {t('Ou')}
        </span>
      </div>
      <p className="text-center text-sm text-muted-foreground">
        Não possui uma conta?{' '}
        <RouterLink className="text-lime-600" to="/signup">
          Crie sua conta
        </RouterLink>
      </p>

      {isError && (
        <Alert variant="destructive" className="mt-4">
          <AlertDescription>
            Erro ao tentar redefinir sua senha. Tente novamente, caso o erro persista entre em contato com o nosso
            suporte.
          </AlertDescription>
        </Alert>
      )}
    </AuthCard>
  )
}

const RecoverPasswordPage: React.FC = () => {
  return (
    <AuthLayout showTerms={false}>
      <MetaTags />
      <RecoverPasswordCard />
    </AuthLayout>
  )
}

export { RecoverPasswordPage }
