import { Badge, Card, CardContent, CardHeader, CardTitle, Separator } from '@hub-la/shadcn'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { PaymentMethod } from '../../../domain/enums/payment-method'
import { Phone } from '../../../domain/vos/phone'
import { CardRow } from './card-row'

type DetailsProps = {
  transactionId: string
  email?: string
  phoneNumber?: string
  paymentMethod: PaymentMethod
  createdAt: string
  paidAt: string
  isRenewing: boolean
}

export const Details = ({
  transactionId,
  email,
  phoneNumber,
  paymentMethod,
  createdAt,
  paidAt,
  isRenewing,
}: DetailsProps) => {
  const { t } = useTranslation()

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <div className="flex justify-between space-y-1">
            <h2 className="text-2xl font-semibold">Detalhes</h2>
            <Badge variant="outline">{isRenewing ? t('renewal') : t('purchase')}</Badge>
          </div>
        </CardTitle>
      </CardHeader>
      <Separator />
      <CardContent className="pt-4 space-y-4">
        <CardRow title="ID da fatura" content={<span>{transactionId}</span>} />

        <CardRow title="Data do pagamento" content={<span>{moment(paidAt).format('D MMM. YYYY HH:mm')}</span>} />

        <CardRow title="Data do pedido" content={<span>{moment(createdAt).format('D MMM. YYYY HH:mm')}</span>} />

        {email && <CardRow title="Email" content={<span>{email}</span>} />}

        {phoneNumber && (
          <CardRow title="Telefone" content={<span>{Phone.build(phoneNumber).getFormattedNumber()}</span>} />
        )}

        <CardRow title="Método de Pagamento" content={<span>{t(`paymentMethod.${paymentMethod}`)}</span>} />
      </CardContent>
    </Card>
  )
}
