interface InfoRowProps {
  label: string
  value?: string
}

export const InfoRow = ({ label, value }: InfoRowProps) => (
  <div className="flex justify-between items-start">
    <div className="w-48 pr-2">
      <p className="text-base text-muted-foreground">{label}</p>
    </div>
    <div className="pl-2 max-w-sm">
      <p className={`text-base`}>{value || '–'}</p>
    </div>
  </div>
)
