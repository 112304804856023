import { spacing, useIsMobile } from '@hub-la/design-system'
import { AccountDropdownProvider } from '@hub-la/fe-account-dropdown'
import { AccountVerificationBanner } from '@hub-la/fe-account-verification'
import { container } from '@hub-la/fe-container'
import { useRoleplay } from '@hub-la/fe-roleplay'
import { useTheme } from '@hub-la/shadcn'
import { Box, Container } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { AppHeader } from './app-header'
import { MenuSidebar } from './menu-sidebar'

type Props = {
  children: React.ReactNode
  hideSidebar?: boolean
  disablePadding?: boolean
  disableAppHeader?: boolean
}

export const DashboardContainer: React.FC<Props> = ({ children, hideSidebar, disablePadding, disableAppHeader }) => {
  useTheme('light')

  const isMobile = useIsMobile()
  const [toggleSidebar, setToggleSidebar] = useState(() => localStorage.getItem('toggleSidebar') === 'true')
  const { isRoleplay, hasPermission } = useRoleplay()

  useEffect(() => {
    localStorage.setItem('toggleSidebar', String(toggleSidebar))
  }, [toggleSidebar])

  const handleToggleSidebar = useCallback((value: boolean) => {
    setToggleSidebar(value)
  }, [])

  const headerHeight = 52
  const roleplayExtraHeight = 48

  return (
    <div className="flex bg-zinc-50">
      {!disableAppHeader && (
        <AccountDropdownProvider container={container}>
          <AppHeader isToggleSidebar={toggleSidebar} onToggleSidebar={handleToggleSidebar} hideSidebar={hideSidebar} />
        </AccountDropdownProvider>
      )}
      {!isMobile && !hideSidebar && <MenuSidebar isToggleSidebar={toggleSidebar} />}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          minHeight: disableAppHeader
            ? '100vh'
            : `calc(100vh - ${headerHeight}px${isRoleplay ? ` - ${roleplayExtraHeight}px` : ''})`,
          marginTop: disableAppHeader ? 0 : `${headerHeight + (isRoleplay ? roleplayExtraHeight : 0)}px`,
          width: '100%',
        }}
      >
        {isRoleplay && <AccountVerificationBanner />}

        <Container
          maxWidth={false}
          disableGutters
          sx={
            disablePadding
              ? {
                  paddingTop: spacing(isMobile ? 2 : 3),
                  paddingLeft: spacing(isMobile ? 0 : 0),
                  paddingRight: spacing(isMobile ? 0 : 0),
                  paddingBottom: spacing(isMobile ? 0 : 0),
                }
              : {
                  paddingTop: spacing(isMobile ? 6 : 10),
                  paddingLeft: spacing(isMobile ? 6 : 10),
                  paddingRight: spacing(isMobile ? 6 : 10),
                  paddingBottom: spacing(isMobile ? 6 : 10),
                }
          }
        >
          {children}
        </Container>
      </Box>
    </div>
  )
}
