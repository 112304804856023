import { Separator } from '@hub-la/shadcn'
import { Tag } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { OfferItem } from '../../../domain/dtos/invoice'
import { CopyClipboardTag } from '../../components/copy-clipboard-tag'

type ChargeDetailProps = {
  offers: OfferItem[]
}

export const Offers = ({ offers }: ChargeDetailProps) => {
  const { t } = useTranslation()

  if (!offers || !offers?.length) {
    return null
  }

  return (
    <div className="space-y-4">
      <h2 className="text-lg font-semibold">Ofertas</h2>
      <Separator />
      <div className="space-y-2">
        {offers.map((offer) => (
          <div className="flex items-center">
            <Tag size={16} className="mr-2 text-muted-foreground" />
            <p className="text-sm flex-1">{offer?.name}</p>
            <CopyClipboardTag
              value={offer.id}
              successMessage={t('copyClipboard.idCopied')}
              tooltip={t('copyClipboard.idTooltip')}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
