import { AssetResponse, AssetStatus, AssetType } from '@hub-la/fe-asset'
import { cn, Progress } from '@hub-la/shadcn'
import { Lock } from 'lucide-react'
import { useGetPdfFileUrl } from '../../hooks/use-get-pdf-file-url'
import { MediaDocumentPDF } from '../media-document-pdf/media-document-pdf'
import { Player } from '../player'
import { VideoProcessingCover } from '../video-processing-cover'

type ThumbnailOverlayProps = {
  image?: string
  alt?: string
}

const ThumbnailOverlay = ({ image, alt }: ThumbnailOverlayProps) => {
  return (
    <img
      className="absolute top-1/2 left-1/2 w-full h-full object-cover rounded-none lg:rounded-lg filter blur-sm brightness-50 transform -translate-x-1/2 -translate-y-1/2"
      src={image}
      alt={alt}
    />
  )
}

type PostDetailCoverProps = {
  cover?: AssetResponse
  customCover?: string
  sectionId: string
  postId: string
  hideContent?: boolean
  hideText?: string
  isLive?: boolean
}

export const PostDetailCover = ({
  cover,
  customCover,
  sectionId,
  postId,
  hideContent,
  hideText,
  isLive,
}: PostDetailCoverProps) => {
  const hasContent = !!(cover?.metadata?.thumbnailUrl ?? cover?.url ?? customCover)

  const { data: pdfFileUrl } = useGetPdfFileUrl(cover)

  /** @description: Hide the content for users that don't have access */
  if (hasContent && hideContent) {
    return (
      <div
        id="detail-cover-box"
        data-testid="content-hidden"
        className={cn(
          'relative w-full rounded-none lg:rounded-lg overflow-hidden flex',
          isLive
            ? 'bg-gradient-to-r from-[#d8fd6c] via-[#dde2fe] to-[#79fbac] bg-[length:400%_400%] animate-background-wave border-4 border-transparent'
            : 'bg-transparent border',
        )}
      >
        <img
          className="w-full h-full aspect-video object-cover rounded-none lg:rounded-lg filter blur-3xl brightness-50"
          src={cover?.metadata?.thumbnailUrl ?? cover?.url ?? customCover}
          alt="hidden"
        />

        <div className="absolute top-1/2 left-1/2 flex flex-col gap-2 items-center transform -translate-x-1/2 -translate-y-1/2">
          <Lock />

          <p className="text-sm">{hideText}</p>
        </div>
      </div>
    )
  }

  /** @description: If it does not have a cover, but has a customCover, display it */
  if (!cover && customCover) {
    return (
      <div
        id="detail-cover-box"
        data-testid="custom-cover"
        className={cn(
          'relative w-full aspect-video border rounded-md overflow-hidden flex',
          isLive
            ? 'bg-gradient-to-r from-[#d8fd6c] via-[#dde2fe] to-[#79fbac] bg-[length:400%_400%] animate-background-wave border-4 border-transparent'
            : 'bg-transparent border',
        )}
      >
        <ThumbnailOverlay image={customCover} alt="capa" />
      </div>
    )
  }

  return (
    <div
      id="detail-cover-box"
      key={cover?.id}
      className={cn(
        'w-full rounded-none lg:rounded-lg flex',
        isLive
          ? 'bg-gradient-to-r from-[#d8fd6c] via-[#dde2fe] to-[#79fbac] bg-[length:400%_400%] animate-background-wave border-4 border-transparent'
          : 'bg-transparent  bg-zinc-900',
      )}
    >
      {/** @description: Image */}
      {cover?.type === AssetType.image && (
        <img
          src={cover.url}
          alt={cover.name}
          className="m-auto w-full aspect-video bg-zinc-900 shadow-2xl sha rounded-none lg:rounded-lg"
          data-testid="image-viewer"
        />
      )}

      {/** @description: PDF file */}
      {cover?.type === AssetType.application && (
        <div
          className="w-full aspect-auto p-2 bg-zinc-900 shadow-2xl sha rounded-none lg:rounded-lg flex"
          data-testid="pdf-viewer"
        >
          {!pdfFileUrl && <Progress className="h-1 w-full" indicatorColor="bg-red-600" />}

          {pdfFileUrl && (
            <MediaDocumentPDF
              progress={cover.progress?.currentProgress || 1}
              assetId={cover.id}
              url={pdfFileUrl}
              sectionId={sectionId}
              postId={postId}
            />
          )}
        </div>
      )}

      {/** @description: Video that have been processed */}
      {cover?.type === AssetType.video && cover.status !== AssetStatus.processing && (
        <div data-testid="video-player" className="w-full aspect-video flex">
          <Player
            progress={cover.progress?.currentProgress}
            duration={cover.metadata?.duration}
            assetId={cover.id}
            src={cover.url ?? ''}
            poster={cover.metadata?.thumbnailUrl}
            title={cover.name}
            sectionId={sectionId}
            postId={postId}
          />
        </div>
      )}

      <VideoProcessingCover cover={cover} />
    </div>
  )
}
