import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@hub-la/shadcn'
import { Info } from 'lucide-react'
import React from 'react'

interface ContentRowProps {
  label: string
  value: string
  secondValue?: string
  secondaryText?: boolean
  tooltip?: string
  variant?: 'display1' | 'display2' | 'display3' | 'h1' | 'h2' | 'h3' | 'h4' | 'body1' | 'body2' | 'caption'
  bold?: boolean
}

const variantClasses = {
  display1: 'text-4xl',
  display2: 'text-3xl',
  display3: 'text-2xl',
  h1: 'text-2xl',
  h2: 'text-xl',
  h3: 'text-lg',
  h4: 'text-base',
  body1: 'text-base',
  body2: 'text-sm',
  caption: 'text-xs',
}

export const ContentRow: React.FC<ContentRowProps> = ({
  label,
  value,
  secondaryText = false,
  secondValue,
  tooltip,
  variant = 'body2',
  bold = false,
}) => {
  const textColor = secondaryText ? 'text-gray-500' : 'text-gray-900'
  const fontWeight = bold ? 'font-bold' : 'font-normal'
  const variantClass = variantClasses[variant] || 'text-sm'

  const labelElement = <span className={`${textColor} ${fontWeight} ${variantClass}`}>{label}</span>

  const keyElement = tooltip ? (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="flex items-center">
            {labelElement}
            <Info className="ml-1 h-4 w-4 text-gray-500" />
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p>{tooltip}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  ) : (
    labelElement
  )

  return (
    <div className="flex items-center justify-between mb-1 mr-4">
      {keyElement}
      <span className={`${textColor} ${variantClass}`}>{value}</span>
      {secondValue && <span className={`${textColor} ${variantClass}`}>{secondValue}</span>}
    </div>
  )
}
