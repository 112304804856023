import React from 'react'
import { Header } from './components/header'
import { Pendencies } from './components/pendencies'
import { PendingInvites } from './components/pending-invites'
import { GroupsList } from './components/group-list'
import { AppHeader } from './app-header'

export const UserGroupsPage: React.FC = () => {
  return (
    <div className="flex flex-col container gap-4">
      <AppHeader />
      <Pendencies />
      <Header />
      <PendingInvites />
      <GroupsList />
    </div>
  )
}
