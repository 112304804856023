import { OfferDto } from '@hub-la/sdk-bff-product'
import { Badge, Button } from '@hub-la/shadcn'
import { formatLocalNumber } from 'libs/frontend/modules/offer/src/lib/domain/vos/format-local-number'
import { Edit, Plus, Trash2 } from 'lucide-react'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PlaceholderLogo from '../../assets/placeholder-image.svg'
import { OfferSchemaValidationType } from '../../validations/offer-validation'
import { UpsellModal } from './upsell-modal'

const OfferPreviewSelected = ({ logo, offer, onEdit, onDelete }) => {
  const { t } = useTranslation()
  const isRecurring = offer?.plans?.length > 0

  return (
    <div className="flex items-center justify-between border border-gray-200 p-4 rounded-lg">
      <div className="space-y-3">
        <div className="flex items-center space-x-3">
          <img src={logo ?? PlaceholderLogo} alt="Product logo" className="w-[70px] h-[46px] object-cover rounded" />
          <span className="text-sm">{offer?.name}</span>
        </div>
        {isRecurring && (
          <div className="flex flex-wrap gap-2">
            {offer?.plans?.map((plan) => (
              <Badge key={plan.interval} variant="secondary">
                {`${formatLocalNumber(plan.price / 100 ?? 0)} / ${t('plansPerPeriod.' + plan.interval.toLowerCase())}`}
              </Badge>
            ))}
          </div>
        )}
      </div>

      <div className="flex items-center space-x-3">
        {!isRecurring && <Badge variant="secondary">{formatLocalNumber(offer?.price / 100 ?? 0)}</Badge>}
        <Button variant="ghost" size="sm" onClick={onEdit} type="button">
          <Edit className="h-4 w-4" />
        </Button>
        <Button variant="ghost" size="sm" onClick={onDelete} type="button">
          <Trash2 className="h-4 w-4" />
        </Button>
      </div>
    </div>
  )
}

export const UpsellCustomization = () => {
  const { t } = useTranslation()
  const { watch, setValue, resetField } = useFormContext<OfferSchemaValidationType>()
  const upsell = watch('afterPurchase.upsell')
  const [orderbumpModal, setOrderBumpModal] = useState({
    isEdit: false,
    open: false,
  })

  return (
    <>
      <UpsellModal
        open={orderbumpModal.open}
        onCancel={() => {
          if (!orderbumpModal.isEdit) {
            resetField('checkout.isOrderBump')
          }
          setOrderBumpModal({ isEdit: false, open: false })
        }}
        onSubmit={(values) => {
          setValue('afterPurchase.upsell.product', values.product, { shouldDirty: true })
          setValue(
            'afterPurchase.upsell.offer',
            {
              ...values?.offer,
              price: values?.offer?.price ? values.offer.price * 100 : 0,
              plans: values?.offer?.plans?.map((plan) => ({ ...plan, price: plan.price * 100 })),
            } as OfferDto,
            { shouldDirty: true },
          )
          setValue('afterPurchase.upsell.youtubeEmbedUrl', values?.youtubeEmbedUrl ?? null)
          setValue('afterPurchase.upsell.isYoutubeEmbed', values?.isYoutubeEmbed)
          setOrderBumpModal({ isEdit: false, open: false })
        }}
        defaultValues={upsell}
        actionSave={orderbumpModal.isEdit ? t('upsellModal.actions.edit') : t('upsellModal.actions.save')}
        actionCancel={
          orderbumpModal.isEdit
            ? t('offer.checkoutTab.options.orderBump.cancel')
            : t('offer.checkoutTab.options.orderBump.close')
        }
      />

      <div className="w-full space-y-4">
        <div className="w-full flex justify-between">
          {!upsell?.offer && (
            <Button variant="outline" onClick={() => setOrderBumpModal({ isEdit: false, open: true })} type="button">
              <Plus className="mr-2 h-4 w-4" />
              {t('offer.afterPurchaseTab.upsell.add')}
            </Button>
          )}
        </div>

        {upsell?.offer && (
          <OfferPreviewSelected
            offer={upsell?.offer}
            logo={upsell?.product?.picture ?? PlaceholderLogo}
            onEdit={() => setOrderBumpModal({ isEdit: true, open: true })}
            onDelete={() => {
              setValue('afterPurchase.upsell.offer', null, { shouldDirty: true })
              setValue('afterPurchase.upsell.productId', null, { shouldDirty: true })
            }}
          />
        )}

        {/* Commented out Select component */}
        {/* <p>{t('offer.afterPurchaseTab.upsell.upsellRedirectType.description')}</p>
        <Controller
          name="afterPurchase.upsell.redirectType"
          control={control}
          render={({ field }) => (
            <Select onValueChange={field.onChange} defaultValue={field.value}>
              <SelectTrigger className="w-full">
                <SelectValue placeholder={t('offer.afterPurchaseTab.upsell.upsellRedirectType.label')} />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={RedirectType.USER_GROUPS}>
                  {t('redirectType.' + RedirectType.USER_GROUPS)}
                </SelectItem>
                <SelectItem value={RedirectType.MEMBERS_AREA}>
                  {t('redirectType.' + RedirectType.MEMBERS_AREA)}
                </SelectItem>
              </SelectContent>
            </Select>
          )}
        /> */}
      </div>
    </>
  )
}
