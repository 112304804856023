import { Card, CardContent, Skeleton } from '@hub-la/shadcn'

export const LoadingCard = () => {
  return (
    <div className="w-full">
      <Card className="bg-card hover:bg-card">
        <CardContent className="p-6">
          <div className="flex justify-between">
            <div className="space-y-2">
              <Skeleton className="h-5 w-4/5 rounded" />
              <Skeleton className="h-8 w-[155px] rounded" />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}
