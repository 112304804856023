import { yupResolver } from '@hookform/resolvers/yup'
import {
  Badge,
  Button,
  Input,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Separator,
  Tabs,
  TabsList,
  TabsTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@hub-la/shadcn'
import { Loader2, X } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { IsEvent } from '../../../usecases/is-event.usecase'
import { CloseAlertModal } from '../../components/close-alert-modal'
import { useGetProduct } from '../../hooks/use-get-product'
import { useListCohorts } from '../../hooks/use-list-cohorts'
import { offerSchemaValidation, OfferSchemaValidationType } from '../../validations/offer-validation'
import { CheckoutContent } from './checkout/checkout-content'
import { AfterPurchaseContent } from './digital/after-purchase/after-purchase-content'
import { PricingContent } from './digital/pricing/pricing-content'
import { PricingPreview } from './digital/pricing/pricing-preview'
import { AfterPurchaseEventContent } from './event/after-purchase/after-purchase-content'
import { PricingEventContent } from './event/pricing/pricing-content'

const OfferHeader = ({ productId, isEdit, isSubmitting, isDirty, hasErrors, errorsKeys }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [closeConfirmationOpen, setCloseConfirmationOpen] = useState(false)

  return (
    <div className="p-4  flex justify-between items-center border-b border-gray-200 bg-white">
      <div className="flex items-center">
        <CloseAlertModal
          onConfirm={() => history.push(`/edit/${productId}/offers`)}
          onClose={() => setCloseConfirmationOpen(false)}
          open={closeConfirmationOpen}
        />

        <Button
          size="icon"
          variant="ghost"
          onClick={() => {
            if (isDirty) {
              setCloseConfirmationOpen(true)
              return
            }

            history.push(`/edit/${productId}/offers`)
          }}
        >
          <X className=" h-4 w-4" />
        </Button>

        <div className="hidden sm:flex gap-4 items-center">
          <Separator orientation="vertical" className="min-h-5" />

          <h1 className="text-sm font-medium">{isEdit ? t('offer.title.edit') : t('offer.title.new')}</h1>
        </div>
      </div>

      <div className="flex gap-3">
        {hasErrors ? (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button type="submit" disabled={!isDirty || isSubmitting} loading={isSubmitting}>
                  {isEdit ? t('offer.header.edit') : t('offer.header.publish')}
                </Button>
              </TooltipTrigger>

              <TooltipContent>
                <p className="whitespace-pre-line">
                  Verifique os erros na aba:
                  <br />
                  {errorsKeys.map((field) => `- ${t('offer.tooltipErrors.' + field)}`).join('\n')}
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ) : (
          <Button type="submit" disabled={!isDirty || isSubmitting} loading={isSubmitting}>
            {isEdit ? t('offer.header.edit') : t('offer.header.publish')}
          </Button>
        )}
      </div>
    </div>
  )
}

const tabContent = {
  event: {
    pricing: <PricingEventContent />,
    checkout: <CheckoutContent />,
    afterPurchase: <AfterPurchaseEventContent />,
  },
  digital: {
    pricing: <PricingContent />,
    checkout: <CheckoutContent />,
    afterPurchase: <AfterPurchaseContent />,
  },
}

type OfferPageProps = {
  productId: string
  offerId?: string
  defaultValues: OfferSchemaValidationType
  onSubmit: (data: OfferSchemaValidationType) => void
  isLoading?: boolean
  isSubmitting?: boolean
}

const OfferForm = ({ offerId, productId, onSubmit, defaultValues, isLoading, isSubmitting }: OfferPageProps) => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()

  const { data: product } = useGetProduct(productId)
  const { data: cohorts, isLoading: isLoadingCohorts } = useListCohorts({ productId })

  const form = useForm<OfferSchemaValidationType>({
    defaultValues: useMemo(() => defaultValues, [defaultValues]),
    resolver: yupResolver(offerSchemaValidation),
    mode: 'onChange',
  })

  useEffect(() => {
    form.reset(defaultValues)
  }, [form, defaultValues])

  const searchParams = new URLSearchParams(location.search)
  const tab = searchParams.get('tab') || 'pricing'

  const updateTabParam = (newTab: string) => {
    searchParams.set('tab', newTab)
    history.push({ search: searchParams.toString() })
  }

  const tabContentByProductType = product?.type ? tabContent[product.type] : {}
  const isEvent = new IsEvent(product).execute()

  if (isLoading) {
    return (
      <FormProvider {...form}>
        <div className="min-h-screen">
          <OfferHeader
            productId={productId}
            isEdit={!!offerId}
            isSubmitting={isLoading}
            isDirty={false}
            hasErrors={false}
            errorsKeys={[]}
          />

          <div className="flex justify-center items-center min-h-screen">
            <div className="animate-spin">
              <Loader2 className="h-12 w-12" />
            </div>
          </div>
        </div>
      </FormProvider>
    )
  }

  return (
    <FormProvider {...form}>
      <form className="min-h-screen" onSubmit={form.handleSubmit(onSubmit)}>
        <OfferHeader
          productId={productId}
          isEdit={!!offerId}
          isSubmitting={isSubmitting}
          isDirty={Object.keys(form.formState.dirtyFields).length > 0}
          hasErrors={Object.keys(form.formState.errors).length > 0}
          errorsKeys={Object.keys(form.formState.errors)}
        />

        <div className="min-h-[calc(100vh-75px)] flex flex-col md:grid md:grid-cols-5">
          <div className="px-6 py-8 flex md:col-span-2 md:pl-24 md:pr-12 flex-col gap-4">
            <h2 className="text-xl font-semibold">{offerId ? t('offer.title.edit') : t('offer.title.new')}</h2>

            <div className="mb-6 flex flex-col gap-4">
              <Controller
                name="name"
                control={form.control}
                render={({ field, fieldState }) => (
                  <div className="flex flex-col gap-2">
                    <Label>{t('offer.name.label')}</Label>

                    <Input
                      {...field}
                      placeholder={t('offer.name.placeholder')}
                      className={fieldState.error ? 'border-red-500' : ''}
                    />

                    {fieldState.error && (
                      <p className="text-red-500 text-sm mt-1">{t(`fieldErrors.${fieldState.error.type}`)}</p>
                    )}
                  </div>
                )}
              />

              <Controller
                name="cohortId"
                render={({ field, fieldState }) => (
                  <div className="flex flex-col gap-2">
                    <Label>{t(isEvent ? 'offer.cohortEvent.select.label' : 'offer.cohort.select.label')}</Label>

                    <Select
                      disabled={isLoadingCohorts}
                      onValueChange={(value) => value && field.onChange(value)}
                      value={field.value}
                    >
                      <SelectTrigger className={fieldState.error ? 'border-red-500' : ''}>
                        <SelectValue
                          placeholder={t(
                            isEvent ? 'offer.cohortEvent.select.placeholder' : 'offer.cohort.select.placeholder',
                          )}
                        />
                      </SelectTrigger>

                      <SelectContent>
                        {(cohorts ?? []).map((cohort) => (
                          <SelectItem key={cohort?.id} value={cohort?.id}>
                            <div className="flex items-center gap-2">
                              <span>{cohort?.name}</span>

                              {cohort?.isDefault && (
                                <Badge variant="secondary" className="ml-2">
                                  Padrão
                                </Badge>
                              )}
                            </div>
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>

                    {fieldState.error && (
                      <p className="text-red-500 text-sm mt-1">{t(`fieldErrors.${fieldState.error.type}`)}</p>
                    )}
                  </div>
                )}
              />
            </div>

            <Tabs value={tab} onValueChange={updateTabParam}>
              <TabsList variant="underlined">
                {Object.keys(tabContentByProductType).map((tabName) => (
                  <TabsTrigger key={tabName} value={tabName} variant="underlined">
                    {t(`offer.tabs.${tabName}`)}
                  </TabsTrigger>
                ))}
              </TabsList>
            </Tabs>

            <div className="flex flex-col gap-12">{tabContentByProductType[tab]}</div>
          </div>

          <div className="px-6 py-6 min-w-80 md:col-span-3 bg-gray-50 border-l border-gray-200">
            <PricingPreview productName={product?.name} productPicture={product?.picture} />
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

export { OfferForm }
