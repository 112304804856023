import { Badge, Table, TableBody, TableCell, TableHead, TableHeader, TablePagination, TableRow } from '@hub-la/shadcn'
import isEmpty from 'lodash/isEmpty'
import times from 'lodash/times'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Partner } from '../../../domain/dtos/partner'
import { PartnershipActions } from '../../components/partnership-actions'
import { useListPartnerships } from '../../hooks/use-list-partnerships'
import { PartnershipStatusTag } from './partnership-status-tag'
import { PartnershipsTableLoading } from './partnerships-table-loading'

export const Partnerships: React.FC = () => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(25)

  const { t } = useTranslation()
  const {
    data = { items: [], lastPage: 0, page: 1, pageSize: 1, total: 0 },
    isFetching,
    refetch,
  } = useListPartnerships({
    page,
    pageSize,
  })

  const headCells = [
    { key: 'product', label: 'Produto', sortable: false, width: 190 },
    { key: 'status', label: 'Status', sortable: false, width: 90 },
    { key: 'commissioning', label: 'Comissão', sortable: false, width: 60 },
    { key: 'expires', label: 'Data de encerramento', sortable: false, width: 90 },
    { key: 'actions', label: '', sortable: false, width: 80 },
  ]

  const isRowsEmpty = isEmpty(data.items)
  const canRenderEmptyState = !isFetching && isRowsEmpty
  const isLoading = isFetching && isRowsEmpty

  return (
    <div className="flex flex-col gap-4">
      {/* <div className="flex justify-between items-baseline">
        <p className="text-sm">{data.total} co-produções</p>
      </div> */}

      <div className="flex flex-col gap-4  bg-white rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              {headCells.map((cell) => (
                <TableHead key={cell.key} className="font-medium" style={{ width: cell.width }}>
                  {cell.label}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>

          <TableBody>
            {isLoading
              ? times(pageSize, (i) => <PartnershipsTableLoading key={`skeleton-row-${i}`} />)
              : data.items.map((partner: Partner) => (
                  <TableRow key={partner.id} className="hover:bg-gray-50">
                    <TableCell>
                      <div className="flex items-center space-x-4">
                        <img
                          src={partner.offer.picture ?? '/placeholder-image.svg'}
                          alt={partner.offer.name}
                          className="w-[61px] h-auto aspect-[218/142] rounded object-cover"
                        />

                        <div>
                          <p className="text-base font-medium">{partner.offer.name}</p>
                          <p className="text-sm text-gray-500">{partner.offer.id}</p>
                        </div>
                      </div>
                    </TableCell>

                    <TableCell>
                      <PartnershipStatusTag status={partner.status} />
                    </TableCell>

                    <TableCell>
                      <Badge variant="outline">{partner.commission.sell?.percent ?? 0}%</Badge>
                    </TableCell>

                    <TableCell>{partner.expiresAt ? moment(partner.expiresAt).format('D MMM. YYYY') : '∞'}</TableCell>

                    <TableCell>
                      <PartnershipActions
                        receiverId={partner.id}
                        status={partner.status}
                        termsText={partner.termsText}
                        commission={partner.commission.sell?.percent ?? 0}
                        expiresAt={partner.expiresAt}
                        onChange={refetch}
                      />
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>

        {canRenderEmptyState && (
          <div className="text-center py-4">
            <p>{t('empty')}</p>
          </div>
        )}
      </div>

      {!isRowsEmpty && (
        <TablePagination
          page={page}
          setPage={setPage}
          lastPage={data.lastPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </div>
  )
}
