import { AuthProvider, AuthRedirectProvider, SignInSmsPage } from '@hub-la/fe-auth'

export const SignInSmsInit = () => {
  return (
    <AuthProvider>
      <AuthRedirectProvider>
        <SignInSmsPage />
      </AuthRedirectProvider>
    </AuthProvider>
  )
}
