import { ImageUploader } from '@hub-la/fe-asset'
import {
  ProductSettingsInfoResponseDto,
  UpdateProductSettingsInfoRequestDto,
  UpdateProductSettingsInfoRequestDtoCategoryEnum,
} from '@hub-la/sdk-bff-product'
import { Input, Label, Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Textarea } from '@hub-la/shadcn'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { ProductCategory } from '../../../../domain/enums/product-category'
import { ProductType } from '../../../../domain/enums/product-type'
import { Section } from '../../../components/section'
import { useGetSettings } from '../../../hooks/settings/use-get-settings'
import { useSaveSettings } from '../../../hooks/settings/use-save-settings'
import { useGetProduct } from '../../../hooks/use-get-product'
import { EventDate } from './event-date'
import { EventLocation } from './event-location'

export type Values = UpdateProductSettingsInfoRequestDto
const validationSchema = Yup.object().shape({})
const makeInitialValues = (data?: ProductSettingsInfoResponseDto): Values => {
  return {
    name: data?.name ?? '',
    category: (data?.category ?? ProductCategory.other) as unknown as UpdateProductSettingsInfoRequestDtoCategoryEnum,
    description: data?.description ?? '',
    picture: data?.picture ?? '',
  }
}

export const BasicInfo = () => {
  const { productId } = useParams<{ productId: string }>()
  const { mutateAsync: saveSettings } = useSaveSettings()
  const { data, isLoading } = useGetSettings({ productId, type: 'info' })
  const { t } = useTranslation()
  const { data: product } = useGetProduct(productId)

  const formik = useFormik<Values>({
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: makeInitialValues(data),
    onSubmit: async (values) => {
      await saveSettings({ productId, type: 'info', data: values })
    },
  })

  const isEvent = product?.type === ProductType?.EVENT

  return (
    <div className="space-y-6">
      <Section title={t('settings.basicInfo.title')} subtitle={t('settings.basicInfo.subtitle')} formik={formik}>
        <div className="space-y-6">
          <div className="flex flex-col md:flex-row gap-4">
            <div className="w-full">
              <Label>{t('settings.basicInfo.input.cover.title')}</Label>

              <p className="text-sm text-gray-500">{t('settings.basicInfo.input.cover.subtitle')}</p>
            </div>

            <ImageUploader
              name="picture"
              path={productId}
              className="w-36 h-20"
              value={formik.values.picture}
              onChange={(file) => formik.setFieldValue('picture', file?.URL ?? '')}
            />
          </div>

          <div>
            <Label htmlFor="name">Nome do produto</Label>

            <Input id="name" {...formik.getFieldProps('name')} disabled={isLoading} maxLength={70} />
            {formik.touched.name && formik.errors.name && <p className="text-sm text-red-500">{formik.errors.name}</p>}
          </div>

          <div>
            <Label htmlFor="category">Categoria</Label>

            <Select
              onValueChange={(value) => formik.setFieldValue('category', value)}
              defaultValue={formik.values.category}
              disabled={isLoading}
            >
              <SelectTrigger>
                <SelectValue placeholder="Selecione uma categoria" />
              </SelectTrigger>

              <SelectContent>
                {Object.values(ProductCategory).map((category) => (
                  <SelectItem key={category} value={category}>
                    {t(`settings.basicInfo.categories.${category}`)}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div>
            <Label htmlFor="description">Descrição</Label>

            <Textarea
              id="description"
              {...formik.getFieldProps('description')}
              disabled={isLoading}
              maxLength={350}
              rows={3}
            />

            <p className="text-sm text-gray-500">{`${(formik.values.description ?? '').length}/350 caracteres.`}</p>
            {formik.touched.description && formik.errors.description && (
              <p className="text-sm text-red-500">{formik.errors.description}</p>
            )}
          </div>
        </div>
      </Section>

      {isEvent && <EventDate />}

      {isEvent && <EventLocation />}
    </div>
  )
}
