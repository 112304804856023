import { ChartTokens } from './chart-tokens.types'

export const CHART_TOKENS: ChartTokens = {
  primaryContainer: '#D7FF60',

  greenPrimary: '#587300',
  greenSecondary: '#314000',

  redPrimary: '#F08A71',
  redSecondary: '#80230D',

  yellowPrimary: '#F7CC4A',
  yellowSecondary: '#8C6900',

  bluePrimary: '#6B90FF',
  blueSecondary: '#0C44B4',

  purplePrimary: '#B29EF2',
  purpleSecondary: '#4B3886',

  pinkPrimary: '#F38CAE',
  pinkSecondary: '#974161',

  orangePrimary: '#EC9548',
  orangeSecondary: '#6C3906',

  neutralPrimary: '#C4C8BC',
  neutralSecondary: '#5C6057',
}
