import Close from '@mui/icons-material/Close'
import { Snackbar as SnackbarMui } from '@mui/material'
import { styled } from '@mui/material/styles'
import { css, Stack } from '@mui/system'
import { elevation, shape, spacing, withUtility } from '../../foundation/utils'
import { Button, Text } from '../components'
import { SnackbarProps } from './snackbar.types'

const SnackbarWrapper = styled(SnackbarMui)<Omit<SnackbarProps, 'variant'>>`
  ${(props) =>
    !props.autoPosition &&
    css`
      position: static;
      transform: none !important;
      justifycontent: normal !important;
    `}
`

const SnackbarContent = styled(Stack)`
  ${withUtility<Pick<SnackbarProps, 'variant'>>(({ palette }, { variant }) => {
    switch (variant) {
      case 'neutral':
        return css`
          background-color: ${palette('surfaceAt1')};
          color: ${palette('onSurface')};
          border: 1.5px solid ${palette('surfaceVariant')};
          button {
            color: ${palette('onSurface')};
          }
        `
      case 'positive':
        return css`
          background-color: ${palette('successContainer')};
          color: ${palette('onSuccessContainer')};
          border: 1.5px solid ${palette('onSuccessContainer')};
          button {
            color: ${palette('onSuccessContainer')};
          }
        `
      case 'negative':
        return css`
          background-color: ${palette('errorContainer')};
          color: ${palette('onErrorContainer')};
          border: 1.5px solid ${palette('onErrorContainer')};
          button {
            color: ${palette('onErrorContainer')};
          }
        `
      case 'informative':
        return css`
          background-color: ${palette('infoContainer')};
          color: ${palette('onInfoContainer')};
          border: 1.5px solid ${palette('onInfoContainer')};
          button {
            color: ${palette('onInfoContainer')};
          }
        `
      default:
        break
    }

    return
  })}

  box-shadow: 0px ${elevation('small')};
  border-radius: ${shape('small')};
  padding: ${spacing(2.5)} ${spacing(4)};
`

export const Snackbar = ({
  variant = 'positive',
  open,
  children,
  closeable = true,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  },
  onClose,
  autoHideDuration = 6000,
  autoPosition = true,
  ...props
}: SnackbarProps) => (
  <SnackbarWrapper
    anchorOrigin={anchorOrigin}
    open={open}
    autoHideDuration={autoHideDuration}
    onClose={onClose}
    autoPosition={autoPosition}
    {...props}
  >
    <SnackbarContent variant={variant} flexDirection="row" alignItems="center" gap={6}>
      <Text variant="body2">{children}</Text>
      {closeable && (
        <Button hierarchy="secondary" size="small" variant="text" onClick={onClose} p={0} style={{ height: 'auto' }}>
          <Text variant="caption">Fechar</Text> <Close fontSize="small" />
        </Button>
      )}
    </SnackbarContent>
  </SnackbarWrapper>
)
