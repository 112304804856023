import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetAffiliates } from '../../hooks/use-get-affiliates'
import { ConfirmExportModal } from '../../components/confirm-export-modal'
import { TableLoading } from './table-loading'

import { Table, TableBody, TableCell, TableHead, TableHeader, TablePagination, TableRow } from '@hub-la/shadcn'

export const AffiliatesTable = () => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(25)
  const { data, isFetching } = useGetAffiliates({ page, pageSize })

  const handleModalVisibility = (isOpen: boolean) => setIsModalOpen(isOpen)

  const headCells = [
    { key: 'name', label: 'Nome', sortable: false, width: 50 },
    { key: 'product', label: 'Produto', sortable: false, width: 130 },
  ]

  const isRowsEmpty = !data?.items || data.items.length === 0
  const canRenderEmptyState = !isFetching && isRowsEmpty

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-baseline justify-between">
        <p className="text-sm">{data?.total} afiliados</p>

        <ConfirmExportModal isOpen={isModalOpen} handleVisibility={handleModalVisibility} />
      </div>

      <div className="flex flex-col gap-4">
        <Table>
          <TableHeader>
            <TableRow>
              {headCells.map((cell) => (
                <TableHead key={cell.key} style={{ width: cell.width }}>
                  {cell.label}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>

          <TableBody>
            {isFetching && isRowsEmpty
              ? Array.from({ length: pageSize }).map((_, i) => <TableLoading key={`skeleton-row-${i}`} />)
              : (data.items || []).map((affiliate) => (
                  <TableRow key={affiliate.id}>
                    <TableCell>{affiliate.user.name}</TableCell>

                    <TableCell>
                      <div className="flex flex-col">
                        <span>{affiliate.product?.name}</span>
                        <span className="text-xs text-gray-500">{affiliate.product?.id}</span>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>

        {canRenderEmptyState && (
          <div className="text-center py-4">
            <p>{t('empty')}</p>
          </div>
        )}

        {!isRowsEmpty && (
          <TablePagination
            page={page}
            setPage={setPage}
            lastPage={data.lastPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        )}
      </div>
    </div>
  )
}
