import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Alert, AlertDescription, AlertTitle, Button } from '@hub-la/shadcn'
import { RefreshCcw } from 'lucide-react'
import { useGetPendencies } from '../../../hooks/use-get-pendencies'

const PendenciesAlert = ({
  length,
  type,
  isDestructive,
}: {
  isDestructive?: boolean
  length: number
  type: 'overdue' | 'unpaid'
}) => {
  const history = useHistory()
  const { t } = useTranslation()

  const redirectToPendenciesPage = () => history.push('/user_pendencies')

  return (
    <Alert variant={isDestructive ? 'destructive' : 'default'} className="space-y-2">
      <AlertTitle>{t(`${type}.title`)}</AlertTitle>
      <AlertDescription>{t(`${type}.subtitle`, { length })}</AlertDescription>
      <Button variant="link" className="p-0 h-auto font-bold" onClick={redirectToPendenciesPage}>
        {t(`${type}.cta`, { length })}
      </Button>
    </Alert>
  )
}

export const Pendencies: React.FC = () => {
  const { data } = useGetPendencies()
  const overdueLength = data?.overdue.length ?? 0
  const unpaidLength = data?.unpaid.length ?? 0

  return (
    <div className="flex flex-col gap-4">
      {overdueLength > 0 && <PendenciesAlert length={overdueLength} isDestructive type="overdue" />}
      {unpaidLength > 0 && <PendenciesAlert length={unpaidLength} type="unpaid" />}
    </div>
  )
}
