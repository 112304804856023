import {
  Label,
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TablePagination,
  TableRow,
} from '@hub-la/shadcn'
import isEmpty from 'lodash/isEmpty'
import times from 'lodash/times'
import { Barcode, CreditCard, QrCode } from 'lucide-react'
import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Invoice } from '../../../../domain/dtos/invoice'
import { InvoiceStatus } from '../../../../domain/enums/invoice-status'
import { InvoiceType } from '../../../../domain/enums/invoice-type'
import { OrderDirection } from '../../../../domain/enums/order-direction'
import { PaymentMethod } from '../../../../domain/enums/payment-method'
import { formatCurrency } from '../../../../domain/vos/format-currency'
import { InvoiceStatusStats } from '../../../components/invoice-status-stats'
import { SmartInstallmentChip } from '../../../components/smart-installment-chip'
import { useGetInvoices } from '../../../hooks/use-get-invoices'
import { useRefetch } from '../../../hooks/use-refetch'
import { TableLoading } from './table-loading'

export const InvoicesTable = ({ filters, setFilters, offers, isLoading }) => {
  const { page, pageSize } = filters

  /** @todo: fazer o sorting da coluna */
  const [orderDirection, setOrderDirection] = useState<OrderDirection>(OrderDirection.DESC)
  const { t } = useTranslation()
  const history = useHistory()

  const {
    data = { items: [], total: 0, page, pageSize, lastPage: 1 },
    isFetching,
    refetch,
  } = useGetInvoices(
    {
      offers,
      offerIds: filters.offerIds,
      statuses: filters.statuses as InvoiceStatus[],
      types: filters.types as InvoiceType[],
      methods: filters.methods as PaymentMethod[],
      startDate: filters.startDate,
      endDate: filters.endDate,
      page: filters.page,
      pageSize: parseInt(filters.pageSize, 10),
      search: filters.search,
      utmSource: filters.utmSource,
      utmMedium: filters.utmMedium,
      utmCampaign: filters.utmCampaign,
      utmContent: filters.utmContent,
      utmTerm: filters.utmTerm,
      orderDirection,
    },
    isLoading,
  )

  useRefetch({ offers, ...filters, orderDirection }, refetch)

  const getIcon = (paymentMethod: PaymentMethod) => {
    switch (paymentMethod) {
      case PaymentMethod.PIX:
        return <QrCode className="h-4 w-4 mr-2" />
      case PaymentMethod.CREDIT_CARD:
        return <CreditCard className="h-4 w-4 mr-2" />
      case PaymentMethod.BOLETO:
        return <Barcode className="h-4 w-4 mr-2" />
      default:
        return
    }
  }

  const isRowsEmpty = isEmpty(data?.items)
  const canRenderEmptyState = !isFetching && !isLoading && isRowsEmpty

  return (
    <div className="flex flex-col gap-4">
      <div className="rounded-md border bg-white">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Valor total</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>ID da fatura</TableHead>
              <TableHead>Cliente</TableHead>
              <TableHead>Produto</TableHead>
              <TableHead>Data da criação</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {(isFetching && isRowsEmpty) || isLoading
              ? times(pageSize, (idx) => <TableLoading key={idx} />)
              : (data?.items ?? []).map((invoice: Invoice) => (
                  <TableRow
                    key={invoice.id}
                    onClick={() => history.push(`sales/${invoice.id}`)}
                    className="cursor-pointer"
                  >
                    <TableCell className="py-2.5">
                      <div className="flex items-center space-x-1">
                        {getIcon(invoice.paymentMethod)}
                        <Label className="text-base font-medium">
                          {formatCurrency(invoice.amount.totalCents / 100)}
                        </Label>
                      </div>
                    </TableCell>
                    <TableCell className="py-2.5">
                      <div className="flex items-center gap-1">
                        <InvoiceStatusStats status={invoice.status} />
                        {invoice.smartInstallmentRef && (
                          <SmartInstallmentChip installment={invoice.installment} installments={invoice.installments} />
                        )}
                      </div>
                    </TableCell>
                    <TableCell className="py-2.5 max-w-[180px] truncate">
                      <Label className="text-xs ">{invoice.id}</Label>
                    </TableCell>
                    <TableCell className="py-2.5">
                      <Label className="text-sm">{invoice.payer?.identity?.fullName}</Label>
                    </TableCell>
                    <TableCell className="py-2.5">
                      <Label className="text-sm">{invoice.items.at(0)?.productName}</Label>
                    </TableCell>
                    <TableCell className="py-2.5">
                      <Label className="text-sm">{moment(invoice.createdAt).format('D MMM. YYYY')}</Label>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
          {canRenderEmptyState && (
            <TableCaption>
              <div className="py-4 Label-center">
                <Label className="text-sm">{t('empty')}</Label>
                <Label className="text-sm">{t('emptySubtitle')}</Label>
              </div>
            </TableCaption>
          )}
        </Table>
      </div>
      <div className="flex justify-end px-2 mt-3 mb-3">
        {!isRowsEmpty && (
          <TablePagination
            page={page}
            setPage={(page) => setFilters({ ...filters, page })}
            lastPage={data.lastPage}
            pageSize={pageSize}
            setPageSize={(pageSize) => setFilters({ ...filters, pageSize })}
          />
        )}
      </div>
    </div>
  )
}
