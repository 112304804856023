import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GetSmartInstallmentsSummariesInput } from '../domain/dtos/get-smart-installments-summaries-input'
import { GetSmartInstallmentsSummariesOutput } from '../domain/dtos/get-smart-installments-summaries-output'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'
import { GetOfferIds } from './get-offer-ids'

export class GetSmartInstallmentsSummaries {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(
    input: GetSmartInstallmentsSummariesInput,
    signal?: AbortSignal,
  ): Promise<GetSmartInstallmentsSummariesOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/smart-installments/summaries`,
      body: this.makeBody(input),
      signal,
    })

    if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
      return response.data
    }

    throw new GeneralError()
  }

  private makeBody(input: GetSmartInstallmentsSummariesInput) {
    const productIds = new GetOfferIds().execute({ offerIds: input.offerIds, offers: input.offers })
    const body = {
      offerIds: productIds,
      filters: {
        status: input.statuses,
        search: input.search,
      },
      page: input.page,
      pageSize: input.pageSize,
      orderBy: input.orderBy,
      orderDirection: input.orderDirection,
    }

    return body
  }
}
