import { Button } from '@hub-la/shadcn'
import { ExternalLink } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { IntegrationProvider } from '../../../domain/dtos/integration-provider'

type ENotasCardActionsProps = {
  provider: IntegrationProvider
  setTab: (name: string) => void
}

export const ZapierCardActions = ({ provider }: ENotasCardActionsProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <div className="w-full flex flex-col items-center justify-between gap-2">
      <Button className="w-full" onClick={() => history.push({ pathname: `/settings#zapierapi-integration` })}>
        {t(`buttons.activeIntegration`)}
      </Button>

      <a
        className="flex items-center gap-2 font-800 text-muted-foreground"
        href={t(`integrations.${provider}.about.link`)}
        target="_blank"
        rel="noreferrer"
      >
        {t('aboutTab.seeMore')}

        <ExternalLink fontSize="small" />
      </a>
    </div>
  )
}
