import { Button, Calendar, Popover, PopoverContent, PopoverTrigger } from '@hub-la/shadcn'
import { CalendarIcon } from 'lucide-react'
import moment from 'moment'

interface DateRange {
  startDate?: Date
  endDate?: Date
}

interface SelectDateRangeCalendar {
  onChange: (value?: DateRange) => void
  value: DateRange
  placeholder?: string
}

const formatPlaceholder = (dateRange: DateRange, placeholder?: string) => {
  if (dateRange.startDate && dateRange.endDate) {
    const formattedStartDate = moment(dateRange.startDate).format('D MMM. YYYY')
    const formattedEndDate = moment(dateRange.endDate).format('D MMM. YYYY')
    return `${formattedStartDate} a ${formattedEndDate}`
  }

  if (dateRange.startDate) {
    const formattedStartDate = moment(dateRange.startDate).format('D MMM. YYYY')
    return `${formattedStartDate}`
  }

  return placeholder ?? 'Período'
}

export const SelectDateRangeCalendar = (props: SelectDateRangeCalendar) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline">
          <CalendarIcon className="mr-2 h-4 w-4" />
          <span>{formatPlaceholder(props.value, props.placeholder)}</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="range"
          selected={{ from: props.value.startDate, to: props.value.endDate }}
          defaultMonth={props.value.startDate}
          numberOfMonths={2}
          onSelect={(value) => props.onChange({ startDate: value?.from, endDate: value?.to })}
        />
      </PopoverContent>
    </Popover>
  )
}
