import { useAuth } from '@hub-la/fe-auth'
import { useContainer } from '@hub-la/fe-container'
import { Analytics, AnalyticsEvent } from '@hub-la/fe-core-analytics'
import { Button, useIsMobile } from '@hub-la/shadcn'
import { Loader2, QrCode, Send } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { UserVerificationTelegramStep } from '../../../domain/enums/user-verification-telegram-step'
import { makeTrackingEventData } from '../../../domain/mappers/make-tracking-event-data'
import { Envs } from '../../../envs'
import VerificationStartDesk from '../../assets/verification-start-desk.svg'
import VerificationStartMobile from '../../assets/verification-start-mobile.svg'
import { LinkHelperFAQ } from '../../components/link-helper-faq'
import { NavigationButton } from '../../components/navigation-button'
import { NotGroupMember } from '../../components/not-group-member'
import { useGetTelegramCode } from '../../hooks/use-get-telegram-code'
import { useGetUserVerification } from '../../hooks/use-get-user-verification'
import { useUpdateVerificationTelegramStep } from '../../hooks/use-update-verification-telegram-step'

export const VerificationStartPage = (): JSX.Element => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const history = useHistory()
  const container = useContainer()
  const analytics = container.get(Analytics)

  const { currentUser } = useAuth()
  const { data: user, isLoading: isUserLoading } = useGetUserVerification(currentUser?.id)
  const updateStepAction = useUpdateVerificationTelegramStep()
  const { data: telegramCode } = useGetTelegramCode()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isAwaiting, setIsAwaiting] = useState<boolean>(true)
  const step = user?.steps?.verificationTelegram
  const urlSearch = useLocation().search
  const groupId = new URLSearchParams(urlSearch).get('groupId')

  const url = telegramCode ? `https://t.me/${Envs.TELEGRAM_BOT}?start=${telegramCode}` : undefined

  const trackingParams = {
    groupId: groupId,
    userId: user?.id,
    userEmail: user?.email ?? undefined,
    telegramId: user?.ims?.telegram ?? undefined,
    whatsappId: user?.ims?.whatsapp ?? undefined,
  }

  useEffect(() => {
    if (!isUserLoading && user) {
      if (!step) {
        history.push({
          pathname: '/verification-telegram/intro',
          search: urlSearch,
        })
      }

      switch (step) {
        case UserVerificationTelegramStep.intro:
          setIsLoading(false)
          setIsAwaiting(false)
          dispatchUpdateStep(UserVerificationTelegramStep.onboarding)
          break
        case UserVerificationTelegramStep.onboarding:
          setIsLoading(false)
          setIsAwaiting(false)
          break
        case UserVerificationTelegramStep.pending:
          setIsLoading(true)
          setIsAwaiting(true)
          setTimeout(() => {
            setIsLoading(false)
            setIsAwaiting(false)
          }, 3000)
          break
        case UserVerificationTelegramStep.completed:
          setIsLoading(false)
          setIsAwaiting(false)
          history.push({
            pathname: '/verification-telegram/done',
            search: urlSearch,
          })
          break
        default:
          setIsLoading(false)
          setIsAwaiting(false)
          break
      }
    }
  }, [user, isUserLoading, step, history, urlSearch])

  const dispatchUpdateStep = (step: UserVerificationTelegramStep, next?: string) => {
    updateStepAction.mutateAsync(step).then(() => {
      if (next) {
        window.location.href = next
      }
    })
  }

  const handleClickStartWeb = () => {
    const telegramUrl = url
    if (telegramUrl) {
      setIsLoading(true)
      setIsAwaiting(true)
      dispatchUpdateStep(UserVerificationTelegramStep.pending, telegramUrl)
      analytics.track(AnalyticsEvent.VERIFICATION_TELEGRAM.START.WEB_CLICKED, makeTrackingEventData(trackingParams))
    }
  }

  const handleClickStartQRCode = () => {
    analytics.track(AnalyticsEvent.VERIFICATION_TELEGRAM.START.QRCODE_CLICKED, makeTrackingEventData(trackingParams))
    history.push({
      pathname: '/verification-telegram/qrcode',
      search: urlSearch,
    })
  }

  const handleClickNav = () => {
    analytics.track(AnalyticsEvent.VERIFICATION_TELEGRAM.START.NAV_BACK_CLICKED, makeTrackingEventData(trackingParams))
    history.push({
      pathname: '/verification-telegram/intro',
      search: urlSearch,
    })
  }

  const handleClickFAQ = () => {
    analytics.track(AnalyticsEvent.VERIFICATION_TELEGRAM.START.FAQ_CLICKED, makeTrackingEventData(trackingParams))
  }

  if (isUserLoading || !user) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="h-12 w-12 animate-spin" />
      </div>
    )
  }

  return (
    <div className="flex flex-col flex-1 h-screen" data-testid="verification-start-container">
      <div className="flex flex-col md:flex-row flex-1">
        <div className={`flex flex-col flex-1 w-full md:w-1/2 p-2 md:p-3 ${isMobile ? 'bg-[#F7F8F2]' : 'bg-white'}`}>
          <div className="flex flex-col items-start justify-center">
            <NavigationButton onClick={handleClickNav} />
          </div>
          <div className="flex flex-col flex-1 items-center justify-center">
            <div className="flex flex-col flex-1 space-y-3 justify-center w-full md:w-[400px]">
              {isMobile && (
                <div className="flex items-center justify-center">
                  <img className="h-[200px]" src={VerificationStartMobile} alt="verification start mobile" />
                </div>
              )}
              <h1 className={`text-left text-[#161F00] font-bold ${isMobile ? 'text-xl' : 'text-4xl'}`}>
                {t('verificationTelegram.start.title')}
              </h1>
              <p className="text-left text-[#747968]">{t('verificationTelegram.start.subtitle')}</p>
              {!isMobile && (
                <div className="flex flex-col space-y-2 items-start justify-center">
                  <Button
                    variant="outline"
                    size="lg"
                    className={`w-full font-bold text-base border-[#314000] ${isAwaiting ? '' : 'text-[#314000]'}`}
                    loading={isLoading}
                    onClick={handleClickStartWeb}
                  >
                    {isAwaiting
                      ? t('verificationTelegram.start.buttonAwaiting')
                      : t('verificationTelegram.start.buttonStartWeb')}
                    <Send className="ml-2 h-4 w-4" />
                  </Button>
                  {!isAwaiting && (
                    <Button
                      variant="outline"
                      size="lg"
                      className="w-full font-bold text-base text-[#314000] border-[#314000]"
                      onClick={handleClickStartQRCode}
                    >
                      {t('verificationTelegram.start.buttonStartQRCode')}
                      <QrCode className="ml-2 h-4 w-4" />
                    </Button>
                  )}
                  <LinkHelperFAQ onClick={handleClickFAQ} />
                </div>
              )}
            </div>
          </div>

          {isMobile && (
            <div className="flex flex-col space-y-2 items-center justify-center">
              <Button
                variant="default"
                size="lg"
                className="w-full font-bold text-base text-[#314000] border-[#314000]"
                loading={isLoading}
                onClick={handleClickStartWeb}
              >
                {isAwaiting
                  ? t('verificationTelegram.start.buttonAwaiting')
                  : t('verificationTelegram.start.buttonStart')}
                <Send className="ml-2 h-4 w-4" />
              </Button>
              <LinkHelperFAQ onClick={handleClickFAQ} />
            </div>
          )}
        </div>
        {!isMobile && (
          <div className="flex flex-col md:flex-row flex-1 w-full md:w-1/2 bg-[#F7F8F2]">
            <div className="flex flex-col flex-1 items-start justify-center">
              <img src={VerificationStartDesk} alt="verification start desktop" className="-ml-[50px]" />
            </div>
          </div>
        )}
      </div>
      {groupId && <NotGroupMember groupId={groupId} />}
    </div>
  )
}
