import { Card, CardContent, cn } from '@hub-la/shadcn'
import React from 'react'

interface PriceTypeSelectCardProps {
  title: string
  selected: boolean
  onSelect: () => void
  icon: React.ReactNode
  disabled?: boolean
}

export const PriceTypeSelectCard: React.FC<PriceTypeSelectCardProps> = ({
  title,
  selected,
  onSelect,
  icon,
  disabled = false,
}) => {
  return (
    <Card
      className={cn(
        'flex items-center shadow-none p-6 w-full cursor-pointer transition-colors',
        selected
          ? 'bg-popover text-accent-foreground border-2 border-accent-foreground'
          : 'border-gray-300 text-gray-600 bg-popover hover:bg-gray-100',
        disabled && 'opacity-50 cursor-not-allowed',
      )}
      onClick={disabled ? undefined : onSelect}
      role="button"
      aria-disabled={disabled}
    >
      <CardContent className="items-center p-0">
        {icon}
        <span className="text-base">{title}</span>
      </CardContent>
    </Card>
  )
}
