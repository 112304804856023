import { memo } from 'react'
import { SmartInstallmentAmountDetail } from '../../../domain/dtos/smart-installment-amount-detail'
import { InvoiceStatus } from '../../../domain/enums/invoice-status'
import { PlanType } from '../../../domain/enums/plan-type'
import { SubscriptionStatus } from '../../../domain/enums/subscription-status'
import { SubscriptionType } from '../../../domain/enums/subscription-type'
import { formatCurrency } from '../../../domain/vos/format-currency'
import { useGetSmartInstallment } from '../../hooks/use-get-smart-installment'
import { useGetSmartInstallmentInvoices } from '../../hooks/use-get-smart-installment-invoices'
import { Description } from './description'
import { Details } from './details'
import { Header } from './header'
import { InvoiceTable } from './invoice-table'
import { Loading } from './loading'
import { SmartInstallmentPayerDetails } from './smart-intallment-payer-details'
import { SmartInstallmentTimeline } from './smart-installment-timeline'
import { SmartInstallmentInvoiceEvent } from '../../../domain/dtos/smart-installment-invoice'
import { useFeatureFlag } from '@hub-la/fe-core-feature-flag'
import { useGetUser } from '@hub-la/fe-get-user'
import { CustomerCard } from './customer-card'
import { SmartInstallmentInvoiceRelations } from './smart-intallment-invoice-relations'
import { ScrollArea, Separator } from '@hub-la/shadcn'

export const SmartInstallmentDetail: React.FC<{ id: string; onClose: () => void }> = memo(({ id, onClose }) => {
  const { data: smartInstallment, refetch: refetchSmartInstallment } = useGetSmartInstallment(id)
  const { data = { items: [], total: 0, page: 1, pageSize: 50, lastPage: 1 } } = useGetSmartInstallmentInvoices({
    smartInstallmentId: id,
    page: 1,
    pageSize: 50,
  })

  const { isFlagEnabled } = useFeatureFlag()
  const { data: currentUser } = useGetUser()
  const showTimeline = isFlagEnabled('isValidUserToShowTimeline', {
    id: currentUser?.id,
  })

  const events = data.items
    .map((smartInstallmentInvoice) => smartInstallmentInvoice.events)
    .filter((events) => !!events)
    .flat() as SmartInstallmentInvoiceEvent[][]

  const firstScheduled = data.items.find((item) => item.status === InvoiceStatus.DRAFT)
  const finalAmountDetail: SmartInstallmentAmountDetail | undefined =
    firstScheduled && smartInstallment
      ? {
          products:
            smartInstallment.invoiceRef?.amountDetail?.products?.map((product) => ({
              productName: product.productName,
              priceCents: firstScheduled.amount.subtotalCents + firstScheduled.amount.discountCents,
              quantity: product.quantity ?? 1,
            })) ?? [],
          discountCents: firstScheduled.amount.discountCents,
          couponCode: smartInstallment.invoiceRef?.amountDetail?.couponCode,
          installmentFeeCents: firstScheduled.amount.installmentFeeCents,
          totalCents: firstScheduled.amount.totalCents,
          nextDueAt: firstScheduled.dueDate || firstScheduled.scheduleEta,
        }
      : undefined

  const smartInstallmentCredits =
    (smartInstallment?.subscriptionRef?.credits ?? 0) + (smartInstallment?.creditsRemaining ?? 0)

  const creditsRemaining =
    smartInstallment?.subscriptionRef?.status === SubscriptionStatus.STATUS_ACTIVE ? smartInstallmentCredits : 0

  if (!smartInstallment) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <Loading />
  }

  return (
    <ScrollArea className="w-full p-6 md:p-4 overflow-auto h-full">
      <div className="space-y-4 mb-8">
        <Header
          total={formatCurrency(smartInstallment.amountTotalCents / 100)}
          status={smartInstallment.status}
          installment={smartInstallment.installment}
          installments={smartInstallment.installments}
          id={smartInstallment.id}
          onChange={refetchSmartInstallment}
          role={smartInstallment.role}
          onClose={onClose}
        />
        <Separator />
        <SmartInstallmentPayerDetails smartInstallment={smartInstallment} />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-8">
          {finalAmountDetail && (
            <div>
              <Description detail={finalAmountDetail} role={smartInstallment.role} />
            </div>
          )}

          {showTimeline && !!events.length && (
            <>
              <div>{showTimeline && !!events.length && <SmartInstallmentTimeline events={events} />}</div>
            </>
          )}

          <InvoiceTable smartInstallmentId={smartInstallment.id} status={smartInstallment.status} />

          <SmartInstallmentInvoiceRelations
            products={(smartInstallment.invoiceRef?.items ?? []).map((item) => ({
              name: item.productName,
              id: item.offerId,
            }))}
            subscription={{
              credits: creditsRemaining,
              id: smartInstallment.subscriptionRef?.id ?? '',
              plan: smartInstallment.subscriptionRef?.planType ?? PlanType.MONTHLY,
              status: smartInstallment.subscriptionRef?.status ?? SubscriptionStatus.STATUS_ACTIVE,
              type: smartInstallment.subscriptionRef?.type ?? SubscriptionType.TYPE_ONE_TIME,
            }}
          />
        </div>

        <div className="space-y-8">
          <CustomerCard
            name={smartInstallment.payer?.identity?.fullName ?? ''}
            id={smartInstallment.payer?.id ?? ''}
            email={smartInstallment.payer?.identity?.email ?? ''}
            phone={smartInstallment.payer?.identity?.phone ?? ''}
          />
          <Details
            detail={{
              createdAt: smartInstallment.createdAt,
              id: smartInstallment.id ?? '',
              type: smartInstallment.invoiceRef?.type,
              nextDueAt: smartInstallment.nextDueAt ?? undefined,
            }}
          />
        </div>
      </div>
    </ScrollArea>
  )
})
