import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'
import { Offer } from '../../../../domain/dtos/offer'
import { formatLocalNumber } from '../../../../domain/vos/format-local-number'
import { InfoTooltip } from '../../../components/info-tooltip'
import { StatusBox } from '../../../components/status-box'
import { useGetAbandonedCheckout } from '../../../hooks/use-get-abandoned-checkout'
import { useRefetch } from '../../../hooks/use-refetch'
import { ConfirmExportModal } from './confirm-export-modal'
import { Loading } from './loading'

type AbandonedCheckoutProps = {
  startDate: string
  endDate: string
  offerIds: string[]
  offers: Offer[]
  isLoading: boolean
}

export const AbandonedCheckout = (props: AbandonedCheckoutProps) => {
  const { startDate, endDate, offers, offerIds, isLoading } = props
  const { t } = useTranslation()

  const { data, isFetching, refetch } = useGetAbandonedCheckout({ startDate, endDate, offers, offerIds }, isLoading)
  const isLoadingData = isFetching && data.current === 0

  useRefetch({ startDate, endDate, offers, offerIds }, refetch)

  if (isLoadingData || isLoading) {
    return <Loading />
  }

  return (
    <Card className="w-full h-full">
      <CardContent className="p-6">
        <div className="flex flex-col lg:flex-row justify-between gap-4">
          <div className="w-full">
            <CardHeader className="p-0 space-y-0">
              <CardDescription className="flex items-center gap-1">
                {t('abandonedCheckout.title')}
                <InfoTooltip title={t('abandonedCheckout.description')} />
              </CardDescription>
              <CardTitle className="text-4xl font-medium mt-1">{formatLocalNumber(data.current)}</CardTitle>
            </CardHeader>
            <div className="mt-1">
              <StatusBox
                status={data.status}
                description={t('variation', { previous: formatLocalNumber(data.previous) })}
              />
            </div>
          </div>
          <div className="w-full lg:w-auto">
            <ConfirmExportModal filters={{ startDate, endDate, offerIds }} offers={offers} />
          </div>
        </div>
      </CardContent>
    </Card>
  )
}
