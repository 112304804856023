import { AuthProvider, AuthRedirectProvider, SignInPage as AuthSignInPage } from '@hub-la/fe-auth'

export const SignInInit = () => {
  return (
    <AuthProvider>
      <AuthRedirectProvider>
        <AuthSignInPage />
      </AuthRedirectProvider>
    </AuthProvider>
  )
}
