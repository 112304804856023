import { AuthProvider, AuthRedirectProvider, SignInWhatsappPage } from '@hub-la/fe-auth'

export const SignInWhatsappInit = () => {
  return (
    <AuthProvider>
      <AuthRedirectProvider>
        <SignInWhatsappPage />
      </AuthRedirectProvider>
    </AuthProvider>
  )
}
