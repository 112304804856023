import { withAccountVerification, withApprovedPayout } from '@hub-la/fe-account-verification'
import { Button, useIsMobile } from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'
import { GetBalanceOutput } from '../../../../domain/dtos/get-balance-output'
import { BalanceTile } from './balance-tile'
import { Loading } from './loading'

type BalancesProps = {
  data?: GetBalanceOutput
  isFetching: boolean
  onWithdrawRequest: () => void
}

const ButtonWithApprovedPayout = withApprovedPayout(
  withAccountVerification(Button, { tooltip: 'Você precisa cadastrar uma conta bancária para realizar saques' }),
  { tooltip: 'Você precisa ativar sua conta para realizar saques' },
)

export const Balances = ({ data, isFetching, onWithdrawRequest }: BalancesProps) => {
  const hasBalanceReserve = (data?.reservedInCents ?? 0) > 0
  const hasTransferableBalance = (data?.transferableInCents ?? 0) > 0
  const hasContestedBalance = (data?.contestedInCents ?? 0) > 0
  const numSecondaryTiles = 1 + Number(hasBalanceReserve) + Number(hasTransferableBalance)

  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const getTilePaddingY = (n: number) => (isMobile ? 24 : 24 - (n - 1) * 8)
  const tilesPaddingY = getTilePaddingY(numSecondaryTiles)

  if (!data || isFetching) {
    return <Loading />
  }

  return (
    <div className="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0">
      <BalanceTile
        data-testid="available-balance-tile"
        label={t('balances.available.label')}
        valueInCents={data?.availableInCents ?? 0}
        tooltip={t('balances.available.tooltip')}
        variant="active-main"
        cta={
          <ButtonWithApprovedPayout onClick={onWithdrawRequest} variant="default">
            {t('balances.requestWithdraw')}
          </ButtonWithApprovedPayout>
        }
      />

      <BalanceTile
        data-testid="receivable-balance-tile"
        label={t('balances.receivable.label')}
        valueInCents={data?.receivableInCents ?? 0}
        tooltip={t('balances.receivable.tooltip')}
        variant="neutral-main"
      />

      <div className="flex flex-col space-y-2">
        {hasContestedBalance && (
          <BalanceTile
            data-testid="contested-balance-tile"
            label={t('balances.contested.label')}
            valueInCents={data?.contestedInCents ?? 0}
            tooltip={t('balances.contested.tooltip')}
            variant={numSecondaryTiles === 1 ? 'neutral-main' : 'secondary'}
            paddingY={tilesPaddingY}
          />
        )}

        {hasBalanceReserve && (
          <BalanceTile
            data-testid="reserved-balance-tile"
            label={t('balances.reserved.label')}
            valueInCents={data?.reservedInCents ?? 0}
            tooltip={t('balances.reserved.tooltip')}
            paddingY={tilesPaddingY}
          />
        )}

        {hasTransferableBalance && (
          <BalanceTile
            data-testid="transferable-balance-tile"
            label={t('balances.transferable.label')}
            valueInCents={data?.transferableInCents ?? 0}
            tooltip={t('balances.transferable.tooltip')}
            paddingY={tilesPaddingY}
          />
        )}
      </div>
    </div>
  )
}
