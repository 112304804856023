import moment from 'moment'
import { ColumnDef } from '@tanstack/react-table'
import { Subscription } from '../../../domain/dtos/subscription'
import { DataTableColumnHeader } from '../../components/data-table-column-header'
import { Checkbox } from '@hub-la/shadcn'
import { SubscriptionStatusStats } from '../../components/subscription-status-stats'
import { SubscriptionStatus } from '../../../domain/enums/subscription-status'

export const columns: ColumnDef<Subscription>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="mt-1"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className="mt-1"
        onClick={(e) => e.stopPropagation()}
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'payer.identity.fullName',
    header: 'Cliente',
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row }) => {
      const status: SubscriptionStatus = row.getValue('status')

      return <SubscriptionStatusStats status={status} />
    },
  },
  {
    accessorKey: 'id',
    header: 'ID da assinatura',
  },
  {
    accessorKey: 'items',
    header: 'Produto',
    cell: ({ row }) => {
      const items: Pick<Subscription, 'items'> = row.getValue('items')

      if (!items) {
        return null
      }

      return <>{items[0].productName}</>
    },
  },
  {
    accessorKey: 'createdAt',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Data da criação" />,
    cell: ({ row }) => {
      const createdAt: string = row.getValue('createdAt')

      if (!createdAt) {
        return null
      }

      return <>{moment(createdAt).format('D MMM. YYYY')}</>
    },
  },
  {
    accessorKey: 'nextDueAt',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Data de vencimento" />,
    cell: ({ row }) => {
      const nextDueAt: string = row.getValue('nextDueAt')

      if (!nextDueAt) {
        return <>-</>
      }

      return <>{moment(nextDueAt).format('D MMM. YYYY')}</>
    },
  },
]
