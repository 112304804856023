import { container } from '@hub-la/fe-container'
import { DashboardSettings, DashboardSettingsProvider } from '@hub-la/fe-dashboard-settings'

export const DashboardSettingsInit = () => {
  return (
    <DashboardSettingsProvider container={container}>
      <DashboardSettings />
    </DashboardSettingsProvider>
  )
}
