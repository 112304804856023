import { Card, CardContent, cn, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@hub-la/shadcn'
import { InfoIcon } from 'lucide-react'
import React from 'react'
import { Money } from '../../../../domain/vos/money'

type BalanceTileProps = React.HTMLAttributes<HTMLDivElement> & {
  cta?: React.ReactNode
  label: string
  paddingY?: number
  tooltip: string
  valueInCents: number
  variant?: 'active-main' | 'neutral-main' | 'secondary'
}

export const BalanceTile: React.FC<BalanceTileProps> = ({
  cta,
  label,
  paddingY = 24,
  valueInCents,
  tooltip,
  variant,
  className,
  ...rest
}) => {
  const isMain = variant?.endsWith('main')

  return (
    <Card
      className={cn(
        'flex flex-col shadow-none justify-between min-h-[60px] min-w-auto md:min-w-[420px]',
        'bg-card',
        className,
      )}
      style={{ padding: `${paddingY}px 24px` }}
      {...rest}
    >
      <CardContent className="p-0 flex flex-col justify-between h-full space-y-4">
        <div className="space-y-1">
          <div className="flex items-center space-x-1">
            <p className={cn('text-sm', 'text')}>{label}</p>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <InfoIcon className={cn('w-4 h-4', 'text')} />
                </TooltipTrigger>
                <TooltipContent>{tooltip}</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
          <p className={cn('font-medium', isMain ? 'text-3xl' : 'text-xl', 'text')}>
            {Money.build(valueInCents / 100).getValue()}
          </p>
        </div>
        {cta}
      </CardContent>
    </Card>
  )
}
