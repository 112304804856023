export const BRAZILIAN_BANKS = [
  { code: '104', name: 'Caixa Econômica Federal' },
  { code: '237', name: 'Bradesco' },
  { code: '341', name: 'Itaú Unibanco' },
  { code: '260', name: 'Nubank - Nu Pagamentos S.A.' },
  { code: '001', name: 'Banco do Brasil' },
  { code: '033', name: 'Santander' },
  { code: '212', name: 'Original' },
  { code: '518', name: 'Mercado Crédito sociedade de crédito, financiamento e investimento s.a.' },
  { code: '290', name: 'Pagbank - Pagseguro Internet S.A' },
  { code: '336', name: 'Banco C6 S.A.' },
  { code: '077', name: 'Banco Inter' },
  { code: '208', name: 'Banco BTG Pactual' },
  { code: '735', name: 'Neon' },
  { code: '655', name: 'Banco Votorantim S.A.' },
  { code: '269', name: 'HSBC Banco de Investimento' },
  { code: '348', name: 'Banco XP S.A.' },
  { code: '380', name: 'Picpay servicos s.a.' },
  { code: '246', name: 'Banco ABC Brasil' },
  { code: '465', name: 'Capital consig sociedade de crédito direto s.a.' },
  { code: '144', name: 'Bexs' },
  { code: '748', name: 'Sicredi' },
  { code: '183', name: 'Socred SCM' },
  { code: '088', name: 'Banco randon s.a.' },
  { code: '478', name: 'Gazincred s.a. sociedade de crédito, financiamento e investimento' },
  { code: '243', name: 'Banco Máxima' },
  { code: '542', name: 'Cloud walk meios de pagamentos e servicos ltda' },
  { code: '529', name: 'Pinbank brasil - pagamentos inteligentes s.a.' },
  { code: '452', name: 'Credifit sociedade de crédito direto s.a.' },
  { code: '425', name: 'Socinal S.A. - crédito, financiamento e investimento' },
  { code: '534', name: 'Ewally tecnologia e servicos s.a.' },
  { code: '362', name: 'CIELO S.A' },
  { code: '119', name: 'Banco Western Union' },
  { code: '428', name: 'Cred-system sociedade de crédito direto s.a.' },
  { code: '335', name: 'Banco Digio S.A.' },
  { code: '511', name: 'Magnum sociedade de crédito direto s.a.' },
  { code: '084', name: 'Uniprime do Norte do Paraná' },
  { code: '435', name: 'Delcred sociedade de crédito direto s.a.' },
  { code: '322', name: 'Sulcredi / Crediluz - Abelardo Luz' },
  { code: '099', name: 'Uniprime Central' },
  { code: '756', name: 'Sicoob' },
  { code: '462', name: 'Stark sociedade de crédito direto s.a.' },
  { code: '070', name: 'Banco de Brasília - BRB' },
  { code: '332', name: 'Acesso Soluções De Pagamento S.A.' },
  { code: '450', name: 'FITBANK INSTITUICAO DE PAGAMENTOS ELETRONICOS S.A.' },
  { code: '461', name: 'ASAAS IP S.A.' },
  { code: '419', name: 'Numbrs sociedade de crédito direto s.a.' },
  { code: '509', name: 'Celcoin instituicao de pagamento s.a.' },
  { code: '312', name: 'Hscm - sociedade de crédito ao microempreendedor e à empresa de pequeno porte lt' },
  { code: '359', name: 'Zema Crédito, Financiamento E Investimento' },
  { code: '427', name: 'Cooperativa de credito dos servidores da universidade federal do espirito santo' },
  { code: '407', name: 'Índigo investimentos distribuidora de títulos e valores mobiliários ltda.' },
  { code: '276', name: 'Senff Financeira' },
  { code: '281', name: 'Coopavel' },
  { code: '355', name: 'Ótimo Sociedade De Crédito Direto S.A.' },
  { code: '374', name: 'Realize crédito, financiamento e investimento s.a.' },
  { code: '382', name: 'Fidúcia sociedade de crédito ao microempreendedor e à empresa de pequeno porte l' },
  { code: '404', name: 'Sumup sociedade de crédito direto s.a.' },
  { code: '368', name: 'Banco CSF' },
  { code: '408', name: 'Bônuscred sociedade de crédito direto s.a.' },
  { code: '350', name: 'Crehnor Laranjeiras' },
  { code: '397', name: 'Listo sociedade de credito direto s.a.' },
  { code: '330', name: 'Banco Bari' },
  { code: '324', name: 'Cartos sociedade de crédito direto s.a.' },
  { code: '396', name: 'Hub pagamentos s.a' },
  { code: '753', name: 'Novo Banco Continental' },
  { code: '451', name: 'J17 - sociedade de crédito direto s/a' },
  { code: '384', name: 'Global finanças sociedade de crédito ao microempreendedor e à empresa de pequeno' },
  { code: '546', name: 'U4crypto solucoes tecnologicas e financeiras sa' },
  { code: '079', name: 'Banco Original do Agronegócio' },
  { code: '358', name: 'Midway s.a. - crédito, financiamento e investimento' },
  { code: '310', name: 'Vortx DTVM' },
  { code: '403', name: 'Cora SCD S.A.' },
  { code: '481', name: 'Superlógica sociedade de crédito direto s.a.' },
  { code: '413', name: 'Banco bv s.a.' },
  { code: '654', name: 'Banco Renner' },
  { code: '041', name: 'Banrisul' },
  { code: '010', name: 'Credicoamo' },
  { code: '402', name: 'Cobuccio sociedade de crédito direto s.a.' },
  { code: '712', name: 'Banco Ourinvest' },
  { code: '610', name: 'Banco VR' },
  { code: '218', name: 'Banco BS2' },
  { code: '633', name: 'Banco Rendimento' },
  { code: '273', name: 'Sulcredi - São Miguel' },
  { code: '707', name: 'Daycoval' },
  { code: '089', name: 'Cooperativa de Crédito Rural da Região da Mogiana' },
  { code: '318', name: 'Banco BMG' },
  { code: '069', name: 'Crefisa' },
  { code: '653', name: 'Banco Indusval' },
  { code: '637', name: 'Banco Sofisa' },
  { code: '623', name: 'Banco Pan' },
  { code: '224', name: 'Banco Fibra' },
  { code: '630', name: 'Banco Smartbank S.A.' },
  { code: '422', name: 'Banco Safra' },
  { code: '213', name: 'Banco Arbi' },
  { code: '125', name: 'Brasil plural' },
  { code: '604', name: 'Banco Industrial' },
  { code: '612', name: 'Banco Guanabara' },
  { code: '136', name: 'Unicred' },
  { code: '329', name: 'QI Sociedade De Crédito Direto S.A.' },
  { code: '326', name: 'Parati CFI S.A.' },
  { code: '746', name: 'Banco Modal' },
  { code: '430', name: 'Cooperativa de credito rural seara - crediseara' },
  { code: '021', name: 'Banestes' },
  { code: '401', name: 'Iugu servicos na internet s/a' },
  { code: '383', name: 'Juno' },
  { code: '634', name: 'Banco Triângulo' },
  { code: '468', name: 'Portoseg s.a. - credito, financiamento e investimento' },
  { code: '421', name: 'Lar cooperativa de crédito - lar credi' },
  { code: '389', name: 'Mercantil do Brasil' },
  { code: '274', name: 'Money Plus SCMEPP' },
  { code: '197', name: 'Stone Pagamentos' },
  { code: '254', name: 'Banco Parana' },
  { code: '301', name: 'BPP Instituição de Pagamento S.A.' },
  { code: '096', name: 'Banco BM&FBovespa' },
  { code: '047', name: 'Banese' },
  { code: '323', name: 'Mercado Pago' },
  { code: '133', name: 'Confederação Nac das CCC SOL' },
  { code: '412', name: 'Banco Capital' },
  { code: '095', name: 'Banco Confidence de Câmbio' },
  { code: '444', name: 'Trinus sociedade de crédito direto s.a.' },
  { code: '536', name: 'Neon pagamentos s.a.' },
  { code: '121', name: 'Banco Agibank S.A' },
  { code: '340', name: 'Super Pagamentos' },
  { code: '130', name: 'Caruana SCFI' },
  { code: '364', name: 'Efí S.A' },
  { code: '082', name: 'Banco Topazio' },
  { code: '004', name: 'Banco do Nordeste' },
  { code: '085', name: 'Cooperativa Central de Crédito Urbano' },
  { code: '159', name: 'Casa do Crédito' },
  { code: '037', name: 'Banco do Estado do Pará' },
  { code: '003', name: 'Banco da Amazônia' },
  { code: '012', name: 'Banco Inbursa' },
  { code: '016', name: 'Creditran' },
  { code: '097', name: 'CCC Noroeste Brasileiro' },
  { code: '406', name: 'Accredito - sociedade de crédito direto s.a.' },
  { code: '439', name: 'Id corretora de títulos e valores mobiliários s.a.' },
  { code: '025', name: 'Banco Alfa' },
  { code: '537', name: 'Microcash sociedade de crédito ao microempreendedor e à empresa de pequeno porte' },
  { code: '757', name: 'Banco Keb Hana' },
  { code: '299', name: 'Sorocred Crédito, Financiamento E Investimento' },
  { code: '755', name: 'Bank of America Merrill Lynch' },
  { code: '741', name: 'Banco Ribeirão Preto S.A.' },
  { code: '376', name: 'Banco JP Morgan' },
  { code: '743', name: 'Banco Semear' },
  { code: '098', name: 'Credialiança' },
  { code: '600', name: 'Banco Luso Brasileiro' },
  { code: '745', name: 'Citibank' },
  { code: '613', name: 'Omni Banco' },
  { code: '174', name: 'Pernambucanas Financiadora' },
  { code: '286', name: 'Sulcredi - Ouro' },
  { code: '457', name: 'UY3 SCD S/A' },
  { code: '279', name: 'CCR de Primavera do Leste' },
  { code: '349', name: 'Amaggi S.A.' },
  { code: '131', name: 'Tullett Prebon' },
  { code: '113', name: 'Magliano CCVM' },
  { code: '479', name: 'Banco ItauBank' },
  { code: '139', name: 'Intesa Sanpaolo' },
  { code: '111', name: 'Oliveira Trust' },
  { code: '128', name: 'MS Bank' },
  { code: '129', name: 'UBS' },
  { code: '467', name: 'Master s/a corretora de câmbio, títulos e valores mobiliários' },
  { code: '454', name: 'Mérito distribuidora de títulos e valores mobiliários ltda.' },
  { code: '343', name: 'FFA SCMEPP' },
  { code: '321', name: 'Crefaz' },
  { code: '298', name: "Vip's CC" },
  { code: '132', name: 'ICBC' },
  { code: '309', name: 'Cambionet CC' },
  { code: '508', name: 'Avenue securities distribuidora de títulos e valores mobiliários ltda.' },
  { code: '331', name: 'Fram Capital DTVM S.A.' },
  { code: '475', name: 'Banco yamaha motor do brasil s.a.' },
  { code: '325', name: 'Órama DTVM S.A.' },
  { code: '259', name: 'Moneycorp' },
  { code: '482', name: 'Sbcash sociedade de crédito direto s.a.' },
  { code: '522', name: 'Red sociedade de crédito direto s.a.' },
  { code: '063', name: 'Banco Bradescard' },
  { code: '516', name: 'Fc financeira s.a. - crédito, financiamento e investimento' },
  { code: '510', name: 'Ffcred sociedade de crédito direto s.a..' },
  { code: '296', name: 'Vision S.A. Corretora De Cambio' },
  { code: '532', name: 'Eagle sociedade de crédito direto s.a.' },
  { code: '443', name: 'Credihome sociedade de crédito direto s.a.' },
  { code: '138', name: 'Get Money' },
  { code: '149', name: 'Facta CFI' },
  { code: '463', name: 'Azumi distribuidora de títulos e valores mobiliários ltda.' },
  { code: '315', name: 'PI Investimentos' },
  { code: '459', name: 'Cooperativa de crédito mútuo de servidores públicos do estado de são paulo - cre' },
  { code: '471', name: 'Cooperativa de economia e credito mutuo dos servidores publicos de pinhão - cres' },
  { code: '525', name: 'Intercam corretora de câmbio ltda.' },
  { code: '455', name: 'Fênix distribuidora de títulos e valores mobiliários ltda.' },
  { code: '513', name: 'Atf credit sociedade de crédito direto s.a.' },
  { code: '108', name: 'PortoCred' },
  { code: '519', name: 'Lions trust distribuidora de títulos e valores mobiliários ltda.' },
  { code: '398', name: 'Ideal corretora de títulos e valores mobiliários s.a.' },
  { code: '386', name: 'Nu financeira s.a. - sociedade de crédito, financiamento e investimento' },
  { code: '100', name: 'Planner' },
  { code: '410', name: 'Planner sociedade de crédito ao microempreendedor s.a.' },
  { code: '400', name: 'Cooperativa de crédito, poupança e serviços financeiros do centro oeste' },
  { code: '371', name: 'Warren CVMC' },
  { code: '512', name: 'Captalys distribuidora de títulos e valores mobiliários ltda.' },
  { code: '393', name: 'Banco volkswagen s.a.' },
  { code: '507', name: 'Gerencianet s.a. - crédito, financiamento e investimento' },
  { code: '449', name: 'Dmcard sociedade de crédito direto s.a.' },
  { code: '137', name: 'Multimoney' },
  { code: '429', name: 'Crediare s.a. - crédito, financiamento e investimento' },
  { code: '135', name: 'Gradual' },
  { code: '180', name: 'CM Capital Markets CCTVM' },
  { code: '143', name: 'Treviso' },
  { code: '190', name: 'Servicoop' },
  { code: '191', name: 'Nova Futura CTVM' },
  { code: '320', name: 'China Construction Bank' },
  { code: '189', name: 'HS Financeira' },
  { code: '157', name: 'ICAP' },
  { code: '091', name: 'Unicred RS' },
  { code: '173', name: 'BRL Trust' },
  { code: '268', name: 'Barigui Companhia Hipotecária' },
  { code: '142', name: 'Broker' },
  { code: '194', name: 'Parmetal DTVM' },
  { code: '163', name: 'Commerzbank' },
  { code: '146', name: 'Guitta' },
  { code: '271', name: 'IB CCTVM' },
  { code: '118', name: 'Standard Chartered' },
  { code: '460', name: 'Unavanti sociedade de crédito direto s/a' },
  { code: '172', name: 'Albatross' },
  { code: '182', name: 'Dacasa Financeira' },
  { code: '458', name: 'Hedge investments distribuidora de títulos e valores mobiliários ltda.' },
  { code: '134', name: 'BGC Liquidez' },
  { code: '521', name: 'Peak sociedade de empréstimo entre pessoas s.a.' },
  { code: '241', name: 'Banco Clássico' },
  { code: '196', name: 'Fair' },
  { code: '300', name: 'Banco de la Nacion Argentina' },
  { code: '266', name: 'Banco Cédula' },
  { code: '265', name: 'Banco Fator' },
  { code: '188', name: 'Ativa Investimentos' },
  { code: '411', name: 'Via certa financiadora s.a. - crédito, financiamento e investimentos' },
  { code: '145', name: 'Levycam' },
  { code: '250', name: 'BCV' },
  { code: '253', name: 'Bexs Corretora de Câmbio' },
  { code: '527', name: 'Aticca - sociedade de crédito direto s.a.' },
  { code: '370', name: 'Banco Mizuho' },
  { code: '249', name: 'Banco Investcred Unibanco' },
  { code: '535', name: 'Marú sociedade de crédito direto s.a.' },
  { code: '366', name: 'Banco Société Générale' },
  { code: '233', name: 'Banco Cifra' },
  { code: '177', name: 'Guide' },
  { code: '523', name: 'Hr digital - sociedade de crédito direto s/a' },
  { code: '169', name: 'Banco Olé Bonsucesso' },
  { code: '222', name: 'Banco Credit Agrícole Brasil' },
  { code: '217', name: 'Banco John Deere' },
  { code: '120', name: 'Banco Rodobens' },
  { code: '127', name: 'Codepe CVC' },
  { code: '105', name: 'Lecca CFI' },
  { code: '101', name: 'Renascença DTVM' },
  { code: '140', name: 'Easynvest' },
  { code: '447', name: 'Mirae asset wealth management (brazil) corretora de câmbio, títulos e valores mo' },
  { code: '494', name: 'BROU' },
  { code: '719', name: 'Banif' },
  { code: '641', name: 'Banco Alvorada' },
  { code: '126', name: 'BR Partners' },
  { code: '117', name: 'Advanced Corretora' },
  { code: '102', name: 'XP Investimentos' },
  { code: '739', name: 'Banco Cetelem' },
  { code: '080', name: 'B&T CC' },
  { code: '652', name: 'Itaú Unibanco Holding S.A.' },
  { code: '720', name: 'Banco rnx s.a.' },
  { code: '011', name: 'Credit Suisse Hedging-Griffo' },
  { code: '204', name: 'Banco Bradesco Cartões S.A.' },
  { code: '539', name: 'Santinvest s.a. - credito, financiamento e investimentos' },
  { code: '438', name: 'Planner trustee distribuidora de títulos e valores mobiliários ltda.' },
  { code: '747', name: 'Rabobank' },
  { code: '752', name: 'BNP Paribas' },
  { code: '399', name: 'Kirton Bank' },
  { code: '036', name: 'Banco Bradesco BBI S.A.' },
  { code: '394', name: 'Banco Bradesco Financiamentos S.A.' },
  { code: '107', name: 'Banco Bocom BBM S.A.' },
  { code: '184', name: 'BANCO ITAÚ BBA S.A.' },
  { code: '545', name: 'Senso' },
  { code: '280', name: 'Avista S.A. Crédito, Financiamento e Investimento' },
  { code: '751', name: 'Scotiabank' },
  { code: '505', name: 'Banco Credit Suisse' },
  { code: '477', name: 'Citibank NA' },
  { code: '495', name: 'Bapro' },
  { code: '488', name: 'JPMorgan Chase Bank' },
  { code: '492', name: 'ING' },
  { code: '456', name: 'Banco MUFG Brasil' },
  { code: '464', name: 'Banco Sumitomo Mitsui' },
  { code: '626', name: 'Banco Ficsa' },
  { code: '611', name: 'Banco Paulista' },
  { code: '487', name: 'Deutsche Bank' },
  { code: '754', name: 'Banco Sistema' },
  { code: '018', name: 'Banco Tricury' },
  { code: '334', name: 'Banco besa s.a.' },
  { code: '524', name: 'Wnt capital distribuidora de títulos e valores mobiliários s.a.' },
  { code: '065', name: 'AndBank' },
  { code: '528', name: 'Reag distribuidora de títulos e valores mobiliários s.a.' },
  { code: '440', name: 'Cooperativa de economia e crédito mútuo brf - credibrf' },
  { code: '328', name: 'Cooperativa de economia e crédito mútuo dos fabricantes de calçados de sapiranga' },
  { code: '418', name: 'Zipdin soluções digitais sociedade de crédito direto s/a' },
  { code: '414', name: 'Work sociedade de crédito direto s.a.' },
  { code: '416', name: 'Lamara sociedade de crédito direto s.a.' },
  { code: '448', name: 'Hemera distribuidora de títulos e valores mobiliários ltda.' },
  { code: '195', name: 'Valor sociedade de crédito direto s.a.' },
  { code: '390', name: 'Banco gm s.a.' },
  { code: '445', name: 'Plantae s.a. - crédito, financiamento e investimento' },
  { code: '377', name: 'Bms sociedade de crédito direto s.a.' },
  { code: '313', name: 'Amazônia corretora de câmbio ltda.' },
  { code: '017', name: 'BNY Mellon Banco' },
  { code: '433', name: 'Br-capital distribuidora de títulos e valores mobiliários s.a.' },
  { code: '391', name: 'Cooperativa de credito rural de ibiam - sulcredi/ibiam' },
  { code: '423', name: 'Coluna s/a distribuidora de titulos e valores mobiliários' },
  { code: '387', name: 'Banco toyota do brasil s.a.' },
  { code: '360', name: 'Trinus capital distribuidora de títulos e valores mobiliários s.a.' },
  { code: '378', name: 'Bbc leasing s.a. - arrendamento mercantil' },
  { code: '319', name: 'OM DTVM' },
  { code: '541', name: 'Fundo garantidor de creditos - fgc' },
  { code: '530', name: 'Ser finance sociedade de crédito direto s.a.' },
  { code: '078', name: 'Haitong' },
  { code: '007', name: 'BNDES' },
  { code: '092', name: 'BRK CFI' },
  { code: '094', name: 'Banco Finaxis' },
  { code: '024', name: 'Banco Bandepe' },
  { code: '083', name: 'Banco da China' },
  { code: '014', name: 'Natixis Brasil' },
  { code: '076', name: 'Banco KDB' },
  { code: '114', name: 'CECOOP' },
  { code: '060', name: 'Confidence Câmbio' },
  { code: '064', name: 'Goldman Sachs' },
  { code: '141', name: 'Banco master de investimento s.a.' },
  { code: '395', name: "F.d'gold - distribuidora de títulos e valores mobiliários ltda." },
  { code: '040', name: 'Banco Cargill' },
  { code: '093', name: 'Pólocred' },
  { code: '075', name: 'ABN Amro' },
  { code: '074', name: 'Banco J Safra' },
  { code: '062', name: 'Hipercard' },
  { code: '311', name: 'Dourada corretora de câmbio ltda.' },
  { code: '015', name: 'UBS Brasil' },
  { code: '066', name: 'Banco Morgan Stanley' },
  { code: '081', name: 'Bancoseguro S.A.' },
  { code: '506', name: 'Rji corretora de titulos e valores mobiliarios ltda' },
  { code: '124', name: 'Woori Bank' },
  { code: '484', name: 'Maf distribuidora de títulos e valores mobiliários s.a.' },
  { code: '470', name: 'Cdc sociedade de crédito ao microempreendedor e à empresade pequeno porte ltda.' },
  { code: '469', name: 'Lecca distribuidora de titulos e valores mobiliarios ltda' },
  { code: '526', name: 'Monetarie sociedade de crédito direto s.a.' },
  { code: '385', name: 'Cooperativa de economia e credito mutuo dos trabalhadores portuarios da grande v' },
  { code: '426', name: 'Biorc financeira - crédito, financiamento e investimento s.a.' },
  { code: '442', name: 'Magnetis - distribuidora de títulos e valores mobiliários ltda' },
  { code: '289', name: 'Decyseo CC' },
  { code: '283', name: 'RB Capital Investimentos DTVM' },
  { code: '643', name: 'Banco Pine' },
  { code: '285', name: 'Frente CC' },
  { code: '293', name: 'Lastro RDV DTVM' },
  { code: '365', name: 'Solidus CCVM S.A.' },
  { code: '363', name: 'Socopa SC Paulista S.A.' },
  { code: '288', name: 'Carol DTVM' },
  { code: '270', name: 'Sagitur CC' },
  { code: '381', name: 'Banco Mercedes-benz Do Brasil S.A.' },
  { code: '354', name: 'Necton Investimentos S.A.' },
  { code: '306', name: 'Portopar DTVM' },
  { code: '373', name: 'UP.P SEP S.A.' },
  { code: '367', name: 'Vitreo DTVM S.A.' },
  { code: '029', name: 'Banco Itaú Consignado S.A.' },
  { code: '473', name: 'Banco Caixa Geral - Brasil S.A.' },
  { code: '122', name: 'Banco Bradesco Berj S.A.' },
  { code: '352', name: 'Toro CTVM' },
  { code: '292', name: 'BS2 DTVM S.A.' },
  { code: '379', name: 'Cooperforte - cooperativa de economia e crédito mútuo dos funcionários de instit' },
  { code: '272', name: 'AGK Corretora De Cambio' },
  { code: '342', name: 'Creditas Sociedade De Crédito Direto S.A.' },
  { code: '307', name: 'Terra Investimentos' },
  { code: '278', name: 'Genial Investimentos CVM' },
]

export type BrazilianBankCode = typeof BRAZILIAN_BANKS[number]['code']
