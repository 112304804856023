import { CheckCircle2, Circle, CircleAlert, CircleSlash, Undo2, XCircle } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { InvoiceStatus } from '../../../domain/dtos/get-invoice-details-request.dto'
import { TransactionSource } from '../../../domain/dtos/get-moviments.dto'

type StatusTagProps = {
  status?: InvoiceStatus | TransactionSource
}

const getStyles = (color: string) => {
  return { size: 16, className: `mr-1 text-${color}-700`, fill: color, style: { fillOpacity: 0.1 } }
}

export const StatusTag: React.FC<StatusTagProps> = ({ status }) => {
  const { t } = useTranslation()

  const getIcon = () => {
    switch (status) {
      case InvoiceStatus.STATUS_PAID:
      case TransactionSource.WITHDRAWAL_SUCCEEDED:
        return <CheckCircle2 {...getStyles('green')} />
      case InvoiceStatus.STATUS_UNPAID:
      case InvoiceStatus.STATUS_DRAFT:
      case TransactionSource.WITHDRAWAL_PENDING:
        return <Circle {...getStyles('blue')} />
      case InvoiceStatus.STATUS_OVERDUE:
        return <CircleSlash {...getStyles('red')} />
      case InvoiceStatus.STATUS_VOID:
      case TransactionSource.WITHDRAWAL_CANCELED:
        return <XCircle {...getStyles('gray')} />
      case InvoiceStatus.STATUS_PARTIALLY_PAID:
      case InvoiceStatus.STATUS_PARTIALLY_RETURNED:
        return <CircleAlert {...getStyles('orange')} />
      case InvoiceStatus.STATUS_RETURNED:
      case TransactionSource.WITHDRAWAL_FAILED:
      case TransactionSource.WITHDRAWAL_FAILED_AFTER_SUCCEEDED:
        return <Undo2 {...getStyles('gray')} />
      default:
        return null
    }
  }

  const getLabel = () => {
    if (!status) return null
    if (status in InvoiceStatus) {
      return t(`invoiceStatus.${status}`)
    } else if (status in TransactionSource) {
      return t(`withdrawalStatus.${status}`)
    }
    return `${status}`
  }

  return (
    <div className="flex items-center" data-testid="status-tag">
      {getIcon()}
      {getLabel()}
    </div>
  )
}
