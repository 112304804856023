import {
  Drawer,
  DrawerContent,
  Label,
  Sheet,
  SheetContent,
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TablePagination,
  TableRow,
  useIsMobile,
} from '@hub-la/shadcn'
import isEmpty from 'lodash/isEmpty'
import times from 'lodash/times'
import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SmartInstallment } from '../../../../domain/dtos/smart-installment'
import { OrderDirection } from '../../../../domain/enums/order-direction'
import { SmartInstallmentStatus } from '../../../../domain/enums/smart-installment-status'
import { SmartInstallmentStatusStats } from '../../../components/smart-installment-status-stats'
import { useGetSmartInstallments } from '../../../hooks/use-get-smart-installments'
import { useRefetch } from '../../../hooks/use-refetch'
import { SmartInstallmentDetail } from '../../smart-installment-detail/smart-installment-detail'
import { InstallmentByStatus } from '../installment-by-status'
import { TableLoading } from './table-loading'

export const SmartInstallmentsTable = ({ filters, setFilters, offers, isLoading }) => {
  const { page, pageSize } = filters

  const [openSmartInstallmentDetail, setOpenSmartInstallmentDetail] = useState<string>()
  const [orderDirection] = useState<OrderDirection>(OrderDirection.DESC)
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const {
    data = { items: [], total: 0, page, pageSize, lastPage: 1 },
    isFetching,
    refetch,
  } = useGetSmartInstallments(
    {
      offers,
      offerIds: filters.offerIds,
      statuses: filters.statuses as SmartInstallmentStatus[],
      startDate: filters.startDate,
      endDate: filters.endDate,
      dateRangeBy: filters.dateRangeBy,
      page: filters.page,
      pageSize: parseInt(filters.pageSize, 10),
      search: filters.search,
      orderDirection,
    },
    isLoading,
  )

  useRefetch({ offers, ...filters, orderDirection }, refetch)

  const getHeadCells = () => {
    return [
      {
        key: 'client',
        label: 'Cliente',
        sortable: false,
        width: 220,
      },
      {
        key: 'product',
        label: 'Produto',
        sortable: false,
        width: 170,
      },
      {
        key: 'status',
        label: 'Status',
        tooltip: t('smartInstallmentsTable.tooltip.status'),
        sortable: false,
        width: 130,
      },
      {
        key: 'installments',
        label: 'Total em parcelas',
        width: 160,
        sortable: false,
      },
      {
        key: 'paid_installments',
        label: 'Pagas',
        tooltip: t('smartInstallmentsTable.tooltip.paid_installments'),
        width: 150,
        sortable: false,
      },
      {
        key: 'overdue_installments',
        label: 'Atrasadas',
        tooltip: t('smartInstallmentsTable.tooltip.overdue_installments'),
        width: 150,
        sortable: false,
      },
      {
        key: 'draft_installments',
        label: 'Agendadas',
        tooltip: t('smartInstallmentsTable.tooltip.draft_installments'),
        width: 150,
        sortable: false,
      },
      {
        key: 'createdAt',
        label: 'Data da criação',
        sortable: true,
        defaultSortOrder: 'desc',
        width: 178,
      },
      {
        key: 'nextDueAt',
        label: 'Próximo vencimento',
        sortable: false,
        width: 172,
      },
    ]
  }

  const isRowsEmpty = isEmpty(data?.items)
  const canRenderEmptyState = !isFetching && !isLoading && isRowsEmpty

  return (
    <div className="flex flex-col gap-4">
      <div className="rounded-md border bg-white">
        <Table>
          <TableHeader>
            <TableRow>
              {getHeadCells().map((column) => (
                <TableHead>{column.label}</TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {(isFetching && isRowsEmpty) || isLoading
              ? times(pageSize, (idx) => <TableLoading key={idx} />)
              : (data?.items ?? []).map((smartInstallment: SmartInstallment) => (
                  <TableRow
                    key={smartInstallment.id}
                    /**
                     * @todo Switch this history push to open a modal with the SmartInstallmentDetail component.
                     * Also update this todo to switch back to the original history push when the new navigation is done.
                     */
                    onClick={() => setOpenSmartInstallmentDetail(smartInstallment.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    <TableCell>
                      <p>{smartInstallment.payer?.identity?.fullName}</p>
                    </TableCell>
                    <TableCell>
                      <p>{smartInstallment.invoiceRef?.items[0].productName}</p>
                    </TableCell>
                    <TableCell>
                      <SmartInstallmentStatusStats status={smartInstallment.status} />
                    </TableCell>
                    <TableCell>
                      <InstallmentByStatus
                        installments={smartInstallment.installments}
                        amount={smartInstallment.amountTotalCents}
                      />
                    </TableCell>
                    <TableCell>
                      <InstallmentByStatus
                        installments={smartInstallment.installmentTotalPaid}
                        amount={smartInstallment.amountTotalPaidCents}
                      />
                    </TableCell>
                    <TableCell>
                      <InstallmentByStatus
                        installments={smartInstallment.installmentTotalOverdue}
                        amount={smartInstallment.amountTotalOverdueCents}
                      />
                    </TableCell>
                    <TableCell>
                      <InstallmentByStatus
                        installments={smartInstallment.installmentTotalDraft}
                        amount={smartInstallment.amountTotalDraftCents}
                      />
                    </TableCell>
                    <TableCell>
                      <p>{moment(smartInstallment.createdAt).format('D MMM. YYYY')}</p>
                    </TableCell>
                    <TableCell>
                      <p>{moment(smartInstallment.nextDueAt).format('D MMM. YYYY')}</p>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
          {canRenderEmptyState && (
            <TableCaption>
              <div className="py-4 Label-center">
                <Label className="text-sm">{t('empty')}</Label>
                <Label className="text-sm">{t('emptySubtitle')}</Label>
              </div>
            </TableCaption>
          )}
        </Table>
      </div>
      {isMobile ? (
        <Drawer open={!!openSmartInstallmentDetail} onClose={() => setOpenSmartInstallmentDetail(undefined)}>
          <DrawerContent className="max-h-[940px]">
            {openSmartInstallmentDetail && (
              <SmartInstallmentDetail
                id={openSmartInstallmentDetail}
                onClose={() => setOpenSmartInstallmentDetail(undefined)}
              />
            )}
          </DrawerContent>
        </Drawer>
      ) : (
        <Sheet
          key="right"
          open={!!openSmartInstallmentDetail}
          onOpenChange={(open) => open === false && setOpenSmartInstallmentDetail(undefined)}
        >
          <SheetContent side="right" className="w-[400px] bg-gray-50 sm:w-[1340px] sm:max-w-8xl">
            {openSmartInstallmentDetail && (
              <SmartInstallmentDetail
                id={openSmartInstallmentDetail}
                onClose={() => setOpenSmartInstallmentDetail(undefined)}
              />
            )}
          </SheetContent>
        </Sheet>
      )}
      <div className="flex justify-end px-2 mt-3 mb-3">
        {!isRowsEmpty && (
          <TablePagination
            page={page}
            setPage={(page) => setFilters({ ...filters, page })}
            lastPage={data.lastPage}
            pageSize={+pageSize}
            setPageSize={(pageSize) => setFilters({ ...filters, pageSize })}
          />
        )}
      </div>
    </div>
  )
}
