import { memo } from 'react'
import { useHistory } from 'react-router-dom'
import { Badge, useIsMobile } from '@hub-la/shadcn'
import { Calendar } from 'lucide-react'
import { FormatDate } from '../../../../domain/vos/format-date'
import { getGroupUrl } from '../../../../utils/sessions-utils'
import { textEllipsis } from '../../../../utils/text-ellipsis'
import avatar from '../../../assets/placeholder-image.svg'

export interface GroupProps {
  id: string
  name: string
  picture?: string
  type: 'digital' | 'event'
  eventData?: {
    startDate: string
    endDate: string
  }
}

export const Group = memo(({ id, name, picture, type, eventData }: GroupProps) => {
  const startDate = eventData?.startDate ? new FormatDate(eventData.startDate).getValue() : null
  const endDate = eventData?.endDate ? new FormatDate(eventData.endDate).getValue() : null
  const history = useHistory()
  const isMobile = useIsMobile()

  const redirectToGroupFeed = () => {
    if (type === 'event') {
      history.push(`/user_events/${id}`)
    } else {
      window.location.assign(getGroupUrl(id))
    }
  }

  return (
    <div
      className="p-1 cursor-pointer transition-all duration-200 hover:bg-secondary/10 hover:shadow-lg hover:scale-105 relative"
      onClick={redirectToGroupFeed}
    >
      {startDate && endDate && (
        <Badge className="absolute top-2 left-2 z-10" variant="secondary">
          <Calendar className="w-3 h-3 mr-1" />
          {`${startDate}${startDate === endDate ? '' : ' - ' + endDate}`}
        </Badge>
      )}
      <img src={picture ?? avatar} alt={name} className="w-full h-auto aspect-[218/142] rounded-lg object-cover" />
      <p className="mt-2 text-center text-sm">{isMobile ? textEllipsis(name, 20) : name}</p>
    </div>
  )
})
