import { Card, CardContent, CardHeader, CardTitle, Separator } from '@hub-la/shadcn'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { PaymentMethod } from '../../../domain/enums/payment-method'
import { ContentRow } from './content-row'

type DetailsProps = {
  id: string
  createdAt: string
  validUntil: string
  paymentMethods: PaymentMethod[]
}

export const Details = ({ id, createdAt, validUntil, paymentMethods }: DetailsProps) => {
  const { t } = useTranslation()

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader className="p-6">
          <CardTitle className="text-lg font-semibold flex justify-between items-center">Detalhes</CardTitle>
        </CardHeader>
        <Separator />
        <CardContent className="p-6 space-y-4">
          <ContentRow title="ID do cupom" content={<span>{id}</span>} />

          <ContentRow title="Data de criação" content={<span>{moment(createdAt).format('D MMM. YYYY HH:mm')}</span>} />

          <ContentRow
            title="Expira em"
            content={<span>{validUntil ? moment(validUntil).format('D MMM. YYYY HH:mm') : '-'}</span>}
          />

          <ContentRow
            title="Método de Pagamento"
            content={
              <div className="flex items-center space-x-2">
                {paymentMethods.map((paymentMethod) => (
                  <div key={paymentMethod} className="flex items-center space-x-1">
                    <span>{t(`paymentMethod.${paymentMethod}`)}</span>
                  </div>
                ))}
              </div>
            }
          />
        </CardContent>
      </Card>
    </div>
  )
}
