import { CopyClipboard } from '@hub-la/fe-copy-clipboard'
import { Button, Card, CardContent, useToast } from '@hub-la/shadcn'
import { ChevronDown, ChevronUp, Copy, Wand2 } from 'lucide-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const CardInstructionUtm: React.FC = () => {
  const { t } = useTranslation()
  const { toast } = useToast()
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const utmScript = `var _prefixDomains=["https://pay.hub.la", "https://invoice.hub.la", "https://app.hub.la", "https://hub.la"];function _getUtmParams(){var a="",r=window.top.location.href,e=new URL(r);if(null!==e){var t=e.searchParams.get("utm_source"),n=e.searchParams.get("utm_medium"),m=e.searchParams.get("utm_campaign"),o=e.searchParams.get("utm_term"),s=e.searchParams.get("utm_content");-1!==r.indexOf("?")&&(a="&sck="+t+"|"+n+"|"+m+"|"+o+"|"+s,console.log("[hubla][utms]",a))}return a}!function(){var a=new URLSearchParams(window.location.search);a.toString()&&document.querySelectorAll("a").forEach((function(r){for(var e=0;e<_prefixDomains.length;e++)-1!==r.href.indexOf(_prefixDomains[e])&&(-1===r.href.indexOf("?")?r.href+="?"+a.toString()+_getUtmParams():r.href+="&"+a.toString()+_getUtmParams())}))}();`

  const handleCopyClipboard = async (target: string) => {
    const content = target === 'javascript' ? utmScript : `<script type="text/javascript">${utmScript}</script>`
    await new CopyClipboard().execute(content)
    toast({
      title: t('shortlinks.snackbar.copyClipboard'),
      duration: 6000,
    })
  }

  return (
    <Card className="w-full">
      <CardContent className="p-4 space-y-4">
        <div className="flex justify-between items-center cursor-pointer" onClick={() => setShowDetails(!showDetails)}>
          <div className="flex items-center gap-2">
            <Wand2 className="h-5 w-5 text-yellow-600" />
            <span className="text-sm">{t('shortlinks.instruction.utm')}</span>
          </div>
          {showDetails ? <ChevronUp className="h-5 w-5" /> : <ChevronDown className="h-5 w-5" />}
        </div>

        {showDetails && (
          <div className="space-y-4">
            <Card>
              <CardContent className="p-4 bg-gray-100">
                <pre className="text-xs whitespace-pre-wrap break-all">{utmScript}</pre>
              </CardContent>
            </Card>

            <div className="flex flex-col sm:flex-row gap-3 items-start sm:items-center">
              <Button variant="outline" size="sm" onClick={() => handleCopyClipboard('javascript')}>
                <Copy className="mr-2 h-4 w-4" />
                Javascript
              </Button>
              <Button variant="outline" size="sm" onClick={() => handleCopyClipboard('html')}>
                <Copy className="mr-2 h-4 w-4" />
                HTML
              </Button>
              <div className="flex items-center gap-2">
                <span className="text-sm">Saiba mais sobre</span>
                <a href="#" className="text-sm text-blue-600 hover:underline cursor-pointer">
                  UTMs.
                </a>
              </div>
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  )
}
