import { Button, Card, CardContent, Separator } from '@hub-la/shadcn'
import { ArrowLeft, CreditCard, RefreshCw } from 'lucide-react'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Money } from '../../domain/vos/money'
import avatar from '../assets/default-group-avatar.png'
import { useGetAffiliationProgram } from '../hooks/use-get-affiliation-program'
import { useRequestAffiliate } from '../hooks/use-request-affiliate'
import { AppHeader } from './app-header'
import { Loading } from './loading'

export const AffiliationProgramPage: React.FC = () => {
  const { groupId } = useParams<{ groupId: string }>()
  const { data, isLoading } = useGetAffiliationProgram(groupId)
  const { mutateAsync: requestAffiliate, isLoading: isRequesting } = useRequestAffiliate()
  const history = useHistory()

  if (isLoading || !data) {
    return <Loading />
  }

  return (
    <div className="flex flex-col gap-4">
      <AppHeader />
      <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8 pb-10 container">
        <Button variant="ghost" className="mb-6 pl-0" onClick={() => history.goBack()}>
          <ArrowLeft className="mr-2 h-4 w-4" />
          {data.offerName}
        </Button>

        <Card>
          <CardContent className="p-6">
            <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 mb-6">
              <img
                src={data.offerImageUrl ?? avatar}
                alt={data.offerName}
                className="w-24 h-auto aspect-[218/142] rounded-lg object-cover border border-gray-200"
              />
              <div>
                <h2 className="text-2xl font-bold">{data.offerName}</h2>
                <p className="text-sm text-gray-500">{data.offerOwnerName}</p>
              </div>
            </div>

            <Separator className="my-6" />

            <div className="grid grid-cols-2 sm:grid-cols-1 gap-6">
              {data.commissioning.sell.percent > 0 && (
                <div className="flex items-start gap-3">
                  <CreditCard className="mt-1" />
                  <div>
                    <p>Até {Money.build((data.commissioning.sell.maxAmountCents ?? 0) / 100).getValue()} por venda</p>
                    <p className="text-sm text-gray-500">{data.commissioning.sell.percent}% comissão</p>
                  </div>
                </div>
              )}
              {data.commissioning.renewal.percent > 0 && (
                <div className="flex items-start gap-3">
                  <RefreshCw className="mt-1" />
                  <div>
                    <p>
                      Até {Money.build((data.commissioning.renewal.maxAmountCents ?? 0) / 100).getValue()} por renovação
                    </p>
                    <p className="text-sm text-gray-500">{data.commissioning.renewal.percent}% comissão</p>
                  </div>
                </div>
              )}
            </div>
          </CardContent>
        </Card>

        <div className="flex flex-row justify-end space-x-4 mt-6">
          <Button variant="outline" onClick={() => history.goBack()}>
            Cancelar
          </Button>
          <Button onClick={() => requestAffiliate(groupId)} loading={isRequesting}>
            Affiliar
          </Button>
        </div>
      </div>
    </div>
  )
}
