import { ReactNode } from 'react'
import { RuleDetailItem } from './rule-detail-item'

type JSONDetailItemProps = {
  title: string
  children?: ReactNode
}

export const JSONDetailItem = ({ title, children }: JSONDetailItemProps) => {
  return (
    <RuleDetailItem title={title}>
      <pre
        style={{
          fontSize: '12px',
          color: '#FFF',
          padding: '10px',
          overflowX: 'auto',
          maxHeight: '300px',
          backgroundColor: '#333',
          margin: 0,
        }}
      >
        <code style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>{children}</code>
      </pre>
    </RuleDetailItem>
  )
}
