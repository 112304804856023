import { CheckIcon, PlusCircle } from 'lucide-react'

import {
  Badge,
  Button,
  cn,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Separator,
} from '@hub-la/shadcn'

import { Offer } from '../../domain/dtos/offer'

interface SelectOffersProps {
  onChange: (ids: string[]) => void
  value: string[]
  offers: Offer[]
}

export const SelectOffers = (props: SelectOffersProps) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline">
          <PlusCircle className="h-4 w-4 mr-1" />
          <span className="text-sm">Produto(s)</span>
          {props.value.length > 0 && (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <Badge variant="secondary" className="rounded-sm px-1 font-normal lg:hidden">
                {props.value.length}
              </Badge>
              <div className="hidden space-x-1 lg:flex">
                {props.value.length > 2 ? (
                  <Badge variant="secondary" className="rounded-sm px-1 font-normal">
                    {props.value.length} selecionado(s)
                  </Badge>
                ) : (
                  props.offers
                    .filter((option) => props.value.includes(option.id))
                    .map((option) => (
                      <Badge variant="secondary" key={option.id} className="rounded-sm px-1 font-normal">
                        {option.name}
                      </Badge>
                    ))
                )}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-80 p-0" align="start">
        <Command>
          <CommandInput placeholder="Produto(s)" />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {props.offers.map((option) => {
                const isSelected = props.value.includes(option.id)
                return (
                  <CommandItem
                    key={option.id}
                    onSelect={() => {
                      if (isSelected) {
                        const newArr = props.value.filter((value) => value !== option.id)
                        props.onChange(newArr)
                      } else {
                        const newArr = [...props.value]
                        newArr.push(option.id)
                        props.onChange(newArr)
                      }
                    }}
                  >
                    <div
                      className={cn(
                        'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                        isSelected ? 'bg-primary text-primary-foreground' : 'opacity-50 [&_svg]:invisible',
                      )}
                    >
                      <CheckIcon className={cn('h-4 w-4')} />
                    </div>
                    <span>{option.name}</span>
                  </CommandItem>
                )
              })}
            </CommandGroup>
          </CommandList>
        </Command>
        <Separator />
        {props.value.length > 0 && (
          <Button
            variant="ghost"
            className="w-full justify-center text-center rounded-none"
            onClick={() => props.onChange([])}
          >
            Limpar filtros
          </Button>
        )}
      </PopoverContent>
    </Popover>
  )
}
