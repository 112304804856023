import moment from 'moment'
import { Period } from '../dtos/period'
import { OutputFormatPeriodToMoment } from '../dtos/period-to-moment'

export const formatDate = (dateString: string, period: Period): string => {
  const date = moment(dateString).locale('pt-br')
  if (date.isValid()) {
    return date.format(OutputFormatPeriodToMoment[period])
  }

  return 'Invalid date'
}
