import React, { useCallback, useRef, useState } from 'react'
import { Command, CommandInput, CommandList, CommandItem, cn, Skeleton, CommandGroup, Popover } from '@hub-la/shadcn'
import { ArrowRight, Loader2 } from 'lucide-react'
import { getGroupUrl } from '../../../../utils/sessions-utils'
import { useListMemberGroups } from '../../../hooks/use-list-member-groups'
import { textEllipsis } from '../../../../utils/text-ellipsis'
import { useIsMobile } from '@hub-la/shadcn'

const Autocomplete: React.FC = () => {
  const inputRef = useRef<HTMLInputElement>(null)

  const { data = [], isFetching } = useListMemberGroups()
  const isMobile = useIsMobile()

  const [open, setOpen] = useState(false)

  const handleKeyDown = useCallback(() => {
    const input = inputRef.current

    if (!input) {
      return
    }

    if (!open) {
      setOpen(true)
    }
  }, [open])

  const options = data.map((option) => ({
    id: option.id,
    label: option.name,
    picture: option.pictureUrl,
  }))

  const handleBlur = useCallback(() => {
    setOpen(false)
  }, [])

  const redirectToGroupFeed = (id: string) => window.location.assign(getGroupUrl(id))

  return (
    <Command onKeyDown={handleKeyDown} onBlur={handleBlur} className="overflow-visible w-full max-w-[400px]">
      <div className="flex w-full items-center justify-between rounded-lg border bg-background ring-offset-background text-sm focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2">
        <CommandInput ref={inputRef} className="w-full p-3 rounded-lg outline-none" />
      </div>
      <div className={cn('relative animate-in fade-in-0 zoom-in-95 h-auto', open ? 'block' : 'hidden')}>
        <CommandList>
          <div className="absolute top-1.5 z-50 w-full">
            <CommandGroup className="relative h-auto z-50 min-w-[8rem] overflow-hidden rounded-md border shadow-md bg-background">
              {isFetching ? (
                <div className="h-28 flex items-center justify-center">
                  <Loader2 className="size-6 animate-spin" />
                </div>
              ) : (
                <>
                  {options.map((option) => (
                    <CommandItem
                      key={option.id}
                      value={option.label}
                      onMouseDown={(event) => {
                        event.preventDefault()
                        event.stopPropagation()
                      }}
                      onSelect={() => redirectToGroupFeed(option.id)}
                      className="flex select-text flex-col cursor-pointer gap-0.5 h-max p-2 px-3 rounded-md aria-selected:bg-accent aria-selected:text-accent-foreground hover:bg-accent hover:text-accent-foreground items-start"
                    >
                      <div className="flex items-center space-x-2 w-full">
                        <img
                          src={option.picture}
                          alt={option.label}
                          className="w-[70px] h-[46px] rounded-lg object-cover border border-secondary"
                        />
                        <p className="text-sm flex-1">{isMobile ? textEllipsis(option.label, 20) : option.label}</p>
                        <ArrowRight className="w-4 h-4" />
                      </div>
                    </CommandItem>
                  ))}
                </>
              )}
            </CommandGroup>
          </div>
        </CommandList>
      </div>
    </Command>
  )
}

export default Autocomplete
