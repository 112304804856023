import { useContainer } from '@hub-la/fe-container'
import { Analytics } from '@hub-la/fe-core-analytics'
import { Events, makePostEventData, SectionResponse } from '@hub-la/fe-post'
import { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable, DroppableProps } from 'react-beautiful-dnd'
import { useGetMembersArea } from '../../hooks/use-get-members-area'
import { useReorderSections } from '../../hooks/use-reorder-sections'
import { EmptyState } from '../empty-state/empty-state'
import { SectionItem } from '../section-item/section-item'

// This component is a workaround to avoid the following error
// on the lib itself with react 18 strict mode:
// react-beautiful-dnd: Unable to find draggable with id
// https://github.com/atlassian/react-beautiful-dnd/issues/2407
const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false)
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true))
    return () => {
      cancelAnimationFrame(animation)
      setEnabled(false)
    }
  }, [])

  if (!enabled) {
    return null
  }

  return <Droppable {...props}>{children}</Droppable>
}

type Props = {
  productId: string
  userId: string
  sections: SectionResponse[]
}

export const SectionList: React.FC<Props> = ({ productId, userId, sections }) => {
  const reorderSections = useReorderSections()
  const container = useContainer()
  const analytics = container.get(Analytics)
  const { data: membersAreaSettings } = useGetMembersArea({
    productId,
  })

  const onDragEnd = (result) => {
    const section = sections.find((it) => it.id === result.draggableId)

    // dropped outside the list
    if (!result.destination || !section) {
      return
    }

    reorderSections
      .mutateAsync({
        name: section.name,
        postIds: section.posts.length > 0 ? section.posts.map((it) => it.id) : undefined,
        order: result.destination.index + 1,
        source: result.source.index,
        destination: result.destination.index,
        id: section.id,
        productId,
      })
      .then(() => {
        analytics.track(
          Events.HUB.SECTION.ORDER_SUBMITTED,
          makePostEventData({
            userId,
            productId,
          }),
        )
      })
  }

  const hasContent = sections.length > 0

  return (
    <div className="mt-6 mb-45 flex flex-col gap-4 w-full" data-testid="list">
      {hasContent ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <StrictModeDroppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {sections.map((section, idx) => (
                  <Draggable key={section.id} draggableId={section.id} index={idx}>
                    {(provided) => (
                      <div key={section.id} ref={provided.innerRef} {...provided.draggableProps}>
                        <SectionItem
                          key={section.id}
                          dragHandleProps={provided.dragHandleProps}
                          sections={sections}
                          section={section}
                          productId={productId}
                          userId={userId}
                          showEditThumbnail={membersAreaSettings?.modulesHighlight}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}

                {provided.placeholder}
              </div>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      ) : (
        <EmptyState />
      )}
    </div>
  )
}
