import { useContainer } from '@hub-la/fe-container'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-key'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useSnackbar } from '@hub-la/design-system'
import { useTranslation } from 'react-i18next'
import { ListEventsInput } from '../../domain/dtos/list-events-input'
import { ListEventsUsecase } from '../../usecases/list-events.usecase'

export const useListEvents = (input: ListEventsInput) => {
  const { addSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const container = useContainer()

  return useQuery(
    [QueryKey.listEvents, input.page, input.pageSize],
    () => new ListEventsUsecase(container.get(HttpClient)).execute(input),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: (error: Error) => {
        addSnackbar({ message: t(error.message), variant: 'negative' })
      },
    },
  )
}
