import {
  Badge,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@hub-la/shadcn'
import isEmpty from 'lodash/isEmpty'
import times from 'lodash/times'
import { RefreshCw } from 'lucide-react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { EventDelivery } from '../../../domain/dtos/event-delivery'
import { IntegrationEvent } from '../../../domain/dtos/integration-event'
import { GetEventName } from '../../../usecases/get-event-name'
import { EmptyState } from '../empty-state'
import { HistoryTableLoading } from './history-table-loading'

type TableHistoryProps = {
  items: EventDelivery[]
  isFetching: boolean
  onHistoryDetail: (state: { isOpen: boolean; history: EventDelivery }) => void
  onRetryEventHistory: (state: { isOpen: boolean; history: EventDelivery }) => void
}

export const TableHistory = ({ items, isFetching, onHistoryDetail, onRetryEventHistory }: TableHistoryProps) => {
  const { t } = useTranslation()

  const isRowsEmpty = isEmpty(items)
  const canRenderEmptyState = !isFetching && isRowsEmpty
  const isLoading = isFetching && isRowsEmpty

  const headCells = [
    { key: 'date', label: t('histories.table.columns.date') },
    { key: 'event', label: t('histories.table.columns.event') },
    { key: 'product', label: t('histories.table.columns.product') },
    { key: 'email', label: t('histories.table.columns.email') },
    { key: 'status', label: t('histories.table.columns.status') },
    { key: 'actions', label: t('histories.table.columns.actions') },
  ]

  const renderUserEmail = (payload?: string) => {
    if (!payload) {
      return '-'
    }

    const parse = JSON.parse(payload)
    return parse?.event?.userEmail ?? parse?.event?.user?.email ?? '-'
  }

  const renderEventCell = (event: IntegrationEvent) => {
    const eventName = new GetEventName().execute(event)

    if (!eventName) {
      return null
    }

    return <Badge variant="secondary">{t(eventName)}</Badge>
  }

  const renderStatusCodeCell = (status?: number | null) => {
    if (!status) {
      return <Badge variant="secondary">Em Processamento</Badge>
    } else if (status >= 0 && status < 300) {
      return <Badge>{status} - Processado</Badge>
    } else if (status >= 300) {
      return <Badge variant="destructive">{status} - Erro</Badge>
    } else {
      return <Badge>{status}</Badge>
    }
  }

  const handleOpenedDetail = (history: EventDelivery) => {
    onHistoryDetail({ isOpen: true, history })
  }

  if (canRenderEmptyState) {
    return <EmptyState />
  }

  return (
    <div className="rounded-md border bg-white">
      <Table>
        <TableHeader>
          <TableRow>
            {headCells.map((cell) => (
              <TableHead key={cell.key}>{cell.label}</TableHead>
            ))}
          </TableRow>
        </TableHeader>

        <TableBody>
          {isLoading
            ? times(50, (i) => <HistoryTableLoading key={`skeleton-row-${i}`} />)
            : items.map((history) => (
                <TableRow key={history.id} className="hover:bg-muted/50 cursor-pointer">
                  <TableCell onClick={() => handleOpenedDetail(history)}>
                    {moment(history.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                  </TableCell>

                  <TableCell onClick={() => handleOpenedDetail(history)}>{renderEventCell(history.event)}</TableCell>

                  <TableCell onClick={() => handleOpenedDetail(history)}>{history.productId}</TableCell>

                  <TableCell onClick={() => handleOpenedDetail(history)}>{renderUserEmail(history.payload)}</TableCell>

                  <TableCell onClick={() => handleOpenedDetail(history)}>
                    {renderStatusCodeCell(history.statusCode)}
                  </TableCell>

                  <TableCell>
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            size="sm"
                            variant="outline"
                            className="w-8 h-8 p-0"
                            onClick={() => onRetryEventHistory({ isOpen: true, history: history })}
                          >
                            <RefreshCw className="h-4 w-4" />
                          </Button>
                        </TooltipTrigger>

                        <TooltipContent>
                          <p>{t('buttons.retry')}</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </div>
  )
}
