import { Logo } from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { SelectRoleplay } from '../../../domain/dtos/select-roleplay'
import { GetFirstName } from '../../../usecases/get-first-name'
import { AccountCard } from '../../components/account-card'
import { useGetMyAccess } from '../../hooks/use-get-my-access'
import { useRoleplay } from '../../hooks/use-roleplay'
import { SelectAccountLoadingState } from './loading-state'

export const SelectAccountRoleplayPage = ({ currentUser }: SelectRoleplay) => {
  const history = useHistory()
  const { t } = useTranslation()

  const { data, isFetching } = useGetMyAccess()
  const { isRoleplay, signIn, signOut, signInLoading, signOutLoading } = useRoleplay()

  const handleSignIn = async (id: string) => {
    await signIn(id)
    history.push('/dashboard')
  }

  const handleSignOut = async () => {
    if (!isRoleplay) {
      history.push('/dashboard')
      return
    }
    await signOut()
    history.push('/dashboard')
  }

  return (
    <div className="max-w-lg mx-auto mt-8 mb-8 border bg-white rounded-lg p-8 space-y-6">
      <div className="pb-4">
        <Logo hierarchy="primary" size="medium" />
      </div>
      <h1 className="text-2xl font-semibold">
        {t('selectAccountRoleplay.title', {
          firstName: new GetFirstName().execute(currentUser?.name),
        })}
      </h1>
      <div className="">
        <h2 className="text-base font-semibold mb-2">{t('selectAccountRoleplay.yourAccount')}</h2>
        <AccountCard
          userId={currentUser?.id}
          name={currentUser?.name}
          email={currentUser?.email}
          avatarUrl={currentUser?.avatarUrl}
          onClick={handleSignOut}
          loading={signOutLoading}
        />
      </div>
      <div className="">
        <h2 className="text-base font-semibold mb-2">{t('selectAccountRoleplay.accountsWithAccess')}</h2>
        <div className="space-y-2">
          {data?.map((account) => (
            <AccountCard
              key={account.userId}
              userId={account?.userId}
              name={account.name}
              email={account.email}
              avatarUrl={account?.picture}
              onClick={handleSignIn}
              loading={signInLoading}
            />
          ))}
          {isFetching && <SelectAccountLoadingState />}
        </div>
      </div>
    </div>
  )
}
