import { Button, Dialog, DialogContent, DialogHeader, DialogTitle, Input, Toast } from '@hub-la/shadcn'
import { DialogTrigger } from '@radix-ui/react-dialog'
import { Copy, DollarSign, RefreshCcw } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Money } from '../../../domain/vos/money'

type GetLinkModalProps = {
  affiliationId?: string
  linkDetails?: {
    offerId: string
    offerName: string
    commissioning: {
      sell: {
        isEnabled: boolean
        percent: number
        maxAmountCents: number
      }
      renewal: {
        isEnabled: boolean
        percent: number
        maxAmountCents: number
      }
    }
  }[]
}

export const GetLinkModal = ({ affiliationId, linkDetails }: GetLinkModalProps) => {
  const { t } = useTranslation()

  const copyToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text)
    Toast({
      title: t('affiliates.linksModal.snackbar.copiedCheckoutLink'),
    })
  }

  return (
    <Dialog>
      <div className="flex flex-row gap-2 justify-end">
        <DialogTrigger>
          <Button variant="outline">Links</Button>
        </DialogTrigger>
      </div>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('affiliates.linksModal.title')}</DialogTitle>
        </DialogHeader>

        <div className="mt-2">
          <p className="text-sm text-gray-500">{t('affiliates.linksModal.description')}</p>
        </div>

        <div className="mt-4 space-y-4">
          {(linkDetails || []).map((linkDetail, index) => {
            const checkoutLink = `${process.env['NEXT_PUBLIC_PAY_CHECKOUT_URL']}/${linkDetail?.offerId}?ref=${affiliationId}`

            return (
              <div key={index} className="border border-gray-200 rounded-md p-4">
                <div className="space-y-2">
                  <label className="text-sm font-medium" htmlFor={`link-${index}`}>
                    {`Checkout – ${linkDetail.offerName}`}
                  </label>

                  <div className="flex">
                    <Input id={`link-${index}`} value={checkoutLink} readOnly className="flex-grow" />

                    <Button
                      variant="outline"
                      size="icon"
                      className="ml-2"
                      onClick={() => copyToClipboard(checkoutLink)}
                    >
                      <Copy className="h-4 w-4" />
                    </Button>
                  </div>
                </div>

                {linkDetail?.commissioning?.sell?.percent > 0 && linkDetail?.commissioning?.renewal?.percent > 0 && (
                  <div className="mt-4 flex space-x-4">
                    {linkDetail?.commissioning?.sell?.percent > 0 && (
                      <div className="flex items-start space-x-2">
                        <DollarSign className="h-5 w-5 text-gray-400" />

                        <div>
                          <p className="text-sm font-medium">
                            {t('affiliates.linksModal.linkComission.sellMaxAmount', {
                              maxAmountCents: Money.build(
                                (linkDetail?.commissioning?.sell?.maxAmountCents ?? 0) / 100,
                              ).getValue(),
                            })}
                          </p>

                          <p className="text-sm text-gray-500">
                            {t('affiliates.linksModal.linkComission.sellComissionPercent', {
                              percent: linkDetail?.commissioning?.sell?.percent,
                            })}
                          </p>
                        </div>
                      </div>
                    )}

                    {linkDetail?.commissioning?.renewal?.percent > 0 && (
                      <div className="flex items-start space-x-2">
                        <RefreshCcw className="h-5 w-5 text-gray-400" />

                        <div>
                          <p className="text-sm font-medium">
                            {t('affiliates.linksModal.linkComission.renewalMaxAmount', {
                              maxAmountCents: Money.build(
                                (linkDetail?.commissioning?.renewal?.maxAmountCents ?? 0) / 100,
                              ).getValue(),
                            })}
                          </p>

                          <p className="text-sm text-gray-500">
                            {t('affiliates.linksModal.linkComission.renewalComissionPercent', {
                              percent: linkDetail?.commissioning?.renewal?.percent,
                            })}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </DialogContent>
    </Dialog>
  )
}
