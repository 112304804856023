import { useContainer } from '@hub-la/fe-container'
import { Analytics } from '@hub-la/fe-core-analytics'
import { useIsMobile } from '@hub-la/shadcn'
import { MuxPlayerRefAttributes } from '@mux/mux-player-react'
import { debounce } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Events } from '../../../domain/dtos/events'
import { GetAssetPlaybackId } from '../../../usecases/get-asset-playbackid'
import { useRealtimeSections } from '../../hooks'
import { useUserProgress } from '../../hooks/use-user-progress'
import { UpNextCard } from '../upnext-card'
import { MuxPlayerRewrite } from './mux-player-rewrite'

interface PlayerProps {
  poster?: any
  title: string
  src: string
  assetId: string
  progress?: number
  duration?: number | null
  postId: string
  sectionId: string
}

export const Player = ({ src, assetId, progress, postId, sectionId, duration }: PlayerProps) => {
  const container = useContainer()
  const analytics = container.get(Analytics)
  const isMobile = useIsMobile()

  const [videoIsEnded, setVideoIsEnded] = useState(false)

  const muxPlayerRef = useRef<MuxPlayerRefAttributes>(null)
  const videoRef = muxPlayerRef?.current

  const { setRealtimePostProgress } = useRealtimeSections()
  const { updateUserProgress } = useUserProgress({ assetId, sectionId, postId, progress })

  const handleVideoChange = () => {
    setVideoIsEnded(false)
  }
  const handleVideoEnd = () => {
    setVideoIsEnded(true)
  }

  const addEvents = useCallback(
    (player: any) => {
      const handlePlay = () => {
        updateUserProgress(player.currentTime, player.duration)
        if (player.currentTime === 0) {
          analytics.track(Events.HUB.PROGRESS.STARTED, {
            assetId,
            type: 'video',
            userProgress: progress ?? 0,
            current: player.currentTime,
            total: player.duration,
          })
        }
      }

      const handlePause = () => {
        updateUserProgress(player.currentTime, player.duration)
      }

      const handleError = (error: any) => {
        console.warn(error)
        analytics.track(Events.HUB.PROGRESS.REPRODUCTION_ERROR, {
          assetId,
          type: 'video',
          userProgress: progress ?? 0,
          current: player.currentTime,
          total: player.duration,
        })
      }

      const handleEnded = () => {
        analytics.track(Events.HUB.PROGRESS.ENDED, {
          assetId,
          type: 'video',
          userProgress: progress ?? 0,
          current: player.currentTime,
          total: player.duration,
        })
      }

      player.addEventListener('play', handlePlay)
      player.addEventListener('pause', handlePause)
      player.addEventListener('error', handleError)
      player.addEventListener('ended', handleEnded)

      return () => {
        player.removeEventListener('play', handlePlay)
        player.removeEventListener('pause', handlePause)
        player.removeEventListener('error', handleError)
        player.removeEventListener('ended', handleEnded)
      }
    },
    [progress, updateUserProgress, src],
  )

  // Disable download button from video controls and right click
  const removeDownloadButton = () => {
    if (!videoRef) return

    const videoEL = videoRef.media?.nativeEl as HTMLVideoElement
    if (!videoEL) return

    videoEL.setAttribute('controlsList', 'nodownload')
  }

  // Video events to save user progress and analytics
  useEffect(() => {
    if (!videoRef) return

    removeDownloadButton()
    setRealtimePostProgress(progress ?? 0)

    const removeEvents = addEvents(videoRef)
    return () => {
      removeEvents()
    }
  }, [videoRef])

  // Debounce to not re-update same progress time
  const updateRealtimeProgressDebounce = useCallback(
    debounce((currentTime) => {
      setRealtimePostProgress(Math.trunc(currentTime || 0))
    }, 10000),
    [],
  )

  // Update section-content UI progress in realtime
  useEffect(() => {
    const runTimeUpdate = () => {
      const currentTime = Math.trunc(videoRef?.currentTime || 0)
      // Update progress every 3 seconds of video time to avoid too many updates
      if (progress === currentTime || currentTime <= 0 || currentTime % 3 !== 0) return

      // Dont update UI progress if video is in fullscreen to optimize performance
      const isFullScreen = Boolean(document.fullscreenElement)
      if (isFullScreen) return

      updateRealtimeProgressDebounce(currentTime)
    }

    videoRef?.addEventListener('timeupdate', runTimeUpdate)
    return () => {
      videoRef?.removeEventListener('timeupdate', runTimeUpdate)
    }
  }, [setRealtimePostProgress, updateRealtimeProgressDebounce, videoRef])

  // When umount player save user progress
  useEffect(() => {
    const player = videoRef

    return () => {
      if (!player || !player?.duration) return
      // When user progress is same as video time, do not update user progress
      if (player.currentTime === progress) return

      updateUserProgress(player.currentTime, player.duration)
    }
  }, [videoRef, videoRef?.duration])

  // Get Mux playbackId from src to use preview-thumbnail
  const playbackId = new GetAssetPlaybackId().execute(src)

  return (
    <div
      className="w-full flex"
      onContextMenu={(e) => {
        // prevent right click on video to avoid download
        e.preventDefault()
      }}
    >
      {!isMobile && videoIsEnded && <UpNextCard postId={postId} />}

      <MuxPlayerRewrite
        ref={muxPlayerRef}
        src={src}
        playbackId={playbackId}
        onPlay={handleVideoChange}
        onSeeked={handleVideoChange}
        onEnded={handleVideoEnd}
        startTime={(progress ?? 0) >= (duration ?? 0) ? 0 : progress}
      />
    </div>
  )
}
