import { Asset, AssetStatus, MAX_FILE_SIZE, UploadStatus } from '@hub-la/fe-asset'
import { Button } from '@hub-la/shadcn'
import { isNil } from 'lodash'
import { Paperclip } from 'lucide-react'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { AttachedProgress } from '../../../domain/dtos/attached-progress'
import { PostAttachment } from '../post-attachment/post-attachment'

// @todo(joao.pellissari): replace any interface
export interface IProps {
  handleUpload: (file: File, isAttachment: boolean) => void
  isLoading: boolean
  attachedAssets: any[]
  attachedProgressStatus: UploadStatus
  attachedProgress: AttachedProgress[] | null
}

export const PostAttachmentList: React.FC<IProps> = ({
  attachedAssets,
  handleUpload,
  isLoading,
  attachedProgressStatus,
  attachedProgress,
}: IProps) => {
  const { t } = useTranslation()
  const uploadRef = useRef<HTMLInputElement>(null)

  const getPublicationAsset = (): JSX.Element | JSX.Element[] => {
    if (!isNil(attachedAssets)) {
      return attachedAssets.map((asset: Asset, index) => {
        return (
          <PostAttachment
            attachedAssets={attachedAssets}
            attachedProgressStatus={attachedProgressStatus}
            attachedProgress={attachedProgress}
            firstChild={index === 0 ? true : false}
            key={asset.id}
            asset={asset}
            isAvailable={asset.status === AssetStatus.available}
            lastChild={index === attachedAssets.length - 1 ? true : false}
            isLoading={isLoading}
          />
        )
      })
    }

    return <></>
  }

  return (
    <div className="flex flex-col">
      <h3 className="text-2xl font-semibold">{t('assets.title')}</h3>

      {attachedAssets.length > 0 && (
        <div id="publication-attachment-list" className="overflow-x-auto snap-x snap-mandatory">
          <div className="inline-flex flex-row relative">{getPublicationAsset()}</div>
        </div>
      )}

      <input
        type="file"
        data-max-size={MAX_FILE_SIZE}
        id="file-attachment-upload"
        name="file-attachment-upload"
        ref={uploadRef}
        data-testid="file-attachment-upload"
        style={{ display: 'none' }}
        onChange={(e) => {
          handleUpload(e.target.files![0], true)
          e.target.value = ''
        }}
      />

      <Button variant="outline" className="mt-2" onClick={() => uploadRef.current?.click()} loading={isLoading}>
        <Paperclip className="w-4 h-4 mr-2" />
        {t('assets.new')}
      </Button>
    </div>
  )
}
