import { useContainer } from '@hub-la/fe-container'
import { Analytics, AnalyticsEvent } from '@hub-la/fe-core-analytics'
import {
  Table,
  TableBody,
  TableEmptyState,
  TableHead,
  TableHeader,
  TablePagination,
  TableRow,
  useIsMobile,
} from '@hub-la/shadcn'
import { FetchNextPageOptions, InfiniteData, InfiniteQueryObserverResult } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'
import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BookAccount } from '../../../../domain/dtos/book-accounts'
import { GetMovementsOutput } from '../../../../domain/dtos/get-movements-output'
import { TransactionSource } from '../../../../domain/dtos/get-moviments.dto'
import { MovementsByDate } from '../../../../domain/dtos/movements-by-date'
import { ExportMovementsModal } from '../../../components/export-movements-modal/export-movements-modal'
import { useCalculateBalance } from '../../../hooks/use-calculate-balance'
import { Filters } from '../filters'
import { GroupedMovements } from './grouped-movements'
import { TableLoading } from './table-loading'

type MovementsGridProps = {
  accountType: BookAccount
  startBalance: number
  data: InfiniteData<GetMovementsOutput> | undefined
  isLoading?: boolean
  perPage: string
  setPerPage: (perPage: string) => void
  onInvoiceClick?: (invoiceId: string) => void
  onWithdrawalClick?: (withdrawalId: string) => void
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<GetMovementsOutput, unknown>>
  hideTotal?: boolean
  filters: any
  setFilters: (filters: any) => void
}

type HeadCell = {
  key: string
  label: string
  justifyContent?: string
  tooltip?: string
}

const getHeadCells = (hideTotal: boolean, accountType: BookAccount, translate: (key: string) => string): HeadCell[] => {
  const columnsByAccountType: Record<BookAccount, Partial<HeadCell>[]> = {
    available: [
      { key: 'icon' },
      { key: 'value' },
      { key: 'balance' },
      { key: 'description' },
      { key: 'invoiceId', tooltip: translate('movementsGrid.headersHint.invoiceId') },
      { key: 'date' },
    ],
    receivable: [
      { key: 'icon' },
      { key: 'value' },
      { key: 'balance' },
      { key: 'description' },
      { key: 'invoiceId', tooltip: translate('movementsGrid.headersHint.invoiceId') },
      { key: 'date' },
      { key: 'releaseDate' },
    ],
    contested: [
      { key: 'icon' },
      { key: 'value' },
      { key: 'balance' },
      { key: 'description' },
      { key: 'invoiceId', tooltip: translate('movementsGrid.headersHint.invoiceId') },
      { key: 'date' },
    ],
    reserved: [
      { key: 'value' },
      { key: 'icon' },
      { key: 'balance' },
      { key: 'description' },
      { key: 'invoiceId', tooltip: translate('movementsGrid.headersHint.invoiceId') },
      { key: 'date' },
    ],
  }

  let columns = columnsByAccountType[accountType]

  if (hideTotal) {
    columns = columns.filter((c) => c.key !== 'balance')
  }

  return columns.map((c) => ({
    key: c.key ?? '',
    label: translate(`movementsGrid.headers.${c.key}`),
    tooltip: c.tooltip,
  }))
}

export const MovementsGrid = memo((props: MovementsGridProps) => {
  const {
    accountType,
    startBalance,
    data,
    isLoading,
    perPage,
    setPerPage,
    fetchNextPage,
    onInvoiceClick,
    onWithdrawalClick,
    hideTotal = false,
    filters,
    setFilters,
  } = props

  const container = useContainer()
  const analytics = container.get(Analytics)
  const [page, setPage] = useState(1)
  const [isExportMovementsModalOpen, setExportMovementsModalOpen] = useState<boolean>(false)
  const dataWithBalance = useCalculateBalance(data, startBalance)
  const movements = dataWithBalance?.pages[page - 1]?.movements.flat() ?? []
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const numPages = data?.pages.length ?? 1
  const headCells = getHeadCells(isMobile || hideTotal, accountType, t)

  const groupMovementsByDate = (data: MovementsByDate[]): MovementsByDate[] => {
    const groupedMovements: { [date: string]: any[] } = {}
    data.forEach(({ date, movements }) => {
      groupedMovements[date] = (groupedMovements[date] || []).concat(movements)
    })
    return Object.keys(groupedMovements).map((date) => ({
      date,
      movements: groupedMovements[date],
    }))
  }

  const handlePerPageChange = (value: string) => {
    setPage(1)
    setPerPage(value)
  }

  useEffect(() => {
    page > numPages && fetchNextPage()
  }, [page])

  const toggleExportMovementsModal = () => {
    analytics.track(AnalyticsEvent.WALLET.EXPORT_POPUP_CLICKED)
    setExportMovementsModalOpen(!isExportMovementsModalOpen)
  }

  const isRowsEmpty = isEmpty(movements)
  const canRenderEmptyState = !isLoading && isRowsEmpty

  return (
    <div className="mt-3">
      <Filters
        filters={{
          accountType: filters.accountType,
          startDate: filters.startDate,
          endDate: filters.endDate,
          transactionSources: filters.transactionSources as TransactionSource[],
        }}
        setFilters={setFilters}
        onExportMovements={toggleExportMovementsModal}
      />

      <div className="rounded-lg border bg-white overflow-hidden">
        <Table>
          <TableHeader>
            <TableRow>
              {headCells.map((cell, index) => (
                <TableHead key={cell.key}>{cell.label}</TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {isLoading ? (
              Array.from({ length: parseInt(perPage, 10) }).map((_, i) => (
                <TableLoading key={`skeleton-row-${i}`} cols={headCells.length} />
              ))
            ) : canRenderEmptyState ? (
              <TableRow>
                <TableHead colSpan={headCells.length}>
                  <TableEmptyState title={t('invoiceDetails.movementsIsEmpty')} />
                </TableHead>
              </TableRow>
            ) : (
              groupMovementsByDate(movements)?.map((groupedMovement, groupedMovementIdx: number) => (
                <GroupedMovements
                  key={`movement-fragment-rows-${groupedMovementIdx}`}
                  headCells={headCells}
                  accountType={accountType}
                  data={groupedMovement}
                  idx={groupedMovementIdx}
                  onInvoiceClick={onInvoiceClick}
                  onWithdrawalClick={onWithdrawalClick}
                />
              ))
            )}
          </TableBody>
        </Table>
      </div>

      <div className="flex justify-end px-2 mt-3 mb-3">
        <TablePagination
          page={page}
          setPage={setPage}
          lastPage={numPages + (data?.pages[numPages - 1]?.after ? 1 : 0)}
          pageSize={parseInt(perPage, 10)}
          setPageSize={(pageSize) => handlePerPageChange(String(pageSize))}
        />
      </div>

      <ExportMovementsModal
        open={isExportMovementsModalOpen}
        onClose={() => setExportMovementsModalOpen(false)}
        accountType={accountType}
      />
    </div>
  )
})
