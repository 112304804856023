import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CreateCollaboratorInput } from '../../domain/dtos/create-collaborator-input'
import { QueryKey } from '../../domain/dtos/query-keys'
import { CreateCollaborator } from '../../usecases/create-collaborator'

export const useCreateCollaborator = () => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation<null, Error, CreateCollaboratorInput>(
    [QueryKey.postCreateCollaborators],
    (input: CreateCollaboratorInput) => new CreateCollaborator(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess: () => queryClient.invalidateQueries({ queryKey: [QueryKey.getMyCollaborators] }),
    },
  )
}
