import { createTheme } from '@mui/material/styles'
import { makeComponents } from './components'

import { makePalette } from './tokens/palette'
import { TOKENS_DARK, TOKENS_LIGHT } from './tokens/tokens'
import { typography } from './typography'

export { Theme } from '@mui/material/styles'

export const theme = {
  light: createTheme({
    typography,
    palette: makePalette('light', TOKENS_LIGHT),
    spacing: 4,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 840,
        lg: 1200,
        xl: 1728,
      },
    },
  }),
  dark: createTheme({
    typography,
    palette: makePalette('dark', TOKENS_DARK),
    spacing: 4,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 840,
        lg: 1200,
        xl: 1728,
      },
    },
  }),
}

theme.light.components = makeComponents(theme.light)
theme.dark.components = makeComponents(theme.dark)
