import { memo } from 'react'
import { createEnumParam, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { InvoiceStatus } from '../../../domain/enums/invoice-status'
import { InvoiceType } from '../../../domain/enums/invoice-type'
import { PaymentMethod } from '../../../domain/enums/payment-method'
import { Period } from '../../../domain/enums/period'
import { GetDateRangeFromPeriod } from '../../../usecases/get-date-range-from-period'
import { useGetInvoicesSummaries } from '../../hooks/use-get-invoices-summaries'
import { useGetOffers } from '../../hooks/use-get-offers'
import { useRefetch } from '../../hooks/use-refetch'
import { CommaDelimitedArrayParam } from '../../utils/query-string-encoder'
import { Filters } from './filters'
import { InvoicesTable } from './invoices-table/invoices-table'
import { NetRevenue } from './net-revenue/net-revenue'
import { TotalInvoices } from './total-invoices/total-invoices'
import { TotalSale } from './total-sale/total-sale'

export const Invoices = memo(() => {
  const { data: offers = [], isFetching } = useGetOffers()
  const isFetchingOffers = isFetching && offers.length <= 0

  const initialDateFilter = new GetDateRangeFromPeriod().execute(Period.MONTHLY)
  const PeriodEnumParam = createEnumParam([Period.HOURLY, Period.DAILY, Period.WEEKLY, Period.MONTHLY, Period.YEARLY])

  const [filters, setFilters] = useQueryParams({
    period: withDefault(PeriodEnumParam, Period.DAILY),
    search: withDefault(StringParam, ''),
    offerIds: withDefault(CommaDelimitedArrayParam, []),
    statuses: withDefault(CommaDelimitedArrayParam, ['paid']),
    types: withDefault(CommaDelimitedArrayParam, []),
    methods: withDefault(CommaDelimitedArrayParam, []),
    startDate: withDefault(StringParam, initialDateFilter.from),
    endDate: withDefault(StringParam, initialDateFilter.to),
    page: withDefault(NumberParam, 1),
    pageSize: withDefault(NumberParam, 25),
    utmSource: withDefault(StringParam, ''),
    utmMedium: withDefault(StringParam, ''),
    utmCampaign: withDefault(StringParam, ''),
    utmContent: withDefault(StringParam, ''),
    utmTerm: withDefault(StringParam, ''),
  })

  const {
    data: summaries,
    isFetching: isFetchingSummaries,
    refetch,
  } = useGetInvoicesSummaries(
    {
      offers,
      search: filters.search,
      offerIds: filters.offerIds as string[],
      statuses: filters.statuses as InvoiceStatus[],
      types: filters.types as InvoiceType[],
      methods: filters.methods as PaymentMethod[],
      startDate: filters.startDate,
      endDate: filters.endDate,
      page: filters.page,
      pageSize: parseInt(filters.pageSize.toString(), 10),
      utmSource: filters.utmSource,
      utmMedium: filters.utmMedium,
      utmCampaign: filters.utmCampaign,
      utmContent: filters.utmContent,
      utmTerm: filters.utmTerm,
    },
    isFetching,
  )

  useRefetch({ offers, ...filters, page: undefined, pageSize: undefined }, refetch)

  const isCardLoading = (isFetchingSummaries && summaries.count <= 0) || isFetchingOffers

  return (
    <div className="flex flex-col gap-4">
      <Filters filters={filters} setFilters={setFilters} offers={offers} />

      <div className="grid md:grid-cols-3 gap-3">
        <div>
          <TotalSale isLoading={isCardLoading} amount={summaries?.sumPaid} />
        </div>

        <div>
          <NetRevenue isLoading={isCardLoading} amount={summaries?.netRevenue} />
        </div>

        <div>
          <TotalInvoices isLoading={isCardLoading} amount={summaries?.count} />
        </div>
      </div>

      <InvoicesTable filters={filters} setFilters={setFilters} offers={offers} isLoading={isFetchingOffers} />
    </div>
  )
})
