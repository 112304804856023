import { Button, useToast } from '@hub-la/shadcn'
import { ArrowLeft } from 'lucide-react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { InvoiceStatus } from '../../../domain/enums/invoice-status'
import { AppHeader } from '../../components/app-header'
import { useGetInvoice } from '../../hooks/use-get-invoice'
import { Description } from './components/description'
import { Header } from './components/header'
import { PaymentDetails } from './components/payment-details'
import { Loading } from './loading'

export const UserInvoicesDetailsPage = () => {
  const history = useHistory()
  const { toast } = useToast()
  const { t } = useTranslation()

  const { invoiceId } = useParams<{ invoiceId: string }>()
  const { data: invoice, error, isFetching } = useGetInvoice(invoiceId)

  useEffect(() => {
    if (error) {
      toast({
        title: t('userInvoices.error'),
        description: t((error as Error)?.message),
        variant: 'destructive',
      })
    }
  }, [error, toast, t])

  return (
    <div className="mb-4 flex flex-col container gap-4">
      <AppHeader />

      {isFetching && !invoice ? (
        <Loading />
      ) : (
        <div className="w-full flex flex-col gap-4">
          <Button variant="ghost" onClick={() => history.goBack()} className="w-min gap-2">
            <ArrowLeft className="h-4 w-4" />
            {t('back')}
          </Button>

          <div className="flex flex-col gap-4">
            <Header
              invoice={{
                id: invoice?.id ?? '',
                status: invoice?.status ?? InvoiceStatus.PAID,
                dueDate: invoice?.dueDate ?? '',
                paidAt: invoice?.paidAt ?? '',
                createdAt: invoice?.createdAt ?? '',
                total: invoice?.totalCents ?? 0,
                installment: invoice?.installment ?? 1,
                installments: invoice?.installments ?? 1,
                smartInstallmentRef: invoice?.smartInstallmentRef,
              }}
              product={{
                name: invoice?.items[0]?.productName ?? '',
              }}
              payer={{
                fullName: invoice?.payer.identity?.fullName ?? '',
              }}
              seller={{
                fullName: invoice?.seller.identity?.fullName ?? '',
              }}
            />

            <PaymentDetails
              paymentMethod={invoice?.paymentMethod}
              cardDetails={invoice?.cardDetails}
              softDescriptor={invoice?.softDescriptor}
              qrcodeData={invoice?.qrcodeData}
              qrcodeText={invoice?.qrcodeText}
              status={invoice?.status ?? InvoiceStatus.PAID}
            />

            <Description
              id={invoice?.id ?? ''}
              detail={{
                products: invoice?.amountDetail?.products ?? [],
                totalCents: invoice?.amountDetail?.totalCents ?? 0,
                couponCode: invoice?.amountDetail?.couponCode,
                discountCents: invoice?.amountDetail?.discountCents,
                installmentFeeCents: invoice?.amountDetail?.installmentFeeCents,
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}
