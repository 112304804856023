import React, { useEffect, useMemo, useState } from 'react'
import { useCheckHaveChanged } from '../../hooks/use-check-have-changed'
import { useGetInvoices } from '../../hooks/use-get-invoices'

import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableEmptyState,
  TableHead,
  TableHeader,
  TablePagination,
  TableRow,
} from '@hub-la/shadcn'
import { flexRender, getCoreRowModel, PaginationState, useReactTable } from '@tanstack/react-table'
import { useHistory } from 'react-router-dom'
import { columns } from './invoice-table-columns'

type Props = {
  subscriptionId: string
}

export const InvoiceTable: React.FC<Props> = ({ subscriptionId }) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageSize: 25,
    pageIndex: 1,
  })

  const {
    data = {
      items: [],
      total: 0,
      page: pagination.pageIndex,
      pageSize: pagination.pageSize,
      lastPage: 1,
    },
    isFetching,
    refetch,
  } = useGetInvoices({
    subscriptionId: subscriptionId,
    page: pagination.pageIndex,
    pageSize: pagination.pageSize,
  })

  const canRefetch = useCheckHaveChanged({ page: pagination.pageIndex, pageSize: pagination.pageSize })

  useEffect(() => {
    canRefetch && refetch()
  }, [canRefetch, refetch])

  const tableData = useMemo(() => (isFetching ? Array(pagination.pageSize).fill({}) : data.items), [isFetching, data])

  const history = useHistory()

  const tableColumns = useMemo(
    () =>
      isFetching
        ? columns.map((column) => ({
            ...column,
            cell: () => <Skeleton className="h-[14px] w-[60%] rounded-sm" />,
          }))
        : columns,
    [isFetching],
  )

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    state: {
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center w-full gap-2">
        <h2 className="text-lg font-bold">Faturas</h2>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  onClick={() => {
                    const cellValue = row.getAllCells()[2].getValue()
                    history.push(`/dashboard/sales/${cellValue}`)
                  }}
                >
                  {row.getVisibleCells().map((cell, cellIndex) => (
                    <TableCell
                      className={`text-foreground py-2.5 ${cellIndex === 2 ? 'max-w-[100px] truncate' : ''} ${
                        cellIndex === 1 ? 'font-medium' : 'font-normal'
                      }
            ${cellIndex === 2 ? 'text-xs' : 'text-sm'}`}
                      key={cell.id}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableEmptyState title="Vazio" />
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex justify-end">
        <TablePagination
          page={pagination.pageIndex}
          setPage={(page) => table.setPageIndex(page)}
          lastPage={data.lastPage}
          pageSize={pagination.pageSize}
          setPageSize={(pageSize) => table.setPageSize(pageSize)}
        />
      </div>
    </div>
  )
}
