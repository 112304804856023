import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@hub-la/shadcn'
import { Monitor, Smartphone } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

export enum View {
  Mobile = 'mobile',
  Desktop = 'desktop',
}

interface SelectViewProps {
  onChange: (mode: View) => void
  value: View
}

export const SelectView: React.FC<SelectViewProps> = ({ onChange, value }) => {
  const { t } = useTranslation()

  return (
    <Select onValueChange={(value) => onChange(value as View)} value={value}>
      <SelectTrigger className="w-[130px]">
        <SelectValue>
          {value === View.Mobile ? (
            <div className="flex items-center">
              <Smartphone className="mr-2 h-4 w-4 text-gray-500" />
              <span className="text-sm text-gray-700">{t('checkoutSettings.view.mobile')}</span>
            </div>
          ) : (
            <div className="flex items-center">
              <Monitor className="mr-2 h-4 w-4 text-gray-500" />
              <span className="text-sm text-gray-700">{t('checkoutSettings.view.desktop')}</span>
            </div>
          )}
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        <SelectItem value={View.Desktop}>
          <div className="flex items-center">
            <Monitor className="mr-2 h-4 w-4 text-gray-500" />
            <span className="text-sm text-gray-700">{t('checkoutSettings.view.desktop')}</span>
          </div>
        </SelectItem>
        <SelectItem value={View.Mobile}>
          <div className="flex items-center">
            <Smartphone className="mr-2 h-4 w-4 text-gray-500" />
            <span className="text-sm text-gray-700">{t('checkoutSettings.view.mobile')}</span>
          </div>
        </SelectItem>
      </SelectContent>
    </Select>
  )
}
