import React from 'react'
import { useFormContext } from 'react-hook-form'
import CheckoutMockup from '../../assets/checkout-mockup-desktop.svg'
import DesktopTop from '../../assets/desktop-top.svg'
import SummaryMockup from '../../assets/summary-mockup-desktop.svg'
import { OfferSchemaValidationType } from '../../validations/offer-validation'
import { DesktopBanner } from './desktop-banner'
import { DesktopProductHeader } from './desktop-product-header'
import { Timer } from './timer'

interface DesktopPreviewProps {
  group: {
    picture?: string | null
    name: string
    owner: string
  }
}

export const DesktopPreview: React.FC<DesktopPreviewProps> = ({ group }) => {
  const { watch } = useFormContext<OfferSchemaValidationType>()
  const { isTimer, timerBgColor, bannerDesktopUrl, creatorLogoUrl } = watch('checkout')

  return (
    <div className="w-full bg-white rounded-lg overflow-hidden relative shadow-2xl">
      <img className="w-full h-auto" src={DesktopTop} alt="Desktop top mockup" />

      {isTimer && timerBgColor && <Timer />}

      {bannerDesktopUrl && <DesktopBanner />}

      <div className="flex">
        <div className="flex flex-col">
          {creatorLogoUrl && !bannerDesktopUrl && (
            <img
              style={{
                maxHeight: '40px',
                maxWidth: '90px',
                objectFit: 'cover',
                margin: '12px 0 0 58px',
              }}
              width="100%"
              height="auto"
              src={creatorLogoUrl}
              alt="Creator logo"
            />
          )}
          <img width="100%" height="auto" src={CheckoutMockup} alt="mockup da parte de baixo computador" />
        </div>
        <div>
          <DesktopProductHeader group={group} />
          <img width="100%" height="auto" src={SummaryMockup} alt="mockup da parte de baixo computador" />
        </div>
      </div>
    </div>
  )
}
