import { Table, TableBody, TableCell, TableHead, TableHeader, TablePagination, TableRow } from '@hub-la/shadcn'
import { times } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { CreateEventProduct } from '../../../components/create-event-product'
import { formatDateRange } from '../../../components/format-date-range'
import { useListEvents } from '../../../hooks/use-list-events'
import { FutureEventsTableLoading } from './future-events-table-loading'

interface EventsProps {
  timeScope: 'past' | 'future'
}

export const Events = ({ timeScope }: EventsProps) => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(25)

  const { t } = useTranslation()
  const { data = { items: [], lastPage: 0, page: 1, pageSize: 1, total: 0 }, isFetching } = useListEvents({
    page,
    pageSize,
    timeScope,
  })

  const headCells = [
    { key: 'event', label: 'Evento', sortable: false, width: 100 },
    { key: 'date', label: 'Data', sortable: false, width: 100 },
    { key: 'local', label: 'Local', sortable: false, width: 90 },
  ]

  const isRowsEmpty = isEmpty(data.items)
  const canRenderEmptyState = !isFetching && isRowsEmpty
  const isLoading = isFetching && isRowsEmpty

  return (
    <div className="flex flex-col h-full w-full space-y-3">
      <div className="flex justify-between items-baseline">
        <p className="text-sm">{data?.items?.length} afiliações</p>
        <CreateEventProduct />
      </div>

      <div className="flex flex-col gap-4 rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              {headCells.map((cell) => (
                <TableHead key={cell.key} style={{ width: cell.width }}>
                  {cell.label}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>

          <TableBody>
            {isLoading
              ? times(pageSize, (i) => <FutureEventsTableLoading key={`skeleton-row-${i}`} />)
              : data?.items?.map((event) => {
                  const { date, time, weekDay } = formatDateRange(event.eventData.startDate, event.eventData.endDate)
                  const { street = '', number = '' } = event.eventData.location ?? {}
                  return (
                    <TableRow key={event.id} className="hover:bg-gray-100 cursor-pointer">
                      <TableCell>
                        <Link to={`/edit/${event.id}/#content`} className="block">
                          <div className="flex flex-col">
                            <span>{event.name}</span>

                            <span className="text-sm text-gray-500">{weekDay}</span>
                          </div>
                        </Link>
                      </TableCell>

                      <TableCell>
                        <div className="flex flex-col">
                          <span>{date}</span>
                          <span className="text-sm text-gray-500">{time}</span>
                        </div>
                      </TableCell>

                      <TableCell>{event.eventData.location ? `${street}, ${number}` : 'Sem local definido'}</TableCell>
                    </TableRow>
                  )
                })}
          </TableBody>
        </Table>

        {canRenderEmptyState && (
          <div className="text-center py-4">
            <p>{t('empty')}</p>
          </div>
        )}
      </div>

      {!isRowsEmpty && (
        <TablePagination
          page={page}
          setPage={setPage}
          lastPage={data.lastPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </div>
  )
}
