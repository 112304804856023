import { Input } from '@hub-la/shadcn'
import React, { useEffect, useRef, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

type EditableTextProps = {
  onSubmit: (value: string) => void
  value: string
  className?: string
}

export const EditableText: React.FC<EditableTextProps> = ({ value: initialValue, onSubmit, className }) => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [value, setValue] = useState<string>(inputRef.current?.value ?? initialValue)
  const sizer = useRef<HTMLSpanElement>(null)

  const handleOnAttributeChange = useDebouncedCallback((value) => {
    value !== initialValue && onSubmit(value)
  }, 1000)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const handleSize = (value: string) => {
    if (sizer.current && inputRef.current) {
      sizer.current.textContent = value
      const width = sizer.current.offsetWidth
      inputRef.current.style.width = `${width + 10}px` // Add some padding
    }
  }

  useEffect(() => {
    handleSize(value)
  }, [])

  return (
    <>
      <Input
        ref={inputRef}
        value={value}
        onClick={(e) => e.stopPropagation()}
        onChange={(event) => {
          const value = event.target.value
          handleOnAttributeChange(value)
          setValue(value)
          handleSize(value)
        }}
        className="border-none focus-visible:outline-none focus-visible:border-none focus-visible:ring-0 focus-visible:bg-zinc-100 px-1 h-6 text-sm font-medium"
      />
      <span
        ref={sizer}
        style={{
          position: 'absolute',
          visibility: 'hidden',
          whiteSpace: 'pre',
          font: 'inherit',
          letterSpacing: 'inherit',
          textTransform: 'inherit',
          padding: 'inherit',
        }}
      />
    </>
  )
}
