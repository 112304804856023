import { Input, Label } from '@hub-la/shadcn'
import debounce from 'lodash/debounce'
import { Search } from 'lucide-react'
import { useTranslation } from 'react-i18next'

type ActionsProps = {
  onSearchChange: (value: string) => void
}

export const Actions = ({ onSearchChange }: ActionsProps) => {
  const { t } = useTranslation()

  const handleSearchChange = debounce((value) => onSearchChange(value), 500)

  return (
    <div className="mb-6 mt-2 w-full max-w-80 flex justify-between items-center">
      <Label htmlFor="search" className="sr-only">
        {t('tabs.invites.actions.search.label')}
      </Label>

      <div className="relative">
        <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-500" />

        <Input
          id="search"
          className="pl-8"
          placeholder={t('tabs.invites.actions.search.placeholder')}
          onChange={(e) => handleSearchChange(e.target.value)}
        />
      </div>
    </div>
  )
}
