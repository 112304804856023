import { Button } from '@hub-la/shadcn'
import { Share, Trash2 } from 'lucide-react'
import { useState } from 'react'
import { Offer } from '../../../domain/dtos/offer'
import { CouponStatus } from '../../../domain/enums/coupon-status'
import { CouponStatusStats } from '../../components/coupon-status-stats'
import { DeleteCouponModal } from '../../components/delete-coupon-modal'
import { ShareCouponModal } from '../../components/share-coupon-modal'
import { useChangeCouponStatus } from '../../hooks/use-change-coupon-status'

type HeaderProps = {
  id: string
  code: string
  status: CouponStatus
  onClose: () => void
  offers?: Offer[]
}

export const Header = ({ id, code, status, onClose, offers }: HeaderProps) => {
  const [isDeleteCouponOpen, setIsDeleteCouponOpen] = useState<boolean>(false)
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false)

  const { mutateAsync: changeCouponStatus, isLoading } = useChangeCouponStatus()

  const canChangeStatus = [CouponStatus.ACTIVE, CouponStatus.INACTIVE].includes(status)

  const onChangeStatus = () => {
    changeCouponStatus({ id, currentStatus: status })
  }

  return (
    <div className="flex flex-col lg:flex-row justify-between items-start lg:items-end space-y-5 lg:space-y-0 lg:space-x-5">
      <div className="space-y-1">
        <div className="flex items-center space-x-2">
          <h3 className="text-3xl font-semibold">{code}</h3>
          <CouponStatusStats status={status} />
        </div>
        <p className="text-sm text-muted-foreground">{id}</p>
      </div>

      <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 w-full md:w-auto">
        <Button variant="outline" onClick={() => setIsShareOpen(true)}>
          <Share className="mr-2 h-4 w-4" />
          Compartilhar links
        </Button>

        {canChangeStatus && (
          <Button variant="outline" onClick={onChangeStatus} loading={isLoading}>
            {status === CouponStatus.ACTIVE ? 'Inativar cupom' : 'Ativar cupom'}
          </Button>
        )}

        <Button variant="outline" onClick={() => setIsDeleteCouponOpen(true)}>
          <Trash2 className="mr-2 h-4 w-4 text-red-500" />
          Apagar cupom
        </Button>
      </div>

      <ShareCouponModal
        id={id}
        open={isShareOpen}
        couponCode={code}
        offers={offers}
        onClose={() => setIsShareOpen(false)}
      />

      <DeleteCouponModal
        couponId={id}
        couponCode={code}
        open={isDeleteCouponOpen}
        onClose={() => setIsDeleteCouponOpen(false)}
        onSuccess={onClose}
      />
    </div>
  )
}
