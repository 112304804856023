import React from 'react'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, Separator } from '@hub-la/shadcn'
import { Info } from 'lucide-react'

type Props = {
  title: string
  content: React.ReactElement
  tooltip?: string
  fontWeight?: 'bold' | 'lighter'
  showDivider?: boolean
  direction?: 'column' | 'row' | 'row-reverse' | 'column-reverse'
}

const InfoTooltip: React.FC<{ title: string }> = ({ title }) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger>
        <Info className="h-4 w-4" />
      </TooltipTrigger>
      <TooltipContent>
        <p>{title}</p>
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
)

export const ContentRow: React.FC<Props> = ({
  title,
  content,
  tooltip,
  fontWeight = 'normal',
  showDivider = false,
  direction,
}) => {
  const directionClasses = direction ? `flex-${direction}` : 'flex-col sm:flex-col md:flex-col lg:flex-row'

  const alignClasses = direction ? 'items-start' : 'items-start sm:items-start md:items-start lg:items-center'

  return (
    <div className="w-full space-y-2">
      <div className={`flex ${directionClasses} ${alignClasses} justify-between gap-1`}>
        <div className="flex items-center gap-2">
          <p
            className={`text-body ${
              fontWeight === 'bold' ? 'font-bold' : fontWeight === 'lighter' ? 'font-light' : 'font-normal'
            }`}
          >
            {title}
          </p>
          {tooltip && <InfoTooltip title={tooltip} />}
        </div>
        {content}
      </div>
      {showDivider && <Separator className="my-2" />}
    </div>
  )
}
