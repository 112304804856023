import { Table, TableBody, TableCell, TableHead, TableHeader, TablePagination, TableRow } from '@hub-la/shadcn'
import isEmpty from 'lodash/isEmpty'
import times from 'lodash/times'
import { EyeOff } from 'lucide-react'
import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import placeholderAvatar from '../../assets/placeholder-image.svg'
import { ProductStatusTag } from '../../components/product-status-tag'
import { useListProducts } from '../../hooks/use-list-products'
import { ProductsTableLoading } from './products-table-loading'

export const Products = () => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(25)

  const { t } = useTranslation()
  const { data = { items: [], lastPage: 0, page: 1, pageSize: 1, total: 0 }, isFetching } = useListProducts({
    page,
    pageSize,
  })

  const headCells = [
    { key: 'product', label: 'Produto', sortable: false, width: 200 },
    { key: 'status', label: 'Status', sortable: false, width: 60 },
    { key: 'createdAt', label: 'Data de criação', sortable: false, width: 90 },
  ]

  const isRowsEmpty = isEmpty(data.items)
  const canRenderEmptyState = !isFetching && isRowsEmpty
  const isLoading = isFetching && isRowsEmpty

  return (
    <div className="flex flex-col gap-4">
      {/* <div className="flex justify-between items-end">
        <p className="text-sm">{data.total} produtos</p>
      </div> */}

      <div className="flex flex-col gap-4 bg-white rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              {headCells.map((cell) => (
                <TableHead key={cell.key} className="font-medium" style={{ minWidth: cell.width }}>
                  {cell.label}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>

          <TableBody>
            {isLoading
              ? times(pageSize, (i) => <ProductsTableLoading key={`skeleton-row-${i}`} />)
              : data.items.map((product) => (
                  <TableRow key={product.id} className="hover:bg-gray-50">
                    <TableCell>
                      <Link to={`/edit/${product.id}/#content`} className="flex items-center space-x-4">
                        <div className="relative">
                          <img
                            src={product.picture || placeholderAvatar}
                            alt={product.name}
                            className="w-16 h-auto aspect-[218/142] rounded object-cover"
                          />

                          {product.isVisible === false && (
                            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded">
                              <EyeOff className="text-white" />
                            </div>
                          )}
                        </div>

                        <div>
                          <p className="text-base font-medium">{product.name}</p>
                          <p className="text-sm text-gray-500">{product.mainOfferId}</p>
                        </div>
                      </Link>
                    </TableCell>

                    <TableCell>
                      <ProductStatusTag status={product.status} />
                    </TableCell>

                    <TableCell>{moment(product.createdAt).format('D MMM. YYYY')}</TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>

        {canRenderEmptyState && (
          <div className="text-center py-4">
            <p>{t('empty')}</p>
          </div>
        )}
      </div>

      {!isRowsEmpty && (
        <TablePagination
          page={page}
          setPage={setPage}
          lastPage={data.lastPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </div>
  )
}
