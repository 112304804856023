import { container } from '@hub-la/fe-container'
import { UserInvoicesPage, UserInvoicesProvider } from '@hub-la/fe-user-invoices'

export const UserInvoicesInit = () => {
  return (
    <UserInvoicesProvider container={container}>
      <UserInvoicesPage />
    </UserInvoicesProvider>
  )
}
