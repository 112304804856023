import {
  Asset,
  GetFileMimetype,
  HasFileValidSize,
  isValidType,
  UploadStatus,
  useSaveAsset,
  useUpdateAssetDuration,
} from '@hub-la/fe-asset'
import { useContainer } from '@hub-la/fe-container'
import { Analytics } from '@hub-la/fe-core-analytics'
import {
  ALERT_TYPE,
  CardUploader,
  CustomCover,
  ERROR_TYPE,
  Events,
  makePostCreateAlertData,
  makePostErrorViewedData,
  PostType,
} from '@hub-la/fe-post'
import { Input } from '@hub-la/shadcn'
import { useFormikContext } from 'formik'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as uuid from 'uuid'
import { Values } from '../../../domain/dtos/values'
import { Editor } from '../../components/editor-main-content/editor'
import { EventForm } from '../../components/event-form/event-form'
import { PostAttachmentList } from '../../components/publication-attachment-list/post-attachment-list'

export interface CreatePublicationProps {
  postType: PostType | null
  productId: string
  userId: string
}

export enum StatusUpload {
  'START',
  'SUCCESS',
}

export const CreatePublication: React.FC<CreatePublicationProps> = (props) => {
  const { productId, userId, postType } = props

  const { setFieldValue, values, setFieldTouched } = useFormikContext<Values>()
  const { title, subtitle, content, attachedAssets, attachedProgress, attachedProgressStatus } = values

  const { t } = useTranslation()
  const container = useContainer()
  const saveAsset = useSaveAsset()
  const updateAssetDuration = useUpdateAssetDuration()
  const [, setErrorMessage] = useState<string>('')

  const analytics = container.get(Analytics)

  /**
   * @todo Attachments will be refactor next cycle
   */
  const dispatchAsset = useCallback(
    (asset: Asset, status: StatusUpload, isAttachment: boolean) => {
      if (status === StatusUpload.START) {
        setFieldValue('attachedProgressStatus', UploadStatus.STARTED)

        // asset with uuid generate by front to get file props
        if (attachedAssets) {
          setFieldValue('attachedAssets', [...attachedAssets, { ...asset }])
        } else {
          setFieldValue('attachedAssets', [asset])
        }
      }
      if (status === StatusUpload.SUCCESS) {
        setFieldValue('attachedProgressStatus', UploadStatus.LOADED, true)
        // formik not update validation when setFieldValue
        setTimeout(() => {
          setFieldTouched('attachedProgressStatus', true, true)
        }, 500)
        // db asset
        if (attachedAssets) {
          setFieldValue('attachedAssets', [...attachedAssets, { ...asset }])
        } else {
          setFieldValue('attachedAssets', [asset])
        }
      } else {
        setFieldValue('attachedProgressStatus', UploadStatus.LOADING)
        makePostCreateAlertData({
          alertType: ALERT_TYPE.VIDEO_LOADING_ALERT,
          userId,
          productId,
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attachedAssets],
  )

  const dispatchProgress = (percentage: number, assetId: string, isAttachment: boolean) => {
    setFieldValue('attachedProgressStatus', UploadStatus.LOADING)
    setFieldTouched('attachedProgressStatus', true, true)

    if (attachedProgress && attachedProgress.length > 0) {
      if (attachedProgress.findIndex((el) => el.attachedId === assetId) === -1) {
        setFieldValue('attachedProgress', [
          ...attachedProgress,
          {
            attachedId: assetId,
            progress: percentage,
          },
        ])
      }
    } else {
      setFieldValue('attachedProgress', [
        {
          attachedId: assetId,
          progress: percentage,
        },
      ])
    }
  }

  const handleUpload = async (file: File, isAttachment: boolean) => {
    if (file) {
      const { name, size } = file
      const type = await new GetFileMimetype().execute(file)

      try {
        const hasFileValidSize = new HasFileValidSize().execute(size)
        isValidType(type, isAttachment)
      } catch (e: any | Error) {
        analytics.track(
          Events.HUB.REJECTED,
          makePostErrorViewedData({
            errorType: ERROR_TYPE.UPLOAD_ASSET_FAIL,
            userId,
            productId,
          }),
        )
      }

      const asset: Asset = {
        id: uuid.v4(),
        name: name,
        mimeType: type,
        file: file,
        ownerId: userId,
      }

      dispatchAsset(asset, StatusUpload.START, isAttachment)

      saveAsset
        .mutateAsync({
          payload: asset,
          onUploadProgress: (percentage: number) => {
            dispatchProgress(percentage, asset.id, isAttachment)
          },
        })
        .then(async (data) => {
          if (data !== undefined) {
            asset.id = data.id
            asset.metadata = data.metadata || {
              duration: null,
              pages: null,
              size: 0,
              thumbnailUrl: null,
            }

            asset.url = data.url
            asset.progress = data.progress
            asset.name = data.name
            asset.metadata.size = asset?.file?.size || 0

            dispatchAsset(asset, StatusUpload.SUCCESS, isAttachment)
            await updateAssetDuration.mutateAsync(asset.id, asset.file)
            return data
          }

          return
        })

        .catch((e: any | Error) => {
          analytics.track(
            Events.HUB.REJECTED,
            makePostErrorViewedData({
              errorType: ERROR_TYPE.SAVE_ASSET_FAIL,
              userId,
              productId,
            }),
          )
        })
    }
  }

  return (
    <div className="w-full flex-1">
      <div className="w-full max-w-4xl h-full mt-14 mx-auto p-8 flex flex-col gap-6 overflow-y-auto">
        <div className="flex flex-col gap-2">
          <Input
            className="p-0 border-none text-3xl focus-visible:ring-0 focus-visible:ring-offset-0"
            data-testid="post-title"
            value={title}
            autoFocus
            placeholder={t('content.form.title')}
            onChange={(e) => {
              const { value } = e.target
              setFieldValue('title', value)
            }}
          />

          <Input
            className="p-0 border-none text-base focus-visible:ring-0 focus-visible:ring-offset-0"
            data-testid="post-subtitle"
            value={subtitle}
            placeholder={t('content.form.subtitle')}
            onChange={(e) => {
              e.preventDefault()
              const { value } = e.target
              setFieldValue('subtitle', value)
            }}
          />
        </div>

        <CardUploader hasLoading fieldName="mainMedia" postType={postType} />

        <Editor
          setErrorMessage={setErrorMessage}
          placeholder={t('content.form.content')}
          data-testid="post-content"
          onChange={(value) => setFieldValue('content', value)}
          value={content}
        />

        <PostAttachmentList
          attachedProgress={attachedProgress}
          attachedAssets={attachedAssets}
          attachedProgressStatus={attachedProgressStatus}
          handleUpload={handleUpload}
          isLoading={saveAsset.isLoading}
        />

        <EventForm postType={postType} />

        <div className="mt-8">
          <CustomCover
            title={t('customCoverUpload.post.title')}
            description={t('customCoverUpload.post.description')}
          />
        </div>
      </div>
    </div>
  )
}
