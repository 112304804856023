import React, { createContext, useCallback, useReducer } from 'react'
import { Snackbar, Stack } from '../components/components'

type SnackbarItem = {
  id: string
  message: string
  /**  @description Time in milliseconds to auto hide the snackbar or null to keep it open */
  autoHideDuration?: number | null
  closeable?: boolean
  variant: 'positive' | 'negative' | 'informative' | 'neutral'
  position?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'bottomCenter' | 'topCenter'
  /** @description Callback to be called when the snackbar is closed by the user or autoHideDuration */
  onClose?: () => void
}

type SnackbarContainer = {
  position: string
  style: {
    top?: string
    bottom?: string
    left?: string
    right?: string
    transform?: string
    justifyContent?: string
    alignItems?: string
  }
}

type SnackbarContextType = {
  snackbarList: SnackbarItem[]
  addSnackbar: (snackbar: Omit<SnackbarItem, 'id'>) => void
  removeSnackbar: (id: string) => void
}

type SnackbarReducerAction =
  | { type: 'ADD_SNACKBAR'; payload: SnackbarItem }
  | { type: 'REMOVE_SNACKBAR'; payload: string }

export const SnackbarContext = createContext<SnackbarContextType>({
  snackbarList: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addSnackbar: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  removeSnackbar: () => {},
})

const snackbarReducer = (state: SnackbarItem[], action: SnackbarReducerAction) => {
  switch (action.type) {
    case 'ADD_SNACKBAR':
      return [action.payload, ...state].slice(0, 10)
    case 'REMOVE_SNACKBAR':
      return state.filter((snackbar) => snackbar.id !== action.payload)
    default:
      return state
  }
}

const snackbarContainerPositions: SnackbarContainer[] = [
  { position: 'topLeft', style: { top: '1em', left: '1em', alignItems: 'flex-start' } },
  { position: 'topRight', style: { top: '1em', right: '1em', alignItems: 'flex-end' } },
  { position: 'bottomLeft', style: { bottom: '1em', left: '1em', alignItems: 'flex-start' } },
  { position: 'bottomRight', style: { bottom: '1em', right: '1em', alignItems: 'flex-end' } },
  {
    position: 'bottomCenter',
    style: {
      bottom: '1em',
      left: '50%',
      transform: 'translateX(-50%)',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  {
    position: 'topCenter',
    style: {
      top: '1em',
      left: '50%',
      transform: 'translateX(-50%)',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
]

type Props = {
  children?: React.ReactNode
}

export const SnackbarProvider: React.FC<Props> = ({ children }) => {
  const [snackbarList, dispatch] = useReducer(snackbarReducer, [])

  const addSnackbar = useCallback((snackbar: Omit<SnackbarItem, 'id'>): string => {
    const newSnackbar: SnackbarItem = {
      ...snackbar,
      id: Math.random().toString(36).substr(2, 9),
      position: snackbar.position || 'bottomCenter',
    }

    dispatch({ type: 'ADD_SNACKBAR', payload: newSnackbar })

    return newSnackbar.id
  }, [])

  const removeSnackbar = useCallback((id: string) => {
    dispatch({ type: 'REMOVE_SNACKBAR', payload: id })
  }, [])

  const snackbarContainerHasSnackbars = (position: string) => {
    return snackbarList.some((snackbar) => snackbar.position === position)
  }

  return (
    <SnackbarContext.Provider value={{ snackbarList, addSnackbar, removeSnackbar }}>
      {children}
      {snackbarContainerPositions.map(
        (snackbarContainer) =>
          snackbarContainerHasSnackbars(snackbarContainer?.position) && (
            <Stack
              data-snackbar-position={snackbarContainer.position}
              direction="column-reverse"
              position="fixed"
              top={snackbarContainer?.style?.top}
              left={snackbarContainer?.style?.left}
              right={snackbarContainer?.style?.right}
              bottom={snackbarContainer?.style?.bottom}
              justifyContent={snackbarContainer?.style?.justifyContent}
              alignItems={snackbarContainer?.style?.alignItems}
              style={{ transform: snackbarContainer?.style?.transform }}
              maxWidth={600}
              gap={3}
            >
              {snackbarList.map((snackbar) =>
                snackbarContainer?.position === snackbar?.position ? (
                  <Snackbar
                    autoPosition={false}
                    variant={snackbar?.variant}
                    key={snackbar.id}
                    open
                    autoHideDuration={snackbar?.autoHideDuration}
                    onClose={() => {
                      removeSnackbar(snackbar.id)
                      if (snackbar?.onClose) {
                        snackbar.onClose()
                      }
                    }}
                    closeable={snackbar?.closeable}
                    ClickAwayListenerProps={{ onClickAway: () => null }}
                  >
                    {snackbar.message}
                  </Snackbar>
                ) : null,
              )}
            </Stack>
          ),
      )}
    </SnackbarContext.Provider>
  )
}
