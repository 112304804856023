import { Tabs, TabsContent, TabsList, TabsTrigger } from '@hub-la/shadcn'
import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Home } from '../home/home'
import { SubscriptionsTable } from '../subscriptions-table'

export const DashboardSubscriptions = memo(() => {
  const [tab, setTab] = useState('home')
  const history = useHistory()
  const { t } = useTranslation()

  const onTabChange = (tab: string) => {
    history.push(history.location.pathname + '?tab=' + tab + '#subscriptions')
    setTab(tab)
  }
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const tab = urlParams.get('tab')
    if (tab) {
      setTab(tab)
    }
  }, [])

  return (
    <div>
      <h1 className="text-3xl font-semibold leading-tight mb-2">Assinaturas</h1>
      <Tabs className="space-y-4" value={tab} onValueChange={onTabChange}>
        <TabsList variant="underlined">
          <TabsTrigger variant="underlined" value="home" key="home">
            {t('tabs.home')}
          </TabsTrigger>
          <TabsTrigger variant="underlined" value="subscriptions" key="subscriptions">
            {t('tabs.subscriptions')}
          </TabsTrigger>
        </TabsList>
        <TabsContent value="home" key="home">
          <Home />
        </TabsContent>
        <TabsContent value="subscriptions" key="subscriptions">
          <SubscriptionsTable />
        </TabsContent>
      </Tabs>
    </div>
  )
})
