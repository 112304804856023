import {
  ProductSettingsAffiliatesResponseDto,
  UpdateProductSettingsAffiliatesRequestDto,
} from '@hub-la/sdk-bff-product'
import {
  Input,
  Label,
  RadioGroup,
  RadioGroupItem,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Switch,
} from '@hub-la/shadcn'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { Section } from '../../../components/section'
import { useGetSettings } from '../../../hooks/settings/use-get-settings'
import { useSaveSettings } from '../../../hooks/settings/use-save-settings'

type Values = UpdateProductSettingsAffiliatesRequestDto
const validationSchema = Yup.object().shape({})
const makeInitialValues = (data?: ProductSettingsAffiliatesResponseDto): Values => ({
  enabled: data?.enabled ?? false,
  sell: data?.sell ?? 0,
  renewal: data?.renewal ?? 0,
  public: data?.public ?? false,
})

export const AffiliatesDigital: React.FC = () => {
  const [enabled, setEnabled] = useState<{ sell: boolean; renewal: boolean }>({ sell: true, renewal: true })
  const { productId } = useParams<{ productId: string }>()
  const { mutateAsync: saveSettings } = useSaveSettings()
  const { data, isLoading } = useGetSettings({ productId, type: 'affiliates' })
  const { t } = useTranslation()

  const formik = useFormik<Values>({
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: makeInitialValues(data),
    onSubmit: async (values) => {
      await saveSettings({
        productId,
        type: 'affiliates',
        data: {
          ...values,
          sell: enabled.sell ? values.sell : 0,
          renewal: enabled.renewal ? values.renewal : 0,
        },
      })
    },
  })

  useEffect(() => {
    if (data) {
      setEnabled({ sell: data.sell > 0, renewal: data.renewal > 0 })
    }
  }, [data])

  const handleAffiliateTypeChange = (value: string) => {
    setEnabled({
      sell: value === 'sell_renewal' || value === 'sell',
      renewal: value === 'sell_renewal' || value === 'renewal',
    })
  }

  return (
    <Section title={t('settings.affiliates.title')} subtitle={t('settings.affiliates.subtitle')} formik={formik}>
      <div className="space-y-6">
        <div className="space-y-3">
          <div className="flex items-center space-x-2">
            <Switch
              id="affiliates-enabled"
              checked={formik.values.enabled}
              onCheckedChange={(checked) => formik.setFieldValue('enabled', checked)}
              disabled={isLoading}
            />
            <Label htmlFor="affiliates-enabled">{t('settings.affiliates.switch')}</Label>
          </div>
          <p className="text-sm text-gray-500">{t('settings.affiliates.helperText')}</p>
        </div>

        <div className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="affiliate-type">{t('settings.affiliates.input.type')}</Label>
            <Select
              onValueChange={handleAffiliateTypeChange}
              value={enabled.sell && enabled.renewal ? 'sell_renewal' : enabled.sell ? 'sell' : 'renewal'}
              disabled={isLoading}
            >
              <SelectTrigger id="affiliate-type">
                <SelectValue placeholder="Selecione o tipo de comissão" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="sell_renewal">{t('settings.affiliates.input.option_1')}</SelectItem>
                <SelectItem value="sell">{t('settings.affiliates.input.option_2')}</SelectItem>
                <SelectItem value="renewal">{t('settings.affiliates.input.option_3')}</SelectItem>
              </SelectContent>
            </Select>
          </div>

          {enabled.sell && (
            <div className="space-y-2">
              <Label htmlFor="sell">{t('settings.affiliates.input.sell')}</Label>
              <Input
                id="sell"
                type="number"
                value={formik.values.sell}
                onChange={(e) => {
                  const value = Math.min(Number(e.target.value), 90)
                  formik.setFieldValue('sell', value)
                }}
                disabled={isLoading}
                className="max-w-[200px]"
              />
            </div>
          )}

          {enabled.renewal && (
            <div className="space-y-2">
              <Label htmlFor="renewal">{t('settings.affiliates.input.renewal')}</Label>
              <Input
                id="renewal"
                type="number"
                value={formik.values.renewal}
                onChange={(e) => {
                  const value = Math.min(Number(e.target.value), 90)
                  formik.setFieldValue('renewal', value)
                }}
                disabled={isLoading}
                className="max-w-[200px]"
              />
            </div>
          )}
        </div>

        <div className="space-y-3">
          <Label>{t('settings.affiliates.preferences.title')}</Label>
          <RadioGroup
            onValueChange={(value) => formik.setFieldValue('public', value === 'true')}
            value={formik.values.public.toString()}
            disabled={isLoading}
            className="space-y-2"
          >
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="true" id="public-true" />
              <Label htmlFor="public-true">{t('settings.affiliates.preferences.any')}</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="false" id="public-false" />
              <Label htmlFor="public-false">{t('settings.affiliates.preferences.members')}</Label>
            </div>
          </RadioGroup>
        </div>
      </div>
    </Section>
  )
}
