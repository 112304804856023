import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { GetIntegrationByProviderInput } from '../../domain/dtos/get-integration-by-provider-input'
import { QueryKey } from '../../domain/dtos/query-keys'
import { GetIntegrationByProvider } from '../../usecases/get-integration-by-provider'

export const useGetIntegrationByProvider = (input: GetIntegrationByProviderInput) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.getIntegrationByProvider],
    () => new GetIntegrationByProvider(container.get(HttpClient)).execute(input.provider),
    {
      retry: 3,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      initialData: null,
    },
  )
}
