import { StackProps } from '@mui/system'

export interface PaginationProps extends StackProps {
  total: number
  page: number
  pageSize: number
  lastPage: number
  onChangePage: (page: number) => void
  onChangePageSize: (size: number) => void
}

export type SelectPageSizeProps = {
  pageSize: number
  onChangePage: (page: number) => void
  onChangePageSize: (size: number) => void
}

export enum PaginationPageSize {
  TWENTY_FIVE = '25',
  FIFTY = '50',
  ONE_HUNDRED = '100',
  TWO_HUNDRED = '200',
}
