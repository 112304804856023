import { Shapes } from 'lucide-react'

interface ProductImageProps {
  url?: string | null
}

export const ProductImage = ({ url }: ProductImageProps) => {
  return (
    <div className="w-12 h-12 rounded-md bg-gray-100 flex justify-center items-center overflow-hidden bg-background">
      {url ? (
        <img src={url} alt="product" className="w-full h-full object-cover" />
      ) : (
        <Shapes className="text-primary" />
      )}
    </div>
  )
}
